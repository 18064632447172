import queryString from 'query-string';

import type { TransactionResponse } from '@aircarbon/utils-common/src/dto/transaction';

import { userAuthenticatedApi } from '../fetcher';

export const fetchTransaction = async (accountAddresses: string[], txStatus?: string) => {
  const qs = queryString.stringify(
    {
      accountAddresses,
      txStatus,
    },
    { arrayFormat: 'bracket', skipEmptyString: true, skipNull: true },
  );

  const endpoint = `/user/transactions?${qs}`;
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).get();
  return res.json<TransactionResponse>();
};
