import generateIdxTradeReceipt from './idx/generateIdxTradeReceipt';

// eslint-disable-next-line import/prefer-default-export
export function generate(
  template: string, // TODO: Add other templates
  retirement: any,
): Promise<{
  streamPDF: Promise<NodeJS.ReadableStream>;
  stringPDF: Promise<string>;
} | null> {
  switch (template) {
    case 'IDX':
      return generateIdxTradeReceipt(retirement);
    default:
      // NOTE: We can add ACX later
      return Promise.resolve(null);
  }
}
