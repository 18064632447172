import { useCallback, useContext, useEffect, useState } from 'react';

import { logger } from '@aircarbon/utils-common';

import { useUserOrders } from '../hooks';
import { useCancelOrder } from '../hooks/useCancelOrder';
import { usePlaceOrder } from '../hooks/usePlaceOrderToken';
import { useTokenPrices } from '../hooks/useTokenPrice';
import { MonkeyTestingModeContext } from './MonkeyController';

type Props = {
  userApiKey: string;
  accountAddress: string;
  pairId?: number;
  pair?: string;
};

/**
 * Generate random qty between 1000 vs 10000
 */
function generateQty() {
  return Math.floor(Math.random() * 10) * 1000 || 1000;
}

/**
 * Generate new price from current price +/- percentage
 * @param referencePrice
 * @param percentage
 */
function generatePrice(referencePrice: number, percentage = 0.15): number {
  const price = Math.floor(Math.random())
    ? referencePrice * (1 + Math.random() * percentage)
    : referencePrice * (1 - Math.random() * percentage);

  // get 2 decimals
  return Math.floor(price * 100) / 100;
}

/**
 * Generate time in force
 */
function generateTimeInForce() {
  const expireInTomorrow = new Date(Date.now() + 1000 * 60 * 60 * 24);
  switch (Math.floor(Math.random() * 3)) {
    case 2:
      return { timeInForceTypeId: 0 };
    case 1:
      return { timeInForceTypeId: 1 };
    default:
      return { timeInForceTypeId: 5, expiryUtc: expireInTomorrow };
  }
}

/**
 * Generate random order type
 * @param pairId
 * @param referencePrice
 * @returns
 */
function orderFactory(
  pairId?: number,
  referencePrice?: {
    marketPrice: {
      buy: number;
      sell: number;
    };
    settlementPrice: number;
  },
) {
  const sellMarketOrder = {
    pairId,
    orderSide: 'Sell',
    orderType: 'Market',
    qty: generateQty(),
    timeInForceTypeId: 0,
  };

  const buyMarketOrder = {
    pairId,
    orderSide: 'Buy',
    orderType: 'Market',
    qty: generateQty(),
    timeInForceTypeId: 0,
  };

  const limitBuyOrder = {
    pairId,
    orderSide: 'Buy',
    orderType: 'Limit',
    qty: generateQty(),
    price: generatePrice(referencePrice?.settlementPrice ?? referencePrice?.marketPrice.buy ?? 0),
    ...generateTimeInForce(),
  };
  const limitSellOrder = {
    pairId,
    orderSide: 'Sell',
    orderType: 'Limit',
    qty: generateQty(),
    price: generatePrice(referencePrice?.settlementPrice ?? referencePrice?.marketPrice.sell ?? 0),
    ...generateTimeInForce(),
  };

  switch (Math.floor(Math.random() * 4)) {
    case 1:
      return sellMarketOrder;
    case 2:
      return limitBuyOrder;
    case 3:
      return limitSellOrder;

    default:
      return buyMarketOrder;
  }
}

function MonkeyTestingForTradingScreen({ userApiKey, accountAddress, pairId = 1, pair = '' }: Props) {
  const monkeyModeContext = useContext(MonkeyTestingModeContext);
  const [counter, setCounter] = useState(5);
  const { placeOrder } = usePlaceOrder();
  const { cancelOrder } = useCancelOrder();
  const { orders } = useUserOrders({
    pairId,
    accountAddress,
    status: 'opened',
    limit: 1,
    page: 1,
  });
  const [isRunning, setIsRunning] = useState(false);

  const { tokenPrices } = useTokenPrices({
    pairId: pairId,
  });

  const onPlaceOrderHandler = useCallback(async () => {
    const order = orderFactory(pairId, tokenPrices);
    logger.warn(order, 'Placing order in monkey testing mode');
    monkeyModeContext.setMessage?.('Placing order');
    try {
      await placeOrder(order);
    } catch (error) {
      logger.error(error, 'Failed to place order in monkey testing mode');
      monkeyModeContext.setMessage?.('Failed to place order');
    }
  }, [pairId, tokenPrices, monkeyModeContext, placeOrder]);

  const onCancelOrderHandler = useCallback(async () => {
    const openOrderId = orders?.items?.[0]?.id;
    logger.warn('Canceling order in monkey testing mode', openOrderId, orders);
    monkeyModeContext.setMessage?.('Canceling order');
    try {
      if (openOrderId) {
        await cancelOrder(Number(openOrderId));
      }
    } catch (error) {
      logger.error(error, 'Failed to cancel order in monkey testing mode');
      monkeyModeContext.setMessage?.('Failed to cancel order');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelOrder, orders]);

  useEffect(() => {
    setIsRunning(!!monkeyModeContext.isActive);
  }, [monkeyModeContext.isActive]);

  useEffect(() => {
    setCounter(monkeyModeContext.speed || 1);
  }, [monkeyModeContext.speed]);

  // create a new order every second
  useEffect(() => {
    if (isRunning) {
      const timer = 200 * (10 - counter);
      const interval = setInterval(onPlaceOrderHandler, timer);
      return () => clearInterval(interval);
    }
  }, [counter, isRunning, onPlaceOrderHandler]);

  // cancel order every five seconds
  useEffect(() => {
    if (isRunning) {
      const timer = 1000 * counter;
      const interval = setInterval(onCancelOrderHandler, timer);
      return () => clearInterval(interval);
    }
  }, [counter, isRunning, onCancelOrderHandler]);

  return <></>;
}

export default MonkeyTestingForTradingScreen;
