export const RED = {
  text: {
    default: 'rgb(251 97 117)',
    hex: 'F6465D',
  },
  bg: {
    default: '#f05350',
    dark: '#6e4040',
  },
  hover: {
    default: '#e43c3c',
  },
};

export const BLUE = {
  text: {
    default: '#2db3c6',
  },
};
export const YELLOW = {
  text: {
    default: '#F7DE3A',
  },
};

export const GREEN = {
  text: {
    brand: 'rgba(44,171,119,1)',
    default: 'rgb(14, 203, 129)',
  },
  bg: {
    default: '#5c935c',
    brand: 'rgba(44,171,119,1)',
    brandRGB: '44,171,119',
    dark: '#3d583d',
  },
  hover: {
    default: '#02b71f',
  },
};

export const SKY_BLUE = {
  text: {
    default: '#28bdd5',
  },
};

export const LIGHT = {
  text: `#bbb`,
  border: `rgb(66 81 75 / 30%)`,
};

export const BRIGHT = {
  text: `rgb(207, 208,210)`,
};

export const DARK = {
  bg: '#141416',
};
