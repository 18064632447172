import { useEffect, useState } from 'react';
import { Colors, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

import Button from './Button';

const Wrapper = styled.div<{ border?: Border; type?: Type }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  gap: 10px;
  ${(props) => {
    if (props.type !== 'ghost') {
      if (props.border === 'red') {
        return `border: 1px solid #B02D2D;`;
      } else if (props.border === 'green') {
        return `border: 1px solid #008F17;`;
      } else {
        return `border: 1px solid #444444;`;
      }
    }
  }}

  ${(props) => {
    if (props.type !== 'ghost') {
      return `background-color: #292929; padding: 4px;`;
    } else {
      return `background-color: transparent; padding: 0px;`;
    }
  }}
  
  border-radius: 6px;
  align-items: center;
  cursor: pointer;

  .ghost {
    border-bottom: 1px solid transparent;
    padding-bottom: 3px;
    &.selected {
      border-bottom: 1px solid #626262;
    }
  }
`;

type Border = 'red' | 'green' | 'gray';
type Type = 'boxed' | 'ghost';
export type Tab = {
  label: string;
  value?: any;
  _key?: string;
};

export default function Tabs(props: {
  border?: Border;
  type?: Type;
  label?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  selected: string;
  list: Tab[];
  onSelect?: (tab: Tab) => void;
}) {
  const [selected, setSelected] = useState<string>(props.selected);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => (props.selected !== selected ? setSelected(props.selected) : undefined), [props.selected]);

  return (
    <Wrapper border={props.border} type={props.type} className={props.className}>
      {props.list.map((item, index) => {
        const isGhost = props.type === 'ghost';
        const isSelected = selected === item.label;
        return (
          <div key={`${item._key || item.label}${index}`} className={`${isGhost ? '' : 'w-full'} flex flex-row`}>
            <Button
              onClick={() => {
                setSelected(item.label);
                props.onSelect?.(item);
              }}
              className={`${isGhost && isSelected ? 'selected' : ''} ${isGhost ? 'ghost' : ''}`}
              style={{
                borderRadius: '0px',
                color:
                  isGhost && isSelected ? `var(${Colors.gray_900})` : isGhost ? `var(${Colors.gray_500})` : undefined,
                fontWeight: isSelected ? `var(${FontWeight.bold})` : isGhost ? `var(${FontWeight.medium})` : undefined,
              }}
              config={{
                hoverStyle: isGhost ? 'underline' : undefined,
                size: isGhost ? 'auto' : undefined,
                border: 'transparent',
                label: item.label,
                backgroundColor: !isGhost && isSelected ? 'light-gray' : 'transparent',
              }}
            />
          </div>
        );
      })}
    </Wrapper>
  );
}
