const themes = {
  AirCarbon: {
    brandColor: '#2CAB77',
    brandRbgColor: '44,171,119',
    backgroundColor: '#191919',
    secondaryBackgroundColor: '#f2f2f2',
    secondaryColor: '#ededed',
    hxBackgroundColor: '#000',
  },
  SDAX: {
    brandColor: '#E7313A',
    brandRbgColor: '231,49,58',
    backgroundColor: '#191919',
    secondaryBackgroundColor: '#f2f2f2',
    secondaryColor: '#ededed',
    hxBackgroundColor: '#FFFFFF',
  },
  IDX: {
    brandColor: '#2CAB77',
    brandRbgColor: '44,171,119',
    backgroundColor: '#191919',
    secondaryBackgroundColor: '#f2f2f2',
    secondaryColor: '#ededed',
    hxBackgroundColor: '#000',
  },
  EON: {
    brandColor: '#2CAB77',
    brandRbgColor: '44,171,119',
    backgroundColor: '#191919',
    secondaryBackgroundColor: '#f2f2f2',
    secondaryColor: '#ededed',
    hxBackgroundColor: '#000',
  },
};

export function getTheme(companyName: 'SDAX' | 'AirCarbon' | 'IDX' | 'EON'): {
  brandColor: string;
  brandRbgColor: string;
} {
  return themes[companyName] || themes.AirCarbon;
}

export default {
  themes,
  getTheme,
};
