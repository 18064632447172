import { useCallback, useState } from 'react';

import { Layer, styled, toSpacing } from '@aircarbon/ui';

import { BottomSlideUp } from '../BottomSlideUp';
import { useActiveTab } from '../BottomSlideUp/hooks/useActiveTab';
import { Filters } from '../Filters';
import { FiltersProvider } from '../FiltersProvider';
import { Listings } from '../Listings';
import { OnboardingAlert } from './components/OnboardingAlert';

export const MarketBoardV2ListingsPage: React.FunctionComponent<{}> = () => {
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const { updateState } = useActiveTab();

  const onPressFilterButton = useCallback(() => {
    setIsFiltersVisible((isPrevVisible) => !isPrevVisible);
  }, []);

  const onPressShowOpenRequests = useCallback(() => {
    updateState(() => ({ activeTabId: 'my-requests', isOpened: true }));
  }, []);

  return (
    <FiltersProvider>
      <PageContainer>
        <OnboardingAlert />
        <ContentContainer>
          <Layer>
            {!!isFiltersVisible && <Filters />}

            <Listings
              isFiltersVisible={isFiltersVisible}
              onPressFilter={onPressFilterButton}
              onPressShowOpenRequests={onPressShowOpenRequests}
            />
          </Layer>
        </ContentContainer>
        <Layer>
          <BottomSlideUp />
        </Layer>
      </PageContainer>
    </FiltersProvider>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const ContentContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => toSpacing(theme)(6)};
  padding: ${({ theme }) => toSpacing(theme)(8)};
  margin-bottom: ${({ theme }) => toSpacing(theme)(22)};
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 1;
  overflow-y: auto;

  @media (max-width: 1258px) {
    flex-direction: column;
  }
`;
