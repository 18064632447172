export const toStatus = (backendStatus: string) => {
  const statusesMap: Record<string, { value: string; label: string }> = {
    SUBMITTED: {
      value: 'submitted',
      label: 'Submitted',
    },
    CANCELLED: {
      value: 'cancelled',
      label: 'Cancelled',
    },
    DONE: {
      value: 'done',
      label: 'Done',
    },
    PENDING: {
      value: 'pedning',
      label: 'Pending',
    },
    PROCESSING: {
      value: 'processing',
      label: 'Processing',
    },
    REVERTED: {
      value: 'reverted',
      label: 'Reverted',
    },
  };

  return (
    statusesMap[backendStatus] || {
      value: 'unknown',
      label: 'Unknown',
    }
  );
};
