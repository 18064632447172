import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { helpers, hooks } from '@aircarbon/utils-common';
import type { Page } from '@aircarbon/utils-common/src/dto';

import { fetchSearchResultsPage } from 'data-provider/user/fetchSearchResultsPage';

const defaultPageSize = 5;

interface UseSearchResultsPageProps<F extends {}, R, TR = R> {
  searchEndpoint: string;
  urlPath?: string;
  toApiFilters: (filters: F) => object;
  toQueryParams: (filters: F) => object;
  toFilters: (searchQuery: string) => F;
  toResults?: (results: R[]) => TR[];
}

export const useSearchResultsPage = <Filters extends {}, Result, TransformedResult = Result>(
  props: UseSearchResultsPageProps<Filters, Result, TransformedResult>,
): {
  searchValue: string;
  pageSize: number;
  currentPage: number;
  filters: Filters;
  results: TransformedResult[];
  totalResultsCount: number;
  isFetching: boolean;
  search(searchValue: string): void;
  filter(filters: Filters): void;
  changePagination(currentPage: number, pageSize: number): void;
} => {
  const { search: searchQuery } = useLocation();
  const prevSearchQuery = hooks.usePrevious(searchQuery);

  const history = useHistory();

  const {
    search: searchFromQuery,
    filters: filtersFromQuery,
    currentPage: currentPageFromQuery,
    pageSize: pageSizeFromQuery,
  } = toSearchParams<Filters>({ searchQuery, defaultPageSize, toFilters: props.toFilters });

  const [searchValue, setSearchValue] = useState(searchFromQuery);
  const [filters, setFilters] = useState(filtersFromQuery);
  const [currentPage, setCurrentPage] = useState(currentPageFromQuery);
  const [pageSize, setPageSize] = useState(pageSizeFromQuery);
  const [totalResultsCount, setTotalResultsCount] = useState(0);
  const [results, setResults] = useState<TransformedResult[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (prevSearchQuery === undefined || searchQuery === prevSearchQuery) {
      return;
    }
    const {
      search: searchFromQuery,
      filters: filtersFromQuery,
      currentPage: currentPageFromQuery,
      pageSize: pageSizeFromQuery,
    } = toSearchParams<Filters>({ searchQuery, defaultPageSize, toFilters: props.toFilters });

    setSearchValue(searchFromQuery);
    setFilters(filtersFromQuery);
    setCurrentPage(currentPageFromQuery);
    setPageSize(pageSizeFromQuery);
  }, [searchQuery, prevSearchQuery]);

  useEffect(() => {
    const fetchResults = async () => {
      setIsFetching(true);
      const apiFilters = toApiFilters({
        searchValue,
        currentPage,
        pageSize,
        filters,
        toApiFilters: props.toApiFilters,
      });
      const queryParams = helpers.objectToQueryString({ ...apiFilters });
      let apiPath = props.searchEndpoint;
      if (queryParams) {
        apiPath += `?${queryParams}`;
      }

      try {
        const response = (await fetchSearchResultsPage(apiPath)) as Page<Result>;
        const transformedResults = props.toResults ? props.toResults(response.data) : response.data;
        setResults(transformedResults as unknown as TransformedResult[]);
        setTotalResultsCount(response.totalCount);
      } catch {}
      setIsFetching(false);
    };

    fetchResults();
  }, [searchValue, filters, currentPage, pageSize, props.searchEndpoint, props.toApiFilters]);

  const replaceHistory = (params: { searchValue: string; currentPage: number; pageSize: number; filters: Filters }) => {
    const queryParams = toQueryParams({ ...params, defaultPageSize, toQueryParams: props.toQueryParams });

    history.replace({
      pathname: props.urlPath,
      search: queryParams ? `?${queryParams}` : undefined,
    });
  };

  const pushToHistory = (params: { searchValue: string; currentPage: number; pageSize: number; filters: Filters }) => {
    const queryParams = toQueryParams({ ...params, defaultPageSize, toQueryParams: props.toQueryParams });

    history.push({
      pathname: props.urlPath,
      search: queryParams ? `?${queryParams}` : undefined,
    });
  };

  const search = (searchValue: string) => {
    if (!props.urlPath) {
      setSearchValue(searchValue);
      return;
    }
    replaceHistory({ searchValue, filters, currentPage: 1, pageSize });
  };

  const filter = (filters: Filters) => {
    if (!props.urlPath) {
      setFilters(filters);
      return;
    }
    replaceHistory({ searchValue, filters, currentPage: 1, pageSize });
  };

  const changePagination = (currentPage: number, pageSize: number) => {
    if (!props.urlPath) {
      setCurrentPage(currentPage);
      setPageSize(pageSize);
      return;
    }
    pushToHistory({ searchValue, filters, currentPage, pageSize });
  };

  return {
    results,
    totalResultsCount,
    pageSize,
    currentPage,
    filters,
    searchValue,
    isFetching,
    search,
    filter,
    changePagination,
  };
};

const toApiFilters = <Filters extends {}>(params: {
  searchValue: string;
  currentPage: number;
  pageSize: number;
  filters: Filters;
  toApiFilters: (filters: Filters) => object;
}) => {
  const { currentPage, pageSize, searchValue, filters } = params;
  let fetchParams: {
    filter?: {
      search?: {
        fields: Array<string>;
        value: string;
      };
    };
    page?: {
      size?: number;
      number?: number;
    };
  } = {};

  if (currentPage && currentPage !== 1) {
    fetchParams = {
      ...fetchParams,
      page: {
        ...fetchParams.page,
        number: currentPage,
      },
    };
  }

  if (pageSize) {
    fetchParams = {
      ...fetchParams,
      page: {
        ...fetchParams.page,
        size: pageSize,
      },
    };
  }

  if (searchValue) {
    fetchParams = {
      ...fetchParams,
      filter: {
        ...fetchParams.filter,
        search: {
          ...fetchParams.filter?.search,
          fields: ['name', 'sectoralScope'],
          value: searchValue,
        },
      },
    };
  }

  return { ...fetchParams, ...params.toApiFilters(filters) };
};

const toQueryParams = <Filters extends {}>(params: {
  searchValue: string;
  currentPage: number;
  pageSize: number;
  filters: Filters;
  defaultPageSize: number;
  toQueryParams: (filters: Filters) => object;
}) => {
  const queryParams: Partial<Record<string, string>> = {};
  const { searchValue, filters, currentPage, pageSize, defaultPageSize } = params;

  if (currentPage && currentPage !== 1) {
    queryParams['page[number]'] = currentPage.toString();
  }

  if (pageSize && pageSize !== defaultPageSize) {
    queryParams['page[size]'] = pageSize.toString();
  }

  if (searchValue) {
    queryParams.search = searchValue;
  }

  const otherParams = params.toQueryParams(filters);

  return helpers.objectToQueryString({ ...queryParams, ...otherParams });
};

const toSearchParams = <F extends {}>(props: {
  searchQuery: string;
  defaultPageSize: number;
  toFilters: (searchQuery: string) => F;
}) => {
  const filtersFromQueryParams: {
    search: string;
    filters: F;
    currentPage: number;
    pageSize: number;
  } = {
    search: '',
    filters: {} as F,
    currentPage: 1,
    pageSize: props.defaultPageSize,
  };
  const queryParams = new URLSearchParams(props.searchQuery);
  const currentPage = Number(queryParams.get('page[number]') || undefined);

  if (!isNaN(currentPage)) {
    filtersFromQueryParams.currentPage = currentPage;
  }

  const pageSize = Number(queryParams.get('page[size]') || undefined);

  if (!isNaN(pageSize)) {
    filtersFromQueryParams.pageSize = pageSize;
  }

  const search = queryParams.get('search') || '';

  filtersFromQueryParams.search = search;

  filtersFromQueryParams.filters = {
    ...filtersFromQueryParams.filters,
    ...props.toFilters(props.searchQuery),
  };

  return filtersFromQueryParams;
};
