export default (
  <svg viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.75 11H8.75V15C8.75 15.5523 8.30228 16 7.75 16H6.75C6.19772 16 5.75 15.5523 5.75 15V11Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 0C3.66015 0 0.75 2.91015 0.75 6.5C0.75 10.0899 3.66015 13 7.25 13C10.8399 13 13.75 10.0899 13.75 6.5C13.75 2.91015 10.8399 0 7.25 0ZM7.25 2C4.76472 2 2.75 4.01472 2.75 6.5C2.75 8.98528 4.76472 11 7.25 11C9.73528 11 11.75 8.98528 11.75 6.5C11.75 4.01472 9.73528 2 7.25 2Z"
      fill="white"
    />
    <path
      d="M7.38441 9.22867C6.99014 9.22867 6.62575 9.18444 6.29122 9.09597C5.95669 9.0075 5.658 8.88586 5.39516 8.73104C5.13829 8.57622 4.92324 8.39652 4.75 8.19194L5.431 7.4455C5.56243 7.63349 5.73268 7.7966 5.94176 7.93483C6.15084 8.06754 6.3868 8.17259 6.64964 8.25C6.91249 8.32188 7.1813 8.35782 7.45609 8.35782C7.82049 8.35782 8.10723 8.30253 8.31631 8.19194C8.52539 8.08136 8.62993 7.91825 8.62993 7.70261C8.62993 7.56438 8.57019 7.4455 8.45072 7.34597C8.33722 7.24645 8.1371 7.16627 7.85036 7.10545L6.59588 6.84005C6.26135 6.76817 5.97461 6.66864 5.73566 6.54147C5.49671 6.4143 5.31452 6.25948 5.18907 6.07701C5.06959 5.88902 5.00986 5.67338 5.00986 5.43009C5.00986 5.1094 5.10245 4.82464 5.28763 4.57583C5.47879 4.32701 5.74761 4.12796 6.09409 3.97867C6.44654 3.82938 6.87067 3.75474 7.36649 3.75474C7.8862 3.75474 8.34319 3.83768 8.73746 4.00355C9.13172 4.16943 9.46924 4.41548 9.75 4.74171L9.09588 5.43009C8.85693 5.1481 8.5911 4.94352 8.29839 4.81635C8.01165 4.68365 7.67712 4.6173 7.2948 4.6173C7.02599 4.6173 6.80795 4.64771 6.64068 4.70853C6.47342 4.76382 6.34797 4.84123 6.26434 4.94076C6.18668 5.04028 6.14785 5.15363 6.14785 5.28081C6.14785 5.38033 6.17174 5.4688 6.21953 5.54621C6.26732 5.62362 6.35693 5.6955 6.48835 5.76185C6.62575 5.82267 6.81989 5.88073 7.07079 5.93602L8.14606 6.15995C8.70759 6.27607 9.11081 6.44747 9.35574 6.67417C9.60663 6.89534 9.73208 7.19115 9.73208 7.56161C9.73208 7.91548 9.63351 8.21682 9.43638 8.46564C9.23925 8.71446 8.96147 8.90521 8.60305 9.03791C8.2506 9.16509 7.84439 9.22867 7.38441 9.22867ZM6.96326 10V3H7.7966V10H6.96326Z"
      fill="white"
    />
  </svg>
);
