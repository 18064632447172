import { userAuthenticatedApi } from 'data-provider/fetcher';

export const downloadAuctionRules = async (
  orderId: number,
): Promise<
  | {
      status: 'ok';
      file: Promise<Blob>;
      fileName: string;
    }
  | {
      status: 'error';
      error: string;
    }
> => {
  const userService = await userAuthenticatedApi();

  try {
    const res = await userService.url(`/user/otc/order/${orderId}/documents`).get().res();

    if (res.status >= 300) {
      return {
        status: 'error',
        error: res.statusText ?? 'Something went wrong. Please try again',
      };
    }

    const fileName =
      res.headers.get('Content-Disposition')?.split('filename=')[1]?.replace(/"/g, '') ?? 'auction-rules.pdf';

    return {
      status: 'ok',
      file: res.blob(),
      fileName,
    };
  } catch (e) {
    const error = JSON.parse((e as Error).message);
    return {
      status: 'error',
      error: error.message ?? 'Something went wrong. Please try again',
    };
  }
};
