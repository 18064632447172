import type { GetApxProjectsApiParams } from '@aircarbon/utils-common/src/dto';

import type { ProjectsListFilters } from '../../MyProjects/components/ProjectsList';

export const toMarketplaceApiFilters = (filters: ProjectsListFilters): GetApxProjectsApiParams => {
  let fetchParams: GetApxProjectsApiParams = {};

  if (filters.sectoralScopes?.length) {
    fetchParams = {
      ...fetchParams,
      filter: {
        ...fetchParams.filter,
        'sectoral-scopes': filters.sectoralScopes,
      },
    };
  }

  if (filters.projectTypes?.length) {
    fetchParams = {
      ...fetchParams,
      filter: {
        ...fetchParams.filter,
        'project-types': filters.projectTypes,
      },
    };
  }

  if (filters.vintageYear?.from !== undefined) {
    fetchParams = {
      ...fetchParams,
      filter: {
        ...fetchParams.filter,
        vintage: {
          ...fetchParams.filter?.vintage,
          from: filters.vintageYear?.from,
        },
      },
    };
  }

  if (filters.vintageYear?.to !== undefined) {
    fetchParams = {
      ...fetchParams,
      filter: {
        ...fetchParams.filter,
        vintage: {
          ...fetchParams.filter?.vintage,
          to: filters.vintageYear?.to,
        },
      },
    };
  }

  return fetchParams;
};
