type ToVintagesCountProps = {
  startYear: string;
  endYear: string;
};

export const toVintagesCount = (props: ToVintagesCountProps) => {
  const { startYear, endYear } = props;

  if (!startYear || !endYear) {
    return '';
  }

  return '' + (Number(endYear) - Number(startYear) + 1);
};
