import { useContext, useEffect } from 'react';

import { type AssetCategory, logger } from '@aircarbon/utils-common';

import { MarketplaceProductContext } from 'providers/MarketplaceProductProvider';

import emitter from 'utils/emitter';

export const useMarketplaceProduct = (products?: Array<AssetCategory>) => {
  const context = useContext(MarketplaceProductContext);

  useEffect(() => {
    if (products && products?.length > 0) {
      context.changeAvailableProducts(products);
    }
  }, [products]);

  useEffect(() => {
    emitter.on('CHANGE_MARKET_PRODUCT', (newProduct) => {
      logger.info({ newProduct }, 'change market event received');
      context.changeAvailableProducts([newProduct]);
    });
  }, [context]);

  return context;
};
