export default (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.3 7.9615H14.8435C12.829 7.9615 11.1885 6.321 11.1885 4.3065V1.85C11.1885 1.3825 10.806 1 10.3385 1H6.7345C4.1165 1 2 2.7 2 5.7345V13.2655C2 16.3 4.1165 18 6.7345 18H13.4155C16.0335 18 18.15 16.3 18.15 13.2655V8.8115C18.15 8.344 17.7675 7.9615 17.3 7.9615ZM9.6755 10.8005C9.548 10.928 9.3865 10.9875 9.225 10.9875C9.0635 10.9875 8.902 10.928 8.7745 10.8005L8.1625 10.1885V13.75C8.1625 14.0985 7.8735 14.3875 7.525 14.3875C7.1765 14.3875 6.8875 14.0985 6.8875 13.75V10.1885L6.2755 10.8005C6.029 11.047 5.621 11.047 5.3745 10.8005C5.128 10.554 5.128 10.146 5.3745 9.8995L7.0745 8.1995C7.134 8.1485 7.1935 8.106 7.2615 8.072C7.2785 8.0635 7.304 8.055 7.321 8.0465C7.372 8.0295 7.423 8.021 7.4825 8.0125C7.508 8.0125 7.525 8.0125 7.5505 8.0125C7.6185 8.0125 7.6865 8.0295 7.7545 8.055C7.763 8.055 7.763 8.055 7.7715 8.055C7.8395 8.0805 7.9075 8.1315 7.9585 8.1825C7.967 8.191 7.9755 8.191 7.9755 8.1995L9.6755 9.8995C9.922 10.146 9.922 10.554 9.6755 10.8005Z"
      fill="currentColor"
    />
    <path
      d="M14.69 6.78841C15.4975 6.79692 16.6195 6.79691 17.58 6.79691C18.0645 6.79691 18.3195 6.22741 17.9795 5.88741C16.7555 4.65491 14.5625 2.43641 13.3045 1.17841C12.956 0.829915 12.3525 1.06791 12.3525 1.55241V4.51891C12.3525 5.75991 13.4065 6.78841 14.69 6.78841Z"
      fill="currentColor"
    />
  </svg>
);
