import type { SettleEntity } from 'generated';

import { SettleStatus } from '@aircarbon/utils-common';

import { formatDateWithParameter } from 'utils/helpers';

import { PendingActionType } from '../../PendingAlerts';

export const getAlertTitle = (
  settlement: Pick<SettleEntity, 'id' | 'status' | 'buyerUserId' | 'sellerUserId' | 'settlementDate'>,
  currentUserId: number,
): string => {
  const isSeller = settlement.sellerUserId === currentUserId;

  if (settlement.status === SettleStatus.SUBMITTED) {
    return `You need to accept the instruction #${settlement.id} to proceed with the trade`;
  }

  if (settlement.status === SettleStatus.VALIDATED) {
    const assetOrFund = isSeller ? 'Asset' : 'Fund';
    const formattedDate = formatDateWithParameter({
      date: settlement.settlementDate,
      minute: false,
      hour: false,
      week: false,
      year: true,
    });
    return `You need to Reserve the ${assetOrFund} for the instruction #${settlement.id} before the settlement date ${formattedDate} to avoid cancellation`;
  }

  return '';
};

export const toPendingAlerts = ({
  settlement,
  currentUserId,
}: {
  settlement?: Pick<
    SettleEntity,
    'id' | 'status' | 'buyerUserId' | 'sellerUserId' | 'buyerStatus' | 'sellerStatus' | 'settlementDate'
  >;
  currentUserId: number;
}) => {
  if (
    !settlement ||
    ([SettleStatus.SETTLED, SettleStatus.REJECTED, SettleStatus.CANCELED, SettleStatus.EXPIRED] as string[]).includes(
      settlement?.status,
    )
  ) {
    return [];
  }
  const isBuyer = settlement.buyerUserId === currentUserId;
  const mySideStatus = isBuyer ? settlement.buyerStatus : settlement.sellerStatus;
  const otherSideStatus = isBuyer ? settlement.sellerStatus : settlement.buyerStatus;

  // User can accept or reject the instruction
  if (mySideStatus === SettleStatus.SUBMITTED && otherSideStatus !== SettleStatus.REJECTED) {
    return [
      {
        id: settlement.id,
        title: getAlertTitle(settlement, currentUserId),
        primaryActionType: PendingActionType.Accept,
        secondaryActionType: PendingActionType.Reject,
      },
    ];
  }

  // Ops validate the instruction, user can reserve
  if (settlement.status === SettleStatus.VALIDATED && mySideStatus !== SettleStatus.RESERVED) {
    const isSeller = settlement.sellerUserId === currentUserId;

    return [
      {
        id: settlement.id,
        title: getAlertTitle(settlement, currentUserId),
        primaryActionType: PendingActionType.Reserve,
        primaryActionLabel: isSeller ? 'Reserve Asset' : 'Reserve Fund',
      },
    ];
  }

  return [];
};
