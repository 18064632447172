import queryString from 'query-string';

import { Dto } from '@aircarbon/utils-common';

import { userAuthenticatedApi } from 'data-provider/fetcher';

export interface FetchTradeRequestsProps {
  assetCategoryId?: string;
  otcOrderId?: string;
  status?: string[];
  page?: number;
}

export interface CounterOffer {
  id: number;
  price: number;
  quantity: number;
  createdAtUtc: string;
  direction?: 'sent' | 'received';
  userId?: number;
  tradeRequestId?: string;
}

export interface TradeRequest {
  id: string;
  price: number;
  quantity: number;
  status: 'NEW' | 'EXECUTED' | 'REJECTED' | 'CANCELLED' | 'APPROVED' | 'ACCEPTED';
  approvedAtUtc: string | null;
  cancelledAtUtc: string | null;
  confirmedByBuyerAtUtc: string | null;
  confirmedBySellerAtUtc: string | null;
  buyerConfirmationStatus: string;
  sellerConfirmationStatus: string;
  counterOffers: Array<CounterOffer>;
  reservedAssetQty: number | null;
  isPrefunded: number;
  expiryUtc: string | null;
  otcOrderId: string;
  sellerUserId: number | null;
  buyerUserId: number | null;
  createdBy: number | null;
  otcOrder: {
    id: string;
    sideId: number;
    isAuction: number;
    filledQty: number;
    qty: number;
    isPrefunded: number;
    expiryUtc?: string | null;
    userId: number;
    placedBy: number;
  };
  baseAsset?: {
    symbol: string;
    numDecimals: number;
  } | null;
  rejectionReason: string | null;
  comment: string | null;
  tradeRequestDetail: {
    carbonProjectVintageYear: number;
    projectId: number | null;
    __project__: {
      id: number;
      name: string;
      status: Dto.ProjectStatus;
      registryProjectId: string;
      __registry__?: {
        name: string;
      };
    } | null;
  };
}

export interface FetchTradeRequestsResponse {
  data: Array<TradeRequest>;
  totalPages: number;
  totalCount: number;
  pageCount: number;
}

export const fetchTradeRequests = async (
  props: FetchTradeRequestsProps = {},
): Promise<FetchTradeRequestsResponse | null> => {
  const userService = await userAuthenticatedApi();

  const request = userService
    .url(`/user/otc/trade-requests`)
    .query(
      queryString.stringify(props, {
        arrayFormat: 'bracket',
      }),
    )
    .get();

  const response = await request.res();

  if (response.status >= 300) {
    return null;
  }

  const resJson: any = await request.json();

  return resJson;
};
