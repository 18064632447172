export default (
  <svg viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.3518 19.98C29.9678 24.2206 26.8799 27 22.3999 27H7.99996C3.58398 27 0 23.4424 0 19.0588V7.94118C0 3.62118 2.62399 0.603529 6.70396 0.095294C7.11996 0.0317646 7.55196 0 7.99996 0H22.3999C22.8159 0 23.2159 0.0158823 23.5999 0.0794117C27.4238 0.524118 30.0158 3.17647 30.3518 7.02C30.3998 7.48059 30.0158 7.86176 29.5518 7.86176H27.0719C25.5359 7.86176 24.1119 8.44941 23.0879 9.49765C21.8719 10.6729 21.2639 12.3247 21.4079 13.9765C21.6639 16.8671 24.2239 19.1382 27.2638 19.1382H29.5518C30.0158 19.1382 30.3998 19.5194 30.3518 19.98Z"
      fill="#007495"
    />
    <path
      d="M32 11.8628V15.1346C32 16.0081 31.296 16.7228 30.4 16.7546H27.264C25.536 16.7546 23.952 15.4999 23.808 13.7846C23.712 12.784 24.096 11.847 24.768 11.1958C25.36 10.5922 26.176 10.2429 27.072 10.2429H30.4C31.296 10.2746 32 10.9893 32 11.8628Z"
      fill="#007495"
    />
  </svg>
);
