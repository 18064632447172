import { Route, Switch } from 'react-router-dom';

import { Marketplace } from './components/Marketplace';
import { MyProjects } from './components/MyProjects';
import { ProjectDetailPage } from './components/ProjectDetailPage';
import { ProjectFormPage } from './components/ProjectFormPage';

export const ProjectExchange = () => {
  return (
    <Switch>
      <Route path={['/account/apx/marketplace', '/account/apx']} exact>
        <Marketplace />
      </Route>
      <Route path={'/account/apx/my-projects'} exact>
        <MyProjects />
      </Route>
      <Route path={'/account/apx/my-projects/new-project'} exact>
        <ProjectFormPage />
      </Route>
      <Route path={'/account/apx/my-projects/:projectId/edit'} exact>
        <ProjectFormPage />
      </Route>
      <Route path={['/account/apx/my-projects/:projectId', '/account/apx/marketplace/:projectId']}>
        <ProjectDetailPage />
      </Route>
    </Switch>
  );
};
