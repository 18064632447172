// eslint-disable-next-line no-shadow
enum TokenMintStatus {
  PENDING = 'pending',
  REVIEWED = 'reviewed',
  APPROVED = 'approved',
  EXECUTED = 'executed',
  MINTED = 'minted',
}

export default TokenMintStatus;
