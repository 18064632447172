import { CMBOrderType } from '@aircarbon/ui';

import { BidFormValue, OfferFormValue } from '../components/PlaceOrderModal/hooks/usePlaceOrderForm';
import { TimeInForce } from './TimeInForce';
import { CreateTradeRequestProps } from './createTradeRequest';

interface ToCreateTradeRequestBodyProps {
  formValue: BidFormValue | OfferFormValue;
  orderType: CMBOrderType;
  orderId: string;
  senderUserId: number;
}

export const toCreateTradeRequestBody = (props: ToCreateTradeRequestBodyProps): CreateTradeRequestProps => {
  const { senderUserId, orderId: otcOrderId, formValue, orderType } = props;

  const { quantity, price, isInstantTrade } = formValue;
  let body: CreateTradeRequestProps = {
    senderUserId,
    otcOrderId: Number(otcOrderId),
    quantity: Number(quantity),
    price: Number(price),
    timeInForceId: Number(TimeInForce.GoodTillCancel),
    isPrefunded: isInstantTrade,
  };

  if (orderType === CMBOrderType.Bid) {
    const offerFormValue = formValue as OfferFormValue;
    body = {
      ...body,
      projectId: Number(offerFormValue.project?.value),
      vintageYear: Number(offerFormValue.vintage),
      baseAssetId: Number(offerFormValue.baseAssetId),
    };
  }

  return body;
};
