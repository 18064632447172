import { userAuthenticatedApi } from '../fetcher';

type RFQResponse = {
  items: Array<{
    id: number;
    quantity: number;
    price: number;
    tokenTypeId: number;
    ccyTypeId: number;
    rfqRequestId: number;
    createdAt: Date;
    userId: number;
    status: string;
    txHash: string;
    statusMessage: string;
    __rfqRequest__: Array<any>;
  }>;
  limit: number;
  page: number;
  total: number;
};

export const fetchRFQResponses = async (limit: number) => {
  const endpoint = '/user/rfq-responses';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ limit }).get();
  return res.json<RFQResponse>();
};
