import { userAuthenticatedApi } from '../fetcher';

export interface TradeRequestCancelReason {
  id: number;
  reason: string;
}

export const fetchTradeRequestCancelReason = async () => {
  const endpoint = '/oms/trade/trade-request-cancel-reason';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).get();
  return res.json<TradeRequestCancelReason[]>();
};
