import { Text } from 'refreshed-component/atoms/Text';
import { Tooltip } from 'refreshed-component/atoms/Tooltip';
import { type Colors, FontSize } from 'refreshed-component/design-system';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import Loading from './Loading';

export const MarketStatus = () => {
  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({
    includeMarketStatus: true,
  });

  if (isLoadingMarketSettings) return <Loading isOverLay={false} size="small" />;

  if (!marketSettings?.marketStatusObj?.label) {
    return <></>;
  }

  return (
    <>
      <Text size={FontSize.small}>Market Status:</Text>
      <Text
        color={marketSettings?.marketStatusObj?.color as Colors}
        size={FontSize.small}
        postfix={{
          view: marketSettings?.marketStatusObj?.description ? (
            <Tooltip text={marketSettings?.marketStatusObj?.description} />
          ) : undefined,
        }}
      >
        {marketSettings?.marketStatusObj?.label}
      </Text>
    </>
  );
};
