import { type ChangeEventHandler, useRef } from 'react';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Input } from 'refreshed-component/atoms/Input';
import Popover from 'refreshed-component/atoms/Popover';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight, Radius } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { hooks } from '@aircarbon/utils-common';

import { SearchResult } from './components/SearchResult';
import { useSearchProject } from './hooks/useSearchProject';
import { searchProjects } from './utils/searchProjects';
import { UIState, UIEvent } from './utils/toUIState';

interface SearchProps {
  assetCategoryId: string;
  onSelectProject(project: { id: number; label: string }): void;
}

interface SearchResult {
  title: string;
  description: string;
  id: number;
}

export const SearchProject: React.FunctionComponent<SearchProps> = (props) => {
  const { onSelectProject, assetCategoryId } = props;
  const resultsContainerRef = useRef<HTMLDivElement>(null);
  const { uiState, searchResults, searchValue, dispatch, setSearchValue } = useSearchProject(
    assetCategoryId,
    searchProjects,
  );

  hooks.useOnClickOutside(resultsContainerRef as any, () => {
    if (uiState === UIState.Idle) {
      return;
    }
    dispatch({
      event: UIEvent.Blur,
    });
  });

  const onFocusInput = () => {
    dispatch({
      event: UIEvent.Focus,
      payload: searchValue,
    });
  };

  const onChangeInput: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchValue(event.target.value);
    dispatch({
      event: UIEvent.ChangeSearchValue,
      payload: event.target.value,
    });
  };

  const onPressClearSearch = () => {
    setSearchValue('');
    dispatch({
      event: UIEvent.ChangeSearchValue,
    });
  };

  const onPressProject = (project: { id: number; label: string }) => () => {
    setSearchValue('');
    dispatch({
      event: UIEvent.SelectProject,
    });
    onSelectProject(project);
  };

  const shouldShowDropdown = [UIState.SearchInProgress, UIState.ShowingEmptyResults, UIState.ShowingResults].includes(
    uiState,
  );
  const shouldShowLoader = uiState === UIState.SearchInProgress;
  const shouldShowSearchResults = [UIState.ShowingResults, UIState.IdleAfterShowingResults].includes(uiState);
  const shouldShowNothingFound = [UIState.ShowingEmptyResults, UIState.IdleAfterShowingEmptyResults].includes(uiState);

  return (
    <StyledSearch>
      <Popover
        view="transparent"
        placement="bottomLeft"
        visible={shouldShowDropdown}
        overlayStyle={{
          borderRadius: '0px !important',
        }}
        content={() => {
          return (
            <ResultsContainer ref={resultsContainerRef}>
              {shouldShowSearchResults &&
                searchResults.map((item, index) => (
                  <SearchResult
                    {...item}
                    key={index}
                    onPress={onPressProject({
                      id: item.id,
                      label: item.title,
                    })}
                  />
                ))}
              {shouldShowLoader && [1, 2, 3].map((key) => <SearchResult.Loader key={key} />)}
              {shouldShowNothingFound && (
                <NothingFound>
                  <Text size={FontSize.large} align="center" weight={FontWeight.medium}>
                    No projects found
                  </Text>
                  <Text size={FontSize.small} align="center" color={Colors.gray_500}>
                    Please ajust your search to see available projects
                  </Text>
                </NothingFound>
              )}
            </ResultsContainer>
          );
        }}
      >
        <div>
          <Input
            placeholder="Search Project"
            onFocus={onFocusInput}
            config={{
              size: 'base',
              color: 'gray',
              postfix: !!searchValue && (
                <ClearSearch onClick={onPressClearSearch}>
                  <Icon type={IconType.XCircle} width={14} height={14} />
                </ClearSearch>
              ),
              prefix: <Icon type={IconType.Search} width={14} height={14} />,
            }}
            value={searchValue}
            onChange={onChangeInput}
          />
        </div>
      </Popover>
    </StyledSearch>
  );
};

const StyledSearch = styled.div`
  position: relative;
  width: 275px;
`;

const ResultsContainer = styled.div`
  padding: 8px 16px;
  border: 1px solid var(${Colors.gray_200});
  border-radius: var(${Radius.medium});
  background: var(${Colors.gray_0});
  max-height: 260px !important;
  overflow-y: auto;
  box-shadow:
    0px 4px 6px 0px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`;

const ClearSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 20px;
  margin-inline-end: -6px;
  color: var(${Colors.gray_400});
  cursor: pointer;
`;

const NothingFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 240px;
`;
