import { queryCache, useQuery } from 'react-query';

import { fetchUserOpenCurrencyBalances } from 'data-provider/user/fetchUserOpenCurrencyBalances';

export function useUserOpenCurrencyBalances(accountAddress: string) {
  const { data, isLoading, error } = useQuery(
    ['oms-user-open-currency-balances', accountAddress],
    () => fetchUserOpenCurrencyBalances(accountAddress),
    {
      enabled: accountAddress,
    },
  );
  const refetchBalance = () => {
    queryCache.refetchQueries(['oms-user-open-currency-balances']);
  };

  const getOpenRFQAmountByScCcyTypeId = (scCcyTokenId: number) => {
    const openRFQAmounts = data?.openRFQAmounts ?? [];
    return openRFQAmounts?.length > 0
      ? openRFQAmounts?.find((asset: { scId: number }) => asset.scId === scCcyTokenId)?.assetOpenBalance ?? 0
      : 0;
  };

  const getOpenOrderAmountByScCcyTypeId = (scCcyTokenId: number) => {
    const openOrderAmounts = data?.openOrderAmounts ?? [];
    return openOrderAmounts?.length > 0
      ? openOrderAmounts?.find((asset: { scId: number }) => asset.scId === scCcyTokenId)?.assetOpenBalance ?? 0
      : 0;
  };

  const getPendingTransactionAmountByScCcyTypeId = (scCcyTokenId: number) => {
    const pendingTransactionAmounts = data?.pendingTransactionAmounts ?? [];
    return pendingTransactionAmounts?.length > 0
      ? pendingTransactionAmounts?.find((asset: { scId: number }) => asset.scId === scCcyTokenId)?.assetOpenBalance ?? 0
      : 0;
  };

  return {
    error,
    isLoadingOpenCurrencyBalance: isLoading,
    openCurrencyBalance: data,
    refetchOpenCurrencyBalance: refetchBalance,
    selectors: {
      getOpenRFQAmount: (scCcyTokenId: number) =>
        data?.openRFQAmounts.find((asset: { scId: number }) => asset.scId === scCcyTokenId),
      getOpenOrderAmount: (scCcyTokenId: number) =>
        data?.openOrderAmounts.find((asset: { scId: number }) => asset.scId === scCcyTokenId),
      getPendingTransactionAmount: (scCcyTokenId: number) =>
        data?.pendingTransactionAmounts.find((asset: { scId: number }) => asset.scId === scCcyTokenId),
      getOpenBalances: (scCcyTokenId: number) =>
        getOpenRFQAmountByScCcyTypeId(scCcyTokenId) +
        getOpenOrderAmountByScCcyTypeId(scCcyTokenId) +
        getPendingTransactionAmountByScCcyTypeId(scCcyTokenId),
    },
  };
}
