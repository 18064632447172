import type { ReactNode } from 'react';
import { Colors, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Text, TextAs, TextColor, TypographyVariant, Tooltip, Icon, IconName, Card } from '@aircarbon/ui';

export type SummaryCardProps = {
  title: string;
  icon?: ReactNode;
  tooltip?: string;
  value: string | ReactNode;
  secondaryValue?: string;
  secondaryValueColor?: TextColor;
  secondaryTooltip?: string;
};

export const IconContainer = styled.div`
  position: relative;
  width: 66px;
  height: 66px;
  background: var(${Colors.summaryIconsBackground});
  border-radius: 66px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(${Colors.summaryIconsIcons});
  font-size: 2.25rem;
`;

export const SummaryCard = ({
  title,
  tooltip,
  value,
  icon,
  secondaryValue,
  secondaryValueColor,
  secondaryTooltip,
}: SummaryCardProps) => {
  return (
    <Card>
      <StyledSummary>
        {icon && <IconContainer>{icon}</IconContainer>}
        <div className="info">
          <StyledTitle>
            <Text color={TextColor.secondary} as={TextAs.span}>
              {title}
            </Text>
            {tooltip && (
              <Tooltip value={tooltip}>
                <Icon color={TextColor.secondary} name={IconName.InfoCircleOutlined} />
              </Tooltip>
            )}
          </StyledTitle>
          <Text variant={TypographyVariant.h5Title}>{value || '-'}</Text>
          {secondaryValue && (
            <Text variant={TypographyVariant.body2} color={secondaryValueColor || TextColor.secondary}>
              {secondaryTooltip ? <Tooltip value={secondaryTooltip}>{secondaryValue}</Tooltip> : secondaryValue}
            </Text>
          )}
        </div>
      </StyledSummary>
    </Card>
  );
};

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  gap: var(${Spacing.xs});
`;

const StyledSummary = styled.div`
  display: flex;
  gap: 16px;
  padding: var(${Spacing.large});
`;
