import { queryCache, useQuery } from 'react-query';

import { fetchUserOpenCurrencyBalance } from 'data-provider/user/fetchUserOpenCurrencyBalance';

export function useUserOpenCurrencyBalance({
  assetId,
  accountAddress,
  ccyTypeId = 1,
}: {
  assetId: number;
  accountAddress: string;
  ccyTypeId: number;
}) {
  const { data, isLoading, error } = useQuery(
    ['oms-user-open-currency-balance', assetId, accountAddress, ccyTypeId],
    () =>
      fetchUserOpenCurrencyBalance({
        assetId,
        accountAddress,
        ccyTypeId,
      }),
    {
      enabled: assetId && accountAddress,
    },
  );
  const refetchBalance = () => {
    queryCache.refetchQueries(['oms-user-open-currency-balance', assetId && accountAddress]);
  };

  return {
    error,
    isLoadingOpenCurrencyBalance: isLoading,
    openCurrencyBalance: data,
    refetchOpenCurrencyBalance: refetchBalance,
    selectors: {
      getOpenRFQAmount: () => data?.openRFQAmount ?? 0,
      getOpenOrderAmount: () => data?.openOrderAmount ?? 0,
      getPendingTransactionAmount: () => data?.pendingTransactionAmount ?? 0,
      getTotalOpenBalance: () =>
        data !== undefined ? data?.openRFQAmount + data?.openOrderAmount + data?.pendingTransactionAmount : 0,
    },
  };
}
