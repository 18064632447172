import { useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'refreshed-component/atoms/Button';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Colors, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import {
  type PageTab,
  type UrlInfo,
  generatePath,
  handleBooleanProp,
  matchUrlAndQuery,
} from 'refreshed-component/templates/ContentHolder';
import styled from 'styled-components';

import {
  Avatar,
  ButtonSize,
  ButtonVariant,
  IconName,
  Knob,
  Text,
  TypographyVariant,
  useLayerBackground,
} from '@aircarbon/ui';
import { AssetCategory } from '@aircarbon/utils-common';

import { useNotifications } from 'pages/account/trading/hooks';

import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';
import useQueryParams from 'hooks/useQueryParams';

import { NotificationsOverlay } from '../Notifications';
import { ProductDropdown } from '../ProductDropdown';
import { ProfileMenu } from '../ProfileMenu';
import { TransactionsOverlay } from '../Transactions';
import { ActionPopover, type ActionPopoverRef } from './components/ActionPopover';

export type HeaderProps = {
  tabs?: PageTab[];
  name?: string;
  products: Array<AssetCategory>;
  onExpand?: () => void;
};

const HeaderRoot = styled.div<{
  background: string;
}>`
  height: auto;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background: ${({ background }) => background};
  > .item {
    height: 60px;
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid var(${Colors.borderNormal});
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: var(${Spacing.large});
    padding-right: var(${Spacing.large});
    padding-top: var(${Spacing.xs});
    padding-bottom: var(${Spacing.xs});
    &:last-child {
      border-bottom: 0px;
    }
    > .navigation-bar {
      display: flex;
      flex-direction: row;
      position: relative;
      justify-content: center;
      align-items: center;
      gap: var(${Spacing.base});
      > .name {
        white-space: pre;
        color: var(${Colors.gray_900});
      }
      > .option {
        cursor: pointer;
        height: 100%;
        padding-left: var(${Spacing.small});
        padding-right: var(${Spacing.small});
        position: relative;
        color: var(${Colors.gray_500});
        white-space: pre;
        text-overflow: ellipsis;
        &.selected {
          color: var(${Colors.gray_900});
          font-weight: var(${FontWeight.semibold});
        }
        > .selection-indicator {
          position: absolute;
          height: 2px;
          width: 100%;
          align-self: stretch;
          background-color: var(${Colors.gray_900});
          left: 0;
          bottom: -20px;
        }
      }
    }
    > .controls {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: var(${Spacing.xs});
      height: 100%;
      border-left: 1px solid var(${Colors.gray_200});
      padding-left: var(${Spacing.xs});
      > .item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: auto;
        min-width: 36px;
        height: 36px;
        border-radius: var(${Radius.medium});
        color: var(${Colors.gray_500});
        background-color: transparent;
        svg * {
          fill: var(${Colors.headerItemsContentDefault});
        }
        . > wrapper {
        }
        &:hover {
          background-color: var(${Colors.headerItemsSurfaceHover});
          color: var(${Colors.headerItemsContentHover});
          cursor: pointer;
          svg * {
            fill: var(${Colors.headerItemsContentHover});
          }
        }
        &.open {
          background-color: var(${Colors.headerItemsSurfaceSelect});
          color: var(${Colors.headerItemsContentHover});
          cursor: pointer;
          svg * {
            fill: var(${Colors.headerItemsContentSelect});
          }
          > .wrapper > .profile {
            background-color: transparent;
            color: var(${Colors.headerItemsContentSelect});
          }
        }
      }
    }
  }
`;

const productsMap: Partial<
  Record<
    AssetCategory,
    {
      id: AssetCategory;
      label: string;
      description: string;
      icon: IconType;
    }
  >
> = {
  [AssetCategory.token]: {
    id: AssetCategory.token,
    label: 'Carbon',
    description: 'Issued Carbon Credits',
    icon: IconType.Retire,
  },
  [AssetCategory.fct]: {
    id: AssetCategory.fct,
    label: 'FCT',
    description: 'Pre-issued Carbon Credits',
    icon: IconType.CarbonCredits,
  },
  [AssetCategory.rec]: {
    id: AssetCategory.rec,
    label: 'REC',
    description: 'Renewable Energy Certificates',
    icon: IconType.REC,
  },
  [AssetCategory.biofuel]: {
    id: AssetCategory.biofuel,
    label: 'ACXBiofuels',
    description: 'Biofuels Order Matching',
    icon: IconType.Drop,
  },
};

export const Header: React.FC<HeaderProps> = (props) => {
  const { layerBackground } = useLayerBackground();
  const location = useLocation();
  const { product, changeProduct } = useMarketplaceProduct(props.products);
  const { entity } = Entity.useContainer();
  const queryParams = useQueryParams();
  const history = useHistory();
  const locationInfo: UrlInfo = {
    path: location.pathname,
    queryParams,
  };
  const {
    selector: { getUserProfile },
  } = User.useContainer();
  const { screenSize } = UI.useContainer();
  const {
    selector: { unreadCounter },
  } = useNotifications('All', '');
  const notificationsPopoverRef = useRef<ActionPopoverRef>(null);

  const user = getUserProfile();
  const isProductDropdownVisible = props.products.length > 1;

  const productDropdownItems = useMemo(() => {
    return props.products.map((product) => ({
      ...productsMap[product],
      id: String(productsMap[product]?.id),
    })) as Array<{ id: string; label: string; description: string; icon: IconType }>;
  }, [props.products]);

  const tabs = () => {
    return (
      <>
        {props.tabs
          ?.filter((tab) => (tab.isDisabled ? !handleBooleanProp(tab.isDisabled) : tab))
          ?.map((tab) => {
            const isMatch = tab.path ? matchUrlAndQuery(tab, locationInfo) : false;
            const onClick = () => tab.path && history.push(generatePath(tab.path, tab.query));
            if (tab.isSelected ? tab.isSelected?.() : isMatch) {
              return (
                <div key={tab.title} onClick={onClick} className="option selected">
                  {tab.title}
                  <div className="selection-indicator" />
                </div>
              );
            }
            return (
              <div key={tab.title} onClick={onClick} className="option">
                {tab.title}
              </div>
            );
          })}
      </>
    );
  };

  const controls = () => {
    return (
      <div className="controls">
        {entity.config?.helpEnable && (
          <Knob
            size={ButtonSize.s}
            variant={ButtonVariant.ghost}
            icon={IconName.QuestionMarkCircle}
            onPress={() => {
              var clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false,
              });
              const tFrame = document.getElementById('jsd-widget') as HTMLIFrameElement;
              const doc = (tFrame as any).contentWindow.document as Document;
              tFrame.classList.add('enable');
              doc.addEventListener('click', (event) => {
                const target = event.target as HTMLHtmlElement;
                if (target.className === 'close-icon fit') {
                  tFrame.classList.remove('enable');
                }
              });
              const button = doc.getElementById('help-button');
              button?.dispatchEvent(clickEvent);
            }}
          />
        )}

        <ActionPopover icon={IconName.TransactionStatus}>
          <TransactionsOverlay />
        </ActionPopover>
        <ActionPopover ref={notificationsPopoverRef} icon={IconName.Bell} counter={unreadCounter}>
          <NotificationsOverlay count={unreadCounter} remove={() => notificationsPopoverRef.current?.hide()} />
        </ActionPopover>
        <ActionPopover
          icon={<Avatar initials={[user.first_name, user.last_name].map((str: string) => str[0]).join('')} />}
        >
          <ProfileMenu />
        </ActionPopover>
      </div>
    );
  };

  if (screenSize === 'small' || screenSize === 'medium') {
    return (
      <HeaderRoot background={layerBackground('layer')}>
        <div className="item">
          <div className="navigation-bar">
            <Knob icon={IconName.Menu} size={ButtonSize.s} variant={ButtonVariant.outlined} onPress={props.onExpand} />

            <div className="name">
              <Text variant={TypographyVariant.h5Title}>{props.name}</Text>
            </div>
          </div>
          {controls()}
        </div>
        {isProductDropdownVisible && (
          <div className="item">
            <ProductDropdown value={product} onChange={changeProduct} items={productDropdownItems} />
          </div>
        )}
        {props.tabs?.length && (
          <div className="item">
            <div className="navigation-bar">{tabs()}</div>
          </div>
        )}
      </HeaderRoot>
    );
  } else {
    return (
      <HeaderRoot background={layerBackground('layer')}>
        <div className="item">
          <div className="navigation-bar">
            <div className="name">
              <Text variant={TypographyVariant.h5Title}>{props.name}</Text>
            </div>
            {isProductDropdownVisible && (
              <ProductDropdown value={product} onChange={changeProduct} items={productDropdownItems} />
            )}
            {tabs()}
          </div>
          {controls()}
        </div>
      </HeaderRoot>
    );
  }
};
