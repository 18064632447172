import { forwardRef, useImperativeHandle, useState } from 'react';

import {
  Badge,
  BadgeSize,
  BadgeVariant,
  ButtonSize,
  ButtonVariant,
  type IconName,
  Knob,
  Popover,
  styled,
} from '@aircarbon/ui';

export interface ActionPopoverRef {
  hide(): void;
}

export const ActionPopover = forwardRef<
  ActionPopoverRef,
  React.PropsWithChildren<{
    counter?: number;
    icon: IconName | React.ReactElement;
  }>
>((props, ref) => {
  const { icon, counter, children } = props;

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  useImperativeHandle(ref, () => {
    return {
      hide() {
        setIsPopoverVisible(false);
      },
    };
  }, []);

  return (
    <Popover isVisible={isPopoverVisible} onHide={() => setIsPopoverVisible(false)} value={children}>
      <NotificationsWrapper>
        <Knob
          isActive={isPopoverVisible}
          size={ButtonSize.s}
          icon={icon}
          variant={ButtonVariant.ghost}
          onPress={() => setIsPopoverVisible(true)}
          counter={counter}
        />
      </NotificationsWrapper>
    </Popover>
  );
});

const NotificationsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
