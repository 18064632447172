import { Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

export const Select = styled.div<{
  isFocused: boolean;
}>`
  cursor: pointer;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 11px;
  border-start-start-radius: 8px;
  border-end-start-radius: 8px;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out, outline-color 200ms ease-in-out;
  &:hover {
    z-index: 1;
    ${({ isFocused }) => {
      switch (true) {
        case isFocused:
          return `
            border: 1px solid var(${Colors.gray_400});
            background: var(${Colors.gray_200});
          `;
        default:
          return `
            border: 1px solid var(${Colors.gray_300});
            background: var(${Colors.gray_100});
          `;
      }
    }}
  }
  ${({ isFocused }) => {
    switch (true) {
      case isFocused:
        return `
          z-index: 1;
          border: 1px solid var(${Colors.gray_400});
          background: var(${Colors.gray_200});
          outline: var(${Colors.gray_100}) solid 3px;
        `;
      default:
        return `
          border: 1px solid var(${Colors.gray_200});
          background: var(${Colors.gray_50});
          outline: var(${Colors.transparent}) solid 3px;
        `;
    }
  }}
`;
