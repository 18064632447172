import * as yup from 'yup';

import type { RetireBatchFormValue } from '../../RetireBatchForm';

const partialTonsError = 'The quantity cannot result in partial tons of underlyers';

interface ToValidationErrorsMapProps {
  assetsBalances: Array<{
    balanceChange: number;
    resultingBalance: number;
  }>;
  formValue: RetireBatchFormValue;
  resultingBalance?: number;
  maxQuantity: number;
}

export const toValidationErrorsMap = (props: ToValidationErrorsMapProps) => {
  const formValidationSchema = yup.object().shape({
    formValue: yup.object().shape({
      quantity: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .integer('The quantity cannot be in partial tons')
        .min(1000, 'The quantity should be more than or equal 1,000')
        .required('Please provide quantity'),
      retirementReason: yup.string().required(),
      offsettingPeriod: yup.object().shape({
        from: yup.date().required('Please provide offsetting period'),
        to: yup.date().required('Please provide offsetting period'),
      }),
    }),
    assetsBalances: yup.array().of(
      yup.object().shape({
        balanceChange: yup.number().integer(partialTonsError),
        resultingBalance: yup
          .number()
          .min(0, "You don't have sufficient amount of assets to proceed")
          .integer(partialTonsError),
      }),
    ),
    resultingBalance: yup.number().integer().min(0, "You don't have sufficient GER balance"),
  });
  try {
    formValidationSchema.validateSync(props, {
      abortEarly: false,
    });

    return null;
  } catch (e) {
    return (e as yup.ValidationError).inner.reduce(
      (current, error) => ({
        ...current,
        [error.path as string]: error.errors[0],
      }),
      {} as Record<string, string>,
    );
  }
};
