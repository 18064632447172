import { userAuthenticatedApi } from 'data-provider/fetcher';

export interface FetchOtcCriteriaResponse {
  keys: Array<{
    code: string;
    name: string;
    __projectMetaOptions__?: Array<{
      code: string;
      metaValue: string;
      description: string;
    }>;
  }>;
  offerRequiredFields: Array<string>;
  bidByProjectsRequiredFields: Array<string>;
  bidByCriteriaRequiredFields: Array<string>;
  auctionRequiredFields: Array<string>;
}

export const fetchOtcCriteria = async (assetCategoryId: number): Promise<FetchOtcCriteriaResponse> => {
  const userService = await userAuthenticatedApi();
  const res = userService.url(`/user/otc/criteria-option?assetCategoryId=${assetCategoryId}`).get();

  const resJson: {
    data: FetchOtcCriteriaResponse;
  } = await res.json();

  return resJson.data; // TODO: use DTO instead of raw model response
};
