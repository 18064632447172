import { useMemo } from 'react';
import { ContentHolder, type MapItem } from 'refreshed-component/templates/ContentHolder';

import { IconName } from '@aircarbon/ui';
import { AssetCategory, Const } from '@aircarbon/utils-common';

import RequestForQuote from 'pages/account/RFQ';

import { UI } from 'state/ui';
import { User } from 'state/user';

import { BatchHistory, BatchHistoryType } from './BatchHistory';
import { Cash } from './Cash';
import { Dashboard } from './Dashboard';
import { NotificationsPage } from './Notifications';
import { Projects, ProjectsType } from './Projects';
import { MarketPlace as MarketPlaceAuction } from './auction/Marketplace';
import { MyBids as MyBidsAuction } from './auction/MyBids';
import { MyListings as MyListingsAuction } from './auction/MyListings';
import { CreateRequest } from './block-trade/create-request';
import { IncomingRequests } from './block-trade/incoming-requests';
import { OutgoingRequests } from './block-trade/outgoing-requests';
import { BankDetails } from './compnay-settings/BankDetails';
import { ExternalClients } from './compnay-settings/ExternalClients';
import { ManageUser } from './compnay-settings/ManageUser';
import { Exchange } from './exchange';
import { Ger } from './ger';
import { GERHistory } from './ger/components/GERHistory';
import { MarketBoardV2 } from './market-board-v2';
import { MarketBoardV2ListingsPage } from './market-board-v2/components/ListingsPage';
import { MarketBoardV2PlaceOrderPage } from './market-board-v2/components/PlaceOrderPage';
import { MarketPlace as MarketPlaceMarketBoard } from './market-board/Marketplace';
import { MyBids as MyBidsMarketBoard } from './market-board/MyBids';
import { MyListings as MyListingsMarketBoard } from './market-board/MyListings';
import { ProjectExchange } from './project-exchange';
import { Assets } from './reports/Asset';
import { CurrentBalance } from './reports/CurrentBalance';
import { MarketBoardOrder } from './reports/MarketBoardOrders';
import { SpotOrders } from './reports/SpotOrders';
import { Trades } from './reports/Trades';
import { Transfers } from './reports/Transfers';
import Settlement from './settlement';

export const RootHolder = () => {
  const {
    status: {
      canViewCorpHistory,
      isIndividual,
      canDeposit,
      canWithdraw,
      canRetireToken,
      canDeliverToken,
      canViewCmbAsk,
      canViewCmbAskV2,
      canViewAuction,
      canViewAuctionV2,
      canAccessGER,
      canSeeExternalBrokerClientForBlockTrade,
      canSeeExternalBrokerClientForBiofuel,
      canAccessReport,
      canManageUser,
      canViewRFQ,
      canViewBiofuel,
      canViewSpot,
      canAccessAPX,
      canTradeAPX,
      canManageAPX,
      canViewIncomingBlockRequest,
      canViewOutgoingBlockRequest,
      canCreateBlockRequest,
      canViewSettlement,
      canAccessSpotOrdersReport,
      canAccessTradesReport,
      canAccessCurrentBalanceReport,
      canAccessAssetsReport,
      canAccessTransfersReport,
      canAccessRecSpot,
      canAccessRecMarketBoardAndAuctions,
      canAccessRecMarketBoardAndAuctionsV2,
      canAccessRecBlockTrade,
      canAccessRecSettle,
    },
  } = User.useContainer();
  const { getSetting } = UI.useContainer();

  const webEnableAuction = Number(getSetting('web_settings_enable_auction') || 0);
  const spotLabel = getSetting('web_settings_spot_label') ?? 'Spot';
  const cmbOfferLabel = getSetting('web_settings_cmb_offer_label') ?? 'Market Board';
  const blockTradeLabel = getSetting('web_settings_block_trade_label') ?? 'Block Trades';
  const isMarketBoardAndAuctionsV2Enabled =
    getSetting(Const.FeatureToggle.MarketBoardAndAuctionsV2) === '1' && canViewCmbAskV2();
  const isMarketBoardAndAuctionsV2SettlementModeEnabled =
    getSetting(Const.FeatureToggle.MarketBoardAndAuctionsV2SettlementMode) === '1';

  const isGERDisabled = !canAccessGER();
  const isCashDisabled = !(canDeposit() || canWithdraw());
  const isDeliveryDisabled = !canDeliverToken();
  const isRetirementDisabled = !canRetireToken();
  const isSpotExchangeEnabled = canViewSpot();
  const isAPXEnabled = canAccessAPX();
  const isRecSpotEnabled = canAccessRecSpot();
  const isRecMarketBoardAndAuctionsEnabled = canAccessRecMarketBoardAndAuctions();
  const isRecMarketBoardAndAuctionsV2Enabled = canAccessRecMarketBoardAndAuctionsV2();
  const isRecBlockTradeEnabled = canAccessRecBlockTrade();
  const isRecSettleEnabled = canAccessRecSettle();
  const isCmbAskDisabled = !canViewCmbAsk();
  const isCmbAskV2Enabled = canViewCmbAskV2();
  const isAuctionsDisabled = !(webEnableAuction && canViewAuction());
  const isBlockTradesDisabled = !(
    canViewIncomingBlockRequest() ||
    canViewOutgoingBlockRequest() ||
    canCreateBlockRequest()
  );
  const isSettlementDisabled = !canViewSettlement();
  const isIncomingRequestsDisabled = !canViewIncomingBlockRequest();
  const isOutgoingRequestsDisabled = !canViewOutgoingBlockRequest();
  const isCreateRequestDisabled = !canCreateBlockRequest();
  const isCarbonFinderDisabled = !canViewRFQ();
  const isACXBiofuelsDisabled = !canViewBiofuel();
  const isAPXMarketplaceDisabled = !canTradeAPX();
  const isAPXMyProjectsDisabled = !canManageAPX();
  const isReportsDisabled = !canAccessReport();
  const isBankDetailsDisabled = !(canDeposit() || canWithdraw());
  const isExternalClientsDisabled = !(
    canSeeExternalBrokerClientForBlockTrade() || canSeeExternalBrokerClientForBiofuel()
  );
  const isManageUsersDisabled = !canManageUser();
  const isCompanySettingsDisabled = !(
    canSeeExternalBrokerClientForBlockTrade() ||
    canSeeExternalBrokerClientForBiofuel() ||
    canManageUser() ||
    canDeposit() ||
    canWithdraw()
  );
  const isTransfersReportDisabled = !canViewCorpHistory() || !canAccessTransfersReport();
  const isAssetsReportDisabled = !(canViewCorpHistory() || isIndividual()) || !canAccessAssetsReport();
  const isCurrentBalanceReportDisabled = !(canViewCorpHistory() || isIndividual()) || !canAccessCurrentBalanceReport();
  const isSpotOrdersReportDisabled = !canAccessSpotOrdersReport();
  const isTradesReportDisabled = !canAccessTradesReport();

  const isAllExchangeFeaturesDisabled = !(isSpotExchangeEnabled || isAPXEnabled || isRecSpotEnabled);
  const isAuctionsV2Enabled = getSetting(Const.FeatureToggle.AuctionV2) === '1' && canViewAuctionV2();

  const marketBoardAndAuctionV2Label =
    isMarketBoardAndAuctionsV2Enabled && isAuctionsV2Enabled ? 'MB and Auctions' : 'Market Board';

  const contentHolderConfig = useMemo<MapItem[]>(
    () => [
      {
        sideBarConfig: { icon: IconName.ChartPie, name: 'Assets' },
        type: 'list',
        list: [
          {
            path: '/account/balances',
            title: 'Balances',
            component: <Dashboard />,
          },
          {
            title: 'Projects',
            path: '/account/projects',
            component: <Projects type={ProjectsType.Project} />,
          },
          {
            title: 'GER',
            isDisabled: isGERDisabled,
            component: <Ger />,
            path: '/account/assets/ger/*',
            tabs: [
              {
                title: 'Dashboard',
                path: '/account/assets/ger',
              },
              {
                title: 'History',
                path: '/account/assets/ger/history',
                component: <GERHistory />,
              },
            ],
          },
          {
            title: 'Cash',
            path: '/account/cash',
            isDisabled: isCashDisabled,
            component: <Cash />,
          },
          {
            title: 'Delivery',
            path: '/account/deliver',
            isDisabled: isDeliveryDisabled,
            tabs: [
              {
                title: 'Projects',
                path: '/account/deliver',
                component: <Projects type={ProjectsType.Deliver} />,
              },
              {
                title: 'History',
                path: '/account/deliver/history',
                component: <BatchHistory type={BatchHistoryType.Deliver} />,
              },
            ],
          },
          {
            title: 'Retirement',
            path: '/account/retire',
            isDisabled: isRetirementDisabled,
            tabs: [
              {
                title: 'Projects',
                path: '/account/retire',
                component: <Projects type={ProjectsType.Retire} />,
              },
              {
                title: 'History',
                path: '/account/retire/history',
                component: <BatchHistory type={BatchHistoryType.Retire} />,
              },
            ],
          },
        ],
      },
      {
        type: 'sidebar-separator',
      },
      {
        sideBarConfig: { icon: IconName.SpotExchange, name: spotLabel },
        type: 'link',
        page: {
          title: 'Exchange',
          path: `/account/trade`,
          isExpandable: false,
          isExpanded: false,
          isDisabled: isAllExchangeFeaturesDisabled,
          availableProducts: [
            ...(isSpotExchangeEnabled ? [AssetCategory.token] : []),
            ...(isAPXEnabled ? [AssetCategory.fct] : []),
            ...(isRecSpotEnabled ? [AssetCategory.rec] : []),
          ],
          component: <Exchange />,
        },
      },
      {
        sideBarConfig: { icon: IconName.DocumentReport, name: cmbOfferLabel },
        type: 'link',
        page: {
          title: cmbOfferLabel,
          path: '/account/market-board',
          isDisabled: isCmbAskDisabled,
          availableProducts: [AssetCategory.token, ...(isRecMarketBoardAndAuctionsEnabled ? [AssetCategory.rec] : [])],
          tabs: [
            {
              title: 'Marketplace',
              path: '/account/market-board/marketplace',
              component: <MarketPlaceMarketBoard />,
            },
            {
              title: 'My Bids',
              path: '/account/market-board/my-bids',
              component: <MyBidsMarketBoard />,
            },
            {
              title: 'My Listings',
              path: '/account/market-board/my-listings/*',
              component: <MyListingsMarketBoard />,
            },
          ],
        },
      },
      {
        sideBarConfig: { icon: IconName.DocumentReport, name: marketBoardAndAuctionV2Label },
        type: 'link',
        page: {
          title: marketBoardAndAuctionV2Label,
          path: '/account/mb-and-auctions',
          isDisabled: !isMarketBoardAndAuctionsV2Enabled || (!isCmbAskV2Enabled && !isAuctionsV2Enabled),
          component: <MarketBoardV2ListingsPage />,
          availableProducts: [
            AssetCategory.token,
            ...(isRecMarketBoardAndAuctionsV2Enabled ? [AssetCategory.rec] : []),
          ],
        },
      },
      {
        sideBarConfig: { icon: IconName.Auction, name: 'Auctions' },
        type: 'link',
        page: {
          title: 'Auctions',
          isDisabled: isAuctionsDisabled,
          path: '/account/auctions',
          availableProducts: [
            AssetCategory.token,
            ...(isAPXEnabled ? [AssetCategory.fct] : []),
            ...(isRecMarketBoardAndAuctionsEnabled ? [AssetCategory.rec] : []),
          ],
          tabs: [
            {
              title: 'Marketplace',
              path: '/account/auctions/marketplace',
              component: <MarketPlaceAuction />,
            },
            {
              title: 'My Bids',
              path: '/account/auctions/my-bids',
              component: <MyBidsAuction />,
            },
            {
              title: 'My Listings',
              path: '/account/auctions/my-listings/*',
              component: <MyListingsAuction />,
            },
          ],
        },
      },
      {
        sideBarConfig: { icon: IconName.Suitcase, name: blockTradeLabel },
        type: 'link',
        page: {
          title: blockTradeLabel,
          path: '/account/block-trade/*',
          isDisabled: isBlockTradesDisabled,
          availableProducts: [AssetCategory.token, ...(isRecBlockTradeEnabled ? [AssetCategory.rec] : [])],
          tabs: [
            {
              title: 'Incoming Requests',
              path: '/account/block-trade/incoming-requests',
              component: <IncomingRequests />,
              isDisabled: isIncomingRequestsDisabled,
            },
            {
              title: 'Outgoing Requests',
              path: '/account/block-trade/outgoing-requests',
              component: <OutgoingRequests />,
              isDisabled: isOutgoingRequestsDisabled,
            },
            {
              title: 'Create Request',
              path: '/account/block-trade/create-request/*',
              component: <CreateRequest />,
              isDisabled: isCreateRequestDisabled,
            },
          ],
        },
      },
      {
        sideBarConfig: { icon: IconName.Suitcase, name: 'ACXSettle' },
        type: 'link',
        page: {
          title: 'ACXSettle',
          path: '/account/settlement/',
          isDisabled: isSettlementDisabled,
          availableProducts: [AssetCategory.token, ...(isRecSettleEnabled ? [AssetCategory.rec] : [])],
          component: <Settlement />,
        },
      },
      {
        type: 'link',
        sideBarConfig: { icon: IconName.DocumentReport, name: 'Carbon Finder' },
        page: {
          path: '/account/carbon-finder/*',
          title: 'Carbon Finder',
          isDisabled: isCarbonFinderDisabled,
          component: <RequestForQuote />,
        },
      },
      {
        sideBarConfig: { icon: IconName.Drop, name: 'ACXBiofuels' },
        type: 'link',
        page: {
          title: 'ACXBiofuels',
          isExpandable: false,
          isExpanded: false,
          path: '/account/trade-biofuel',
          isDisabled: isACXBiofuelsDisabled,
          availableProducts: [AssetCategory.biofuel],
          component: <Exchange />,
        },
      },
      {
        type: 'sidebar-separator',
        isDisabled: !isAPXEnabled,
      },
      {
        sideBarConfig: { icon: IconName.CarbonCredits, name: 'Project Exchange' },
        type: 'link',
        isDisabled: !isAPXEnabled,
        page: {
          title: 'Project Exchange',
          path: '/account/apx/*',
          component: <ProjectExchange />,
          tabs: [
            {
              title: 'Marketplace',
              path: '/account/apx/marketplace/*',
              component: <ProjectExchange />,
              isDisabled: isAPXMarketplaceDisabled,
            },
            {
              title: 'My projects',
              path: '/account/apx/my-projects/*',
              component: <ProjectExchange />,
              isDisabled: isAPXMyProjectsDisabled,
            },
          ],
        },
      },
      {
        type: 'sidebar-separator',
        isDisabled: isReportsDisabled,
      },
      {
        sideBarConfig: { icon: IconName.ChartBar, name: 'Reports' },
        type: 'list',
        isDisabled: isReportsDisabled,
        list: [
          {
            title: 'Report',
            name: 'Current Balance',
            path: '/account/reports/balance',
            isDisabled: isCurrentBalanceReportDisabled,
            component: <CurrentBalance />,
          },
          {
            title: 'Report',
            name: 'Assets',
            path: '/account/reports/assets',
            isDisabled: isAssetsReportDisabled,
            component: <Assets />,
          },
          {
            title: 'Report',
            name: `${spotLabel} Orders`,
            path: '/account/reports/spot-orders',
            isDisabled: isSpotOrdersReportDisabled,
            component: <SpotOrders />,
          },
          {
            title: 'Report',
            name: 'Trades',
            path: '/account/reports/trades',
            isDisabled: isTradesReportDisabled,
            component: <Trades />,
          },
          {
            title: 'Report',
            name: 'MB Orders',
            path: '/account/reports/mb-orders',
            isDisabled: !isMarketBoardAndAuctionsV2Enabled || !isMarketBoardAndAuctionsV2SettlementModeEnabled,
            component: <MarketBoardOrder />,
          },
          {
            title: 'Report',
            name: 'Users Transfers',
            path: '/account/reports/transfers',
            isDisabled: isTransfersReportDisabled,
            component: <Transfers />,
          },
        ],
      },
      {
        type: 'link',
        page: {
          title: 'Company Settings',
          path: '/account/company-settings',
          isDisabled: isCompanySettingsDisabled,
          tabs: [
            {
              title: 'Manage Users',
              path: '/account/company-settings/manage-user',
              component: <ManageUser />,
              isDisabled: isManageUsersDisabled,
            },
            {
              title: 'External Clients',
              path: '/account/company-settings/external-clients',
              isDisabled: isExternalClientsDisabled,
              component: <ExternalClients />,
            },
            {
              title: 'Bank Details',
              path: '/account/company-settings/bank-details',
              isDisabled: isBankDetailsDisabled,
              component: <BankDetails />,
            },
          ],
        },
      },
      {
        type: 'link',
        page: {
          title: 'Notifications',
          component: <NotificationsPage />,
          path: '/account/notifications',
        },
      },
      {
        type: 'link',
        page: {
          title: marketBoardAndAuctionV2Label,
          path: '/account/mb-and-auctions/place-order',
          availableProducts: [
            AssetCategory.token,
            ...(isRecMarketBoardAndAuctionsV2Enabled ? [AssetCategory.rec] : []),
          ],
          isDisabled: !isMarketBoardAndAuctionsV2Enabled,
          component: <MarketBoardV2PlaceOrderPage />,
        },
      },
      {
        type: 'link',
        page: {
          title: marketBoardAndAuctionV2Label,
          path: '/account/mb-and-auctions/*',
          isDisabled: !isMarketBoardAndAuctionsV2Enabled || (!isCmbAskV2Enabled && !isAuctionsV2Enabled),
          component: <MarketBoardV2 />,
        },
      },
      {
        type: 'link',
        page: {
          title: 'ACXSettle',
          path: '/account/settlement/create',
          isDisabled: isSettlementDisabled,
          component: <Settlement />,
          availableProducts: [AssetCategory.token, ...(isRecSettleEnabled ? [AssetCategory.rec] : [])],
        },
      },
      {
        type: 'link',
        page: {
          title: 'ACXSettle',
          path: '/account/settlement/*',
          isDisabled: isSettlementDisabled,
          component: <Settlement />,
        },
      },
    ],
    [
      isGERDisabled,
      isCashDisabled,
      isDeliveryDisabled,
      isRetirementDisabled,
      spotLabel,
      isAllExchangeFeaturesDisabled,
      isSpotExchangeEnabled,
      isAPXEnabled,
      isRecSpotEnabled,
      cmbOfferLabel,
      isCmbAskDisabled,
      isRecMarketBoardAndAuctionsEnabled,
      marketBoardAndAuctionV2Label,
      isMarketBoardAndAuctionsV2Enabled,
      isCmbAskV2Enabled,
      isAuctionsV2Enabled,
      isRecMarketBoardAndAuctionsV2Enabled,
      isAuctionsDisabled,
      blockTradeLabel,
      isBlockTradesDisabled,
      isRecBlockTradeEnabled,
      isIncomingRequestsDisabled,
      isOutgoingRequestsDisabled,
      isCreateRequestDisabled,
      isSettlementDisabled,
      isRecSettleEnabled,
      isCarbonFinderDisabled,
      isACXBiofuelsDisabled,
      isAPXMarketplaceDisabled,
      isAPXMyProjectsDisabled,
      isReportsDisabled,
      isCurrentBalanceReportDisabled,
      isAssetsReportDisabled,
      isSpotOrdersReportDisabled,
      isTradesReportDisabled,
      isMarketBoardAndAuctionsV2SettlementModeEnabled,
      isTransfersReportDisabled,
      isCompanySettingsDisabled,
      isManageUsersDisabled,
      isExternalClientsDisabled,
      isBankDetailsDisabled,
    ],
  );
  return <ContentHolder config={contentHolderConfig} />;
};
