import { mutatePlaceOrder } from 'data-mutation/oms/mutatePlaceOrder';
import { useMutation } from 'react-query';

import { logger } from '@aircarbon/utils-common';

export function usePlaceOrder() {
  const [placeOrder, { isLoading, error, data }] = useMutation(async (order: Record<string, any>) => {
    try {
      return await mutatePlaceOrder(order);
    } catch (error) {
      logger.error(error);
      throw error;
    }
  });

  return {
    placeOrder,
    isLoading,
    data,
    error,
  };
}
