import { findGERAsset } from 'refreshed-pages/ger/utils/findGERAsset';
import { toGERAssetsAllocations } from 'refreshed-pages/ger/utils/toGERAssetsAllocations';

import { formatter } from '@aircarbon/utils-common';

import { Contract } from 'state/contract';
import { Entity } from 'state/entity';

import useAccountBalance from 'hooks/useAccountBalance';

type Batch = {
  label: string;
  batchId: number;
  quantity: number;
  assets: Array<{
    label: string;
    value: string;
  }>;
};

export const useBatches = (): {
  batches: Array<Batch>;
} => {
  const {
    selector: { mainCcyScId, mainCcySymbol },
  } = Entity.useContainer();
  const { selector } = useAccountBalance({
    ccyTypeId: mainCcyScId,
    ccySymbol: mainCcySymbol,
    getRootBalance: 'no',
    queryConfig: {
      refetchInterval: 3000,
    },
  });
  const { tokenTypes } = Contract.useContainer();
  const gerAsset = findGERAsset(tokenTypes);
  const gerBatches = selector.getGerBatches();
  const batches: Array<Batch> = [];

  gerBatches.forEach((batch) => {
    const quantity = formatter.hex2int(batch.currentQty) / (gerAsset?.uom?.scRatio ?? 1000);

    const assetAllocations = toGERAssetsAllocations({
      basketConfigurationJSON: gerAsset?.configuration as string,
      assets: tokenTypes,
      assetBalance: quantity,
    });
    batches.push({
      label: `Batch #${formatter.hex2int(batch.batchId)}`,
      batchId: formatter.hex2int(batch.batchId),
      quantity: quantity,
      assets: assetAllocations,
    });
  });

  return {
    batches,
  };
};
