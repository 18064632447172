import queryString from 'query-string';

import type { FiatHistoryResponse } from '@aircarbon/utils-common/src/dto/user';

import { userAuthenticatedApi } from '../fetcher';

export const fetchFiatHistory = async ({
  type,
  page,
  pageSize,
  statusQuery,
}: {
  type: string;
  page: number;
  pageSize: number;
  statusQuery?: string;
}) => {
  const qs = queryString.stringify(
    { page, limit: pageSize, status: [statusQuery] },
    {
      arrayFormat: 'bracket',
      skipEmptyString: true,
      skipNull: true,
    },
  );
  const endpoint = `/user/fiat/${type}?${qs}`;
  const userService = await userAuthenticatedApi();

  const res = userService.url(endpoint).get();
  return res.json<FiatHistoryResponse>();
};
