import { userAuthenticatedApi } from 'data-provider/fetcher';

export interface CreateProjectProps {
  registryId: number;
  registryProjectId: string;
  assetCategory: string;
  projectName: string;
}

export const createProject = async (
  props: CreateProjectProps,
): Promise<
  | {
      status: 'ok';
      data: {
        id: number;
        name: string;
        registryId: number;
        registryProjectId: string;
        __registry__: {
          id: number;
          registryName: string;
        };
      };
    }
  | {
      status: 'error';
      error: string;
    }
> => {
  const userService = await userAuthenticatedApi();

  try {
    const bodyJSON = JSON.stringify(props);

    const res = await userService
      .url(`/user/carbon/project`)
      .headers({
        'Content-Type': 'application/json',
      })
      .body(bodyJSON)
      .post()
      .res();

    if (res.status >= 300) {
      return {
        status: 'error',
        error: res.statusText ?? 'Something went wrong. Please try again',
      };
    }

    const resJson = await res.json();

    return {
      status: 'ok',
      data: resJson.project,
    };
  } catch (e) {
    const error = JSON.parse((e as Error).message);
    return {
      status: 'error',
      error: error.message ?? 'Something went wrong. Please try again',
    };
  }
};
