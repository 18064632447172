import { Text, TextColor, TypographyVariant } from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

import { Entity } from 'state/entity';

function FeeAmount({ amount, isPrimary }: { amount: number; isPrimary?: boolean }) {
  const {
    selector: { mainCcyCode, mainCcySymbol },
  } = Entity.useContainer();

  return (
    <Text variant={TypographyVariant.body2} color={isPrimary ? TextColor.primary : TextColor.secondary}>
      {mainCcyCode}
      {formatter.formatCurrency(amount, 2)} {mainCcySymbol}
    </Text>
  );
}

export default FeeAmount;
