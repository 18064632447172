import { useQuery } from 'react-query';

import type { CommonInterfaces } from '@aircarbon/utils-common';

import { fetchAddressesData } from 'data-provider/user/fetchAddressesData';

function useAddressesData({ addresses, options = {} }: { addresses: string[]; options?: Record<string, any> }) {
  const {
    data: addressesData = [],
    isLoading,
    error,
    refetch,
  } = useQuery<CommonInterfaces.AddressData[]>(
    ['contract/addresses-data', addresses],
    () => fetchAddressesData(addresses),
    {
      ...options,
      refetchOnWindowFocus: false,
    },
  );

  const accountUsersData = () => {
    const data = (addressesData?.length ? addressesData : []).map((addressData: Record<string, any>) => ({
      address: addressData.address,
      tokens: addressData.account.tokens.map((token: Record<string, any>) => ({
        tokTypeId: token.tokTypeId,
        currentQty: token.currentQty,
        batchId: token.batchId,
        stId: token.stId,
      })),
      ccys: addressData.account.ccys.map((ccy: Record<string, any>) => ({
        unit: ccy.unit,
        balance: ccy.balance,
        ccyTypeId: ccy.ccyTypeId,
        name: ccy.name,
      })),
      batches: addressData.batches,
    }));

    return data;
  };

  return {
    addressesData,
    accountUsersData,
    isLoading,
    error,
    refetch,
  };
}

export default useAddressesData;
