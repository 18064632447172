import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Cleave from 'cleave.js/react';
import { format } from 'date-fns';
import { Heading } from 'grommet';
import { CheckBox } from 'grommet';
import moment from 'moment';
import React, { useEffect } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useForm, Controller } from 'react-hook-form';
import { FaCalendar } from 'react-icons/fa';
import styled from 'styled-components';
import * as Yup from 'yup';

import { formatter } from '@aircarbon/utils-common';

import FormDevTool from 'components/FormDevTool';
import Button from 'components/styled/Button';
import { SelectWrapper } from 'components/styled/Styled';
import { CheckBoxWrapperWithThemeColors, InputWrapperWithThemeColors } from 'components/styled/Styled';

import { Entity } from 'state/entity';
import { type ScreenSizes, UI } from 'state/ui';

import useRetirementOptions from 'hooks/useRetirementOptions';

import { convertTextNumberToValue } from 'utils/helpers';

import { type ThemeColors, Themes } from '../trading/components/Themes';
import PreviewBasket from './PreviewBasket';
import { InputControlWrapper, InputControlButton } from './Styled';
import useBasketQuantity from './useBasketQuantity';

const Wrapper = styled.div<{ screenSize: ScreenSizes }>`
  ${(props) => {
    if (props.screenSize === 'small' || props.screenSize === 'medium') {
      return 'width: 100%; padding: 16px;';
    }
    return `width: 700px;`;
  }}
  margin: 0 auto;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 1rem;
  width: 100%;

  label {
    margin: 0 !important;
  }
`;

export const InputLabel = styled.label`
  display: block;
  font-size: 0.75rem;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 1;
  font-weight: 600;
`;

export const TextArea = styled.textarea<{ themeColors: ThemeColors }>`
  border: 1px solid ${({ themeColors }) => themeColors.dashboard.form.input.border};
  background: ${({ themeColors }) => themeColors.dashboard.form.input.background};
  padding: 16px;
  height: 120px;
  display: inline-flex;
  width: 100%;
  color: #222;
  border-radius: 5px;

  &:-internal-autofill-selected,
  &:-webkit-autofill:focus,
  &:-webkit-autofill {
    background-color: ${({ themeColors }) => themeColors.dashboard.deepBackground} !important;
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }

  &:focus {
    outline: none;
    border-color: ${(props) => `rgba(${props.theme.brandRbgColor},0.9)`};
  }

  &::placeholder {
    font-weight: 300;
    letter-spacing: 0.5px;
    color: ${({ themeColors }) => themeColors.dashboard.form.input.placeholder};
  }
`;

const CalendarWrapper = styled.div`
  > div {
    width: 100%;
    padding-right: 0 !important;
    > button {
      width: 100%;
      padding: 12px;
      border: 0px;
      color: inherit !important;
    }
  }
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  background: transparent;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 5px;
  color: #626262;
  &:hover {
    border-color: rgb(${(props) => props.theme.brandRbgColor});
  }
`;

const SCOPES: Array<number> = [1, 2, 3];

const schema = (
  basketTypes: Array<{
    percentage: number;
    tokenTypeId: number;
  }>,
) =>
  Yup.object().shape({
    tokTypeId: Yup.number().required(),
    minQty: Yup.number().required(),
    maxQty: Yup.number().required(),
    qtyUnit: Yup.number()
      .transform(function transformQtyUnit(value, originalValue) {
        if (this.isType(value)) return value;

        return convertTextNumberToValue(originalValue);
      })
      .required('Required')
      .min(Yup.ref('minQty'))
      .max(Yup.ref('maxQty'))
      .test(
        'qtyUnitByTon',
        'Invalid quantity, please check quantity input below',
        function validateFormat(val: any): boolean {
          const qty = convertTextNumberToValue(val);
          return !basketTypes.some((item) => !Number.isInteger((qty * item.percentage) / 100));
        },
      ),
    retirementReason: Yup.string().max(256),
    makeRetirementPublic: Yup.string(),
    retirementBeneficiary: Yup.string().nullable().max(256),
    retirementReasonComments: Yup.string().nullable().max(256),
    offsettingStartDate: Yup.string().required(),
    offsettingEndDate: Yup.string().required(),
    scopes: Yup.object().optional(),
  });

export type RetireBasketFormValues = {
  stId: number;
  tokTypeId: number;
  qtyUnit: number;
  minQty: number;
  maxQty: number;
  retirementReason: string;
  makeRetirementPublic: string;
  retirementBeneficiary: string;
  retirementReasonComments?: string;
  offsettingStartDate?: Date;
  offsettingEndDate?: Date;
  scopes: Record<string, boolean>;
};

type Props = {
  onSubmitForm: (data: RetireBasketFormValues) => void;
  tokenUnit: string;
  tokTypeId: number;
  stId: number;
  tokenSymbol: string;
  maxQty: number;
  basketConfiguration: Array<{
    percentage: number;
    tokenTypeId: number;
  }>;
  tokenScRatio: number;
};

export const RetireBasketForm = ({
  onSubmitForm,
  tokenUnit,
  tokenSymbol,
  tokTypeId,
  stId,
  maxQty,
  basketConfiguration,
  tokenScRatio,
}: Props) => {
  const { theme: themeColors } = Themes.useContainer();
  const { screenSize } = UI.useContainer();
  const {
    selector: { mainCcyCode, mainCcyNumDecimals },
  } = Entity.useContainer();
  const { publicOptions, forcedBeneficiary } = useRetirementOptions();

  const {
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
    setValue,
    getValues,
  } = useForm<RetireBasketFormValues>({
    mode: 'onBlur',
    resolver: yupResolver(schema(basketConfiguration)),
    defaultValues: {
      tokTypeId,
      maxQty,
      stId,
      qtyUnit: 1,
      minQty: 1,
      retirementReason: 'Voluntary',
      makeRetirementPublic: 'yes',
      retirementBeneficiary: forcedBeneficiary || '',
      retirementReasonComments: '',
      offsettingStartDate: undefined,
      offsettingEndDate: undefined,
      scopes: {},
    },
  });

  const qtyUnit = convertTextNumberToValue(watch('qtyUnit'));
  const minQty = convertTextNumberToValue(watch('minQty'));
  const tokensQty = basketConfiguration.reduce((qty, type) => {
    return {
      ...qty,
      [type.tokenTypeId]: ((tokenUnit !== '' ? 1000 : 1) * (maxQty * type.percentage)) / 100,
    };
  }, {});
  const { generateNextValidQuantity, generatePreviousValidQuantity, setBasketTypes } = useBasketQuantity('');

  // set max quantity base on token type
  useEffect(() => {
    setValue('maxQty', maxQty, {
      shouldValidate: true,
    });
  }, [maxQty, setValue, tokTypeId]);

  useEffect(() => {
    setBasketTypes(basketConfiguration);
  }, [basketConfiguration, setBasketTypes]);

  const quantity: number = convertTextNumberToValue(watch('qtyUnit'));
  const scopes = watch('scopes');

  // TODO: remove hardcoded fee value. Get from DB.
  const feePerThousand = 10;
  let feeAmount = (Number(quantity) / 1000) * feePerThousand;
  feeAmount = feeAmount > 1500 ? 1500 : feeAmount;
  feeAmount = feeAmount < feePerThousand ? feePerThousand : feeAmount;
  return (
    <Wrapper screenSize={screenSize}>
      <FormDevTool control={control} />
      <form className="pt-6 pb-8 w-full" onSubmit={handleSubmit(onSubmitForm)}>
        <Heading
          style={{
            maxWidth: screenSize === 'medium' || screenSize === 'small' ? '100%' : '',
          }}
          className="m-2 mx-auto mb-8 w-full"
          level="3"
          alignSelf="center"
          textAlign="center"
        >
          Retire Basket: {tokenSymbol}
        </Heading>
        <div className="flex flex-col flex-grow mx-auto">
          <div className="flex-1 mb-4 sm:mr-6 lg:mr-0">
            <label className="form-label" htmlFor="qtyUnit">
              Quantity {tokenUnit && `(${tokenUnit})`}
            </label>
            <InputWrapperWithThemeColors>
              <Controller
                name="qtyUnit"
                control={control}
                render={({ field }) => (
                  <InputControlWrapper className="flex">
                    <div className="flex">
                      <Cleave
                        options={{ numeral: true, numeralDecimalScale: 0 }}
                        className="focus:ring focus:outline-none"
                        min={minQty}
                        max={maxQty}
                        {...field}
                      />
                    </div>
                    <div className="flex">
                      <InputControlButton
                        themeColors={themeColors}
                        className="left"
                        type="button"
                        onClick={() =>
                          setValue('qtyUnit', generatePreviousValidQuantity(qtyUnit), {
                            shouldValidate: true,
                          })
                        }
                      >
                        -
                      </InputControlButton>
                      <InputControlButton
                        themeColors={themeColors}
                        className="right"
                        type="button"
                        onClick={() =>
                          setValue('qtyUnit', generateNextValidQuantity(qtyUnit), {
                            shouldValidate: true,
                          })
                        }
                      >
                        +
                      </InputControlButton>
                    </div>
                  </InputControlWrapper>
                )}
              />
            </InputWrapperWithThemeColors>
            <ErrorMessage as={<p className="form-error-message" />} name="qtyUnit" errors={errors} />
            <div className="pt-4">
              Transaction Fee: {mainCcyCode}
              {formatter.formatNumber(feeAmount, mainCcyNumDecimals)} (
              <em>
                {mainCcyCode}
                {feePerThousand} per 1000 tCO2e
              </em>
              )
            </div>
          </div>

          <div className={`flex gap-8 ${screenSize === 'small' ? 'flex-col' : 'flex-row'}`}>
            <div className="flex-1">
              <FieldWrapper>
                <InputLabel>Retirement Reason</InputLabel>
                <SelectWrapper themeColors={themeColors}>
                  <select
                    {...register('retirementReason')}
                    className="w-full ac-input"
                    defaultValue="Voluntary"
                    required
                    onChange={(event) => {
                      setValue('retirementReason', event.target.value, {
                        shouldValidate: true,
                      });
                    }}
                  >
                    <option value="Voluntary">Voluntary</option>
                    <option value="Compliance">Compliance</option>
                  </select>
                </SelectWrapper>
              </FieldWrapper>
            </div>
            <div className="flex-1">
              <FieldWrapper>
                <InputLabel>Make retirement public</InputLabel>
                <SelectWrapper themeColors={themeColors}>
                  <select {...register('makeRetirementPublic')} className="w-full ac-input" defaultValue="yes" required>
                    {publicOptions.map((option: { label: string; value: string }) => (
                      <option key={`registry:${option.value}`} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </SelectWrapper>
              </FieldWrapper>
            </div>
          </div>

          <div className={`flex gap-8 ${screenSize === 'small' ? 'flex-col' : 'flex-row'}`}>
            <div className="flex-1">
              <FieldWrapper>
                <InputLabel>Offset scope</InputLabel>
                <div
                  style={{
                    background: themeColors.dashboard.form.input.background,
                    border: `1px solid ${themeColors.dashboard.form.input.border}`,
                  }}
                  className="flex flex-col gap-4 p-3 pb-4 rounded-md"
                >
                  {SCOPES.map((scope) => (
                    <React.Fragment key={`scope-${scope}`}>
                      <CheckBoxWrapperWithThemeColors>
                        <CheckBox
                          key={`scope-${scope}-${scopes[scope]}`}
                          label={`Scope ${scope}`}
                          checked={scopes[scope]}
                          onChange={() => {
                            const newScopes: any = { ...scopes };
                            newScopes[scope] = newScopes[scope] ? undefined : true;
                            setValue('scopes', newScopes, {
                              shouldValidate: true,
                            });
                          }}
                        />
                      </CheckBoxWrapperWithThemeColors>
                    </React.Fragment>
                  ))}
                </div>
              </FieldWrapper>
            </div>
            <div className="flex-1">
              <div>
                <FieldWrapper>
                  <InputLabel>Retirement beneficiary</InputLabel>
                  <InputWrapperWithThemeColors>
                    <Controller
                      name="retirementBeneficiary"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input type="text" className="w-full" {...field} disabled={!!forcedBeneficiary} />
                          <div className="text-sm text-right text-gray-400">{field.value?.length ?? 0}/256</div>
                        </>
                      )}
                    />
                  </InputWrapperWithThemeColors>
                </FieldWrapper>
                <FieldWrapper>
                  <InputLabel>Offsetting period</InputLabel>
                  <CalendarWrapper
                    style={{
                      background: themeColors.dashboard.block.background,
                      border: `1px solid ${themeColors.dashboard.form.input.border}`,
                    }}
                    className="bg-white rounded-md"
                  >
                    <DateRangePicker
                      maxDate={moment(moment('2200-01-01'))}
                      minDate={moment('1970-01-01')}
                      showDropdowns={true}
                      alwaysShowCalendars
                      startDate={getValues('offsettingStartDate')}
                      endDate={getValues('offsettingEndDate')}
                      onApply={(_evt, picker) => {
                        setValue('offsettingStartDate', picker.startDate.toDate());
                        setValue('offsettingEndDate', picker.endDate.toDate(), {
                          shouldValidate: true,
                        });
                      }}
                    >
                      <IconButton type="button" className="mr-6">
                        <FaCalendar />
                        {getValues('offsettingStartDate') && getValues('offsettingEndDate') && (
                          <div>
                            {format(getValues('offsettingStartDate') || new Date(), 'MMM d yyyy')} -{' '}
                            {format(getValues('offsettingEndDate') || new Date(), 'MMM d yyyy')}
                          </div>
                        )}
                      </IconButton>
                    </DateRangePicker>
                  </CalendarWrapper>
                </FieldWrapper>
              </div>
            </div>
          </div>

          <div className="flex">
            <div className="w-full">
              <FieldWrapper>
                <InputLabel>Other notes</InputLabel>
                <TextArea
                  themeColors={themeColors}
                  {...register('retirementReasonComments')}
                  className="w-full"
                  style={{
                    height: '100px',
                  }}
                  maxLength={256}
                  onChange={(event) => {
                    setValue('retirementReasonComments', event.target.value || '', {
                      shouldValidate: true,
                    });
                  }}
                />
                <div className="text-sm text-right text-gray-400">
                  {getValues('retirementReasonComments')?.length ?? 0}/256
                </div>
              </FieldWrapper>
            </div>
          </div>
        </div>
        <PreviewBasket
          tokensQty={tokensQty}
          basketConfiguration={basketConfiguration}
          qty={qtyUnit}
          tokenUnit={tokenUnit}
          tokenScRatio={tokenScRatio}
          render={(invalidQty) => (
            <div className="flex justify-center pt-2 text-center">
              <Button disabled={!isValid || invalidQty} isLoading={isSubmitting} type="submit">
                Confirm
              </Button>
            </div>
          )}
        />
      </form>
    </Wrapper>
  );
};

export default RetireBasketForm;
