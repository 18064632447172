import { useQuery } from 'react-query';

import { fetchUserTrades } from 'data-provider/trade/fetchUserTrades';

export function useUserTrades({
  page,
  limit,
  userId,
  startDate,
  endDate,
}: {
  page: number;
  limit: number;
  userId: number;
  startDate?: string;
  endDate?: string;
}) {
  const enableQuery = page && limit && userId;
  const { data, isLoading, error, refetch } = useQuery(
    ['oms-user-trades', page, limit, userId, startDate, endDate],
    () =>
      fetchUserTrades({
        page,
        limit,
        userId,
        startDate,
        endDate,
      }),
    { enabled: enableQuery },
  );

  return {
    error,
    isLoading,
    trades: data,
    refetchTrades: refetch,
  };
}

export const getNonPaginatedUserTrades = async ({
  page,
  limit,
  userId,
  startDate,
  endDate,
}: {
  page: number;
  limit: number;
  userId: number;
  startDate?: string;
  endDate?: string;
}) => {
  return fetchUserTrades({
    page,
    limit,
    userId,
    startDate,
    endDate,
  });
};
