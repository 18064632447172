import { type ApiResponse, externalApi } from '../fetcher';
import type { Account, GlobalSetting } from './index';

export const fetchPublicSettings = () => {
  const res = externalApi.get('/settings/public');
  return res.json<
    ApiResponse<{
      accounts: Account[];
      settings: GlobalSetting[];
    }>
  >();
};
