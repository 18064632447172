import { Badge, BadgeSize, BadgeVariant } from 'refreshed-component/atoms/Badge';
import { Card } from 'refreshed-component/atoms/Card';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { User } from 'state/user';

import { BadgeProjectStatus } from '../../../../components/BadgeProjectStatus';
import type { ProjectStatus } from '../../../../utils/ProjectStatus';
import { DetailsRow } from './components/DetailsRow';
import { ProjectDocument } from './components/ProjectDocument';
import { SectionHeader } from './components/SectionHeader';

export interface ProjectInformationProps {
  id: string;
  description?: string;
  status?: ProjectStatus;
  projectDetails: Array<
    Array<
      | {
          type: 'country';
          label: string;
          value: {
            code: string;
            name: string;
          };
        }
      | {
          type: 'item';
          label: string;
          value: string;
        }
      | {
          type: 'list';
          label: string;
          value: Array<string>;
        }
    >
  >;
  sdgGoals?: Array<{
    id: string;
    name: string;
  }>;
  proponents?: Array<
    Array<
      Array<{
        label: string;
        value: string;
      }>
    >
  >;
  documents?: Array<
    Array<{
      label: string;
      name: string;
      url: string;
    }>
  >;
}

export const ProjectInformation: React.FC<ProjectInformationProps> = (props) => {
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  const { id, status, description, projectDetails, sdgGoals = [], proponents = [], documents = [] } = props;
  const onDownloadDocument = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    options: {
      url: string;
      name: string;
    },
  ) => {
    e.preventDefault();
    const authToken = await getAuthToken();

    fetch(options.url, {
      method: 'GET',
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', options.name);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  return (
    <StyledProjectInformation>
      <StyledCard>
        <SectionHeader
          suffix={[
            <Badge size={BadgeSize.Large} variant={BadgeVariant.Default} key={'id'}>
              <Text weight={FontWeight.semibold}>ID: #{id}</Text>
            </Badge>,
            !!status && <BadgeProjectStatus status={status} key={'status'} />,
          ]}
        >
          Project Information
        </SectionHeader>
        {!!description && <StyledDescriptionText color={Colors.gray_500}>{description}</StyledDescriptionText>}
        <DetailsList>
          {projectDetails.map((column, index) => (
            <DetailsColumn key={index}>
              {column.map((detail, index) => {
                if (detail.type === 'country') {
                  return (
                    <DetailsRow key={index} label={detail.label}>
                      <CountryFlag src={`/assets/country-flags/${detail.value.code}.svg`} alt={detail.value.name} />{' '}
                      <Text weight={FontWeight.medium}>{detail.value.name}</Text>
                    </DetailsRow>
                  );
                }

                if (detail.type === 'item') {
                  return (
                    <DetailsRow key={index} label={detail.label}>
                      {detail.value}
                    </DetailsRow>
                  );
                }

                if (detail.type === 'list') {
                  return (
                    <DetailsRow label={detail.label} key={index}>
                      {!!detail.value.length ? (
                        <UnorderedList>
                          {detail.value.map((item, index) => (
                            <li key={index}>
                              <Text weight={FontWeight.medium}>{item}</Text>
                            </li>
                          ))}
                        </UnorderedList>
                      ) : (
                        '-'
                      )}
                    </DetailsRow>
                  );
                }
              })}
            </DetailsColumn>
          ))}
        </DetailsList>
        {!!sdgGoals.length && (
          <>
            <Separator />
            <SectionHeader>SDG Goals:</SectionHeader>
            <SDGGoals>
              {sdgGoals.map((goal, index) => (
                <img key={index} src={`/assets/sdg-goals/sdg-${goal.id}-white.png`} />
              ))}
            </SDGGoals>
          </>
        )}
        {!!proponents.length && (
          <>
            <Separator />
            <SectionHeader>Proponents</SectionHeader>
            {proponents.map((proponent, index) => (
              <DetailsList key={index}>
                {proponent.map((column, index) => (
                  <DetailsColumn key={index}>
                    {column.map((row, index) => (
                      <DetailsRow key={index} label={row.label}>
                        {row.value}
                      </DetailsRow>
                    ))}
                  </DetailsColumn>
                ))}
              </DetailsList>
            ))}
          </>
        )}
      </StyledCard>
      {!!documents.length && (
        <StyledCard>
          <SectionHeader>Documents</SectionHeader>
          <DetailsList>
            {documents.map((column, index) => (
              <DetailsColumn key={index}>
                {column.map((row, index) => (
                  <ProjectDocument
                    key={index}
                    label={row.label}
                    name={row.name}
                    href={row.url}
                    onPress={(e) => onDownloadDocument(e, row)}
                  />
                ))}
              </DetailsColumn>
            ))}
          </DetailsList>
        </StyledCard>
      )}
    </StyledProjectInformation>
  );
};

const StyledProjectInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
`;

const CountryFlag = styled.img`
  width: 28px;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: var(${Colors.gray_200});
`;

const StyledCard = styled(Card)`
  height: auto;
  flex-direction: column;
  flex: 1 0 auto;
`;

const StyledDescriptionText = styled(Text)`
  text-align: justify;
  line-height: 24px;
`;

const DetailsList = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  @media (max-width: 1367px) {
    flex-direction: column;
  }
`;

const DetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const UnorderedList = styled.ul`
  list-style-type: disc;
  list-style-position: inside;

  & li > div {
    display: inline;
    line-height: 24px;
  }
`;

const SDGGoals = styled.div`
  display: flex;
  margin-inline-start: -12px;

  & > * {
    width: 72px;
  }
  & > *:not(:first-child) {
    margin-inline-start: -16px;
  }
`;
