import { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import { Header } from 'refreshed-component/molecules/Header';
import { WebAnnouncement } from 'refreshed-component/molecules/WebAnnouncement';
import { SideBar, type SideBarMenuProps } from 'refreshed-component/organisms/SideBar';
import styled from 'styled-components';

import { IconName, Layer, useLayerBackground } from '@aircarbon/ui';
import type { AssetCategory } from '@aircarbon/utils-common';

import { UI } from 'state/ui';

import { MarketplaceProductProvider } from 'providers/MarketplaceProductProvider';

import useQueryParams from 'hooks/useQueryParams';

import { Footer } from '../molecules/Footer';

export interface PageTab {
  title?: string;
  path?: string;
  component?: ReactNode;
  query?: { [key: string]: string };
  isDisabled?: (() => boolean) | boolean;
  isSelected?: (urlInfo?: UrlInfo) => boolean;
  isExpandable?: boolean;
  isExpanded?: boolean;
  availableProducts?: Array<AssetCategory>;
}

export const handleBooleanProp = (prop?: (() => boolean) | boolean) => {
  return typeof prop === 'function' ? prop() : prop;
};

export interface PageInfo extends PageTab {
  name?: string;
  tabs?: PageTab[];
}

export const getPathAndQuery = (pageInfo: PageInfo) => {
  const tabs = pageInfo.tabs?.filter((tab) => {
    return !handleBooleanProp(tab?.isDisabled);
  });
  if (tabs?.length) {
    return {
      path: tabs[0].path,
      query: tabs[0].query,
    };
  } else {
    return {
      path: pageInfo.path,
      query: pageInfo.query,
    };
  }
};

export type MapItem = (
  | {
      type: 'sidebar-separator';
    }
  | ({
      sideBarConfig?: {
        icon: IconName;
        name: string;
      };
    } & (
      | {
          type: 'link';
          page: PageInfo;
        }
      | {
          type: 'list';
          list: PageInfo[];
        }
    ))
) & {
  isDisabled?: boolean | (() => boolean);
};

export type ContentHolderProps = {
  config: MapItem[];
};

const ContentHolderRoot = styled.div<{
  contentBackground: string;
}>`
  z-index: 100;
  background-color: var(${Colors.gray_0});
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  > .screen {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    > .sidebar {
      height: 100%;
      width: auto;
      border-right: 1px solid var(${Colors.borderNormal});
      overflow-y: auto;
      overflow-x: hidden;
      max-width: 250px;
      &.overlay {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 10;
        width: 250px;
        transition:
          left 250ms ease-in,
          box-shadow 250ms ease-in;
        &.close {
          left: -250px;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0);
        }
        &.open {
          left: 0px;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        }
      }
      > .title {
        display: flex;
        flex-direction: row;
        padding-left: var(${Spacing.small});
        padding-right: var(${Spacing.small});
        justify-items: center;
        align-items: center;
        justify-content: space-between;
      }
    }
    > .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: 1 1 0%;
      max-width: 100%;
      > .header {
        min-height: 60px;
        width: 100%;
        border-bottom: 1px solid var(${Colors.borderNormal});
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .title {
          font-size: var(${FontSize._2Xl});
          font-weight: var(${FontWeight.semibold});
        }
        > .controls {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: var(${Spacing.xs});
          > .item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            border-radius: var(${Radius.medium});
            transition: background 100ms ease-in-out;
            > .wrapper {
              display: block;
              width: auto;
              height: auto;
              position: relative;
              > .notification {
                position: absolute;
                border: 1px solid #fff;
                background-color: var(${Colors.danger_700});
                color: var(${Colors.gray_0});
                font-size: var(${FontSize.xs});
                font-weight: var(${FontWeight.semibold});
                border-radius: var(${Radius.xl});
                top: 0px;
                right: 0px;
                transform: translateY(-50%) translateX(50%);
                padding-left: var(${Spacing._3xs});
                padding-right: var(${Spacing._3xs});
                height: 20px;
              }
              > .profile {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 26px;
                height: 26px;
                font-weight: var(${FontWeight.semibold});
                font-size: var(${FontSize.xs});
                background-color: var(${Colors.gray_200});
                padding: var(${Spacing._2xs});
                border-radius: var(${Radius.xl});
              }
            }
            &:hover {
              background-color: var(${Colors.gray_200});
              cursor: pointer;
            }
          }
        }
      }
      > .body {
        position: relative;
        width: 100%;
        flex: 1 1 0%;
        background-color: ${({ contentBackground }) => contentBackground};
        > .holder {
          position: absolute;
          width: 100%;
          height: 100%;
          overflow: auto;
        }
      }
      > .footer {
        height: fit-content;
        width: 100%;
        border-top: 1px solid var(${Colors.borderNormal});
      }
    }
  }
`;

export function matchUrlAndQuery(supply: PageInfo | PageTab, match: UrlInfo) {
  const finalPath = (supply.path || '').split('/*')[0];
  const endWithStarExtension = supply.path?.endsWith('/*');
  if (endWithStarExtension ? match.path.startsWith(finalPath) : supply.path === match.path) {
    if (!supply.query) {
      return true;
    } else {
      for (const [key, value] of Object.entries(supply.query)) {
        if (match.queryParams.get(key) !== value) {
          return false;
        }
      }
      return true;
    }
  }
  return false;
}

export function generatePath(
  path: string,
  query?: {
    [key: string]: string;
  },
) {
  return `${(path || '').split('/*')[0]}?${Object.entries(query || {})
    .map(([key, value]) => `${key}=${value}`)
    .join('&')}`;
}

export type UrlInfo = {
  path: string;
  queryParams: URLSearchParams;
};

export const ContentHolder: React.FC<ContentHolderProps> = (props) => {
  const { screenSize } = UI.useContainer();
  const [sidebarOverlay, setSidebarOverlay] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const queryParams = useQueryParams();
  const locationInfo: UrlInfo = useMemo(
    () => ({
      path: location.pathname,
      queryParams,
    }),
    [location.pathname, queryParams],
  );
  const { layerBackground } = useLayerBackground();

  const finalConfig = useMemo(
    () =>
      props.config.filter((item) => {
        const isDisabled = handleBooleanProp(item.isDisabled);
        if (isDisabled) {
          return false;
        } else if (item.type === 'link') {
          return !handleBooleanProp(item.page.isDisabled);
        } else {
          return true;
        }
      }),
    [props.config],
  );

  const currentPageInfo = finalConfig
    .map((item) => {
      if (item.type === 'sidebar-separator') {
        return undefined;
      } else if (item.type === 'link') {
        const isMatch = item.page.path ? matchUrlAndQuery(item.page, locationInfo) : false;
        const isMatchTab = item.page.tabs?.find((tab) => matchUrlAndQuery(tab, locationInfo)) !== undefined;
        const isValid = item.page.isSelected ? item.page.isSelected?.(locationInfo) : isMatch || isMatchTab;
        return isValid ? item.page : undefined;
      } else {
        return item.list.find((pageInfo) => {
          const isMatch = pageInfo.path ? matchUrlAndQuery(pageInfo, locationInfo) : false;
          const isMatchTab = pageInfo.tabs?.find((tab) => matchUrlAndQuery(tab, locationInfo)) !== undefined;
          const isValid = pageInfo.isSelected ? pageInfo.isSelected?.(locationInfo) : isMatch || isMatchTab;
          return isValid;
        });
      }
    })
    .find((item) => !handleBooleanProp(item?.isDisabled) && item);

  const pageTabs = currentPageInfo?.tabs?.filter((tab) => {
    return !handleBooleanProp(tab?.isDisabled);
  });

  const pageTab = pageTabs?.find((tab) => {
    return matchUrlAndQuery(tab, locationInfo);
  });

  const pageComponent = pageTab?.component || currentPageInfo?.component;

  const pageProducts =
    (pageTab?.availableProducts || currentPageInfo?.availableProducts)?.filter((product) => !!product) || [];

  useEffect(() => {
    if (!pageComponent) {
      const tab = pageTabs?.[0];
      if (tab?.component && tab.path) {
        history.replace(
          `${(tab.path || '').split('/*')[0]}?${Object.entries(tab.query || {})
            .map(([key, value]) => `${key}=${value}`)
            .join('&')}`,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageComponent]);

  const onCollapseSidebar = useCallback(() => {
    setSidebarOverlay(false);
  }, []);

  return (
    <ContentHolderRoot contentBackground={layerBackground('layer')}>
      <MarketplaceProductProvider>
        <div className="screen">
          {screenSize !== 'small' && screenSize !== 'medium' ? (
            <div className="sidebar">
              <SidebarContainer
                routingConfig={finalConfig}
                locationInfo={locationInfo}
                inOverlay={false}
                isExpandable={
                  pageTab?.isExpandable !== undefined ? pageTab.isExpandable : currentPageInfo?.isExpandable
                }
                isExpanded={pageTab?.isExpanded !== undefined ? pageTab.isExpanded : currentPageInfo?.isExpanded}
              />
            </div>
          ) : (
            <div className={`sidebar overlay ${sidebarOverlay ? 'open' : 'close'}`}>
              <SidebarContainer
                routingConfig={finalConfig}
                locationInfo={locationInfo}
                inOverlay={true}
                isExpanded={true}
                isExpandable={false}
                onCollops={onCollapseSidebar}
              />
            </div>
          )}
          <div className="content">
            <div className="header">
              <Layer>
                <Header
                  onExpand={() => setSidebarOverlay(true)}
                  name={currentPageInfo?.title as string}
                  tabs={pageTabs}
                  products={pageProducts}
                />
              </Layer>
            </div>
            <WebAnnouncement />
            <div className="body">
              <div className="holder">{pageComponent}</div>
            </div>
            <div className="footer">
              <Footer />
            </div>
          </div>
        </div>
      </MarketplaceProductProvider>
    </ContentHolderRoot>
  );
};

const SidebarContainer: React.FunctionComponent<{
  isExpandable?: boolean;
  isExpanded?: boolean;
  onCollops?: () => void;
  inOverlay: boolean;
  routingConfig: MapItem[];
  locationInfo: UrlInfo;
}> = React.memo(({ isExpandable, isExpanded, onCollops, inOverlay, routingConfig, locationInfo }) => {
  const sidebarMenu = routingConfig
    .map((route) => {
      if (route.type === 'link' && route.sideBarConfig) {
        const isMatch = route.page.path ? matchUrlAndQuery(route.page, locationInfo) : false;
        const isMatchTab = route.page.tabs?.find((tab) => matchUrlAndQuery(tab, locationInfo)) !== undefined;
        const pathAndQuery = getPathAndQuery(route.page);
        return {
          path: pathAndQuery.path,
          query: pathAndQuery.query,
          title: route.sideBarConfig.name,
          icon: route.sideBarConfig.icon,
          type: 'link',
          isSelected: route.page.isSelected ? route.page.isSelected?.(locationInfo) : isMatch || isMatchTab,
        };
      } else if (route.type === 'list' && route.sideBarConfig) {
        return {
          title: route.sideBarConfig.name,
          icon: route.sideBarConfig.icon,
          type: 'list',
          list: route.list
            .filter((item) => !handleBooleanProp(item.isDisabled))
            .map((item) => {
              const isMatch = item.path ? matchUrlAndQuery(item, locationInfo) : false;
              const isMatchTab = item.tabs?.find((tab) => matchUrlAndQuery(tab, locationInfo)) !== undefined;
              const pathAndQuery = getPathAndQuery(item);
              return {
                path: pathAndQuery.path,
                query: pathAndQuery.query,
                title: item.name || item.title,
                isSelected: item.isSelected ? item.isSelected?.(locationInfo) : isMatch || isMatchTab,
              };
            }),
        };
      } else if (route.type === 'sidebar-separator') {
        return {
          type: 'Separator',
        };
      }
    })
    .filter((item) => item) as SideBarMenuProps[];
  return (
    <SideBar
      isExpandable={isExpandable}
      isExpanded={isExpanded}
      onCollops={onCollops}
      menu={sidebarMenu}
      inOverlay={inOverlay}
    />
  );
});
