export type WebSettingsAnnouncementOption = 'WARNING' | 'ALERT' | 'INFO';

export type WebSettingsAnnouncementInfo = {
  message: string;
  type: WebSettingsAnnouncementOption;
  cancelable: 0 | 1;
};

export const resolveWebSettingsAnnouncement = (webSettingsAnnouncement: string): WebSettingsAnnouncementInfo => {
  let webSettingsAnnouncementValue: string | WebSettingsAnnouncementInfo;
  try {
    webSettingsAnnouncementValue = JSON.parse(webSettingsAnnouncement) as string | WebSettingsAnnouncementInfo;
  } catch {
    webSettingsAnnouncementValue = typeof webSettingsAnnouncement === 'string' ? webSettingsAnnouncement : '';
  }
  if (!webSettingsAnnouncementValue) {
    return {
      message: '',
      type: 'ALERT',
      cancelable: 0,
    };
  }
  return typeof webSettingsAnnouncementValue === 'string' || !webSettingsAnnouncement
    ? {
        message: (webSettingsAnnouncementValue as string) ?? '',
        type: 'ALERT',
        cancelable: 0,
      }
    : webSettingsAnnouncementValue;
};
