import { QuotesProvider } from './quotes-provider';
import { Requester } from './requester';
import { UDFCompatibleDatafeedBase } from './udf-compatible-datafeed-base';

export class UDFCompatibleDatafeed extends UDFCompatibleDatafeedBase {
  public constructor(datafeedURL?: string, authToken?: string, updateFrequency: number = 10 * 1000) {
    if (!datafeedURL) {
      throw new Error('Datafeed URL is not set');
    }

    const requester = authToken
      ? new Requester({
          Authorization: `Bearer ${authToken}`,
        })
      : new Requester();
    const quotesProvider = new QuotesProvider(datafeedURL, requester);
    super(datafeedURL, quotesProvider, requester, updateFrequency);
  }
}
