import { BadgeVariant, IconName } from '@aircarbon/ui';

export const toOfferStatusBadgeProps = (status: string) => {
  switch (status) {
    case 'sent':
      return {
        value: 'Sent',
        icon: IconName.ArrowUpEnd,
        variant: BadgeVariant.Alert,
      };
    case 'pending':
      return {
        value: 'Pending',
        icon: IconName.XCircle,
        variant: BadgeVariant.Alert,
      };
    case 'rejected':
      return {
        value: 'Rejected',
        icon: IconName.XCircle,
        variant: BadgeVariant.Danger,
      };
    case 'accepted':
      return {
        value: 'Accepted',
        icon: IconName.Check,
        variant: BadgeVariant.Success,
      };
    case 'executed':
      return {
        value: 'Executed',
        icon: IconName.Check,
        variant: BadgeVariant.Success,
      };
    case 'approved':
      return {
        value: 'Approved',
        icon: IconName.Check,
        variant: BadgeVariant.Success,
      };
    case 'canceled':
      return {
        value: 'Canceled',
        variant: BadgeVariant.Default,
      };
    case 'received':
    default:
      return {
        value: 'Received',
        icon: IconName.ArrowDownStart,
        variant: BadgeVariant.Inverted,
      };
  }
};
