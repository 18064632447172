import { format } from 'date-fns';
import { TradeRequest } from 'refreshed-pages/market-board-v2/utils/fetchTradeRequests';
import { toCMBOrderType } from 'refreshed-pages/market-board-v2/utils/toCMBOrderType';
import { toOfferDescription } from 'refreshed-pages/market-board-v2/utils/toOfferDescription';
import { toOfferName } from 'refreshed-pages/market-board-v2/utils/toOfferName';
import { toLastOfferPriceRaw, toOfferPrice } from 'refreshed-pages/market-board-v2/utils/toOfferPrice';
import { toOfferQuantity } from 'refreshed-pages/market-board-v2/utils/toOfferQuantity';
import { toOfferStatus } from 'refreshed-pages/market-board-v2/utils/toOfferStatus';

import { CMBOrderType } from '@aircarbon/ui';

import { OpenRequest } from './OpenRequest';

export interface ToOpenRequestsProps {
  tradeRequests: Array<TradeRequest>;
  currentUserId: number;
  currency: string;
}

export const toOpenRequests = (props: ToOpenRequestsProps): Array<OpenRequest> => {
  const { tradeRequests, currentUserId, currency } = props;

  return tradeRequests.map((tradeRequest) => {
    const otcOrderType = toCMBOrderType({
      isAuction: !!tradeRequest.otcOrder.isAuction,
      sideId: tradeRequest.otcOrder.sideId,
    });

    return {
      id: tradeRequest.id,
      name: toOfferName(tradeRequest),
      description: toOfferDescription(tradeRequest.tradeRequestDetail.__project__),
      price: toOfferPrice({
        tradeRequest,
        currentUserId,
        currency,
      }),
      quantity: toOfferQuantity({
        tradeRequest,
      }),
      isPrefunded: !!tradeRequest.isPrefunded,
      otcOrder: tradeRequest.otcOrder,
      lastPriceRaw: toLastOfferPriceRaw({ tradeRequest, currentUserId }),
      orderOpenQty: tradeRequest.otcOrder.qty - tradeRequest.otcOrder.filledQty,
      status: toOfferStatus({ tradeRequest, currentUserId }),
      side: otcOrderType === CMBOrderType.Bid ? CMBOrderType.Offer : CMBOrderType.Bid,
      matchSide: otcOrderType,
      matchId: tradeRequest.otcOrder.id,
      rejectionReason: tradeRequest.comment ?? undefined,
      counterOffers: tradeRequest.counterOffers,
      baseAsset: tradeRequest.baseAsset ?? undefined,
      reservedAssetQty: tradeRequest.reservedAssetQty,
      sellerUserId: tradeRequest.sellerUserId,
      createdBy: tradeRequest.createdBy,
      endsAt: tradeRequest.otcOrder.expiryUtc ? new Date(tradeRequest.otcOrder.expiryUtc) : undefined,
      ...(tradeRequest.confirmedByBuyerAtUtc
        ? {
            acceptedAt: format(new Date(tradeRequest.confirmedByBuyerAtUtc), 'do MMM, yyyy'),
          }
        : {}),
      ...(tradeRequest.cancelledAtUtc
        ? {
            rejectedAt: format(new Date(tradeRequest.cancelledAtUtc), 'do MMM, yyyy'),
            cancelledAt: format(new Date(tradeRequest.cancelledAtUtc), 'do MMM, yyyy'),
          }
        : {}),
    };
  });
};
