import type { ExternalBrokerClient } from '@aircarbon/utils-common/src/dto/trading';

import { userAuthenticatedApi } from '../fetcher';

export const fetchExternalBrokerClient = async () => {
  const endpoint = '/user/broker/external-clients';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).get();
  return res.json<ExternalBrokerClient[]>();
};
