export default (
  <svg viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.70704 5.70679C9.51951 5.89426 9.26521 5.99957 9.00004 5.99957C8.73488 5.99957 8.48057 5.89426 8.29304 5.70679L5.00004 2.41379L1.70704 5.70679C1.51844 5.88894 1.26584 5.98974 1.00364 5.98746C0.741443 5.98518 0.490631 5.88001 0.305223 5.6946C0.119815 5.5092 0.0146453 5.25838 0.0123669 4.99619C0.0100885 4.73399 0.110883 4.48139 0.293041 4.29279L4.29304 0.292787C4.48057 0.105316 4.73488 0 5.00004 0C5.26521 0 5.51951 0.105316 5.70704 0.292787L9.70704 4.29279C9.89451 4.48031 9.99983 4.73462 9.99983 4.99979C9.99983 5.26495 9.89451 5.51926 9.70704 5.70679Z"
      fill="#111111"
    />
  </svg>
);
