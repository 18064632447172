import { useQuery } from 'react-query';

import { fetchCarbonProjectById } from 'data-provider/contract/fetchCarbonProjectById';

/**
 * Fetches project metadata for a list of batches
 *
 * @param batches - List of batches
 * @param token - User jwt token
 * @returns
 */
const useProjectInfo = (projectId: string, registryId?: string) => {
  const {
    data: project,
    isLoading,
    error,
  } = useQuery(
    ['project-detail', projectId, registryId],
    () => {
      return fetchCarbonProjectById(projectId, registryId);
    },
    {
      enabled: projectId.length > 0,
    },
  );

  return {
    project,
    isLoading,
    error,
  };
};

export default useProjectInfo;
