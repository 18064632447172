import { useRef, useState } from 'react';

import { User } from 'state/user';

interface FileInformation {
  name: string;
  size: number;
  url: string;
}

interface UseFileUploadProps {
  initialFile: FileInformation | null;
  onUploadFinished(file: FileInformation): void;
  onRemoveFinished(): void;
}

export const useFileUpload = (
  props: UseFileUploadProps,
): {
  file: FileInformation | null;
  isUploading: boolean;
  uploadProgress: number;
  upload(file: File): void;
  cancelUpload(): void;
  remove(): void;
} => {
  const { initialFile, onUploadFinished, onRemoveFinished } = props;
  const [fileInformation, setFileInformation] = useState<FileInformation | null>(initialFile);
  const fileRef = useRef<File>();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const {
    selector: { getAuthToken },
  } = User.useContainer();

  const requestRef = useRef<XMLHttpRequest>();

  const cancelUpload = () => {
    if (!requestRef.current) {
      return;
    }

    requestRef.current.abort();
    setFileInformation(initialFile);
    setIsUploading(false);
    setUploadProgress(0);
  };

  const upload = async (file: File) => {
    const newFileInformation = {
      name: file.name,
      size: file.size,
      url: '',
    };
    setFileInformation(newFileInformation);
    fileRef.current = file;
    setUploadProgress(0);
    setIsUploading(true);
    // upload file to s3,
    const userToken = await getAuthToken();

    const formData = new FormData();
    formData.append('file', file);
    const request = new XMLHttpRequest();
    requestRef.current = request;
    request.open('POST', '/api/user/apx/project-documents');
    request.setRequestHeader('accept', 'application/json');
    request.setRequestHeader('Authorization', `Bearer ${userToken}`);
    request.upload.addEventListener('progress', (e) => {
      const uploadProgress = Math.round((e.loaded / e.total) * 100);

      setUploadProgress(uploadProgress);
    });

    request.addEventListener('load', (e) => {
      if (request.status !== 200) {
        // TODO: handle upload error
        return;
      }
      try {
        const data = JSON.parse(request.response);

        newFileInformation.url = data[0].path;

        if (!newFileInformation.url) {
          throw new Error('Could not parse file path');
        }
      } catch (e) {
        // TODO: handle bad response error
        console.error(e);
        return;
      }
      onUploadFinished(newFileInformation);
      setIsUploading(false);
      setUploadProgress(0);
    });

    request.upload.addEventListener('error', (e) => {
      // Handle upload error
      console.error(e);
    });

    request.send(formData);
  };

  const remove = () => {
    // remove file from s3 bucket,
    // empty the file information
    setFileInformation(null);
    onRemoveFinished();
  };

  return {
    isUploading,
    file: fileInformation,
    uploadProgress,
    upload,
    cancelUpload,
    remove,
  };
};
