import { forwardRef, useImperativeHandle, useState } from 'react';

import { Modal, Text, ToastVariant, showToast } from '@aircarbon/ui';

import { updateTradeRequest } from '../ListingPage/components/IncomingOffers/utils/updateTradeRequest';

export interface CancelTradeRequestModalRef {
  show(tradeRequestId: string): void;
}

export interface CancelTradeRequestModalProps {
  onCancelled(tradeRequest: {
    id: string;
    approvedAtUtc: string;
    approvedBy: number;
    buyerConfirmationStatus: string;
    sellerConfirmationStatus: string;
    buyerUpdatedAtUtc: string;
    sellerUpdatedAtUtc: string;
    cancelledAtUtc: string | null;
    price: number;
    quantity: number;
  }): void;
}

export const CancelTradeRequestModal = forwardRef<CancelTradeRequestModalRef, CancelTradeRequestModalProps>(
  ({ onCancelled }, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const [tradeRequestId, setTradeRequestId] = useState<string>('');
    const [isCancelling, setIsCancelling] = useState(false);

    useImperativeHandle(ref, () => ({
      show(tradeRequestId) {
        setTradeRequestId(tradeRequestId);
        setIsVisible(true);
      },
    }));

    const onClose = () => {
      if (!isCancelling) setIsVisible(false);
    };

    const onCancelTradeRequest = async () => {
      setIsCancelling(true);

      const result = await updateTradeRequest({
        tradeRequestId,
        status: 'CANCELLED',
      });

      setIsCancelling(false);

      if (result.status === 'error') {
        showToast({
          variant: ToastVariant.Danger,
          message: result.error,
        });
        return;
      }

      onCancelled(result.data);
      setIsVisible(false);
    };

    return (
      <Modal
        title={`Cancel Trade Request`}
        isVisible={isVisible}
        onClose={onClose}
        onPressNegativeButton={onClose}
        onPressPositiveButton={onCancelTradeRequest}
        isPositiveButtonLoading={isCancelling}
      >
        <Text>Are you sure you want to cancel trade request #{tradeRequestId}?</Text>
      </Modal>
    );
  },
);
