import { Badge, BadgeSize, BadgeVariant } from 'refreshed-component/atoms/Badge';
import { Button } from 'refreshed-component/atoms/Button';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import { ProjectCardLayout } from 'refreshed-pages/project-exchange/components/ProjectCardLayout';
import { SDGGoals } from 'refreshed-pages/project-exchange/components/SDGGoals';
import { ProjectStatus } from 'refreshed-pages/project-exchange/utils/ProjectStatus';
import styled from 'styled-components';

import { ProjectCardLoader } from './components/Loader';

type ProjectCardProps = {
  title: string;
  status: ProjectStatus;
  date: string;
  dateLabel: string;
  vintage?: string;
  country?: string;
  sectoralScope?: string;
  sdgGoals: Array<{ label: string; value: string }>;
  rejectionText?: string;
  onPressEdit?(): void;
  onPressView?(): void;
  onPressMarketplace?(): void;
};

export const ProjectCard: React.FC<ProjectCardProps> & {
  Loader: typeof ProjectCardLoader;
} = (props) => {
  const {
    title,
    country,
    vintage,
    sectoralScope,
    sdgGoals,
    status,
    dateLabel,
    date,
    onPressEdit,
    rejectionText = 'Our team will reach out to you soon...',
    onPressView,
    onPressMarketplace,
  } = props;

  const badgeColor = toBadgeColor(status);

  return (
    <ProjectCardLayout
      title={title}
      information={[
        {
          label: 'Vintage',
          value: vintage,
        },
        {
          label: 'Country',
          value: country,
        },
        {
          label: 'Sectoral Scope',
          value: sectoralScope,
        },
      ]}
      footer={sdgGoals.length ? <SDGGoals goals={sdgGoals} /> : undefined}
      endSection={
        <StyledEndSection>
          <StatusAndDate>
            <Badge
              variant={BadgeVariant.Default}
              size={BadgeSize.Large}
              backgroundColor={badgeColor.background}
              color={badgeColor.color}
            >
              <Icon type={toBadgeIconType(status)} width={16} height={16} />
              <Text color={badgeColor.color} size={FontSize.small}>
                {toStatusText(status)}
              </Text>
            </Badge>
            <Text color={Colors.gray_500}>
              {dateLabel}: <Text color={Colors.secondaryDefault}>{date}</Text>
            </Text>
          </StatusAndDate>
          {status === ProjectStatus.Rejected && (
            <Text size={FontSize.small} weight={FontWeight.medium} color={Colors.danger_700}>
              {rejectionText}
            </Text>
          )}
          {status === ProjectStatus.Draft && !!onPressEdit && (
            <StyledButton
              onClick={onPressEdit}
              config={{
                color: 'secondary',
                size: 'l',
                icon: {
                  right: <Icon width={20} height={20} type={IconType.Pencil} />,
                },
              }}
            >
              Continue Editing
            </StyledButton>
          )}

          {status === ProjectStatus.Submitted && !!onPressView && (
            <StyledButton
              onClick={onPressView}
              config={{
                color: 'outlined',
                size: 'l',
                icon: {
                  right: <Icon width={20} height={20} type={IconType.Eye} />,
                },
              }}
            >
              View
            </StyledButton>
          )}

          {status === ProjectStatus.Active && (
            <ActionsContainer>
              <StyledButton
                onClick={onPressView}
                config={{
                  color: 'outlined',
                  size: 'l',
                  icon: {
                    right: <Icon width={20} height={20} type={IconType.Eye} />,
                  },
                }}
              >
                View
              </StyledButton>
              <Button
                onClick={onPressMarketplace}
                config={{
                  color: 'secondary',
                  size: 'l',
                }}
              >
                Marketplace
              </Button>
            </ActionsContainer>
          )}
        </StyledEndSection>
      }
    />
  );
};

ProjectCard.Loader = ProjectCardLoader;

const ActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

const StyledEndSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  flex-grow: 1;
  justify-content: space-between;
`;

const StatusAndDate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
`;

const StyledButton = styled(Button)`
  justify-content: center;

  & > .children {
    flex: 0 1 auto;
  }
`;

const statusesTextMap = {
  [ProjectStatus.Draft]: 'Draft',
  [ProjectStatus.Rejected]: 'Rejected',
  [ProjectStatus.Submitted]: 'Submitted',
  [ProjectStatus.Active]: 'Active',
};

const statusesIconMap = {
  [ProjectStatus.Draft]: IconType.Document,
  [ProjectStatus.Rejected]: IconType.XCircle,
  [ProjectStatus.Submitted]: IconType.Clock,
  [ProjectStatus.Active]: IconType.CheckCircle,
};

const statusBadgeColorMap = {
  [ProjectStatus.Draft]: {
    background: Colors.gray_200,
    color: Colors.gray_900,
  },
  [ProjectStatus.Rejected]: {
    background: Colors.danger_100,
    color: Colors.danger_700,
  },
  [ProjectStatus.Submitted]: {
    background: Colors.warning_100,
    color: Colors.warning_700,
  },
  [ProjectStatus.Active]: {
    color: Colors.success_700,
    background: Colors.success_100,
  },
};

const toStatusText = (status: ProjectStatus) => statusesTextMap[status];

const toBadgeIconType = (status: ProjectStatus) => statusesIconMap[status];

const toBadgeColor = (status: ProjectStatus) => statusBadgeColorMap[status];
