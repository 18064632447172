import { useState } from 'react';
import { useQuery } from 'react-query';
import { usePagination } from 'refreshed-component/molecules/Pagination';

import { AssetCategory } from '@aircarbon/utils-common';

import type { IncomingTradeRequestsResponse } from 'pages/account/block-trade/types';
import type { User as UserType } from 'pages/account/block-trade/types';

import { Contract } from 'state/contract';
import { User } from 'state/user';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

export const useOutgoingRequest = (params?: { status?: string }) => {
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  const { product } = useMarketplaceProduct();
  const [selectedBroker, setSelectedBroker] = useState<number | string | undefined>();
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(params?.status);

  const { actions, filterBy } = Contract.useContainer();
  const pagination = usePagination();
  const {
    data: tradeRequestsResponse,
    refetch,
    isLoading,
  } = useQuery(
    [
      `/api/user/block-trade/outgoing-requests`,
      pagination.page,
      pagination.pageSize,
      filterBy.range?.endDate,
      filterBy.range?.startDate,
      product,
      selectedBroker,
      selectedStatus,
    ],
    async (): Promise<IncomingTradeRequestsResponse> => {
      // TODO: Implement data-provider
      const authToken = await getAuthToken();
      const response = await fetch(
        `/api/user/block-trade/outgoing-requests?startDate=${filterBy.range?.startDate ?? ''}&endDate=${
          filterBy.range?.endDate ?? ''
        }&brokerUserId=${selectedBroker && selectedBroker !== 'ALL' ? selectedBroker : ''}&status=${
          selectedStatus && selectedStatus !== 'ALL STATUS' ? selectedStatus : ''
        }&page=${pagination.page}&limit=${pagination.pageSize}&assetCategory=${AssetCategory[Number(product)]}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: `Bearer ${authToken}`,
          },
        },
      );
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    },
  );

  const { data: clientList } = useQuery([`/api/user/broker/participants`], async (): Promise<UserType[]> => {
    // TODO: Implement data-provider
    const authToken = await getAuthToken();
    const response = await fetch(`/api/user/broker/participants`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText);
  });

  return {
    selectedStatus,
    setSelectedStatus,
    selectedBroker,
    setSelectedBroker,
    actions,
    filterBy,
    response: tradeRequestsResponse,
    isLoading,
    refetch,
    pagination,
    clientList,
  };
};
