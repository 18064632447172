import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';

import { projects } from '@aircarbon/utils-common';

function Criteria({ filters }: { filters: Array<{ id: number; filterName: string; filterValue: string }> }): any {
  const filtersObj = filters.reduce((result: Record<string, Array<string>>, filter) => {
    const cloneResult = { ...result };
    if (!cloneResult[filter.filterName]) cloneResult[filter.filterName] = [];
    cloneResult[filter.filterName].push(filter.filterValue);
    return cloneResult;
  }, {});

  return (
    <div className="flex flex-row gap-base">
      {Object.keys(filtersObj).map((key) => {
        const criteriaValues = filtersObj[key];
        return (
          <div className="flex flex-row">
            <Text
              size={FontSize.small}
              radius={Radius.medium}
              background={Colors.gray_100}
              spacingLR={Spacing.base}
              spacingTB={Spacing.xs}
              weight={FontWeight.medium}
            >
              {(projects.projectLabel[key] ?? key) === 'Token' ? 'Asset' : projects.projectLabel[key] ?? key}
              {criteriaValues.map((value) => {
                let criteriaValue = value;
                if (key.includes('LOCATION')) {
                  criteriaValue = projects.countryNames[criteriaValue];
                } else if (key.includes('COUNTRY')) {
                  criteriaValue = projects.countryNames[criteriaValue];
                } else if (key.includes('DATE')) {
                  criteriaValue = criteriaValue.slice(criteriaValue.length - 4);
                }
                return (
                  <div key={`${key}-${criteriaValue}`} className="criteriaValue">
                    {criteriaValue}
                  </div>
                );
              })}
            </Text>
          </div>
        );
      })}
    </div>
  );
}

export default Criteria;
