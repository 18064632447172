import type { ProjectsListFilters } from '../components/MyProjects/components/ProjectsList';
import { QueryParamKey } from '../components/MyProjects/utils/QueryParams';
import { ProjectStatus } from './ProjectStatus';

export const toFiltersFromQueryParams = (urlQuery: string) => {
  let filtersFromQueryParams: ProjectsListFilters = {};
  const queryParams = new URLSearchParams(urlQuery);

  const status = toStatus(queryParams.get(QueryParamKey.Status));

  if (status !== null) {
    filtersFromQueryParams = {
      ...filtersFromQueryParams,
      status,
    };
  }

  const projectTypes = toIds(queryParams.get(QueryParamKey.ProjectTypes));
  if (projectTypes?.length) {
    filtersFromQueryParams = {
      ...filtersFromQueryParams,
      projectTypes,
    };
  }

  const sectoralScopes = toIds(queryParams.get(QueryParamKey.SectoralScopes));
  if (sectoralScopes?.length) {
    filtersFromQueryParams = {
      ...filtersFromQueryParams,
      sectoralScopes,
    };
  }

  const ccbStandards = toIds(queryParams.get(QueryParamKey.CcbStandards));
  if (ccbStandards?.length) {
    filtersFromQueryParams = {
      ...filtersFromQueryParams,
      ccbStandards,
    };
  }

  const vintageYearFrom = Number(queryParams.get(QueryParamKey.VintageYearFrom) || undefined);
  if (!isNaN(vintageYearFrom)) {
    filtersFromQueryParams = {
      ...filtersFromQueryParams,
      vintageYear: {
        ...filtersFromQueryParams.vintageYear,
        from: vintageYearFrom,
      },
    };
  }

  const vintageYearTo = Number(queryParams.get(QueryParamKey.VintageYearTo) || undefined);
  if (!isNaN(vintageYearTo)) {
    filtersFromQueryParams = {
      ...filtersFromQueryParams,
      vintageYear: {
        ...filtersFromQueryParams.vintageYear,
        to: vintageYearTo,
      },
    };
  }

  return filtersFromQueryParams;
};

const toIds = (params: string | null) => {
  if (params === null) {
    return null;
  }

  try {
    const ids = JSON.parse(`[${params}]`);

    return ids as Array<number>;
  } catch {
    return null;
  }
};

const toStatus = (status: string | null) => {
  if (status === null) {
    return null;
  }

  const statusesMap: Record<string, ProjectStatus> = {
    draft: ProjectStatus.Draft,
    rejected: ProjectStatus.Rejected,
    submitted: ProjectStatus.Submitted,
    active: ProjectStatus.Active,
  };

  return statusesMap[status] === undefined ? null : statusesMap[status];
};
