import { Badge, BadgeSize, BadgeVariant } from 'refreshed-component/atoms/Badge';
import { Card } from 'refreshed-component/atoms/Card';
import Loading from 'refreshed-component/molecules/Loading';
import { PageHeader } from 'refreshed-component/molecules/PageHeader';
import { Pagination } from 'refreshed-component/molecules/Pagination';
import { Table } from 'refreshed-component/templates/Table';
import styled from 'styled-components';

import { FilterTabs } from './components/FilterTabs';
import type { GERHistoryData } from './hooks/GERHistoryData';
import { useGERHistory } from './hooks/useGERHistory';
import { toBadgeSettings } from './utils/toBadgeSettings';
import { toTableColumns } from './utils/toTableColumns';

export const GERHistory = () => {
  const {
    history,
    meta: { assetsAllocationsMap },
    pagination,
    isLoading,
    changePagination,
  } = useGERHistory();

  const tableColumns = toTableColumns({ history, assetsAllocationsMap });
  const tableRows = toTableRows({ history });

  const onChangePagination = (page: number, pageSize: number) => {
    changePagination({
      currentPage: page,
      perPage: pageSize,
    });
  };

  return (
    <>
      <PageHeader title="History" margin="24px" />
      <StyledCard
        header={
          <FilterTabs
            activeTab={'retirement'}
            onPressTab={() => {}}
            items={[
              {
                label: 'Retirement',
                value: 'retirement',
              },
            ]}
          />
        }
      >
        <Pagination
          onChange={onChangePagination}
          total={pagination.total}
          page={pagination.currentPage}
          pageSize={pagination.perPage}
        />
        <div className="relative">
          {isLoading ? (
            <Loading isOverLay={false} />
          ) : (
            <Table
              config={{
                columns: tableColumns,
                rows: tableRows,
              }}
            />
          )}
        </div>

        <Pagination
          onChange={onChangePagination}
          total={pagination.total}
          page={pagination.currentPage}
          pageSize={pagination.perPage}
        />
      </StyledCard>
    </>
  );
};

const toTableRows = (props: { history: GERHistoryData }) => {
  const numberFormatter = new Intl.NumberFormat('en-US');
  switch (props.history.operation) {
    case 'pack':
      return props.history.history.map((item, index) => ({
        _key: '' + index,
        id: item.id,
        packedGer: '+' + numberFormatter.format(item.packedGer),
        gerBefore: numberFormatter.format(item.gerBefore),
        gerAfter: numberFormatter.format(item.gerAfter),
        bcc: '-' + numberFormatter.format(item.bcc),
        fcc: '-' + numberFormatter.format(item.fcc),
        pcc: '-' + numberFormatter.format(item.pcc),
        ccc: '-' + numberFormatter.format(item.ccc),
        submittedOn: new Intl.DateTimeFormat('en-US', {
          timeStyle: 'medium',
          dateStyle: 'medium',
        }).format(item.submittedOn),
        status: (
          <Badge variant={BadgeVariant.Default} size={BadgeSize.Large}>
            {item.status.label}
          </Badge>
        ),
      }));
    case 'unpack':
      return props.history.history.map((item, index) => ({
        _key: '' + index,
        id: item.id,
        unpackedGer: '+' + numberFormatter.format(item.unpackedGer),
        gerBefore: numberFormatter.format(item.gerBefore),
        gerAfter: numberFormatter.format(item.gerAfter),
        bcc: '-' + numberFormatter.format(item.bcc),
        fcc: '-' + numberFormatter.format(item.fcc),
        pcc: '-' + numberFormatter.format(item.pcc),
        ccc: '-' + numberFormatter.format(item.ccc),
        submittedOn: new Intl.DateTimeFormat('en-US', {
          timeStyle: 'medium',
          dateStyle: 'medium',
        }).format(item.submittedOn),
        status: (
          <Badge variant={BadgeVariant.Default} size={BadgeSize.Large}>
            {item.status.label}
          </Badge>
        ),
      }));
    case 'retirement':
      return props.history.history.map((item, index) => {
        const badgeSettings = toBadgeSettings(item.status.value);

        return {
          _key: '' + index,
          batchId: item.batchId,
          gerQty: numberFormatter.format(item.gerQty),
          bcc: numberFormatter.format(item.bcc),
          fcc: numberFormatter.format(item.fcc),
          pcc: numberFormatter.format(item.pcc),
          ccc: numberFormatter.format(item.ccc),
          submittedOn: new Intl.DateTimeFormat('en-US', {
            timeStyle: 'medium',
            dateStyle: 'medium',
          }).format(item.submittedOn),
          status: (
            <Badge
              backgroundColor={badgeSettings.backgroundColor}
              color={badgeSettings.color}
              variant={BadgeVariant.Default}
              size={BadgeSize.Large}
            >
              {item.status.label}
            </Badge>
          ),
        };
      });
  }
};

const StyledCard = styled(Card)`
  flex-direction: column;
  margin: 0 24px;
  height: auto;
`;
