import { Colors } from 'refreshed-component/design-system';

const badgeSettings: Record<
  string,
  {
    backgroundColor: Colors;
    color: Colors;
  }
> = {
  submitted: {
    backgroundColor: Colors.success_100,
    color: Colors.success_700,
  },
  done: {
    backgroundColor: Colors.success_100,
    color: Colors.success_700,
  },
  pending: {
    backgroundColor: Colors.warning_100,
    color: Colors.warning_700,
  },
  processing: {
    backgroundColor: Colors.warning_100,
    color: Colors.warning_700,
  },
  reverted: {
    backgroundColor: Colors.danger_100,
    color: Colors.danger_700,
  },
  cancelled: {
    backgroundColor: Colors.danger_100,
    color: Colors.danger_700,
  },
};

export const toBadgeSettings = (
  status: string,
): {
  backgroundColor: Colors;
  color: Colors;
} => {
  return (
    badgeSettings[status] || {
      backgroundColor: Colors.gray_100,
      color: Colors.gray_800,
    }
  );
};
