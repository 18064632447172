import { useState } from 'react';
import styled from 'styled-components';

import { User } from 'state/user';

import { Batch } from '../Batch';
import { RetireBatch } from '../RetireBatch';
import { useBatches } from './hooks/useBatches';

export const Batches = () => {
  const { batches } = useBatches();
  const [batchId, setBatchId] = useState(0);
  const [maxRetireQuantity, setMaxRetireQuantity] = useState(0);
  const [isRetireBatchModalVisible, setIsRetireBatchModalVisible] = useState(false);
  const {
    status: { canRetireGER },
  } = User.useContainer();

  const onRetireBatch = ({ batchId, quantity }: { batchId: number; quantity: number }) => {
    setBatchId(batchId);
    setMaxRetireQuantity(quantity);
    setIsRetireBatchModalVisible(true);
  };

  const onCloseRetireBatchModal = () => {
    setIsRetireBatchModalVisible(false);
  };

  return (
    <>
      <StyledBatches>
        {batches.map((batch, key) => {
          const formattedQuantity = new Intl.NumberFormat('en-US').format(batch.quantity);

          return (
            <Batch
              label={batch.label}
              onRetire={
                canRetireGER() ? () => onRetireBatch({ batchId: batch.batchId, quantity: batch.quantity }) : undefined
              }
              key={batch.batchId}
              assets={batch.assets}
              quantity={formattedQuantity}
            />
          );
        })}
      </StyledBatches>
      <RetireBatch
        batchId={batchId}
        maxRetireQuantity={maxRetireQuantity}
        isVisible={isRetireBatchModalVisible}
        onClose={onCloseRetireBatchModal}
      />
    </>
  );
};

const StyledBatches = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
