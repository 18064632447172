import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { urlMapping } from 'refreshed-pages/settlement/route';

import {
  Card,
  Divider,
  DividerDirection,
  Icon,
  IconName,
  LabelWithDescription,
  LabelWithDescriptionSize,
  styled,
  TextColor,
  type ToMarginProps,
  toSpacing,
  Text,
  TypographyVariant,
  CardVariant,
} from '@aircarbon/ui';

import useProjectInfo from 'hooks/useProjectInfo';

import { BadgeSettleOrderSide } from '../organism/BadgeSettleOrderSide';
import { BadgeSettleOrderStatus } from '../organism/BadgeSettleOrderStatus';

interface MetaField {
  label: string;
  value: string;
  valueSuffix?: ReactElement;
}

interface CardSettlementSummaryProps extends ToMarginProps {
  id: string;
  side: string;
  status: string;
  price: string;
  priceLabel: string;
  quantity: string;
  quantityLabel: string;
  settlementDate: string;
  projectName?: string;
  registryProjectId?: string;
  metaFields: MetaField[];
  className?: string;
  providers?: {
    registries: {
      id: string;
      name: string;
    }[];
    countries: Record<string, string>;
  };
}

export const CardSettlementSummary: React.FunctionComponent<CardSettlementSummaryProps> = (props) => {
  const {
    id,
    side,
    status,
    price,
    priceLabel,
    quantity,
    settlementDate,
    projectName,
    registryProjectId,
    quantityLabel,
    metaFields,
    providers,
    ...restProps
  } = props;

  const { project } = useProjectInfo(registryProjectId || '');
  const name = project?.name ?? projectName ?? '';

  // Convert registry id to name
  const fields = metaFields.map((field) => {
    if (field.label === 'Registry') {
      return {
        ...field,
        value: providers?.registries.find((registry) => registry.id === field.value)?.name ?? field.value,
      };
    }

    return field;
  });

  return (
    <Link to={urlMapping.view(id)}>
      <StyledCard {...restProps} variant={CardVariant.Bordered}>
        <Container>
          <SettleInfo>
            <Header>
              <Badges>
                <BadgeSettleOrderSide side={side} id={id} />
                <BadgeSettleOrderStatus status={status} />
              </Badges>
            </Header>
            <MetaInfo>
              {name.length > 0 && name !== '-' && <Text variant={TypographyVariant.subtitle1}>{name}</Text>}

              {fields.length > 0 && (
                <Field>
                  {fields.map((field) => (
                    <LabelWithDescription
                      key={field.label}
                      isExpanded
                      size={LabelWithDescriptionSize.m}
                      label={field.label}
                      description={field.value}
                      descriptionSuffix={field?.valueSuffix}
                    />
                  ))}
                </Field>
              )}
            </MetaInfo>
          </SettleInfo>
          <StyledDivider direction={DividerDirection.Vertical} />
          {/* Show Settlement Date */}
          <MainDetails>
            <LabelWithDescription
              label={'Settlement Date'}
              description={settlementDate}
              size={LabelWithDescriptionSize.l}
            />
            <PriceAndQuantity>
              <LabelWithDescription label={priceLabel} description={price} size={LabelWithDescriptionSize.l} />
              <LabelWithDescription label={quantityLabel} description={quantity} size={LabelWithDescriptionSize.l} />
            </PriceAndQuantity>
          </MainDetails>
        </Container>
        <StylledArrowDivider direction={DividerDirection.Vertical} />
        <IconRoot>
          <StyledIcon name={IconName.ChevronEnd} color={TextColor.secondary} />
        </IconRoot>
      </StyledCard>
    </Link>
  );
};

const StyledCard = styled(Card)`
  container-type: inline-size;
  margin: ${({ theme }) => toSpacing(theme)(8)};
  flex-direction: row;
`;

const StylledArrowDivider = styled(Divider)`
  align-self: stretch;
  height: auto;
  display: none;
  @container (min-width: 500px) {
    display: block;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @container (min-width: 800px) {
    flex-direction: row;
    align-items: stretch;
  }
`;

const SettleInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${({ theme }) => toSpacing(theme)(8)};
  margin-right: ${({ theme }) => toSpacing(theme)(8)};
  @container (min-width: 800px) {
    padding-inline-end: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;

const Badges = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(4)};
`;

const MetaInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(4)};
  margin-top: ${({ theme }) => toSpacing(theme)(8)};
  box-sizing: border-box;
`;

const Field = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => toSpacing(theme)(4)};
  padding-top: ${({ theme }) => toSpacing(theme)(4)};
  > * {
    flex-grow: 1;
  }
`;

const StyledDivider = styled(Divider)`
  height: 1px;
  width: 100%;
  @container (min-width: 800px) {
    align-self: stretch;
    width: 1px;
    height: auto;
  }
`;

const MainDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ theme }) => toSpacing(theme)(8)};
  flex-shrink: 0;
  padding: ${({ theme }) => toSpacing(theme)(12)};
  margin-inline-start: 0;
  min-width: 294px;
  @container (min-width: 800px) {
    flex-direction: column;
    padding-inline-start: 0;
    margin-inline-start: ${({ theme }) => toSpacing(theme)(12)};
  }
`;

const PriceAndQuantity = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: ${({ theme }) => toSpacing(theme)(8)};
  @container (min-width: 800px) {
    padding-inline-start: 0;
  }
`;

const IconRoot = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  display: none;
  @container (min-width: 500px) {
    display: flex;
  }
  @container (min-width: 600px) {
    padding-inline-start: 0;
  }
`;

const StyledIcon = styled(Icon)`
  width: 29px;
  height: 29px;
`;
