import { forwardRef, useImperativeHandle, useState } from 'react';
import { Project } from 'refreshed-pages/market-board-v2/utils/Project';
import { toVintageYears } from 'refreshed-pages/market-board-v2/utils/toVintageYears';
import * as yup from 'yup';

import { Badge, BadgeSize, Select, Tooltip, styled } from '@aircarbon/ui';
import { Const } from '@aircarbon/utils-common';

import { UI } from 'state/ui';

import { FieldsGroup } from '../FieldsGroup';
import { SelectProject } from '../SelectProject';

const getVintageBalancePerAsset = (project: Project, vintage: string) => {
  let totalBalance = 0;
  const balancePerAsset = project?.balances?.reduce(
    (assets: { [key: string]: { assetId: number; quantity: number } }, entry) => {
      if (!assets[entry.assetId]) {
        assets[entry.assetName] = { assetId: entry.assetId, quantity: 0 };
      }

      if (entry.vintage === Number(vintage)) {
        assets[entry.assetName].quantity += entry.qty;
        totalBalance += entry.qty;
      }
      return assets;
    },
    {},
  );

  return { totalBalance, balancePerAsset };
};

export interface SelectProjectAndVintageRef {
  /**
   * Validates the form and returns true if forms has errors
   */
  validate(): boolean;
}

const validator = yup.object().shape({
  project: yup.object().shape({
    value: yup.string().required('Please select project'),
    title: yup.string().required(),
    description: yup.string().required(),
  }),
  vintage: yup.string().required('Please select vintage'),
});

interface SelectProjectAndVintageProps {
  project?: Project;
  isReadOnly?: boolean;
  projectLabel?: string;
  vintage: string;
  ownedByUserId: number;
  selectProjectFilterCriteria?: Array<{
    key: string;
    operator: string;
    values: Array<string>;
  }>;
  availableVintages?: Array<string>;
  onChange(props: { project?: Project; vintage: string }): void;
  onPressCreateNewProject(): void;
}

export const SelectProjectAndVintage = forwardRef<SelectProjectAndVintageRef, SelectProjectAndVintageProps>(
  (props, ref) => {
    const {
      project,
      vintage,
      ownedByUserId,
      onPressCreateNewProject,
      isReadOnly,
      onChange,
      projectLabel = 'Project',
      availableVintages,
      selectProjectFilterCriteria,
    } = props;
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>({});
    const { getSetting } = UI.useContainer();

    useImperativeHandle(
      ref,
      () => ({
        validate() {
          setDirtyFields({});
          try {
            validator.validateSync(
              {
                project,
                vintage,
              },
              {
                abortEarly: false,
              },
            );
          } catch (e) {
            setErrors(
              (e as yup.ValidationError).inner.reduce(
                (acc, curr) => ({ ...acc, [curr.path as string]: curr.message }),
                {},
              ),
            );
            return true;
          }

          setErrors({});
          return false;
        },
      }),
      [project, vintage],
    );

    const isMarketBoardAndAuctionsV2SettlementModeEnabled =
      getSetting(Const.FeatureToggle.MarketBoardAndAuctionsV2SettlementMode) === '1';

    const onChangeSelectProject = (value?: Project) => {
      setDirtyFields((prevFields) => ({
        ...prevFields,
        project: true,
      }));

      onChange({
        project: value,
        vintage,
      });
    };

    const onChangeVintage = ({ value }: { value: string }) => {
      const { totalBalance, balancePerAsset } = getVintageBalancePerAsset(project!, value);
      setDirtyFields((prevFields) => ({
        ...prevFields,
        vintage: true,
      }));
      onChange({
        project: { ...project!, vintageBalance: totalBalance, vintageBalancePerAsset: balancePerAsset },
        vintage: value,
      });
    };

    return (
      <StyledFieldsGroup columnMinWidth="14rem">
        <SelectProject
          isReadOnly={isReadOnly}
          isOwnedByMeFilterEnabled={!isMarketBoardAndAuctionsV2SettlementModeEnabled}
          ownedByUserId={ownedByUserId}
          onPressCreateNewProject={onPressCreateNewProject}
          value={project}
          error={!dirtyFields['project'] ? errors['project.value'] : undefined}
          onChange={onChangeSelectProject}
          label={projectLabel}
          filterCriteria={selectProjectFilterCriteria}
        />
        <Select
          isDisabled={isReadOnly}
          label="Vintage"
          placeholder="Select"
          error={!dirtyFields.vintage ? errors.vintage : undefined}
          value={vintage}
          items={toVintageYears({
            include: availableVintages?.map((v) => Number(v)),
          }).map((item) => {
            const { totalBalance } = getVintageBalancePerAsset(project!, item.value);

            if (!totalBalance) {
              return item;
            }

            return {
              ...item,
              suffix: (
                <Tooltip value="You have available assets of this vintage">
                  <Badge size={BadgeSize.s} value="avail." />
                </Tooltip>
              ),
            };
          })}
          onChange={onChangeVintage}
        />
      </StyledFieldsGroup>
    );
  },
);

const StyledFieldsGroup = styled(FieldsGroup)`
  grid-template-columns: 1fr 11rem;

  @media (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;
