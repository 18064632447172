import { TooltipPlacement } from 'antd/lib/tooltip';
import { ForwardedRef, forwardRef, RefObject, useRef, useState } from 'react';
import { useEffect } from 'react';
import { FaSortDown } from 'react-icons/fa';
import { Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { hooks } from '@aircarbon/utils-common';

import Popover from './Popover';
import { Themes } from './Themes';
import { GREEN, RED } from './theme';

const { useOnClickOutside } = hooks;

const Wrapper = styled.button<{
  focused?: Color;
  color?: string;
  border?: Color;
  backgroundColor?: Color;
  size?: Size;
  hoverStyle?: HoverStyle;
  isDisabled?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  white-space: pre;
  color: ${(props) => props.color || `var(${Colors.gray_0})`};
  position: relative;
  ${(props) => {
    if (props.border === 'red') {
      return `border: 1px solid ${RED.text.default};`;
    } else if (props.border === 'green') {
      return `border: 1px solid ${GREEN.text.default};`;
    } else if (props.border === 'transparent') {
      return `border: 1px solid transparent;`;
    } else if (props.border) {
      return `border: 1px solid ${props.border};`;
    } else {
      return `border: 1px solid var(${Colors.gray_200});`;
    }
  }}
  border-radius: 3px;
  ${(props) => {
    if (props.isDisabled) {
      if (props.backgroundColor?.startsWith('#')) {
        return `background-color: ${props.backgroundColor};`;
      }
      return `background-color: #444;`;
    } else if (props.backgroundColor === 'red') {
      return `background-color: ${RED.bg.default};`;
    } else if (props.backgroundColor === 'green') {
      return `background-color: ${GREEN.bg.brand};`;
    } else if (props.backgroundColor === 'light-gray') {
      return `background-color: #444;`;
    } else if (props.backgroundColor === 'transparent') {
      return `background-color: transparent;`;
    } else {
      return `background-color: ${props.backgroundColor ?? '#333'};`;
    }
  }}
  ${(props) => {
    if (props.size === 'big') {
      return `padding: 9px;font-size: 16px;`;
    } else if (props.size === 'medium') {
      return `padding: 6px; font-size: 14px;`;
    } else if (props.size === 'auto') {
      return `padding: 0px; font-size: 12px;`;
    } else {
      return `padding: 3px; font-size: 12px;`;
    }
  }}
  align-items: center;
  cursor: pointer;
  &:hover {
    ${(props) => {
      if (props.hoverStyle === 'underline') {
        return `text-decoration: underline;`;
      } else {
        if (props.border === 'red') {
          return `border: 1px solid #e43c3c;`;
        } else if (props.border === 'green') {
          return `border: 1px solid #02b71f;`;
        } else if (props.backgroundColor === 'transparent') {
          return `background-color: transparent;`;
        } else {
          return `border: 1px solid ${props.border};`;
        }
      }
    }}
  }
  &:active {
    border: 1px solid var(${Colors.gray_500});
  }
  &:focus {
    outline: none;
    ${(props) => {
      if (props.focused === 'white') {
        return `border: 1px solid #fff;`;
      } else if (props.border === 'red') {
        return `border: 1px solid #e43c3c;`;
      } else if (props.border === 'green') {
        return `border: 1px solid #02b71f;`;
      } else if (props.backgroundColor === 'transparent' && props.border === 'transparent') {
        return `border: 1px solid transparent;`;
      } else {
        return `border: 1px solid ${props.focused};`;
      }
    }}
  }
  &:disabled {
    background-color: var(${Colors.gray_500});
  }
`;

type Color = 'red' | 'green' | 'gray' | 'light-gray' | 'transparent' | 'white' | string;
type HoverStyle = 'underline' | 'border';
type Size = 'small' | 'medium' | 'big' | 'auto';

const Label = styled.div`
  font-weight: 700;
  padding-left: 3px;
  padding-right: 3px;
  line-height: 18px;
  user-select: none;
`;

const List = styled.div<{ color: string; focusedBackground: string }>`
  position: relative;
  width: auto;
  > div {
    color: ${(props) => props.color};
    width: 100%;
    height: 22px;
    left: 0;
    right: 0;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 12px;
    align-items: start;
    cursor: pointer;
    &:hover,
    &.focused {
      background-color: ${(props) => props.focusedBackground};
    }
  }
`;
interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  action?: string;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
}

export type DropdownItem = { id: number; value: string };

export type ButtonConfigProps = {
  color?: string;
  border?: Color;
  focused?: Color;
  hoverStyle?: HoverStyle;
  backgroundColor?: Color;
  size?: Size;
  label?: React.ReactNode;
  children?: React.ReactNode;
  dropdown?: {
    placement?: TooltipPlacement;
  } & (
    | { type: 'string'; list: string[]; onSelect: (item: string, index: number) => void }
    | {
        type: 'idAndValues';
        list: DropdownItem[];
        onSelect: (item: DropdownItem, index: number) => void;
      }
  );
  arrow?: boolean;
  arrowIcon?: React.ReactNode;
};

export type ButtonProps = Props & {
  ref?: React.RefObject<HTMLDivElement>;
  config?: ButtonConfigProps;
};

const Button = forwardRef(({ config, disabled, ...props }: ButtonProps, forwardRef: ForwardedRef<HTMLDivElement>) => {
  const { theme } = Themes.useContainer();
  const [showMenu, setShowMenu] = useState(false);
  const [focused, setFocused] = useState(-1);
  const filterWrapperRef = useRef<HTMLDivElement>(null);
  const ref = (forwardRef || filterWrapperRef) as RefObject<HTMLDivElement> | undefined;
  useEffect(() => {
    if (!showMenu) setFocused(-1);
  }, [showMenu]);

  const { isLoading } = props;

  const wrapper = (
    <Wrapper
      color={config?.color}
      hoverStyle={config?.hoverStyle}
      isDisabled={disabled}
      ref={ref}
      size={config?.size}
      border={config?.border}
      focused={config?.focused}
      backgroundColor={config?.backgroundColor}
      tabIndex={0}
      className={`${isLoading ? 'spinner' : ''} ${props.className}`}
      onMouseDown={(event) => {
        const isOPen = !showMenu;
        const mouseup = () => {
          config?.dropdown && setShowMenu(isOPen);
        };
        const clear = () => {
          event.target.removeEventListener('mouseup', mouseup);
          window.removeEventListener('mouseup', clear);
        };
        event.target.addEventListener('mouseup', mouseup);
        window.addEventListener('mouseup', clear);
      }}
      {...(props as any)}
      onClick={(event) => {
        if (!disabled)
          if (config?.dropdown) {
            props.onClick?.(event as any);
          } else {
            props.onClick?.(event);
          }
      }}
      onKeyDown={
        config?.dropdown
          ? (event) => {
              if (config.dropdown) {
                if (event.key === 'ArrowDown') {
                  const _focused = focused + 1;
                  setFocused(_focused > config.dropdown.list.length - 1 ? 0 : _focused);
                } else if (event.key === 'ArrowUp') {
                  const _focused = focused - 1;
                  setFocused(_focused < 0 ? config.dropdown.list.length - 1 : _focused);
                } else if (event.key === 'Enter') {
                  if (focused !== -1) {
                    if (config.dropdown.type === 'string') {
                      config.dropdown?.onSelect(config.dropdown.list[focused], focused);
                    } else {
                      config.dropdown?.onSelect(config.dropdown.list[focused], focused);
                    }
                  }
                  setShowMenu(false);
                } else if (event.key === 'Tab') {
                  setShowMenu(false);
                  return;
                }
              }
              if (showMenu) event.preventDefault();
              props.onKeyPress?.(event as any);
            }
          : props.onKeyPress
      }
    >
      {config?.label && (
        <Label className={`button-label flex-auto ${config?.dropdown ? 'text-left' : 'text-center'}`}>
          {config?.label}
        </Label>
      )}
      {config?.children}
      {(config?.dropdown || config?.arrow) && (
        <div className="flex-shrink mr-1">
          {config?.arrowIcon || (
            <FaSortDown
              style={{
                marginTop: '-4px',
              }}
            />
          )}
        </div>
      )}
    </Wrapper>
  );

  const dropDownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropDownRef as any, () => {
    if (showMenu) setShowMenu(false);
  });

  const content = config?.dropdown ? (
    <Popover
      visible={showMenu}
      placement={config?.dropdown.placement || 'bottomRight'}
      content={() => {
        return (
          <List
            color={theme.dropdown.text}
            focusedBackground={theme.dropdown.active.itemBackground}
            ref={dropDownRef}
            style={{
              minWidth: ref?.current?.offsetWidth ? `${ref?.current?.offsetWidth - 24}px` : '',
              maxHeight: '300px',
              overflow: 'auto',
            }}
          >
            {config?.dropdown?.list.map((item, index) => {
              return (
                <div
                  key={typeof item === 'string' ? item : item.value}
                  className={`${focused === index ? 'focused' : ''}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowMenu(false);
                    config.dropdown?.onSelect(item as string & { id: number; value: string }, index);
                  }}
                >
                  {typeof item === 'string' ? item : item.value}
                </div>
              );
            })}
          </List>
        );
      }}
    >
      {wrapper}
    </Popover>
  ) : (
    wrapper
  );
  return content;
});

export default Button;
