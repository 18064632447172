import Emittery from 'emittery';
import { PropsWithChildren, useEffect, useMemo, useRef } from 'react';
import { FiltersContext } from 'refreshed-pages/market-board-v2/utils/FiltersContext';
import { FiltersContextEventName } from 'refreshed-pages/market-board-v2/utils/FiltersContextEvent';
import { FiltersContextValue } from 'refreshed-pages/market-board-v2/utils/FiltersContextValue';
import { OtcCriteria } from 'refreshed-pages/market-board-v2/utils/OtcCriteria';
import { Sorting } from 'refreshed-pages/market-board-v2/utils/Sorting';
import { StaticFilters } from 'refreshed-pages/market-board-v2/utils/StaticFilters';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { fetchOtcCriteria } from '../../utils/fetchOtcCriteria';

const initialStaticFilters: StaticFilters = {
  price: {
    from: 0,
    to: 0,
  },
  vintageYear: '',
  isInstantTrade: false,
  orderType: 'all',
  sort: Sorting.MostRecent,
};

export const FiltersProvider: React.FunctionComponent<PropsWithChildren<{}>> = ({ children }) => {
  const { product } = useMarketplaceProduct();
  const filteringOptions = useRef<OtcCriteria>([]);
  const dynamicFilters = useRef<OtcCriteria>([]);
  const staticFilters = useRef<StaticFilters>(initialStaticFilters);

  const filtersEventEmitter = useRef(new Emittery());

  const filtersContextValue = useMemo<FiltersContextValue>(
    () => ({
      getFilteringOptions: () => filteringOptions.current,
      setFilteringOptions: (newFilteringOptions: OtcCriteria) => {
        filteringOptions.current = newFilteringOptions;
        filtersEventEmitter.current.emit(FiltersContextEventName.ChangedFilteringOptions, filteringOptions.current);
      },
      getDynamicFilters: () => dynamicFilters.current,
      setDynamicFilters: (newCurrentFilters: OtcCriteria) => {
        dynamicFilters.current = newCurrentFilters;
        filtersEventEmitter.current.emit(FiltersContextEventName.ChangedDynamicFilters, dynamicFilters.current);
      },
      getStaticFilters: () => staticFilters.current,
      setStaticFilters: (newCurrentFilters: StaticFilters) => {
        staticFilters.current = newCurrentFilters;
        filtersEventEmitter.current.emit(FiltersContextEventName.ChangedStaticFilters, staticFilters.current);
      },
      subscribe(event, callback) {
        return filtersEventEmitter.current.on(event, (payload) =>
          callback({
            event,
            payload,
          } as any),
        );
      },
    }),
    [],
  );

  useEffect(() => {
    const loadFilteringOptions = async () => {
      filtersContextValue.setDynamicFilters([]);
      filtersContextValue.setStaticFilters(initialStaticFilters);
      filtersEventEmitter.current.emit(FiltersContextEventName.FetchingFilteringOptions, true);
      const filteringOptions = await fetchOtcCriteria(Number(product));

      filtersContextValue.setFilteringOptions(
        filteringOptions.keys
          .map((option) => ({
            value: option?.code,
            label: option?.name,
            options: option?.__projectMetaOptions__?.map((metaOption) => ({
              value: metaOption?.code,
              label: metaOption.metaValue,
            })),
          }))
          .filter((option) => {
            if (option.value === 'COUNTRY_CODE' || option.value === 'VINTAGE_YEAR') {
              return true;
            }

            return !!option.options?.length;
          }),
      );
      filtersEventEmitter.current.emit(FiltersContextEventName.FetchingFilteringOptions, false);
    };
    loadFilteringOptions();
  }, [filtersContextValue, product]);

  return <FiltersContext.Provider value={filtersContextValue}>{children}</FiltersContext.Provider>;
};
