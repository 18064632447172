import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { hooks } from '@aircarbon/utils-common';

interface UseBottomSlideUpProps {
  /**
   * Header height in px
   */
  headerHeight: number;

  /**
   * Reference to the slide up section container
   */
  containerRef: RefObject<HTMLElement>;

  /**
   * Wether slide up section is expanded or collapsed
   * (will override custom position state)
   */
  isExpanded: boolean;
}

export enum SliderState {
  Expanded,
  Collapsed,
  Dragging,
  CustomPosition,
}

export const useBottomSlideUp = (props: UseBottomSlideUpProps) => {
  const { headerHeight, containerRef: slideUpRef, isExpanded } = props;
  const [translateY, setTranslateY] = useState(isExpanded ? slideUpRef.current?.offsetHeight ?? 0 : -headerHeight);
  const initialHeightRef = useRef<number>(0);
  const startYRef = useRef<number | null>(null);
  const [bottomSlideUpState, setBottomSlideUpState] = useState(
    isExpanded ? SliderState.Expanded : SliderState.Collapsed,
  );
  const prevIsExpanded = hooks.usePrevious(isExpanded);

  useEffect(() => {
    if (prevIsExpanded === isExpanded) {
      return;
    }

    if ([SliderState.Dragging].includes(bottomSlideUpState)) {
      return;
    }

    setBottomSlideUpState(isExpanded ? SliderState.Expanded : SliderState.Collapsed);
    setTranslateY(isExpanded ? -1 * (slideUpRef.current?.offsetHeight ?? 0) : -headerHeight);
  }, [isExpanded, bottomSlideUpState, prevIsExpanded, headerHeight]);

  const startDragging = useCallback(
    (startY: number) => {
      document.body.style.userSelect = 'none';
      startYRef.current = startY;
      initialHeightRef.current =
        bottomSlideUpState === SliderState.CustomPosition
          ? -translateY
          : bottomSlideUpState === SliderState.Expanded
            ? slideUpRef.current?.offsetHeight ?? 0
            : headerHeight;
      setBottomSlideUpState(SliderState.Dragging);
    },
    [bottomSlideUpState, translateY, headerHeight],
  );

  useEffect(() => {
    const updateTranslateY = (event: MouseEvent | TouchEvent) => {
      if (startYRef.current === null || bottomSlideUpState !== SliderState.Dragging) {
        return;
      }

      const currentY = 'touches' in event ? event.touches[0].clientY : event.clientY;
      const deltaY = startYRef.current - currentY;
      let newHeight = -1 * (initialHeightRef.current + deltaY);

      if (newHeight > -headerHeight) {
        newHeight = -headerHeight;
      } else if (newHeight < -1 * (slideUpRef.current?.scrollHeight ?? 0)) {
        newHeight = -1 * (slideUpRef.current?.scrollHeight ?? 0);
      }

      setTranslateY(newHeight);
    };

    const updateIsExpanded = () => {
      if (bottomSlideUpState !== SliderState.Dragging) {
        return;
      }

      document.body.style.userSelect = '';

      if (translateY === -headerHeight) {
        setBottomSlideUpState(SliderState.Collapsed);
        return;
      }

      if (translateY === -1 * (slideUpRef.current?.scrollHeight ?? 0)) {
        setBottomSlideUpState(SliderState.Expanded);
        return;
      }

      setBottomSlideUpState(SliderState.CustomPosition);
    };

    const onMouseMove = (event: MouseEvent) => {
      updateTranslateY(event);
    };

    const onMouseUp = () => {
      updateIsExpanded();
    };
    const onTouchMove = (event: TouchEvent) => {
      updateTranslateY(event);
    };
    const onTouchEnd = () => {
      updateIsExpanded();
    };
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('touchend', onTouchEnd);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
    };
  }, [bottomSlideUpState, translateY]);

  return {
    translateY,
    startDragging,
    state: bottomSlideUpState,
  };
};
