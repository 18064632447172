import type { Dto } from '@aircarbon/utils-common';

import type { ProjectFormValue } from '../../ProjectForm';
import { toDocumentTypeId } from './toDocumentTypeId';

export const toUpdateProjectApiBody = (props: {
  projectId: number;
  formValue: ProjectFormValue;
}): Dto.UpdateMyApxProjectApiParams => {
  const { projectId, formValue } = props;

  const apiBody: Dto.UpdateMyApxProjectApiParams = {
    id: projectId,
    name: formValue.projectName,
    description: formValue.projectDescription,
    countryCode: formValue.projectLocation,
    apxProject: {
      apxPhaseId: formValue.projectPhase ? Number(formValue.projectPhase) : undefined,
      implementationDate: formValue.implementationDate ? new Date(formValue.implementationDate) : undefined,
    },
    registryId: formValue.standardUsed ? Number(formValue.standardUsed) : undefined,
    registryProjectId: formValue.projectId,
    carbonProjectTypeId: formValue.projectType ? Number(formValue.projectType) : undefined,
    methodologyId: formValue.methodology ? Number(formValue.methodology) : undefined,
    customMethodology: formValue.customMethodology ? formValue.customMethodology : undefined,
    sectoralScopeId: formValue.sectoralScope ? Number(formValue.sectoralScope) : undefined,
    sdgGoals: formValue.sdgGoals?.length ? formValue.sdgGoals.map((goal) => Number(goal)) : undefined,
  };

  if (formValue.proponents.length) {
    apiBody.apxProponents = formValue.proponents.map((proponent) => {
      return {
        id: proponent.id,
        companyName: proponent.companyName,
        phone: proponent.phone.value ? `${proponent.phone.phoneCode}${proponent.phone.value}` : undefined,
        contactPersonName: proponent.contactPersonName,
        contactEmails: proponent.email,
        relationType: proponent.companyInvolvement,
        contactPersonRole: proponent.contactPersonRole,
      };
    });
  }

  if (formValue.vintages.length) {
    apiBody.vintages = formValue.vintages.map((vintage) => ({
      id: vintage.id,
      vintageYear: Number(vintage.year) || undefined,
      projectedCreditQuantity: Number(vintage.estimatedQty) || undefined,
      expectedIssuanceDate: vintage.dateOfIssuance ? new Date(vintage.dateOfIssuance) : undefined,
      offeredCreditQuantity: Number(vintage.offeredQty) || undefined,
    }));
  }

  const documents = Object.keys(formValue.documents)
    .map((key) => {
      const documentTypeId = toDocumentTypeId(key);

      if (!documentTypeId || !formValue.documents[key as keyof typeof formValue.documents]) {
        return null;
      }

      return {
        documentTypeId: toDocumentTypeId(key) as number,
        ...formValue.documents[key as keyof typeof formValue.documents],
      };
    })
    .filter((item) => !!item) as Dto.UpdateMyApxProjectApiParams['apxDocuments'];

  if (documents?.length) {
    apiBody.apxDocuments = documents;
  }

  return apiBody;
};
