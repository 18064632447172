export default (
  <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.3002 15.5031C30.3002 19.4283 28.7409 23.1928 25.9654 25.9683C23.1898 28.7438 19.4254 30.3031 15.5002 30.3031C11.575 30.3031 7.81055 28.7438 5.03501 25.9683C2.25948 23.1928 0.700195 19.4283 0.700195 15.5031C0.700195 11.5779 2.25948 7.81348 5.03501 5.03794C7.81055 2.26241 11.575 0.703125 15.5002 0.703125C19.4254 0.703125 23.1898 2.26241 25.9654 5.03794C28.7409 7.81348 30.3002 11.5779 30.3002 15.5031V15.5031ZM19.2002 9.95312C19.2002 10.9344 18.8104 11.8755 18.1165 12.5694C17.4226 13.2633 16.4815 13.6531 15.5002 13.6531C14.5189 13.6531 13.5778 13.2633 12.8839 12.5694C12.19 11.8755 11.8002 10.9344 11.8002 9.95312C11.8002 8.97182 12.19 8.03071 12.8839 7.33683C13.5778 6.64294 14.5189 6.25312 15.5002 6.25312C16.4815 6.25312 17.4226 6.64294 18.1165 7.33683C18.8104 8.03071 19.2002 8.97182 19.2002 9.95312V9.95312ZM15.5002 17.3531C13.729 17.3528 11.995 17.8609 10.5041 18.8172C9.01327 19.7735 7.82833 21.1377 7.09009 22.7477C8.13105 23.9587 9.4216 24.9303 10.8732 25.5958C12.3249 26.2612 13.9033 26.6049 15.5002 26.6031C17.0971 26.6049 18.6755 26.2612 20.1271 25.5958C21.5788 24.9303 22.8693 23.9587 23.9103 22.7477C23.1721 21.1377 21.9871 19.7735 20.4963 18.8172C19.0054 17.8609 17.2714 17.3528 15.5002 17.3531V17.3531Z"
      fill="#007495"
    />
  </svg>
);
