import type { MarketFlags } from '@aircarbon/utils-common/src/dto';

import { userAuthenticatedApi } from '../fetcher';

export const fetchUserMarketSettings = async (accountAddress: string) => {
  const endpoint = '/oms/order/user-market-settings';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ accountAddress }).get();
  return res.json<MarketFlags>();
};
