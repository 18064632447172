import type { ApxProject } from '@aircarbon/utils-common/src/dto';

export const toVintagePeriod = (vintages: ApxProject['vintages'] = []) => {
  if (!vintages?.length) {
    return '-';
  }

  const vintagesYears = vintages.map((v) => v.vintageYear).filter((v) => !!v) as Array<number>;

  const minimumYear = Math.min(...vintagesYears);
  const maximumYear = Math.max(...vintagesYears);

  if (minimumYear !== maximumYear) {
    return `${minimumYear}-${maximumYear}`;
  }

  return minimumYear.toString();
};
