import { Dispatch, SetStateAction, forwardRef, useImperativeHandle, useState } from 'react';
import Loading from 'refreshed-component/molecules/Loading';

import {
  Alert,
  AlertColor,
  AlertVariant,
  Card,
  Divider,
  Layer,
  Modal,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toSpacing,
  useSpacing,
} from '@aircarbon/ui';

import { SummaryItem } from './components/SummaryItem';

export interface ReviewOrderModalProps {
  isSubmitting: boolean;
  onConfirm(): void;
}

export interface ReviewOrderModalData {
  summary: Array<{
    label: string;
    value: string;
    valueColor?: TextColor;
  }>;
  quantity: string;
  price: string;
  fee: string;
  total: string;
  placedBy?: string;
  onBehalfOf?: string;
  shouldAcceptImmediately?: boolean;
}

export interface ReviewOrderModalRef {
  /**
   * Show review modal on screen
   */
  show(props: ReviewOrderModalData): void;

  /**
   * Hide modal
   */
  hide(): void;

  /**
   * Put modal in loading state
   */
  setIsLoading(isLoading: boolean): void;

  /**
   * Update Modal Data
   */
  setModalData: Dispatch<SetStateAction<ReviewOrderModalData>>;
}

export const ReviewOrderModal = forwardRef<ReviewOrderModalRef, ReviewOrderModalProps>((props, ref) => {
  const { onConfirm, isSubmitting } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reviewOrderModalData, setReviewOrderModalData] = useState<ReviewOrderModalData>({
    summary: [],
    quantity: '',
    price: '',
    fee: '',
    placedBy: '',
    onBehalfOf: '',
    total: '',
  });
  const { spacing } = useSpacing();

  useImperativeHandle(ref, () => ({
    show(props: ReviewOrderModalData) {
      setReviewOrderModalData(props);
      setIsVisible(true);
    },
    hide() {
      setIsVisible(false);
    },
    setIsLoading,
    setModalData: setReviewOrderModalData,
  }));

  const onClose = () => {
    if (isSubmitting) {
      return;
    }
    setIsVisible(false);
  };

  const onPressConfirm = () => {
    onConfirm();
  };

  return (
    <Modal
      title="Confirm Order Details"
      isVisible={isVisible}
      onClose={onClose}
      onPressNegativeButton={onClose}
      onPressPositiveButton={onPressConfirm}
      isPositiveButtonLoading={isSubmitting}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {reviewOrderModalData.shouldAcceptImmediately && (
            <Alert
              variant={AlertVariant.Secondary}
              color={AlertColor.Info}
              marginBottom={spacing(4)}
              description={
                "Orders that match offer's price and quantity will be accepted immediately for offers with reserved assets"
              }
              title={'On confirmation this order will be accepted immediately'}
            />
          )}
          <Text variant={TypographyVariant.subtitle1}>Summary</Text>

          {reviewOrderModalData.summary.map((summaryItem) => (
            <SummaryItem
              key={summaryItem.label}
              label={summaryItem.label}
              value={summaryItem.value}
              valueColor={summaryItem.valueColor}
              marginTop={spacing(4)}
            />
          ))}

          <Layer>
            <StyledQuantityAndPriceCard>
              <PriceItem>
                <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                  Quantity
                </Text>
                <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                  {reviewOrderModalData.quantity}
                </Text>
              </PriceItem>
              <PriceItem>
                <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                  Price
                </Text>
                <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                  {reviewOrderModalData.price}
                </Text>
              </PriceItem>
              <PriceItem>
                <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                  Fee
                </Text>
                <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                  {reviewOrderModalData.fee}
                </Text>
              </PriceItem>
              <PriceItem>
                <Text variant={TypographyVariant.subtitle2}>Total</Text>
                <Text variant={TypographyVariant.subtitle2}>{reviewOrderModalData.total}</Text>
              </PriceItem>
            </StyledQuantityAndPriceCard>
            {!!(reviewOrderModalData.onBehalfOf || reviewOrderModalData.placedBy) && (
              <Divider marginTop={spacing(8)} marginBottom={spacing(8)} />
            )}
            {!!reviewOrderModalData.onBehalfOf && (
              <>
                <Text variant={TypographyVariant.subtitle1} marginTop={spacing(4)}>
                  On Behalf Of
                </Text>
                <SummaryItem label="Account Name" value={reviewOrderModalData.onBehalfOf} />
              </>
            )}
            {!!reviewOrderModalData.placedBy && (
              <>
                <Text variant={TypographyVariant.subtitle1} marginTop={spacing(4)}>
                  Placed By
                </Text>
                <SummaryItem label="Account Name" value={reviewOrderModalData.placedBy} />
              </>
            )}
          </Layer>
        </>
      )}
    </Modal>
  );
});

const PriceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledQuantityAndPriceCard = styled(Card)`
  padding: ${({ theme }) => toSpacing(theme)(8)};
  gap: ${({ theme }) => toSpacing(theme)(4)};
  margin-top: ${({ theme }) => toSpacing(theme)(4)};
`;
