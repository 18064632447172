import type { Dto } from '@aircarbon/utils-common';

import { userAuthenticatedApi } from 'data-provider/fetcher';

export const fetchProjectById = async (props: { projectId: string }) => {
  try {
    const userService = await userAuthenticatedApi();
    const request = await userService.url(`/user/apx/projects/${props.projectId}`).get();

    const response = (await request.json()) as {
      data: Dto.ApxProject;
    };

    return response;
  } catch {
    return null;
  }
};
