import { GERHistoryData } from '../hooks/GERHistoryData';
import { toStatus } from './toStatus';

interface ToHistoryDataProps {
  items: Array<{
    quantity: number;
    status: string;
    createdUtc: string;
    stIds: string;
  }>;
  assetsAllocationsMap: Record<string, number>;
}

interface RetirementHistory {
  batchId: string;
  gerQty: number;
  bcc: number;
  fcc: number;
  pcc: number;
  ccc: number;
  submittedOn: Date;
  status: {
    label: string;
    value: string;
  };
}

export const toHistoryData = (props: ToHistoryDataProps): Array<RetirementHistory> => {
  const result: Array<RetirementHistory> = [];

  props.items.forEach((item) => {
    result.push({
      batchId: item.stIds,
      gerQty: item.quantity,
      bcc: ((props.assetsAllocationsMap['bcc'] || 0) / 100) * item.quantity,
      fcc: ((props.assetsAllocationsMap['fcc'] || 0) / 100) * item.quantity,
      pcc: ((props.assetsAllocationsMap['pcc'] || 0) / 100) * item.quantity,
      ccc: ((props.assetsAllocationsMap['ccc'] || 0) / 100) * item.quantity,
      submittedOn: new Date(item.createdUtc),
      status: toStatus(item.status),
    });
  });

  return result;
};
