import { uniqBy } from 'lodash';
import { TimeInForce } from 'refreshed-pages/market-board-v2/utils/TimeInForce';
import type { OTCOrderResource } from 'refreshed-pages/market-board-v2/utils/fetchOTCOrder';
import { toCMBOrderType } from 'refreshed-pages/market-board-v2/utils/toCMBOrderType';

import { CMBOrderType } from '@aircarbon/ui';

import type { Criteria } from '../../PlaceOrderPage/components/BidForm/utils/Criteria';
import type { FormValue } from '../../PlaceOrderPage/utils/FormValue';
import type { ApiProject } from './fetchProjects';

export const toFormValue = (order: OTCOrderResource, projects: Array<ApiProject>): FormValue<CMBOrderType> => {
  const orderType = toCMBOrderType({
    isAuction: !!order.isAuction,
    sideId: order.sideId,
  });

  if (orderType === CMBOrderType.Bid) {
    return {
      isInstantTrade: !!order.isPrefunded,
      price: String(order.price ?? ''),
      quantity: String(order.qty),
      timeInForce: TimeInForce.GoodTillCancel,
      criteria: projects.length
        ? [order.criteria.find((criteriaItem) => criteriaItem.key === 'VINTAGE_YEAR')]
            .filter((v) => !!v)
            .map(
              (item) =>
                !!item && {
                  key: item.key,
                  value: item.values.join(','),
                },
            )
        : (order.criteria
            .map((item) => ({
              key: item.key,
              value: item.values.join(','),
            }))
            .filter((criteria) => criteria.key !== 'PROJECT_ID') as Array<Criteria>),
      projects: uniqBy(
        projects.map((project) => ({
          title: project.name,
          value: String(project.id),
          description: [
            project.__registry__.name,
            project.registryProjectId ? `ID: ${project.registryProjectId}` : undefined,
          ]
            .filter((v) => !!v)
            .join(' | '),
          registryProjectId: project.registryProjectId,
        })),
        (project) => project.value,
      ),
      maximumQuantity: String(order.maxQty || ''),
      minimumQuantity: String(order.minQty),
      quantityMultiplesOf: String(order.qtyMultipleOf),
      endDate: order.expiryUtc ? new Date(order.expiryUtc).toISOString() : '',
    } as FormValue<CMBOrderType.Bid>;
  }

  if (orderType === CMBOrderType.Auction) {
    return {
      isInstantTrade: !!order.isPrefunded,
      price: String(order.price ?? ''),
      quantity: String(order.qty),
      timeInForce: '',
      maximumQuantity: String(order.maxQty || order.qty || ''),
      minimumQuantity: String(order.minQty ?? '1000'),
      quantityMultiplesOf: String(order.qtyMultipleOf ?? '1000'),
      vintage: order.criteria.find((v) => v.key === 'VINTAGE_YEAR')?.values?.[0] || '',
      auctionPeriod:
        [order.startAtUtc, order.expiryUtc]
          .filter((v) => !!v)
          .map((v) => new Date(String(v)).toISOString())
          .join(',') || '',
      showBestBid: order.showBestBid,
      showBidVolume: order.showBidVolume,
      ...(order.projectId
        ? {
            project: {
              title: order.project?.name,
              description: [
                order.project?.__registry__?.name,
                order.project?.registryProjectId ? `ID: ${order.project.registryProjectId}` : undefined,
              ]
                .filter((v) => !!v)
                .join(' | '),
              value: String(order.projectId),
            },
          }
        : {}),
    } as FormValue<CMBOrderType.Auction>;
  }

  return {
    isInstantTrade: !!order.isPrefunded,
    price: String(order.price ?? ''),
    quantity: String(order.qty),
    timeInForce: TimeInForce.GoodTillCancel,
    ...(order.projectId
      ? {
          project: {
            title: order.project?.name,
            description: [
              order.project?.__registry__?.name,
              order.project?.registryProjectId ? `ID: ${order.project.registryProjectId}` : undefined,
            ]
              .filter((v) => !!v)
              .join(' | '),
            value: String(order.projectId),
          },
        }
      : {}),
    vintage: order.criteria.find((v) => v.key === 'VINTAGE_YEAR')?.values?.[0] || '',
    maximumQuantity: String(order.maxQty),
    minimumQuantity: String(order.minQty),
    quantityMultiplesOf: String(order.qtyMultipleOf),
    endDate: order.expiryUtc ? new Date(order.expiryUtc).toISOString() : '',
  } as FormValue<CMBOrderType.Offer>;
};
