import { differenceInCalendarDays, format, formatDistanceToNowStrict, isPast } from 'date-fns';

interface ToFormattedDateProps {
  date?: string;
  prefix?: string;
}
export const toFormattedDate = (props: ToFormattedDateProps) => {
  const { date, prefix } = props;
  if (!date) {
    return '';
  }

  const dateObj = new Date(date);

  if (isPast(dateObj)) {
    return 'Closed';
  }

  const currentDate = new Date();

  if (differenceInCalendarDays(dateObj, currentDate) <= 1) {
    return `${prefix ? prefix + ' ' : ''}${formatDistanceToNowStrict(dateObj, {
      addSuffix: true,
    })}`;
  }

  return `${prefix ? prefix + ' ' : ''}${format(dateObj, 'd MMM yyyy, p')}`;
};
