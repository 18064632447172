import type React from 'react';
import type { ReactNode } from 'react';
import { Alerts } from 'refreshed-component/atoms/Alerts';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

const PageRoot = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  padding: var(${Spacing.large});
  display: flex;
  flex-direction: column;
  gap: var(${Spacing.large});
`;

export const Sections = styled.div<{ type?: 'plain' | 'card' }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(${Spacing.large});
  ${({ type }) => {
    return type === 'card'
      ? `
        border-radius: var(${Radius.medium});
        background: var(${Colors.gray_0});
        padding: var(${Spacing.large});
    `
      : ``;
  }}
  &:last-child {
    flex: auto;
  }
  &:empty {
    display: none;
  }
`;

export const PageSections = ({
  children,
  type,
  className,
}: {
  children?: ReactNode;
  type?: 'plain' | 'card';
  className?: string;
}) => {
  return (
    <Sections type={type} className={className}>
      {children}
    </Sections>
  );
};

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: var(${Spacing.base});
  align-items: center;
`;

const Title = styled.div`
  position: relative;
  font-size: var(${FontSize.base});
  font-weight: var(${FontWeight.medium});
`;

const Controls = styled.div`
  position: relative;
  flex: 1 1 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > div {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: var(${Spacing.base});
  }
`;

export const PageHolder = ({
  title,
  controls,
  children,
}: {
  title?: string;
  controls?: {
    primary?: ReactNode;
    secondary?: ReactNode;
  };
  children: React.ReactElement<typeof PageSections> | (React.ReactElement<typeof PageSections> | undefined)[];
}) => {
  return (
    <PageRoot>
      {title && controls?.primary && controls.secondary && (
        <Sections>
          <Header>
            {title && <Title>{title}</Title>}
            <Controls>
              <div>{controls?.primary}</div>
              <div>{controls?.secondary}</div>
            </Controls>
          </Header>
        </Sections>
      )}
      {children}
    </PageRoot>
  );
};
