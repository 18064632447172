import { useState } from 'react';
import { Button } from 'refreshed-component/atoms/Button';
import { Dropdown, type DropdownItem } from 'refreshed-component/atoms/Dropdown';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize } from 'refreshed-component/design-system';
import { InputPhoneNumber } from 'refreshed-component/molecules/InputPhoneNumber';
import { countriesMock } from 'refreshed-component/molecules/InputPhoneNumber/utils/countries.mock';
import styled from 'styled-components';

import { FieldWrapper } from 'components/styled/Styled';

interface ProponentValue {
  id?: number;
  companyName: string;
  companyInvolvement: string;
  contactPersonName: string;
  contactPersonRole: string;
  email: string;
  phone: {
    value: string;
    phoneCode: string;
  };
}

const Proponent: React.FC<{
  id: number;
  label?: string;
  value: ProponentValue;
  companyInvolvements: DropdownItem[];
  contactPersonRoles: DropdownItem[];
  error?: Record<string, string>;
  onChange(value: ProponentValue): void;
  onRemove?(): void;
}> = (props) => {
  const { id, label, value, error, companyInvolvements, contactPersonRoles, onChange, onRemove } = props;

  const onChangeFormValue =
    <K extends keyof typeof value>(key: K) =>
    (fieldValue: (typeof value)[K]) => {
      const newValue = {
        ...value,
        [key]: fieldValue,
      };
      onChange(newValue);
    };

  return (
    <>
      {(!!label || !!onRemove) && (
        <ProponentHeader>
          {!!label && <Text size={FontSize.large}>{label}</Text>}
          {!!onRemove && (
            <Button
              onClick={onRemove}
              config={{
                variant: 'icon',
                color: 'outlined',
                icon: {
                  left: <Icon width={20} height={20} type={IconType.Trash} />,
                },
              }}
            />
          )}
        </ProponentHeader>
      )}
      <div className="flex w-full">
        <div className="pr-2 w-1/2">
          <FieldWrapper>
            <Text size={FontSize.small}>Company Name*</Text>
            <Input
              config={{
                color: !!error?.[`proponents[${id}].companyName`] ? 'error' : 'gray',
              }}
              value={value.companyName}
              onChange={(e) => {
                onChangeFormValue('companyName')(e.target.value);
              }}
            />
            {!!error?.[`proponents[${id}].companyName`] && (
              <Text color={Colors.danger_700} size={FontSize.small}>
                {error?.[`proponents[${id}].companyName`]}
              </Text>
            )}
          </FieldWrapper>
        </div>
        <div className="pl-2 w-1/2">
          <FieldWrapper>
            <Text size={FontSize.small}>Company Involvement*</Text>
            <Dropdown
              list={companyInvolvements}
              selected={value.companyInvolvement ? [value.companyInvolvement] : []}
              config={{
                color: 'gray',
              }}
              error={error?.[`proponents[${id}].companyInvolvement`]}
              onSelectItem={(selectedItems) => {
                onChangeFormValue('companyInvolvement')(selectedItems.id as string);
              }}
              placeholder="Select"
            />
          </FieldWrapper>
        </div>
      </div>
      <div className="flex w-full">
        <div className="pr-2 w-1/2">
          <FieldWrapper>
            <Text size={FontSize.small}>Contact Person's Name*</Text>
            <Input
              config={{
                color: !!error?.[`proponents[${id}].contactPersonName`] ? 'error' : 'gray',
              }}
              value={value.contactPersonName}
              onChange={(e) => onChangeFormValue('contactPersonName')(e.target.value)}
            />
            {!!error?.[`proponents[${id}].contactPersonName`] && (
              <Text color={Colors.danger_700} size={FontSize.small}>
                {error?.[`proponents[${id}].contactPersonName`]}
              </Text>
            )}
          </FieldWrapper>
        </div>
        <div className="pl-2 w-1/2">
          <FieldWrapper>
            <Text size={FontSize.small}>Contact Person's Role*</Text>
            <Dropdown
              list={contactPersonRoles}
              selected={value.contactPersonRole ? [value.contactPersonRole] : []}
              config={{
                color: 'gray',
              }}
              error={error?.[`proponents[${id}].contactPersonRole`]}
              onSelectItem={(selectedItems) => {
                onChangeFormValue('contactPersonRole')(selectedItems.id as string);
              }}
              placeholder="Select"
            />
          </FieldWrapper>
        </div>
      </div>
      <div className="flex w-full">
        <div className="pr-2 w-1/2">
          <FieldWrapper>
            <Text size={FontSize.small}>Email Address*</Text>
            <Input
              config={{
                color: !!error?.[`proponents[${id}].email`] ? 'error' : 'gray',
              }}
              value={value.email}
              onChange={(e) => onChangeFormValue('email')(e.target.value)}
            />
            {!!error?.[`proponents[${id}].email`] && (
              <Text color={Colors.danger_700} size={FontSize.small}>
                {error?.[`proponents[${id}].email`]}
              </Text>
            )}
          </FieldWrapper>
        </div>
        <div className="pl-2 w-1/2">
          <InputPhoneNumber
            label="Phone Number*"
            phoneCode={value.phone.phoneCode}
            value={value.phone.value}
            countries={countriesMock}
            onChange={onChangeFormValue('phone')}
            error={error?.[`proponents[${id}].phone.value`]}
          />
        </div>
      </div>
    </>
  );
};

export interface ProponentsValue {
  proponents: Array<ProponentValue>;
}

export interface ProponentsMetadata {
  companyInvolvements: DropdownItem[];
  contactPersonRoles: DropdownItem[];
}

export const Proponents: React.FC<{
  value: ProponentsValue;
  metadata: ProponentsMetadata;
  error?: Record<string, string>;
  onChange(value: ProponentsValue): void;
}> = ({ value, error, metadata, onChange }) => {
  const onChangeProponent = (index: number) => (newProponentValue: ProponentValue) => {
    const newValue = [...value.proponents];
    newValue[index] = newProponentValue;

    onChange({
      proponents: newValue,
    });
  };

  const onRemoveProponent = (index: number) => {
    const newValue = [...value.proponents];
    newValue.splice(index, 1);

    onChange({
      proponents: newValue,
    });
  };

  const onPressAddProponent = () => {
    const newValue = [
      ...value.proponents,
      {
        companyName: '',
        companyInvolvement: '',
        contactPersonName: '',
        contactPersonRole: '',
        email: '',
        phone: {
          phoneCode: '+971',
          value: '',
        },
      },
    ];
    onChange({
      proponents: newValue,
    });
  };

  return (
    <>
      {value.proponents.map((item, index) => (
        <Proponent
          id={index}
          key={index}
          value={item}
          error={error}
          companyInvolvements={metadata.companyInvolvements}
          contactPersonRoles={metadata.contactPersonRoles}
          onChange={onChangeProponent(index)}
          label={index !== 0 ? `Proponent ${index + 1}` : undefined}
          onRemove={index !== 0 ? () => onRemoveProponent(index) : undefined}
        />
      ))}
      <StyledAddProponentButton
        config={{
          color: 'outlined',
          icon: {
            left: <Icon width={20} height={20} type={IconType.PlusCircle} />,
          },
        }}
        onClick={onPressAddProponent}
      >
        Add Proponent
      </StyledAddProponentButton>
    </>
  );
};

const ProponentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  border-top: 1px solid var(${Colors.gray_200});
  margin-top: 24px;
  margin-bottom: 24px;
`;

const StyledAddProponentButton = styled(Button)`
  margin-top: 16px;
`;
