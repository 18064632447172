import { type DetailedHTMLProps, type HTMLAttributes, ReactNode, useState } from 'react';
import type { Colors } from 'refreshed-component/design-system';
import { toast } from 'refreshed-component/molecules/toast';

import { User } from 'state/user';

import Loading from './Loading';

interface Props {
  url: string;
  fileName: string;
  loadingColor?: Colors;
}

const onDownload = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
  link.parentNode?.removeChild(link);
};

export const downloadFile = (url: string, authToken: string, fileName: string) =>
  // TODO: Implement data-mutation
  fetch(url, {
    method: 'POST',
    headers: { authorization: `Bearer ${authToken}` },
  })
    .then((resp) => resp.blob())
    .then((blob) => onDownload(blob, fileName))
    .catch((error) => toast.error(error.messages));

const DownloadText = ({
  url,
  fileName,
  loadingColor,
  ...textProps
}: DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  return (
    <span
      {...textProps}
      className={`cursor-pointer ${textProps.className}`}
      onClick={async (event) => {
        if (isLoading) return;
        setIsLoading(true);
        const authToken = await getAuthToken();
        await downloadFile(url, authToken, fileName);
        setIsLoading(false);
        textProps.onClick?.(event);
      }}
    >
      {isLoading && <Loading isOverLay={false} size="small" color={loadingColor} />}
      {textProps.children}
    </span>
  );
};

export default DownloadText;
