import { Route, useHistory } from 'react-router-dom';
import Loading from 'refreshed-component/molecules/Loading';

import { AssetCategory } from '@aircarbon/utils-common';

import { ProjectDetail } from '../ProjectDetail';
import type { ProjectInformationProps } from '../ProjectDetail/components/ProjectInformation';
import { useProjectDetail } from './hooks/useProjectDetail';

export const ProjectDetailPage = () => {
  const { project, isLoading } = useProjectDetail();
  const history = useHistory();

  if (isLoading || !project) {
    return <Loading />;
  }

  const onPressTrade = () => {
    history.push(`/account/trade?assetCategoryId=${AssetCategory.fct}&projectId=${project.id}`);
  };

  const onBack = () => {
    history.goBack();
  };

  return (
    <>
      <Route path={'/account/apx/marketplace/:projectId'} exact>
        <ProjectDetail
          onBack={onBack}
          title={project.name}
          projectInformation={project as ProjectInformationProps}
          milestones={project.milestones}
          creditingPeriod={project.creditingPeriod}
          markets={project.markets}
          onPressTrade={onPressTrade}
        />
      </Route>
      <Route path={'/account/apx/my-projects/:projectId'} exact>
        <ProjectDetail
          onBack={onBack}
          title={project.name}
          projectInformation={project as ProjectInformationProps}
          milestones={project.milestones}
          creditingPeriod={project.creditingPeriod}
          markets={project.markets}
        />
      </Route>
    </>
  );
};
