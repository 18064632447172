import { ReactNode } from 'react';
import { Colors, FontSize, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { GREEN, RED } from './theme';

const Wrapper = styled.div<{ border?: Border; borderStyle?: BorderStyle }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  ${(props) => {
    if (props.border === 'red') {
      return `border-top: 1px ${props.borderStyle} ${RED.bg.dark}; border-bottom: 1px ${props.borderStyle} ${RED.bg.dark};`;
    } else if (props.border === 'green') {
      return `border-top: 1px ${props.borderStyle} ${GREEN.bg.dark}; border-bottom: 1px ${props.borderStyle} ${GREEN.bg.dark};`;
    } else if (props.border) {
      return `border-top: 1px ${props.borderStyle} ${props.border}; border-bottom: 1px ${props.borderStyle} ${props.border};`;
    } else {
      return `border-top: 1px ${props.borderStyle} #444444; border-bottom: 1px ${props.borderStyle} #444444;`;
    }
  }}
  align-items: center;
  padding: var(${Spacing.small});
`;

type Border = 'red' | 'green' | 'gray' | string;
type BorderStyle = 'solid' | 'dotted' | 'dashed';

export default function InfoList(props: {
  border?: Border;
  borderStyle?: BorderStyle;
  label?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  info?: {
    label: ReactNode;
    value: ReactNode;
    font?: 'default' | 'code';
    fontSize?: FontSize;
    color?: Colors;
    _key?: string;
  }[];
}) {
  return (
    <Wrapper border={props.border} className={props.className} borderStyle={props.borderStyle || 'solid'}>
      {props.info?.map((item, index) => {
        return (
          <div key={`${item._key || item.label}${index}`} className="flex flex-row w-full">
            <div className="flex-1">
              <div
                style={{
                  color: `var(${item.color || Colors.gray_500})`,
                  fontSize: `var(${item.fontSize || FontSize.small})`,
                }}
              >
                {item.label}
              </div>
            </div>
            <div
              className={`${item.font === 'code' ? 'code' : ''}`}
              style={{
                color: `var(${item.color || Colors.gray_500})`,
                fontSize: `var(${item.fontSize || FontSize.small})`,
              }}
            >
              {item.value}
            </div>
          </div>
        );
      })}
    </Wrapper>
  );
}
