import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize } from 'refreshed-component/design-system';

import {
  type SelectProjectAndContractValue,
  useProjectAndContractSelector,
} from './hooks/useProjectAndContractSelector';

interface SelectProjectAndContractProps {
  error?: Record<string, string>;
  onChange(value: SelectProjectAndContractValue): void;
}

export const SelectProjectAndContract: React.FunctionComponent<SelectProjectAndContractProps> = (props) => {
  const {
    isLoading,
    project,
    contract,
    vintageYear,
    projects,
    contracts,
    vintageYears,
    changeProject,
    changeContract,
    changeVintageYear,
  } = useProjectAndContractSelector({
    onChange: props.onChange,
  });

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col gap-xs">
        <Text size={FontSize.small}>Project</Text>
        <Dropdown
          config={{
            color: 'gray',
            size: 'base',
          }}
          selected={[project]}
          onSelectItem={(list) => {
            changeProject(list.id as number);
          }}
          isSearchable
          placeholder="Select project"
          list={projects}
        ></Dropdown>
        {!!props.error?.projectId && (
          <Text size={FontSize.small} color={Colors.danger_700}>
            {props.error?.projectId}
          </Text>
        )}
      </div>
      {!!project && (
        <div className="flex flex-col gap-xs">
          <Text size={FontSize.small}>Contract</Text>
          <Dropdown
            config={{
              color: 'gray',
              size: 'base',
            }}
            isSearchable
            selected={[contract]}
            list={contracts}
            onSelectItem={(list) => {
              changeContract(list.id as number);
            }}
            placeholder="Select contract"
          ></Dropdown>
          {!!props.error?.contractId && (
            <Text size={FontSize.small} color={Colors.danger_700}>
              {props.error?.contractId}
            </Text>
          )}
        </div>
      )}
      {!!contract && (
        <div className="flex flex-col gap-xs">
          <Text size={FontSize.small}>Vintage Year</Text>
          <Dropdown
            list={vintageYears}
            selected={[vintageYear]}
            config={{
              color: 'gray',
              size: 'base',
            }}
            onSelectItem={(list) => {
              changeVintageYear(list.id as number);
            }}
            placeholder="Select vintage year"
          />
          {!!props.error?.vintage && (
            <Text size={FontSize.small} color={Colors.danger_700}>
              {props.error?.vintage}
            </Text>
          )}
        </div>
      )}
    </>
  );
};
