import { useQuery } from 'react-query';
import { getProjectMetaBy } from 'refreshed-pages/settlement/helpers';

import { formatter } from '@aircarbon/utils-common';
import type { CommonInterfaces } from '@aircarbon/utils-common';

import { fetchAddressData } from 'data-provider/user/fetchAddressData';

type Token = {
  batchId: number;
  stId: number;
  qty: number;
  tokTypeId: number;
  tokTypeName: string;
};

type UserProject = {
  name: string;
  metaData: Record<string, string>;
  tokens: Token[];
};
type UserProjects = Record<string, UserProject>;

const parseSecToken = (secToken: any) => {
  return {
    stId: secToken ? formatter.hex2int(secToken.stId) : 0,
    qty: secToken ? formatter.hex2int(secToken.currentQty) : 0,
    tokTypeId: secToken ? formatter.hex2int(secToken.tokTypeId) : 0,
    tokTypeName: secToken ? secToken.tokTypeName : '',
    batchId: 0,
  };
};

export function parseProjects(addressData?: CommonInterfaces.AddressData) {
  if (!addressData) {
    return null;
  }

  const userProjects: UserProjects = {};
  addressData?.batches?.forEach((batch) => {
    if (batch.tokTypeId) {
      const batchId = Number(batch.batchId);
      const metaData: Record<string, any> = batch.metaData;
      const key = getProjectMetaBy('PROJECT_ID', metaData);

      const token = addressData?.account?.tokens?.filter(
        (secToken: any) => formatter.hex2int(secToken.batchId) === batchId,
      )?.[0];

      if (!userProjects[key]) {
        userProjects[key] = {
          metaData,
          tokens: [],
          name: getProjectMetaBy('PROJECT_NAME', metaData),
        };
      }

      if (token) {
        const parsedToken = parseSecToken(token);
        parsedToken.batchId = batchId;
        userProjects[key].tokens.push(parsedToken);
      }
    }
  });

  return userProjects;
}

function getBatches(addressData?: CommonInterfaces.AddressData) {
  if (!addressData) {
    return [];
  }
  return (
    addressData?.batches?.map((batch) => {
      const id = Number(batch.batchId);
      const metaData: Record<string, any> = batch.metaData;
      const projectId = getProjectMetaBy('PROJECT_ID', metaData);

      return {
        id,
        projectId,
      };
    }) ?? []
  );
}

function useAddressData({
  address,
  options = {},
  queryName = '/api/contract/address-data',
}: {
  address: string;
  options?: Record<string, any>;
  queryName?: string;
}) {
  const { data, isLoading, error, refetch } = useQuery([queryName, address], () => fetchAddressData(address), {
    ...options,
    refetchOnWindowFocus: false,
  });

  return {
    addressData: data,
    isLoading,
    error,
    refetch,
    selectors: {
      userProjects: () => {
        return parseProjects(data);
      },
      getAllBatches: () => {
        return getBatches(data);
      },
    },
  };
}

export default useAddressData;
