import type { Colors } from 'refreshed-component/design-system';
import styled, { type CSSProperties } from 'styled-components';

import ArrowRight from './icons/arrow-right';
import Ban from './icons/ban';
import Bids from './icons/bids';
import Calendar from './icons/calendar';
import CarbonCredits from './icons/carbon-credits';
import ChartSquareBar from './icons/chart-square-bar';
import Check from './icons/check';
import CheckCircle from './icons/check-circle';
import ChevronDown from './icons/chevron-down';
import ChevronLeft from './icons/chevron-left';
import ChevronRight from './icons/chevron-right';
import ChevronUp from './icons/chevron-up';
import Clock from './icons/clock';
import CO2 from './icons/co2';
import Copy from './icons/copy';
import Database from './icons/database';
import Deposit from './icons/deposit';
import DocumentIcon from './icons/document';
import DocumentDownload from './icons/document-download';
import DoubleLeaves from './icons/double-leaves';
import Download from './icons/download';
import Drop from './icons/drop';
import Empty from './icons/empty';
import Exclamation from './icons/exclamation';
import ExclamationCircle from './icons/exclamation-circle';
import Eye from './icons/eye';
import Filter from './icons/filter';
import InformationCircle from './icons/information-circle';
import InformationCircleFilled from './icons/information-circle-filled';
import LockClosed from './icons/lock-closed';
import LockOpen from './icons/lock-open';
import Menu from './icons/menu';
import MinusCircle from './icons/minus-circle';
import Pencil from './icons/pencil';
import PlusCircle from './icons/plus-circle';
import PoweredByACXLogo from './icons/powered-by-acx-logo';
import REC from './icons/rec';
import Refresh from './icons/refresh';
import Retire from './icons/retire';
import RSS from './icons/rss';
import Search from './icons/search';
import Suitcase from './icons/suitcase';
import Total from './icons/total';
import TotalBatches from './icons/total-batches';
import Trash from './icons/trash';
import Upload from './icons/upload';
import UserAdd from './icons/user-add';
import UserCircle from './icons/user-circle';
import ViewBoards from './icons/view-boards';
import ViewGrid from './icons/view-grid';
import Wallet from './icons/wallet';
import Withdraw from './icons/withdraw';
import X from './icons/x';
import XCircle from './icons/x-circle';

export enum IconType {
  UserCircle = 'UserCircle',
  ChartSquareBar = 'ChartSquareBar',
  ArrowRight = 'ArrowRight',
  Bids = 'Bids',
  ChevronDown = 'ChevronDown',
  ChevronRight = 'ChevronRight',
  ChevronLeft = 'ChevronLeft',
  ChevronUp = 'ChevronUp',
  Withdraw = 'Withdraw',
  Deposit = 'Deposit',
  Filter = 'Filter',
  Search = 'Search',
  PlusCircle = 'PlusCircle',
  Pencil = 'Pencil',
  X = 'X',
  InformationCircle = 'InformationCircle',
  CheckCircle = 'CheckCircle',
  XCircle = 'XCircle',
  InformationCircleFilled = 'InformationCircleFilled',
  CarbonCredits = 'CarbonCredits',
  Check = 'Check',
  Calendar = 'Calendar',
  Upload = 'Upload',
  ExclamationCircle = 'ExclamationCircle',
  Exclamation = 'Exclamation',
  Document = 'Document',
  DocumentDownload = 'DocumentDownload',
  Download = 'Download',
  Clock = 'Clock',
  Eye = 'Eye',
  Ban = 'Ban',
  Suitcase = 'Suitcase',
  MinusCircle = 'MinusCircle',
  LockClosed = 'LockClosed',
  LockOpen = 'LockOpen',
  UserAdd = 'UserAdd',
  RSS = 'RSS',
  PoweredByACXLogo = 'PoweredByACXLogo',
  Wallet = 'Wallet',
  CO2 = 'CO2',
  Database = 'Database',
  TotalBatches = 'TotalBatches',
  Menu = 'Menu',
  Total = 'Total',
  Empty = 'Empty',
  ViewGrid = 'ViewGrid',
  ViewBoards = 'ViewBoards',
  Refresh = 'Refresh',
  Retire = 'Retire',
  Drop = 'Drop',
  DoubleLeaves = 'DoubleLeaves',
  Copy = 'copy',
  Trash = 'Trash',
  REC = 'REC',
}

export const IconMapping = {
  [IconType.ArrowRight]: ArrowRight,
  [IconType.Bids]: Bids,
  [IconType.ChevronDown]: ChevronDown,
  [IconType.ChevronRight]: ChevronRight,
  [IconType.ChevronLeft]: ChevronLeft,
  [IconType.ChevronUp]: ChevronUp,
  [IconType.Deposit]: Deposit,
  [IconType.Withdraw]: Withdraw,
  [IconType.Filter]: Filter,
  [IconType.Search]: Search,
  [IconType.PlusCircle]: PlusCircle,
  [IconType.Pencil]: Pencil,
  [IconType.X]: X,
  [IconType.CheckCircle]: CheckCircle,
  [IconType.XCircle]: XCircle,
  [IconType.InformationCircle]: InformationCircle,
  [IconType.InformationCircleFilled]: InformationCircleFilled,
  [IconType.CarbonCredits]: CarbonCredits,
  [IconType.Check]: Check,
  [IconType.Calendar]: Calendar,
  [IconType.Upload]: Upload,
  [IconType.ExclamationCircle]: ExclamationCircle,
  [IconType.Document]: DocumentIcon,
  [IconType.Download]: Download,
  [IconType.Clock]: Clock,
  [IconType.Eye]: Eye,
  [IconType.DocumentDownload]: DocumentDownload,
  [IconType.Ban]: Ban,
  [IconType.Suitcase]: Suitcase,
  [IconType.MinusCircle]: MinusCircle,
  [IconType.LockClosed]: LockClosed,
  [IconType.LockOpen]: LockOpen,
  [IconType.UserAdd]: UserAdd,
  [IconType.RSS]: RSS,
  [IconType.PoweredByACXLogo]: PoweredByACXLogo,
  [IconType.Exclamation]: Exclamation,
  [IconType.Wallet]: Wallet,
  [IconType.CO2]: CO2,
  [IconType.Database]: Database,
  [IconType.TotalBatches]: TotalBatches,
  [IconType.Menu]: Menu,
  [IconType.Total]: Total,
  [IconType.UserCircle]: UserCircle,
  [IconType.ChartSquareBar]: ChartSquareBar,
  [IconType.ViewBoards]: ViewBoards,
  [IconType.ViewGrid]: ViewGrid,
  [IconType.Refresh]: Refresh,
  [IconType.Empty]: Empty,
  [IconType.Retire]: Retire,
  [IconType.Drop]: Drop,
  [IconType.DoubleLeaves]: DoubleLeaves,
  [IconType.Copy]: Copy,
  [IconType.Trash]: Trash,
  [IconType.REC]: REC,
};

export type Props = {
  type: IconType;
  url?: string;
  width?: number | 'auto';
  height?: number | 'auto';
  color?: Colors;
};

const IconRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > svg {
    width: 100%;
    height: 100%;
    path[fill] {
      fill: currentColor;
    }
    path[stroke] {
      stroke: currentColor;
    }
  }
`;

export const Icon = ({
  width = 'auto',
  height = 'auto',
  type,
  color,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & Props) => {
  return (
    <IconRoot
      {...(props as any)}
      style={{
        width: width === 'auto' ? width : `${width}px`,
        minWidth: width === 'auto' ? width : `${width}px`,
        height: height === 'auto' ? height : `${height}px`,
        minHeight: height === 'auto' ? height : `${height}px`,
        color: color ? `var(${color})` : undefined,
        ...props.style,
      }}
    >
      {IconMapping[type]}
    </IconRoot>
  );
};

const SvgRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > svg {
    width: 100%;
    height: 100%;
  }
`;

export const PlainSVGIcon = ({
  type,
  width,
  height,
  style,
}: {
  type: IconType;
  width?: number | 'auto';
  height?: number | 'auto';
  style?: CSSProperties;
}) => {
  return (
    <SvgRoot
      style={{
        width: width === 'auto' ? width : `${width}px`,
        height: height === 'auto' ? height : `${height}px`,
        ...style,
      }}
    >
      {IconMapping[type]}
    </SvgRoot>
  );
};
