import { useState } from 'react';

export default function usePagination(page: number, defaultLimit = 10) {
  const [currentPage, setPage] = useState(page);
  const [limit, setLimit] = useState(defaultLimit);

  const changePage = (newPage: number) => setPage(newPage);
  const changeLimit = (newLimit: number) => setLimit(newLimit);

  return {
    currentPage,
    changePage,
    limit,
    changeLimit,
  };
}
