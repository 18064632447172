import { useState } from 'react';

import { ButtonSize, ButtonVariant, IconName, Knob, ListItem, Popover, styled } from '@aircarbon/ui';

export const MoreActionsDropdown: React.FunctionComponent<{
  isDisabled?: boolean;
  actions: Array<{
    label: string;
    onPress(): void;
  }>;
}> = ({ actions, isDisabled }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  return (
    <Popover
      isVisible={isPopoverVisible}
      onHide={() => setIsPopoverVisible(false)}
      value={
        <ActionsList>
          {actions.map((action, index) => (
            <ListItem
              key={index}
              title={action.label}
              onPress={() => {
                setIsPopoverVisible(false);
                action.onPress();
              }}
            />
          ))}
        </ActionsList>
      }
    >
      <Knob
        isDisabled={isDisabled}
        isActive={isPopoverVisible}
        size={ButtonSize.xs}
        variant={ButtonVariant.outlined}
        icon={IconName.DotsVertical}
        onPress={() => setIsPopoverVisible(true)}
      />
    </Popover>
  );
};

const ActionsList = styled.div`
  display: flex;
  flex-direction: column;
`;
