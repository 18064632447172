import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

export const ProjectInformation: React.FC<{
  batchId: string;
  quantity: string;
}> = (props) => {
  return (
    <StyledProjectInformation>
      <Item label="Project Name" value={'GER'} />
      <Item label="Batch ID" value={props.batchId} />
      <Item label="Quantity (CET)" value={props.quantity} />
    </StyledProjectInformation>
  );
};

const Item: React.FC<{
  label: string;
  value: string;
}> = (props) => {
  const { label, value } = props;
  return (
    <StyledItem>
      <Text size={FontSize.small} color={Colors.gray_500}>
        {label}
      </Text>
      <Text weight={FontWeight.semibold}>{value}</Text>
    </StyledItem>
  );
};

const StyledItem = styled.div``;

export const StyledProjectInformation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  background: var(${Colors.gray_100});
  margin-bottom: 16px;
`;
