import { pino, stdTimeFunctions } from 'pino';

const isBrowser = typeof window !== 'undefined';

// Usage: logger[logLevel]([mergingObject],[message])
const logger = pino(
  isBrowser
    ? {
        level: process.env.LOGLEVEL || process.env.LOG_LEVEL || 'info',
        timestamp: stdTimeFunctions.isoTime,
        browser: {
          asObject: true,
        },
        hooks: {},
      }
    : {
        level: process.env.LOGLEVEL || process.env.LOG_LEVEL || 'info',
        hooks: {},
        timestamp: stdTimeFunctions.isoTime,
      },
);

export default logger;
