// eslint-disable-next-line no-shadow
enum TokenRetirementStatus {
  SUBMITTED = 'SUBMITTED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
  REVERTED = 'REVERTED',
}

export default TokenRetirementStatus;
