/**
 * check presence of html tags in string
 *
 * @param {string} value - storage key
 * @returns will return true if it contains HTML otherwise false.
 *
 */
export const containsHtml = (value: string): boolean => {
  const regex = /<[a-z][\s\S]*>/gi; // regex to check presence of html tags in string.
  return regex.test(value);
};

/**
 * check presence of urls in string
 *
 * @param {string} value - storage key
 * @returns will return true if it contains URL otherwise false.
 *
 */
export const containsUrl = (value: string): boolean => {
  const regex = /(([A-z]*):\/\/)*([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/gi; // regex to check presence of urls in string.
  return regex.test(value);
};
