import { Dto } from '@aircarbon/utils-common';

export const toOfferDescription = (
  project?: {
    id: number;
    name: string;
    status: Dto.ProjectStatus;
    registryProjectId: string;
    __registry__?: {
      name: string;
    };
  } | null,
) => {
  if (!project) {
    return '';
  }

  return [project.__registry__?.name, project.registryProjectId ? `ID: ${project.registryProjectId}` : undefined]
    .filter((v) => !!v)
    .join(' | ');
};
