import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { createContainer } from 'unstated-next';

import { logger } from '@aircarbon/utils-common';

import { fetchPublicSettings } from 'data-provider/setting/fetchPublicSettings';
import { fetchUserSettings } from 'data-provider/setting/fetchUserSettings';

import { Entity } from './entity';

export type ScreenSizes = 'small' | 'medium' | 'large' | 'xlarge';

export enum ExplorerType {
  NONE = 'NONE',
  LIMITED = 'LIMITED',
  FULL = 'FULL',
}

export interface Setting {
  id: number;
  config_key: string;
  config_value: string;
}

export function useUI(): Record<any, any> {
  const { entity } = Entity.useContainer();
  const [userAuthenticated, setUserAuthenticated] = useState<string>();
  const [blockchainView, setBlockchainView] = useState<boolean>(false);
  const [showTxSidebar, setShowTxSidebar] = useState<boolean>(false);
  const [screenSize, setScreenSize] = useState<ScreenSizes>('small');
  const [showUserMenu, setShowUserMenu] = useState(false);

  const { data } = useQuery([userAuthenticated, 'settings'], async () => {
    const result = userAuthenticated ? await fetchUserSettings() : await fetchPublicSettings();
    if (result.status !== 'success') {
      logger.error(data, 'Error fetching settings');
      return Promise.reject(result.data);
    }
    return result.data;
  });

  const getSetting = useCallback(
    (settingKey: string) => {
      return data?.settings?.find((setting) => setting?.config_key === settingKey)?.config_value;
    },
    [data],
  );

  const getAnnouncement = useCallback(() => getSetting('web_settings_announcement') ?? '', [getSetting]);
  const headerSize = useCallback(() => (getAnnouncement() !== '' ? '110px' : '70px'), [getAnnouncement]);
  const cynopsisUrl = useCallback(() => getSetting('global_cypnopsis_webUrl'), [getSetting]);
  const entityName = entity?.name;
  const getAccountNames = useCallback(() => {
    return data?.accounts.reduce((accounts, account) => {
      return {
        ...accounts,
        [account.account.toLowerCase()]: `${entityName} Pte. Ltd.`,
        [account.account.toUpperCase()]: `${entityName} Pte. Ltd.`,
      };
    }, {});
  }, [data, entityName]);

  const uiStatus = useMemo(
    () => ({
      isReady: () => !!data?.settings,
      isExchangeClosed: () => Number(getSetting('web_settings_closeExchange')) === 1,
      openDemoRegistration: () => Number(getSetting('web_settings_openDemoRegistration')) === 1,
      getExplorerType: () => getSetting('web_settings_explorerType'),
      isMFADisabled: () => Number(getSetting('web_settings_disableMFA')) === 1, // TODO: deprecated, to be removed
      isDashboardDisabled: () => Number(getSetting('web_settings_disableDashboard')) === 1,
      isParticipantBrokerEnabled: () => Number(getSetting('web_settings_enabled_participantBroker')) === 1,
    }),
    [data, getSetting],
  );

  return useMemo(
    () => ({
      blockchainView,
      setBlockchainView,
      screenSize,
      setScreenSize,
      showUserMenu,
      setShowUserMenu,
      showTxSidebar,
      setShowTxSidebar,
      getSetting,
      cynopsisUrl,
      getAccountNames,
      uiStatus,
      getAnnouncement,
      headerSize,
      setUserAuthenticated,
    }),
    [
      blockchainView,
      screenSize,
      showUserMenu,
      showTxSidebar,
      getSetting,
      cynopsisUrl,
      getAccountNames,
      uiStatus,
      getAnnouncement,
      headerSize,
    ],
  );
}

export const UI = createContainer(useUI);
