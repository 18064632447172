import { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useFilters } from 'refreshed-pages/market-board-v2/hooks/useFilters';
import { usePlaceOrderModal } from 'refreshed-pages/market-board-v2/hooks/usePlaceOrderModal';
import { toMetaFields } from 'refreshed-pages/market-board-v2/utils/toMetaFields';
import { toPlaceOrderModalOrderDetails } from 'refreshed-pages/market-board-v2/utils/toPlaceOrderModalOrderDetails';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  Card,
  CardCMB,
  CardCMBProps,
  CardVariant,
  IconName,
  Layer,
  Masonry,
  styled,
  toLayerBackground,
  toSpacing,
  useSpacing,
} from '@aircarbon/ui';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { UI } from 'state/ui';
import { User } from 'state/user';

import { FilterBadges } from './components/FilterBadges';
import { ListingCard } from './components/ListingCard';
import { OrderTypeTabs } from './components/OrderTypeTabs';
import { SubHeader } from './components/SubHeader';
import { useListings } from './hooks/useListings';

export const Listings: React.FunctionComponent<{
  isFiltersVisible: boolean;
  onPressFilter(): void;
  onPressShowOpenRequests(): void;
}> = (props) => {
  const { isFiltersVisible, onPressFilter, onPressShowOpenRequests } = props;
  const { listings, loadMore, isLoading, totalCount, getRawDataItemById } = useListings();
  const { screenSize } = UI.useContainer();
  const { spacingInPixels } = useSpacing();
  const listingsContainerRef = useRef(null);
  const history = useHistory();
  const { modal: placeOrderModal, open: openPlaceOrderModal } = usePlaceOrderModal({ onPressShowOpenRequests });
  const { filteringOptions } = useFilters();
  const {
    status: { canCreateAuctionV2, canManageCmbBidV2 },
  } = User.useContainer();
  const { marketSettings } = useMarketSettings({});

  const canPlaceAnOrder = canManageCmbBidV2() || canCreateAuctionV2();
  const isPlacingOrderDisabled = !marketSettings?.otcEntryEnabled;

  const onPressCardCTA = useCallback(
    (item: Omit<CardCMBProps, 'onPressCTA'>) => {
      if (item.isMine) {
        history.push(`/account/mb-and-auctions/${item.id}`);
        return;
      }

      const rawDataItem = getRawDataItemById(item.id);

      if (!rawDataItem) {
        return;
      }

      openPlaceOrderModal({
        orderDetails: toPlaceOrderModalOrderDetails(toMetaFields(filteringOptions))(rawDataItem),
        orderCriteria: rawDataItem.orderCriteria || [],
      });
    },
    [filteringOptions],
  );

  const renderItemComponent = useCallback(
    ({ item }) => {
      return <ListingCard key={item.id} item={item} onPressCTA={onPressCardCTA} />;
    },
    [onPressCardCTA],
  );

  const onPressPlaceOrder = useCallback(() => {
    history.push('/account/mb-and-auctions/place-order');
  }, []);

  return (
    <>
      {placeOrderModal}
      <StyledCard variant={CardVariant.Bordered}>
        <Layer>
          <Header>
            <Button
              variant={ButtonVariant.outlined}
              startIcon={isFiltersVisible ? IconName.X : IconName.Filter}
              size={ButtonSize.s}
              onPress={onPressFilter}
            >
              Filter
            </Button>
            <OrderTypeTabs />
            {canPlaceAnOrder && (
              <Button
                isDisabled={isPlacingOrderDisabled}
                startIcon={IconName.PlusCircle}
                size={ButtonSize.s}
                onPress={onPressPlaceOrder}
              >
                Place order
              </Button>
            )}
          </Header>
        </Layer>
        <FilterBadges />
        <SubHeader resultsCount={totalCount} />
        <ListingsWithShadow>
          <Layer>
            <ListingsContainer ref={listingsContainerRef}>
              {isLoading && (
                <Masonry
                  items={[{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }, { id: '5' }, { id: '6' }] as any}
                  gap={spacingInPixels(8)}
                  minColumnWidth={
                    screenSize === 'small' || screenSize === 'medium' ? spacingInPixels(160) : spacingInPixels(200)
                  }
                  itemComponent={CardCMB.Loader}
                  containerRef={listingsContainerRef}
                  maxColumnCount={3}
                  onLoadMore={loadMore}
                />
              )}
              {!isLoading && (
                <Masonry
                  items={listings}
                  gap={spacingInPixels(8)}
                  minColumnWidth={
                    screenSize === 'small' || screenSize === 'medium' ? spacingInPixels(160) : spacingInPixels(200)
                  }
                  itemComponent={renderItemComponent}
                  containerRef={listingsContainerRef}
                  maxColumnCount={3}
                  onLoadMore={loadMore}
                />
              )}
            </ListingsContainer>
          </Layer>
        </ListingsWithShadow>
      </StyledCard>
    </>
  );
};

const StyledCard = styled(Card)`
  flex-grow: 1;
  align-self: stretch;

  @media (max-width: 1258px) {
    width: 100%;
    max-height: 100vh;
    align-self: flex-start;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => toSpacing(theme)(8)};
  padding-top: ${({ theme }) => toSpacing(theme)(8)};
`;

const ListingsContainer = styled.div`
  padding: ${({ theme }) => toSpacing(theme)(8)};
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
`;

const ListingsWithShadow = styled.div`
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      ${({ theme }) => {
          return toLayerBackground({ ...theme, layer: theme.layer })('layer');
        }}
        100%
    );
    height: 0.5rem;
    width: 100%;
  }
`;
