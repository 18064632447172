import { useQuery } from 'react-query';

import { fetchTradeRequests } from 'data-provider/trade/fetchTradeRequests';

// TODO: implement TradeRequestDto. All the way to ORM function.
// import { TradeRequestDto } from '@aircarbon/utils-common/src/dto';
import { AssetCategoryCode } from '../layouts/trading.hook';

export function useTradesRequests({
  pairId,
  accountAddress,
  searchByOrderId,
  status,
  assetCategory,
  options,
}: {
  pairId?: number | string;
  accountAddress: string;
  searchByOrderId?: string;
  status?: string;
  assetCategory?: AssetCategoryCode;
  options?: { enabled: boolean };
}) {
  let enableQuery = !!pairId && !!accountAddress;

  if (options?.enabled !== undefined) {
    enableQuery = enableQuery && options.enabled;
  }

  const { data, isLoading, error } = useQuery(
    ['oms-user-trades-request', pairId, searchByOrderId, status, accountAddress, assetCategory, !!options?.enabled],
    () => {
      // NOTE: This is a workaround to disable the query when refetchQueries or invalidateQueries is called.
      // Avoid using in other places https://app.asana.com/0/1204270300548432/1205948524421627/f
      if (!enableQuery) {
        return Promise.resolve({ items: [], total: 0 });
      }

      return fetchTradeRequests({
        accountAddress,
        searchByOrderId,
        status,
        assetCategory,
        pairId: String(pairId),
      });
    },
    { enabled: enableQuery },
  );

  return {
    error,
    isLoading,
    tradeRequests: data as {
      items: any[];
      total: number;
    },
  };
}
