import { useQuery } from 'react-query';

import { fetchEntityTokenPrices } from 'data-provider/contract/fetchEntityTokenPrices';
import { fetchTokenPrice } from 'data-provider/contract/fetchTokenPrice';
import { fetchTokenPrices } from 'data-provider/contract/fetchTokenPrices';

export function useTokenPrice({ pairId, type }: { pairId: number; type: 'BUY' | 'SELL' }) {
  const { data, isLoading, error } = useQuery(
    ['oms-token-price', pairId, type],
    () =>
      fetchTokenPrice({
        pairId,
        type,
      }),
    { enabled: pairId && type },
  );

  return {
    error,
    isLoadingTokenPrice: isLoading,
    tokenPrice: data,
  };
}
export function useTokenPrices({ pairId }: { pairId: number }) {
  const { data, isLoading, error } = useQuery(['oms-token-prices', pairId], () => fetchTokenPrices(pairId), {
    enabled: pairId,
  });

  return {
    error,
    isLoadingTokenPrice: isLoading,
    tokenPrices: data,
  };
}
export function useEntityTokenPrice({ type }: { type: 'BUY' | 'SELL' }) {
  const { data, isLoading, error } = useQuery(['oms-token-prices', type], () => fetchEntityTokenPrices(type), {
    enabled: type,
  });

  return {
    error,
    isLoadingEntityTokenPrice: isLoading,
    entityTokenPrice: data,
  };
}
