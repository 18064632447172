export enum SettleOrderSide {
  Sell = 'sell',
  Buy = 'buy',
  Broker = 'broker',
}

export const getOrderSide = ({
  currentUserId,
  buyerUserId,
  sellerUserId,
}: {
  currentUserId: number;
  buyerUserId: number;
  sellerUserId: number;
}) => {
  if (buyerUserId === currentUserId) {
    return SettleOrderSide.Buy;
  }
  if (sellerUserId === currentUserId) {
    return SettleOrderSide.Sell;
  }
  return SettleOrderSide.Broker;
};
