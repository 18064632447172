export interface GetAccountSummariesRequest {
  accountPurposeCode?: string;
  page?: string;
  limit?: string;
}

export interface AccountSummaryResponse {
  address: string;
  name: string;
  purpose: string | null;
}

export enum AccountPurposeCode {
  Retokenization = 'RETOKENIZATION',
  Emb = 'EMB',
  Free = 'FREE',
  PhysicalDelivery = 'PHYSICAL_DELIVERY',
  Retirement = 'RETIREMENT',
  TokenBasket = 'TOKEN_BASKET',
  Treasury = 'TREASURY',
  User = 'USER',
  Withdraw = 'WITHDRAW',
}
