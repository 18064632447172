import { PhoneNumberUtil } from 'google-libphonenumber';

export const toPhone = (
  phone = '',
): {
  phoneCode: string;
  value: string;
} => {
  if (!phone) {
    return {
      phoneCode: '+971',
      value: '',
    };
  }
  const parsedPhone = PhoneNumberUtil.getInstance().parse(phone);

  const phoneCode = '+' + (parsedPhone.getCountryCode() || '971');
  const value = String(parsedPhone.getNationalNumber() || '');

  return {
    phoneCode,
    value,
  };
};
