const SEPARATOR = ' | ';

export const parseBankInfo = (bank: string | undefined) => {
  const groups = bank?.length
    ? bank?.split(SEPARATOR)
    : ['bankName:', 'bankAddress:', 'bankCountry:', 'swiftCode:', 'accountIBAN:', 'accountName:', 'contactPhone:'];

  return groups.reduce((result: Record<string, any>, group: string) => {
    const [key, value] = group.split(':');
    result[key] = value?.trim();
    return result;
  }, {});
};

export const serializeBankInfo = (formData: Record<string, any>) =>
  Object.keys(formData)
    .reduce((result: Array<string>, currentValue: string) => {
      result.push(`${currentValue}: ${formData[currentValue]}`);
      return result;
    }, [])
    .join(SEPARATOR);
