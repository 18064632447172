import { Card, Text, TypographyVariant, styled, toSpacing } from '@aircarbon/ui';

export const Counters: React.FunctionComponent<{
  items: Array<{
    label: string;
    value: string;
  }>;
}> = ({ items }) => {
  return (
    <StyledCounters>
      {items.map((item) => (
        <Counter key={item.label} {...item} />
      ))}
    </StyledCounters>
  );
};

const Counter: React.FunctionComponent<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  return (
    <StyledCard>
      <Text variant={TypographyVariant.subtitle1}>{label}</Text>
      <Text variant={TypographyVariant.h5Title}>{value}</Text>
    </StyledCard>
  );
};

const StyledCounters = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
`;

const StyledCard = styled(Card)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `${toSpacing(theme)(8)} ${toSpacing(theme)(12)}`};
  gap: ${({ theme }) => toSpacing(theme)(4)};
  flex-grow: 1;
`;
