import { Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { AssetCategory, type AssetCategoryCode, formatter, logger } from '@aircarbon/utils-common';

import type { AccountDetails } from 'components/SelectOboAccount';

import { Entity } from 'state/entity';
import { User } from 'state/user';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import SimpleBar from '../components/Simplebar';
import Table from '../components/Table';
import { Wrapper, Span } from '../components/styled';
import {
  usePairs,
  useWeb3AccountBalance,
  useUserOpenTokenBalance,
  useUserOpenCurrencyBalances,
  usePositions,
  useTradeSettings,
} from '../hooks';

const SummaryItem = styled.div<{ borderColor: string; background: string; color: string }>`
  border: 1px solid ${(props) => props.borderColor};
  background: ${(props) => props.background};
  border-radius: 10px;
  padding: 7px 10px;
  color: ${(props) => props.color};
  flex: 1;
`;

const Balance = ({
  selectedAccount,
  showUserInfo,
  withScrollBar = true,
  projectId,
}: {
  selectedAccount: AccountDetails;
  showUserInfo?: true;
  projectId?: string | null;
  withScrollBar?: boolean;
}) => {
  const { account } = selectedAccount;
  const {
    selector: { getAccountAddress },
  } = User.useContainer();
  const { product } = useMarketplaceProduct();
  const {
    selector: { mainCcyCode, mainCcyNumDecimals, mainCcySymbol },
  } = Entity.useContainer();
  const { positions } = usePositions(getAccountAddress());
  const { pairs: pairsWatchList } = usePairs({
    assetCategories: [AssetCategory[Number(product)]] as Array<AssetCategoryCode>,
    includeMarketData: true,
    projectId: projectId ?? undefined,
  });
  const { data: tradeSettings } = useTradeSettings(
    { orderBy: { timeInForceOrderBy: 'uiOrder ASC' }, projectId: projectId ?? undefined },
    AssetCategory[Number(product)] as AssetCategoryCode,
  );

  const pairs = tradeSettings?.pairs ?? [];
  const ccyAssets = Object.keys(tradeSettings?.ccyAssets ?? {});

  const {
    selectors: { getTotalOpenToken },
  } = useUserOpenTokenBalance(account);
  const {
    selectors: { getOpenBalances },
  } = useUserOpenCurrencyBalances(account);
  const {
    selectors: { getSellCurrentBalance, getBuyCurrentBalances, isSellCurrentBalance },
  } = useWeb3AccountBalance(account);

  let netAssetValue = 0;
  let currencyBalance = 0;
  let totalUnrealizedPnL = 0;
  let totalRealizedPnL = 0;
  let totalPnL = 0;

  const getLabelColor = (value: number): string => {
    return value > 0
      ? `var(${Colors.success_700})`
      : value < 0
        ? `var(${Colors.danger_700})`
        : `var(${Colors.gray_900})`;
  };

  const getCcyBalance = () => {
    const ccyBalanceList: {
      asset: string;
      totalBalance: JSX.Element;
      settlementPrice: JSX.Element;
      totalCurrentValue: JSX.Element;
      averageCost: JSX.Element;
      totalCost: JSX.Element;
      reservedBalance: JSX.Element;
      unrealizedPnL: JSX.Element;
      realizedPnL: JSX.Element;
      pnl: JSX.Element;
    }[] = [];
    getBuyCurrentBalances()
      ?.filter((ccy: { name: string }) => ccyAssets.includes(ccy.name))
      .forEach((ccy: { name: string; ccyTypeId: number; balance: number }) => {
        netAssetValue += ccy?.balance;
        currencyBalance += ccy?.balance;

        if (ccy?.name !== 'test') {
          ccyBalanceList.push({
            asset: ccy?.name,
            totalBalance: !Number.isNaN(ccy?.balance) ? (
              <span>{formatter.formatNumber(ccy?.balance)}</span>
            ) : (
              <div className="spinner" />
            ),
            settlementPrice: <span>-</span>,
            totalCurrentValue: !Number.isNaN(ccy?.balance) ? (
              <span>{formatter.formatNumber(ccy?.balance)}</span>
            ) : (
              <div className="spinner" />
            ),
            averageCost: <span>-</span>,
            totalCost: <span>-</span>,
            reservedBalance: !Number.isNaN(getOpenBalances(ccy?.ccyTypeId)) ? (
              <span>{formatter.formatNumber(getOpenBalances(ccy?.ccyTypeId))}</span>
            ) : (
              <div className="spinner" />
            ),
            unrealizedPnL: <span>-</span>,
            realizedPnL: <span>-</span>,
            pnl: <span>-</span>,
          });
        }
      });
    return ccyBalanceList;
  };

  const getTokenBalanceList = () => {
    const tokenBalanceList: {
      asset: string;
      totalBalance: JSX.Element;
      settlementPrice: JSX.Element;
      totalCurrentValue: JSX.Element;
      averageCost: JSX.Element;
      totalCost: JSX.Element;
      reservedBalance: JSX.Element;
      unrealizedPnL: JSX.Element;
      realizedPnL: JSX.Element;
      pnl: JSX.Element;
    }[] = [];
    pairs.forEach((pair, index) => {
      const assetName = pair?.baseAsset?.name;
      const pairScId = pair?.baseAsset?.scId;
      const pairScRatio = pair?.baseAsset?.uom?.scRatio;
      const assetAutoRetire = Boolean(pair?.baseAsset?.autoRetire);

      if (pairScId && pairScRatio && assetName && isSellCurrentBalance(pairScId)) {
        const currentToken = getSellCurrentBalance(pairScRatio, pairScId, assetAutoRetire);
        const openToken = getTotalOpenToken(pair.id);

        const openPosition = positions?.openPositions?.find(
          (position: { symbol: string }) => position.symbol === assetName,
        );
        logger.info(
          {
            currentToken,
            openToken,
            pairScId,
            pairScRatio,
            openPosition,
            positions,
          },
          `Token Balance for ${assetName}`,
        );
        const realizedPL = Number(
          positions?.realizedPnl?.find((position: { symbol: string }) => position.symbol === assetName)?.realizedPnl ??
            0,
        );

        // TODO: get referencePrice as fallback
        let settlementPrice = 0;
        if (pairsWatchList && pairsWatchList.length > 0)
          settlementPrice =
            pairsWatchList.find((pair: { name: string }) => pair?.name?.split('/')[0] === assetName)?.marketData
              ?.lastTradedPrice ?? 0;
        const totalCurrentValue = currentToken * settlementPrice;
        const totalCost = currentToken * Number(openPosition?.weighedCost ?? 0);
        const unrealizedPnL = totalCurrentValue - totalCost;
        const PnL = unrealizedPnL + realizedPL;

        netAssetValue += totalCurrentValue;
        totalUnrealizedPnL += unrealizedPnL;
        totalRealizedPnL += realizedPL;
        totalPnL += PnL;

        tokenBalanceList.push({
          asset: assetName,
          totalBalance: !Number.isNaN(currentToken) ? (
            <span>{formatter.formatNumber(currentToken, 0)}</span>
          ) : (
            <div className="spinner" />
          ),
          settlementPrice: pairsWatchList ? (
            <span>{formatter.formatNumber(settlementPrice, mainCcyNumDecimals)}</span>
          ) : (
            <div className="spinner" />
          ),
          totalCurrentValue: pairsWatchList ? (
            <span>{formatter.formatNumber(totalCurrentValue, mainCcyNumDecimals)}</span>
          ) : (
            <div className="spinner" />
          ),
          averageCost: positions ? (
            <span>{formatter.formatNumber(openPosition?.weighedCost ?? 0, mainCcyNumDecimals)}</span>
          ) : (
            <div className="spinner" />
          ),
          totalCost: <span>{formatter.formatNumber(totalCost, mainCcyNumDecimals)}</span>,
          reservedBalance: !Number.isNaN(openToken) ? (
            <span>{formatter.formatNumber(openToken, 0)}</span>
          ) : (
            <div className="spinner" />
          ),
          unrealizedPnL:
            pairsWatchList && positions ? (
              <Span color={getLabelColor(unrealizedPnL)}>{formatter.formatNumber(unrealizedPnL, 0)}</Span>
            ) : (
              <div className="spinner" />
            ),
          realizedPnL: positions ? (
            <Span color={getLabelColor(realizedPL)}>{formatter.formatNumber(realizedPL, 0)}</Span>
          ) : (
            <div className="spinner" />
          ),
          pnl: positions ? (
            <Span color={getLabelColor(PnL)}>{formatter.formatNumber(PnL, 0)}</Span>
          ) : (
            <div className="spinner" />
          ),
        });
      }
    });
    return tokenBalanceList;
  };

  const generateBalanceList = () => {
    const balances = [];
    balances.push(...getCcyBalance());
    balances.push(...getTokenBalanceList());
    return balances;
  };

  const balanceList = pairs.length > 0 ? generateBalanceList() : [];

  const content = (
    <>
      {showUserInfo && (
        <div
          style={{ color: `var(${Colors.gray_900})`, marginBottom: '8px', marginTop: '4px' }}
        >{`${selectedAccount.fullName} [${selectedAccount.userId}]`}</div>
      )}
      <div className="flex flex-row gap-4">
        <SummaryItem
          background={`var(${Colors.gray_100})`}
          borderColor={`var(${Colors.borderNormal})`}
          color={`var(${Colors.gray_900})`}
          className="flex flex-col"
        >
          <span>Net Asset Value:</span>
          {!Number.isNaN(netAssetValue) ? (
            <span className="code">
              {mainCcyCode}
              {formatter.formatNumber(netAssetValue, mainCcyNumDecimals)}
            </span>
          ) : (
            <div className="spinner" />
          )}
        </SummaryItem>
        <SummaryItem
          background={`var(${Colors.gray_100})`}
          borderColor={`var(${Colors.borderNormal})`}
          color={`var(${Colors.gray_900})`}
          className="flex flex-col"
        >
          <span>Balance {mainCcySymbol}:</span>
          {!Number.isNaN(currencyBalance) ? (
            <span className="code">
              {mainCcyCode}
              {formatter.formatNumber(currencyBalance, mainCcyNumDecimals)}
            </span>
          ) : (
            <div className="spinner" />
          )}
        </SummaryItem>
        <SummaryItem
          background={`var(${Colors.gray_100})`}
          borderColor={`var(${Colors.borderNormal})`}
          color={`var(${Colors.gray_900})`}
          className="flex flex-col"
        >
          <span>Unrealized P&L:</span>
          {!Number.isNaN(totalUnrealizedPnL) ? (
            <Span color={getLabelColor(totalUnrealizedPnL)} className="code">
              {mainCcyCode}
              {formatter.formatNumber(totalUnrealizedPnL)}
            </Span>
          ) : (
            <div className="spinner" />
          )}
        </SummaryItem>
        <SummaryItem
          background={`var(${Colors.gray_100})`}
          borderColor={`var(${Colors.borderNormal})`}
          color={`var(${Colors.gray_900})`}
          className="flex flex-col"
        >
          <span>Realized P&L:</span>
          {!Number.isNaN(totalRealizedPnL) ? (
            <Span color={getLabelColor(totalRealizedPnL)} className="code">
              {mainCcyCode}
              {formatter.formatNumber(totalRealizedPnL)}
            </Span>
          ) : (
            <div className="spinner" />
          )}
        </SummaryItem>
        <SummaryItem
          background={`var(${Colors.gray_100})`}
          borderColor={`var(${Colors.borderNormal})`}
          color={`var(${Colors.gray_900})`}
          className="flex flex-col"
        >
          <span>P&L:</span>
          {!Number.isNaN(totalPnL) ? (
            <Span color={getLabelColor(totalPnL)} className="code">
              {mainCcyCode}
              {formatter.formatNumber(totalPnL)}
            </Span>
          ) : (
            <div className="spinner" />
          )}
        </SummaryItem>
      </div>
      <div className="flex relative flex-row flex-auto">
        <Table
          config={{
            withCustomScrollBar: false,
            columns: {
              asset: {
                label: 'Asset',
                font: 'code',
              },
              totalBalance: {
                label: 'Balance',
                align: 'right',
                font: 'code',
              },
              settlementPrice: {
                label: 'Last',
                align: 'right',
                font: 'code',
              },
              totalCurrentValue: {
                label: 'Total Value',
                align: 'right',
                font: 'code',
              },
              averageCost: {
                label: 'Avg Cost',
                align: 'right',
                font: 'code',
              },
              totalCost: {
                label: 'Total Cost',
                align: 'right',
                font: 'code',
              },
              reservedBalance: {
                label: 'Reserved',
                align: 'right',
                font: 'code',
              },
              unrealizedPnL: {
                label: 'Unrealized',
                align: 'right',
                font: 'code',
              },
              realizedPnL: {
                label: 'Realized',
                align: 'right',
                font: 'code',
              },
              pnl: {
                label: 'P&L',
                align: 'right',
                font: 'code',
              },
            },
            rows: balanceList,
          }}
        />
      </div>
    </>
  );

  return withScrollBar ? (
    <>
      <Wrapper className="flex flex-col w-full h-full">
        <SimpleBar>{content}</SimpleBar>
      </Wrapper>
    </>
  ) : (
    <>
      <Wrapper
        style={{
          height: 'auto',
        }}
        className="flex flex-col w-full"
      >
        {content}
      </Wrapper>
    </>
  );
};

export default Balance;
