import generateAcxRetirementCertificate from './acx/generateAcxRetirementCertificate';
import generateIdxRetirementCertificate from './idx/generateIdxRetirementCertificate';

export enum Template {
  ACX = 'ACX',
  IDX = 'IDX',
  // NOTE: add EON template for retirement certificate if needed
}

export function generate(template: Template, retirement: any) {
  switch (template) {
    case Template.ACX:
      return generateAcxRetirementCertificate(retirement);
    case Template.IDX:
      return generateIdxRetirementCertificate(retirement);
    default:
      return Promise.resolve(null);
  }
}
