import { useQuery } from 'react-query';

import { FobType } from '@aircarbon/utils-common/src/dto';

import { fetchFobTypes } from 'data-provider/oms/fetchFobTypes';

const defaultFobTypes: FobType[] = [];

export function useFobTypes(assetId: number, enabled?: boolean) {
  let queryEnabled = !!assetId;

  if (enabled !== undefined) {
    queryEnabled &&= enabled;
  }

  const { data, isLoading, error } = useQuery(['oms-fob-types', assetId], () => fetchFobTypes(assetId), {
    enabled: queryEnabled,
  });

  return {
    isLoading,
    error,
    fobTypes: Array.isArray(data?.data) ? data?.data : defaultFobTypes,
  };
}
