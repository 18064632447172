import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import Loading from 'refreshed-component/molecules/Loading';
import { useOtcCriteria } from 'refreshed-pages/market-board-v2/hooks/useOtcCriteria';
import { useTradeRequests } from 'refreshed-pages/market-board-v2/hooks/useTradeRequests';
import { toCMBOrderType } from 'refreshed-pages/market-board-v2/utils/toCMBOrderType';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  CMBOrderType,
  IconName,
  Knob,
  Layer,
  Modal,
  Text,
  ToastVariant,
  TypographyVariant,
  showToast,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import { Dto } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { NotFoundError } from 'utils/NotFoundError';

import { FetchOtcOrderResponse, FetchOtcOrderStatus, OTCOrderResource, fetchOTCOrder } from '../../utils/fetchOTCOrder';
import { AcceptTradeRequestModal, AcceptTradeRequestModalRef } from '../AcceptTradeRequestModal';
import { BottomSlideUp } from '../BottomSlideUp';
import { Counters } from './components/Counters';
import { IncomingOffers } from './components/IncomingOffers';
import { CounterOfferModal, CounterOfferModalRef } from './components/IncomingOffers/components/CounterOfferModal';
import { RejectRequestModal, RejectRequestModalRef } from './components/IncomingOffers/components/RejectRequestModal';
import { Listing } from './components/Listing';
import { cancelOtcOrder } from './utils/cancelOtcOrder';
import { toCounters } from './utils/toCounters';
import { toListingProps } from './utils/toListingProps';

const titleToOrderTypeMap = {
  [CMBOrderType.Auction]: 'Auction',
  [CMBOrderType.Bid]: 'Bid',
  [CMBOrderType.Offer]: 'Offer',
};

export const MarketBoardV2ListingPage = () => {
  const history = useHistory();
  const [error, setError] = useState<Error | null>(null);
  const [order, setOrder] = useState<OTCOrderResource | null>(null);
  const { meta } = useOtcCriteria();
  const [isCancelling, setIsCancelling] = useState(false);
  const [isCancelOrderModalVisible, setIsCancelOrderModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { marketSettings } = useMarketSettings({});

  if (error) {
    throw error;
  }

  const { id } = useParams<{
    id: string;
  }>();

  const onPressBack = () => {
    history.push('/account/mb-and-auctions');
  };

  const counterOfferModalRef = useRef<CounterOfferModalRef>(null);
  const rejectRequestModalRef = useRef<RejectRequestModalRef>(null);
  const acceptTradeRequestModalRef = useRef<AcceptTradeRequestModalRef>(null);

  const {
    data: orderResponse,
    refetch: refetchOrder,
    isLoading,
  } = useQuery<FetchOtcOrderResponse>(['fetchOTCOrder', id], () => fetchOTCOrder({ id }), {
    enabled: !!id,
  });

  useMarketplaceProduct(
    orderResponse?.data?.baseAssetCategoryId ? [orderResponse?.data?.baseAssetCategoryId] : undefined,
  );

  const {
    tradeRequests,
    acceptTradeRequest,
    showPlaceCounterOfferModal,
    onCounterOfferPlaced,
    onTradeRequestRejected,
    showRejectTradeRequestModal,
    isAcceptingTradeRequest,
    showReviewOrderModal,
    totalPages,
  } = useTradeRequests({
    counterOfferModalRef,
    rejectRequestModalRef,
    acceptTradeRequestModalRef,
    otcOrderId: id,
    refetchOrder,
    currentPage,
  });

  useEffect(() => {
    if (orderResponse) {
      setError(null);

      if (orderResponse?.status === FetchOtcOrderStatus.NotFound) {
        // show not found page
        setError(new NotFoundError());
        return;
      }

      if (orderResponse.status === FetchOtcOrderStatus.Error) {
        setError(new Error());
        return;
      }

      setOrder(orderResponse.data ?? null);
    }
  }, [orderResponse]);

  const activeTradeRequests = useMemo(() => {
    return tradeRequests.filter((tradeRequest) => !['REJECTED', 'CANCELLED'].includes(tradeRequest.status));
  }, [tradeRequests]);

  const counters = useMemo(() => toCounters(order), [order]);

  const onPressEdit = useCallback(() => {
    history.push(`/account/mb-and-auctions/${id}/edit`);
  }, [id]);

  const onPressCancel = useCallback(async () => {
    setIsCancelOrderModalVisible(true);
  }, []);

  const onPressConfirmCancel = useCallback(async () => {
    setIsCancelling(true);

    const result = await cancelOtcOrder(id);

    setIsCancelling(false);
    if (result.status === 'error') {
      showToast({
        variant: ToastVariant.Danger,
        message: result.error,
      });
      return;
    }

    setIsCancelOrderModalVisible(false);
    showToast({
      variant: ToastVariant.Success,
      message: `Order #${id} was cancelled`,
    });
    history.replace('/account/mb-and-auctions');
  }, [id]);

  const onPressConfirmTradeRequest = () => {
    acceptTradeRequest();
  };

  const onCloseCancelOrderModal = () => {
    if (!isCancelling) setIsCancelOrderModalVisible(false);
  };

  if (!order || isLoading) {
    return <Loading />;
  }

  const orderType = toCMBOrderType({
    isAuction: !!order.isAuction,
    sideId: order.sideId,
  });
  const isAuctionNonEditable = orderType === CMBOrderType.Auction && order.statusId !== Dto.OtcOrderStatus.Pending;
  const isAuctionNonCancellable = isAuctionNonEditable;
  const isCancelDisabled =
    !marketSettings?.otcCancelationEnabled || isAuctionNonCancellable || !!activeTradeRequests.length;
  const cancelButtonTooltip = isAuctionNonCancellable
    ? 'Auction can not be cancelled once active'
    : !!activeTradeRequests.length
      ? 'You cannot cancel order with ongoing trade requests'
      : '';

  const isEditDisabled =
    !marketSettings?.otcEditEnabled ||
    isAuctionNonEditable ||
    isCancelling ||
    !!order.isPrefunded ||
    !!activeTradeRequests.length;
  const editButtonTooltip = !!order.isPrefunded
    ? 'Orders with reserved assets cannot be edited'
    : !!activeTradeRequests.length
      ? 'Orders with active trade requests cannot be edited'
      : isAuctionNonEditable
        ? 'Auction can not be edited once active'
        : undefined;

  return (
    <Wrapper>
      <PageContainer>
        <RejectRequestModal ref={rejectRequestModalRef} onRejected={onTradeRequestRejected} />
        <CounterOfferModal ref={counterOfferModalRef} onCounterOfferPlaced={onCounterOfferPlaced} />
        <AcceptTradeRequestModal
          ref={acceptTradeRequestModalRef}
          onConfirm={onPressConfirmTradeRequest}
          isSubmitting={isAcceptingTradeRequest}
        />
        <Modal
          onClose={onCloseCancelOrderModal}
          onPressNegativeButton={onCloseCancelOrderModal}
          isVisible={isCancelOrderModalVisible}
          title={'Cancel Order'}
          onPressPositiveButton={onPressConfirmCancel}
          isPositiveButtonLoading={isCancelling}
        >
          <Text>Are you sure you want to cancel order #{order.id}?</Text>
        </Modal>
        <Header>
          <TitleWithBack>
            <Knob
              variant={ButtonVariant.outlined}
              size={ButtonSize.xs}
              icon={IconName.ChevronStart}
              onPress={onPressBack}
            />
            <Text variant={TypographyVariant.subtitle1}>
              {titleToOrderTypeMap[orderType]} #{order.id}
            </Text>
          </TitleWithBack>
          <Actions>
            <Button
              onPress={onPressEdit}
              startIcon={IconName.Pencil}
              size={ButtonSize.l}
              variant={ButtonVariant.outlined}
              tooltip={editButtonTooltip}
              isDisabled={isEditDisabled}
            >
              Edit
            </Button>
            <Knob
              isDisabled={isCancelDisabled}
              isLoading={isCancelling}
              onPress={onPressCancel}
              icon={IconName.Ban}
              size={ButtonSize.l}
              variant={ButtonVariant.outlined}
              tooltip={cancelButtonTooltip}
            />
          </Actions>
        </Header>
        <Content>
          <Layer>
            <Listing
              {...toListingProps({
                order,
                projectMeta: meta,
              })}
            />
            <Counters items={counters} />
            <IncomingOffers
              {...{
                tradeRequests,
                showPlaceCounterOfferModal,
                showRejectTradeRequestModal,
                showReviewOrderModal,
                currentPage,
                totalPages,
              }}
              onChangePage={setCurrentPage}
              order={order}
            />
          </Layer>
        </Content>
      </PageContainer>
      <Layer>
        <BottomSlideUp />
      </Layer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 4.75rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => toSpacing(theme)(12)};
  width: 100%;
  flex-wrap: wrap;
  gap: ${({ theme }) => toSpacing(theme)(4)};
`;

const TitleWithBack = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(4)};
  flex-grow: 1;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(4)};
  flex-shrink: 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => toSpacing(theme)(12)};
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;

const SlideUpContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
