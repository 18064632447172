import { Colors, Radius, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

type Props = {
  className?: string;
  header?: React.ReactElement | false;
  footer?: React.ReactElement | false;
};

const CardRoot = styled.div<{
  hasHeader: boolean;
  hasFooter: boolean;
}>`
  display: flex;
  height: 100%;
  padding: var(${Spacing.large});
  padding-top: ${(props) => (props.hasHeader ? 0 : `var(${Spacing.large})`)};
  flex: 1 0 0;
  border-radius: var(${Radius.medium});
  background: var(${Colors.gray_0});
  gap: var(${Spacing.large});
  overflow: hidden;
  > .icon {
    width: 66px;
    height: 66px;
    flex-shrink: 0;
    fill: var(${Colors.gray_100});
  }
  > .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    gap: var(${Spacing.xs});
    justify-content: center;
  }
`;

export const Card: React.FC<Props> = ({ children, className, header, footer }) => {
  return (
    <CardRoot hasHeader={!!header} hasFooter={!!footer} className={className ? className : 'flex-row'}>
      {header}
      {children}
      {footer}
    </CardRoot>
  );
};
