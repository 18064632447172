import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Button } from 'refreshed-component/atoms/Button';
import Modal, { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  action?: string;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export type DialogProps = {
  title: string;
  message: string | React.ReactNode;
  cancel: Props;
  accept: Props;
};

const Dialog: React.FC<DialogProps> = ({ title, message, cancel, accept }) => {
  const refConfirmButton = useRef<HTMLButtonElement>(null);
  const refCancelButton = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (refConfirmButton.current)
      setTimeout(() => {
        refConfirmButton.current?.focus();
      }, 150);
  }, [refConfirmButton]);

  return (
    <Modal title={title} isOpen={true} onClose={() => cancel.onClick?.()}>
      {() => {
        return (
          <>
            <ModalContent>{message}</ModalContent>
            <ModalFooter>
              <div className="flex flex-row w-full gap-base">
                <Button
                  className="flex-1"
                  config={{
                    color: 'outlined',
                    size: 'sm',
                  }}
                  ref={refCancelButton as any}
                  onKeyDownCapture={(event) => {
                    if (event.key === 'Tab' && event.shiftKey === true) {
                      event.preventDefault();
                      if (refCancelButton.current) {
                        refCancelButton.current.focus();
                      }
                    }
                    cancel.onKeyDownCapture?.(event as any);
                  }}
                  onClick={() => cancel.onClick?.()}
                >
                  CANCEL
                </Button>
                <Button
                  className="flex-1"
                  config={{
                    color: 'primary',
                    size: 'sm',
                  }}
                  ref={refConfirmButton}
                  onClick={() => accept.onClick?.()}
                  onKeyDownCapture={(event) => {
                    if (event.key === 'Tab' && event.shiftKey === false) {
                      event.preventDefault();
                      if (refConfirmButton.current) {
                        refConfirmButton.current.focus();
                      }
                    }
                    accept.onKeyDownCapture?.(event as any);
                  }}
                >
                  CONFIRM
                </Button>
              </div>
            </ModalFooter>
          </>
        );
      }}
    </Modal>
  );
};

export type DialogDefaultContext = {
  props?: DialogProps;
  showDialog?: (props: DialogProps) => void;
  removeDialog?: () => void;
};

export const DialogContext = React.createContext({} as DialogDefaultContext);

export default Dialog;
