import { format } from 'date-fns';
import { OtcCriteria } from 'refreshed-pages/market-board-v2/utils/OtcCriteria';

import { CMBOrderType, TextColor } from '@aircarbon/ui';

import { ReviewOrderModalData } from '../../ReviewOrderModal';
import { AuctionFormValue } from '../components/AuctionForm';
import { BidFormValue } from '../components/BidForm';
import { OfferFormValue } from '../components/OfferForm';
import { FormValue } from './FormValue';

interface ToReviewOrderModalDataProps<T extends CMBOrderType> {
  formValue: FormValue<T>;
  orderType: T;
  placedBy: string;
  onBehalfOf: string;
  fee: number;
  total: number;
  currency: string;
  quantityUnit: string;
  projectMeta: OtcCriteria;
  isOtcSettlementEnabled: boolean;
}

const orderTypeToSideMap: Record<
  CMBOrderType,
  {
    value: string;
    valueColor: TextColor;
  }
> = {
  [CMBOrderType.Auction]: {
    value: 'Auction',
    valueColor: TextColor.warning,
  },
  [CMBOrderType.Bid]: {
    value: 'Bid',
    valueColor: TextColor.success,
  },
  [CMBOrderType.Offer]: {
    value: 'Offer',
    valueColor: TextColor.error,
  },
};

export const toReviewOrderModalData = <T extends CMBOrderType>(
  props: ToReviewOrderModalDataProps<T>,
): ReviewOrderModalData => {
  const {
    orderType,
    formValue,
    fee,
    total,
    placedBy,
    onBehalfOf,
    currency,
    quantityUnit,
    projectMeta,
    isOtcSettlementEnabled,
  } = props;

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  });

  const quantityFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  let summary: Array<{
    label: string;
    value: string;
    valueColor?: TextColor;
  }> = [
    {
      label: 'Side',
      value: orderTypeToSideMap[orderType].value,
      valueColor: orderTypeToSideMap[orderType].valueColor,
    },
  ];

  if (orderType === CMBOrderType.Bid) {
    const criteriaByKey = new Map<string, { key: string; value: string }>();

    (formValue as BidFormValue).criteria.forEach((item) => {
      criteriaByKey.set(item.key, item);
    });

    if ((formValue as BidFormValue).projects?.length) {
      const projects = (formValue as BidFormValue).projects;
      summary.push({
        label: (formValue as BidFormValue).projects?.length > 1 ? 'Projects' : 'Project',
        value: projects
          .map((project) => [project.registryProjectId, project.title].filter((v) => !!v).join(' | '))
          .join(', '),
      });
    }

    const projectMetaByKey = new Map<
      string,
      {
        value: string;
        label: string;
        options: Map<
          string,
          {
            label: string;
            value: string;
          }
        >;
      }
    >();

    projectMeta.forEach((metaItem) => {
      if (!metaItem.options?.length) {
        return;
      }

      const options = new Map();

      metaItem.options.forEach((option) => {
        options.set(option.value, option);
      });

      projectMetaByKey.set(metaItem.value, {
        label: metaItem.label,
        value: metaItem.value,
        options,
      });
    });

    projectMeta.forEach((metaItem) => {
      const selectedCriteria = criteriaByKey.get(metaItem.value);
      const availableProjectMeta = projectMetaByKey.get(metaItem.value);
      if (!selectedCriteria) {
        return;
      }

      const summaryValue = selectedCriteria.value
        .split(',')
        .map((valueItem) => availableProjectMeta?.options?.get(valueItem)?.label ?? valueItem)
        .join(', ');

      summary.push({
        label: metaItem.label,
        value: summaryValue || '',
      });
    });
  }

  if (orderType === CMBOrderType.Auction) {
    const auctionFormValue = formValue as AuctionFormValue;
    const [startDate, endDate] = auctionFormValue.auctionPeriod.split(',');

    summary = [
      ...summary,
      {
        label: 'Project',
        value:
          [auctionFormValue.project?.registryProjectId, auctionFormValue.project?.title]
            .filter((v) => !!v)
            .join(' | ') ?? '',
      },
      {
        label: 'Vintage',
        value: auctionFormValue.vintage,
      },
      {
        label: 'Auction Period',
        value: `${format(new Date(startDate), "dd/MM/yyyy 'at' p")} ~ ${format(new Date(endDate), "dd/MM/yyyy 'at' p")}`,
      },
      {
        label: 'Show Bid Volume',
        value: auctionFormValue.showBidVolume ? 'Yes' : 'No',
      },
      {
        label: 'Show Best Bid',
        value: auctionFormValue.showBestBid ? 'Yes' : 'No',
      },
    ];
  }

  if (orderType === CMBOrderType.Offer) {
    summary = [
      ...summary,
      {
        label: 'Project',
        value:
          [(formValue as OfferFormValue).project?.registryProjectId, (formValue as OfferFormValue).project?.title]
            .filter((v) => !!v)
            .join(' | ') ?? '',
      },
      {
        label: 'Vintage',
        value: (formValue as OfferFormValue).vintage,
      },
    ];
  }

  summary = [
    ...summary,
    ...(orderType !== CMBOrderType.Auction && !isOtcSettlementEnabled
      ? [
          {
            label: 'Assets Reserved',
            value: formValue.isInstantTrade ? 'Yes (Assets will be reserved)' : 'No',
          },
        ]
      : []),
    ...(orderType === CMBOrderType.Offer &&
    formValue.baseAssetId &&
    (formValue as OfferFormValue).project?.vintageBalancePerAsset
      ? [
          {
            label: 'Contract',
            value:
              Object.keys((formValue as OfferFormValue).project?.vintageBalancePerAsset ?? {}).find(
                (v) =>
                  (formValue as OfferFormValue).project?.vintageBalancePerAsset?.[v].assetId ===
                  Number(formValue.baseAssetId),
              ) ?? '',
          },
        ]
      : []),
    ...(formValue.minimumQuantity
      ? [
          {
            label: orderType === CMBOrderType.Bid ? 'Minimum Offer Quantity' : 'Minimum Bid Quantity',
            value: quantityFormatter.format(Number(formValue.minimumQuantity)),
          },
        ]
      : ([] as typeof summary)),
    ...(formValue.maximumQuantity
      ? [
          {
            label: orderType === CMBOrderType.Bid ? 'Maximum Offer Quantity' : 'Maximum Bid Quantity',
            value: quantityFormatter.format(Number(formValue.maximumQuantity)),
          },
        ]
      : ([] as typeof summary)),
    ...(formValue.quantityMultiplesOf
      ? [
          {
            label: orderType === CMBOrderType.Bid ? 'Offer Qty in Multiples of' : 'Bid Qty in Multiples of',
            value: quantityFormatter.format(Number(formValue.quantityMultiplesOf)),
          },
        ]
      : ([] as typeof summary)),
  ];

  return {
    summary,
    price: moneyFormatter.format(Number(formValue.price)),
    quantity: quantityFormatter.format(Number(formValue.quantity)) + ' ' + quantityUnit,
    fee: moneyFormatter.format(Number(fee)),
    total: moneyFormatter.format(Number(total)),
    placedBy,
    onBehalfOf: onBehalfOf ?? placedBy,
  };
};
