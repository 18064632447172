import { format } from 'date-fns';
import { OtcCriteria } from 'refreshed-pages/market-board-v2/utils/OtcCriteria';
import { toMetaFields } from 'refreshed-pages/market-board-v2/utils/toMetaFields';

import { assetCategoryUom, AssetCategory } from '@aircarbon/utils-common';

import { OTCOrderResource } from '../../../utils/fetchOTCOrder';
import { ListingProps } from '../components/Listing';

const quantityFormat = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const toListingProps = (props: { order: OTCOrderResource; projectMeta: OtcCriteria }): ListingProps => {
  const { order, projectMeta } = props;

  return {
    orderId: Number(order.id),
    isInstantTrade: !!order.isPrefunded,
    hasMultipleProjects: !!order.projects?.length,
    endingAt: order.expiryUtc ? format(new Date(order.expiryUtc), "MMMM do, yyyy 'at' p") : undefined,
    projectStatus: order.project?.status,
    project: order.projects
      ? order.projects.map((project) => project.registryProjectId).join(', ')
      : order.project?.name,
    statusId: order.statusId,
    meta: order.criteria
      .map((item) => toMetaFields(projectMeta)({ id: item.key, key: item.key, value: item.values.join(',') }))
      .filter((v) => !!v) as Array<{
      label: string;
      value: string | Array<string>;
    }>,
    price: order.price
      ? new Intl.NumberFormat('en-US', { style: 'currency', currency: order.quoteAsset.symbol }).format(order.price)
      : '-',
    auctionRulesLink: order.auctionRulesLink,
    quantityUnit: assetCategoryUom[order.baseAssetCategoryId as AssetCategory],
    openQuantity: quantityFormat.format(order.qty),
    totalQuantity: quantityFormat.format(order.initialQty),
  };
};
