// eslint-disable-next-line import/prefer-default-export
export enum FiatRequestSource {
  UserWebPortal = 'UserWebPortal',
  AdminWebPortal = 'AdminWebPortal',
}

export enum FiatProvider {
  KSEI = 'ksei',
}

export interface DepositDetailsResponse {
  id: number;
  userId: number | null;
  amount: number;
  status: string | null;
  createdAt: Date | null;
  updatedAt: Date | null;
  fiatProviderNotifications: FiatProviderNotification[];
}

export interface FiatProviderNotification {
  id: number;
  processingStatus: string | null;
  processingFailedReason: string | null;
  notificationType: string | null;
  createdAt: Date;
  updatedAt: Date;
  provider: FiatProvider;
}
