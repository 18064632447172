import { useCallback, useContext, useEffect, useState } from 'react';

import { CMBOrderType } from '@aircarbon/ui';
import { projects } from '@aircarbon/utils-common';

import { FiltersContext } from '../utils/FiltersContext';
import { FiltersContextEventName } from '../utils/FiltersContextEvent';
import { OtcCriteria } from '../utils/OtcCriteria';
import { Sorting } from '../utils/Sorting';
import { toCriteriaQueryParams } from '../utils/toCriteriaQueryParams';

interface UpdateDynamicFilterProps {
  value: string;
  label: string;
  option?: {
    label: string;
    value: string;
  };
}

export const useFilters = () => {
  const filtersContext = useContext(FiltersContext);

  if (!filtersContext) {
    throw new Error('No FiltersContext available, please use this hook with FiltersProvider');
  }
  const [filteringOptions, setFilteringOptions] = useState(filtersContext.getFilteringOptions());
  const [dynamicFilters, setDynamicFilters] = useState(filtersContext.getDynamicFilters());
  const [staticFilters, setStaticFilters] = useState(filtersContext.getStaticFilters());
  const [isFetchingFilteringOptions, setIsFetchingFilteringOptions] = useState(false);
  useEffect(() => {
    return filtersContext.subscribe(FiltersContextEventName.ChangedFilteringOptions, (e) => {
      setFilteringOptions(e.payload);
    });
  }, []);

  useEffect(() => {
    return filtersContext.subscribe(FiltersContextEventName.ChangedDynamicFilters, (e) => {
      setDynamicFilters(e.payload);
    });
  }, []);

  useEffect(() => {
    return filtersContext.subscribe(FiltersContextEventName.ChangedStaticFilters, (e) => setStaticFilters(e.payload));
  }, []);

  useEffect(() => {
    return filtersContext.subscribe(FiltersContextEventName.FetchingFilteringOptions, (e) =>
      setIsFetchingFilteringOptions(e.payload),
    );
  }, []);
  /**
   * Set sorting of the order cards
   */
  const setSorting = useCallback((sorting: Sorting) => {
    filtersContext.setStaticFilters({
      ...filtersContext.getStaticFilters(),
      sort: sorting,
    });
  }, []);

  /**
   * Set the order type filter
   */
  const setOrderType = useCallback((orderType: CMBOrderType | 'all') => {
    filtersContext.setStaticFilters({
      ...filtersContext.getStaticFilters(),
      orderType,
    });
  }, []);

  /**
   * Updates dynamic filters.
   */
  const updateDynamicFilters = useCallback(
    (filteringOption: UpdateDynamicFilterProps) => {
      /**
       * Adds new dynamic filter to the list
       */
      const addNewDynamicFilter = (newDynamicFilter: UpdateDynamicFilterProps) => {
        filtersContext.setDynamicFilters([
          ...dynamicFilters,
          {
            label: newDynamicFilter.label,
            value: newDynamicFilter.value,
            options: [
              newDynamicFilter.option as {
                label: string;
                value: string;
              },
            ],
          },
        ]);
      };

      /**
       * Add new filtering option to the dynamic filter
       */
      const addFilteringOption = (
        dynamicFilter: OtcCriteria[0],
        newFilteringOption: {
          label: string;
          value: string;
        },
      ) => {
        dynamicFilter.options = [...(dynamicFilter.options || []), newFilteringOption];
        filtersContext.setDynamicFilters([...dynamicFilters]);
      };

      /**
       * Remove filtering option from the dynamic filter
       */
      const removeFilteringOption = (
        dynamicFilter: OtcCriteria[0],
        optionToRemove: {
          label: string;
          value: string;
        },
      ) => {
        const newFilteringOptions = dynamicFilter.options?.filter((option) => {
          return option.value !== optionToRemove.value;
        });

        dynamicFilter.options = newFilteringOptions;

        filtersContext.setDynamicFilters([...dynamicFilters]);
      };

      const existingFilter = dynamicFilters.find((filter) => {
        return filter.value === filteringOption.value;
      });

      if (!existingFilter) {
        if (!filteringOption.option) {
          return;
        }
        addNewDynamicFilter(filteringOption);
        return;
      }

      if (!filteringOption.option) {
        filtersContext.setDynamicFilters(
          dynamicFilters.filter((dynamicFilter) => dynamicFilter.value !== filteringOption.value),
        );
        return;
      }

      const existingFilterOption = existingFilter.options?.find((filterOption) => {
        return filterOption.value === filteringOption.option?.value;
      });

      if (!existingFilterOption) {
        addFilteringOption(existingFilter, filteringOption.option);
        return;
      }

      removeFilteringOption(existingFilter, filteringOption.option);
    },
    [dynamicFilters],
  );

  const setVintageYear = useCallback((vintageYear: string) => {
    filtersContext.setStaticFilters({
      ...filtersContext.getStaticFilters(),
      vintageYear,
    });
  }, []);

  const toggleInstantTrade = useCallback((isInstantTrade: boolean) => {
    filtersContext.setStaticFilters({
      ...filtersContext.getStaticFilters(),
      isInstantTrade,
    });
  }, []);

  const setPrice = useCallback((price: { from: number; to: number }) => {
    filtersContext.setStaticFilters({
      ...filtersContext.getStaticFilters(),
      price,
    });
  }, []);

  const setCountries = useCallback((value: string) => {
    const countryCodes = value.split(',').filter((code) => !!code);

    if (!countryCodes.length) {
      filtersContext.setDynamicFilters(
        filtersContext.getDynamicFilters().filter((dynamicFilter) => dynamicFilter.value !== 'COUNTRY_CODE'),
      );
      return;
    }

    const filteringOptions = countryCodes.map((countryCode) => ({
      label: projects.countryNames[countryCode] || countryCode,
      value: countryCode,
    }));

    const existingCountryCodeFilter = filtersContext
      .getDynamicFilters()
      .find((dynamicFilter) => dynamicFilter.value === 'COUNTRY_CODE');

    if (!existingCountryCodeFilter) {
      filtersContext.setDynamicFilters([
        ...filtersContext.getDynamicFilters(),
        {
          label: 'Country',
          value: 'COUNTRY_CODE',
          options: filteringOptions,
        },
      ]);
      return;
    }

    existingCountryCodeFilter.options = filteringOptions;
    filtersContext.setDynamicFilters([...filtersContext.getDynamicFilters()]);
  }, []);

  const getApiQueryParams = useCallback(
    (currentPage: number) => {
      const queryParams = {
        ...toCriteriaQueryParams(dynamicFilters, staticFilters),
        ...(staticFilters.orderType !== 'all'
          ? staticFilters.orderType === CMBOrderType.Auction
            ? {
                isAuction: true,
              }
            : staticFilters.orderType === CMBOrderType.Bid
              ? {
                  sideId: 1,
                  isAuction: false,
                }
              : {
                  sideId: 2,
                  isAuction: false,
                }
          : {}),
        ...(staticFilters.price.from
          ? {
              priceFrom: staticFilters.price.from,
            }
          : {}),
        ...(staticFilters.price.to
          ? {
              priceTo: staticFilters.price.to,
            }
          : {}),
        ...(staticFilters.isInstantTrade
          ? {
              isPrefunded: true,
            }
          : {}),
        page: currentPage,
        sortBy: staticFilters.sort === Sorting.MostRecent ? 'createdAtUtc' : 'price',
        sortType:
          staticFilters.sort === Sorting.MostRecent || staticFilters.sort === Sorting.PriceHigh ? 'DESC' : 'ASC',
      };

      return queryParams;
    },
    [dynamicFilters, staticFilters],
  );

  return {
    filteringOptions,
    dynamicFilters,
    staticFilters,
    setSorting,
    setOrderType,
    updateDynamicFilters,
    setVintageYear,
    toggleInstantTrade,
    setPrice,
    setCountries,
    isFetchingFilteringOptions,
    getApiQueryParams,
  };
};
