import { useQuery } from 'react-query';

import type { TransactionResponse } from '@aircarbon/utils-common/src/dto/transaction';

import { Account } from 'state/account';

import { fetchTransaction } from 'data-provider/transaction/fetchTransaction';

function useTransactions(options?: Record<string, any>) {
  const { accountUsers } = Account.useContainer();
  const accountAddresses = accountUsers.filter((user) => !!user.account).map((user) => user.account) ?? [];

  const { data: pendingTx } = useQuery<TransactionResponse>(
    ['account-transactions-pending', accountAddresses],
    () => fetchTransaction(accountAddresses, 'pending'),
    { ...options, refetchOnWindowFocus: true },
  );

  const { data: allTx, isLoading: isLoadingAllTx } = useQuery<TransactionResponse>(
    ['account-transactions', accountAddresses],
    () => fetchTransaction(accountAddresses),
    {
      ...options,
      refetchOnWindowFocus: true,
    },
  );

  const getTxByTxHash = (txHash: string) => allTx?.items?.find((tx) => tx.txHash === txHash);
  const getTxById = (transactionId: number) => allTx?.items?.find((tx) => tx.id === transactionId);

  return {
    pendingTx,
    allTx,
    isLoadingAllTx,
    getTxByTxHash,
    getTxById,
  };
}

export default useTransactions;
