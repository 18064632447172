import type { Dto } from '@aircarbon/utils-common';

interface ToAssetsAllocationsProps {
  assetConfigurationJSON?: string | null;
  assets: Dto.Asset[];
  tokensQuantities: Record<number, number>;
}

export const toAssetsAllocations = (props: ToAssetsAllocationsProps) => {
  const { assetConfigurationJSON, tokensQuantities, assets } = props;
  const assetsAllocations: Array<{
    name: string;
    percentage: number;
    availableBalance: number;
  }> = [];
  try {
    if (!tokensQuantities || !assetConfigurationJSON) {
      return [];
    }

    const decodedConfiguration = JSON.parse(assetConfigurationJSON);
    const tokensByIdMap = decodedConfiguration.tokens?.reduce(
      (current: any, token: { tokenTypeId: string }) => ({
        ...current,
        [Number(token.tokenTypeId)]: token,
      }),
      {} as Record<number, any>,
    );

    assets.forEach((asset) => {
      const { scId, name } = asset;
      const token = tokensByIdMap[scId];
      if (!token) {
        return;
      }

      assetsAllocations.push({
        name: name,
        percentage: token.percentage,
        availableBalance: tokensQuantities[scId],
      });
    });
    return assetsAllocations;
  } catch {
    return [];
  }
};
