import MimeMatcher from 'mime-matcher';
import { type ChangeEventHandler, type DragEventHandler, useEffect, useRef, useState } from 'react';
import { Button } from 'refreshed-component/atoms/Button';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { ProgressBar } from 'refreshed-component/atoms/ProgressBar';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { hooks } from '@aircarbon/utils-common';

import { FileCard } from './components/FileCard';

enum UploadFileState {
  Idle = 0,
  DragAndDrop = 1,
  UploadError = 2,
}

type UploadFileProps = {
  /**
   * Upload description
   *
   * @example "Max. File Size: 50MB"
   */
  description?: string;

  /**
   * @example "PIN document file is required"
   */
  error?: string;

  /**
   * @example "File is too large! Max. File Size: 50MB"
   */
  uploadError?: string;

  /**
   * List of accepted MIME types
   *
   * @example ["application/pdf", "image/jpeg"]
   */
  accepts?: Array<string>;

  /**
   * Handle file change
   */
  onChange?(file: File): void;
};

interface UploadProgressProps {
  /**
   * Upload progress in %
   */
  percentage: number;

  /**
   * File name
   */
  fileName: string;

  /**
   * Handle cancel file upload
   */
  onPressCancel(): void;
}

export const UploadFile: React.FC<UploadFileProps> & {
  FileCard: typeof FileCard;
  UploadProgress: React.FC<UploadProgressProps>;
} = (props) => {
  const { accepts = [], onChange = () => {}, uploadError, error, description } = props;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadFileState, setUploadFileState] = useState(UploadFileState.Idle);
  const previousUploadState = hooks.usePrevious(uploadFileState);

  useEffect(() => {
    if (!!uploadError) {
      setUploadFileState(UploadFileState.UploadError);
    } else if (UploadFileState.UploadError) {
      setUploadFileState(UploadFileState.Idle);
    }
  }, [uploadError]);

  const onDragEnter: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setUploadFileState(UploadFileState.DragAndDrop);
  };

  const onDragLeave: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setUploadFileState(
      previousUploadState === UploadFileState.UploadError ? UploadFileState.UploadError : UploadFileState.Idle,
    );
  };

  const onDragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setUploadFileState(
      previousUploadState === UploadFileState.UploadError ? UploadFileState.UploadError : UploadFileState.Idle,
    );

    const files = e.dataTransfer.files;

    if (!files?.length) {
      return;
    }

    const file = files[0];

    if (accepts.length) {
      const mimeMatcher = new MimeMatcher(...accepts);
      if (!mimeMatcher.match(file.type)) {
        return;
      }
    }

    onChange(file);
  };

  const onChangeFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    onChange(file);
  };

  const onPressUpload = () => {
    fileInputRef.current?.click();
  };

  const isInErrorState = uploadFileState === UploadFileState.UploadError;

  return (
    <StyledUploadFile
      hasError={!!error}
      state={uploadFileState}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onClick={onPressUpload}
    >
      <Icon
        width={34}
        height={34}
        style={{
          color: `var(${
            isInErrorState
              ? Colors.danger_700
              : uploadFileState === UploadFileState.DragAndDrop
                ? Colors.secondaryDefault
                : Colors.gray_500
          })`,
        }}
        type={isInErrorState ? IconType.Upload : IconType.Upload}
      />
      <StyledClickToUploadText size={FontSize.small} color={Colors.gray_500}>
        {isInErrorState ? (
          <Text color={Colors.danger_700} weight={FontWeight.bold}>
            Failed to Upload
          </Text>
        ) : (
          <>
            <Text color={Colors.gray_500} weight={FontWeight.bold}>
              Click to upload
            </Text>{' '}
            or drag and drop
          </>
        )}
      </StyledClickToUploadText>
      {isInErrorState && (
        <StyledClickToUploadText size={FontSize.xs} color={Colors.danger_700}>
          {uploadError}
        </StyledClickToUploadText>
      )}
      {!isInErrorState && !!description && (
        <StyledClickToUploadText size={FontSize.xs} color={Colors.gray_500}>
          {description}
        </StyledClickToUploadText>
      )}
      <StyledFileInput ref={fileInputRef} accept={accepts.join(',')} onChange={onChangeFile} />
    </StyledUploadFile>
  );
};

UploadFile.FileCard = FileCard;

UploadFile.UploadProgress = (props) => {
  const { fileName, percentage, onPressCancel } = props;
  return (
    <StyledUploadFile state={UploadFileState.Idle}>
      <Text size={FontSize.small} weight={FontWeight.semibold}>
        Uploading...
      </Text>
      <StyledProgressBar label={fileName} progress={percentage} value={`${Math.round(percentage)}%`} />
      <StyledCancelButton
        config={{
          color: 'outlined',
        }}
        onClick={onPressCancel}
      >
        Cancel
      </StyledCancelButton>
    </StyledUploadFile>
  );
};

const StyledProgressBar = styled(ProgressBar)`
  max-width: 370px;
  margin-top: 8px;
`;

const StyledCancelButton = styled(Button)`
  margin-top: 16px;
`;

const StyledUploadFile = styled.div<{
  state: UploadFileState;
  hasError?: boolean;
}>`
  cursor: pointer;
  border-radius: 8px;
  border: 2px dashed
    var(
      ${({ state, hasError }) =>
        state === UploadFileState.DragAndDrop
          ? Colors.secondaryDefault
          : state === UploadFileState.UploadError || hasError
            ? Colors.danger_700
            : Colors.gray_200}
    );
  background: var(
    ${({ state }) =>
      state === UploadFileState.DragAndDrop
        ? Colors.gray_200
        : state === UploadFileState.UploadError
          ? Colors.danger_50
          : Colors.gray_50}
  );
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 228px;
  color: var(${Colors.gray_400});
`;

const StyledClickToUploadText = styled(Text)`
  margin-top: var(${Spacing._1_5});
`;

const StyledFileInput = styled.input.attrs({ type: 'file' })`
  display: none;
`;
