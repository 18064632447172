export default (
  <svg viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.400024 0.901562C0.400024 0.689389 0.48431 0.485906 0.634339 0.335877C0.784368 0.185848 0.987851 0.101563 1.20002 0.101562H10.8C11.0122 0.101563 11.2157 0.185848 11.3657 0.335877C11.5157 0.485906 11.6 0.689389 11.6 0.901562V3.30156C11.6 3.51372 11.5157 3.71717 11.3656 3.86716L7.60003 7.63276V10.5016C7.59998 10.7137 7.51566 10.9172 7.36562 11.0672L5.76562 12.6672C5.65374 12.779 5.51121 12.8552 5.35605 12.886C5.20089 12.9169 5.04006 12.901 4.8939 12.8405C4.74774 12.78 4.62281 12.6775 4.5349 12.5459C4.447 12.4144 4.40006 12.2598 4.40002 12.1016V7.63276L0.634424 3.86716C0.484385 3.71717 0.40007 3.51372 0.400024 3.30156V0.901562Z"
      fill="#111111"
    />
  </svg>
);
