export enum FiatDepositStatus {
  Canceled = 'CANCELLED',
  Done = 'DONE',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
  InProgress = 'IN PROGRESS',
  CompletionFailed = 'COMPLETION_FAILED',
}

export enum FiatWithdrawalStatus {
  Canceled = 'CANCELLED',
  Debited = 'DEBITED',
  Done = 'DONE',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Refunding = 'REFUNDING',
  Reverted = 'REVERTED',
  Submitted = 'SUBMITTED',
  Submitting = 'SUBMITTING',
  InProgress = 'IN PROGRESS',
  ProcessingFailed = 'PROCESSING_FAILED',
  CompletionFailed = 'COMPLETION_FAILED',
  Settled = 'SETTLED',
}
