import React from 'react';
import type { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

const PageNavigationRoot = styled.div`
  gap: var(${Spacing.xs});
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  > .path {
    display: flex;
    flex-direction: row;
    gap: var(${Spacing.large});
    justify-content: center;
    align-items: center;
    width: min-content;
  }
  > .back {
    display: flex;
    flex-direction: row;
    gap: var(${Spacing.xs});
    cursor: pointer;
  }
`;

interface Item {
  label: string | ReactNode;
  path?: string;
}

interface Props {
  list: Item[];
  suffix?: React.ReactNode;
}

export const PageNavigationHolder = (props: Props) => {
  // TODO: Pavlo this needs to be properly implemented. Even discuss with Nour to come to agreement. I think we should have the name of the project (or any item) as title as I did here, replacing the GO BACK action.
  const history = useHistory();
  const secondLastItem = props.list.length >= 2 ? props.list[props.list.length - 2] : undefined;
  const lastItem = props.list[props.list.length - 1];
  return (
    <StyledPageNavigationHolder>
      <PageNavigationRoot>
        <div className="path">
          {props.list.map((item, index, list) => {
            const isLastItem = index === list.length - 1;
            const isSecondLastItem = index === list.length - 2;
            if (isLastItem) return null;
            return (
              <React.Fragment key={`${index}-${item.label}`}>
                <Text
                  color={isLastItem ? Colors.gray_900 : Colors.gray_500}
                  size={FontSize.xs}
                  weight={FontWeight.medium}
                  className="whitespace-pre cursor-pointer"
                  onClick={() => {
                    if (item.path) history.push(item.path);
                  }}
                >
                  {item.label}
                </Text>
                <Text color={Colors.gray_500}>
                  {!isSecondLastItem && <Icon width={12} height={12} type={IconType.ChevronRight} />}
                </Text>
              </React.Fragment>
            );
          })}
        </div>
        {secondLastItem && (
          // <div
          //   className="back"
          //   onClick={() => {
          //     if (secondLastItem.path) history.push(secondLastItem.path);
          //   }}
          // >
          //   <Text size={FontSize.large} weight={FontWeight.semibold}>
          //     <Icon width={12} height={12} type={IconType.ChevronLeft} />
          //   </Text>
          //   <Text size={FontSize.large} weight={FontWeight.semibold}>
          //     {`Back to ${secondLastItem.label}`}
          //   </Text>
          // </div>
          <div
            className="my-2 back"
            onClick={() => {
              if (secondLastItem.path) history.push(secondLastItem.path);
            }}
          >
            {/* <Text size={FontSize.large} weight={FontWeight.semibold}>
            <Icon width={12} height={12} type={IconType.ChevronLeft} />
          </Text> */}
            <Text size={FontSize._3Xl} weight={FontWeight.semibold}>
              {lastItem.label}
            </Text>
          </div>
        )}
      </PageNavigationRoot>
      {props.suffix}
    </StyledPageNavigationHolder>
  );
};

const StyledPageNavigationHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(${Spacing.large});

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
