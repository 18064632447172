export default (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.400024 12.1016C0.400024 11.8894 0.48431 11.6859 0.634339 11.5359C0.784368 11.3858 0.987851 11.3016 1.20002 11.3016H10.8C11.0122 11.3016 11.2157 11.3858 11.3657 11.5359C11.5157 11.6859 11.6 11.8894 11.6 12.1016C11.6 12.3137 11.5157 12.5172 11.3657 12.6672C11.2157 12.8173 11.0122 12.9016 10.8 12.9016H1.20002C0.987851 12.9016 0.784368 12.8173 0.634339 12.6672C0.48431 12.5172 0.400024 12.3137 0.400024 12.1016ZM3.03442 5.93596C3.18445 5.78599 3.38789 5.70173 3.60002 5.70173C3.81216 5.70173 4.0156 5.78599 4.16562 5.93596L5.20002 6.97036V0.901562C5.20002 0.689389 5.28431 0.485906 5.43434 0.335877C5.58437 0.185848 5.78785 0.101563 6.00002 0.101562C6.2122 0.101563 6.41568 0.185848 6.56571 0.335877C6.71574 0.485906 6.80002 0.689389 6.80002 0.901562V6.97036L7.83443 5.93596C7.90822 5.85955 7.9965 5.79861 8.0941 5.75668C8.1917 5.71475 8.29668 5.69268 8.4029 5.69176C8.50913 5.69084 8.61447 5.71108 8.71279 5.75131C8.81111 5.79153 8.90043 5.85093 8.97554 5.92605C9.05066 6.00116 9.11006 6.09048 9.15028 6.1888C9.19051 6.28712 9.21075 6.39246 9.20983 6.49868C9.2089 6.60491 9.18683 6.70988 9.14491 6.80749C9.10298 6.90509 9.04203 6.99336 8.96562 7.06716L6.56562 9.46716C6.4156 9.61714 6.21216 9.70139 6.00002 9.70139C5.78789 9.70139 5.58445 9.61714 5.43442 9.46716L3.03442 7.06716C2.88445 6.91714 2.8002 6.71369 2.8002 6.50156C2.8002 6.28943 2.88445 6.08598 3.03442 5.93596Z"
      fill="#111111"
    />
  </svg>
);
