import isEqual from 'lodash/isEqual';
import { useState, useEffect, useCallback } from 'react';

import { logger } from '@aircarbon/utils-common';

function useBasketQuantity(basketConfig: string) {
  const [tokens, setTokens] = useState<Array<{ percentage: number }>>([]);

  const isValidQty = useCallback(
    (qty) => {
      return !tokens.some((item) => !Number.isInteger((qty * item.percentage) / 100));
    },
    [tokens],
  );

  useEffect(() => {
    try {
      if (basketConfig) {
        const result = JSON.parse(basketConfig) || {};
        setTokens(result?.tokens ?? []);
      }
    } catch (error) {
      logger.error(error);
    }
    return () => {
      setTokens([]);
    };
  }, [basketConfig]);

  return {
    setBasketConfig: (config: string) => {
      try {
        if (config) {
          const result = JSON.parse(config) || {};
          if (!isEqual(result?.tokens ?? [], tokens)) {
            setTokens(result?.tokens ?? []);
          }
        }
      } catch (error) {
        logger.error(error);
      }
    },
    setBasketTypes: (basketTypes: Array<any>) => {
      if (!isEqual(basketTypes, tokens)) {
        setTokens(basketTypes);
      }
    },
    generateNextValidQuantity: (qty?: number) => {
      let next = 0;
      if (Number.isNaN(Number(qty))) {
        return next;
      }

      next = Number(qty) + 1;
      while (next < Number.POSITIVE_INFINITY && !isValidQty(next)) {
        next += 1;
      }

      return next;
    },
    generatePreviousValidQuantity: (qty?: number) => {
      let prev = 0;
      if (Number.isNaN(Number(qty))) {
        return prev;
      }

      prev = Number(qty) - 1 > 0 ? Number(qty) - 1 : 0;
      while (prev > 0 && !isValidQty(prev)) {
        prev -= 1;
      }

      return prev;
    },
  };
}

export default useBasketQuantity;
