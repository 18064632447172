import queryString from 'query-string';

import { externalApi } from '../fetcher';

export const fetchPublicTrades = (data: {
  pairId: string;
  typeIds?: number[];
  page: number;
  limit: number;
  status: 'confirmed';
}) => {
  const qs = queryString.stringify(data, {
    arrayFormat: 'bracket',
  });
  const endpoint = `/public/all-trades?${qs}`;

  const res = externalApi.url(endpoint).get();
  return res.json();
};
