import { useEffect, useState } from 'react';
import { Project } from 'refreshed-pages/market-board-v2/utils/Project';
import { TimeInForce } from 'refreshed-pages/market-board-v2/utils/TimeInForce';

import { CMBOrderType } from '@aircarbon/ui';

export interface UsePlaceOrderFormProps {
  orderType: CMBOrderType;
}

export interface BidFormValue {
  price: string;
  quantity: string;
  timeInForce: string;
  isInstantTrade: boolean;
  endDate: string;
}

export interface OfferFormValue extends BidFormValue {
  project?: Project;
  vintage: string;
  baseAssetId?: string;
}

const initialOfferFormValue: OfferFormValue = {
  price: '',
  quantity: '',
  timeInForce: TimeInForce.GoodTillCancel,
  isInstantTrade: false,
  vintage: '',
  endDate: '',
};

const initialBidFormValue: BidFormValue = {
  price: '',
  quantity: '',
  timeInForce: TimeInForce.GoodTillCancel,
  isInstantTrade: false,
  endDate: '',
};

export const usePlaceOrderForm = (props: UsePlaceOrderFormProps) => {
  const { orderType } = props;
  const [value, setValue] = useState<BidFormValue | OfferFormValue>(
    orderType === CMBOrderType.Offer ? initialOfferFormValue : initialBidFormValue,
  );

  useEffect(() => {
    setValue(orderType === CMBOrderType.Offer ? initialOfferFormValue : initialBidFormValue);
  }, [orderType]);

  return {
    value,
    setValue,
  };
};
