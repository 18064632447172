import { queryCache, useQuery } from 'react-query';

import { fetchUserOpenTokenBalance } from 'data-provider/user/fetchUserOpenTokenBalance';

export function useUserOpenTokenBalance(accountAddress: string) {
  const { data, isLoading, error } = useQuery(
    ['oms-user-open-token-balance', accountAddress],
    () => fetchUserOpenTokenBalance(accountAddress),
    {
      enabled: accountAddress,
    },
  );
  const refetchBalance = () => {
    queryCache.refetchQueries(['oms-user-open-token-balance', accountAddress]);
  };

  const getOpenOrderToken = (pairId: number) => {
    const openOrderToken = data?.openOrderToken ?? [];
    return openOrderToken?.length > 0 ? openOrderToken?.find((token: any) => token.pair_id === pairId)?.token ?? 0 : 0;
  };

  const getPendingTransactionToken = (pairId: number) => {
    const pendingTransactionToken = data?.pendingTransactionToken ?? [];
    return pendingTransactionToken?.length > 0
      ? pendingTransactionToken?.find((token: any) => token.pair_id === pairId)?.token ?? 0
      : 0;
  };

  return {
    error,
    isLoadingOpenTokenBalance: isLoading,
    openTokenBalance: data,
    refetchOpenTokenBalance: refetchBalance,
    selectors: {
      getOpenOrderToken: (pairId: number) => getOpenOrderToken(pairId),
      getPendingTransactionToken: (pairId: number) => getPendingTransactionToken(pairId),
      getTotalOpenToken: (pairId: number) => getOpenOrderToken(pairId) + getPendingTransactionToken(pairId),
    },
  };
}
