import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HitArea } from 'refreshed-component/atoms/HitArea';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import { toast } from 'refreshed-component/molecules/toast';
import styled from 'styled-components';

import { Button } from '@aircarbon/ui';

import { PanelList } from 'pages/account/trading/PanelList';
import SimpleBar from 'pages/account/trading/components/Simplebar';
import { Themes } from 'pages/account/trading/components/Themes';
import type { Theme } from 'pages/account/trading/components/types';
import { useNotifications } from 'pages/account/trading/hooks';

import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useQueryParams from 'hooks/useQueryParams';

import { formatDate } from 'utils/helpers';

const filters = ['All', 'Alert', 'Email'];

export const Holder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-items: stretch;
`;

export const Content = styled.div`
  background: var(${Colors.gray_0});
  color: var(${Colors.gray_900});
  width: auto;
  min-width: max-content;
  display: flex;
  flex-direction: column;

  svg {
    stroke: var(${Colors.gray_900});
  }
  .title {
    font-size: 20px;
    font-weight: 700;
  }
  .date {
    font-size: 12px;
  }
  .attachment {
    width: min-content;
    border: 1px solid var(${Colors.gray_200});
    height: 34px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    background-color: var(${Colors.gray_0});
    box-shadow: 0px 4px 9px 4px #00000008;

    &:hover {
      border: 1px solid var(${Colors.gray_400});
      box-shadow: 0px 4px 9px 4px #00000009;
      background-color: var(${Colors.gray_100});
    }
    > .icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg > path {
        stroke: transparent;
        fill: var(${Colors.gray_0});
      }
    }
    > .name {
      white-space: pre;
      font-size: 12px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
`;

const actionCallToAction: Record<string, string> = {
  mbOrder: '/account/mb-and-auctions/{{orderId}}',
};

type NotificationsPageProps = {
  theme?: Theme;
};

export const NotificationsPage = ({ theme: themeType }: NotificationsPageProps) => {
  const { entity } = Entity.useContainer();
  const { screenSize } = UI.useContainer();
  const notificationRef = useRef<HTMLDivElement>(null);

  const [search, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setFilter] = useState('All');
  const [selectedItem, setItem] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const queryParams = useQueryParams();
  const queryId = queryParams.get('id');

  const history = useHistory();
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  const {
    notifications,
    markAsRead,
    refetchNotifications,
    selector: { unreadCounter },
  } = useNotifications(selectedFilter, searchTerm);

  const onSelectItem = (item: any) => {
    setItem(item);
    markAsRead(item?.id);
    refetchNotifications();
  };

  useEffect(() => {
    if (queryId && !Number.isNaN(queryId) && notifications) {
      const item = notifications.find((item: { id: number | string }) => Number(item.id) === Number(queryId));
      if (item && Number(selectedItem?.id) !== Number(item.id)) {
        setItem(item);
      }
      history.push(`/account/notifications`);
    } else if (selectedItem === null && notifications) {
      setItem(notifications[0]);
    }
  }, [queryId, notifications, selectedItem, history]);

  const onChooseFilter = (filter: string) => {
    setFilter(filter);
    if (filter !== 'All') {
      setItem(null);
    }
  };

  const onDownload = (blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `${fileName}.pdf`);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const downloadFile = (authToken: string, fileName: string) =>
    // TODO: Implement data-provider
    fetch(`/api/user/user/download-s3-file?filename=${fileName}`, {
      method: 'GET',
      headers: { authorization: `Bearer ${authToken}` },
    })
      .then((resp) => resp.blob())
      .then((blob) => onDownload(blob, fileName))
      .catch((error) => toast.error(error.messages));

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchTerm(search);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const showedNotifications: any[] = notifications ?? [];

  const list = (
    <div ref={notificationRef} className="flex flex-col items-stretch h-full">
      <div className="flex flex-col gap-base">
        <div className="flex flex-row px-large pt-base">
          <Input
            placeholder="Search"
            config={{
              size: 'sm',
              color: 'gray',
              postfix: (
                <HitArea
                  width={20}
                  height={20}
                  className="cursor-pointer"
                  onClick={() => {
                    setSearch('');
                  }}
                >
                  <Icon type={IconType.X} width={10} height={10} />
                </HitArea>
              ),
              prefix: <Icon type={IconType.Search} width={14} height={14} />,
            }}
            className="flex-1"
            value={search}
            onChange={(event) => {
              setSearch(event.target.value.trim() || '');
            }}
          />
        </div>
        <div className="flex flex-row border-b border-gray_300 px-large gap-large">
          {filters.map((item, index) => {
            const isSelected = selectedFilter === item;
            return (
              <div
                className={`pb-small flex flex-row gap-xs ${
                  isSelected ? 'border-b-2 border-gray_900' : ''
                } cursor-pointer`}
                onClick={() => onChooseFilter(item)}
                key={`filter-key-${item}`}
              >
                <Text
                  lineHeight={22}
                  color={isSelected ? Colors.gray_900 : Colors.gray_500}
                  weight={isSelected ? FontWeight.semibold : FontWeight.default}
                >
                  {item}
                </Text>
                {!!unreadCounter && index === 0 && (
                  <Text
                    spacingLR={Spacing.xs}
                    background={Colors.gray_200}
                    radius={Radius.medium}
                    lineHeight={20}
                    color={Colors.gray_900}
                    weight={FontWeight.default}
                    size={FontSize.xs}
                  >
                    {unreadCounter}
                  </Text>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="overflow-hidden flex-1 flex-shrink">
        <SimpleBar>
          <div className="flex flex-col w-full">
            {showedNotifications?.length <= 0 ? (
              <Text spacing={Spacing.base} align="center" color={Colors.gray_900} size={FontSize.base}>
                No Notifications Available
              </Text>
            ) : (
              showedNotifications?.map((item: any, index: number, list) => {
                const isSelectedItem = item.id === selectedItem?.id;
                return (
                  <div
                    key={`item-${item.id}`}
                    onClick={() => {
                      onSelectItem(item);
                    }}
                    style={
                      isSelectedItem
                        ? {
                            borderLeft: 'solid 4px',
                            borderLeftColor: `var(${Colors.summaryIconsIcons})`,
                          }
                        : {}
                    }
                    className={`relative flex flex-row gap-base p-large cursor-pointer border-b border-gray_200 ${
                      item.isRead === 0 ? 'bg-gray_100' : ''
                    } ${isSelectedItem ? 'bg-gray_300' : ''}`}
                  >
                    <div>
                      <div className="flex flex-row justify-center rounded-full bg-summaryIconsIcons p-xs">
                        <svg width="16" height="16" viewBox="0 0 16 12" fill="none">
                          <path
                            d="M0.00292969 1.884L7.99993 5.882L15.9969 1.884C15.9673 1.37444 15.744 0.895488 15.3728 0.545227C15.0015 0.194965 14.5103 -9.35847e-05 13.9999 3.36834e-08H1.99993C1.48951 -9.35847e-05 0.998377 0.194965 0.627107 0.545227C0.255838 0.895488 0.0325338 1.37444 0.00292969 1.884Z"
                            fill={`var(${Colors.summaryIconsBackground})`}
                          />
                          <path
                            d="M16 4.11719L8 8.11719L0 4.11719V9.99919C0 10.5296 0.210714 11.0383 0.585786 11.4134C0.960859 11.7885 1.46957 11.9992 2 11.9992H14C14.5304 11.9992 15.0391 11.7885 15.4142 11.4134C15.7893 11.0383 16 10.5296 16 9.99919V4.11719Z"
                            fill={`var(${Colors.summaryIconsBackground})`}
                          />
                        </svg>
                        {item.isRead === 0 && (
                          <svg
                            className="absolute top-2 left-2 m-xs"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                          >
                            <circle
                              cx="4"
                              cy="4"
                              r="3.5"
                              fill={`var(${Colors.danger_700})`}
                              stroke={`var(${Colors.danger_700})`}
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-xs">
                      <Text color={Colors.gray_900}>
                        {item?.subject ?? item.action}{' '}
                        {item?.userInboxFiles?.length > 0 && (
                          <div className="px-2 text-center icon">
                            <svg width="12" height="14" fill="none">
                              <path
                                d="M10.9628 5.93166L5.9861 11.2971C5.02971 12.3282 3.41993 12.3887 2.38882 11.4323C1.35772 10.4759 1.29721 8.86617 2.2536 7.83507L7.66306 2.00304C8.26026 1.35918 9.2675 1.32132 9.91136 1.91853C10.5552 2.51573 10.5931 3.52297 9.99587 4.16682L5.45193 9.06573C5.21391 9.32234 4.80922 9.33755 4.55261 9.09953C4.296 8.86152 4.28079 8.45682 4.5188 8.20021L8.62999 3.76787L7.93015 3.11874L3.81896 7.55108C3.22175 8.19493 3.25962 9.20217 3.90347 9.79937C4.54733 10.3966 5.55456 10.3587 6.15177 9.71486L10.6957 4.81596C11.6521 3.78486 11.5916 2.17508 10.5605 1.21868C9.52939 0.26229 7.91961 0.322802 6.96322 1.3539L1.55376 7.18593C0.238178 8.60428 0.321339 10.8166 1.73969 12.1322C3.15804 13.4478 5.37036 13.3646 6.68594 11.9463L11.6626 6.58079L10.9628 5.93166Z"
                                fill="gray"
                              />
                            </svg>
                          </div>
                        )}
                      </Text>
                      <Text color={Colors.gray_500} size={FontSize.small}>
                        {formatDate(item?.createdAt)}
                      </Text>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </SimpleBar>
      </div>
    </div>
  );

  const actionId = selectedItem?.action?.split('-')[0]?.split('_');
  const actionType = actionId?.[0];
  const actionItemId = actionId?.[1];
  const actionCtaUrl = actionCallToAction[actionType] ?? null;

  const content = selectedItem ? (
    <div className="w-full h-full">
      <SimpleBar>
        <Content>
          <div className={`flex flex-row justify-between w-full p-large bg-gray_100 border-b border-gray_200`}>
            <div className="flex flex-1 items-center">
              <Text color={Colors.gray_900} weight={FontWeight.semibold}>
                {selectedItem?.subject ?? selectedItem.action}
              </Text>
            </div>
            <div className="flex items-center date">
              <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.default}>
                {formatDate(selectedItem.createdAt)}
              </Text>
            </div>
          </div>
          <div className="p-large" style={{ whiteSpace: 'pre' }}>
            {replaceParameters(`${selectedItem.description ?? selectedItem?.__emailTracking__?.body}`, {
              projectName: entity.name,
            })}
          </div>
          {actionCtaUrl && actionItemId && (
            <div className="flex flex-row justify-center p-large">
              <Button
                onPress={() => {
                  history.push(replaceParameters(actionCtaUrl, { orderId: actionItemId }));
                }}
              >
                View
              </Button>
            </div>
          )}
          {selectedItem?.userInboxFiles?.length > 0 && (
            <div className="flex flex-row gap-4 items-center">
              Files:
              {selectedItem?.userInboxFiles?.map((file: any) => (
                <div
                  className="attachment"
                  key={file.filename}
                  onClick={async () => {
                    setIsLoading(true);
                    const authToken = await getAuthToken();
                    await downloadFile(authToken, file.filename);
                    setIsLoading(false);
                  }}
                >
                  <div className="icon">
                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none">
                      <path
                        d="M10.9628 5.93166L5.9861 11.2971C5.02971 12.3282 3.41993 12.3887 2.38882 11.4323C1.35772 10.4759 1.29721 8.86617 2.2536 7.83507L7.66306 2.00304C8.26026 1.35918 9.2675 1.32132 9.91136 1.91853C10.5552 2.51573 10.5931 3.52297 9.99587 4.16682L5.45193 9.06573C5.21391 9.32234 4.80922 9.33755 4.55261 9.09953C4.296 8.86152 4.28079 8.45682 4.5188 8.20021L8.62999 3.76787L7.93015 3.11874L3.81896 7.55108C3.22175 8.19493 3.25962 9.20217 3.90347 9.79937C4.54733 10.3966 5.55456 10.3587 6.15177 9.71486L10.6957 4.81596C11.6521 3.78486 11.5916 2.17508 10.5605 1.21868C9.52939 0.26229 7.91961 0.322802 6.96322 1.3539L1.55376 7.18593C0.238178 8.60428 0.321339 10.8166 1.73969 12.1322C3.15804 13.4478 5.37036 13.3646 6.68594 11.9463L11.6626 6.58079L10.9628 5.93166Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div className="name">{file.filename}</div>
                  {isLoading && (
                    <div className="w-1/2 h-1/2">
                      <span className="py-2 px-4 w-1/4 h-1/4 spinner"></span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </Content>
      </SimpleBar>
    </div>
  ) : (
    <></>
  );

  return (
    <Holder>
      <Themes.Provider>
        <PanelList
          id={`notifications-main-${screenSize}`}
          type={screenSize === 'small' ? 'column' : 'row'}
          style={{
            background: 'transparent',
          }}
          list={[
            {
              type: screenSize === 'small' ? 'flex' : 'px',
              value: screenSize === 'small' ? 1 : 350,
              children: list,
              style: {
                background: `var(${Colors.gray_0})`,
              },
            },
            {
              type: 'flex',
              value: screenSize === 'small' ? 1.5 : 2.5,
              children: content,
              style: {
                background: `var(${Colors.gray_0})`,
              },
            },
          ]}
        />
      </Themes.Provider>
    </Holder>
  );
};

function replaceParameters(string: string, parameters: { [index: string]: string }) {
  for (const [key, parameter] of Object.entries(parameters)) {
    string = string.replace(new RegExp(`{{${key}}}`, 'g'), parameter);
  }
  return string;
}
