import queryString from 'query-string';

import { Dto, trades } from '@aircarbon/utils-common';

import { userAuthenticatedApi } from 'data-provider/fetcher';

export interface FetchTradeRequestsProps {
  assetCategoryId?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  userId: number;
  limit?: number;
}

export interface TradeRequest {
  id: string;
  price: number;
  quantity: number;
  trader: { firstName: string; lastName: string };
  vintageYear: number;
  project: { name: string; registryProjectId: string };
  registry: { name: string };
  createdAt: string;
  side: trades.OrderSide;
}

export const fetchTradeRequestsReport = async (
  props: FetchTradeRequestsProps,
): Promise<Dto.Page<TradeRequest> | null> => {
  const userService = await userAuthenticatedApi();

  const request = userService
    .url(`/user/otc/report/order`)
    .query(
      queryString.stringify(props, {
        arrayFormat: 'bracket',
      }),
    )
    .get();

  const response = await request.res();

  if (response.status >= 300) {
    return null;
  }

  const resJson: any = await request.json();

  return resJson;
};
