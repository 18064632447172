import { FunctionComponent } from 'react';
import { useFilters } from 'refreshed-pages/market-board-v2/hooks/useFilters';
import { Sorting } from 'refreshed-pages/market-board-v2/utils/Sorting';

import { TypographyVariant, Text, styled, Select, InputTextSize, TextColor, toSpacing } from '@aircarbon/ui';

export const SubHeader: FunctionComponent<{
  resultsCount: number;
}> = ({ resultsCount }) => {
  const { staticFilters, setSorting } = useFilters();
  const onChangeSortingSelect = ({ value }: { value: Sorting }) => {
    setSorting(value);
  };

  return (
    <StyledSubHeader>
      {!!resultsCount && (
        <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
          {resultsCount} {resultsCount > 1 ? 'results' : 'result'}
        </Text>
      )}

      <Select
        value={staticFilters.sort}
        size={InputTextSize.s}
        onChange={onChangeSortingSelect}
        items={[
          {
            value: Sorting.MostRecent,
            title: 'Most Recent',
          },
          {
            value: Sorting.PriceLow,
            title: 'Price: Low to High',
          },
          {
            value: Sorting.PriceHigh,
            title: 'Price: High to Low',
          },
        ]}
      />
    </StyledSubHeader>
  );
};

const StyledSubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => toSpacing(theme)(4)} ${({ theme }) => toSpacing(theme)(8)};
`;
