import { queryCache, useQuery } from 'react-query';

import { fetchExternalBrokerClient } from 'data-provider/user/fetchExternalBrokerClient';

// TODO: Implement pagination
export function useExternalBrokerClient(options = { enabled: true }) {
  const { data, isLoading, error } = useQuery(
    [`/api/user/broker/external-clients`],
    async () => {
      return await fetchExternalBrokerClient();
    },
    { enabled: options?.enabled },
  );
  const refetch = () => {
    queryCache.refetchQueries([`/api/user/broker/external-clients`]);
  };

  return {
    error,
    isLoading,
    refetch,
    data,
  };
}
