import { useEffect, useMemo, useRef, useState } from 'react';
import { Project, ProjectBalance } from 'refreshed-pages/market-board-v2/utils/Project';
import { wait } from 'refreshed-pages/utils/wait';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  IllustrationEmptyListCircle,
  ListItem,
  Select,
  Toggle,
  ToggleSize,
  styled,
  Text,
  TypographyVariant,
  useSpacing,
  TextColor,
  SelectRef,
  Layer,
  Checkbox,
} from '@aircarbon/ui';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { searchProjects } from './utils/searchProjects';

interface BaseSelectProjectProps {
  isOwnedByMeFilterEnabled?: boolean;
  label?: string;
  error?: string;
  isReadOnly?: boolean;
  ownedByUserId: number;
  filterCriteria?: Array<{
    key: string;
    operator: string;
    values: Array<string>;
  }>;
  onPressCreateNewProject?(): void;
}

interface MultiSelectProjectProps extends BaseSelectProjectProps {
  isMultiple?: true;
  value?: Array<Project>;
  onChange(value?: Array<Project>): void;
}

interface SingleSelectProjectProps extends BaseSelectProjectProps {
  isMultiple?: false;
  value?: Project;
  onChange(value?: Project): void;
}

type SelectProjectProps = SingleSelectProjectProps | MultiSelectProjectProps;

export const SelectProject: React.FunctionComponent<SelectProjectProps> = (props) => {
  const [isOwnedByMe, setIsOwnedByMe] = useState(false);
  const {
    isOwnedByMeFilterEnabled,
    onPressCreateNewProject,
    value,
    label = 'Project',
    ownedByUserId,
    onChange,
    isMultiple,
    filterCriteria,
  } = props;
  const { product } = useMarketplaceProduct();
  const [searchValue, setSearchValue] = useState('');
  const { spacing } = useSpacing();
  const selectProjectRef = useRef<SelectRef>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchResults, setSearchResults] = useState<
    Array<{
      id: number;
      title: string;
      description: string;
      registryProjectId: string;
      balances?: ProjectBalance[];
    }>
  >(
    isMultiple && value?.length
      ? value.map((v) => ({
          id: Number(v.value),
          title: v.title,
          description: v.description,
          registryProjectId: v.registryProjectId,
        }))
      : [],
  );

  useEffect(() => {
    let cancelableLoadProjects: ReturnType<
      typeof wait<
        Array<{
          id: number;
          title: string;
          description: string;
          registryProjectId: string;
        }>
      >
    >;

    const loadProjects = async () => {
      setIsLoading(true);
      cancelableLoadProjects = wait(
        () =>
          searchProjects({
            ownedByUserId: ownedByUserId,
            showOwnedOnly: isOwnedByMe,
            assetCategoryId: product,
            criteria: filterCriteria,
            searchValue,
          }),
        500,
      );

      const foundProjects = await cancelableLoadProjects.job;

      setSearchResults(foundProjects);
      setIsLoading(false);
    };

    loadProjects();

    return () => {
      setIsLoading(false);
      if (cancelableLoadProjects) {
        cancelableLoadProjects.cancel();
      }
    };
  }, [isOwnedByMe, searchValue, ownedByUserId, product]);

  const selectedProjectsMap: Record<string, Project> = useMemo(() => {
    if (isMultiple) {
      return (value as Array<Project>).reduce(
        (curr, project) => ({
          ...curr,
          [project.value]: project,
        }),
        {},
      );
    }

    return {};
  }, [value, isMultiple]);

  const onSearchProject = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const onPressItem =
    (item: {
      value: string;
      title: string;
      description: string;
      registryProjectId: string;
      balances?: ProjectBalance[];
    }) =>
    () => {
      if (!isMultiple) {
        (onChange as (value: Project) => void)(item as Project);
        selectProjectRef.current?.toggleDropdownVisibility(false);
        setSearchValue('');
        return;
      }

      const selectedProjectIndex = value?.findIndex((selectedProject) => selectedProject.value === item.value) ?? -1;

      if (selectedProjectIndex === -1) {
        onChange([...(value as Project[]), item]);

        return;
      }

      const newValue = [...(value as Project[])];

      newValue.splice(selectedProjectIndex, 1);

      onChange(newValue);
    };

  const onChangeSelectProject = ({ value: selectProjectValue }: { value: string }) => {
    if (!value) {
      onChange();
      return;
    }

    if (isMultiple) {
      const selectedProjectIds = selectProjectValue.split(',');

      const projects = searchResults
        .filter((searchItem) => selectedProjectIds.includes(String(searchItem.id)))
        .map((searchItem) => ({
          value: String(searchItem.id),
          title: searchItem.title,
          description: searchItem.description,
          registryProjectId: searchItem.registryProjectId,
          balances: searchItem.balances,
        }));

      onChange(projects);

      return;
    }

    if (selectProjectValue === (value as Project).value) {
      return;
    }

    const project = searchResults.find((searchResult) => searchResult.id === Number(selectProjectValue));

    (onChange as (value?: Project) => void)(
      !!project
        ? {
            value: String(project.id),
            title: project.title,
            description: project.description,
            balances: project.balances,
            registryProjectId: project.registryProjectId,
          }
        : undefined,
    );
  };

  const shouldShowLoader = isLoading;
  const shouldShowSearchResults = !isLoading && !!searchResults.length;
  const shouldShowNothingFound = !isLoading && !searchResults.length;

  return (
    <StyledSelect
      items={value ? (isMultiple ? value : [value as Project]) : []}
      isMultiple={isMultiple}
      isSearchable
      placeholder="Select Project"
      searchPlaceholder="Search Project Name or ID"
      toMultiItemProps={(item: Project) => ({ ...item, title: item.registryProjectId ?? item.value })}
      header={
        isOwnedByMeFilterEnabled ? (
          <Toggle
            size={ToggleSize.s}
            isOn={isOwnedByMe}
            onChange={setIsOwnedByMe}
            label={'Show projects in my inventory only'}
          />
        ) : undefined
      }
      isDisabled={props.isReadOnly}
      label={label}
      onSearch={onSearchProject}
      searchValue={searchValue}
      ref={selectProjectRef}
      onChange={onChangeSelectProject}
      value={
        isMultiple ? (value as Array<Project>)?.map((project) => project.value).join(',') : (value as Project)?.value
      }
      error={props.error}
      content={
        <SearchContent>
          {shouldShowLoader && [1, 2, 3].map((key) => <StyledListItemLoader key={key} showDescription showPrefix />)}
          {shouldShowSearchResults &&
            searchResults.map((result, index) => (
              <ListItem
                key={index}
                title={result.title}
                description={result.description}
                onPress={onPressItem({
                  value: String(result.id),
                  title: result.title,
                  description: result.description,
                  balances: result.balances,
                  registryProjectId: result.registryProjectId,
                })}
                prefix={
                  isMultiple ? (
                    <Layer>
                      <Checkbox isReadOnly isChecked={!!selectedProjectsMap[String(result.id)]} />
                    </Layer>
                  ) : undefined
                }
              />
            ))}
          {shouldShowNothingFound && (
            <EmptyResultsSection>
              <IllustrationEmptyListCircle />
              <Text variant={TypographyVariant.subtitle1} marginTop={spacing(8)}>
                No Results Found
              </Text>
              <Text variant={TypographyVariant.body2} marginTop={spacing(2)} color={TextColor.secondary}>
                We can't find any matching project
              </Text>
            </EmptyResultsSection>
          )}
        </SearchContent>
      }
      footer={
        !!onPressCreateNewProject ? (
          <Button size={ButtonSize.xs} variant={ButtonVariant.outlined} onPress={onPressCreateNewProject}>
            Create New Project
          </Button>
        ) : undefined
      }
    />
  );
};

const StyledSelect = styled(Select)`
  overflow: hidden;
  min-width: 0;
`;

const SearchContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledListItemLoader = styled(ListItem.Loader)`
  align-self: stretch;
`;

const EmptyResultsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 13.375rem;
`;
