import { useQuery } from 'react-query';

import { fetchCarbonProjectById } from 'data-provider/contract/fetchCarbonProjectById';

type ProjectType = Awaited<ReturnType<typeof fetchCarbonProjectById>>;

/**
 * Fetches project metadata for a list of batches
 *
 * @param batches - List of batches
 * @param registryId - Registry ID
 * @returns
 */
const useProjectsMetadata = (batches: { id: number; projectId: string }[], registryId?: string) => {
  const uniqueProjectIds = [...new Set(batches.map((batch) => batch.projectId))].filter(Boolean);
  const {
    data: projects,
    isLoading,
    error,
  } = useQuery(
    ['projects-metadata', batches, registryId],
    () => {
      return Promise.all(uniqueProjectIds.map((projectId) => fetchCarbonProjectById(projectId, registryId)));
    },
    {
      enabled: uniqueProjectIds.length > 0,
    },
  );

  const projectMap = projects?.reduce(
    (result, project) => {
      if (project) {
        result[project.registryProjectId] = project;
      }
      return result;
    },
    {} as Record<string, ProjectType>,
  );

  const batchesWithProject = batches.map((batch) => ({
    batchId: batch.id,
    project: projectMap ? projectMap[batch.projectId] : null,
  }));

  return {
    projects: !projects ? [] : batchesWithProject,
    isLoading,
    error,
  };
};

export default useProjectsMetadata;
