// TODO: Use from common instead
export const recList = ['Registry', 'Vintage', 'Technology', 'Country'];
export const carbonList = ['Registry Project ID', 'Vintage', 'Contract'];
export const metaMapping: { [key: string]: string } = {
  REGISTRY_ID: 'Registry',
  VINTAGE_YEAR: 'Vintage',
  REC_TECHNOLOGY: 'Technology',
  COUNTRY_CODE: 'Country',
  PROJECT_ID: 'Registry Project ID',
};
