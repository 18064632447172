export enum ProjectStatus {
  Draft = 0,
  Rejected = 1,
  Submitted = 2,
  Active = 3,
}

export enum ApxProjectStatusCode {
  Draft = 'draft',
  Submitted = 'submitted',
  Rejected = 'rejected',
  Active = 'active',
}
