export const toOfferedQuantity = (props: { estimatedQuantity: string; percentage: string }) => {
  const estimatedQuantityValue = Number(props.estimatedQuantity);
  const percentageValue = Number(props.percentage);

  if (isNaN(estimatedQuantityValue) || isNaN(percentageValue)) {
    return '0';
  }

  return String(Math.ceil(estimatedQuantityValue * (percentageValue / 100)));
};
