import { useFilters } from 'refreshed-pages/market-board-v2/hooks/useFilters';
import { OtcCriteria } from 'refreshed-pages/market-board-v2/utils/OtcCriteria';

import {
  Accordion,
  Badge,
  BadgeVariant,
  Card,
  CardVariant,
  Checkbox,
  Divider,
  IconName,
  Layer,
  ListCountries,
  Select,
  Text,
  Toggle,
  ToggleLayout,
  ToggleSize,
  TypographyVariant,
  styled,
  toSpacing,
  useSpacing,
} from '@aircarbon/ui';
import { assetCategoryUom, Const } from '@aircarbon/utils-common';
import { AssetCategory } from '@aircarbon/utils-common';

import { UI } from 'state/ui';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { toVintageYears } from '../../utils/toVintageYears';
import { InputRange } from './components/InputRange';
import { SearchProject } from './components/SearchProject';

export const Filters: React.FunctionComponent<{}> = () => {
  const { spacing } = useSpacing();
  const {
    filteringOptions,
    isFetchingFilteringOptions,
    dynamicFilters,
    updateDynamicFilters,
    setVintageYear,
    staticFilters,
    setPrice,
    setCountries,
  } = useFilters();
  const { product } = useMarketplaceProduct();

  const uom = assetCategoryUom[Number(product) as AssetCategory];
  const checkIfFilteringOptionSelected = (
    filteringOption: OtcCriteria[0],
    optionToCheck: {
      value: string;
    },
  ) => {
    const existingFilter = dynamicFilters.find((filter) => filter.value === filteringOption.value);

    if (!existingFilter) {
      return false;
    }

    const isOptionSelected = !!existingFilter.options?.find((option) => option.value === optionToCheck.value);

    return isOptionSelected;
  };

  const onChangeDynamicFilterCheckbox = (props: {
    value: string;
    label: string;
    option: {
      label: string;
      value: string;
    };
  }) => {
    updateDynamicFilters(props);
  };

  const onChangeCountry = ({ value }: { value: string }) => {
    setCountries(value);
  };

  const onSelectProject = (project: { id: number; title: string; description: string }) => {
    updateDynamicFilters({
      label: 'Project',
      value: 'PROJECT_ID',
      option: {
        label: project.title,
        value: String(project.id),
      },
    });
  };

  const toCountryValue = () => {
    const existingCountryCodeFilter = dynamicFilters.find((dynamicFilter) => dynamicFilter.value === 'COUNTRY_CODE');

    if (!existingCountryCodeFilter) {
      return '';
    }

    return existingCountryCodeFilter.options?.map((option) => option.value).join(',');
  };

  return (
    <StyledFilters>
      <StyledCard
        variant={CardVariant.Bordered}
        header={<FilterHeader onSelectProject={onSelectProject} />}
        footer={<FilterFooter />}
      >
        <Text variant={TypographyVariant.subtitle2} marginVertical={spacing(4)} marginHorizontal={spacing(8)}>
          Project Criteria
        </Text>

        {isFetchingFilteringOptions && (
          <>
            <Accordion.Loader />
            <Accordion.Loader />
            <Accordion.Loader />
            <Accordion.Loader />
            <Accordion.Loader />
            <Accordion.Loader />
          </>
        )}
        {!isFetchingFilteringOptions &&
          filteringOptions.map((filteringOption) => {
            if (filteringOption.value === 'COUNTRY_CODE') {
              return (
                <Accordion title={filteringOption.label}>
                  <StyledListCountries isMultiple onChange={onChangeCountry} value={toCountryValue()} />
                </Accordion>
              );
            }

            if (filteringOption.value === 'VINTAGE_YEAR') {
              return (
                <Accordion title={filteringOption.label}>
                  <Layer>
                    <StyledSelect
                      placeholder="Select Vintage Years"
                      isMultiple
                      items={toVintageYears()}
                      value={staticFilters.vintageYear}
                      onChange={({ value }: { value: string }) => setVintageYear(value)}
                    />
                  </Layer>
                </Accordion>
              );
            }

            return (
              <Accordion title={filteringOption.label}>
                <Layer>
                  <CheckboxList>
                    {!!filteringOption.options?.length &&
                      filteringOption.options?.map((option) => (
                        <Checkbox
                          label={option.label}
                          isChecked={checkIfFilteringOptionSelected(filteringOption, option)}
                          onChange={() =>
                            onChangeDynamicFilterCheckbox({
                              label: filteringOption.label,
                              value: filteringOption.value,
                              option,
                            })
                          }
                        />
                      ))}
                  </CheckboxList>
                </Layer>
              </Accordion>
            );
          })}

        <Divider />
        <PriceContainer>
          <Text variant={TypographyVariant.body1}>Price (per {uom})</Text>
          <InputRange
            from={staticFilters.price.from}
            to={staticFilters.price.to}
            fromPlaceholder="Price"
            toPlaceholder="Price"
            onChange={setPrice}
          />
        </PriceContainer>
      </StyledCard>
    </StyledFilters>
  );
};

const StyledFilters = styled.div`
  overflow-y: auto;
  align-self: stretch;
  flex-shrink: 0;
  @media (max-width: 1258px) {
    overflow-y: initial;
  }
`;

const FilterHeader: React.FunctionComponent<{
  onSelectProject(project: { title: string; description: string; id: number }): void;
}> = ({ onSelectProject }) => {
  return (
    <StyledFilterHeader>
      <SearchProject onChange={onSelectProject} />
    </StyledFilterHeader>
  );
};

const StyledFilterHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => toSpacing(theme)(8)};
  > * > * {
    width: 100%;
  }
`;

const FilterFooter = () => {
  const { spacing } = useSpacing();
  const { staticFilters, toggleInstantTrade } = useFilters();
  const { getSetting } = UI.useContainer();
  const isMarketBoardAndAuctionsV2SettlementModeEnabled =
    getSetting(Const.FeatureToggle.MarketBoardAndAuctionsV2SettlementMode) === '1';

  if (isMarketBoardAndAuctionsV2SettlementModeEnabled) return null;

  return (
    <StyledFilterFooter>
      <Toggle
        label={<Badge value="Assets Reserved" icon={IconName.Bolt} variant={BadgeVariant.Info2} />}
        layout={ToggleLayout.LabelFirst}
        size={ToggleSize.s}
        description="Faster settlement with pre-funded accounts"
        marginHorizontal={spacing(8)}
        marginVertical={spacing(6)}
        onChange={toggleInstantTrade}
        isOn={staticFilters.isInstantTrade}
      />
    </StyledFilterFooter>
  );
};
const StyledFilterFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCard = styled(Card)`
  width: 320px;

  @media (max-width: 1258px) {
    width: 100%;
  }
`;

const StyledListCountries = styled(ListCountries)`
  width: calc(100% + ${({ theme }) => toSpacing(theme)(16)}) !important;
  margin: ${({ theme }) => toSpacing(theme)(-8)};
`;

const CheckboxList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(6)};
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(12)};
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;
