import { type ChangeEventHandler, useEffect, useState } from 'react';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { hooks } from '@aircarbon/utils-common';

import { toOfferedQuantity } from './utils/toOfferedQuantity';
import { toOfferedQuantityPercentage } from './utils/toOfferedQuantityPercentage';

export const OfferedQuantity: React.FC<{
  estimatedQuantity: string;
  offeredQuantity: string;
  maximumOfferedQuantityPercentage: number;
  onChange(offeredQuantity: string): void;
}> = (props) => {
  const { estimatedQuantity, offeredQuantity, maximumOfferedQuantityPercentage, onChange } = props;
  const prevEstimatedQuantity = hooks.usePrevious(estimatedQuantity);
  const [percentage, setPercentage] = useState(
    toOfferedQuantityPercentage({
      estimatedQuantity,
      offeredQuantity,
      maximumOfferedQuantityPercentage,
    }),
  );

  useEffect(() => {
    if (prevEstimatedQuantity === undefined || prevEstimatedQuantity === estimatedQuantity) {
      return;
    }

    onChange(
      toOfferedQuantity({
        percentage,
        estimatedQuantity,
      }),
    );
  }, [prevEstimatedQuantity, estimatedQuantity, percentage]);

  const onChangeOfferedQuantity: ChangeEventHandler<HTMLInputElement> = (e) => {
    const maximumOfferedQuantity = toOfferedQuantity({
      percentage: String(maximumOfferedQuantityPercentage),
      estimatedQuantity: estimatedQuantity,
    });

    if (Number(maximumOfferedQuantity) < Number(e.target.value)) {
      return;
    }

    setPercentage(
      toOfferedQuantityPercentage({
        estimatedQuantity,
        offeredQuantity: e.target.value,
        maximumOfferedQuantityPercentage,
      }),
    );
    onChange(e.target.value);
  };

  const onChangePercentage: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newPercentage = Number(e.target.value);

    if (isNaN(newPercentage) || newPercentage < 0) {
      setPercentage('');
      return;
    }

    if (newPercentage > maximumOfferedQuantityPercentage) {
      return;
    }

    setPercentage(e.target.value);
    onChange(
      toOfferedQuantity({
        percentage: e.target.value,
        estimatedQuantity,
      }),
    );
  };

  return (
    <OfferedQuantityContainer style={{ minWidth: '300px' }}>
      <StyledPercentageInput
        config={{
          color: 'gray',
          postfix: <Text color={Colors.gray_500}>%</Text>,
        }}
        onChange={onChangePercentage}
        value={percentage}
        min="0"
        type="number"
      />
      <StyledNumberInput
        config={{
          color: 'gray',
          postfix: <Text color={Colors.gray_500}>tCO2e</Text>,
        }}
        value={offeredQuantity}
        onChange={onChangeOfferedQuantity}
        type="number"
        min="0"
      />
    </OfferedQuantityContainer>
  );
};

const OfferedQuantityContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledNumberInput = styled(Input)`
  & input {
    -moz-appearance: textfield;
  }
  & > input::-webkit-inner-spin-button,
  & > input::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;

const StyledPercentageInput = styled(StyledNumberInput)`
  max-width: 110px;
`;
