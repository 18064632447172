export default (
  <svg viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 14C20 15.1046 19.1046 16 18 16L2 16C0.895429 16 -2.73753e-06 15.1046 -2.44784e-06 14L-1.66103e-06 11C-1.51619e-06 10.4477 0.447714 10 0.999999 10C1.55228 10 2 10.4477 2 11L2 14L18 14L18 11C18 10.4477 18.4477 10 19 10C19.5523 10 20 10.4477 20 11L20 14Z"
      fill="#111928"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12L16 12L16 1.35694C16 0.606515 15.1408 3.4532e-06 14.0777 3.17438e-06L5.92233 1.03549e-06C4.85922 7.56674e-07 4 0.606512 4 1.35694L4 12ZM8.14662 5.35344C8.05274 5.25967 8 5.13251 8 4.99993C8 4.86735 8.05274 4.74019 8.14662 4.64642L9.64897 3.1464C9.74288 3.05266 9.87023 3 10.003 3C10.1358 3 10.2632 3.05266 10.3571 3.1464L11.8594 4.64643C11.9506 4.74073 12.0011 4.86703 12 4.99813C11.9988 5.12923 11.9462 5.25464 11.8533 5.34735C11.7605 5.44005 11.6349 5.49264 11.5036 5.49378C11.3723 5.49492 11.2458 5.44452 11.1513 5.35344L10.5038 4.70693L10.5038 8.49999C10.5038 8.6326 10.451 8.75978 10.3571 8.85355C10.2632 8.94732 10.1358 9 10.003 9C9.87021 9 9.74283 8.94732 9.64891 8.85355C9.555 8.75978 9.50224 8.6326 9.50224 8.49999L9.50224 4.70693L8.85473 5.35344C8.76082 5.44717 8.63346 5.49983 8.50068 5.49983C8.36789 5.49983 8.24053 5.44717 8.14662 5.35344Z"
      fill="#111928"
    />
  </svg>
);
