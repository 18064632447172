import { OtcCriteria } from './OtcCriteria';

export enum OtcCriteriaEventName {
  /**
   * Wether meta is being fetched from api or not
   */
  Fetching = 'FETCHING',

  /**
   * Otc criteria list has changed
   */
  Changed = 'CHANGED',
}

export type OtcCriteriaEvent =
  | {
      event: OtcCriteriaEventName.Fetching;
      payload: boolean;
    }
  | {
      event: OtcCriteriaEventName.Changed;
      payload: OtcCriteria;
    };
