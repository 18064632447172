import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { FieldWrapper } from 'components/styled/Styled';

import { AssetsAllocationTable } from '../AssetsAllocationTable';
import { Balance } from './components/Balance';

interface PackUnpackGERFormProps {
  balanceTitle: string;
  quantity: string;
  assets: Array<{
    assetType: string;
    balance: string;
    packQuantity: string;
    balanceAfter: string;
  }>;
  resultingBalance: string;
  errors?: Record<string, string>;
  onChangeQuantity(value: string): void;
}

export const PackUnpackGERForm: React.FC<PackUnpackGERFormProps> = (props) => {
  const { balanceTitle, quantity, assets, resultingBalance, errors, onChangeQuantity } = props;

  // Combined error based on quantity and resulting in invalid assets allocation or false resulting balance
  const quantityError = !!errors ? errors[Object.keys(errors)[0]] : '';

  return (
    <StyledPackUnpackGERForm>
      <FieldWrapper>
        <Text size={FontSize.small}>Quantity (min. 1,000)</Text>
        <Input
          config={{
            size: 'sm',
            color: 'gray',
            postfix: (
              <Text color={Colors.gray_500} size={FontSize.small}>
                tCO2
              </Text>
            ),
          }}
          type="number"
          onChange={(e) => onChangeQuantity(e.target.value)}
          value={quantity}
        />
        {!!quantityError && (
          <Text color={Colors.danger_700} size={FontSize.small}>
            {quantityError}
          </Text>
        )}
      </FieldWrapper>
      <AssetsAllocationTable assets={assets} errors={errors} />
      <Balance title={balanceTitle} value={resultingBalance} />
    </StyledPackUnpackGERForm>
  );
};

const StyledPackUnpackGERForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
