import { CMBOrderType } from '@aircarbon/ui';
import { Dto } from '@aircarbon/utils-common';

import { TradeRequest } from './fetchTradeRequests';
import { findLastCounterOffers } from './findLastCounterOffers';
import { toCMBOrderType } from './toCMBOrderType';

export const toOfferStatus = (props: { tradeRequest: TradeRequest; currentUserId: number }) => {
  const { tradeRequest } = props;

  if (tradeRequest.tradeRequestDetail.__project__?.status !== Dto.ProjectStatus.Active) {
    return 'pending';
  }

  if (tradeRequest.status === 'ACCEPTED') {
    return 'accepted';
  }

  if (tradeRequest.status === 'APPROVED') {
    return 'approved';
  }

  if (tradeRequest.status === 'EXECUTED') {
    return 'executed';
  }

  if (tradeRequest.status === 'REJECTED') {
    return 'rejected';
  }

  const otcOrderType = toCMBOrderType({
    isAuction: !!tradeRequest.otcOrder.isAuction,
    sideId: tradeRequest.otcOrder.sideId,
  });

  if (tradeRequest.status === 'CANCELLED' && otcOrderType === CMBOrderType.Auction) {
    return 'canceled';
  }

  if (tradeRequest.status === 'CANCELLED' && otcOrderType !== CMBOrderType.Auction) {
    return 'rejected';
  }

  const { isLastFromOwner } = findLastCounterOffers({
    counterOffers: tradeRequest.counterOffers,
  });

  if (isLastFromOwner) {
    return 'sent';
  }

  return 'received';
};
