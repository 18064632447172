import AirCarbon from './config/ac.json';
import SingDax from './config/sd.json';

const branding = {
  AirCarbon,
  SingDax,
};

/**
 * @deprecated Will be removed in a future release. Please use db instead.
 * @param companyName
 * @returns
 */
export function getBranding(companyName: 'SDAX' | 'AirCarbon' | string): {
  info: {
    name: string;
    phone: string;
    address: string;
    description: string;
    email: {
      info: string;
      support: string;
      onboarding: string;
      operations: string;
      trusteeFiat: string;
    };
    footerLink: {
      name: string;
      url: string;
    }[];
  };
  bank: {
    beneficiaryAddress: string;
    beneficiaryName: string;
    bankName: string;
    swiftCode: string;
    beneficiaryAccountNumber: string;
  };
} {
  if (companyName === 'SDAX') return branding.SingDax;

  return branding.AirCarbon;
}

export default {
  branding,
  getBranding,
};
