import { UploadDocument } from './Documentation/components/UploadDocument';

interface Document {
  name: string;
  size: number;
  url: string;
}

export interface DocumentationFormValue {
  documents: {
    projectIdeaNote: Document | null;
    preFeasibilityStudyDocument: Document | null;
    projectDesignDocument: Document | null;
    verificationReport: Document | null;
    emissionReductionCalculation: Document | null;
    monitoringProtocols: Document | null;
  };
}

export const Documentation: React.FC<{
  value: DocumentationFormValue;
  error?: Record<string, string>;
  onChange(value: DocumentationFormValue): void;
}> = (props) => {
  const { value, error, onChange } = props;

  const onChangeFile =
    <K extends keyof DocumentationFormValue['documents']>(key: K) =>
    (newValue: DocumentationFormValue['documents'][K]) => {
      onChange({
        ...value,
        documents: {
          ...value.documents,
          [key]: newValue,
        },
      });
    };

  return (
    <div className="flex flex-col w-full mt-large">
      <UploadDocument
        label="Project Idea Note (PIN)*"
        onChange={onChangeFile('projectIdeaNote')}
        value={value.documents.projectIdeaNote}
        error={error?.['documents.projectIdeaNote']}
      />
      <UploadDocument
        label="Pre-feasibility Study Document"
        onChange={onChangeFile('preFeasibilityStudyDocument')}
        value={value.documents.preFeasibilityStudyDocument}
      />
      <UploadDocument
        label="Project Design Document (PDD)"
        onChange={onChangeFile('projectDesignDocument')}
        value={value.documents.projectDesignDocument}
      />
      <UploadDocument
        label="Verification report (If applies)"
        onChange={onChangeFile('verificationReport')}
        value={value.documents.verificationReport}
      />
      <UploadDocument
        label="Emission reduction calculations"
        onChange={onChangeFile('emissionReductionCalculation')}
        value={value.documents.emissionReductionCalculation}
      />
      <UploadDocument
        label="Monitoring protocols"
        onChange={onChangeFile('monitoringProtocols')}
        value={value.documents.monitoringProtocols}
      />
    </div>
  );
};
