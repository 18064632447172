const UserType: Record<string, string> = {
  CORPORATE_ADMIN: 'CORPORATE_ADMIN',
  CORPORATE_TRADER: 'CORPORATE_TRADER',
  CORPORATE_MONITOR: 'CORPORATE_MONITOR',
  CORPORATE_CLIENT_DMA: 'CORPORATE_CLIENT_DMA',
  CORPORATE_CLIENT_READ_ONLY: 'CORPORATE_CLIENT_READ_ONLY',
  MEMBER_ADMIN: 'MEMBER_ADMIN',
  MEMBER_TRADER: 'MEMBER_TRADER',
  MEMBER_CLIENT: 'MEMBER_CLIENT',
  INDIVIDUAL: 'INDIVIDUAL',
  OFFMARKET_SELLER: 'OFFMARKET_SELLER',
  OFFMARKET_BUYER: 'OFFMARKET_BUYER',
  EXTERNAL_BROKER: 'EXTERNAL_BROKER',
} as const;

export default UserType;
