export interface TokenTypes {
  tokenTypes: TokenType[];
}

export interface TokenType {
  id: number;
  name: string;
  settlementType: number;
  ft: Ft;
  cashflowBaseAddr: string;
}

export interface Ft {
  expiryTimestamp: HexValue;
  underlyerTypeId: HexValue;
  refCcyId: HexValue;
  initMarginBips: number;
  varMarginBips: number;
  contractSize: number;
  feePerContract: HexValue;
}

export interface CurrencyTypes {
  ccyTypes: CcyType[];
}

export interface CcyType {
  id: number;
  name: string;
  unit: string;
  decimals: number;
}

export interface LedgerEntry {
  entry: Entry;
  account: string;
}

export interface Entry {
  exists: boolean;
  tokens: Token[];
  spot_sumQty: HexValue;
  ccys: Ccy[];
  spot_sumQtyMinted: HexValue;
  spot_sumQtyBurned: HexValue;
}

export interface Ccy {
  ccyTypeId: HexValue;
  name: string;
  unit: string;
  balance: HexValue;
  reserved: HexValue;
}

export interface HexValue {
  _hex: string;
}

export interface Token {
  exists: boolean;
  stId: HexValue;
  tokTypeId: HexValue;
  tokTypeName: string;
  batchId: HexValue;
  mintedQty: HexValue;
  currentQty: HexValue;
  ft_price: HexValue;
  ft_ledgerOwner: string;
  ft_lastMarkPrice: HexValue;
  ft_PL: HexValue;
}

export interface ScLedgerEntry {
  entry: ScEntry;
  account: string;
}

export interface ScEntry {
  exists: boolean;
  tokens: ScToken[];
  spotSumQty: number;
  ccys: ScCcy[];
  spotSumQtyMinted: number;
  spotSumQtyBurned: number;
}

export interface ScCcy {
  ccyTypeId: number;
  name: string;
  unit: string;
  balance: number;
  reserved: number;
}

export interface ScToken {
  exists: boolean;
  stId: number; // NOTE: this is different than an ERC20 token itself. ERC20 tokens generate a big number stId.
  tokTypeId: number;
  tokTypeName: string;
  batchId: number;
  mintedQty: number;
  currentQty: number;
}

export interface ScBatch {
  id: number;
  mintedTimestamp: number;
  tokTypeId: number;
  mintedQty: number;
  burnedQty: number;
  metaKeys: string[];
  metaValues: string[];
  originator: string;
  origCcyFee_percBips_ExFee: number;
  origTokFee: OrigTokFee;
}

export interface Batch {
  id: HexValue;
  mintedTimestamp: HexValue;
  tokTypeId: HexValue;
  mintedQty: HexValue;
  burnedQty: HexValue;
  metaKeys: string[];
  metaValues: string[];
  origTokFee: OrigTokFee;
  origCcyFee_percBips_ExFee: number;
  originator: string;
}

export interface OrigTokFee {
  fee_fixed: HexValue;
  fee_percBips: HexValue;
  fee_min: HexValue;
  fee_max: HexValue;
  ccy_perMillion: HexValue;
  ccy_mirrorFee: boolean;
}
