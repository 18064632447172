import { type ApiResponse, userAuthenticatedApi } from '../fetcher';
import type { Account, GlobalSetting } from './index';

export const fetchUserSettings = async () => {
  const userService = await userAuthenticatedApi();
  const res = userService.get('/settings');
  return res.json<
    ApiResponse<{
      accounts: Account[];
      settings: GlobalSetting[];
    }>
  >();
};
