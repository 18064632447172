import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { InformationItem } from './components/InformationItem';

type ProjectCardLayoutProps = {
  title: string;
  information: Array<{
    label: string;
    value?: string;
  }>;
  subtitle?: string;
  description?: string;
  endSection?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
};

export const ProjectCardLayout: React.FC<ProjectCardLayoutProps> = (props) => {
  const { title, subtitle, description, information, endSection, header, footer } = props;

  return (
    <StyledProjectCard>
      <StartSection>
        {header}
        <Content>
          {!!subtitle && <StyledDescriptionText>{subtitle}</StyledDescriptionText>}
          <StyledTitleText size={FontSize._2Xl} weight={FontWeight.bold}>
            {title}
          </StyledTitleText>
          {!!description && <StyledDescriptionText>{description}</StyledDescriptionText>}
        </Content>
        <Information>
          {information.map((informationItem, index) => (
            <InformationItem key={index} label={informationItem.label} value={informationItem.value} />
          ))}
        </Information>
        {footer}
      </StartSection>
      <EndSection>{endSection}</EndSection>
    </StyledProjectCard>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledProjectCard = styled.div`
  display: flex;
  border-radius: 12px;
  border: 1px solid var(${Colors.gray_200});
  background-color: var(${Colors.gray_50});
  gap: 24px;
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

const StartSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 24px;
`;

const StyledTitleText = styled(Text)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 36px;
`;

const StyledDescriptionText = styled(Text).attrs({
  size: FontSize.small,
  color: Colors.gray_500,
  weight: FontWeight.medium,
})`
  display: inline;
  white-space: pre-wrap;
  line-height: 21px;
`;

const Information = styled.div`
  display: flex;
  gap: 12px 48px;
  flex-wrap: wrap;
`;

const EndSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  flex-shrink: 0;
  align-self: stretch;
  border-inline-start: 1px solid var(${Colors.gray_200});
  @media (max-width: 1367px) {
    width: 320px;
  }

  @media (max-width: 820px) {
    width: 100%;
    border-inline-start: 0px;
    border-block-start: 1px solid var(${Colors.gray_200});
  }
`;
