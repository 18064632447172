export const SettleStatus = {
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
  VALIDATED: 'VALIDATED',
  SETTLED: 'SETTLED',
  RESERVED: 'RESERVED',
  EXPIRED: 'EXPIRED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
  PROCESSING: 'PROCESSING',
} as const;

export function isValidSettleStatus(value: any): value is keyof typeof SettleStatus {
  return Object.values(SettleStatus).includes(value);
}
