import { useHistory } from 'react-router-dom';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Button } from './Button';
import { Icon, IconType } from './Icon';
import { Text } from './Text';

type Props = {
  title?: string;
  description?: string;
  buttonLabel?: string;
  buttonLink?: string;
  buttonCallback?: () => void;
};

const EmptyRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: var(${Spacing.xl});

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    max-width: 40rem;
    gap: var(${Spacing.small});
  }

  .icon {
    position: relative;
    width: 66px;
    height: 66px;
    background: var(${Colors.summaryIconsBackground});
    border-radius: 66px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    color: var(${Colors.summaryIconsIcons});
  }
  .button {
    margin-top: var(${Spacing.small});
  }
`;

export const Empty: React.FC<Props> = ({ title, description, buttonLabel, buttonLink, buttonCallback }) => {
  const history = useHistory();
  const onCallback = () => {
    if (buttonLink) {
      history.push(buttonLink || '/');
    } else if (buttonCallback) {
      buttonCallback();
    }
  };

  return (
    <EmptyRoot>
      <div className="wrapper">
        <div className="icon">
          <Icon width={45} height={30} type={IconType.Empty} />
        </div>
        {title && (
          <Text size={FontSize.large} weight={FontWeight.bold} align="center">
            {title}
          </Text>
        )}
        {description && (
          <Text size={FontSize.base} align="center">
            {description}
          </Text>
        )}
        {buttonLabel && (
          <div className="button">
            <Button config={{ color: 'outlined' }} type="button" onClick={onCallback}>
              {buttonLabel}
            </Button>
          </div>
        )}
      </div>
    </EmptyRoot>
  );
};
