export const findLastCounterOffers = (props: {
  counterOffers: Array<{
    id: number;
    price: number;
    quantity: number;
    direction?: 'sent' | 'received';
  }>;
}) => {
  const { counterOffers } = props;

  if (counterOffers.length === 1) {
    return {
      receivedCounterOffersCount: 1,
      lastCounterOffer: null,
      lastCounterOfferFromOwner: counterOffers[0],
      isLastFromOwner: true,
    };
  }

  const lastCounterOfferIndex = counterOffers.findIndex((counterOffer) => counterOffer.direction === 'received');
  const lastCounterOfferFromOwnerIndex = counterOffers.findIndex((counterOffer) => counterOffer.direction === 'sent');

  const isLastFromOwner = lastCounterOfferFromOwnerIndex < lastCounterOfferIndex;
  const receivedCounterOffersCount = counterOffers.filter(
    (counterOffer) => counterOffer.direction === 'received',
  ).length;
  return {
    receivedCounterOffersCount,
    lastCounterOffer: lastCounterOfferIndex !== -1 ? counterOffers[lastCounterOfferIndex] : null,
    lastCounterOfferFromOwner:
      lastCounterOfferFromOwnerIndex !== -1 ? counterOffers[lastCounterOfferFromOwnerIndex] : null,
    isLastFromOwner,
  };
};
