import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { CreateNewProjectModal } from 'refreshed-pages/market-board-v2/components/CreateNewProjectModal';

import { CMBOrderType } from '@aircarbon/ui';
import { AssetCategory, assetCategoryUom, Const } from '@aircarbon/utils-common';

import { useEntity } from 'state/entity';
import { UI } from 'state/ui';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { Project } from '../../../../utils/Project';
import { SelectProjectAndVintage, SelectProjectAndVintageRef } from '../../../SelectProjectAndVintage';
import { CommonFormFields, CommonFormFieldsRef, CommonFormFieldsValue } from '../CommonFormFields';
import { StyledSectionCard } from '../StyledSectionCard';

export interface OfferFormValue extends CommonFormFieldsValue {
  project?: Project;
  vintage: string;
}

export interface OfferFormRef {
  /**
   * Validates form;
   * Returns `true` if validation failed
   */
  validate(): boolean;
}

export interface OfferFormProps {
  value: OfferFormValue;
  ownedByUserId: number;
  isEditing?: boolean;
  isInstantTradeHidden?: boolean;
  onChange(value: OfferFormValue): void;
}

export const OfferForm = forwardRef<OfferFormRef, OfferFormProps>((props, ref) => {
  const { onChange, value, ownedByUserId, isEditing, isInstantTradeHidden } = props;
  const [isCreateNewProjectModalVisible, setIsCreateNewProjectModalVisible] = useState(false);
  const { getSetting } = UI.useContainer();

  const isMarketBoardAndAuctionsV2SettlementModeEnabled =
    getSetting(Const.FeatureToggle.MarketBoardAndAuctionsV2SettlementMode) === '1';

  const commonFormFieldsRef = useRef<CommonFormFieldsRef>(null);
  const selectProjectAndVintageRef = useRef<SelectProjectAndVintageRef>(null);
  const { product } = useMarketplaceProduct();
  const uom = assetCategoryUom[Number(product) as AssetCategory];
  const { selector } = useEntity();

  const currencyCode = selector.mainCcySymbol;

  useImperativeHandle(ref, () => ({
    validate() {
      const hasCommonFormFieldsErrors = commonFormFieldsRef.current?.validate();
      const hasSelectProjectAndVintageErrors = selectProjectAndVintageRef.current?.validate();

      return !!(hasCommonFormFieldsErrors || hasSelectProjectAndVintageErrors);
    },
  }));

  const onChangeFormValue = (newValue: Partial<OfferFormValue>) => {
    onChange({
      ...value,
      ...newValue,
    });
  };

  const onPressCreateNewProject = () => {
    setIsCreateNewProjectModalVisible(true);
  };

  const onCloseCreateNewProjectModal = () => {
    setIsCreateNewProjectModalVisible(false);
  };

  const onNewProjectCreated = (newProject: {
    id: string;
    name: string;
    description: string;
    registryProjectId: string;
  }) => {
    onChangeFormValue({
      project: {
        title: newProject.name,
        description: newProject.description,
        value: newProject.id,
        registryProjectId: newProject.registryProjectId,
      },
    });
    setIsCreateNewProjectModalVisible(false);
  };

  return (
    <>
      <CreateNewProjectModal
        isVisible={isCreateNewProjectModalVisible}
        onClose={onCloseCreateNewProjectModal}
        onProjectCreated={onNewProjectCreated}
      />
      <StyledSectionCard>
        <SelectProjectAndVintage
          ref={selectProjectAndVintageRef}
          ownedByUserId={ownedByUserId}
          project={value.project}
          vintage={value.vintage}
          onChange={onChangeFormValue}
          onPressCreateNewProject={onPressCreateNewProject}
        />
      </StyledSectionCard>

      <CommonFormFields
        isEditing={isEditing}
        orderType={CMBOrderType.Offer}
        ref={commonFormFieldsRef}
        minimumQuantityLabel="Minimum Bid Qty"
        minimumQuantityTooltip="Minimum Bid Qty is pre-set as per company policy"
        maximumQuantityLabel="Maximum Bid Qty"
        quantityMultiplesOfLabel="Bid Qty in Multiples of"
        quantityMultiplesOfTooltip="Bid Qty in Multiples of is pre-set as per company policy"
        quantityUnit={uom}
        balance={value.project?.vintageBalance}
        balancePerAsset={value.project?.vintageBalancePerAsset}
        isInstantTradeEnabled={value.project?.vintageBalance! > 0}
        isInstantTradeHidden={isMarketBoardAndAuctionsV2SettlementModeEnabled || isInstantTradeHidden}
        currency={currencyCode}
        value={value}
        onChange={onChangeFormValue}
        isMaximumQuantityReadOnly
        isMinimumQuantityReadOnly
        isQuantityMultiplesOfReadOnly
      />
    </>
  );
});
