import {
  BorderColor,
  Button,
  ButtonVariant,
  LabelWithDescription,
  Layer,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
} from '@aircarbon/ui';

export interface ActionsBarProps {
  label: string;
  description: string;
  buttonLabel?: string;
  onPress?: () => void;
  isButtonDisabled?: boolean;
  secondaryButtonLabel?: string;
  secondaryOnPress?: () => void;
}

export const ActionsBar = (props: ActionsBarProps) => {
  const {
    label,
    description,
    buttonLabel,
    onPress,
    secondaryButtonLabel,
    secondaryOnPress,
    isButtonDisabled = false,
  } = props;
  return (
    <Layer>
      <StyledActionsBar>
        <LabelWithDescription isExpanded label={label} description={description} />
        <ButtonContainer>
          {secondaryButtonLabel && secondaryOnPress && (
            <Button onPress={secondaryOnPress} isDisabled={isButtonDisabled} variant={ButtonVariant.outlined}>
              {secondaryButtonLabel}
            </Button>
          )}
          {buttonLabel && onPress && (
            <Button onPress={onPress} isDisabled={isButtonDisabled}>
              {buttonLabel}
            </Button>
          )}
        </ButtonContainer>
      </StyledActionsBar>
    </Layer>
  );
};

const StyledActionsBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => toSpacing(theme)(8)};
  background: ${({ theme }) => toLayerBackground(theme)('layer')};
  padding: ${({ theme }) => `${toSpacing(theme)(8)} ${toSpacing(theme)(32)}`};
  border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;
