// eslint-disable-next-line no-shadow
export enum InvoiceStatus {
  Done = 'DONE',
}

// eslint-disable-next-line no-shadow
export enum InvoiceType {
  Carbon = 'CARBON',
  Bilateral = 'BILATERAL',
}

// eslint-disable-next-line no-shadow
export enum InvoiceLineType {
  FiatDepositFee = 'FIAT_DEPOSIT_FEE',
  FiatWithdrawalFee = 'FIAT_WITHDRAWAL_FEE',
  SpotTradeSellerFee = 'SPOT_TRADE_SELLER_FEE',
  SpotTradeBuyerFee = 'SPOT_TRADE_BUYER_FEE',
  CarbonFinderSellerFee = 'CARBON_FINDER_SELLER_FEE',
  CarbonFinderBuyerFee = 'CARBON_FINDER_BUYER_FEE',
  AuctionSellerFee = 'AUCTION_SELLER_FEE',
  AuctionBuyerFee = 'AUCTION_BUYER_FEE',
  EmbSellerFee = 'EMB_SELLER_FEE',
  EmbBuyerFee = 'EMB_BUYER_FEE',
  BlockTradeSellerFee = 'BLOCK_TRADE_SELLER_FEE',
  BlockTradeBuyerFee = 'BLOCK_TRADE_BUYER_FEE',
  BrokerPortalSellerFee = 'BROKER_PORTAL_SELLER_FEE',
  BrokerPortalBuyerFee = 'BROKER_PORTAL_BUYER_FEE',
  TokenRetireFee = 'TOKEN_RETIRE_FEE',
  TokenDeliveryFee = 'TOKEN_DELIVERY_FEE',
  OnboardingFee = 'ONBOARDING_FEE',
  MinBalanceFee = 'MIN_BALANCE_FEE',
  NoMovementFee = 'NO_MOVEMENT_FEE',
}
