import { useState } from 'react';
import { queryCache, useQuery } from 'react-query';

import { fetchOrderBook } from 'data-provider/oms/fetchOrderBook';

// TODO: Implement pagination
export function useOrderBook(pairId: number | undefined) {
  const [fetchCount, setFetchCount] = useState(0);
  const { data, isLoading, error } = useQuery(['oms-order-book', pairId], () => fetchOrderBook(pairId), {
    enabled: pairId,
  });

  const refetchOrderBook = () => {
    setFetchCount(fetchCount + 1);
    queryCache.refetchQueries(['oms-order-book', pairId]);
  };

  const refreshOrderBookFromMQ = (payload: any, orderBookPairId?: number) => {
    setFetchCount(fetchCount + 1);
    queryCache.setQueryData(['oms-order-book', orderBookPairId], payload);
  };

  return {
    error,
    isLoadingFirstTime: isLoading && fetchCount === 0,
    isLoading,
    refetchOrderBook,
    refreshOrderBookFromMQ: (payload: any, orderBookPairId?: number) =>
      refreshOrderBookFromMQ(payload, orderBookPairId),
    orderBook: data,
  };
}
