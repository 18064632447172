interface PackGERProps {
  quantity: number;
  currentBalance: number;
  scalingRatio?: number;
  assetsAllocations: Array<{
    name: string;
    percentage: number;
    availableBalance: number;
  }>;
}

export const packOrRetireGER = (
  props: PackGERProps,
): {
  resultingBalance: number;
  assetBalances: Array<{
    name: string;
    percentage: number;
    availableBalance: number;
    balanceChange: number;
    resultingBalance: number;
  }>;
} => {
  if (props.quantity <= 0) {
    return {
      resultingBalance: props.currentBalance,
      assetBalances: props.assetsAllocations.map((asset) => {
        return {
          name: asset.name,
          availableBalance: (asset.availableBalance || 0) / (props.scalingRatio || 1),
          percentage: asset.percentage,
          balanceChange: 0,
          resultingBalance: (asset.availableBalance || 0) / (props.scalingRatio || 1),
        };
      }),
    };
  }

  return {
    resultingBalance: props.currentBalance + props.quantity,
    assetBalances: props.assetsAllocations.map((asset) => {
      const balanceChange = -(props.quantity * (asset.percentage / 100));
      return {
        name: asset.name,
        availableBalance: (asset.availableBalance || 0) / (props.scalingRatio || 1),
        percentage: asset.percentage,
        balanceChange: balanceChange,
        resultingBalance: (asset.availableBalance || 0) / (props.scalingRatio || 1) + balanceChange,
      };
    }),
  };
};
