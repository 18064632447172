import { TabItem, Tabs, TabsSize, TabsVariant } from '@aircarbon/ui';

import { Filters } from '../hooks/useFilters';

export const SettlementStatusTabs = () => {
  const { statusFilter, setStatus } = Filters.useContainer();

  const onPressStatusTab = (tab: TabItem) => {
    setStatus(tab.id as 'active' | 'history');
  };

  return (
    <Tabs
      activeItemId={statusFilter}
      variant={TabsVariant.Contained}
      size={TabsSize.m}
      items={[
        {
          label: 'Active',
          id: 'active',
        },
        {
          label: 'History',
          id: 'history',
        },
      ]}
      onPressTab={onPressStatusTab}
    />
  );
};
