import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'refreshed-component/atoms/Button';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import Modal, { ModalContent } from 'refreshed-component/molecules/Modal';
import { toast } from 'refreshed-component/molecules/toast';
import * as yup from 'yup';

import { AssetCategory } from '@aircarbon/utils-common';

import type { CarbonProject } from 'pages/account/carbon/types';

import { User } from 'state/user';

import useCarbonMetaOptions from 'hooks/useCarbonMetaOptions';
import useCarbonProjects from 'hooks/useCarbonProjects';

const schema = yup.object().shape({
  projectName: yup.string().required('Required'),
  registryId: yup.string().required('Required'),
  registryProjectId: yup.string().required('Required'),
});

export type ProjectFormData = {
  carbonProjectId: number;
  registryProjectUrl: string;
  registryId: number;
  registryProjectId: string;
  projectName: string;
};

type Props = {
  assetCategoryId: AssetCategory;
  onChange?: (option?: CarbonProject) => void;
};

export const AddCarbonProject = ({ onChange, assetCategoryId }: Props) => {
  const {
    selector: { getAuthToken },
  } = User.useContainer();

  const {
    carbonMetaOptions: { registries },
  } = useCarbonMetaOptions({
    query: {
      assetCategory: AssetCategory[assetCategoryId],
    },
  });
  const [isSaving, setIsSaving] = useState(false);
  const [selectedProject, setSelectedProject] = useState<CarbonProject | null>(null);
  const [searchProjectId, setSearchProjectId] = useState<string | null>(null);

  const formProperties = useForm<ProjectFormData>({
    resolver: yupResolver(schema),
  });

  const {
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
    trigger,
  } = formProperties;

  const { registryId, registryProjectId } = watch();

  const { projects, isLoading } = useCarbonProjects({
    isFilterLike: false,
    filterBy: { registryProjectId, registryId, status: 'APPROVED' },
    options: { enabled: !!registryId && !!registryProjectId },
  });

  const registryOptions =
    registries?.map((registry) => ({
      label: registry.registryName,
      value: Number(registry.id),
    })) || [];

  const onSubmit = useCallback(
    async ({ registryId, registryProjectId, projectName }: ProjectFormData) => {
      setIsSaving(true);
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      const response = await fetch(`/api/user/carbon/project`, {
        method: 'POST',
        body: JSON.stringify({
          registryId: Number(registryId),
          registryProjectId,
          projectName,
          assetCategory: AssetCategory[assetCategoryId],
        }),
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        const result = await response.json();
        toast.success('Project added successfully');
        setSelectedProject(result?.carbonProject);
        if (onChange) onChange(result?.carbonProject);
        reset();
      } else {
        const result = await response.json();
        toast.error(result.message || response.statusText);
      }
      setIsSaving(false);
    },
    [onChange, reset],
  );

  const canSearchProject = registryId && registryProjectId;

  const removeSelectedProject = () => {
    onChange?.();
    setSelectedProject(null);
    reset();
  };

  const trySubmit = async () => {
    const isValid = await trigger();
    if (isValid === true) {
      onSubmit(getValues());
    }
  };

  return (
    <>
      {!!selectedProject ? (
        <div className="flex flex-row w-full border bg-gray_50 border-gray_200 rounded-large">
          <div className="flex flex-col flex-1 p-base">
            <Text color={Colors.gray_500} size={FontSize.small}>
              {selectedProject?.__registry__?.registryName} | Project ID: {selectedProject?.registryProjectId}
            </Text>
            <Text color={Colors.gray_900} size={FontSize.base} weight={FontWeight.bold}>
              {selectedProject?.name}
            </Text>
          </div>
          <div
            onClick={() => removeSelectedProject()}
            className="flex flex-row justify-center items-center border-l cursor-pointer text-red_700 border-gray_200 p-large"
          >
            <Icon width={16} height={16} type={IconType.MinusCircle} />
          </div>
        </div>
      ) : (
        <Modal
          title="Add a Project"
          description="Let us try to locate your project on our directory."
          action={
            <Button
              type="button"
              config={{
                color: 'outlined',
                size: 'base',
              }}
            >
              <Icon width={16} height={16} type={IconType.PlusCircle} />
              Add Project
            </Button>
          }
        >
          {() => {
            return (
              <form
                className="contents"
                onSubmit={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  trySubmit();
                }}
              >
                <ModalContent>
                  <div className="flex flex-col gap-base">
                    <div className="flex flex-col sm:flex-row gap-base">
                      <div className="flex overflow-hidden flex-col flex-1 gap-xs">
                        <Text size={FontSize.small}>Registry*</Text>
                        <Dropdown
                          list={registryOptions.map(({ value, label }) => ({ id: value, label }))}
                          selected={[registryId]}
                          onSelectItem={(list) => {
                            setValue('registryId', (list?.id as any) || undefined, {
                              shouldValidate: true,
                            });
                          }}
                          config={{
                            color: 'gray',
                            size: 'base',
                          }}
                          placeholder="Select account"
                        />
                        {errors.registryId?.message && (
                          <Text size={FontSize.small} color={Colors.danger_700}>
                            {errors.registryId?.message}
                          </Text>
                        )}
                      </div>
                      <div className="flex flex-col flex-1 gap-xs">
                        <Text size={FontSize.small}>Registry Project ID*</Text>
                        <Input
                          config={{
                            color: 'gray',
                            size: 'base',
                          }}
                          value={getValues('registryProjectId')}
                          onChange={(event) => {
                            setValue('registryProjectId', (event?.target.value as any) || undefined, {
                              shouldValidate: true,
                            });
                            if (searchProjectId) setSearchProjectId(null);
                          }}
                        />
                        {errors.registryProjectId?.message && (
                          <Text size={FontSize.small} color={Colors.danger_700}>
                            {errors.registryProjectId.message}
                          </Text>
                        )}
                      </div>
                    </div>
                    <Button
                      type="button"
                      onClick={() => setSearchProjectId(registryProjectId)}
                      disabled={!canSearchProject}
                      className="flex-1"
                    >
                      Search
                    </Button>

                    {registryId && registryProjectId && !isLoading && projects && (
                      <>
                        {projects && projects?.length > 0 && (
                          <>
                            <Text weight={FontWeight.semibold}>Please select the project:</Text>
                            {projects?.map((project) => (
                              <div
                                key={`option-${project.id}`}
                                onClick={() => {
                                  if (onChange) onChange(project);
                                  setSelectedProject(project);
                                  setValue('carbonProjectId', project.id);
                                  setValue('projectName', project.name);
                                  setValue('registryId', project.registryId);
                                  setValue('registryProjectId', project.registryProjectId);
                                  setValue('registryProjectUrl', project.registryProjectUrl);
                                }}
                                className="flex flex-row w-full border cursor-pointer bg-gray_50 border-gray_200 rounded-large"
                              >
                                <div className="flex flex-col flex-1 p-base">
                                  <Text color={Colors.gray_500} size={FontSize.small}>
                                    {project?.__registry__?.registryName} | Project ID: {project?.registryProjectId}
                                  </Text>
                                  <Text color={Colors.gray_900} size={FontSize.base} weight={FontWeight.bold}>
                                    {project?.name}
                                  </Text>
                                </div>
                                <div
                                  onClick={() => removeSelectedProject()}
                                  className="flex flex-row justify-center items-center border-l cursor-pointer text-gray_900 border-gray_200 p-large"
                                >
                                  <Icon width={16} height={16} type={IconType.PlusCircle} />
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                        {projects && projects?.length === 0 && (
                          <>
                            <div className="bg-gray_100 p-small rounded-medium">
                              <Text size={FontSize.small}>
                                We could not find your project. Please fill out the following information to add the
                                project.
                              </Text>
                            </div>
                            <div className="flex flex-col flex-1 gap-xs">
                              <Text size={FontSize.small}>Project Name*</Text>
                              <Input
                                name="projectName"
                                config={{
                                  color: 'gray',
                                  size: 'base',
                                }}
                                value={getValues('projectName')}
                                onChange={(event) => {
                                  setValue('projectName', (event?.target.value as any) || undefined, {
                                    shouldValidate: true,
                                  });
                                }}
                              />
                              {errors.projectName?.message && (
                                <Text size={FontSize.small} color={Colors.danger_700}>
                                  {errors.projectName.message}
                                </Text>
                              )}
                            </div>
                            <div className="flex flex-row items-center w-full">
                              <Button type="submit" className="flex-1" disabled={isSaving} isLoading={isSaving}>
                                Add Project
                              </Button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </ModalContent>
              </form>
            );
          }}
        </Modal>
      )}
    </>
  );
};
