import { BadgeSettleOrderSide } from 'refreshed-pages/settlement/components/organism/BadgeSettleOrderSide';
import { BadgeSettleOrderStatus } from 'refreshed-pages/settlement/components/organism/BadgeSettleOrderStatus';
import type { SettleOrderSide } from 'refreshed-pages/settlement/utils/SettleOrderSide';

import { Card, LabelWithDescription, styled, Text, toSpacing, TypographyVariant } from '@aircarbon/ui';

export type DetailsProps = {
  settleId: string;
  settlementDate?: string;
  createdOn?: string;
  createdBy?: string;
  vintage?: string;
  projectName?: string;
  projectId?: string;
  contract?: string;
  country?: string;
  technology?: string;
  registry?: string;
  productType?: string;
  status?: string;
  side?: SettleOrderSide;
};

export const Details = ({
  settleId,
  settlementDate,
  createdOn,
  vintage,
  projectName,
  projectId,
  contract,
  country,
  technology,
  registry,
  productType,
  status,
  side,
}: DetailsProps) => {
  return (
    <StyledCard>
      <Badges>
        <BadgeSettleOrderSide side={side || ''} id={settleId} />
        <BadgeSettleOrderStatus status={status || ''} />
      </Badges>
      <Text variant={TypographyVariant.h6Title}>{projectName}</Text>
      <MetaContainer>
        {productType === 'rec' && <LabelWithDescription isExpanded label="Registry" description={registry ?? '-'} />}
        {productType === 'token' && (
          <LabelWithDescription isExpanded label="Registry Project ID" description={projectId ?? '-'} />
        )}
        <LabelWithDescription isExpanded label="Vintage" description={vintage ?? '-'} />
        <LabelWithDescription isExpanded label="Settlement Date" description={settlementDate ?? '-'} />
        {productType === 'rec' && (
          <LabelWithDescription isExpanded label="Technology" description={technology ?? '-'} />
        )}
        {productType === 'rec' && <LabelWithDescription isExpanded label="Country" description={country ?? '-'} />}
        {productType === 'token' && <LabelWithDescription isExpanded label="Contract" description={contract ?? '-'} />}
        <LabelWithDescription isExpanded label="Created on" description={createdOn ?? '-'} />
      </MetaContainer>
    </StyledCard>
  );
};

const Badges = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(4)};
`;

const StyledCard = styled(Card)`
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => toSpacing(theme)(12)};
  gap: ${({ theme }) => toSpacing(theme)(8)};
  container-type: inline-size;
`;

const MetaContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => toSpacing(theme)(12)};

  @container (min-width: 380px) {
    grid-template-columns: 1fr 1fr;
  }

  @container (min-width: 500px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
