import { Button } from 'refreshed-component/atoms/Button';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import Modal, { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import styled from 'styled-components';

import { PackUnpackGERForm } from '../PackUnpackGERForm';
import { useGERUnpacker } from './hooks/useGERUnpacker';

export const UnpackGER: React.FC<{
  isVisible: boolean;
  onClose(): void;
}> = (props) => {
  const { isVisible, onClose } = props;
  const {
    quantity,
    assets,
    resultingBalance,
    isUnpackingDisabled,
    validationErrorsMap,
    isUnpackingInProgress,
    resetUserInput,
    changeQuantity,
    unpack,
  } = useGERUnpacker({
    onUnpackingFinished() {
      resetUserInput();
      onClose();
    },
  });

  const onCloseModal = () => {
    if (isUnpackingInProgress) {
      return;
    }
    resetUserInput();
    onClose();
  };

  const onPressConfirm = () => {
    unpack();
  };

  return (
    <Modal isOpen={isVisible} title="Unpack GER" onClose={onCloseModal}>
      {() => {
        return (
          <>
            <ModalContent>
              <PackUnpackGERForm
                quantity={quantity}
                assets={assets}
                resultingBalance={resultingBalance}
                onChangeQuantity={changeQuantity}
                balanceTitle="GER Balance After Unpacking"
                errors={validationErrorsMap}
              />
            </ModalContent>
            <ModalFooter>
              <StyledButton
                config={{
                  color: 'secondary',
                  icon: {
                    right: <Icon type={IconType.ArrowRight} width={20} />,
                  },
                }}
                disabled={isUnpackingDisabled}
                isLoading={isUnpackingInProgress}
                onClick={onPressConfirm}
              >
                Confirm
              </StyledButton>
            </ModalFooter>
          </>
        );
      }}
    </Modal>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
  & .children {
    flex: 0 1 auto;
  }
`;
