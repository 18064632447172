export default (
  <svg viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.58281 0.546875C3.60151 0.546875 2.6604 0.936695 1.96652 1.63058C1.27263 2.32446 0.882813 3.26557 0.882812 4.24688V22.7469C0.882813 23.7282 1.27263 24.6693 1.96652 25.3632C2.6604 26.0571 3.60151 26.4469 4.58281 26.4469H23.0828C24.0641 26.4469 25.0052 26.0571 25.6991 25.3632C26.393 24.6693 26.7828 23.7282 26.7828 22.7469V4.24688C26.7828 3.26557 26.393 2.32446 25.6991 1.63058C25.0052 0.936695 24.0641 0.546875 23.0828 0.546875H4.58281ZM21.2328 7.94688C21.2328 7.45623 21.0379 6.98567 20.691 6.63873C20.344 6.29179 19.8735 6.09688 19.3828 6.09688C18.8922 6.09688 18.4216 6.29179 18.0747 6.63873C17.7277 6.98567 17.5328 7.45623 17.5328 7.94688V19.0469C17.5328 19.5375 17.7277 20.0081 18.0747 20.355C18.4216 20.702 18.8922 20.8969 19.3828 20.8969C19.8735 20.8969 20.344 20.702 20.691 20.355C21.0379 20.0081 21.2328 19.5375 21.2328 19.0469V7.94688ZM15.6828 11.6469C15.6828 11.1562 15.4879 10.6857 15.141 10.3387C14.794 9.99179 14.3235 9.79688 13.8328 9.79688C13.3422 9.79688 12.8716 9.99179 12.5247 10.3387C12.1777 10.6857 11.9828 11.1562 11.9828 11.6469V19.0469C11.9828 19.5375 12.1777 20.0081 12.5247 20.355C12.8716 20.702 13.3422 20.8969 13.8328 20.8969C14.3235 20.8969 14.794 20.702 15.141 20.355C15.4879 20.0081 15.6828 19.5375 15.6828 19.0469V11.6469ZM10.1328 17.1969C10.1328 16.7062 9.9379 16.2357 9.59096 15.8887C9.24402 15.5418 8.77346 15.3469 8.28281 15.3469C7.79216 15.3469 7.32161 15.5418 6.97467 15.8887C6.62772 16.2357 6.43281 16.7062 6.43281 17.1969V19.0469C6.43281 19.5375 6.62772 20.0081 6.97467 20.355C7.32161 20.702 7.79216 20.8969 8.28281 20.8969C8.77346 20.8969 9.24402 20.702 9.59096 20.355C9.9379 20.0081 10.1328 19.5375 10.1328 19.0469V17.1969Z"
      fill="#007495"
    />
  </svg>
);
