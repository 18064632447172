import { Colors } from 'refreshed-component/design-system';

export const ProjectCardLoader = () => (
  <svg viewBox="0 0 1094 315" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="1093" height="313.496" rx="16.5" fill={`var(${Colors.gray_50})`} />
    <rect x="25" y="25" width="73" height="24" rx="12" fill={'url("#fill")'} />
    <rect x="25" y="64.25" width="297" height="14" rx="7" fill={'url("#fill")'} />
    <rect x="25" y="102.25" width="601" height="24" rx="12" fill={'url("#fill")'} />
    <rect x="25" y="150.25" width="646" height="14" rx="7" fill={'url("#fill")'} />
    <rect x="25" y="172.25" width="537" height="14" rx="7" fill={'url("#fill")'} />
    <g opacity="0.7">
      <rect x="25" y="224.5" width="48" height="14" rx="7" fill={'url("#fill")'} />
    </g>
    <rect x="25" y="246.5" width="83" height="20" rx="10" fill={'url("#fill")'} />
    <g opacity="0.7">
      <rect x="25" y="224.5" width="48" height="14" rx="7" fill={'url("#fill")'} />
    </g>
    <rect x="25" y="246.5" width="83" height="20" rx="10" fill={'url("#fill")'} />
    <g opacity="0.7">
      <rect x="156" y="224.5" width="48" height="14" rx="7" fill={'url("#fill")'} />
    </g>
    <rect x="156" y="246.5" width="83" height="20" rx="10" fill={'url("#fill")'} />
    <g opacity="0.7">
      <rect x="156" y="224.5" width="48" height="14" rx="7" fill={'url("#fill")'} />
    </g>
    <rect x="156" y="246.5" width="83" height="20" rx="10" fill={'url("#fill")'} />
    <g opacity="0.7">
      <rect x="287" y="224.5" width="48" height="14" rx="7" fill={'url("#fill")'} />
    </g>
    <rect x="287" y="246.5" width="83" height="20" rx="10" fill={'url("#fill")'} />
    <g opacity="0.7">
      <rect x="287" y="224.5" width="48" height="14" rx="7" fill={'url("#fill")'} />
    </g>
    <rect x="287" y="246.5" width="83" height="20" rx="10" fill={'url("#fill")'} />
    <path d="M695 1V313.496" stroke={`var(${Colors.gray_200})`} />
    <rect x="719" y="22.5" width="48" height="14" rx="7" fill={'url("#fill")'} />
    <rect x="719" y="44.5" width="124" height="24" rx="12" fill={'url("#fill")'} />
    <path d="M695 90.75L1093 90.0019" stroke={`var(${Colors.gray_100})`} />
    <rect width="398" height="142" transform="translate(695 90.75)" fill={`var(${Colors.gray_100})`} />
    <rect x="1089" y="101" width="4" height="32" rx="2" fill={'url("#fill")'} />
    <g>
      <rect x="719" y="106.75" width="48" height="14" rx="7" fill={'url("#fill")'} />
      <rect x="1021" y="106.75" width="48" height="14" rx="7" fill={'url("#fill")'} />
      <rect x="719" y="136.75" width="48" height="14" rx="7" fill={'url("#fill")'} />
      <rect x="1021" y="136.75" width="48" height="14" rx="7" fill={'url("#fill")'} />
      <rect x="719" y="166.75" width="48" height="14" rx="7" fill={'url("#fill")'} />
      <rect x="1021" y="166.75" width="48" height="14" rx="7" fill={'url("#fill")'} />
      <rect x="719" y="196.75" width="48" height="14" rx="7" fill={'url("#fill")'} />
      <rect x="1021" y="196.75" width="48" height="14" rx="7" fill={'url("#fill")'} />
    </g>
    <path d="M695 233.5L1093 232.752" stroke={`var(${Colors.gray_200})`} />
    <rect x="719" y="249.5" width="167" height="48" rx="8" fill={'url("#fill")'} />
    <rect x="902" y="249.5" width="167" height="48" rx="8" fill={'url("#fill")'} />
    <rect x="0.5" y="0.5" width="1093" height="313.496" rx="16.5" stroke={`var(${Colors.gray_200})`} />
    <defs>
      <linearGradient id="fill">
        <stop offset="0.599964" stop-color={`var(${Colors.gray_200})`} stop-opacity="1">
          <animate
            attributeName="offset"
            values="-2; -2; 1"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          ></animate>
        </stop>
        <stop offset="1.59996" stop-color={`var(${Colors.gray_300})`} stop-opacity="1">
          <animate
            attributeName="offset"
            values="-1; -1; 2"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          ></animate>
        </stop>
        <stop offset="2.59996" stop-color={`var(${Colors.gray_200})`} stop-opacity="1">
          <animate
            attributeName="offset"
            values="0; 0; 3"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          ></animate>
        </stop>
      </linearGradient>
    </defs>
  </svg>
);
