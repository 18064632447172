/**
 * Transforms Array into array of tupples
 *
 * Array<T> => Array<[T, T?]>
 */
export const toTuple = <T>(
  arrayToTransform: Array<T>,
  shouldSeparate: (currentItem: T) => boolean = () => false,
): Array<[T, T?]> => {
  const result: Array<[T, T?]> = [];
  let i = 0;

  while (i < arrayToTransform.length) {
    if (shouldSeparate(arrayToTransform[i]) || i === arrayToTransform.length - 1) {
      // If the current item should be separated or is the last item, add it as a new tuple.
      result.push([arrayToTransform[i], undefined]);
    } else {
      // Otherwise, group this item with the next item.
      result.push([arrayToTransform[i], arrayToTransform[i + 1]]);
      i++; // Skip the next item since it's already included in the current tuple.
    }
    i++;
  }

  return result;
};
