import { useState } from 'react';
import { Colors } from 'refreshed-component/design-system';

import { AccountDetails } from 'components/SelectOboAccount';

import { User } from 'state/user';

import { checkIfNonBilateral } from 'utils/checkIfTokenOrRec';

import Panel from '../components/Panel';
import OrderBook from '../panels/OrderBook';
import OrderHistory from '../panels/OrderHistory';
import PlaceOrderBiofuel from '../panels/PlaceOrderBiofuel';
import PlaceOrderCarbon from '../panels/PlaceOrderCarbon';
import Trades from '../panels/Trades';
import Watchlist from '../panels/Watchlist';
import TradingHook, { Box, List, MainFrame } from './trading.hook';

const LAYOUT = 'tablet';

const TabletLayout = ({ viewPort }: { viewPort: string }) => {
  const {
    selector: { getAccountAddress, getFullName, getUserId },
  } = User.useContainer();
  const {
    states: { orderBookView, setOrderBookView, orderBookLevel, setOrderBookLevel, assetCategory, projectId },
    info: { watchlist, tradeSettings, orderBook, trades, isLoadingFirstTime, currentPair, currentCcy },
    views: { holder, pairDropdown },
  } = TradingHook();

  const [selectedAccount, setSelectedAccount] = useState<AccountDetails>({
    account: getAccountAddress(),
    userId: getUserId(),
    fullName: getFullName(),
  });
  const isNonBilateral = checkIfNonBilateral(assetCategory);
  const layout = (
    <MainFrame backgroundColor={`var(${Colors.gray_0})`} borderColor={`var(${Colors.gray_200})`}>
      <List
        borderColor={`var(${Colors.gray_200})`}
        type="column"
        style={{
          height: '120%',
          minHeight: '100%',
        }}
      >
        <Box
          style={{
            flex: '0 0 50px',
          }}
          className={'flex flex-col'}
        >
          {pairDropdown(LAYOUT)}
        </Box>
        <Box size={2} className={'flex flex-col h-full'}>
          <List borderColor={`var(${Colors.gray_200})`} type="row">
            <Box size={2} style={{ minWidth: '280px' }}>
              <Panel
                key={viewPort}
                selected={isNonBilateral ? 'Order Book' : 'Order Matching'}
                list={[
                  {
                    title: isNonBilateral ? 'Order Book' : 'Order Matching',
                    content: (
                      <OrderBook
                        pair={currentPair}
                        isLoading={isLoadingFirstTime}
                        orderBook={orderBook ?? []}
                        layout={LAYOUT}
                        view={orderBookView}
                        onViewChanges={(view) => setOrderBookView(view)}
                        level={orderBookLevel}
                        onLevelChanges={(level) => setOrderBookLevel(level)}
                        assetCategory={assetCategory}
                      />
                    ),
                  },
                  {
                    title: 'Markets',
                    content: <Watchlist watchlist={watchlist} assetCategory={assetCategory} />,
                  },
                ]}
              />
            </Box>
            <Box
              size={1}
              style={{
                minWidth: '280px',
                backgroundColor: `var(${Colors.gray_0})`,
              }}
            >
              <Panel
                key={viewPort}
                selected="Place Order"
                contentBodyStyle={{
                  overflow: 'auto',
                }}
                list={[
                  {
                    title: 'Place Order',
                    content:
                      assetCategory === 'biofuel' ? (
                        <PlaceOrderBiofuel
                          pairs={tradeSettings?.pairs ?? []}
                          pair={currentPair?.name}
                          timeInForces={tradeSettings?.timeInForces ?? []}
                          ccyAsset={tradeSettings?.ccyAssets?.[currentCcy as any]}
                          lastTradedPrice={orderBook?.lastTradedPrice}
                          pairWatchlist={watchlist?.items?.find((item) => item.pairId === currentPair?.id)}
                        />
                      ) : (
                        <PlaceOrderCarbon
                          selectedAccount={selectedAccount}
                          setSelectedAccount={setSelectedAccount}
                          pairs={tradeSettings?.pairs ?? []}
                          pair={currentPair?.name}
                          timeInForces={tradeSettings?.timeInForces ?? []}
                          ccyAsset={tradeSettings?.ccyAssets?.[currentCcy as any]}
                          lastTradedPrice={orderBook?.lastTradedPrice}
                          pairWatchlist={watchlist?.items?.find((item) => item.pairId === currentPair?.id)}
                        />
                      ),
                  },
                ]}
              />
            </Box>
          </List>
        </Box>
        <Box size={1}>
          <List borderColor={`var(${Colors.gray_200})`} type="row">
            <Box size={4}>
              <OrderHistory
                selectedAccount={selectedAccount}
                key={viewPort}
                pair={currentPair}
                layout={LAYOUT}
                showPnL={assetCategory === 'biofuel' ? false : true}
                list={[
                  {
                    title: isNonBilateral ? 'Market Trades' : 'Confirmed Matches',
                    content: (
                      <Trades trades={trades} baseAssetId={currentPair?.baseAsset?.id} assetCategory={assetCategory} />
                    ),
                  },
                ]}
                assetCategory={assetCategory}
                projectId={projectId}
              />
            </Box>
          </List>
        </Box>
      </List>
    </MainFrame>
  );

  return holder(layout);
};

export default TabletLayout;
