import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'refreshed-component/atoms/Button';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Tooltip } from 'refreshed-component/atoms/Tooltip';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system/enums';
import Loading from 'refreshed-component/molecules/Loading';
import { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import * as yup from 'yup';

import { FeeType } from '@aircarbon/utils-common';
import { formatter } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import FormDevTool from 'components/FormDevTool';

import { Entity } from 'state/entity';
import { User } from 'state/user';

import useAccountBalance from 'hooks/useAccountBalance';
import useFee from 'hooks/useFee';

import { convertTextNumberToValue } from 'utils/helpers';

// validation schema
const schema = (max: number, ccySymbol: string, numDecimals: number, feeAmount: number) =>
  yup.object().shape({
    ccyTypeId: yup.string().required(),
    amount: yup
      .number()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;

        return convertTextNumberToValue(originalValue);
      })
      .min(feeAmount, `Amount cannot less than the withdraw fee: ${feeAmount} ${ccySymbol}.`)
      .max(max + 0.01, `Insufficient balance - Max ${ccySymbol}${formatter.formatNumber(max, numDecimals)}`)
      .required(),
  });
interface Props {
  onSubmit: (values: Record<string, any>, resetForm: () => void) => void;
}
interface FormData {
  ccyTypeId: string;
  amount: number;
}

const FiatWithdrawForm: React.FC<Props> = ({ onSubmit }: Props) => {
  const { bankAccount } = User.useContainer();
  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});
  const [saving, setSaving] = React.useState(false);
  const {
    selector: { mainCcyScId, mainCcySymbol, mainCcyNumDecimals, mainCcyCode, availableCurrencies },
  } = Entity.useContainer();
  const { availableAmount, refetchBalance } = useAccountBalance({
    getRootBalance: 'yes',
    ccyTypeId: mainCcyScId,
    ccySymbol: mainCcySymbol,
    queryConfig: {
      refetchInterval: 3000,
    },
  });

  const { feeAmount, isLoading: isLoadingFeeAmount } = useFee({
    params: {
      feeType: FeeType.FIAT_WITHDRAWAL_FEE,
    },
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      ccyTypeId: String(mainCcyScId),
    },
    resolver: yupResolver(schema(availableAmount, mainCcySymbol, mainCcyNumDecimals, feeAmount)),
  });

  watch();

  const onSubmitHandler = (data: FormData) => {
    setSaving(true);
    onSubmit(data, () => {
      reset();
      setSaving(false);
      refetchBalance();
    });
  };

  if (isLoadingMarketSettings && isLoadingFeeAmount) return <Loading />;
  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <ModalContent>
        <FormDevTool control={control} />
        <div className="flex flex-wrap gap-large">
          <div className="flex flex-col w-full rounded-md bg-gray_100 p-large">
            <div className="flex flex-col gap-xs">
              <div className="flex gap-1 items-center text-gray_500">
                <Text size={FontSize.large} color={Colors.gray_500}>
                  Available Balance ({mainCcySymbol})
                </Text>
                <Tooltip
                  text="Only cash from the main account is eligible for withdrawal. The available balance reflects the
                      current main account balance after deducting any reserved amount."
                />
              </div>
              <Text size={FontSize._2Xl} weight={FontWeight.semibold}>
                {mainCcyCode}
                {formatter.formatNumber(availableAmount || 0, 2)}
              </Text>
            </div>
          </div>
          <div className="flex flex-row w-full h-auto gap-small">
            <div>
              <div className="flex flex-col gap-xs">
                <Text size={FontSize.small}>Currency</Text>
                <Dropdown
                  key={'ccyTypeId'}
                  list={Object.entries(availableCurrencies).map(([id, label]) => {
                    return {
                      id,
                      label,
                    };
                  })}
                  selected={[getValues('ccyTypeId')]}
                  onSelectItem={(item) => {
                    setValue('ccyTypeId', item.id.toString());
                  }}
                  config={{
                    color: 'gray',
                    size: 'sm',
                  }}
                  placeholder="Select Currency"
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex flex-col gap-xs">
                <Text size={FontSize.small}>Amount *</Text>
                <Input
                  config={{
                    size: 'sm',
                    color: errors.amount ? 'error' : 'gray',
                    postfix: availableCurrencies?.[getValues('ccyTypeId')] || '',
                    validation: {
                      type: 'float',
                      numeralDecimalScale: 2,
                      numeralPositiveOnly: true,
                      numeralThousandsGroupStyle: 'thousand',
                    },
                  }}
                  onChange={(event) => {
                    const value = (event?.target as any)?.rawValue;
                    setValue('amount', value as unknown as number);
                  }}
                  value={getValues('amount')}
                />
                <Text size={FontSize.xs}>
                  Withdrawal fees will be taken directly from the requested amount. Refer to the fee schedule
                </Text>
                {errors.amount && (
                  <Text size={FontSize.small} color={Colors.danger_700}>
                    {errors.amount.message}
                  </Text>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full rounded-md bg-gray_100 p-small gap-small">
            <div className="flex flex-row gap-small">
              <div className="flex flex-col flex-1 gap-small">
                <div className="flex flex-col gap-3xs">
                  <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                    Beneficiary Name
                  </Text>
                  <Text size={FontSize.small} weight={FontWeight.semibold}>
                    {bankAccount?.accountName}
                  </Text>
                </div>
                <div className="flex flex-col gap-3xs">
                  <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                    Beneficiary Account Number
                  </Text>
                  <Text size={FontSize.small} weight={FontWeight.semibold}>
                    {bankAccount?.accountIBAN}
                  </Text>
                </div>
                <div className="flex flex-col gap-3xs">
                  <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                    Beneficiary Address
                  </Text>
                  <Text size={FontSize.small} weight={FontWeight.semibold}>
                    {bankAccount?.bankAddress}
                  </Text>
                </div>
                <div className="flex flex-col gap-3xs">
                  <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                    Contact Phone
                  </Text>
                  <Text size={FontSize.small} weight={FontWeight.semibold}>
                    {bankAccount?.contactPhone}
                  </Text>
                </div>
                <div className="flex flex-col gap-3xs">
                  <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                    Reference / Note
                  </Text>
                  <Text size={FontSize.small} weight={FontWeight.semibold}>
                    {bankAccount.referenceNote ?? '-'}
                  </Text>
                </div>
              </div>
              <div className="flex flex-col flex-1 gap-small">
                <div className="flex flex-col gap-3xs">
                  <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                    Bank Name
                  </Text>
                  <Text size={FontSize.small} weight={FontWeight.semibold}>
                    {bankAccount?.bankName}
                  </Text>
                </div>
                <div className="flex flex-col gap-3xs">
                  <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                    SWIFT BIC Code
                  </Text>
                  <Text size={FontSize.small} weight={FontWeight.semibold}>
                    {bankAccount.swiftCode}
                  </Text>
                </div>
                <div className="flex flex-col gap-3xs">
                  <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.semibold}>
                    Account Number
                  </Text>
                  <Text size={FontSize.small} weight={FontWeight.semibold}>
                    {bankAccount.accountIBAN}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          isLoading={saving}
          disabled={saving || marketSettings?.fiatEntryEnabled === 0}
          config={{
            color: 'secondary',
            size: 'sm',
          }}
          type="submit"
          className="flex-1"
        >
          Confirm
          <Icon width={14} height={14} type={IconType.ArrowRight} />
        </Button>
      </ModalFooter>
    </form>
  );
};

export default FiatWithdrawForm;
