import { TextColor, Text, styled, ToMarginProps, toMargin, toSpacing, TextAlign } from '@aircarbon/ui';

interface SummaryItemProps extends ToMarginProps {
  label: string;
  value: string;
  valueColor?: TextColor;
}

export const SummaryItem: React.FunctionComponent<SummaryItemProps> = (props) => {
  const { label, value, valueColor = TextColor.primary, ...restProps } = props;
  return (
    <StyledSummaryItem margin={restProps}>
      <Text color={TextColor.secondary}>{label}</Text>
      <Text color={valueColor} align={TextAlign.end}>
        {value}
      </Text>
    </StyledSummaryItem>
  );
};

const StyledSummaryItem = styled.div<{
  margin: ToMarginProps;
}>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${({ theme }) => toSpacing(theme)(8)};
  ${({ margin }) => toMargin(margin)};

  > *:first-child {
    flex-shrink: 0;
  }
`;
