import { ChangeEvent } from 'react';

import {
  InputText,
  InputTextSize,
  InputTextType,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toSpacing,
} from '@aircarbon/ui';

export const InputRange: React.FunctionComponent<{
  fromPlaceholder: string;
  toPlaceholder: string;
  from: number;
  to: number;
  onChange(props: { from: number; to: number }): void;
}> = (props) => {
  const { fromPlaceholder, toPlaceholder, from, to, onChange } = props;

  const onChangeFromValue = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      from: Number(e.target.value || 0),
      to,
    });
  };
  const onChangeToValue = (e: ChangeEvent<HTMLInputElement>) => {
    onChange({
      to: Number(e.target.value || 0),
      from,
    });
  };

  return (
    <StyledInputRange>
      <StyledInputText
        type={InputTextType.Number}
        size={InputTextSize.s}
        value={String(from || '')}
        placeholder={fromPlaceholder}
        onChange={onChangeFromValue}
      />
      <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
        to
      </Text>
      <StyledInputText
        type={InputTextType.Number}
        size={InputTextSize.s}
        value={String(to || '')}
        placeholder={toPlaceholder}
        onChange={onChangeToValue}
      />
    </StyledInputRange>
  );
};

const StyledInputRange = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(2)};
`;

const StyledInputText = styled(InputText)`
  input[type='number'] {
    width: 100%;
  }
`;
