import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { SummaryCard } from 'refreshed-component/molecules/SummaryCard';
import { SummaryHolder } from 'refreshed-component/molecules/SummaryHolder';

import { useOutgoingRequest } from '../hooks/outgoingTradeRequest';

export const Summery = () => {
  const allTradeRequest = useOutgoingRequest();
  const approvedTradeRequest = useOutgoingRequest({ status: 'APPROVED' });
  const rejectedTradeRequest = useOutgoingRequest({ status: 'REJECTED' });

  return (
    <SummaryHolder>
      <SummaryCard
        icon={<Icon width={29} height={27} type={IconType.Total} />}
        title="Total Requests"
        value={allTradeRequest.response?.count?.toString() ?? '-'}
      />
      <SummaryCard
        icon={<Icon width={29} height={29} type={IconType.CheckCircle} />}
        title="Approved Requests"
        value={approvedTradeRequest.response?.count?.toString() ?? '-'}
      />
      <SummaryCard
        icon={<Icon width={29} height={29} type={IconType.Ban} />}
        title="Rejected Requests"
        value={rejectedTradeRequest.response?.count?.toString() ?? '-'}
      />
    </SummaryHolder>
  );
};
