import type { UserOpenCurrencyBalances } from '@aircarbon/utils-common/src/dto';

import { userAuthenticatedApi } from '../fetcher';

export const fetchUserOpenCurrencyBalances = async (accountAddress: string) => {
  const endpoint = '/oms/account/open-currency-balances';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ accountAddress }).get();
  return res.json<UserOpenCurrencyBalances>();
};
