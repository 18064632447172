const documentKeyToDocumentIdMap: Record<string, number> = {
  projectIdeaNote: 1,
  preFeasibilityStudyDocument: 2,
  projectDesignDocument: 3,
  verificationReport: 4,
  emissionReductionCalculation: 5,
  monitoringProtocols: 6,
};

export const toDocumentTypeId = (documentKey: string) => {
  return documentKeyToDocumentIdMap[documentKey] || null;
};
