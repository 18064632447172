import { Button } from 'refreshed-component/atoms/Button';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import BiofuelClientForm from 'refreshed-component/forms/BiofuelClientForm';
import { ConfirmModal } from 'refreshed-component/molecules/ConfirmModal';
import Modal from 'refreshed-component/molecules/Modal';
import { toast } from 'refreshed-component/molecules/toast';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { Table } from 'refreshed-component/templates/Table';

import type { ExternalBrokerClient } from '@aircarbon/utils-common/src/dto/trading';

import type { BiofuelClientFormData } from 'pages/account/components/BiofuelClientForm';
import { useExternalBrokerClient } from 'pages/account/trading/hooks/useExternalBrokerClient';

import { User } from 'state/user';

export const ExternalClients = () => {
  const {
    selector: { getAuthToken },
    status: {
      canAddExternalBrokerClientForBlockTrade,
      canAddExternalBrokerClientForBiofuel,
      canUpdateExternalBrokerClientForBlockTrade,
      canUpdateExternalBrokerClientForBiofuel,
    },
  } = User.useContainer();

  const { data: clientList, refetch: refetchExternalClients } = useExternalBrokerClient();

  const deleteClientRequest = async (clientData: ExternalBrokerClient) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    const response = await fetch(`/api/user/broker/external-client/${clientData.id}`, {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });
    if (response.ok) {
      toast.success(`Client #${clientData.id} is Deleted successfully.`);
    } else {
      toast.error(`Failed to Delete client #${clientData.id}.`);
    }
    refetchExternalClients();
  };

  const editClientRequest = async (clientId: number, formData: BiofuelClientFormData) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    const response = await fetch(`/api/user/broker/external-client/${clientId}`, {
      method: 'PUT',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        address: formData.address || undefined,
        country: formData.country || undefined,
        phone: formData.phone || undefined,
        contactPersonName: formData.contactPersonName || undefined,
      }),
    });
    if (response.ok) {
      toast.success(`Client #${clientId} is edited successfully.`);
    } else {
      toast.error(`Failed to edit client #${clientId}.`);
    }
    refetchExternalClients();
  };

  const addClientRequest = async (formData: BiofuelClientFormData) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    const response = await fetch(`/api/user/broker/external-client`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        address: formData.address || undefined,
        country: formData.country || undefined,
        phone: formData.phone || undefined,
        contactPersonName: formData.contactPersonName || undefined,
      }),
    });
    if (response.ok) {
      toast.success(`Client added successfully.`);
    } else {
      toast.error('Failed to add client.');
    }
    refetchExternalClients();
  };

  return (
    <PageHolder>
      <PageSections>
        <PageControls
          title="External Clients"
          controls={{
            secondary: !!(canAddExternalBrokerClientForBlockTrade() || canAddExternalBrokerClientForBiofuel()) && (
              <Modal
                title={`Add External Client`}
                action={
                  <Button
                    config={{
                      color: 'primary',
                    }}
                  >
                    Add External Client
                    <Icon width={16} height={16} type={IconType.UserAdd} />
                  </Button>
                }
              >
                {({ onClose, onLoading }) => {
                  return (
                    <BiofuelClientForm
                      onClose={onClose}
                      onSubmit={(clientData) => {
                        onLoading(true);
                        addClientRequest(clientData)
                          .then(() => {
                            onLoading(false);
                            onClose();
                          })
                          .catch(() => {
                            onLoading(false);
                          });
                      }}
                    />
                  );
                }}
              </Modal>
            ),
          }}
        />
      </PageSections>
      <PageSections type="card" className="flex flex-col">
        <Table
          config={{
            sticky: {
              left: ['id'],
              right: ['action'],
            },
            columns: {
              id: {
                label: '#',
                minWidth: 50,
              },
              clientName: {
                label: 'CLIENT NAME',
              },
              address: {
                label: 'ADDRESS',
              },
              country: {
                label: 'COUNTRY',
              },
              email: {
                label: 'EMAIL',
              },
              phone: {
                label: 'PHONE',
              },
              contactPersonName: {
                label: 'CONTACT PERSON NAME',
              },
              action: {
                label: 'ACTION',
              },
            },
            rows:
              clientList?.map((item, index) => {
                return {
                  _key: item.id.toString(),
                  id: item.id,
                  clientName: item.name || '-',
                  address: item.address || '-',
                  country: item.country || '-',
                  email: item.email || '-',
                  phone: item.phone || '-',
                  contactPersonName: item.contactPersonName || '-',
                  action: (
                    <>
                      {(canUpdateExternalBrokerClientForBlockTrade() || canUpdateExternalBrokerClientForBiofuel()) && (
                        <div className="flex flex-row gap-base">
                          <Modal
                            title={`Edit client #${item.id}`}
                            action={
                              <Button
                                config={{
                                  color: 'outlined',
                                  size: 'sm',
                                }}
                              >
                                Edit
                                <Icon width={16} height={16} type={IconType.Pencil} />
                              </Button>
                            }
                          >
                            {({ onClose, onLoading }) => {
                              return (
                                <BiofuelClientForm
                                  edit={item}
                                  onClose={onClose}
                                  onSubmit={(clientData) => {
                                    onLoading(true);
                                    editClientRequest(item.id, clientData)
                                      .then(() => {
                                        onLoading(false);
                                        onClose();
                                      })
                                      .catch(() => {
                                        onLoading(false);
                                      });
                                  }}
                                />
                              );
                            }}
                          </Modal>
                          <ConfirmModal
                            title={`Delete client #${item.id}`}
                            action={
                              <Button
                                config={{
                                  color: 'secondary',
                                  size: 'sm',
                                }}
                              >
                                Delete
                                <Icon width={16} height={16} type={IconType.ArrowRight} />
                              </Button>
                            }
                            accept={{
                              label: 'Delete',
                              callback(props) {
                                props.onLoading(true);
                                deleteClientRequest(item)
                                  .then(() => {
                                    props.onLoading(false);
                                    props.onClose();
                                  })
                                  .catch(() => {
                                    props.onLoading(false);
                                  });
                                return false;
                              },
                            }}
                            cancel={{
                              label: 'Keep',
                            }}
                          >
                            <Text
                              align="center"
                              color={Colors.gray_500}
                              size={FontSize.base}
                              weight={FontWeight.medium}
                            >
                              Are you sure you want to{' '}
                              <Text
                                align="center"
                                color={Colors.gray_900}
                                size={FontSize.base}
                                weight={FontWeight.medium}
                              >
                                {`Delete client #${item.id}`}
                              </Text>
                              ?
                            </Text>
                          </ConfirmModal>
                        </div>
                      )}
                    </>
                  ),
                };
              }) || [],
          }}
        />
      </PageSections>
    </PageHolder>
  );
};
