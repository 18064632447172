import { type ApiMutationError, userAuthenticatedApi } from '../mutator';

type PlaceOrderResponse = {
  order: { Result: number; message: string; orderRequest_id: string; order_id: string };
};

export const mutatePlaceOrder = async (order: Record<string, any>) => {
  const userService = await userAuthenticatedApi();
  const res = userService.url('/oms/order').post(order);
  return res.json<PlaceOrderResponse | ApiMutationError>().catch((err) => err);
};
