import queryString from 'query-string';

import type { UserSpotTradeResponse } from '@aircarbon/utils-common/src/dto';

import type { AssetCategoryCode } from 'pages/account/trading/layouts/trading.hook';

import { userAuthenticatedApi } from '../fetcher';

export const fetchUserSpotTrades = async (data: {
  pairId: string;
  page: number;
  limit: number;
  accountAddress: string;
  searchByOrderId?: string;
  side?: string;
  status?: string;
  assetCategory?: AssetCategoryCode;
}) => {
  const qs = queryString.stringify(data, {
    skipNull: true,
    arrayFormat: 'bracket',
  });
  const endpoint = `/oms/trade/spot-trades?${qs}`;
  const userService = await userAuthenticatedApi();

  const res = userService.url(endpoint).get();
  return res.json<UserSpotTradeResponse>();
};
