import * as yup from 'yup';

const partialTonsError = 'The quantity cannot result in partial tons of underlyers';
const formValidationSchema = yup.object().shape({
  quantity: yup
    .number()
    .integer('The quantity cannot be in partial tons')
    .min(1000, 'The quantity should be more than or equal 1,000')
    .required(),
  assetsBalances: yup.array().of(
    yup.object().shape({
      balanceChange: yup.number().integer(partialTonsError),
      resultingBalance: yup
        .number()
        .min(0, "You don't have sufficient amount of assets to proceed")
        .integer(partialTonsError),
    }),
  ),
  resultingBalance: yup.number().integer().min(0, "You don't have sufficient GER balance"),
});

interface ToValidationErrorsMapProps {
  quantity: number;
  assetsBalances: Array<{
    balanceChange: number;
    resultingBalance: number;
  }>;
  resultingBalance?: number;
}

export const toValidationErrorsMap = (props: ToValidationErrorsMapProps) => {
  try {
    formValidationSchema.validateSync(props, {
      abortEarly: false,
    });

    return null;
  } catch (e) {
    return (e as yup.ValidationError).inner.reduce(
      (current, error) => ({
        ...current,
        [error.path as string]: error.errors[0],
      }),
      {} as Record<string, string>,
    );
  }
};
