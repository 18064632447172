import type { MouseEventHandler } from 'react';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

type ProjectDocumentProps = {
  label: string;
  name: string;
  href: string;
  onPress?: MouseEventHandler<HTMLAnchorElement>;
};

export const ProjectDocument: React.FC<ProjectDocumentProps> = (props) => {
  const { label, name, href, onPress } = props;

  return (
    <StyledProjectDocument>
      <Text size={FontSize.small} weight={FontWeight.medium} color={Colors.gray_600}>
        {label}
      </Text>
      <FileLink href={href} target="_blank" download={name} onClick={onPress}>
        <Icon width={24} height={24} type={IconType.DocumentDownload} />
        {name}
      </FileLink>
    </StyledProjectDocument>
  );
};

const StyledProjectDocument = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileLink = styled.a`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(${Colors.gray_900});
  background-color: var(${Colors.gray_50});
  margin-top: 4px;
  display: flex;
  gap: 8px;
  align-items: center;
`;
