import { Fragment } from 'react';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

export const MilestonesTable: React.FC<{
  milestones: Array<[string, string, string]>;
}> = (props) => {
  const { milestones } = props;
  return (
    <StyledMilestonesTable>
      <Row size={FontSize.xs} color={Colors.gray_500}>
        Vintage Year
      </Row>
      <Row size={FontSize.xs} color={Colors.gray_500}>
        Projected Qty (tCo2)
      </Row>
      <Row size={FontSize.xs} color={Colors.gray_500}>
        Offered Qty (tCo2)
      </Row>
      {milestones.map((milestone, index) => (
        <Fragment key={index}>
          <Row color={Colors.gray_500}>{milestone[0]}</Row>
          <Row>{milestone[1]}</Row>
          <Row>{milestone[2]}</Row>
        </Fragment>
      ))}
    </StyledMilestonesTable>
  );
};

const StyledMilestonesTable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  background: var(${Colors.gray_50});
  padding: 24px;
`;

const Row = styled(Text).attrs(({ size = FontSize.small, color = Colors.gray_900 }) => ({
  size,
  color,
  weight: FontWeight.medium,
}))<{
  size?: FontSize;
  color?: Colors;
}>`
  text-transform: uppercase;
  display: block;
  justify-self: stretch;
  text-align: center;
  &:nth-child(3n + 1),
  &:first-child {
    text-align: start;
  }
  &:nth-child(3n) {
    text-align: end;
  }
`;
