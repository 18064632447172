import type { Country } from './Country';

export const toFilteredCountries = (props: { searchValue: string; countries: Array<Country> }) => {
  const { searchValue, countries } = props;

  if (!searchValue) {
    return countries;
  }

  return countries.filter((country) => {
    return (
      country.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      country.phoneCode.toLowerCase().includes(searchValue)
    );
  });
};
