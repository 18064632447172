import { flatten } from 'lodash';

export const toSelectProjectCriteria = (
  criteria: Array<{
    key: string;
    value: string;
  }>,
) => {
  return criteria.map((item) => {
    const values = item.value.split(',');

    return {
      key: item.key,
      operator: 'IN',
      values,
    };
  });
};
