import { ReactNode } from 'react';
import { Text } from 'refreshed-component/atoms/Text';
import { Tooltip } from 'refreshed-component/atoms/Tooltip';
import { Colors, FontSize, FontWeight, Radius } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Dto } from '@aircarbon/utils-common';

const BezeroIcon = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  background: var(${Colors.gray_200});
  border-radius: var(${Radius.small});
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  color: var(${Colors.summaryIconsIcons});
`;

export interface DisclaimerUrl {
  url: string;
  title: string;
}

export const ProjectRatingBadge = ({
  projectRating,
  ratingType,
  urls,
  className,
  title,
}: {
  projectRating: string;
  ratingType: Dto.ProjectRatingType;
  urls?: DisclaimerUrl[];
  className?: string;
  title?: {
    weight?: FontWeight;
    color?: Colors;
  };
}) => {
  return (
    <div className={`flex flex-col ${className ?? ''}`}>
      <Text
        color={title?.color ?? Colors.gray_500}
        size={FontSize.small}
        weight={title?.weight ?? FontWeight.default}
        postfix={{
          view: urls?.length ? (
            <Tooltip
              text={
                <>
                  <div className="p-1">Disclaimer:</div>
                  {urls.map((url) => (
                    <div className="p-1 underline">
                      <a
                        href={`${url.url}`}
                        className="flex flex-row items-center gap-xs"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url.title}
                      </a>
                    </div>
                  ))}
                </>
              }
            />
          ) : (
            <></>
          ),
        }}
      >
        {ratingType === Dto.ProjectRatingType.BezeroCarbonRating ? 'BeZero Carbon Rating' : 'Sylvera Rating Category'}
      </Text>
      <BezeroIcon>
        <Text color={Colors.gray_900} size={FontSize.large} weight={FontWeight.semibold}>
          {projectRating}
        </Text>
      </BezeroIcon>
    </div>
  );
};
