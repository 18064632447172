import { OtcCriteria } from 'refreshed-pages/market-board-v2/utils/OtcCriteria';
import { toMetaFields } from 'refreshed-pages/market-board-v2/utils/toMetaFields';

import { FetchListingsResponse } from '../components/Listings/utils/fetchListings';

export const toOrderName = (props: { listing: FetchListingsResponse['data'][0]; otcCriteria: OtcCriteria }) => {
  const { listing, otcCriteria } = props;
  const nameParts = [listing.project?.name];

  listing.orderCriteria?.forEach((criteriaItem) => {
    const metaFields = toMetaFields(otcCriteria)(criteriaItem);

    if (!metaFields) {
      return;
    }

    nameParts.push(`${metaFields.label}: ${metaFields.value}`);
  });

  return nameParts.filter((v) => !!v).join(', ');
};
