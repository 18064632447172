import { useState } from 'react';
import { useQuery } from 'react-query';
import { createContainer } from 'unstated-next';

import { logger } from '@aircarbon/utils-common';

import { type BrandingEntity, fetchBrandingEntity } from 'data-provider/entity/fetchBrandingEntity';

const convertThemeToJson = (entity: any): BrandingEntity => {
  if (typeof entity?.theme === 'string') {
    try {
      const entityMeta = JSON.parse(entity?.theme ?? '{}');
      const configMeta = JSON.parse(entity?.config ?? '{}');
      return {
        ...entity,
        theme: entityMeta,
        config: configMeta,
      };
    } catch (error) {
      logger.error(error);
      return entity;
    }
  }
  return entity;
};

const uomPerProduct = {
  Carbon: 'tCO2',
  REC: 'MWh',
};

export function useEntity() {
  const [product, setProduct] = useState<keyof typeof uomPerProduct>('Carbon');
  const { data, isLoading, isError } = useQuery(
    ['brand-entity'],
    () => fetchBrandingEntity().then((result) => result?.data?.entity),
    {
      retry: true,
      retryDelay: 500,
      refetchOnWindowFocus: false,
    },
  );

  if (isError) throw new Error('Failed to load entity');

  const entity = convertThemeToJson(data);
  return {
    isLoading,
    entity,
    product,
    setProduct,
    selector: {
      entityName: entity?.name,
      mainCcyScId: entity?.mainCcyScId,
      mainCcyCode: entity?.mainCcyCode,
      mainCcySymbol: entity?.mainCcySymbol,
      mainCcyNumDecimals: entity?.mainCcyNumDecimals,
      availableCurrencies: {
        [entity?.mainCcyScId]: entity?.mainCcySymbol,
      } as Record<string, string>,
    },
    selectors: {
      currencyUnit: () => entity?.mainCcyCode ?? 'USD',
      currencySymbol: () => entity?.mainCcySymbol ?? '$',
      tokenUnit: () => uomPerProduct[product],
      tokenUnitByProduct: (byProduct: 'Carbon' | 'REC') => uomPerProduct[byProduct],
      isCarbon: () => product === 'Carbon',
      isREC: () => product === 'REC',
    },
  };
}

export const Entity = createContainer(useEntity);
