import { FaRegCopy } from 'react-icons/fa';
import styled from 'styled-components';

function clipboardCopy(text: string) {
  let success = false;

  if (typeof window !== 'undefined') {
    // Use the Async Clipboard API when available. Requires a secure browsing
    // context (i.e. HTTPS)
    if (navigator.clipboard) {
      return navigator.clipboard.writeText(text).catch((err) => {
        throw err !== undefined ? err : new DOMException('The request is not allowed', 'NotAllowedError');
      });
    }

    // ...Otherwise, use document.execCommand() fallback

    // Put the text to copy into a <span>
    const span = document.createElement('span');
    span.textContent = text;

    // Preserve consecutive spaces and newlines
    span.style.whiteSpace = 'pre';

    // Add the <span> to the page
    document.body.appendChild(span);

    // Make a selection object representing the range of text selected by the user
    const selection = window.getSelection();
    const range = window.document.createRange();

    if (selection) {
      selection.removeAllRanges();
      range.selectNode(span);
      selection.addRange(range);

      // Copy text to the clipboard
      try {
        success = window.document.execCommand('copy');
      } catch (err) {
        console.error(err);
      }

      // Cleanup
      selection.removeAllRanges();
      window.document.body.removeChild(span);
    }
  }

  return success
    ? Promise.resolve()
    : Promise.reject(new DOMException('The request is not allowed', 'NotAllowedError'));
}

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
  padding-right: 25px;

  svg {
    display: none;
    margin-left: 0.5em;
    cursor: pointer;
    position: absolute;
    right: 0;

    &:active {
      transform: scale(1.4);
      fill: currentColor;
      opacity: 0.5;
    }
  }

  &:hover {
    svg {
      display: block;
    }
  }
`;

export interface CopyInputProps {
  text: string;
  children?: React.ReactNode;
}

const CopyInput: React.FC<CopyInputProps> = ({ children, text }) => (
  <Wrapper>
    {children}
    <FaRegCopy onClick={() => clipboardCopy(text)} />
  </Wrapper>
);

export default CopyInput;
