import { userAuthenticatedApi } from 'data-mutation/mutator';

import type { Dto } from '@aircarbon/utils-common';

export const saveProject = async (props: { params: Dto.UpdateMyApxProjectApiParams }) => {
  try {
    const userService = await userAuthenticatedApi();
    const request = await userService.url('/user/apx/projects').put(props.params);

    const response = (await request.json()) as any;

    return {
      id: response.record.id,
      name: response.record.name,
      updatedAt: response.record.updatedAt,
    };
  } catch (e) {
    console.error(e);
    return false;
  }
};
