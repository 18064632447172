import { Select } from 'grommet';
import range from 'lodash/range';
import type * as React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { type ThemeColors, Themes } from 'pages/account/trading/components/Themes';

const Wrapper = styled.div<{ themeColors: ThemeColors }>`
  display: flex;
  margin-left: auto;
  flex-direction: column;
  min-width: 370px;
  gap: 1rem;

  .navigation {
    display: flex;
    justify-content: flex-end;
    gap: 7px;

    svg {
      stroke: ${({ themeColors }) => themeColors.dashboard.button.filter.fill};
    }
  }

  button {
    background: ${({ themeColors }) => themeColors.dashboard.button.filter.background} !important;
    color: ${({ themeColors }) => themeColors.dashboard.button.filter.color} !important;
    border: ${({ themeColors }) => themeColors.dashboard.button.filter.color} !important;

    &:disabled {
      pointer-events: none;
      opacity: 0.25;
    }

    input {
      color: ${({ themeColors }) => themeColors.dashboard.button.filter.color};
    }
  }

  .page-wrapper {
    background: ${({ themeColors }) => themeColors.dashboard.background} !important;
    height: 2.5rem;
  }

  @media (max-width: 460px) {
    margin-right: 1rem;
  }
`;

const Button = styled('button')<{ themeColors: ThemeColors }>`
  ${tw`flex items-center justify-center w-12 h-12 rounded-full cursor-pointer`};
  background: ${({ themeColors }) => themeColors.dashboard.button.filter.background};
  color: ${({ themeColors }) => themeColors.dashboard.button.filter.color};
  border: ${({ themeColors }) => themeColors.dashboard.button.filter.color};
  width: 2.5rem;
  height: 2.5rem;

  &:disabled {
    ${tw`text-gray-500 bg-gray-100`};
  }
`;

const Pager = styled.div`
  ${tw`items-center justify-center hidden w-12 leading-5 rounded-full cursor-pointer md:flex`};

  width: 2.5rem;
`;
const ActivePaper = styled.div`
  ${tw`items-center justify-center hidden w-12 leading-5 text-white rounded-full cursor-pointer md:flex`};
  background-color: ${(props) => `rgba(${props.theme.brandRbgColor},0.8)`};
  width: 2.5rem;
`;

const SelectWrapper = styled.div<{ themeColors: ThemeColors }>`
  text-align: right;
  font-size: 0.85rem;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: ${({ themeColors }) => themeColors.dashboard.button.filter.color};
  flex: auto;

  button {
    border-radius: 0;
    height: 48px;
    -webkit-appearance: button;

    background: ${({ themeColors }) => themeColors.dashboard.button.filter.background};
    color: ${({ themeColors }) => themeColors.dashboard.button.filter.color};
    border: ${({ themeColors }) => themeColors.dashboard.button.filter.color};
    svg {
      width: 17px !important;
      fill: ${({ themeColors }) => themeColors.dashboard.button.filter.fill};
      stroke: ${({ themeColors }) => themeColors.dashboard.button.filter.fill} !important;
      path {
        stroke-width: 3px !important;
      }
    }
  }
`;

const SelectDropDown = styled(Select)<{ themeColors: ThemeColors }>`
  ${tw`w-24`};
  font-size: 0.9rem;
  width: 3rem;
  padding-left: 15px;
  font-weight: 400;
  padding: 10px 0 10px 1rem;
  line-height: 1;
  color: ${({ themeColors }) => themeColors.dashboard.button.filter.color};
`;

interface PaginationProps {
  page: number;
  limit: number | string;
  /**
   * total records
   */
  recordCount: number;
  /**
   * how many pages will display before and after the active page on UI, default 1
   */
  displayRange?: number;
  setPage: (page: number, limit: number | string) => void;
  itemType?: string;
  allowToChangeLimit?: boolean;
  allowSelectAll?: boolean;
  theme?: 'light' | 'dark';
  style?: React.CSSProperties;
}

const Pagination: React.FC<PaginationProps> = ({
  page,
  setPage,
  limit,
  recordCount,
  displayRange = 1,
  itemType = 'TX',
  allowToChangeLimit = true,
  allowSelectAll = true,
  theme = 'white',
  style: styles,
}) => {
  const { theme: themeColors } = Themes.useContainer();
  const totalPages = Number.isNaN(Number(limit)) ? 1 : Math.ceil(recordCount / Number(limit));
  const options: Array<{ label: string; value: number }> = [
    {
      label: `10`,
      value: 10,
    },
    {
      label: `30`,
      value: 30,
    },
    {
      label: `100`,
      value: 100,
    },
  ].filter(Boolean) as Array<{ label: string; value: number }>;

  const onChange = useCallback(
    (selectedPage: number, selectedLimit: number | string) => {
      setPage(selectedPage, selectedLimit);
    },
    [setPage],
  );
  if (totalPages === 0) return null;

  const totalDisplay = displayRange * 2 + 1;
  const start = Math.max(1, page - displayRange);
  const end = Math.min(totalPages + 1, start + totalDisplay);
  let pageRange = range(start, end);
  // insert more items for last page
  const totalItems = pageRange.length;
  if (totalItems < totalDisplay) {
    for (let index = 0; index < totalDisplay - totalItems; index += 1) {
      const newPage = pageRange[0] - 1;
      if (newPage > 0 && !pageRange.includes(newPage)) {
        pageRange = [newPage, ...pageRange];
      }
    }
  }

  return (
    <Wrapper themeColors={themeColors} style={styles} className={`ml-auto ${theme}`}>
      <div className="navigation">
        <Button themeColors={themeColors} disabled={page === 1} onClick={() => onChange(page - 1, limit)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-6 h-6 feather feather-chevron-left"
          >
            <polyline points="15 18 9 12 15 6" />
          </svg>
        </Button>
        <div className="flex h-12 font-medium rounded-full page-wrapper">
          {!pageRange.includes(1) && (
            <>
              <Pager onClick={() => onChange(1, limit)}>1</Pager>
              {pageRange[0] > 2 && <Pager>...</Pager>}
            </>
          )}
          {pageRange.map((pageNumber: number) =>
            page === pageNumber ? (
              <ActivePaper key={pageNumber}>{pageNumber}</ActivePaper>
            ) : (
              <Pager onClick={() => onChange(pageNumber, limit)} key={pageNumber}>
                {pageNumber}
              </Pager>
            ),
          )}
          {!pageRange.includes(totalPages) && (
            <>
              {totalPages - pageRange[pageRange.length - 1] > 1 && <Pager>...</Pager>}
              <Pager onClick={() => onChange(totalPages, limit)}>{totalPages}</Pager>
            </>
          )}
        </div>
        <Button themeColors={themeColors} disabled={page === totalPages} onClick={() => onChange(page + 1, limit)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-6 h-6 feather feather-chevron-right"
          >
            <polyline points="9 18 15 12 9 6" />
          </svg>
        </Button>
      </div>
      <SelectWrapper themeColors={themeColors}>
        Showing{' '}
        <SelectDropDown
          themeColors={themeColors}
          valueKey="value"
          labelKey="label"
          size="medium"
          value={options.find((option) => option.value === limit)}
          options={options}
          onChange={({ option }) => onChange(1, option.value)}
          disabled={!allowToChangeLimit}
        />{' '}
        of {recordCount} {itemType}
      </SelectWrapper>
    </Wrapper>
  );
};

export default Pagination;
