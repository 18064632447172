import { Route, Switch } from 'react-router-dom';
import { MyListingAskInfo } from 'refreshed-component/templates/market-board/MyListingAskInfo';
import { MyListingAsks } from 'refreshed-component/templates/market-board/MyListingAsks';

export const MyListings = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/account/market-board/my-listings/:askId'}>
          <MyListingAskInfo />
        </Route>
        <Route exact path={'/account/market-board/my-listings/'}>
          <MyListingAsks />
        </Route>
      </Switch>
    </>
  );
};
