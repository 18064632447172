export default (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.707 5.29303C16.8945 5.48056 16.9998 5.73487 16.9998 6.00003C16.9998 6.26519 16.8945 6.5195 16.707 6.70703L8.70704 14.707C8.51951 14.8945 8.26521 14.9998 8.00004 14.9998C7.73488 14.9998 7.48057 14.8945 7.29304 14.707L3.29304 10.707C3.11088 10.5184 3.01009 10.2658 3.01237 10.0036C3.01465 9.74143 3.11981 9.49062 3.30522 9.30521C3.49063 9.1198 3.74144 9.01464 4.00364 9.01236C4.26584 9.01008 4.51844 9.11087 4.70704 9.29303L8.00004 12.586L15.293 5.29303C15.4806 5.10556 15.7349 5.00024 16 5.00024C16.2652 5.00024 16.5195 5.10556 16.707 5.29303Z"
      fill="currentColor"
    />
  </svg>
);
