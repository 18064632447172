import { toYears } from './toEndYears';

export const toStartYears = (props: { maximumYearsCount: number; implementationDate: string }) => {
  const { implementationDate, maximumYearsCount } = props;
  if (!implementationDate) {
    return [];
  }

  const startDate = new Date(implementationDate);
  const startYear = startDate.getFullYear();

  return toYears({ startYear, maximumYearsCount });
};
