import { ApiClient } from 'generated';
import { useQuery } from 'react-query';
import { SummaryCard } from 'refreshed-component/molecules/SummaryCard';
import { SummaryHolder } from 'refreshed-component/molecules/SummaryHolder';

import { Icon, IconName } from '@aircarbon/ui';
import { helpers } from '@aircarbon/utils-common';

import { User } from 'state/user';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import Loader from './Loader';

export const Summary = () => {
  const {
    selector: { getAuthToken, getUserId },
  } = User.useContainer();
  const { product } = useMarketplaceProduct();

  const fetchSettlementSummary = async () => {
    try {
      const token = await getAuthToken();
      const apiClient = new ApiClient({
        BASE: window.location.origin,
        TOKEN: token,
      });
      return await apiClient.settle.summarySettle({
        assetCategoryId: product,
        xEntityDomain: helpers.getHostFromUrl(window.location.origin),
      });
    } catch (error) {
      console.error('Error fetching settlements summary', error);
    }
  };

  const { data: settleSummary, isLoading } = useQuery(
    ['fetch-settlements-summary', product],
    () => fetchSettlementSummary(),
    {
      enabled: getUserId() > 0,
    },
  );

  if (isLoading) {
    return <Loader />;
  }

  if (settleSummary?.status === 'error') {
    return null;
  }

  return (
    <SummaryHolder>
      <SummaryCard
        icon={<Icon name={IconName.Database} />}
        title="Total"
        value={settleSummary?.data?.total?.toString() ?? '-'}
      />
      <SummaryCard
        icon={<Icon name={IconName.Bolt} />}
        title="Active"
        value={settleSummary?.data?.active?.toString() ?? '-'}
      />
      <SummaryCard
        icon={<Icon name={IconName.Check} />}
        title="Settled"
        value={settleSummary?.data?.settled?.toString() ?? '-'}
      />
    </SummaryHolder>
  );
};
