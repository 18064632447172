import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Loading from 'refreshed-component/molecules/Loading';
import * as yup from 'yup';

import { IconName, InputText, InputTextSize, InputTextType, Modal, Select } from '@aircarbon/ui';

import useCarbonMetaOptions from 'hooks/useCarbonMetaOptions';

const schema = yup.object().shape({
  projectName: yup.string().required('Required'),
  registryId: yup.string().required('Required'),
  registryProjectId: yup.string().required('Required'),
});

export type NewProjectFormValues = {
  registryId: string;
  projectName: string;
  registryProjectId: string;
};

function NewCarbonProject({
  isOpen,
  onClose,
  onNewProject,
  type = 'Carbon',
}: {
  isOpen: boolean;
  onClose: () => void;
  onNewProject: (formData: NewProjectFormValues) => void;
  type: 'Carbon' | 'REC';
}) {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<NewProjectFormValues>({
    resolver: yupResolver(schema),
  });
  const {
    carbonMetaOptions: { registries },
    isLoading: isLoadingRegistry,
  } = useCarbonMetaOptions({
    query: {
      assetCategory: type === 'Carbon' ? 'token' : 'rec',
    },
  });

  const onSubmitHandler = (data: NewProjectFormValues) => {
    onNewProject(data);
    reset();
  };

  return (
    <Modal
      title="Add A Carbon Project"
      isVisible={isOpen}
      onClose={onClose}
      positiveButtonText="Submit For Review"
      positiveButtonIcon={IconName.ArrowEnd}
      onPressPositiveButton={() => {
        // Trigger form validation and submit data
        handleSubmit(onSubmitHandler)();
      }}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)}>
        <Controller
          control={control}
          name="projectName"
          render={({ field }) => (
            <InputText
              label="Project Name"
              type={InputTextType.Text}
              size={InputTextSize.s}
              value={field.value}
              onChange={field.onChange}
              error={errors.projectName?.message}
              description="Please enter name exactly as it is on the carbon registry"
            />
          )}
        />
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col w-full">
            {isLoadingRegistry ? (
              <Loading />
            ) : (
              <Controller
                control={control}
                name="registryId"
                render={({ field }) => (
                  <Select
                    className="w-full"
                    label="Registry"
                    placeholder="Select Registry"
                    items={registries?.map((item) => ({ value: String(item.id), title: item.registryName })) ?? []}
                    value={field.value}
                    onChange={(selected) =>
                      setValue(field.name, selected.value, {
                        shouldValidate: true,
                      })
                    }
                    size={InputTextSize.s}
                    error={errors.registryId?.message}
                  />
                )}
              />
            )}
          </div>
          <div className="flex flex-col w-full">
            <Controller
              control={control}
              name="registryProjectId"
              render={({ field }) => (
                <InputText
                  label={'Registry Project ID'}
                  placeholder="Registry Project ID"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.registryProjectId?.message}
                  size={InputTextSize.s}
                />
              )}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default NewCarbonProject;
