/* eslint-disable-next-line no-shadow */
export enum RegistryProvider {
  SRN = 'srn',
  REC = 'rec',
}

export interface GetProjectRegistryBlocksRequest {
  registryCode: string;
  projectId?: number;
}

export interface GetProjectRegistryBlocksResponse {
  accountAddress: string | null;
  registryBlockId: string;
  blockSerialNumber: string;
  quantity: number;
  registryAccountId: string;
}

export interface GetProjectRegistryBlockDetailsResponse {
  accountAddress: string | null;
  registryBlockId: string;
  blockSerialNumber: string;
  quantity: number;
  registryAccountId: string;

  // The next fields are specific to SRN registry provider, for future integrations consider using metadata object
  certificateMaxNumber: number | null;
  certificateMinNumber: number | null;
}

export interface GetProjectRegistryBlockRequest {
  registryCode: string;
  registryBlockId: string;
}
