import React from 'react';
import { Step, StepStatus } from 'refreshed-component/atoms/Step';
import { Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { toSeparatorBackgroundColor } from './utils/toSeparatorBackgroundColor';

const StyledStepper = styled.div`
  display: flex;
  gap: var(${Spacing.small});
  width: 100%;
  align-items: center;
`;

const Separator = styled.div<{
  backgroundColor: string;
}>`
  height: 1px;
  flex-grow: 1;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

type Props = {
  className?: string;
  steps: Array<{
    label: string;
    value: string;
    status: StepStatus;
  }>;
  onPressStep(stepIndex: number): void;
};

export const Stepper: React.FC<Props> = (props) => {
  const { steps = [], className, onPressStep } = props;

  return (
    <StyledStepper className={className}>
      {steps.map((step, index) => {
        if (index === steps.length - 1) {
          return (
            <Step
              key={index}
              onPress={step.status !== StepStatus.Next ? () => onPressStep(index) : undefined}
              status={step.status}
            >
              {step.label}
            </Step>
          );
        }

        return (
          <React.Fragment key={index}>
            <Step onPress={step.status !== StepStatus.Next ? () => onPressStep(index) : undefined} status={step.status}>
              {step.label}
            </Step>
            <Separator backgroundColor={toSeparatorBackgroundColor(step.status)} />
          </React.Fragment>
        );
      })}
    </StyledStepper>
  );
};
