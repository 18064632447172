import { useEffect, useMemo, useRef, useState } from 'react';

import {
  ButtonSize,
  ButtonVariant,
  IconName,
  Knob,
  Tabs,
  toSpacing,
  toBorderColor,
  useLayerBackground,
  BorderColor,
  TabsSize,
  styled,
} from '@aircarbon/ui';

import { useActiveTab } from './hooks/useActiveTab';
import { SliderState, useBottomSlideUp } from './hooks/useBottomSlideUp';

/**
 * Header height in px
 */
const headerHeight = 49;

export const BottomSlideUp = () => {
  const { layerBackground } = useLayerBackground();
  const { activeTabId, isOpened, updateState, availableTabs } = useActiveTab();
  const slideUpRef = useRef<HTMLDivElement>(null);
  const ContentComponent = useMemo(() => {
    return availableTabs.find((tab) => tab.id === activeTabId)?.component || availableTabs[0].component;
  }, [activeTabId]);
  const { startDragging, translateY, state } = useBottomSlideUp({
    headerHeight,
    containerRef: slideUpRef,
    isExpanded: isOpened,
  });

  useEffect(() => {
    if ([SliderState.Expanded, SliderState.Collapsed, SliderState.Dragging].includes(state)) {
      updateState((prevState) => ({ ...prevState, isOpened: state === SliderState.Expanded }));
    }
  }, [state]);

  const onHeaderMouseDown = (event: React.MouseEvent) => {
    startDragging(event.clientY);
  };

  const onHeaderTouchStart = (event: React.TouchEvent) => {
    startDragging(event.touches[0].clientY);
  };

  return (
    <StyledBottomSlideUp
      ref={slideUpRef}
      $translateY={translateY}
      background={layerBackground('layer')}
      $sliderState={state}
      className={state !== SliderState.Dragging ? 'with-animation' : ''}
      style={
        [SliderState.Dragging, SliderState.CustomPosition].includes(state)
          ? {
              transform: `translate3d(0, ${translateY}px, 0)`,
            }
          : undefined
      }
    >
      <Header onMouseDown={onHeaderMouseDown} onTouchStart={onHeaderTouchStart}>
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Tabs
            onPressTab={(tab) => {
              updateState((prevState) => ({
                activeTabId: tab.id,
                isOpened: state === SliderState.Collapsed ? true : prevState.isOpened,
              }));
            }}
            activeItemId={activeTabId}
            items={availableTabs}
            size={TabsSize.l}
          />
        </div>
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Knob
            size={ButtonSize.xs}
            icon={isOpened ? IconName.Collapse : IconName.Expand}
            variant={ButtonVariant.secondary}
            tooltip={isOpened ? 'Collapse' : 'Expand'}
            onPress={(e) => {
              e.preventDefault();
              e.stopPropagation();
              updateState((prevState) => ({
                ...prevState,
                isOpened: !prevState.isOpened,
              }));
            }}
          />
        </div>
      </Header>
      <Content>
        <ContentComponent />
      </Content>
    </StyledBottomSlideUp>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ theme }) => toSpacing(theme)(8)};
  flex-shrink: 0;
  cursor: row-resize;
`;

const Content = styled.div`
  flex-shrink: 1;
  overflow-y: auto;
`;

const StyledBottomSlideUp = styled.div<{
  background: string;
  $translateY: number;
  $sliderState: SliderState;
}>`
  width: 100%;
  position: absolute;
  bottom: calc(-100% + 1px);
  z-index: 1;
  border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  background: ${({ background }) => background};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  ${({ $sliderState }) => {
    if ([SliderState.Dragging, SliderState.CustomPosition].includes($sliderState)) {
      return '';
    }

    return `
      transform: translate3d(
        0,
        ${$sliderState === SliderState.Expanded ? '-100%' : `-${headerHeight}px`},
        0
      );
    `;
  }}

  &.with-animation {
    transition: transform 0.2s ease-in;
  }
`;
