import type { Dto } from '@aircarbon/utils-common';

import type { DocumentationFormValue } from '../../ProjectForm/components/Documentation';

const documentTypeIdToDocumentKeyMap: Record<number, keyof DocumentationFormValue['documents']> = {
  1: 'projectIdeaNote',
  2: 'preFeasibilityStudyDocument',
  3: 'projectDesignDocument',
  4: 'verificationReport',
  5: 'emissionReductionCalculation',
  6: 'monitoringProtocols',
};

export const toFormDocuments = (
  documents: Dto.ApxProject['apxDocuments'] = [],
): DocumentationFormValue['documents'] => {
  const documentsByDocumentIdMap = documents.reduce(
    (acc, current) => {
      const documentKey = documentTypeIdToDocumentKeyMap[current.documentTypeId as number];
      if (!documentKey) return { ...acc };

      return {
        ...acc,
        [documentKey]: {
          id: current.id,
          name: current.name || '',
          url: current.url || '',
          size: current.size || 0,
          documentTypeId: current.documentTypeId,
        },
      };
    },
    {} as Partial<DocumentationFormValue['documents']>,
  );

  return {
    projectIdeaNote: documentsByDocumentIdMap.projectIdeaNote || null,
    preFeasibilityStudyDocument: documentsByDocumentIdMap.preFeasibilityStudyDocument || null,
    projectDesignDocument: documentsByDocumentIdMap.projectDesignDocument || null,
    verificationReport: documentsByDocumentIdMap.verificationReport || null,
    emissionReductionCalculation: documentsByDocumentIdMap.emissionReductionCalculation || null,
    monitoringProtocols: documentsByDocumentIdMap.monitoringProtocols || null,
  };
};
