import { BadgeVariant, CMBOrderType } from '@aircarbon/ui';

export const toOrderSideBadgeProps = (orderType: CMBOrderType) => {
  return {
    variant:
      orderType === CMBOrderType.Bid
        ? BadgeVariant.Success
        : orderType === CMBOrderType.Auction
          ? BadgeVariant.Alert
          : BadgeVariant.Danger,
    value: orderType === CMBOrderType.Bid ? 'Bid' : orderType === CMBOrderType.Auction ? 'Auction' : 'Offer',
  };
};
