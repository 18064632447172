import { Spacing, FontSize, FontWeight, Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

export type RadioBoxProps = {
  id: number | string;
  label: string;
  isSelected?: boolean;
  onClick?: (props: RadioBoxProps) => void;
  className?: string;
};

const RadioBoxRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(${Spacing.xs});
  cursor: pointer;
  font-size: var(${FontSize.small});
  font-weight: var(${FontWeight.default});
  color: var(${Colors.gray_900});
`;

export const RadioBox = (props: RadioBoxProps) => {
  return (
    <RadioBoxRoot
      onClick={() => {
        props.onClick?.(props);
      }}
      tabIndex={1}
      className={props.className}
    >
      {!props.isSelected ? (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="8.25" fill={`var(${Colors.gray_50})`} />
          <rect
            x="0.75"
            y="0.75"
            width="16.5"
            height="16.5"
            rx="8.25"
            stroke={`var(${Colors.gray_300})`}
            strokeWidth="0.5"
          />
        </svg>
      ) : (
        <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="8"
            cy="8"
            r="6.25"
            fill={`var(${Colors.gray_50})`}
            stroke={`var(${Colors.gray_900})`}
            strokeWidth="3"
          />
        </svg>
      )}
      {props.label}
    </RadioBoxRoot>
  );
};
