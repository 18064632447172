import { useEffect, useState } from 'react';

import { getItem, setItem } from '../local-storage';

const storeValue = (key: string, value: any) => {
  try {
    const newValue = value instanceof Function ? value() : value;
    setItem(key, JSON.stringify(newValue));
  } catch (error) {
    console.log('useLocalStorage: error', error);
  }
};

export function useLocalStorage(key: string, initialValue?: any) {
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    setValue(getItem(key, initialValue, true));
  }, [key]);

  return [
    value || getItem(key, initialValue, true),
    (newValue: any) => {
      storeValue(key, newValue);
      setValue(newValue);
    },
  ];
}

export default useLocalStorage;
