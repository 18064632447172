import { useQuery } from 'react-query';
import { toast } from 'refreshed-component/molecules/toast';
import { findGERAsset } from 'refreshed-pages/ger/utils/findGERAsset';
import { toGERAssetsAllocations } from 'refreshed-pages/ger/utils/toGERAssetsAllocations';

import { formatter, logger } from '@aircarbon/utils-common';

import { Contract } from 'state/contract';
import { User } from 'state/user';

import { toAssetBalance } from '../utils/toAssetBalance';

export const useGERBalance = (): {
  gerBalance: string;
  gerLogo: string;
  isLoading: boolean;
  assetAllocations: Array<{
    label: string;
    value: string;
  }>;
} => {
  const {
    selector: { getAuthToken },
  } = User.useContainer();

  const { tokenTypes, isLoadingTokenTypes } = Contract.useContainer();
  const { data: tokensQty = {} } = useQuery<Record<number, number>>(
    ['ger-tokens-qty'],
    async () => {
      try {
        // TODO: Implement data-provider
        const authToken = await getAuthToken();
        const result = fetch('/api/account/basket/token-available', {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: `Bearer ${authToken}`,
          },
        }).then((resp) => resp.json());

        return result;
      } catch (e) {
        toast.error((e as any)?.message ?? 'Something went wrong');
        return {};
      }
    },
    {
      refetchInterval: 3000,
    },
  );
  const gerAsset = findGERAsset(tokenTypes);
  const assetBalance = toAssetBalance({
    asset: gerAsset,
    assetBalances: tokensQty,
  });

  const formattedBalance = assetBalance === null ? '' : formatter.formatNumber(assetBalance, 0);

  const assetAllocations = toGERAssetsAllocations({
    basketConfigurationJSON: gerAsset?.configuration as string,
    assets: tokenTypes,
    assetBalance,
  });

  return {
    gerBalance: formattedBalance,
    gerLogo: '/logos/GER.png',
    isLoading: isLoadingTokenTypes,
    assetAllocations,
  };
};
