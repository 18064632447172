import { Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

export const PhoneInputContainer = styled.div<{
  isFocused: boolean;
  hasError: boolean;
}>`
  display: flex;
  align-items: flex-stretch;
  flex-grow: 1;
  border-start-end-radius: 8px;
  border-end-end-radius: 8px;
  margin-inline-start: -1px;
  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out, outline-color 200ms ease-in-out;
  &:hover {
    ${({ isFocused, hasError }) => {
      switch (true) {
        case isFocused && hasError:
          return `
            border: 1px solid var(${Colors.danger_400});
            background: var(${Colors.danger_100});
            outline: var(${Colors.danger_100}) solid 3px;
          `;
        case isFocused:
          return `
            border: 1px solid var(${Colors.gray_400});
            background: var(${Colors.gray_200});
            outline: var(${Colors.gray_100}) solid 3px;
          `;
        case hasError:
          return `
            border: 1px solid var(${Colors.danger_400});
            background: var(${Colors.danger_100});
            outline: var(${Colors.danger_100}) solid 3px;
          `;
        default:
          return `
            border: 1px solid var(${Colors.gray_300});
            background: var(${Colors.gray_100});
            outline: var(${Colors.transparent}) solid 3px;
          `;
      }
    }}
  }
  ${({ isFocused, hasError }) => {
    switch (true) {
      case isFocused && hasError:
        return `
          border: 1px solid var(${Colors.danger_400});
          background: var(${Colors.danger_100});
          outline: var(${Colors.danger_100}) solid 3px;
        `;
      case isFocused:
        return `
          border: 1px solid var(${Colors.gray_400});
          background: var(${Colors.gray_200});
          outline: var(${Colors.gray_100}) solid 3px;
        `;
      case hasError:
        return `
            border: 1px solid var(${Colors.danger_400});
            background: var(${Colors.gray_50});
            outline: var(${Colors.transparent}) solid 3px;
          `;
      default:
        return `
          border: 1px solid var(${Colors.gray_200});
          background: var(${Colors.gray_50});
          outline: var(${Colors.transparent}) solid 3px;
        `;
    }
  }}
`;
