import { OtcCriteria } from './OtcCriteria';
import { StaticFilters } from './StaticFilters';

export const toCriteriaQueryParams = (dynamicFilters: OtcCriteria, staticFilters: StaticFilters) => {
  let queryParams: any = {};

  queryParams = {
    ...queryParams,
    ...(staticFilters.vintageYear
      ? {
          'criteria[VINTAGE_YEAR][IN]': staticFilters.vintageYear.split(','),
        }
      : {}),
  };

  dynamicFilters.forEach((dynamicFilter) => {
    queryParams = {
      ...queryParams,
      ...(!!dynamicFilter.options?.length
        ? {
            [`criteria[${dynamicFilter.value}][IN]`]: dynamicFilter.options
              .map((option) => option.value)
              .filter((v) => !!v),
          }
        : {}),
    };
  });

  return queryParams;
};
