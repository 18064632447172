import queryString from 'query-string';

import { logger } from '@aircarbon/utils-common';

import { userAuthenticatedApi } from '../fetcher';

export const fetchCarbonProjectById = async (registryProjectId: string, registryId?: string) => {
  if (!registryProjectId) {
    throw new Error('projectId is required');
  }

  const userService = await userAuthenticatedApi();
  const qs = queryString.stringify(
    {
      registryProjectId,
      registryId,
    },
    {
      skipEmptyString: true,
      skipNull: true,
    },
  );

  const projectList = userService.url(`/user/settle/project?${qs}`).get();

  try {
    const result = await projectList.json<{
      name: string;
      projectDeveloper: string;
      countryCode: string;
      registryId: number;
      registryProjectId: string;
      registryProjectUrl: string;
      status: string;
      // REC_TECHNOLOGY
      technology: string;
    }>();

    return result;
  } catch (error) {
    logger.error(
      error,
      `Error fetching project metadata for project: ${JSON.stringify({
        registryProjectId,
        registryId,
      })}`,
    );
    return null;
  }
};
