import { Tip } from 'grommet';
import styled from 'styled-components';

import { formatter } from '@aircarbon/utils-common';

import { Table } from 'pages/account/components/Styled';

import { Contract } from 'state/contract';
import { type ScreenSizes, UI } from 'state/ui';

import { type ThemeColors, Themes } from '../trading/components/Themes';

const Wrapper = styled.div<{ screenSize: ScreenSizes }>`
  ${(props) => {
    if (props.screenSize === 'small' || props.screenSize === 'medium') {
      return `max-width: 100%;`;
    } else {
      return `
      min-width: 700px;
      `;
    }
  }}
`;

const TokenName = styled.div<{ themeColor: ThemeColors }>`
  font-size: 1em;
  text-transform: uppercase;
  background: ${({ themeColor }) => themeColor.dashboard.lightColor};
  color: ${({ themeColor }) => themeColor.dashboard.background};
  font-size: 13px;
  padding: 2px 1em;
  font-weight: 700;
  min-width: 120px;
  text-align: center;
  border-radius: 30px;
`;

interface Props {
  qty?: number;
  basketConfiguration: Array<{
    percentage: number;
    tokenTypeId: number;
  }>;
  tokenUnit: string;
  tokensQty: Record<number, number>;
  tokenScRatio: number;
  render?: (invalid: boolean) => React.ReactNode;
}

function isFloatQty(type: { percentage: number; tokenTypeId: number }, qty: number) {
  return !Number.isInteger((type.percentage * qty) / 100);
}

function invalidQty({
  type,
  qty,
  tokensQty,
  tokenScRatio,
}: {
  type: { percentage: number; tokenTypeId: number };
  qty: number;
  tokensQty: Record<number, number>;
  tokenScRatio: number;
}): boolean {
  return Number((type.percentage * qty) / 100) > (tokensQty?.[type.tokenTypeId] ?? 0) / tokenScRatio;
}

function PreviewBasket({ qty = 0, basketConfiguration, tokenUnit, tokensQty, tokenScRatio, render }: Props) {
  const { theme: themeColors } = Themes.useContainer();
  const { tokenTypesByScId } = Contract.useContainer();
  const { screenSize } = UI.useContainer();
  const invalid = basketConfiguration.some(
    (type) => isFloatQty(type, qty) || invalidQty({ type, qty, tokensQty, tokenScRatio }),
  );
  return (
    <Wrapper screenSize={screenSize}>
      <div
        className="flex justify-center items-center w-full"
        style={{
          overflow: screenSize === 'small' || screenSize === 'medium' ? 'auto' : '',
        }}
      >
        <div
          style={{
            border: `1px solid ${themeColors.dashboard.form.input.border}`,
            overflow: 'hidden',
          }}
          className="w-full rounded border"
        >
          <Table themeColors={themeColors} className="w-full">
            <thead>
              <tr className="">
                <th className="py-3 px-6 text-left">Asset Type</th>
                <th className="py-3 px-6 text-left">Current Balance</th>
                <th className="py-3 px-6 text-left">Percentage</th>
                <th className="py-3 px-6 text-center">Quantity</th>
              </tr>
            </thead>
            <tbody className="">
              {basketConfiguration?.map((type) => (
                <tr key={`${type.percentage}-${type.tokenTypeId}`}>
                  <td className="">
                    <div className="flex items-center">
                      <TokenName themeColor={themeColors} className="font-medium">
                        {tokenTypesByScId()[type.tokenTypeId]?.name}
                      </TokenName>
                    </div>
                  </td>
                  {/* TODO: Show available balance */}
                  <td className="">
                    <div className="flex items-center">
                      <span>
                        {formatter.formatNumber((tokensQty?.[type.tokenTypeId] ?? 0) / tokenScRatio, 0)} {tokenUnit}
                      </span>
                    </div>
                  </td>
                  <td className="">
                    <div className="flex items-center">
                      <span>{type.percentage} %</span>
                    </div>
                  </td>
                  <td
                    className={`${
                      isFloatQty(type, qty) || invalidQty({ type, qty, tokensQty, tokenScRatio }) ? 'text-red-500' : ''
                    }`}
                  >
                    <div className="flex items-center">
                      <span>
                        {Number((type.percentage * qty) / 100).toFixed(2)} {tokenUnit}
                      </span>
                      {invalidQty({ type, qty, tokensQty, tokenScRatio }) && (
                        <Tip
                          plain={false}
                          content={
                            <div style={{ color: themeColors.dashboard.color }} className="text-sm">
                              Insufficient asset.
                            </div>
                          }
                          dropProps={{ align: { left: 'right' } }}
                        >
                          <svg
                            className="inline-block ml-1 w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                          </svg>
                        </Tip>
                      )}
                      {isFloatQty(type, qty) && (
                        <Tip
                          plain={false}
                          content={
                            <div
                              style={{
                                color: themeColors.dashboard.color,
                              }}
                              className="text-sm"
                            >
                              Quantity cannot break 1 ton.
                            </div>
                          }
                          dropProps={{ align: { left: 'right' } }}
                        >
                          <svg
                            className="inline-block ml-1 w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                          </svg>
                        </Tip>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      {render && render(invalid)}
    </Wrapper>
  );
}

export default PreviewBasket;
