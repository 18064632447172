import { ListItemLoader } from '@aircarbon/ui';

const Loader = ({ className }: { className?: string }) => {
  return (
    <div className={`w-full ${className || ''}`}>
      <ListItemLoader.Loader showDescription />
    </div>
  );
};

export default Loader;
