export default (
  <svg viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25697 1.09901C7.02197 -0.26099 8.97897 -0.26099 9.74297 1.09901L15.323 11.019C16.073 12.353 15.11 13.999 13.581 13.999H2.41997C0.889971 13.999 -0.0730296 12.353 0.67697 11.019L6.25697 1.09901ZM8.99997 11C8.99997 11.2652 8.89461 11.5196 8.70708 11.7071C8.51954 11.8947 8.26519 12 7.99997 12C7.73475 12 7.4804 11.8947 7.29286 11.7071C7.10533 11.5196 6.99997 11.2652 6.99997 11C6.99997 10.7348 7.10533 10.4804 7.29286 10.2929C7.4804 10.1054 7.73475 10 7.99997 10C8.26519 10 8.51954 10.1054 8.70708 10.2929C8.89461 10.4804 8.99997 10.7348 8.99997 11ZM7.99997 3.00001C7.73475 3.00001 7.4804 3.10537 7.29286 3.2929C7.10533 3.48044 6.99997 3.73479 6.99997 4.00001V7.00001C6.99997 7.26523 7.10533 7.51958 7.29286 7.70712C7.4804 7.89465 7.73475 8.00001 7.99997 8.00001C8.26519 8.00001 8.51954 7.89465 8.70708 7.70712C8.89461 7.51958 8.99997 7.26523 8.99997 7.00001V4.00001C8.99997 3.73479 8.89461 3.48044 8.70708 3.2929C8.51954 3.10537 8.26519 3.00001 7.99997 3.00001Z"
      fill="#313131"
    />
  </svg>
);
