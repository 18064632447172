import { userAuthenticatedApi } from '../fetcher';

export type TokenPriceResponse = { pairId: number; marketPrice: number; settlementPrice: number };

export const fetchTokenPrice = async ({ pairId, type }: { pairId: number; type: 'BUY' | 'SELL' }) => {
  const endpoint = '/oms/trade/token-price';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ pairId, type }).get();
  return res.json<TokenPriceResponse>();
};
