import { Text } from 'refreshed-component/atoms/Text';
import { FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

export const SectionHeader: React.FC<{
  suffix?: React.ReactNode;
}> = ({ children, suffix }) => {
  return (
    <StyledSectionHeader>
      <Text size={FontSize.large} weight={FontWeight.semibold}>
        {children}
      </Text>
      {!!suffix && <Suffix>{suffix}</Suffix>}
    </StyledSectionHeader>
  );
};

const StyledSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1367px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const Suffix = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
