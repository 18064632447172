import { flatten } from 'lodash';

import { CMBOrderType, TextColor } from '@aircarbon/ui';

import { OrderDetails } from '..';
import { ReviewOrderModalData } from '../../ReviewOrderModal';
import { BidFormValue, OfferFormValue } from '../hooks/usePlaceOrderForm';

interface ToReviewOrderModalDataProps {
  formValue: OfferFormValue | BidFormValue;
  orderDetails: OrderDetails;
  placedBy: string;
  onBehalfOf: string;
  fee: number;
  total: number;
  currency: string;
  quantityUnit: string;
  orderIsPrefunded: boolean;
  isOtcSettlementEnabled: boolean;
}

const orderTypeToSideMap: Record<
  CMBOrderType,
  {
    value: string;
    valueColor: TextColor;
  }
> = {
  [CMBOrderType.Auction]: {
    value: 'Bid',
    valueColor: TextColor.success,
  },
  [CMBOrderType.Bid]: {
    value: 'Offer',
    valueColor: TextColor.error,
  },
  [CMBOrderType.Offer]: {
    value: 'Bid',
    valueColor: TextColor.success,
  },
};

export const toReviewOrderModalData = (props: ToReviewOrderModalDataProps): ReviewOrderModalData => {
  const {
    orderDetails,
    formValue,
    fee,
    total,
    placedBy,
    onBehalfOf,
    currency,
    quantityUnit,
    orderIsPrefunded,
    isOtcSettlementEnabled,
  } = props;

  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  });

  const quantityFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const requestFormValue = formValue as OfferFormValue;

  let summary: Array<{
    label: string;
    value: string;
    valueColor?: TextColor;
  }> = [
    {
      label: 'Side',
      value: orderTypeToSideMap[orderDetails.type].value,
      valueColor: orderTypeToSideMap[orderDetails.type].valueColor,
    },
    ...(orderDetails.type === CMBOrderType.Bid
      ? [
          {
            label: 'Project',
            value: requestFormValue.project?.title || '',
          },
          {
            label: 'Vintage',
            value: requestFormValue.vintage,
          },
        ]
      : ((flatten(orderDetails.metaFields).filter((v) => !!v) ?? []) as Array<{ label: string; value: string }>)),
    ...(orderDetails.type === CMBOrderType.Bid && !isOtcSettlementEnabled
      ? [
          {
            label: 'Assets Reserved',
            value: formValue.isInstantTrade ? 'Yes (Assets will be reserved)' : 'No',
          },
        ]
      : []),
    ...(orderDetails.type === CMBOrderType.Bid &&
    (formValue as OfferFormValue).baseAssetId &&
    (formValue as OfferFormValue).project?.vintageBalancePerAsset
      ? [
          {
            label: 'Contract',
            value:
              Object.keys((formValue as OfferFormValue).project?.vintageBalancePerAsset ?? {}).find(
                (v) =>
                  (formValue as OfferFormValue).project?.vintageBalancePerAsset?.[v].assetId ===
                  Number((formValue as OfferFormValue).baseAssetId),
              ) ?? '',
          },
        ]
      : []),
  ];

  return {
    summary,
    price: moneyFormatter.format(Number(formValue.price)),
    quantity: quantityFormatter.format(Number(formValue.quantity)) + ' ' + quantityUnit,
    fee: moneyFormatter.format(Number(fee)),
    total: moneyFormatter.format(Number(total)),
    shouldAcceptImmediately:
      orderIsPrefunded &&
      Number(formValue.price) === orderDetails.rawPrice &&
      Number(formValue.quantity) === orderDetails.rawQuantity,
    placedBy,
    onBehalfOf,
  };
};
