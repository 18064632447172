import { useCallback, useEffect, useState } from 'react';

import { Const } from '@aircarbon/utils-common';

import { UI } from 'state/ui';

import { activeTabStore } from '../utils/activeTabStore';

export const useActiveTab = () => {
  const [activeTabStoreState, setActiveTabStoreState] = useState(activeTabStore.getSnapshot());
  const { getSetting } = UI.useContainer();

  useEffect(() => {
    const unsubscribeFromStore = activeTabStore.subscribe(() => {
      setActiveTabStoreState(activeTabStore.getSnapshot());
    });

    return unsubscribeFromStore;
  }, []);

  const updateState = useCallback(
    (
      callback: (prevState: { isOpened: boolean; activeTabId: string }) => { isOpened: boolean; activeTabId: string },
    ) => {
      activeTabStore.updateState(callback);
    },
    [],
  );

  const isMarketBoardAndAuctionsV2SettlementModeEnabled =
    getSetting(Const.FeatureToggle.MarketBoardAndAuctionsV2SettlementMode) === '1';

  return {
    availableTabs: isMarketBoardAndAuctionsV2SettlementModeEnabled
      ? activeTabStore.availableTabs.filter((tab) => tab.id !== 'trade-history')
      : activeTabStore.availableTabs,
    updateState,
    ...activeTabStoreState,
  };
};
