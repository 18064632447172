import { useContext } from 'react';
import { Colors, FontSize, FontWeight, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { HitArea } from './HitArea';
import { Icon, IconType } from './Icon';
import { ResponsiveContainerContext, ResponsiveContainerWrapper } from './ResponsiveContainer';
import { Text } from './Text';

const Root = styled.div<{ type: AlertType }>`
  border-left: 4px solid var(${({ type }) => AlertsColor[type].border});
  background: var(${({ type }) => AlertsColor[type].background});
  padding: var(${Spacing.base});
  display: flex;
  flex-direction: column;
  gap: var(${Spacing.xs});
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(${Spacing.xs});
`;

const Contents = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(${Spacing.xs});
  flex: 1;
  align-items: center;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(${Spacing.xs});
  align-items: center;
`;

export enum AlertType {
  ALERT = 'ALERT',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

type AlertColor = {
  border: Colors;
  background: Colors;
  icon: Colors;
};

type AlertsProps = {
  type?: AlertType;
  content: {
    label?: string;
    message: string;
  };
  closeIcon?: {
    visible: boolean;
    onClick: () => void;
  };
};

const AlertsColor: { [key: string]: AlertColor } = {
  [AlertType.ALERT]: {
    background: Colors.danger_100,
    border: Colors.danger_700,
    icon: Colors.danger_700,
  },
  [AlertType.INFO]: {
    background: Colors.gray_200,
    border: Colors.gray_500,
    icon: Colors.gray_500,
  },
  [AlertType.WARNING]: {
    background: Colors.warning_100,
    border: Colors.warning_700,
    icon: Colors.warning_700,
  },
};

export const Alerts = ResponsiveContainerWrapper((props: AlertsProps) => {
  const { content, closeIcon } = props;
  const { size } = useContext(ResponsiveContainerContext);
  const type = props.type && Object.keys(AlertsColor).includes(props.type) ? props.type : AlertType.ALERT;

  return (
    <Root type={type}>
      <Line>
        <Contents>
          <div className="w-auto h-full">
            <HitArea onClick={() => closeIcon?.onClick?.()} className="cursor-pointer" width={20} height={20}>
              <Icon
                color={(AlertsColor[type] || AlertsColor[AlertType.ALERT]).icon}
                width={18}
                height={18}
                type={IconType.ExclamationCircle}
              />
            </HitArea>
          </div>
          {content.label && <Text weight={FontWeight.medium}>{content.label}</Text>}
          {((!['xs', 'small'].includes(size) && content.message) || !content.label) && (
            <Text size={FontSize.small}>{content.message}</Text>
          )}
        </Contents>
        <Controls>
          {closeIcon?.visible && (
            <div className="w-auto h-full">
              <HitArea onClick={() => closeIcon?.onClick?.()} className="cursor-pointer" width={20} height={20}>
                <Icon color={Colors.gray_500} width={12} height={12} type={IconType.X} />
              </HitArea>
            </div>
          )}
        </Controls>
      </Line>
      {['xs', 'small'].includes(size) && content.label && (
        <Line>
          <Contents>{content.message && <Text size={FontSize.small}>{content.message}</Text>}</Contents>
        </Line>
      )}
    </Root>
  );
});
