export default (
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 8C16.5 10.1217 15.6571 12.1566 14.1569 13.6569C12.6566 15.1571 10.6217 16 8.5 16C6.37827 16 4.34344 15.1571 2.84315 13.6569C1.34285 12.1566 0.5 10.1217 0.5 8C0.5 5.87827 1.34285 3.84344 2.84315 2.34315C4.34344 0.842855 6.37827 0 8.5 0C10.6217 0 12.6566 0.842855 14.1569 2.34315C15.6571 3.84344 16.5 5.87827 16.5 8ZM9.5 4C9.5 4.26522 9.39464 4.51957 9.20711 4.70711C9.01957 4.89464 8.76522 5 8.5 5C8.23478 5 7.98043 4.89464 7.79289 4.70711C7.60536 4.51957 7.5 4.26522 7.5 4C7.5 3.73478 7.60536 3.48043 7.79289 3.29289C7.98043 3.10536 8.23478 3 8.5 3C8.76522 3 9.01957 3.10536 9.20711 3.29289C9.39464 3.48043 9.5 3.73478 9.5 4ZM7.5 7C7.23478 7 6.98043 7.10536 6.79289 7.29289C6.60536 7.48043 6.5 7.73478 6.5 8C6.5 8.26522 6.60536 8.51957 6.79289 8.70711C6.98043 8.89464 7.23478 9 7.5 9V12C7.5 12.2652 7.60536 12.5196 7.79289 12.7071C7.98043 12.8946 8.23478 13 8.5 13H9.5C9.76522 13 10.0196 12.8946 10.2071 12.7071C10.3946 12.5196 10.5 12.2652 10.5 12C10.5 11.7348 10.3946 11.4804 10.2071 11.2929C10.0196 11.1054 9.76522 11 9.5 11V8C9.5 7.73478 9.39464 7.48043 9.20711 7.29289C9.01957 7.10536 8.76522 7 8.5 7H7.5Z"
      fill="#111111"
    />
  </svg>
);
