import type { Contract } from 'ethers';

import { parseTokenBatch, parseBatchMetadata } from './contractParser';
import { hex2int, convertUnit, hex2usd, type HexValue } from './formatter';
import { decodeWeb3Object } from './helpers';
import type { AddressData } from './interfaces';

export const getCurrencies = async (contract: Contract) => {
  const ccys = await contract?.getCcyTypes();
  const ccysObj: Record<string, any> = {};
  const ccyTypes = ccys?.ccyTypes ?? [];
  ccyTypes.forEach((ccy: Record<string, any>) => {
    const ccyId = hex2int(ccy.id);
    ccysObj[ccyId] = {
      name: ccy.name,
      unit: ccy.unit,
      decimals: ccy.decimals,
    };
  });

  return ccysObj;
};

export const getTokenTypes = async (contract: Contract): Promise<any> => {
  const types = await contract.getSecTokenTypes();
  const tokensObj: Record<string, any> = {};
  const tokenTypes = types?.tokenTypes ?? [];
  tokenTypes.forEach((token: Record<string, any>) => {
    const tokenId = hex2int(token.id);
    tokensObj[tokenId] = {
      name: token.name,
      address: token.cashflowBaseAddr ?? '',
    };
  });

  return tokensObj;
};

export const getTokenBatchCount = async (contract: Contract) => {
  const batchCount = await contract.getSecTokenBatch_MaxId;
  return hex2int(batchCount);
};

function getBatchEntityByOriginator(contract: Contract, batch: Record<string, any>) {
  return contract.getAccountEntity(batch.originator).then((entityId: HexValue) => ({
    ...batch,
    entityId: hex2int(entityId),
  }));
}

/**
 * Get token batch by id
 * @param contract
 * @param batchId
 * @returns batch with parsed data.
 */
export const getTokenBatchByBatchId = async (contract: Contract, batchId: number) =>
  contract
    .getSecTokenBatch(batchId)
    .then((batch: Record<string, any>) => parseTokenBatch(decodeWeb3Object(batch) as Record<string, any>))
    .then((batch: Record<string, any>) => parseBatchMetadata(batch))
    .then((batch: Record<string, any>) => getBatchEntityByOriginator(contract, batch));

/**
 * Get token batch by st id
 * @param contract
 * @param stId
 * @returns batch with parsed data
 */
export const getTokenBatchByStId = async (contract: Contract, stId: number) =>
  contract.getSecToken(stId).then((st: Record<string, any>) => getTokenBatchByBatchId(contract, st.batchId));

export const getUnit = async (contract: Contract) => {
  const unit = await contract.unit();
  return unit;
};

export const convertAddressTokens = (tokens: Array<any>) =>
  tokens.map((token) => ({
    ...token,
    batchId: hex2int(token.batchId),
    currentQty: hex2int(token.currentQty),
    stId: convertUnit(token.stId),
    tokTypeId: hex2int(token.tokTypeId),
  }));

export const convertAddressCcys = (currencies: Array<any>) =>
  currencies.map((currency) => ({
    ...currency,
    balance: hex2usd(currency.balance),
    ccyTypeId: hex2int(currency.ccyTypeId),
  }));

/**
 * Get all assets of an account address
 * @param contract
 * @param address
 * @returns address parsed data
 */
export const getAddressData = async (contract: Contract, address: string): Promise<AddressData> => {
  let data = await contract.getLedgerEntry(address);

  if (data) {
    data = decodeWeb3Object(data);
    const allTokens = [...data.tokens];
    const getBatches = allTokens.map(async (token: Record<string, any>) => {
      const id = hex2int(token.batchId);
      return getTokenBatchByBatchId(contract, id);
    });

    const totalMintedQty = hex2int(data.spot_sumQtyMinted);
    const totalBurnedQty = hex2int(data.spot_sumQtyBurned);

    const batches = await Promise.all(getBatches);

    return {
      address,
      account: {
        ...data,
        ...(data.tokens ? { tokens: convertAddressTokens(data.tokens) } : {}),
        ...(data.ccys ? { ccys: convertAddressCcys(data.ccys) } : {}),
      },
      batches,
      totalMintedQty,
      totalBurnedQty,
    };
  }

  return { address, account: {}, batches: [], totalMintedQty: 0, totalBurnedQty: 0 };
};

/**
 * Get all assets of multiple account addresses
 * @param contract
 * @param addresses
 * @returns address parsed data
 */
export const getAddressesData = async (contract: Contract, address: string[]): Promise<AddressData[]> => {
  const dataPromises = address.map((accountAddress) => getAddressData(contract, accountAddress));
  return Promise.all(dataPromises);
};

export const getBalance = async (web3: any, contractAddress: string) => web3.eth.getBalance(contractAddress);

export const getTradeTransferFee = async (contract: Contract, mutationParams: any) =>
  contract.transfer_feePreview_ExchangeOnly(mutationParams);

export default {
  getBalance,
  getAddressData,
  getAddressesData,
  getUnit,
  getTokenBatchByStId,
  getTokenBatchByBatchId,
  getTokenBatchCount,
  getTokenTypes,
  getCurrencies,
  getTradeTransferFee,
};
