import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'refreshed-component/atoms/Button';
import { Card } from 'refreshed-component/atoms/Card';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight, Spacing } from 'refreshed-component/design-system';
import Loading from 'refreshed-component/molecules/Loading';
import { PageHeader } from 'refreshed-component/molecules/PageHeader';
import { Stepper } from 'refreshed-component/molecules/Stepper';
import styled from 'styled-components';

import { ProjectForm, type ProjectFormMetadata, type ProjectFormValue } from '../ProjectForm';
import { SuccessMessage } from '../SuccessMessage';
import { useProjectCreator } from './hooks/useProjectCreator';
import { useProjectFormMetadata } from './hooks/useProjectFormMetadata';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledListNewProject = styled.div`
  padding: 0 var(${Spacing.large});
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
`;

const BorderedCard = styled(Card)`
  border: 1px solid var(${Colors.gray_200});
  flex-direction: column;
  height: auto;
`;

const StepperInsideHeader = styled(Stepper)`
  width: calc(100% + var(${Spacing.large}) * 2);
  padding: var(${Spacing.large});
  margin-inline-start: calc(var(${Spacing.large}) * -1);
  margin-inline-end: calc(var(${Spacing.large}) * -1);
`;

const ProjectFormContainer = styled.div`
  max-width: 820px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-bottom: var(${Spacing.base});
  padding-top: var(${Spacing.base});
`;

const FormButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  padding: 24px;
  border-top: 1px solid var(${Colors.gray_200});
  background-color: var(${Colors.gray_0});
  align-self: flex-end;
`;

const ButtonsSection = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const ProjectFormPage = () => {
  const { projectId = '' } = useParams<{
    projectId: string;
  }>();
  const {
    project,
    formTitle,
    formSteps,
    formValue,
    formErrors,
    activeFormStep,
    isSaveVisible,
    saveButtonLabel,
    isNextButtonVisible,
    isNextButtonLoading,
    isSubmitButtonVisible,
    isSubmitButtonLoading,
    isFormSubmitted,
    isLoading,
    changeFormValue,
    goToNextStep,
    goToStep,
    submitForm,
  } = useProjectCreator(projectId);
  const history = useHistory();

  const { metadata, isLoading: isMetadataLoading } = useProjectFormMetadata(projectId);

  const onChangeProjectForm = (value: ProjectFormValue) => {
    changeFormValue(value);
  };

  const onPressStep = (stepIndex: number) => {
    goToStep(stepIndex);
  };

  const onPressViewProject = () => {
    history.replace(`/account/apx/my-projects/${projectId}`);
  };

  const onPressBack = () => {
    history.goBack();
  };

  if (isLoading || isMetadataLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <StyledListNewProject>
        <PageHeader
          title={formTitle}
          onBack={onPressBack}
          marginVertical="24px"
          subtitle={
            <>
              {!!saveButtonLabel
                ? saveButtonLabel
                : !!project?.updatedAt
                  ? `Last saved ${project.updatedAt}`
                  : !!project?.createdAt
                    ? `Last saved ${project?.createdAt}`
                    : ''}{' '}
            </>
          }
          suffix={
            <ButtonsSection>
              {!isFormSubmitted && <Text weight={FontWeight.medium} color={Colors.gray_600}></Text>}
              {isSubmitButtonVisible && !isFormSubmitted && (
                <Button
                  config={{
                    color: 'primary',
                    size: 'l',
                  }}
                  style={{
                    width: '200px',
                  }}
                  type="submit"
                  isLoading={isSubmitButtonLoading}
                  onClick={() => {
                    submitForm();
                  }}
                >
                  {isSubmitButtonLoading ? 'Submitting...' : 'Submit For Review'}
                </Button>
              )}
            </ButtonsSection>
          }
        />
        <BorderedCard
          header={
            !isFormSubmitted &&
            isSaveVisible && (
              <div className="flex gap-4 items-center border-b">
                <StepperInsideHeader steps={formSteps} onPressStep={onPressStep} />
              </div>
            )
          }
        >
          {isFormSubmitted ? (
            <SuccessMessage onPressViewProject={onPressViewProject} />
          ) : (
            <ProjectFormContainer>
              <ProjectForm
                value={formValue}
                errors={formErrors}
                metadata={metadata as ProjectFormMetadata}
                activeStepIndex={activeFormStep}
                onChange={onChangeProjectForm}
              />
            </ProjectFormContainer>
          )}
        </BorderedCard>
      </StyledListNewProject>
      {!isFormSubmitted && !isSaveVisible && (
        <FormButtons>
          {isNextButtonVisible && !isSubmitButtonVisible && (
            <Button
              config={{
                color: 'primary',
                size: 'l',
              }}
              style={{
                width: '180px',
              }}
              onClick={() => {
                goToNextStep();
              }}
              isLoading={isNextButtonLoading}
            >
              Next
            </Button>
          )}
        </FormButtons>
      )}
    </Container>
  );
};
