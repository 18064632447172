import type { RegistryProvider } from './projectRegistry';

export interface GetAvailableBlocksRequest {
  projectId?: string;
  blockId?: string;
  registryProvider: RegistryProvider;
}

export interface GetAvailableBlocksResponse {
  accountId: string;
  blockId: string;
  serialNumber: string;
  quantity: number;
  projectId?: string;
}

export interface GetAvailableBlockDetailsResponse {
  accountId: string;
  blockId: string;
  serialNumber: string;
  quantity: number;
  projectId?: string;

  // The next fields are specific to SRN registry provider, for future integrations consider using metadata object
  certificateMaxNumber: number | null;
  certificateMinNumber: number | null;
}
