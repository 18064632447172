import { useCallback, useState } from 'react';

import { fetchCarbonMetaOptions } from 'data-provider/carbon/fetchCarbonMetaOptions';

import type { ProjectsListFilteringOptions } from '../components/MyProjects/components/ProjectsList';
import type { CarbonMetaOptionsResponse } from '../utils/CarbonMetaOptionsResponse';
import { toProjectsListFilteringOptions } from '../utils/toProjectsListFilteringOptions';

export const useProjectsListFilteringOptions = () => {
  const [isFetching, setIsFetchingFilteringOptions] = useState(false);
  const [filteringOptions, setFilteringOptions] = useState<ProjectsListFilteringOptions>({
    sectoralScopes: [],
    projectTypes: [],
    ccbStandards: [],
    statuses: [],
  });

  const fetchFilteringOptions = useCallback(async () => {
    setIsFetchingFilteringOptions(true);
    try {
      const response = await fetchCarbonMetaOptions();
      const metaOption = response?.data;

      setFilteringOptions(toProjectsListFilteringOptions(metaOption as CarbonMetaOptionsResponse));
    } catch {}

    setIsFetchingFilteringOptions(false);
  }, []);

  return {
    fetchFilteringOptions,
    filteringOptions,
    isFetching,
  };
};
