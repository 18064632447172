import { styled, toSpacing } from '@aircarbon/ui';

export const FieldsGroup = styled.div<{
  /**
   * Minimum with of a single column
   */
  columnMinWidth?: string;
}>`
  display: grid;
  gap: ${({ theme }) => toSpacing(theme)(8)};
  align-items: flex-start;
  grid-template-columns: repeat(auto-fit, minmax(${({ columnMinWidth }) => columnMinWidth || '10rem'}, 1fr));
`;
