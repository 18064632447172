import { forwardRef, useImperativeHandle, useState } from 'react';
import { useOrderSummary } from 'refreshed-pages/market-board-v2/hooks/useOrderSummary';

import {
  Alert,
  AlertColor,
  CMBOrderType,
  Card,
  Layer,
  Modal,
  Text,
  TextAs,
  TextColor,
  TextDecoration,
  TypographyVariant,
  styled,
  toSpacing,
  useSpacing,
} from '@aircarbon/ui';

import { useEntity } from 'state/entity';

import { SummaryItem } from '../ReviewOrderModal/components/SummaryItem';

export interface AcceptTradeRequestModalProps {
  isSubmitting: boolean;
  onConfirm(): void;
}

export interface AcceptTradeRequestModalData {
  summary: Array<{
    label: string;
    value: string;
    valueColor?: TextColor;
  }>;
  quantity: number;
  openQty: number;
  price: number;
  orderId: string;
  orderType: CMBOrderType;
  isMaker: boolean;
}

export interface AcceptTradeRequestModalRef {
  /**
   * Show modal on screen
   */
  show(props: AcceptTradeRequestModalData): void;

  /**
   * Hide modal
   */
  hide(): void;
}

const quantityFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const AcceptTradeRequestModal = forwardRef<AcceptTradeRequestModalRef, AcceptTradeRequestModalProps>(
  (props, ref) => {
    const { onConfirm, isSubmitting } = props;
    const [isVisible, setIsVisible] = useState(false);
    const [reviewOrderModalData, setReviewOrderModalData] = useState<AcceptTradeRequestModalData>({
      summary: [],
      quantity: 0,
      openQty: 0,
      price: 0,
      orderId: '',
      orderType: CMBOrderType.Bid,
      isMaker: false,
    });
    const { spacing } = useSpacing();
    const { selector } = useEntity();

    const currencyCode = selector.mainCcySymbol;

    const finalQuantity =
      reviewOrderModalData.openQty < reviewOrderModalData.quantity
        ? reviewOrderModalData.openQty
        : reviewOrderModalData.quantity;

    const { estimatedTotal, fee, isCalculatingFee } = useOrderSummary({
      orderType: reviewOrderModalData.orderType,
      price: reviewOrderModalData.price,
      quantity: finalQuantity,
    });

    const priceFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
    });

    useImperativeHandle(ref, () => ({
      show(props: AcceptTradeRequestModalData) {
        setReviewOrderModalData(props);
        setIsVisible(true);
      },
      hide() {
        setIsVisible(false);
      },
    }));

    const total = priceFormatter.format(estimatedTotal);
    const formattedFee = priceFormatter.format(fee);
    const formattedPrice = priceFormatter.format(reviewOrderModalData.price);
    const formattedQuantity = quantityFormatter.format(reviewOrderModalData.quantity);

    const isQuantityGreaterThanOpenQty = reviewOrderModalData.openQty < reviewOrderModalData.quantity;

    const onClose = () => {
      if (isSubmitting) {
        return;
      }
      setIsVisible(false);
    };

    const onPressConfirm = () => {
      onConfirm();
    };

    return (
      <Modal
        title="Accept Trade Request"
        isVisible={isVisible}
        onClose={onClose}
        onPressNegativeButton={onClose}
        onPressPositiveButton={onPressConfirm}
        isPositiveButtonLoading={isSubmitting}
        isPositiveButtonDisabled={
          isCalculatingFee ||
          (isQuantityGreaterThanOpenQty && reviewOrderModalData.isMaker) ||
          reviewOrderModalData.openQty === 0
        }
      >
        <Text variant={TypographyVariant.subtitle1}>Summary</Text>

        {reviewOrderModalData.summary.map((summaryItem) => (
          <SummaryItem
            label={summaryItem.label}
            value={summaryItem.value}
            valueColor={summaryItem.valueColor}
            marginTop={spacing(4)}
          />
        ))}

        {reviewOrderModalData.openQty < reviewOrderModalData.quantity && (
          <Alert
            color={AlertColor.Warning}
            title={
              reviewOrderModalData.openQty > 0
                ? `${quantityFormatter.format(reviewOrderModalData.openQty)} open for filling.`
                : `Order #${reviewOrderModalData.orderId} is already filled.`
            }
            description={
              reviewOrderModalData.isMaker
                ? reviewOrderModalData.openQty > 0
                  ? 'Please counter offer with the open quantity.'
                  : 'Please reject this request.'
                : reviewOrderModalData.openQty > 0
                  ? 'You can only accept the open quantity.'
                  : 'Please close this request.'
            }
            marginTop={spacing(4)}
          />
        )}

        <Layer>
          <StyledQuantityAndPriceCard>
            <PriceItem>
              <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                Quantity
              </Text>
              {reviewOrderModalData.openQty < reviewOrderModalData.quantity ? (
                <div className="flex gap-2">
                  <Text variant={TypographyVariant.body2}>
                    {quantityFormatter.format(reviewOrderModalData.openQty)}{' '}
                  </Text>
                  <Text
                    variant={TypographyVariant.body2}
                    color={TextColor.secondary}
                    as={TextAs.span}
                    decoration={TextDecoration.LineThrough}
                  >
                    {formattedQuantity}
                  </Text>
                </div>
              ) : (
                <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                  {formattedQuantity}
                </Text>
              )}
            </PriceItem>
            <PriceItem>
              <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                Price
              </Text>
              <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                {formattedPrice}
              </Text>
            </PriceItem>
            <PriceItem>
              <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                Fee
              </Text>
              <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                {isCalculatingFee ? 'Calculating...' : formattedFee}
              </Text>
            </PriceItem>
            <PriceItem>
              <Text variant={TypographyVariant.subtitle2}>Total</Text>
              <Text variant={TypographyVariant.subtitle2}>{isCalculatingFee ? 'Calculating...' : total}</Text>
            </PriceItem>
          </StyledQuantityAndPriceCard>
        </Layer>
      </Modal>
    );
  },
);

const PriceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledQuantityAndPriceCard = styled(Card)`
  padding: ${({ theme }) => toSpacing(theme)(8)};
  gap: ${({ theme }) => toSpacing(theme)(4)};
  margin-top: ${({ theme }) => toSpacing(theme)(4)};
`;
