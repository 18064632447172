// eslint-disable-next-line no-shadow
export enum TransferType {
  ExchangeFee = 'EXCHANGE_FEE',
  OriginatorFee = 'ORIGINATOR_FEE',
  OnboardingFee = 'ONBOARDING_FEE',
  MintFee = 'MINT_FEE',
  BurnFee = 'BURN_FEE',
  WithdrawFee = 'WITHDRAW_FEE',
  DepositFee = 'DEPOSIT_FEE',
  DataFee = 'DATA_FEE',
  Withdraw = 'WITHDRAW',
  Retirement = 'RETIREMENT',
  Rebate = 'REBATE',
  PhysicalDelivery = 'PHYSICAL_DELIVERY',
  UserRelatedTransfer = 'RELATED_TRANSFER',
  Adjustment = 'ADJUSTMENT',
  MinimumBalanceFee = 'MIN_BALANCE_FEE',
  NoMovementFee = 'NO_MOVEMENT_FEE',
}

// eslint-disable-next-line no-shadow
export enum TransferStatus {
  Executed = 'executed',
  Processing = 'processing',
  PedingApproval = 'pending approval',
  Failed = 'failed',
}
