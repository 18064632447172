import { CSSProperties, useEffect } from 'react';
import { useState } from 'react';
import { Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

const Wrapper = styled.div<{ height?: 'fixed' | 'auto' }>`
  position: ${(props) => (props.height === 'auto' ? 'relative' : 'absolute')};
  width: 100%;
  height: ${(props) => (props.height === 'auto' ? 'auto' : '100%')};
  background-color: transparent;
`;

const Title = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  background-color: transparent;
  padding: 16px;
  gap: 16px;
  padding-bottom: 0px;
`;

const Tab = styled.div<{ defaultColor: string; activeColor: string }>`
  position: relative;
  display: flex;
  width: auto;
  flex-direction: column;
  color: ${(props) => props.defaultColor};
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  transition: color 0.2s;
  &.selected {
    color: ${(props) => props.activeColor};
    font-weight: bold;
    background-color: transparent;
  }
  &:hover {
    color: ${(props) => props.activeColor};
  }
`;

const Body = styled.div<{ height?: 'fixed' | 'auto' }>`
  position: relative;
  width: 100%;
  height: ${(props) => (props.height === 'auto' ? 'auto' : 'calc(100% - 31px)')};
  color: #fff;
  padding: 16px;
  padding-top: 10px;
  box-sizing: border-box;
`;

export const Search = styled.input<{
  afterHeader?: boolean;
  backgroundColor: string;
  borderColor: string;
  color: string;
}>`
  position: ${(props) => (props.afterHeader ? `relative` : `absolute`)};
  ${(props) => (props.afterHeader ? `margin-left: 23px; margin-top: 4px;` : ``)}
  width: 150px;
  height: 28px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  box-sizing: border-box;
  top: 8px;
  right: 8px;
  outline: none;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  &:focus {
    outline: none;
  }
`;

const Panel = (props: {
  height?: 'fixed' | 'auto';
  selected: string;
  contentBodyStyle?: CSSProperties;
  contentAfterHeader?: JSX.Element;
  list: {
    title: string;
    content?: React.ReactNode;
    style?: CSSProperties;
    _key?: string;
  }[];
  onChange?: (selected: string) => void;
}) => {
  const [selected, setSelected] = useState<string>(props.selected);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => (props.selected !== selected ? setSelected(props.selected) : undefined), [props.selected]);
  return (
    <Wrapper height={props.height}>
      <Title>
        {props.list.map((tab, index) => {
          return (
            <Tab
              key={`${tab._key || tab.title}${index}`}
              onClick={() => {
                setSelected(tab.title);
                props.onChange?.(tab.title);
              }}
              className={`${selected === tab.title ? 'selected' : ''}`}
              defaultColor={`var(${Colors.gray_500})`}
              activeColor={`var(${Colors.gray_900})`}
            >
              {tab.title}
            </Tab>
          );
        })}
      </Title>
      {props.contentAfterHeader && (
        <div
          style={{
            paddingLeft: '16px',
            paddingRight: '16px',
            fontSize: '12px',
          }}
          className="flex flex-row w-full"
        >
          {props.contentAfterHeader}
        </div>
      )}
      <Body height={props.height} style={props.contentBodyStyle}>
        {props.list.map((tab, index) => {
          return (
            <div
              key={`${tab._key || tab.title}${index}`}
              className={`relative w-full h-full ${selected === tab.title ? `` : 'hidden'}`}
              style={tab.style}
            >
              {tab.content}
            </div>
          );
        })}
      </Body>
    </Wrapper>
  );
};

export default Panel;
