import type { ApxProject } from '@aircarbon/utils-common/src/dto';

export interface Option {
  id: number;
  label: string;
  contracts: Array<{
    id: number;
    label: string;
    vintages: Array<{
      id: number;
      label: string;
    }>;
  }>;
}

interface ToOptionsProps {
  projects: ApxProject[];
  pairIds: Array<number>;
}

export const toOptions = (props: ToOptionsProps): Option[] => {
  return props.projects.map((project) => {
    const contracts: Option['contracts'] = [];

    project.vintages?.forEach((vintage) => {
      vintage.fctAsset?.basePairs?.forEach((pair) => {
        if (props.pairIds.includes(pair.id)) {
          const contract = {
            id: pair.id,
            label: pair.name,
            vintages: [
              {
                id: vintage.vintageYear,
                label: String(vintage.vintageYear),
              },
            ],
          };
          contracts.push(contract);
        }
      });
    });

    return {
      label: project.name,
      id: project.id,
      contracts,
    };
  });
};
