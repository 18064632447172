import { useFilters } from 'refreshed-pages/market-board-v2/hooks/useFilters';

import { Text, Badge, BadgeSize, styled, toSpacing, TypographyVariant, TextColor } from '@aircarbon/ui';

export const FilterBadges = () => {
  const { dynamicFilters, updateDynamicFilters, staticFilters, setPrice, setVintageYear, toggleInstantTrade } =
    useFilters();

  if (
    !(
      dynamicFilters.length ||
      staticFilters.price.from ||
      staticFilters.price.to ||
      staticFilters.vintageYear ||
      staticFilters.isInstantTrade
    )
  ) {
    return null;
  }

  return (
    <StyledFilterBadges>
      {dynamicFilters.map(
        (filter) =>
          !!filter.options?.length && (
            <Badge
              size={BadgeSize.s}
              prefix={
                <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
                  {filter.label}:
                </Text>
              }
              value={filter.options?.map((filterOption) => filterOption.label).join(',')}
              onRemove={() => {
                updateDynamicFilters({
                  label: filter.label,
                  value: filter.value,
                });
              }}
            />
          ),
      )}
      {!!(staticFilters.price.from || staticFilters.price.to) && (
        <Badge
          prefix={
            <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
              Price:
            </Text>
          }
          size={BadgeSize.s}
          value={
            staticFilters.price.from && staticFilters.price.to
              ? `from ${staticFilters.price.from} to ${staticFilters.price.to}`
              : staticFilters.price.from
                ? 'from ' + staticFilters.price.from
                : 'to ' + staticFilters.price.to
          }
          onRemove={() => {
            setPrice({
              from: 0,
              to: 0,
            });
          }}
        />
      )}
      {!!staticFilters.vintageYear && (
        <Badge
          prefix={
            <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
              Vintage Year:
            </Text>
          }
          size={BadgeSize.s}
          value={staticFilters.vintageYear?.split(',').join(', ')}
          onRemove={() => {
            setVintageYear('');
          }}
        />
      )}
      {staticFilters.isInstantTrade && (
        <Badge
          size={BadgeSize.s}
          value={'Only with Assets Reserved'}
          onRemove={() => {
            toggleInstantTrade(false);
          }}
        />
      )}
    </StyledFilterBadges>
  );
};

const StyledFilterBadges = styled.div`
  display: flex;
  margin: 0 ${({ theme }) => toSpacing(theme)(8)};
  margin-top: ${({ theme }) => toSpacing(theme)(6)};
  gap: ${({ theme }) => toSpacing(theme)(4)};
  flex-wrap: wrap;
`;
