import { userAuthenticatedApi } from '../fetcher';

type PositionResponse = {
  openPositions: any[];
  realizedPnl: any[];
};
export const fetchPositions = async (accountAddress: string) => {
  const endpoint = '/user/positions';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ accountAddress }).get();
  return res.json<PositionResponse>();
};
