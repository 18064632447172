import { useEffect } from 'react';
import styled from 'styled-components';

import { Entity } from 'state/entity';
import { logout } from 'state/user';

const Wrapper = styled.div`
  max-width: 600px;
  margin: 3rem auto;
  text-align: center;
  line-height: 1.6;
  font-size: 18px;
`;
const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 1rem;
`;

type Props = {
  title?: string;
  message?: string;
};

const SystemOffline: React.FC<Props> = ({ title, message }) => {
  const { entity } = Entity.useContainer();
  useEffect(() => {
    logout();
  }, []);
  return (
    <Wrapper>
      <Title>{title || 'System Upgrade in Progress'}</Title>
      <div className="mb-6">{message || 'The trading platform is not available at the moment.'}</div>
      <div>Thank you for your understanding. </div>
      <div>The {entity.name} Team</div>
    </Wrapper>
  );
};

export default SystemOffline;
