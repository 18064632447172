import { Button } from 'refreshed-component/atoms/Button';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

const StyledSuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;
`;

const Check = styled.div`
  width: 87px;
  height: 87px;
  border-radius: 8px;
  color: var(${Colors.gray_0});
  background-color: var(${Colors.secondaryDefault});
`;

const StyledTitleText = styled(Text)`
  margin-top: var(${Spacing.xl});
  letter-spacing: 0.48px;
`;

const StyledDescriptionText = styled(Text)`
  margin-top: var(${Spacing.xs});
`;

export const SuccessMessage: React.FC<{
  onPressViewProject(): void;
}> = ({ onPressViewProject }) => {
  return (
    <StyledSuccessMessage>
      <Check>
        <Icon width={87} height={87} type={IconType.Check} />
      </Check>
      <StyledTitleText size={FontSize._2Xl} weight={FontWeight.bold}>
        Your Project is Submitted Successfully!
      </StyledTitleText>
      <StyledDescriptionText color={Colors.gray_500}>
        Our support team will get in touch with you as soon as possible
      </StyledDescriptionText>
      <StyledViewProjectButton
        config={{
          color: 'secondary',
        }}
        onClick={onPressViewProject}
      >
        View Project
      </StyledViewProjectButton>
    </StyledSuccessMessage>
  );
};

const StyledViewProjectButton = styled(Button)`
  margin-top: 32px;
  min-width: 240px;
`;
