import { ApolloClient, InMemoryCache, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import ws from 'isomorphic-ws';
import { useMemo } from 'react';

function createApolloClient() {
  const url = String(window.location.origin);
  const wssUrl = url.includes('https') ? url.replace('https', 'wss') : url.replace('http', 'ws');
  const wsLink = new WebSocketLink({
    uri: `${wssUrl}/graphql`,
    options: {
      reconnect: true,
    },
    webSocketImpl: ws,
  });

  const splitLink = split(({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  }, wsLink);

  return new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });
}

export function initializeApollo() {
  const client = createApolloClient();

  return client;
}

export function useApollo() {
  const store = useMemo(() => initializeApollo(), []);
  return store;
}
