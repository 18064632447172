import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

export const PeriodInformation: React.FC<{
  label: string;
  value?: string;
  className?: string;
}> = (props) => {
  const { label, value, className } = props;

  return (
    <StyledPeriodInformation className={className}>
      <Text size={FontSize.small} color={Colors.gray_500} weight={FontWeight.medium}>
        {label}
      </Text>
      <StyledPeriodValueText size={FontSize._2Xl} weight={FontWeight.semibold}>
        {value}
      </StyledPeriodValueText>
    </StyledPeriodInformation>
  );
};

const StyledPeriodInformation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border-bottom: 1px solid var(${Colors.gray_200});
  width: 100%;
`;

const StyledPeriodValueText = styled(Text)`
  line-height: 36px;
`;
