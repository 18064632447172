import { Popover as PopoverAntD } from 'antd';
import 'antd/lib/popover/style/index.css';
import { ReactNode } from 'react';
import type { Colors } from 'refreshed-component/design-system';

import { Icon, IconType } from '../Icon';
import styles from './index.module.scss';

export const Tooltip: React.FC<{
  text?: string | React.ReactNode;
  filled?: true;
  width?: number | 'auto';
  height?: number | 'auto';
  contentWidth?: 'small' | 'medium' | 'large';
  color?: Colors;
  icon?: boolean;
  visible?: boolean;
}> = ({ children, text, width, height, contentWidth = 'medium', color, icon = true, visible }) => {
  const contentWidthMap = {
    small: 200,
    medium: 300,
    large: 400,
  };

  const content = (
    <div className="p-1" style={{ maxWidth: contentWidthMap[contentWidth] }}>
      {text}
    </div>
  );
  return (
    <PopoverAntD
      visible={visible}
      className={styles.toolTipBody}
      overlayClassName={styles.toolTipContent}
      content={content}
    >
      {children ||
        (icon && <Icon color={color} type={IconType.InformationCircle} width={width || 14} height={height || 14} />)}
    </PopoverAntD>
  );
};
