import { ProjectStatus } from './ProjectStatus';

export const toProjectStatus = (code: string) => {
  const codeToStatusMap: Record<string, ProjectStatus> = {
    draft: ProjectStatus.Draft,
    rejected: ProjectStatus.Rejected,
    submitted: ProjectStatus.Submitted,
    active: ProjectStatus.Active,
  };

  return codeToStatusMap[code] || ProjectStatus.Draft;
};
