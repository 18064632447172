import Big from 'big.js';
import React, { useEffect, useRef, useState } from 'react';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { helpers } from '@aircarbon/utils-common';

import { clearSelection } from 'utils/helpers';

import { Themes } from './Themes';

const Wrapper = styled.div`
  width: 100%;
  cursor: pointer;
  height: auto;
  position: relative;
  padding-left: 26px;
  padding-right: 26px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const Stick = styled.div<{ backgroundColor: string; borderColor: string }>`
  cursor: pointer;
  height: 9px;
  background-color: var(${Colors.gray_200});
  top: 50%;
  border-radius: 10px;
  width: 100%;
  margin-top: 14px;
  margin-bottom: 30px;
  position: relative;
`;

const Point = styled.div<{ backgroundColor: Colors; borderColor: Colors }>`
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-color: var(${({ backgroundColor }) => backgroundColor});
  transform: translateY(-4px);
  border-radius: 10px;
  border: 2px solid var(${({ borderColor }) => borderColor});
  margin-left: -9px;
  margin-right: -9px;
  font-weight: normal;
  &.important {
    font-weight: bold;
  }
`;

const Label = styled.div`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: calc(100% + 6px);
  white-space: pre;
  color: ${Colors.gray_500};
  pointer-events: none;
  font-size: var(${FontSize.small});
  font-weight: var(${FontWeight.medium});
`;

const Button = styled.div`
  cursor: pointer;
  width: 22px;
  height: 22px;
  transform: translateY(-50%) translateX(-50%);
  border-radius: 22px;
  position: absolute;
  top: 4px;
  border-radius: 30px;
  background-color: var(${Colors.gray_0});
  border: 1px solid var(${Colors.gray_900});
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
`;

const Fill = styled.div<{ side: 'Buy' | 'Sell' }>`
  cursor: pointer;
  height: 80%;
  background: var(${Colors.gray_900});
  left: 0;
  position: absolute;
  border-radius: 6px;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
`;

type Item = {
  label: string;
  value?: number;
};

type Props = {
  style?: React.CSSProperties;
  selectedValue?: number;
  list: Item[];
  onChange?: (value: number) => void;
  divider?: number;
  showDivider?: boolean;
  side: 'Buy' | 'Sell';
  layout?: string;
  valueIncrement?: number;
};

// TODO: destruct props variables
const Slider = ({ divider = 4, side = 'Buy', ...props }: Props) => {
  const { theme } = Themes.useContainer();
  const getPercentageFromDiff = (value: number) => {
    const lastValue = props.list[props.list.length - 1]?.value ?? 0;
    const firstValue = props.list[0]?.value ?? 0;
    if (value === firstValue) return 0;
    if (value === lastValue) return 100;
    const diff = lastValue - firstValue;
    return (100 / diff) * (value - firstValue);
  };

  function getValueAndPercentageBasedOnIncrement(percentage: number, increment: number) {
    let value = getDiffFromPercentage(percentage);
    let adjustedPercentage = percentage;

    const mod = +helpers.fmod(Big(value), Big(increment));
    if (mod !== 0) {
      const closestLeft = value - mod;
      const closestRight = closestLeft + increment;
      value = mod < increment / 2 ? closestLeft : closestRight;

      adjustedPercentage = getPercentageFromDiff(value);
    }

    const lastValue = props.list[props.list.length - 1]?.value ?? 0;
    const firstValue = props.list[0]?.value ?? 0;

    if (adjustedPercentage < 0) return { adjustedValue: firstValue, adjustedPercentage: 0 };
    if (adjustedPercentage > 100) return { adjustedValue: lastValue, adjustedPercentage: 100 };

    return { adjustedValue: value, adjustedPercentage };
  }

  const { valueIncrement } = props;
  const percentage = getPercentageFromDiff(props.selectedValue || 0);
  const buttonRef = useRef<HTMLDivElement>(null);
  const stickRef = useRef<HTMLDivElement>(null);
  const fillRef = useRef<HTMLDivElement>(null);
  const [touched, setTouched] = useState<boolean>(false);

  const getDiffFromPercentage = (percentage: number) => {
    const lastValue = props.list[props.list.length - 1]?.value ?? 0;
    const firstValue = props.list[0]?.value ?? 0;
    if (percentage === 0) return firstValue;
    if (percentage === 100) return lastValue;
    const diff = lastValue - firstValue;
    if (diff === 0) return 0;
    return firstValue + (diff / 100) * percentage;
  };

  const findPoint = (left: number, precised?: boolean) => {
    const stickElm = stickRef.current;
    const buttonElm = buttonRef.current;
    if (stickElm) {
      const percentage = calculatePercentage(left, stickElm.clientWidth, precised);

      const { adjustedValue, adjustedPercentage } = valueIncrement
        ? getValueAndPercentageBasedOnIncrement(percentage, valueIncrement)
        : { adjustedValue: getDiffFromPercentage(percentage), adjustedPercentage: percentage };

      if (buttonElm) {
        if (adjustedPercentage < 0 || adjustedPercentage > 100) {
          buttonElm.style.display = `none`;
        } else {
          buttonElm.style.display = `block`;
        }
        buttonElm.style.left = `${adjustedPercentage}%`;
      }
      if (props.onChange) props.onChange(adjustedValue);
    }
  };

  const calculatePercentage = (left: number, stickElementWidth: number, precised?: boolean) => {
    let percentage = 0;
    if (precised) {
      const pointes = (props.list.length - 1) * divider;
      const pointeWidth = stickElementWidth / pointes;
      const point = Math.round(left / pointeWidth);
      percentage = (100 / pointes) * point || 0;
    } else {
      percentage = (100 / stickElementWidth) * left || 0;
      percentage = percentage < 0 ? 0 : percentage > 100 ? 100 : percentage;
    }

    return percentage;
  };

  useEffect(() => {
    const limitedPercentage = percentage < 0 ? 0 : percentage > 100 ? 100 : percentage;
    if (buttonRef.current) {
      if (percentage < 0 || percentage > 100) {
        buttonRef.current.style.display = `none`;
      } else {
        buttonRef.current.style.display = `block`;
      }
      buttonRef.current.style.left = `${limitedPercentage}%`;
    }
    if (fillRef.current) fillRef.current.style.width = `${limitedPercentage}%`;
  }, [props.selectedValue, percentage]);

  const drawDivider = () => {
    const list = [];
    const count = divider;
    for (let index = 0; index < count; index++) {
      list.push(
        <React.Fragment key={`slider-divider-${index}`}>
          <div className="flex-1 h-full" />
        </React.Fragment>,
      );
    }
    return list;
  };

  const mouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (touched) {
      setTouched(false);
      return;
    }
    const stickElm = stickRef.current;
    let left = 0;
    if (stickElm) {
      left = event.clientX - stickElm.getBoundingClientRect().left;
      const isPoint = event.target instanceof HTMLDivElement ? event.target : undefined;
      findPoint(left, isPoint?.classList.contains('point'));
    }
    if (stickElm) {
      const stickWidth = stickElm.clientWidth;
      let buttonOffset = 0;

      let startX = event.clientX;
      let diffX = 0;
      let moveLock = true;

      const onStart = () => {
        buttonOffset = buttonRef.current?.offsetLeft ?? 0;
        document.body.classList.add('disable-select');
        document.body.classList.add('cursor-pointer');
        document.body.classList.add('children-pointer-events-none');
      };

      const onMove = () => {
        left = buttonOffset + diffX;
        left = left <= 0 ? 0 : left >= stickWidth ? stickWidth : left;

        const percentage = calculatePercentage(left, stickElm.clientWidth);
        const { adjustedPercentage } = valueIncrement
          ? getValueAndPercentageBasedOnIncrement(percentage, valueIncrement)
          : { adjustedPercentage: percentage };

        const limitedPercentage = adjustedPercentage < 0 ? 0 : adjustedPercentage > 100 ? 100 : adjustedPercentage;
        if (buttonRef.current) {
          if (adjustedPercentage < 0 || adjustedPercentage > 100) {
            buttonRef.current.style.display = `none`;
          } else {
            buttonRef.current.style.display = `block`;
          }
          buttonRef.current.style.left = `${limitedPercentage}%`;
        }
        if (fillRef.current) fillRef.current.style.width = `${limitedPercentage}%`;
        findPoint(left);
      };

      const onWindowMove = (event: { clientX: number }) => {
        if (moveLock && !(startX - 5 < event.clientX && startX + 5 > event.clientX)) {
          moveLock = false;
          onStart();
        }
        if (!moveLock) {
          diffX = event.clientX - startX;
          onMove();
        }
      };

      const onWindowUp = () => {
        if (!moveLock) findPoint(left);
        document.body.classList.remove('disable-select');
        document.body.classList.remove('cursor-pointer');
        document.body.classList.remove('children-pointer-events-none');
        window.removeEventListener('mousemove', onWindowMove);
        window.removeEventListener('mouseup', onWindowUp);
      };

      window.addEventListener('mousemove', onWindowMove);
      window.addEventListener('mouseup', onWindowUp);
    }
  };

  const touchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    clearSelection();
    setTouched(true);

    document.body.classList.add('disable-select');
    document.body.classList.add('disable-tap-highlight');
    document.body.classList.add('cursor-pointer');
    document.body.classList.add('children-pointer-events-none');
    event.stopPropagation();
    if (event.touches.length > 1) return;
    const touch = event.touches[0];
    const stickElm = stickRef.current;
    let left = 0;
    if (stickElm) {
      left = touch.clientX - stickElm.getBoundingClientRect().left;
      const isPoint = event.target instanceof HTMLDivElement ? event.target : undefined;
      findPoint(left, isPoint?.classList.contains('point'));
    }

    if (stickElm) {
      const stickWidth = stickElm.clientWidth;
      let buttonOffset = 0;

      let startX = touch.clientX;
      let diffX = 0;
      let moveLock = true;

      const onStart = () => {
        buttonOffset = buttonRef.current?.offsetLeft ?? 0;
      };

      const onMove = () => {
        left = buttonOffset + diffX;
        left = left <= 0 ? 0 : left >= stickWidth ? stickWidth : left;

        const percentage = calculatePercentage(left, stickElm.clientWidth);
        const { adjustedPercentage } = valueIncrement
          ? getValueAndPercentageBasedOnIncrement(percentage, valueIncrement)
          : { adjustedPercentage: percentage };

        const limitedPercentage = adjustedPercentage < 0 ? 0 : adjustedPercentage > 100 ? 100 : adjustedPercentage;
        if (buttonRef.current) {
          if (adjustedPercentage < 0 || adjustedPercentage > 100) {
            buttonRef.current.style.display = `none`;
          } else {
            buttonRef.current.style.display = `block`;
          }
          buttonRef.current.style.left = `${limitedPercentage}%`;
        }
        if (fillRef.current) fillRef.current.style.width = `${limitedPercentage}%`;
        findPoint(left);
      };

      const onWindowMove = (event: TouchEvent) => {
        event.stopPropagation();
        const touch = event.touches[0];
        if (moveLock && !(startX - 5 < touch.clientX && startX + 5 > touch.clientX)) {
          moveLock = false;
          onStart();
        }
        if (!moveLock) {
          diffX = touch.clientX - startX;
          onMove();
        }
      };

      const onWindowUp = () => {
        if (!moveLock) findPoint(left);
        document.body.classList.remove('disable-select');
        document.body.classList.remove('disable-tap-highlight');
        document.body.classList.remove('cursor-pointer');
        document.body.classList.remove('children-pointer-events-none');
        window.removeEventListener('touchmove', onWindowMove);
        window.removeEventListener('touchend', onWindowUp);
      };

      window.addEventListener('touchmove', onWindowMove);
      window.addEventListener('touchend', onWindowUp);
    }
  };
  const inRange = !(percentage < 0 && !(percentage > 100));
  return (
    <Wrapper style={props.style}>
      <Stick
        backgroundColor={theme.placeHolder.input.background}
        borderColor={theme.placeHolder.input.border}
        className="flex flex-row"
        ref={stickRef}
        onTouchStart={touchStart}
        onMouseDown={mouseDown}
      >
        {props.list.map((item, index, list) => {
          const pointValue = index * (100 / (list.length - 1));
          return (
            <React.Fragment key={`slide-point-${index}`}>
              <Point
                backgroundColor={inRange && percentage >= pointValue ? Colors.gray_900 : Colors.gray_0}
                borderColor={inRange && percentage >= pointValue ? Colors.gray_900 : Colors.gray_200}
                className={'point'}
              >
                <Label
                  style={{
                    color: `var(${Colors.gray_500})`,
                  }}
                >
                  {item.label}
                </Label>
              </Point>
              {list.length !== index + 1 && (
                <div className="flex flex-row flex-1 items-center">{props.showDivider && drawDivider()}</div>
              )}
            </React.Fragment>
          );
        })}
        {inRange && (
          <>
            <Fill ref={fillRef} side={side} />
            <Button ref={buttonRef} onMouseDown={mouseDown} />
          </>
        )}
      </Stick>
    </Wrapper>
  );
};
export default Slider;
