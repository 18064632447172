/* eslint-disable no-underscore-dangle */

/* eslint-disable camelcase */
import { Page, Text, View, Document, StyleSheet, renderToStream, renderToString, Image } from '@react-pdf/renderer';
import _ from 'lodash';
import React from 'react';

import { formatNumber } from '../../../formatter';
import { logoImage } from './images';

type TransactionDetails = {
  name: string;
  userId: number | string;
  accountAddress: string;
  action: string;
  token: string;
  total: number;
  quantity: number;
  txConfirmDate: Date | string;
  txHash: string;
  tradeId: number | string;
  orderId?: number | string; // Spot Trade Details
  orderType?: string; // Spot Trade Details
  timeInForce?: string; // Spot Trade Details
  pair: string;
  side: string;
  unitPrice: number;
  fee: number;
  tokenUom: string;
  ccy: string;
  numDecimals: number;
  ccyCode: string;
  orderQty: number; // Spot Trade Details
  orderUnitPrice: number; // Spot Trade Details
  offerId?: string; // CMB & Auction Details
  bidId?: string; // CMB & Auction Details
  projectName?: string; // CMB & Auction Details
  vintage?: string; // CMB & Auction Details
  registryName?: string; // CMB & Auction Details
  registryProjectId?: string; // CMB & Auction Details
  isAuction?: boolean; // CMB & Auction Details
};
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 30,
  },
  image: {
    width: 100,
    height: 80,
    alignSelf: 'center',
    marginBottom: 10,
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 20,
  },
  header: {
    fontSize: 14,
    marginTop: 8,
    marginBottom: 8,
    fontWeight: 'bold',
  },
  contentTitle: {
    fontSize: 12,
    marginBottom: 4,
    fontWeight: 'bold',
  },
  contentText: {
    fontSize: 10,
    marginBottom: 4,
  },
  tradeDoneTitle: {
    fontSize: 16,
    marginTop: 8,
    marginBottom: 4,
    fontWeight: 'bold',
    borderStyle: 'solid',
    borderWidth: 1,
    alignContent: 'center',
    textAlign: 'center',
  },
  table: {
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
    alignContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  tableColHeader: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: '#F3F3F3',
    textAlign: 'center',
    padding: 5,
    flexWrap: 'wrap',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: 'center',
    flexWrap: 'wrap',
    padding: 5,
  },
  tableColHeader2: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 8,
    backgroundColor: '#F3F3F3',
    flexWrap: 'wrap',
    textAlign: 'left',
  },
  tableCol2: {
    width: '75%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 8,
    flexWrap: 'wrap',
    textAlign: 'left',
  },
  tableCellHeader: {
    fontSize: 12,
  },
  tableCell: {
    fontSize: 10,
  },
  tableCellHeader2: {
    fontSize: 10,
  },
  tableCell2: {
    fontSize: 10,
  },
});

const generateIdxTradeReceipt = async (transactionDetails: TransactionDetails) => {
  const {
    name,
    userId,
    accountAddress,
    action,
    token,
    total,
    quantity,
    txConfirmDate,
    txHash,
    tradeId,
    orderId,
    orderType,
    timeInForce,
    pair,
    side,
    unitPrice,
    fee,
    tokenUom,
    ccy,
    numDecimals,
    ccyCode,
    orderQty,
    orderUnitPrice,
    offerId,
    bidId,
    projectName,
    vintage,
    registryName,
    registryProjectId,
    isAuction,
  } = transactionDetails;

  const formatDate = (date: Date | string): string => {
    const dateStr = new Date(date).toLocaleString(undefined, {
      hour12: true,
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });
    return _.replace(dateStr, ',', '');
  };
  // eslint-disable-next-line react/function-component-definition
  const MyDocument = () => (
    // eslint-disable-next-line react/jsx-filename-extension
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.image} src={logoImage} />
        <Text style={styles.title}>Trade Transaction Report</Text>
        <Text style={styles.header}>Account Information</Text>
        <View style={styles.tableRow}>
          <Text style={styles.contentTitle}>Trader Name: </Text>
          <Text style={styles.contentText}>
            {name} (User ID {userId})
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.contentTitle}>Account Address: </Text>
          <Text style={styles.contentText}>{accountAddress}</Text>
        </View>
        <View style={styles.tradeDoneTitle}>
          <Text style={styles.header}>Trade Transaction Done</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Action</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Asset</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Price</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Quantity</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{action}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{token}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {ccyCode}
                {formatNumber(unitPrice, numDecimals)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{formatNumber(quantity, 0)}</Text>
            </View>
          </View>
        </View>

        <Text style={styles.header}>Detailed Information</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader2}>
              <Text style={styles.tableCellHeader2}>Trade ID:</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell2}>{tradeId}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader2}>
              <Text style={styles.tableCellHeader2}>Confirmation Date:</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell2}>
                {txConfirmDate ? formatDate(txConfirmDate ?? '') : ''} {txConfirmDate ? 'UTC' : ''}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader2}>
              <Text style={styles.tableCellHeader2}>Pair:</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell2}>{pair}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader2}>
              <Text style={styles.tableCellHeader2}>Side:</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell2}>{side}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader2}>
              <Text style={styles.tableCellHeader2}>Quantity:</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell2}>
                {formatNumber(quantity, 0)} {tokenUom}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader2}>
              <Text style={styles.tableCellHeader2}>Unit Price:</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell2}>
                {ccyCode}
                {formatNumber(unitPrice, numDecimals)} {ccy}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader2}>
              <Text style={styles.tableCellHeader2}>Fee:</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell2}>
                {ccyCode}
                {formatNumber(fee, numDecimals)} {ccy}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader2}>
              <Text style={styles.tableCellHeader2}>Trade Amount (incl. fees):</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell2}>
                {ccyCode}
                {formatNumber(total, numDecimals)} {ccy}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader2}>
              <Text style={styles.tableCellHeader2}>TX Hash:</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCell2}>{txHash}</Text>
            </View>
          </View>
          {/* This details for Regular Marker */}
          {orderId && (
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader2}>Order ID</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>{orderId}</Text>
              </View>
            </View>
          )}
          {orderType && (
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader2}>Order Type</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>{orderType}</Text>
              </View>
            </View>
          )}
          {orderUnitPrice && (
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader2}>Order Unit Price</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>
                  {ccyCode}
                  {formatNumber(orderUnitPrice, numDecimals)}
                </Text>
              </View>
            </View>
          )}
          {orderQty && (
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader2}>Order Quantity</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>{formatNumber(orderQty, 0)}</Text>
              </View>
            </View>
          )}
          {timeInForce && (
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader2}>Time in Force</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>{timeInForce}</Text>
              </View>
            </View>
          )}
          {/* End of Regular Market */}
          {/* Market Board and Auction */}
          {offerId && (
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader2}>{isAuction ? 'Auction' : 'Market Board'} ID</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>{offerId}</Text>
              </View>
            </View>
          )}
          {bidId && (
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader2}>Bid ID</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>{bidId}</Text>
              </View>
            </View>
          )}
          {registryName && (
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader2}>Registry</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>{registryName}</Text>
              </View>
            </View>
          )}
          {projectName && registryProjectId && (
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader2}>Project</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>
                  {registryProjectId} - {projectName}
                </Text>
              </View>
            </View>
          )}
          {vintage && (
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader2}>
                <Text style={styles.tableCellHeader2}>Vintage</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell2}>{vintage}</Text>
              </View>
            </View>
          )}
          {/* End of Market Board and Auction */}
        </View>
      </Page>
    </Document>
  );

  return { streamPDF: renderToStream(<MyDocument />), stringPDF: renderToString(<MyDocument />) };
};

export default generateIdxTradeReceipt;
