export enum QueryParamKey {
  PageNumber = 'page[number]',
  PageSize = 'page[size]',
  Search = 'search',
  Status = 'status',
  CcbStandards = 'ccb-standards',
  ProjectTypes = 'project-types',
  SectoralScopes = 'sectoral-scopes',
  VintageYearFrom = 'vintage-year[from]',
  VintageYearTo = 'vintage-year[to]',
}
