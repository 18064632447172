export const toSearchResults = (
  items: Array<{
    name: string;
    registryProjectId?: string;
    id: number;
    __registry__: {
      registryName: string;
    };
  }>,
) => {
  return items.map((item) => {
    return {
      title: item.name,
      id: item.id,
      description: [
        item.__registry__.registryName,
        item.registryProjectId ? `ID: ${item.registryProjectId}` : undefined,
      ]
        .filter((v) => !!v)
        .join(' | '),
    };
  });
};
