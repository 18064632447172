/**
 * Returns the closest available date and time rounded up to the nearest interval.
 *
 * @param {Date} selectedDate - The date and time selected by the user.
 * @param {number} [timeInterval=30] - The interval in minutes to round up to (default is 30 minutes).
 * @returns {Date} - A new Date object rounded up to the nearest specified interval that is greater than or equal to the current time.
 *
 * @example
 * // Returns Aug 26 2024 10:30 AM if the current time is after 10:00 AM and before 10:30 AM
 * const selectedDate = new Date('Aug 26 2024 10:00 AM');
 * const closestDate = toRoundedUpTime(selectedDate, 30);
 * console.log(closestDate);
 *
 * @example
 * // Returns Aug 26 2024 10:15 AM if the current time is after 10:00 AM and before 10:15 AM
 * const selectedDate = new Date('Aug 26 2024 10:00 AM');
 * const closestDate = toRoundedUpTime(selectedDate, 15);
 * console.log(closestDate);
 */
export function toRoundedUpTime(selectedDate: Date, timeInterval = 30) {
  const currentDate = new Date();

  // If the selected date and time is earlier than the current date and time, use the current date and time
  if (selectedDate < currentDate) {
    selectedDate = new Date(currentDate);
  }

  const minutes = selectedDate.getMinutes();
  const roundedMinutes = Math.ceil(minutes / timeInterval) * timeInterval;

  // If rounding goes to 60 or more, increment the hour and adjust minutes
  if (roundedMinutes >= 60) {
    selectedDate.setHours(selectedDate.getHours() + Math.floor(roundedMinutes / 60));
    selectedDate.setMinutes(roundedMinutes % 60);
  } else {
    selectedDate.setMinutes(roundedMinutes);
  }

  // Set seconds and milliseconds to 0 for clean output
  selectedDate.setSeconds(0);
  selectedDate.setMilliseconds(0);

  return selectedDate;
}
