import { FunctionComponent } from 'react';
import { toOrderStatusBadgeProps } from 'refreshed-pages/market-board-v2/components/OrderHistory/utils/toOrderStatusBadgeProps';
import onPressDownloadAuctionRules from 'refreshed-pages/market-board-v2/utils/onPressDownloadAuctionRules';

import {
  styled,
  Card,
  toSpacing,
  IconName,
  Text,
  Icon,
  TextColor,
  TypographyVariant,
  LabelWithDescription,
  toBorderColor,
  BorderColor,
  LabelWithDescriptionSize,
  useSpacing,
  BadgeInstantTrade,
  Badge,
  Button,
  ButtonVariant,
  ButtonSize,
} from '@aircarbon/ui';
import { Dto } from '@aircarbon/utils-common';

export interface ListingProps {
  orderId: number;
  isInstantTrade?: boolean;
  endingAt?: string;
  project?: string;
  projectStatus?: Dto.ProjectStatus;
  hasMultipleProjects?: boolean;
  auctionRulesLink?: string;
  meta: Array<{
    label: string;
    value: string | Array<string>;
  }>;
  price: string;
  quantityUnit: string;
  openQuantity: string;
  totalQuantity: string;
  statusId: number;
}

export const Listing: FunctionComponent<ListingProps> = (props) => {
  const {
    orderId,
    isInstantTrade,
    endingAt,
    meta,
    quantityUnit,
    price,
    openQuantity,
    totalQuantity,
    project,
    statusId,
    projectStatus,
    hasMultipleProjects,
    auctionRulesLink = '',
  } = props;

  const { spacing } = useSpacing();

  return (
    <StyledCard>
      <MainSection>
        <Heading>
          {isInstantTrade && <BadgeInstantTrade />}
          <Badge {...toOrderStatusBadgeProps(statusId, projectStatus)} />
          {!!endingAt && (
            <StyledTimeText variant={TypographyVariant.caption} color={TextColor.secondary}>
              <Icon name={IconName.Clock} color={TextColor.error} />
              {endingAt}
            </StyledTimeText>
          )}
        </Heading>
        <MetaFields $hasProject={!!project}>
          {!!project && (
            <LabelWithDescription
              key={'project'}
              label={hasMultipleProjects ? 'Projects' : 'Project'}
              description={project}
              isExpanded
            />
          )}
          {meta.map((item) => (
            <LabelWithDescription key={item.label} label={item.label} description={item.value} isExpanded />
          ))}
        </MetaFields>
        {!!auctionRulesLink && (
          <Button
            startIcon={IconName.Download}
            onPress={() => onPressDownloadAuctionRules(orderId)}
            variant={ButtonVariant.outlined}
            marginTop={spacing(8)}
            size={ButtonSize.s}
          >
            Download Auction Rules
          </Button>
        )}
      </MainSection>
      <Sidebar>
        <LabelWithDescription
          isExpanded
          label={`Price (per ${quantityUnit})`}
          description={price}
          size={LabelWithDescriptionSize.l}
        />
        <LabelWithDescription
          isExpanded
          label={'Open Qty / Total Qty'}
          description={openQuantity}
          descriptionSuffix={
            <StyledTotalQtyText
              marginStart={spacing(2)}
              variant={TypographyVariant.h5Title}
              color={TextColor.secondary}
            >
              / {totalQuantity}
            </StyledTotalQtyText>
          }
          size={LabelWithDescriptionSize.l}
        />
      </Sidebar>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  padding: ${({ theme }) => toSpacing(theme)(12)};
  gap: ${({ theme }) => toSpacing(theme)(12)};

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

const MainSection = styled.div`
  flex-grow: 1;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;

const StyledTimeText = styled(Text)`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(2)};
`;

const MetaFields = styled.div<{
  $hasProject?: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: ${({ theme }) => toSpacing(theme)(8)};
  column-gap: ${({ theme }) => toSpacing(theme)(12)};
  row-gap: ${({ theme }) => toSpacing(theme)(8)};
  width: 100%;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);

    ${({ $hasProject }) =>
      $hasProject &&
      `
        & > *:first-child {
          grid-column: 1/3;
        }
    `}
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);

    ${({ $hasProject }) =>
      $hasProject &&
      `
        & > *:first-child {
          grid-column: 1/4;
        }
    `}
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);

    ${({ $hasProject }) =>
      $hasProject &&
      `
        & > *:first-child {
          grid-column: 1/5;
        }
    `}
  }
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(8)};

  width: 100%;

  border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  padding-top: ${({ theme }) => toSpacing(theme)(12)};

  @media (min-width: 600px) {
    width: 320px;
    flex-shrink: 0;
    padding-top: 0;
    padding-inline-start: ${({ theme }) => toSpacing(theme)(12)};
    border-top: 0;
    border-inline-start: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  }
`;

const StyledTotalQtyText = styled(Text)`
  display: inline-flex;
`;
