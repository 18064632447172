import queryString from 'query-string';

import type { UserOrdersResponse } from '@aircarbon/utils-common/src/dto/order';

import type { AssetCategoryCode } from 'pages/account/trading/layouts/trading.hook';

import { userAuthenticatedApi } from '../fetcher';

export const fetchUserOrders = async (data: {
  pairId?: number | string;
  page: number;
  limit: number;
  status: string | string[];
  accountAddress: string;
  searchByOrderId?: string;
  includeUser?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  side?: string;
  timeInForce?: string;
  statusLabel?: string;
  assetCategory?: AssetCategoryCode;
}) => {
  const qs = queryString.stringify(data, {
    skipNull: true,
    arrayFormat: 'bracket',
  });
  const endpoint = `/oms/order?${qs}`;
  const userService = await userAuthenticatedApi();

  const res = userService.url(endpoint).get();
  return res.json<UserOrdersResponse>();
};
