import { mutatePlaceOrder } from 'data-mutation/oms/mutatePlaceOrder';
import { useMutation } from 'react-query';

export function usePlaceOrder() {
  const [placeOrder, { isLoading, error, data }] = useMutation(async (order: Record<string, any>) => {
    const response = await mutatePlaceOrder(order);
    return response;
  });

  return {
    placeOrder,
    isLoading,
    data,
    error,
  };
}
