import { useEffect, useState } from 'react';

import { Alert, TypographyVariant, styled, Text, AlertColor } from '@aircarbon/ui';

import { Entity } from 'state/entity';

const mbV2OnboardingStorageKey = 'mb-v2-onboarding-enabled';

export const OnboardingAlert = () => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const { entity } = Entity.useContainer();
  const loggedInFooterItems = entity?.config?.footer?.loggedIn;

  const userGuideLink = loggedInFooterItems?.find((item) => item.id === 'userGuide');

  useEffect(() => {
    try {
      const localStorageValue = localStorage.getItem(mbV2OnboardingStorageKey);
      if (!localStorageValue) {
        setIsAlertVisible(true);
        return;
      }
      const isAlertVisible = !!JSON.parse(localStorage.getItem(mbV2OnboardingStorageKey) as string);

      setIsAlertVisible(isAlertVisible);
    } catch {
      setIsAlertVisible(true);
    }
  }, []);

  const onCloseAlert = () => {
    setIsAlertVisible(false);

    try {
      localStorage.setItem(mbV2OnboardingStorageKey, 'false');
    } catch {}
  };

  if (!isAlertVisible || !userGuideLink) {
    return null;
  }

  return (
    <StyledAlert
      color={AlertColor.Info}
      title={'Welcome to Market Board.'}
      onPressClose={onCloseAlert}
      description={
        <Text variant={TypographyVariant.body2}>
          To learn more check our{' '}
          <a href={userGuideLink.url} target="_blank" rel="noopener noreferrer">
            user guide
          </a>
        </Text>
      }
    />
  );
};

const StyledAlert = styled(Alert)`
  flex-shrink: 0;
`;
