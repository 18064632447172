export default (
  <svg viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292787 10.2103C0.105316 10.0228 0 9.7685 0 9.50334C0 9.23817 0.105316 8.98386 0.292787 8.79634L3.58579 5.50334L0.292787 2.21034C0.110629 2.02173 0.00983372 1.76913 0.0121121 1.50694C0.0143906 1.24474 0.11956 0.993927 0.304968 0.808519C0.490376 0.62311 0.741189 0.517941 1.00339 0.515663C1.26558 0.513384 1.51818 0.614179 1.70679 0.796337L5.70679 4.79634C5.89426 4.98386 5.99957 5.23817 5.99957 5.50334C5.99957 5.7685 5.89426 6.02281 5.70679 6.21034L1.70679 10.2103C1.51926 10.3978 1.26495 10.5031 0.999786 10.5031C0.734622 10.5031 0.480314 10.3978 0.292787 10.2103Z"
      fill="#AAAAAA"
    />
  </svg>
);
