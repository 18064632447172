type ToOfferedQuantityPercentage = {
  /**
   * Estimated quantity
   */
  estimatedQuantity: string;

  /**
   * Offered quantity
   */
  offeredQuantity: string;

  /**
   * Maximum offered quantity
   */
  maximumOfferedQuantityPercentage: number;
};

export const toOfferedQuantityPercentage = (props: ToOfferedQuantityPercentage) => {
  const { estimatedQuantity, offeredQuantity, maximumOfferedQuantityPercentage } = props;

  if (isNaN(maximumOfferedQuantityPercentage)) {
    return '0';
  }

  const estimatedQuantityValue = Number(estimatedQuantity);
  const offeredQuantityValue = Number(offeredQuantity);

  if (isNaN(estimatedQuantityValue) || isNaN(offeredQuantityValue)) {
    return String(maximumOfferedQuantityPercentage);
  }

  if (!estimatedQuantityValue) {
    return String(maximumOfferedQuantityPercentage);
  }

  const percentage = offeredQuantityValue
    ? (offeredQuantityValue / estimatedQuantityValue) * 100
    : estimatedQuantityValue * (maximumOfferedQuantityPercentage / 100);

  if (isNaN(percentage)) {
    return String(maximumOfferedQuantityPercentage);
  }

  const flooredPercentage = Math.floor(Math.min(percentage, maximumOfferedQuantityPercentage));

  return String(flooredPercentage);
};
