{
  "info": {
    "name": "AirCarbon",
    "phone": "+65 8168 4248",
    "address": "",
    "description": "AirCarbon is a Block Explorer and Analytics platform for AirCarbon Exchange trades.",
    "email": {
      "info": "info@aircarbon.co",
      "support": "support@aircarbon.co",
      "onboarding": "onboarding@aircarbon.co",
      "operations": "operations@aircarbon.co",
      "trusteeFiat": ""
    },
    "footerLink": [
      {
        "name": "About",
        "url": "/"
      },
      {
        "name": "Terms of Services",
        "url": "/"
      }
    ]
  },
  "bank": {
    "beneficiaryAddress": "12 Marina Boulevard, DBS Asia Central @ MBFC Tower 3. Singapore 018962",
    "beneficiaryName": "Pacific Trustees (Spore) - Aircarbon",
    "bankName": "DBS Bank LTD.",
    "swiftCode": "DBSSSGSG",
    "beneficiaryAccountNumber": "072-031399-2"
  }
}
