import { forwardRef, useImperativeHandle, useState } from 'react';
import * as yup from 'yup';

import {
  Card,
  InputText,
  InputTextType,
  Select,
  Toggle,
  CardVariant,
  ToggleLayout,
  BadgeInstantTrade,
  CMBOrderType,
} from '@aircarbon/ui';
import { Const, formatter } from '@aircarbon/utils-common';

import { UI } from 'state/ui';

import { FieldsGroup } from '../../../FieldsGroup';
import { StyledSectionCard } from '../StyledSectionCard';

export interface CommonFormFieldsValue {
  price: string;
  quantity: string;
  timeInForce: string;
  isInstantTrade: boolean;
  minimumQuantity: string;
  maximumQuantity: string;
  quantityMultiplesOf: string;
  endDate: string;
  baseAssetId?: string;
}

interface CommonFormFieldsProps {
  minimumQuantityLabel: string;
  minimumQuantityTooltip?: string;
  maximumQuantityLabel: string;
  quantityMultiplesOfLabel: string;
  quantityMultiplesOfTooltip?: string;
  quantityUnit: string;
  currency: string;
  value: CommonFormFieldsValue;
  orderType: CMBOrderType;
  balance?: number;
  balancePerAsset?: { [key: string]: { assetId: number; quantity: number } };
  isInstantTradeEnabled?: boolean;
  isInstantTradeHidden?: boolean;
  isMinimumQuantityReadOnly?: boolean;
  isMaximumQuantityReadOnly?: boolean;
  isQuantityMultiplesOfReadOnly?: boolean;
  /**
   * If true, the order is being editing
   */
  isEditing?: boolean;
  onChange(value: CommonFormFieldsValue): void;
}

const valueValidator = yup.object().shape({
  orderType: yup.string(),
  price: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .when('orderType', {
      is: (v: string) => v === CMBOrderType.Bid,
      then: (s) => s.optional().moreThan(0, 'Price should be more than 0'),
      otherwise: (s) => s.moreThan(0, 'Price should be more than 0').required('Please provide Price'),
    }),
  quantity: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .moreThan(0, 'Quantity should be more than 0')
    .required('Please provide Quantity'),
  minimumQuantity: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .when(['quantity', '$minimumQuantityLabel'], ((
      quantity: number,
      $minimumQuantityLabel: string,
      schema: yup.NumberSchema,
    ) => {
      if (!!quantity) {
        return schema.max(quantity, `${$minimumQuantityLabel} should not be bigger than Quantity`);
      }

      return schema.min(0, `Please provide valid ${$minimumQuantityLabel}`);
    }) as any),
  maximumQuantity: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .when(['minimumQuantity', '$maximumQuantityLabel', '$minimumQuantityLabel'], ((
      minimumQuantity: number,
      $maximumQuantityLabel: string,
      $minimumQuantityLabel: string,
      schema: any,
    ) => {
      if (!!minimumQuantity) {
        return schema.min(
          minimumQuantity,
          `${$maximumQuantityLabel} should not be smaller than ${$minimumQuantityLabel}`,
        );
      }

      return schema.min(0, `Please provide valid ${$maximumQuantityLabel}`);
    }) as any),
  quantityMultiplesOf: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0, 'Please provide valid price'),
});

const balanceFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

export interface CommonFormFieldsRef {
  /**
   * Validates the form and returns true if forms has errors
   */
  validate(): boolean;
}

export const CommonFormFields = forwardRef<CommonFormFieldsRef, CommonFormFieldsProps>((props, ref) => {
  const {
    value,
    minimumQuantityLabel,
    maximumQuantityLabel,
    minimumQuantityTooltip,
    quantityMultiplesOfLabel,
    quantityMultiplesOfTooltip,
    quantityUnit,
    orderType,
    isInstantTradeEnabled,
    isInstantTradeHidden,
    isEditing,
    balance,
    balancePerAsset,
    currency = 'USD',
    isMinimumQuantityReadOnly,
    isMaximumQuantityReadOnly,
    isQuantityMultiplesOfReadOnly,
    onChange,
  } = props;

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>({});
  const { getSetting } = UI.useContainer();
  const isMarketBoardAndAuctionsV2SettlementModeEnabled =
    getSetting(Const.FeatureToggle.MarketBoardAndAuctionsV2SettlementMode) === '1';

  useImperativeHandle(
    ref,
    () => ({
      validate() {
        setDirtyFields({});
        try {
          valueValidator.validateSync(
            { ...value, orderType },
            {
              abortEarly: false,
              context: {
                minimumQuantityLabel,
                maximumQuantityLabel,
              },
            },
          );
        } catch (e) {
          setErrors(
            (e as yup.ValidationError).inner.reduce(
              (acc, curr) => ({ ...acc, [curr.path as string]: curr.message }),
              {},
            ),
          );
          return true;
        }

        setErrors({});
        return false;
      },
    }),
    [value, minimumQuantityLabel, maximumQuantityLabel],
  );

  const onChangeFormValue =
    <F extends keyof CommonFormFieldsValue>(field: F) =>
    (fieldValue: CommonFormFieldsValue[F]) => {
      setDirtyFields((prevFields) => ({
        ...prevFields,
        [field]: true,
      }));
      if (field === 'quantity') {
        onChange({
          ...value,
          maximumQuantity: fieldValue as string,
          [field]: fieldValue,
        });
        return;
      }
      onChange({
        ...value,
        [field]: fieldValue,
      });
    };

  return (
    <>
      <StyledSectionCard>
        {!isInstantTradeHidden && (
          <Card variant={CardVariant.Bordered}>
            <Toggle
              margin="16px"
              isOn={isInstantTradeEnabled && value.isInstantTrade}
              isDisabled={!isInstantTradeEnabled || isEditing}
              onChange={(isOn) => onChangeFormValue('isInstantTrade')(isOn)}
              label={<BadgeInstantTrade />}
              description="Faster settlement with pre-funded accounts. If you enable, assets will be reserved."
              layout={ToggleLayout.LabelFirst}
              toggleTooltip={
                !(isInstantTradeEnabled || isEditing) ? "You don't have enough balance for assets reservation" : ''
              }
            />
          </Card>
        )}

        {isInstantTradeEnabled && value.isInstantTrade && (
          <FieldsGroup>
            <Select
              label="Contract"
              placeholder="Select contract to trade"
              value={value.baseAssetId}
              isDisabled={isEditing}
              items={
                balancePerAsset
                  ? Object.keys(balancePerAsset).map((key) => ({
                      value: balancePerAsset[key].assetId.toString(),
                      title: `${key} | ${formatter.formatNumber(balancePerAsset[key].quantity)}`,
                    }))
                  : []
              }
              onChange={({ value }) => onChangeFormValue('baseAssetId')(value)}
            />
          </FieldsGroup>
        )}
        <FieldsGroup columnMinWidth="14rem">
          <InputText
            type={InputTextType.Number}
            label={'Quantity'}
            suffix={quantityUnit}
            value={value.quantity}
            error={!dirtyFields.quantity ? errors.quantity : undefined}
            description={
              balance !== undefined && !isMarketBoardAndAuctionsV2SettlementModeEnabled
                ? 'Available balance: ' + balanceFormatter.format(balance) + ' ' + quantityUnit
                : undefined
            }
            onChange={(e) => onChangeFormValue('quantity')(e.target.value)}
          />
          <InputText
            type={InputTextType.Number}
            label={`Price (per ${quantityUnit})`}
            suffix={currency}
            value={value.price}
            error={!dirtyFields.price ? errors.price : undefined}
            placeholder={orderType === CMBOrderType.Bid ? 'Optional for bids' : ''}
            onChange={(e) => onChangeFormValue('price')(e.target.value)}
          />
        </FieldsGroup>
      </StyledSectionCard>
      <StyledSectionCard>
        <FieldsGroup>
          <InputText
            label={minimumQuantityLabel}
            placeholder="Optional"
            type={InputTextType.Number}
            value={value.minimumQuantity}
            suffix={quantityUnit}
            isDisabled={isMinimumQuantityReadOnly}
            tooltip={minimumQuantityTooltip}
            error={!dirtyFields.minimumQuantity ? errors.minimumQuantity : undefined}
            onChange={(e) => onChangeFormValue('minimumQuantity')(e.target.value)}
          />
          <InputText
            label={maximumQuantityLabel}
            isDisabled={isMaximumQuantityReadOnly}
            type={InputTextType.Number}
            suffix={quantityUnit}
            value={value.maximumQuantity}
            error={!dirtyFields.maximumQuantity ? errors.maximumQuantity : undefined}
            onChange={(e) => onChangeFormValue('maximumQuantity')(e.target.value)}
          />
          <InputText
            label={quantityMultiplesOfLabel}
            tooltip={quantityMultiplesOfTooltip}
            placeholder="Optional"
            isDisabled={isQuantityMultiplesOfReadOnly}
            suffix={quantityUnit}
            type={InputTextType.Number}
            value={value.quantityMultiplesOf}
            error={!dirtyFields.quantityMultiplesOf ? errors.quantityMultiplesOf : undefined}
            onChange={(e) => onChangeFormValue('quantityMultiplesOf')(e.target.value)}
          />
        </FieldsGroup>
      </StyledSectionCard>
    </>
  );
});
