import { type ApiResponse, externalApi } from '../fetcher';

export type SmartContract = {
  name: string;
  addr: string;
  contractVer: string;
  contractEnum: string;
  networkId: number;
  abi: string;
};

export const fetchContract = () => {
  const res = externalApi.get('/contract');
  return res.json<ApiResponse<SmartContract>>();
};
