import { CMBOrderType } from '@aircarbon/ui';

export const checkIfCurrentUserIsSeller = (props: {
  sellerUserId: number | null;
  currentUserId: number | null;
  tradeRequestPlacedByUserId: number | null;
  otcOrderPlacedByUserId: number | null;
  otcOrderOwnerUserId: number | null;
  orderType: CMBOrderType;
}) => {
  const {
    sellerUserId,
    currentUserId,
    tradeRequestPlacedByUserId,
    orderType,
    otcOrderOwnerUserId,
    otcOrderPlacedByUserId,
  } = props;

  return (
    sellerUserId === currentUserId ||
    (tradeRequestPlacedByUserId === currentUserId && orderType === CMBOrderType.Bid) ||
    (otcOrderPlacedByUserId === currentUserId && otcOrderOwnerUserId === sellerUserId)
  );
};
