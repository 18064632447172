import { differenceInCalendarDays, intervalToDuration, isPast } from 'date-fns';
import { useEffect, useState } from 'react';

const checkIfIsFinished = (dateToCheck?: Date) => {
  if (!dateToCheck) {
    return false;
  }

  return isPast(dateToCheck);
};

const toFinishesIn = (date?: Date) => {
  if (!date) {
    return;
  }

  const currentDate = new Date();
  if (differenceInCalendarDays(date, currentDate) <= 1) {
    return intervalToDuration({
      start: currentDate,
      end: date,
    });
  }
};

/**
 * This hook will return the duration to the specified datetime.
 * If the difference between specified datetime is less than 1 day,
 * will return finishes in object with duration to end
 */
export const useDateCountdown = ({ date }: { date?: Date }) => {
  const [isFinished, setIsFinished] = useState(checkIfIsFinished(date));
  const [finishesIn, setFinishesIn] = useState(toFinishesIn(date));

  useEffect(() => {
    const updateInterval = setInterval(() => {
      setFinishesIn(toFinishesIn(date));
      setIsFinished(checkIfIsFinished(date));
    }, 1000);

    return () => {
      clearInterval(updateInterval);
    };
  }, [date]);

  return {
    isFinished,
    finishesIn,
  };
};
