import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

export const DetailsRow: React.FC<{
  label: string;
}> = (props) => {
  const { label, children = '-' } = props;

  return (
    <StyledDetailsRow>
      <Text size={FontSize.small} weight={FontWeight.medium} color={Colors.gray_500}>
        {label}
      </Text>
      {!!children && (
        <Description>
          {typeof children === 'string' ? <Text weight={FontWeight.medium}>{children}</Text> : children}
        </Description>
      )}
    </StyledDetailsRow>
  );
};

const StyledDetailsRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  margin-top: 4px;
  display: flex;
  gap: 8px;
  align-items: center;
`;
