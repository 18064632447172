import { userAuthenticatedApi } from '../fetcher';

export interface UserResponse {
  first_name: string;
  last_name: string;
  cynopsis_full_name: null | string;
  account: string;
  user_id: number;
  parent_id: number | null;
  user_name: string;
  email: string;
  phone: string;
  cognito_id: string;
  status: string;
  role: string;
  trading_name: string;
  account_type: string;
  is_member?: number;
  is_parent_member?: number;
  kyc_status?: string;
}

export interface UsersResponse {
  users: UserResponse[];
}

export const fetchAccountUsers = async () => {
  const userService = await userAuthenticatedApi();
  const res = userService.get('/account/users');
  return res.json<UsersResponse>();
};
