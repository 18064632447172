import { userAuthenticatedApi } from 'data-provider/fetcher';

export interface UpdateTradeRequestProps {
  tradeRequestId: string;
  status: 'ACCEPTED' | 'CANCELLED';
  rejectionReason?: string;
  internalRemark?: string;
}

export const updateTradeRequest = async (
  props: UpdateTradeRequestProps,
): Promise<
  | {
      status: 'ok';
      data: {
        id: string;
        approvedAtUtc: string;
        approvedBy: number;
        buyerConfirmationStatus: string;
        sellerConfirmationStatus: string;
        confirmedByBuyerAtUtc: string;
        buyerUpdatedAtUtc: string;
        sellerUpdatedAtUtc: string;
        cancelledAtUtc: string | null;
        price: number;
        quantity: number;
      };
    }
  | {
      status: 'error';
      error: string;
    }
> => {
  const userService = await userAuthenticatedApi();

  try {
    const bodyJSON = JSON.stringify(props);

    const res = await userService
      .url(`/user/otc/trade-request`)
      .headers({
        'Content-Type': 'application/json',
      })
      .body(bodyJSON)
      .put()
      .res();

    if (res.status >= 300) {
      return {
        status: 'error',
        error: res.statusText ?? 'Something went wrong. Please try again',
      };
    }
    const resJson = await res.json();

    return { status: 'ok', data: resJson.data };
  } catch (e) {
    const error = JSON.parse((e as Error).message);
    return {
      status: 'error',
      error: error.message ?? 'Something went wrong. Please try again',
    };
  }
};
