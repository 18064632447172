import type { ReactElement } from 'react';
import styled from 'styled-components';

import { type ThemeColors, Themes } from './account/trading/components/Themes';

export const Wrapper = styled.div<{ themeColors: ThemeColors }>`
  background: ${({ themeColors }) => themeColors.dashboard.deepBackground};
  color: ${({ themeColors }) => themeColors.dashboard.lightColor};
  height: 100%;
  width: 100%;
  display: flex;
  padding-top: 6rem;
  align-items: stretch;
  justify-content: center;
  font-size: 3rem;
  > {
    line-height: 70px;
    text-align: center;
  }
`;

function PageNotFound(): ReactElement {
  const { theme: themeColors } = Themes.useContainer();
  return (
    <Wrapper themeColors={themeColors}>
      <div>Sorry. Page Not Found</div>
    </Wrapper>
  );
}

export default PageNotFound;
