import logger from './logger';

type ReturnOfGetItem<T = unknown> = T extends undefined ? T | undefined : T;

/**
 * get value from local storage by key.
 *
 * @param {string} key - storage key
 * @param {any} defaultValue - default value.
 * @param {boolean | undefined} parseJson - parse local storage value to JSON and then return.
 * @returns will return local storage value. default will be returned in case of exception or storage value is empty.
 *
 */
export function getItem<T = unknown>(key: string, defaultValue?: T, parseJson?: boolean): ReturnOfGetItem<T> {
  if (localStorage) {
    const value = localStorage.getItem(key);
    if (value === null || value === undefined) {
      return defaultValue as unknown as ReturnOfGetItem<T>;
    }
    if (parseJson) {
      try {
        const storedValue = JSON.parse(value as string);
        return storedValue ?? defaultValue;
        // eslint-disable-next-line no-empty
      } catch (error) {
        logger.error(`JSON parser exception for key: ${key}`, error);
      }
    } else {
      return value as unknown as ReturnOfGetItem<T>;
    }
  }
  if (defaultValue) return defaultValue as unknown as ReturnOfGetItem<T>;
  return undefined as ReturnOfGetItem<T>;
}

/**
 * set local storage value by key.
 *
 * @param {string} key - storage key
 * @param {any} value - default value.
 * @param {boolean | undefined} stringifyJson - convert value to JSON and then store to local storage.
 * @returns {void}
 *
 */
export function setItem<T = unknown>(key: string, value: T, stringifyJson?: boolean): void {
  if (localStorage && value !== null && value !== undefined) {
    try {
      if (stringifyJson) {
        localStorage.setItem(key, JSON.stringify(value as unknown));
      } else {
        localStorage.setItem(key, value as unknown as string);
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }
}
