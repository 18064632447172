import type { SettleEntity } from 'generated';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import {
  ModalSettlementReserveREC,
  type ModalSettlementReserveRECRef,
  ModalSettlementReserveRECStep,
} from '@aircarbon/ui';

import { Entity } from 'state/entity';

import { toRemainingQuantity } from './utils/toRemainingQuantity';
import { toReserveRECModalData } from './utils/toReserveRECModalData';

export interface ReserveRECRef {
  show(): void;
}

export interface RerserveRECProps {
  settlementDetails: SettleEntity;
  projects?: {
    name: string;
    id: string;
    balance: number;
  }[];
  type: 'fund' | 'asset';
  summaryDetails: {
    summary: {
      label: string;
      value: string;
      valueColor?: any;
    }[];
    totals: {
      label: string;
      value: string;
      isTotal?: boolean;
    }[];
    accountName: string;
  };
  onReserveAsset?: (quantitiesByProject: Record<string, number>) => void;
  isLoading: boolean;
}

export const ReserveREC = forwardRef<ReserveRECRef, RerserveRECProps>(
  ({ settlementDetails, summaryDetails, onReserveAsset, isLoading, projects = [], type = 'fund' }, rec) => {
    const [isPositiveButtonDisabled, setIsPositiveButtonDisabled] = useState(false);
    const { selectors } = Entity.useContainer();
    const modalSettlementReserveRECRef = useRef<ModalSettlementReserveRECRef>(null);
    const projectsById = useMemo(
      () =>
        projects.reduce(
          (curr, project) => ({
            ...curr,
            [`${project.id}`]: project,
          }),
          {} as Record<string, (typeof projects)[0]>,
        ),
      [projects],
    );
    const [quantitiesByProject, setQuantitiesByProject] = useState<Record<string, number>>({});

    // NOTE: This is a workaround to show the modal when the parent component calls the show method
    // Should think of a better way to handle this
    // useImperativeHandle customizes the instance value that is exposed to parent components when using ref. As always, imperative code using refs should be avoided in most cases.
    useImperativeHandle(rec, () => ({
      show() {
        if (type === 'fund') {
          modalSettlementReserveRECRef.current?.show({
            ...toReserveRECModalData({
              settlementDetails,
              projects,
              quantitiesByProject,
              uom: selectors.tokenUnitByProduct('REC'),
            }),
            ...summaryDetails,
            currentStep: ModalSettlementReserveRECStep.ConfirmOrder,
          });
        } else {
          modalSettlementReserveRECRef.current?.show({
            ...toReserveRECModalData({
              settlementDetails,
              projects,
              quantitiesByProject,
              uom: selectors.tokenUnitByProduct('REC'),
            }),
            ...summaryDetails,
          });
        }
      },
    }));

    // NOTE: Update modal data when settlementDetails, projects, quantitiesByProject changed
    useEffect(() => {
      const newModalData = toReserveRECModalData({
        settlementDetails,
        projects,
        quantitiesByProject,
        uom: selectors.tokenUnitByProduct('REC'),
      });

      if (type === 'fund') {
        modalSettlementReserveRECRef.current?.setData({
          ...newModalData,
          ...summaryDetails,
          currentStep: ModalSettlementReserveRECStep.ConfirmOrder,
        });
      } else {
        modalSettlementReserveRECRef.current?.setData({
          ...newModalData,
          ...summaryDetails,
        });
      }
    }, [quantitiesByProject, settlementDetails, projects, type, summaryDetails, selectors]);

    // NOTE: Reserve fund do not need to consider the remaining quantity
    useEffect(() => {
      const remainingQuantity = toRemainingQuantity({
        totalQuantity: settlementDetails.quantity,
        quantitiesByProject,
      });

      setIsPositiveButtonDisabled(remainingQuantity > 0);
    }, [settlementDetails, quantitiesByProject]);

    const onChangeProjectQuantity = (newQuantity: { id: string; value: string }) => {
      const quantity = Number(newQuantity.value);

      if (Number.isNaN(quantity) || quantity < 0 || projectsById[newQuantity.id]?.balance < quantity) {
        return;
      }

      setQuantitiesByProject((oldQuantities) => {
        const newRemainingQuantity = toRemainingQuantity({
          totalQuantity: settlementDetails.quantity,
          quantitiesByProject: {
            ...oldQuantities,
            [newQuantity.id]: quantity,
          },
        });

        if (newRemainingQuantity < 0) {
          return oldQuantities;
        }

        return {
          ...oldQuantities,
          [newQuantity.id]: quantity,
        };
      });
    };

    const onPressNext = () => {
      modalSettlementReserveRECRef.current?.show({
        ...toReserveRECModalData({
          settlementDetails,
          projects,
          quantitiesByProject,
          uom: selectors.tokenUnitByProduct('REC'),
        }),
        ...summaryDetails,
        currentStep: ModalSettlementReserveRECStep.ConfirmOrder,
      });
    };

    const onPressPrevious = () => {
      modalSettlementReserveRECRef.current?.show({
        ...toReserveRECModalData({
          settlementDetails,
          projects,
          quantitiesByProject,
          uom: selectors.tokenUnitByProduct('REC'),
        }),
        ...summaryDetails,
        currentStep: ModalSettlementReserveRECStep.AllocateFunds,
      });
    };

    const onPressComplete = () => {
      modalSettlementReserveRECRef.current?.hide();
      onReserveAsset?.(quantitiesByProject);
    };

    return type === 'fund' ? (
      <ModalSettlementReserveREC
        isPositiveButtonDisabled={false}
        isPositiveButtonLoading={false}
        onChangeProjectQuantity={() => {}}
        onPressNext={onPressNext}
        onPressPrevious={onPressPrevious}
        onPressComplete={onPressComplete}
        ref={modalSettlementReserveRECRef}
        isProgressBarHidden
      />
    ) : (
      <ModalSettlementReserveREC
        isPositiveButtonDisabled={isPositiveButtonDisabled}
        isPositiveButtonLoading={false}
        onChangeProjectQuantity={onChangeProjectQuantity}
        onPressNext={onPressNext}
        onPressPrevious={onPressPrevious}
        onPressComplete={onPressComplete}
        ref={modalSettlementReserveRECRef}
        isProgressBarHidden={false}
        isLoading={isLoading}
      />
    );
  },
);
