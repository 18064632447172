/* eslint-disable import/prefer-default-export */

export enum ProjectRatingType {
  BezeroCarbonRating = 'BEZERO_CARBON_RATING',
  SylveraRatingCategory = 'SYLVERA_RATING_CATEGORY',
}

export enum BezeroCarbonRating {
  AAA = 'AAA',
  AA = 'AA',
  A = 'A',
  BBB = 'BBB',
  BB = 'BB',
  B = 'B',
  C = 'C',
  D = 'D',
}

export enum SylveraRatingCategory {
  One = '1',
  Two = '2',
  Three = '3',
}

export enum ProjectStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Disabled = 'DISABLED',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED',
}

export enum ProjectMetaKeyCode {
  Description = 'DESCRIPTION',
  RegistryProjectUrl = 'REGISTRY_PROJECT_URL',
  VintageYear = 'VINTAGE_YEAR',
  BsiCode = 'BSI_CODE',
  VerifiedReferenceNumber = 'VERIFIED_REFERENCE_NUMBER',
  ProjectImgUrl = 'PROJECT_IMG_URL',
  WorldMapLink = 'WORLD_MAP_LINK',
  InitialYearOfCompliance = 'INITIAL_YEAR_OF_COMPLIANCE',
  CarbonStandard = 'CARBON_STANDARD',
  SdgGoal = 'SDG_GOAL',
  CountryCode = 'COUNTRY_CODE',
  EuUnitType = 'EU_UNIT_TYPE',
  TypeOfCarbonUnit = 'TYPE_OF_CARBON_UNIT',
  StateOrProvinceId = 'STATE_OR_PROVINCE_ID',
  ProjectDeveloper = 'PROJECT_DEVELOPER',
  CpxPhase = 'CPX_PHASE',
  ProjectType = 'PROJECT_TYPE',
  OtherCertification = 'OTHER_CERTIFICATION',
  Address = 'ADDRESS',
  CommissioningDate = 'COMMISSIONING_DATE',
  RegistrationDate = 'REGISTRATION_DATE',
  ExpiryDate = 'EXPIRY_DATE',
  PrimaryFuel = 'PRIMARY_FUEL',
  FuelCode = 'FUEL_CODE',
  Technology = 'TECHNOLOGY',
  Capacity = 'CAPACITY',
  IsSupportedByGovSubsidies = 'IS_SUPPORTED_BY_GOV_SUBSIDIES',
  Latitude = 'LATITUDE',
  Longitude = 'LONGITUDE',
  VolumeMwh = 'VOLUME_MWH',
  UnSectoralScope = 'UN_SECTORAL_SCOPE',
  Classification = 'CLASSIFICATION',
  SdgVerificationLevel = 'SDG_VERIFICATION_LEVEL',
  CcbStandard = 'CCB_STANDARD',
  Methodology = 'METHODOLOGY',
  EuEtsPhase = 'EU_ETS_PHASE',
  Sector = 'SECTOR',
  SubSector = 'SUB_SECTOR',
  RegistryProjectId = 'REGISTRY_PROJECT_ID',
  RegistryId = 'REGISTRY_ID',
  ProjectId = 'PROJECT_ID',
}

export interface TokenMint {
  id: number;
  accountAddress: string | null;
  status: string | null;
  comments: string | null;
  fileDocument: string | null;
  parsedSerial: string | null;
  createdBy: number | null;
  reviewedBy: number | null;
  executedBy: number | null;
  approvedBy: number | null;
  transactionId: number | null;
  tradeRequestId: number | null;
  tokenAssetId: number | null;
  userId: number;
  scBatchId: number;
  quantity: number;
  createdAtUtc: Date;
  reviewedAtUtc: Date;
  approvedAtUtc: Date;
  executedAtUtc: Date;
  txHash: string | null;
}

export interface CarbonProjectIssuance {
  id: number;
  createdBy: number;
  updatedBy: number;
  verifiedSerialBlock: string | null;
  mintedSerialBlock: string | null;
  issuanceDocumentUrl: string | null;
  vintageYear: number | null;
  createdAtUtc: Date;
  updatedAtUtc: Date;
  project: Project;
  carbonProjectId: number;
  projectId: number | null;
  tokenMint: TokenMint;
}

export interface ProjectMetaOption {
  code: string;
  metaValue: string;
  description: string | null;
  metaKey: ProjectMetaKey;
}

export interface ProjectMetaKey {
  code: string;
  name: string;
  description: string | null;
  type: string;
  projectMetas: ProjectMeta[];
  projectMetaOptions: ProjectMetaOption[];
}

export interface ProjectRegistry {
  id: number;
  name: string;
  enabled: number;
  type: string;
  code: string;
  projects: Project[];
}

export interface ProjectMeta {
  id: number;
  metaValue: string;
  metaKeyCode: string;
  project: Project;
  projectId: number;
  metaKey: ProjectMetaKey;
}

export interface Project {
  id: number;
  name: string;
  registryProjectId: string | null;
  createdBy: number;
  createdAtUtc: Date;
  updatedBy: number | null;
  updatedAtUtc: Date | null;
  projectStatus: ProjectStatus;
  status: string;
  registry: ProjectRegistry;
  __registry__: ProjectRegistry;
  registryId: number | null;
  projectMetas: ProjectMeta[];
  __projectMetas__: ProjectMeta[];
  projectIssuances: CarbonProjectIssuance[];
  __projectIssuances__: CarbonProjectIssuance[];
}
