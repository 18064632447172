import countryCode from './country-code';

export function convertUnitValue(val: number, unit: 'cent' | 'KG' | 'Ton'): string {
  if (!unit) return `${val} ${unit}`;

  switch (unit) {
    case 'cent':
      return `${val / 100} USD`;
    case 'KG': {
      return `${val / 1000} Ton`;
    }

    default:
      return `${val} ${unit}`;
  }
}

export function networkToken(networkId: number) {
  switch (networkId) {
    case 56:
    case 97:
      return 'BNB';
    case 80001:
    case 137:
      return 'MATIC';
    default:
      return 'ETH';
  }
}

export function convertCountryNameToCode(country: string, codeLength: 2 | 3): string {
  const code = countryCode[country];
  return code?.[codeLength - 2];
}
export default {
  convertUnitValue,
  networkToken,
  convertCountryNameToCode,
};
