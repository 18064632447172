import { AssetCategory } from './assetCategory';
import * as Dto from './dto';

const carbonKeys = [
  Dto.ProjectMetaKeyCode.ProjectId,
  Dto.ProjectMetaKeyCode.RegistryId,
  Dto.ProjectMetaKeyCode.VintageYear,
  Dto.ProjectMetaKeyCode.CountryCode,
  Dto.ProjectMetaKeyCode.Classification,
  Dto.ProjectMetaKeyCode.UnSectoralScope,
  Dto.ProjectMetaKeyCode.SdgGoal,
];

const recKeys = [
  Dto.ProjectMetaKeyCode.ProjectId,
  Dto.ProjectMetaKeyCode.VintageYear,
  Dto.ProjectMetaKeyCode.CountryCode,
  Dto.ProjectMetaKeyCode.RegistryId,
  Dto.ProjectMetaKeyCode.Technology,
  Dto.ProjectMetaKeyCode.PrimaryFuel,
];

const carbonRequiredFields = {
  bidByProjectsRequiredFields: [Dto.ProjectMetaKeyCode.ProjectId, Dto.ProjectMetaKeyCode.VintageYear],
  bidByCriteriaRequiredFields: [Dto.ProjectMetaKeyCode.RegistryId, Dto.ProjectMetaKeyCode.VintageYear],
  offerRequiredFields: [Dto.ProjectMetaKeyCode.VintageYear],
  auctionRequiredFields: [Dto.ProjectMetaKeyCode.VintageYear],
};

const recRequiredFields = {
  bidByProjectsRequiredFields: [Dto.ProjectMetaKeyCode.ProjectId, Dto.ProjectMetaKeyCode.VintageYear],
  bidByCriteriaRequiredFields: [Dto.ProjectMetaKeyCode.RegistryId, Dto.ProjectMetaKeyCode.VintageYear],
  offerRequiredFields: [Dto.ProjectMetaKeyCode.VintageYear],
  auctionRequiredFields: [Dto.ProjectMetaKeyCode.VintageYear],
};

export type MetaKeyMapping = Record<Dto.MbSupportedAssetCategory, Dto.ProjectMetaKeyCode[]>;

export const metaKeysMapping: MetaKeyMapping = {
  [AssetCategory.token]: carbonKeys,
  [AssetCategory.rec]: recKeys,
};

export const metaKeysRequiredFields = {
  [AssetCategory.token]: carbonRequiredFields,
  [AssetCategory.rec]: recRequiredFields,
};
