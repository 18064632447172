import type { RadioBoxProps } from 'refreshed-component/atoms/RadioBox';
import { Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Radio } from '@aircarbon/ui';

export type RadioBoxListProps = {
  list: RadioBoxProps[];
  selected?: string | number;
  className?: string;
  onClick?: (props: RadioBoxProps) => void;
};

const RadioBoxListRoot = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(${Spacing.base});
  flex-direction: row;
`;

export const RadioBoxList = ({ list, className, selected, onClick }: RadioBoxListProps) => {
  return (
    <RadioBoxListRoot className={className}>
      {list.map((item) => {
        const isSelected = item.isSelected || [selected]?.includes(item.id);
        return (
          <Radio
            isChecked={isSelected}
            onChange={() => {
              item.onClick?.(item);
              onClick?.(item);
            }}
            key={item.id}
            label={item.label}
          />
        );
      })}
    </RadioBoxListRoot>
  );
};
