import type { Dto } from '@aircarbon/utils-common';

import type { ProjectFormValue } from '../../ProjectForm';
import { toFormDocuments } from './toFormDocuments';
import { toPhone } from './toPhone';

export const toFormValue = (project: Dto.ApxProject): ProjectFormValue => {
  return {
    id: String(project.id),
    apxId: String(project.apxId),
    projectName: project.name,
    projectDescription: project.description as string,
    projectPhase: String(project.apxPhaseId || ''),
    standardUsed: String(project.registryId || ''),
    projectId: String(project.registryProjectId || ''),
    projectType: String(project.carbonProjectTypeId || ''),
    projectLocation: project.country?.code || '',
    methodology: String(project.methodologyId || ''),
    customMethodology: '',
    sectoralScope: String(project.sectoralScope?.id || ''),
    eligibilityForInternalGrades: '',
    additionalCertifications: [],
    sdgGoals: (project.sdgGoals || []).map((goal) => String(goal.id)),
    proponents: project.apxProponents?.length
      ? project.apxProponents.map((proponent) => {
          return {
            id: proponent.id,
            companyName: proponent.companyName || '',
            phone: toPhone(proponent.phone),
            contactPersonName: proponent.contactPersonName || '',
            email: proponent.contactEmails || '',
            companyInvolvement: proponent.relationType || '',
            contactPersonRole: proponent.contactPersonRole || '',
          };
        })
      : [
          {
            companyName: '',
            companyInvolvement: '',
            contactPersonName: '',
            contactPersonRole: '',
            email: '',
            phone: {
              value: '',
              phoneCode: '+971',
            },
          },
        ],
    implementationDate: project.implementationDate?.substring(0, 10) || '',
    vintages:
      project.vintages?.map((vintage) => ({
        id: vintage.id,
        year: String(vintage.vintageYear || ''),
        dateOfIssuance: vintage.expectedIssuanceDateUtc?.substring(0, 10) || '',
        estimatedQty: String(vintage.projectedCreditQuantity || ''),
        offeredQty: String(vintage.offeredCreditQuantity || ''),
      })) || [],
    documents: toFormDocuments(project.apxDocuments),
  };
};
