export const FeeType = {
  MIN_BALANCE_FEE: 'MIN_BALANCE_FEE',
  TRADING_INACTIVITY_FEE: 'TRADING_INACTIVITY_FEE',
  ONBOARDING_FEE: 'ONBOARDING_FEE',
  FIAT_DEPOSIT_FEE: 'FIAT_DEPOSIT_FEE',
  FIAT_WITHDRAWAL_FEE: 'FIAT_WITHDRAWAL_FEE',
  ASSET_MINT_FEE: 'ASSET_MINT_FEE',
  ASSET_DELIVERY_FEE: 'ASSET_DELIVERY_FEE',
  ASSET_RETIREMENT_FEE: 'ASSET_RETIREMENT_FEE',
  TRADE_SPOT_FEE: 'TRADE_SPOT_FEE',
  TRADE_RFQ_FEE: 'TRADE_RFQ_FEE',
  TRADE_CMB_FEE: 'TRADE_CMB_FEE',
  TRADE_AUCTION_FEE: 'TRADE_AUCTION_FEE',
  TRADE_BLOCK_ADMIN_FEE: 'TRADE_BLOCK_ADMIN_FEE',
  TRADE_BLOCK_BROKER_FEE: 'TRADE_BLOCK_BROKER_FEE',
  GER_PACK_FEE: 'GER_PACK_FEE',
  GER_UNPACK_FEE: 'GER_UNPACK_FEE',
  GER_RETIRE_FEE: 'GER_RETIRE_FEE',
  GER_RETIRE_NFT_FEE: 'GER_RETIRE_NFT_FEE',
  BLOCK_ORDER_FEE: 'BLOCK_ORDER_FEE', // ACX Settlement Fee
} as const;

export const FeeTypesWithoutAssetCategory = [
  FeeType.MIN_BALANCE_FEE,
  FeeType.TRADING_INACTIVITY_FEE,
  FeeType.ONBOARDING_FEE,
  FeeType.FIAT_DEPOSIT_FEE,
  FeeType.FIAT_WITHDRAWAL_FEE,
] as string[];
