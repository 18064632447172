import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { SummaryCard } from 'refreshed-component/molecules/SummaryCard';
import { SummaryHolder } from 'refreshed-component/molecules/SummaryHolder';
import { CmbBidsQueryKey } from 'refreshed-pages/market-board/Marketplace';

import { Icon, IconName, TextColor } from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

import { Account } from 'state/account';
import { Entity } from 'state/entity';

import useAccountBalances from 'hooks/useAccountBalances';

import { fetchMyCMBAsks, fetchMyCMBBid } from 'data-provider/market-board';

import { convertArrayToURParams } from 'utils/helpers';

export const MarketBoardSummery = () => {
  const {
    selector: { mainCcyCode, mainCcyNumDecimals },
  } = Entity.useContainer();

  const { accountUsers } = Account.useContainer();
  const { balances } = useAccountBalances(accountUsers.filter((user) => !!user.account).map((user) => user.user_id));

  const totalCash = useMemo(
    () =>
      balances?.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.balance;
      }, 0),
    [balances],
  );

  const totalOpenCash = useMemo(
    () =>
      balances?.reduce((previousValue, currentValue) => {
        const openAmount = currentValue?.balance - currentValue?.availableAmount ?? 0;
        return previousValue + openAmount;
      }, 0),
    [balances],
  );

  const bidsUrl = `/api/user/carbon/my-cmb-bid?page=${1}&limit=${1}&${convertArrayToURParams('status', ['NEW'])}`;

  const { data: bids } = useQuery([bidsUrl], () => fetchMyCMBBid({ page: 1, limit: 1, status: 'NEW' }));

  const cmbBidsQueryKey = useContext(CmbBidsQueryKey);

  const { data: asks } = useQuery([cmbBidsQueryKey], async () =>
    fetchMyCMBAsks({
      page: 1,
      limit: 100,
      isAuction: 'no',
      status: 'all',
    }),
  );

  return (
    <SummaryHolder>
      <SummaryCard
        icon={<Icon name={IconName.Wallet} />}
        title="Available Balance"
        value={`${mainCcyCode}${formatter.formatNumber(totalCash, mainCcyNumDecimals)}`}
        secondaryValue={
          totalOpenCash > 0 ? `-${mainCcyCode}${formatter.formatNumber(totalOpenCash, mainCcyNumDecimals)}` : undefined
        }
        secondaryValueColor={TextColor.error}
        secondaryTooltip={`Reserved Cash Amount`}
      />
      <SummaryCard icon={<Icon name={IconName.Bids} />} title="My Bids" value={`${bids?.total ?? ''}`} />
      <SummaryCard icon={<Icon name={IconName.Database} />} title="My Listings" value={`${asks?.total ?? ''}`} />
    </SummaryHolder>
  );
};
