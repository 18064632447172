import { Button } from 'refreshed-component/atoms/Button';
import { Card } from 'refreshed-component/atoms/Card';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { PeriodInformation } from '../../../../components/PeriodInformation';
import { BiddingTable } from './components/BiddingTable';
import { MilestonesTable } from './components/MilestonesTable';

export const Sidebar: React.FC<{
  markets: Array<{
    id: string;
    token: string;
    bid: string;
    ask: string;
    last: string;
  }>;
  milestones: Array<[string, string, string]>;
  creditingPeriod: string;
  onPressAsset(assetSymbol: string): void;
}> = (props) => {
  const { milestones, markets, creditingPeriod = '-', onPressAsset } = props;

  return (
    <StyledSidebarCard header={<PeriodInformation label="Crediting Period" value={creditingPeriod} />}>
      {!!markets.length && <BiddingTable items={markets} onPressRow={onPressAsset} />}
      {!!milestones.length && (
        <>
          <StyledMilestonesText>Milestones</StyledMilestonesText>
          <MilestonesTable milestones={milestones} />
        </>
      )}
    </StyledSidebarCard>
  );
};

const StyledSidebarCard = styled(Card)`
  max-width: 374px;
  width: 100%;
  flex: 1 0 auto;
  flex-direction: column;
  padding: 0;
  gap: 0;
  border: 1px solid var(${Colors.gray_200});

  @media (max-width: 1367px) {
    max-width: 320px;
  }

  @media (max-width: 1023px) {
    max-width: 100%;
  }
`;

const StyledMilestonesText = styled(Text).attrs({
  size: FontSize.large,
  weight: FontWeight.semibold,
})`
  padding: 24px;
  border-bottom: 1px solid var(${Colors.gray_200});
  border-radius: 0;
`;

const ButtonContainer = styled.div`
  padding: 24px;
  border-top: 1px solid var(${Colors.gray_200});
  display: flex;
  flex-direction: column;
`;
