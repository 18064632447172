import type { AccountSummaryResponse } from './account';
import type { Entity } from './entity';
import type { Asset } from './pair';

export interface GetRetokenizeJobsRequest {
  statuses?: string[];
  page?: string;
  limit?: string;
}

export interface StartRetokenizeBatchRequest {
  retokenizeBatchId: number;
  newBatchMetadata: BatchMetadataRecord[];
}

export interface AddRetokenizeJobRequest {
  securityTokenIds: number[];
  smartContractTokenId: number;
}

export interface UpdateRetokenizeJobRequest {
  jobId: number;
  status: RetokenizeJobStatus;
}

export interface BatchMetadataRecord {
  key: string;
  value: string;
  encrypted?: boolean;
}

export enum RetokenizeSecurityTokenStatus {
  New = 'NEW',
  Started = 'STARTED',
  Success = 'SUCCESS',
  Failed = 'FAILED',
}

export enum RetokenizeBatchStatus {
  New = 'NEW',
  Started = 'STARTED',
  Minted = 'MINTED',
  Success = 'SUCCESS',
  Failed = 'FAILED',
}

export enum RetokenizeJobStatus {
  New = 'NEW',
  Started = 'STARTED',
  Success = 'SUCCESS',
  Failed = 'FAILED',
}

export interface RetokenizeSecurityToken {
  id: number;
  sourceBatchId: number;
  sourceStId: number;
  targetStId: number | null;
  account: Pick<AccountSummaryResponse, 'name' | 'address'>;
  currentQuantity: number;
  transferSourceAssetTransactionId: number | null;
  transferTargetAssetTransactionId: number | null;
  status: RetokenizeSecurityTokenStatus;
  failedReason: string | null;
  createdAtUtc: Date;
  updatedAtUtc: Date | null;
}

export interface RetokenizeBatch {
  id: number;
  sourceBatchId: number;
  targetBatchId: number | null;
  targetBatchMetadata: string | null;
  mintTransactionId: number | null;
  status: RetokenizeBatchStatus;
  failedReason: string | null;
  securityTokens: RetokenizeSecurityToken[];
}

export interface RetokenizeJob {
  id: number;
  sourceAsset: Pick<Asset, 'id' | 'name'> | null;
  targetAsset: Pick<Asset, 'id' | 'name'> | null;
  status: RetokenizeJobStatus;
  failedReason: string | null;
  createdAtUtc: Date;
  updatedAtUtc: Date | null;
  entity: Pick<Entity, 'id' | 'name'> | null;
  batches: RetokenizeBatch[];
}

export interface RetokenizeJobValidationResponse {
  openOrders: { id: number }[];
  pendingTransactions: { tokenSymbol: string; userId: number }[];
}
