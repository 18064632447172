import { MouseEventHandler } from 'react';
import { Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

const Wrapper = styled.div<{ isOn: boolean; size: 'normal' | 'small' }>`
  width: ${({ size }) => (size === 'normal' ? '40px' : '30px')};
  height: ${({ size }) => (size === 'normal' ? '20px' : '14px')};
  position: relative;
  > .holder {
    width: ${({ size }) => (size === 'normal' ? '40px' : '30px')};
    height: ${({ size }) => (size === 'normal' ? '20px' : '16px')};
    background: ${({ isOn }) => (isOn ? `var(${Colors.success_400})` : `var(${Colors.gray_500})`)};
    border-radius: 20px;
    position: relative;
    cursor: pointer;
  }
  > .button {
    width: ${({ size }) => (size === 'normal' ? '14px' : '12px')};
    height: ${({ size }) => (size === 'normal' ? '14px' : '12px')};
    margin: 2px;
    background: ${({ isOn }) => (!isOn ? `var(${Colors.gray_100})` : `var(${Colors.gray_0})`)};
    border-radius: 20px;
    position: absolute;
    top: 0px;
  }
`;

export const Toggle = ({
  isOn,
  size = 'normal',
  onClick,
}: {
  isOn: boolean;
  size?: 'normal' | 'small';
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
}) => {
  return (
    <Wrapper size={size} isOn={isOn} onClick={onClick}>
      <div className="holder" />
      <div
        className="button"
        style={{
          left: !isOn ? '0px' : 'auto',
          right: isOn ? '0px' : 'auto',
        }}
      />
    </Wrapper>
  );
};
