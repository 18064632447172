export enum BlockOrderStatusEnum {
  SUBMITTED = 'Submitted',
  APPROVED = 'Approved',
  VALIDATED = 'Validated',
  RESERVED = 'Reserved',
  SETTLED = 'Settled',
  REJECTED = 'Rejected',
  CANCELED = 'Canceled',
}

export default BlockOrderStatusEnum;
