import { IllustrationEmptyListCircle, styled, Text, TypographyVariant, useSpacing } from '@aircarbon/ui';

export const EmptyList: React.FunctionComponent<{
  title: string;
  description: React.ReactElement;
}> = (props) => {
  const { spacing } = useSpacing();
  return (
    <StyledEmptyList>
      <IllustrationEmptyListCircle />
      <Text variant={TypographyVariant.h6Title} marginTop={spacing(8)}>
        {props.title}
      </Text>
      {props.description}
    </StyledEmptyList>
  );
};

const StyledEmptyList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 280px;
`;
