import { useRef, useState } from 'react';
import styled from 'styled-components';

import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  InputTextSize,
  ListItem,
  Select,
  Text,
  Toggle,
  ToggleSize,
  type SelectRef,
} from '@aircarbon/ui';

import Loader from './Loader';

const StyledList = styled.div``;
const StyledEmptyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
`;

function SelectCarbonProjectInput({
  isFetching,
  onlyMyProjects,
  items,
  error,
  value,
  onSelect,
  onFilter,
  onNewProject,
  onSearch,
}: {
  isFetching: boolean;
  onlyMyProjects: boolean;
  items: {
    value: string;
    title: string;
    meta: {
      countryCode: string;
      registryName: string;
      registryProjectId: string;
      isNew?: boolean;
    };
  }[];
  value: string;
  error?: string;
  onSelect: ({ value }: { value: string }) => void;
  onFilter?: (myProject: boolean) => void;
  onSearch: (query: string) => void;
  onNewProject: () => void;
}) {
  const selectRef = useRef<SelectRef>(null);

  const onPressCreateNewProject = () => {
    selectRef.current?.toggleDropdownVisibility(false);
    onNewProject();
  };

  const [searchBy, setSearchBy] = useState<string>('');
  const data = Array.isArray(items) ? items : [];

  return (
    <Select
      ref={selectRef}
      items={data}
      value={value}
      size={InputTextSize.s}
      isSearchable
      error={error}
      placeholder="Select project"
      searchPlaceholder="Search Project Name or ID"
      searchValue={searchBy}
      content={(() => {
        if (isFetching) {
          return (
            <StyledEmptyList>
              <Loader />
            </StyledEmptyList>
          );
        }

        return data.length ? (
          <StyledList>
            {data.map((item) => (
              <ListItem
                key={JSON.stringify(item.meta)}
                description={`ID: ${item.meta.registryProjectId} - ${item.meta.registryName}`}
                onPress={() => {
                  onSelect({
                    value: item.value,
                  });
                  selectRef.current?.toggleDropdownVisibility(false);
                }}
                title={item.title}
              />
            ))}
          </StyledList>
        ) : (
          <StyledEmptyList>
            <Text>No projects found</Text>
          </StyledEmptyList>
        );
      })()}
      onSearch={(query) => {
        setSearchBy(query);
        onSearch(query);
      }}
      onChange={onSelect}
      header={
        <Toggle
          size={ToggleSize.s}
          label={'Owned by me'}
          onChange={() => {
            onFilter?.(!onlyMyProjects);
          }}
          isOn={onlyMyProjects}
        />
      }
      footer={
        <Button
          type={ButtonType.Button}
          size={ButtonSize.xs}
          onPress={onPressCreateNewProject}
          variant={ButtonVariant.outlined}
        >
          Create New Project
        </Button>
      }
    />
  );
}

export default SelectCarbonProjectInput;
