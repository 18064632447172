import type * as React from 'react';
import styled from 'styled-components';

import { type ThemeColors, Themes } from 'pages/account/trading/components/Themes';

import { ButtonStyle } from './Styled';

const Wrapper = styled.button<{ themeColors?: ThemeColors }>`
  ${ButtonStyle}
`;
interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  action?: string;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
}

const Button: React.FC<Props> = ({
  children,
  onClick,
  type,
  className = '',
  isLoading = false,
  disabled = false,
  action = '',
  style = {},
}) => {
  const { theme: themeColors } = Themes.useContainer();
  return (
    <Wrapper
      themeColors={themeColors}
      type={type}
      className={`${isLoading ? 'spinner' : ''} ${disabled ? 'disabled-btn' : 'btn'} ${className || ''} ${action}`}
      onClick={onClick}
      disabled={isLoading || disabled}
      style={style}
    >
      {children}
    </Wrapper>
  );
};

export default Button;
