import type { FilterSelections, Filters as SelectionFilters } from 'refreshed-component/molecules/Filter';

import type { Filters } from './Filters';

export const toSelections = (filters: Filters): FilterSelections<SelectionFilters> => {
  let selections = {};

  if (filters.projectTypes?.length) {
    selections = {
      ...selections,
      projectType: {
        type: 'check-box',
        selection: filters.projectTypes,
      },
    };
  }

  if (filters.ccbStandards?.length) {
    selections = {
      ...selections,
      ccbStandard: {
        type: 'check-box',
        selection: filters.ccbStandards,
      },
    };
  }

  if (filters.sectoralScopes?.length) {
    selections = {
      ...selections,
      sectoralScope: {
        type: 'check-box',
        selection: filters.sectoralScopes,
      },
    };
  }

  if (filters.vintageYear?.from !== undefined || filters.vintageYear?.to !== undefined) {
    selections = {
      ...selections,
      vintageYear: {
        type: 'range-input',
        selection: filters.vintageYear,
      },
    };
  }

  return selections;
};
