{
  "info": {
    "name": "SDAX",
    "phone": "+65 6381 6888",
    "address": "1 Paya Lebar Link #06-08 PLQ 2 Paya Lebar Quarter, Singapore 408533",
    "description": "SDAX is a next generation digital marketplace powered by Blockchain technology for asset-backed securities.",
    "email": {
      "info": "info@sdax.co",
      "support": "support@sdax.co",
      "onboarding": "onboarding@sdax.co",
      "operations": "operations@sdax.co",
      "trusteeFiat": ""
    },
    "footerLink": [
      {
        "name": "About",
        "url": "https://www.sdax.co/about/"
      },
      {
        "name": "Terms of Services",
        "url": "https://www.sdax.co/terms-conditions/"
      }
    ]
  },
  "bank": {
    "beneficiaryAddress": "391B Orchard Rd, Level 22 Ngee Ann City Tower B Singapore 238874",
    "beneficiaryName": "SDAX Trust",
    "bankName": "DBS Bank",
    "swiftCode": "DBSSSGSG",
    "beneficiaryAccountNumber": "012341432"
  }
}
