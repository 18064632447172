import wretch from 'wretch';
import QueryStringAddon from 'wretch/addons/queryString';
import { retry, dedupe, RetryOptions } from 'wretch/middlewares';

import { logger } from '@aircarbon/utils-common';

import { RequestParams, UdfResponse, UdfErrorResponse } from './helpers';

function retryWithLogger(options?: RetryOptions) {
  return retry({
    ...options,
    until: (response, _error) => {
      if (response) {
        // Skip retry if 401 (unauthorized) or 403 (forbidden) or 404 (not found)
        if ([401, 403, 404].includes(response.status)) {
          logger.info(`FETCHER: response status ${response.status}. Skip retry.`);
          return true;
        }
      }

      return Boolean(response?.ok);
    },
    onRetry: ({ url }) => {
      logger.info(`FETCHER: retrying ${url}`);
    },
  });
}

export class Requester {
  private _headers: HeadersInit | undefined;

  public constructor(headers?: HeadersInit) {
    if (headers) {
      this._headers = headers;
    }
  }

  public sendRequest<T extends UdfResponse>(
    datafeedUrl: string,
    urlPath: string,
    params?: RequestParams,
  ): Promise<T | UdfErrorResponse>;
  public sendRequest<T>(datafeedUrl: string, urlPath: string, params?: RequestParams): Promise<T>;
  public sendRequest<T>(datafeedUrl: string, urlPath: string, params?: RequestParams): Promise<T> {
    // Send user cookies if the URL is on the same origin as the calling script.
    const options: RequestInit = { credentials: 'same-origin' };

    if (this._headers !== undefined) {
      options.headers = this._headers;
    }

    return wretch(`${datafeedUrl}/${urlPath}`, options)
      .addon(QueryStringAddon)
      .middlewares([
        retryWithLogger({
          maxAttempts: 3,
        }),
        dedupe(),
      ])
      .errorType('json')
      .query(params || {})
      .get()
      .json<T>();
  }
}
