import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

type TabItem = {
  value: string;
  label: string;
};

export const FilterTabs: React.FC<{
  items: Array<TabItem>;
  activeTab: string;
  onPressTab: (value: string) => void;
}> = (props) => {
  const { items, activeTab, onPressTab } = props;
  return (
    <StyledFilterTabs>
      {items.map((item, index) => (
        <Tab isActive={item.value === activeTab} key={index} onPress={() => onPressTab(item.value)}>
          {item.label}
        </Tab>
      ))}
    </StyledFilterTabs>
  );
};

const Tab: React.FC<{
  isActive?: boolean;
  onPress(): void;
}> = (props) => {
  const { children, isActive, onPress } = props;

  return (
    <StyledTab borderColor={isActive ? Colors.secondaryDefault : Colors.transparent} onClick={onPress}>
      <Text
        weight={FontWeight.medium}
        color={isActive ? Colors.secondaryDefault : Colors.gray_500}
        size={FontSize.base}
      >
        {children}
      </Text>
    </StyledTab>
  );
};

const StyledTab = styled.div<{
  borderColor: Colors;
}>`
  display: flex;
  align-items: center;
  margin-bottom: -2px;
  border-bottom: 2px solid var(${(props) => props.borderColor});
  cursor: pointer;
`;

const StyledFilterTabs = styled.div`
  height: 36px;
  margin-top: 16px;
  display: flex;
  gap: 32px;
  width: 100%;
  border-bottom: 2px solid var(${Colors.gray_200});
  flex-shrink: 0;
`;
