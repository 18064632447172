import { OTCOrderResource } from 'refreshed-pages/market-board-v2/utils/fetchOTCOrder';
import { toCMBOrderType } from 'refreshed-pages/market-board-v2/utils/toCMBOrderType';

import { CMBOrderType } from '@aircarbon/ui';
import { assetCategoryUom, AssetCategory } from '@aircarbon/utils-common';

const quantityFormat = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const toCounters = (order: OTCOrderResource | null) => {
  if (!order) {
    return [];
  }

  const orderType = toCMBOrderType({
    isAuction: !!order.isAuction,
    sideId: order.sideId,
  });
  if (orderType === CMBOrderType.Auction) {
    const counters: Array<{
      label: string;
      value: string;
    }> = [];

    if (order.bidVolume) {
      counters.push({
        label: 'Bid Volume',
        value: `${quantityFormat.format(order.bidVolume)} ${assetCategoryUom[order.baseAssetCategoryId as AssetCategory]}`,
      });
    }

    if (order.bestOffer) {
      counters.push({
        label: 'Best Bid',
        value: new Intl.NumberFormat('en-US', { style: 'currency', currency: order.quoteAsset.symbol }).format(
          order.bestOffer,
        ),
      });
    }

    return counters;
  }

  return [];
};
