import { mutateUserInbox } from 'data-mutation/user/mutateUserInbox';
import { useQuery, queryCache, useMutation } from 'react-query';

import { fetchUserInbox } from 'data-provider/user/fetchUserInbox';

export function useNotifications(category?: string, searchFilter?: string) {
  const { data, isLoading, error } = useQuery(['oms-use-notifications', category, searchFilter], () =>
    fetchUserInbox(category, searchFilter),
  );

  const [markAsRead] = useMutation(async (userInboxId: number) => {
    const response = await mutateUserInbox(userInboxId);

    if (response.id) {
      return response;
    }
    throw new Error("Could not update user's inbox");
  });

  const refetchNotifications = () => {
    queryCache.refetchQueries(['oms-use-notifications']);
  };

  const getUnreadCounter = () =>
    data?.filter?.((notification: { isRead: number }) => notification.isRead === 0).length || 0;

  return {
    error,
    isLoading,
    notifications: data,
    markAsRead,
    refetchNotifications,
    selector: { unreadCounter: getUnreadCounter() },
  };
}
