import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

export const SDGGoals: React.FC<{
  goals: Array<{
    label: string;
    value: string;
  }>;
}> = ({ goals }) => {
  return (
    <StyledSDGGoals>
      <StyledLabelText>SDG Goals:</StyledLabelText>
      {goals.map((goal) => (
        <Goal>
          <img src={`/sdg_icons/E-WEB-Goal-${goal.value}.png`} alt="" />
        </Goal>
      ))}
    </StyledSDGGoals>
  );
};

const StyledSDGGoals = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledLabelText = styled(Text).attrs({
  size: FontSize.small,
  weight: FontWeight.medium,
  color: Colors.gray_500,
})``;

const Goal = styled.div`
  display: flex;
  overflow: hidden;
  width: 48px;
  heigh: 48px;
  border-radius: 6px;
`;
