import { ApxProjectStatusCode, ProjectStatus } from './types/enums';
import type { ApxProject } from './types/orm-responses';
import type { GetMyApxProjectsApiParams } from './types/params';
import type { CarbonMetaOptionsResponse, FilteringOptions, ProjectsListFilters } from './types/ui';

export interface ProjectsListFilteringOptions extends FilteringOptions {
  statuses: Array<{
    label: string;
    value: ProjectStatus | 'all';
  }>;
}

const toProjectStatus = (code: string) => {
  const valueIndex = Object.values(ApxProjectStatusCode).indexOf(code as unknown as ApxProjectStatusCode);

  return Object.keys(ApxProjectStatusCode)[valueIndex];
};

const toDate = (props: { createdAt?: string; updatedAt?: string }) => {
  if (!props.createdAt && !props.updatedAt) {
    return '-';
  }

  const projectDate = new Date((props.updatedAt || props.createdAt) as string);

  return projectDate.toLocaleDateString('en-US');
};

export const toVintagePeriod = (vintages: ApxProject['vintages'] = []) => {
  if (!vintages?.length) {
    return '-';
  }

  const vintagesYears = vintages.map((v) => v.vintageYear).filter((v) => !!v) as Array<number>;

  const minimumYear = Math.min(...vintagesYears);
  const maximumYear = Math.max(...vintagesYears);

  if (minimumYear !== maximumYear) {
    return `${minimumYear}-${maximumYear}`;
  }

  return minimumYear.toString();
};

export const toProject = (props: { apxProject: ApxProject }) => {
  const { apxProject } = props;

  return {
    id: apxProject.id as number,
    title: apxProject.name,
    status: toProjectStatus(apxProject.status.code),
    date: toDate({
      createdAt: apxProject.createdAt,
      updatedAt: apxProject.updatedAt,
    }),
    dateLabel: apxProject.updatedAt ? 'Updated at' : 'Created at',
    vintage: toVintagePeriod(apxProject.vintages),
    country: apxProject.country?.name || apxProject.country?.code || '-',
    sectoralScope: apxProject.sectoralScope?.name || '-',
    ccbStandards: (apxProject.ccbStandards?.length
      ? apxProject.ccbStandards
      : [
          {
            id: '-',
            name: '-',
          },
        ]
    )
      .map((standard) => standard.name)
      .join(', '),
  };
};

export const toMyProjectsApiParams = (filters: ProjectsListFilters): GetMyApxProjectsApiParams => {
  let fetchParams: GetMyApxProjectsApiParams = {};

  const status = filters.status && ApxProjectStatusCode[filters.status];
  if (status) {
    fetchParams = {
      ...fetchParams,
      filter: {
        ...fetchParams.filter,
        status,
      },
    };
  }

  if (filters.sectoralScopes?.length) {
    fetchParams = {
      ...fetchParams,
      filter: {
        ...fetchParams.filter,
        'sectoral-scopes': filters.sectoralScopes,
      },
    };
  }

  if (filters.projectTypes?.length) {
    fetchParams = {
      ...fetchParams,
      filter: {
        ...fetchParams.filter,
        'project-types': filters.projectTypes,
      },
    };
  }

  if (filters.vintageYear?.from !== undefined) {
    fetchParams = {
      ...fetchParams,
      filter: {
        ...fetchParams.filter,
        vintage: {
          ...fetchParams.filter?.vintage,
          from: filters.vintageYear?.from,
        },
      },
    };
  }

  if (filters.vintageYear?.to !== undefined) {
    fetchParams = {
      ...fetchParams,
      filter: {
        ...fetchParams.filter,
        vintage: {
          ...fetchParams.filter?.vintage,
          to: filters.vintageYear?.to,
        },
      },
    };
  }

  return fetchParams;
};

export const toProjectsListFilteringOptions = (options: CarbonMetaOptionsResponse): ProjectsListFilteringOptions => ({
  statuses: [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Draft',
      value: ProjectStatus.Draft,
    },
    {
      label: 'Rejected',
      value: ProjectStatus.Rejected,
    },
    {
      label: 'Submitted',
      value: ProjectStatus.Submitted,
    },
    {
      label: 'Active',
      value: ProjectStatus.Active,
    },
  ],
  projectTypes: options.projectTypes.map((item) => ({
    id: item.id,
    label: item.name,
  })),
  ccbStandards: options.ccbStandards.map((item) => ({
    id: item.id,
    label: item.name,
  })),
  sectoralScopes: options.unSectoralScopes.map((item) => ({
    id: item.id,
    label: item.unSectoralScopeName,
  })),
});
