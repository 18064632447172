import { useEffect, useState } from 'react';
import Loading from 'refreshed-component/molecules/Loading';
import { toast } from 'refreshed-component/molecules/toast';
import { fetchProjectById } from 'refreshed-pages/project-exchange/utils/fetchProjectById';

import { AssetCategory } from '@aircarbon/utils-common';
import type { ApxProject } from '@aircarbon/utils-common/src/dto';

import Trading, { type TradingProps } from 'pages/account/trading';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';
import useQueryParams from 'hooks/useQueryParams';

import { userAuthenticatedApi } from 'data-provider/fetcher';

export const Exchange = () => {
  const [tradingProps, setTradingProps] = useState<TradingProps>({});
  const [isProjectLoading, setIsProjectLoading] = useState(false);
  const { product } = useMarketplaceProduct();
  const queryParams = useQueryParams();
  const projectId = queryParams.get('projectId');

  useEffect(() => {
    if (product !== String(AssetCategory.fct)) {
      setTradingProps({
        assetCategoryId: product,
      });
      return;
    }

    const fetchProjectInfo = async () => {
      setIsProjectLoading(true);
      try {
        let project: ApxProject | undefined;
        if (projectId) {
          project = (await fetchProjectById({ projectId }))?.data;
        } else {
          const userService = await userAuthenticatedApi();
          const request = userService.get('/user/apx/projects?page[size]=1');

          const response = (await request.json()) as any;

          project = response?.data?.[0];
        }

        if (!project) {
          throw new Error('Failed to load apx project');
        }

        setTradingProps({
          projectId: String(project.id),
          projectName: project.name,
          assetCategoryId: product,
        });
        setIsProjectLoading(false);
      } catch {
        toast.error('Something went wrong!');
      }
    };

    fetchProjectInfo();
  }, [product, projectId]);

  if (isProjectLoading) {
    return <Loading />;
  }

  return <Trading {...tradingProps} />;
};
