import { type ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { ResponsiveContainerContext, ResponsiveContainerWrapper } from 'refreshed-component/atoms/ResponsiveContainer';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import Loading from 'refreshed-component/molecules/Loading';
import styled from 'styled-components';

export const TableRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(${Colors.gray_50});
  border: 1px solid var(${Colors.gray_200});
  border-radius: var(${Radius.medium});
  width: 100%;
  > .header {
    display: flex;
    contain: layout;
    background-color: var(${Colors.gray_50});
    width: 100%;
    position: relative;
    height: 50px;
    > .left,
    > .body,
    > .action {
      display: flex;
      flex-direction: row;
      width: auto;
      height: max-content;
      > .column {
        display: flex;
        flex-direction: column;
        background-color: var(${Colors.gray_50});
        position: relative;
        &.id {
          width: 50px;
        }
        > .cell {
          color: var(${Colors.gray_500});
          height: 50px;
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;
          padding-left: var(${Spacing.base});
          padding-right: var(${Spacing.base});
          white-space: pre;
          font-size: var(${FontSize.xs});
          font-weight: var(${FontWeight.semibold});
          &.header {
            transform: translateX(var(--scroll-x, 0px)) translateY(var(--scroll-y, 0px));
            border-bottom: 1px solid var(${Colors.gray_200});
            background-color: inherit;
          }
        }
      }
    }
    > .body {
      flex: 1 1 0;
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
  > .rows {
    flex: 1 1 0%;
    display: flex;
    contain: layout;
    background-color: var(${Colors.gray_50});
    height: 500px;
    width: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
    > .no-data {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 250px;
      background-color: var(${Colors.gray_0});
    }
    > .left,
    > .body,
    > .action {
      display: flex;
      flex-direction: row;
      width: auto;
      height: max-content;
      > .column {
        display: flex;
        flex-direction: column;
        background-color: var(${Colors.gray_50});
        position: relative;
        &.id {
          width: 50px;
        }
        > .cell {
          height: 72px;
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: start;
          align-items: center;
          padding-left: var(${Spacing.base});
          padding-right: var(${Spacing.base});
          white-space: pre;
          background-color: var(${Colors.gray_0});
          border-bottom: 1px solid var(${Colors.gray_200});
          font-size: var(${FontSize.small});
          font-weight: var(${FontWeight.medium});
          color: var(${Colors.gray_900});
          transition: background 200ms ease-in-out;
          &:last-child {
            border-bottom: 0px;
          }
          &.nested {
            height: 72px;
            background-color: var(${Colors.gray_100});
          }
          &.action {
            justify-content: end;
            min-width: max-content;
          }
          &.more-action {
            color: var(#626262);
            flex-direction: row;
            gap: var(${Spacing.xs});
            min-width: max-content;
            cursor: pointer;
            &.open {
              background-color: var(${Colors.gray_50});
              > svg {
                rotate: 180deg;
              }
            }
          }
        }
      }
    }
    > .body {
      display: flex;
      flex-direction: row;
      flex: 1 1 0%;
      overflow-x: auto;
      overflow-y: hidden;
      > .column {
        display: flex;
      }
    }
  }
`;

export interface ColumnInfo {
  label?: string | JSX.Element;
  minWidth?: number;
  _key?: string;
}

export interface ColumnList {
  [index: string]: ColumnInfo;
}

export type TableComponent = <Columns extends ColumnList>(props: {
  config: {
    columns: Columns;
    sticky?: {
      left: (keyof Columns)[];
      right?: (keyof Columns)[];
    };
    rows?: ({
      [Type in keyof Columns]: ReactNode;
    } & {
      _key: string;
      _extras?: {
        height?: string;
        color?: string;
        background?: string;
        isOpen?: boolean;
        nestedTable?: ({ [Type in keyof Columns]: ReactNode } & {
          _key: string;
          _extras?: { height?: number; color?: string; background?: string; action?: ReactNode };
        })[];
      };
    })[];
    loading?: boolean;
    whenNoData?: ReactNode;
    onClick?: (
      row: {
        [Type in keyof Columns]: ReactNode;
      } & {
        _key?: string;
      },
    ) => void;
  };
  /**
   * Optional class name component
   */
  className?: string;
}) => JSX.Element;

const ArrowIcon = (
  <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.293031 1.28966C0.480558 1.10219 0.734866 0.996877 1.00003 0.996877C1.26519 0.996877 1.5195 1.10219 1.70703 1.28966L5.00003 4.58266L8.29303 1.28966C8.38528 1.19415 8.49562 1.11797 8.61763 1.06556C8.73963 1.01315 8.87085 0.985567 9.00363 0.984413C9.13641 0.983259 9.26809 1.00856 9.39098 1.05884C9.51388 1.10912 9.62553 1.18338 9.71943 1.27727C9.81332 1.37116 9.88757 1.48281 9.93785 1.60571C9.98813 1.72861 10.0134 1.86029 10.0123 1.99306C10.0111 2.12584 9.98354 2.25706 9.93113 2.37907C9.87872 2.50107 9.80254 2.61142 9.70703 2.70366L5.70703 6.70366C5.5195 6.89113 5.26519 6.99645 5.00003 6.99645C4.73487 6.99645 4.48056 6.89113 4.29303 6.70366L0.293031 2.70366C0.10556 2.51614 0.000244141 2.26183 0.000244141 1.99666C0.000244141 1.7315 0.10556 1.47719 0.293031 1.28966V1.28966Z"
      fill="#007495"
    />
  </svg>
);

const SHOW_MORE_COL = '---showMoreCol---';

type ColumnType = 'left' | 'body' | 'right';

const TableFC: TableComponent = (props) => {
  const { size } = useContext(ResponsiveContainerContext);
  const isSmallScreen = !(size === 'large' || size === 'medium' || size === 'xl');
  const headerBody = useRef<HTMLDivElement>(null);
  const tableRoot = useRef<HTMLDivElement>(null);

  const columns = Object.entries(props.config.columns);
  const [openRows, setOpenRows] = useState<{ [key: string]: boolean }>({});

  const leftColumns = columns.filter(([column, info]) =>
    ((props.config.sticky?.left as string[]) || []).includes(column as string),
  );
  let rightColumns = columns.filter(([column, info]) =>
    ((props.config.sticky?.right as string[]) || []).includes(column as string),
  );
  let bodyColumns = columns.filter(
    ([column, info]) =>
      !([...(props.config.sticky?.right || []), ...(props.config.sticky?.left || [])] as string[]).includes(
        column as string,
      ),
  );

  const haveNestedTable = !!props.config.rows?.find((item) => item._extras?.nestedTable);

  if (haveNestedTable) {
    rightColumns.push([SHOW_MORE_COL, {}]);
  }

  if (isSmallScreen) {
    bodyColumns = [...bodyColumns, ...rightColumns];
    rightColumns = [];
  }

  const columnsRef = useMemo(() => {
    const refs = {} as {
      [string: string]: {
        element: HTMLDivElement | null;
        type: ColumnType;
      };
    };
    return {
      refs,
      setRef: (name: string, element: HTMLDivElement | null, type: ColumnType) => {
        refs[name] = {
          element,
          type,
        };
      },
    };
  }, []);

  const headerRef = useMemo(() => {
    const refs = {} as {
      [string: string]: {
        element: HTMLDivElement | null;
        type: ColumnType;
      };
    };
    return {
      refs,
      setRef: (name: string, element: HTMLDivElement | null, type: 'left' | 'body' | 'right') => {
        refs[name] = {
          element,
          type,
        };
      },
    };
  }, []);

  const buildColumns = (list: [string, ColumnInfo][], type: ColumnType) => {
    return list.map(([column, columnInfo], index) => {
      const rows = props.config.rows;
      return (
        <div
          key={column}
          className="column"
          style={{
            minWidth: columnInfo.minWidth ? `${columnInfo.minWidth}px` : undefined,
          }}
          ref={(elem) => columnsRef.setRef(column, elem, type)}
        >
          {rows?.map((value, index) => {
            const row = value as { [string: string]: ReactNode };
            const extras = value._extras;
            const nestedTable = extras?.nestedTable;
            const rowKey = value._key;
            const isOpen = openRows[rowKey];
            const isShowMoreCol = SHOW_MORE_COL === column;
            const colorBg = isOpen ? `var(${Colors.gray_50})` : `var(${Colors.gray_0})`;
            const colorBgHover = isOpen ? `var(${Colors.gray_100})` : `var(${Colors.gray_50})`;

            return (
              <>
                <div
                  key={rowKey}
                  onClick={() => {
                    if (nestedTable) {
                      setOpenRows({
                        ...openRows,
                        [value._key]: !isOpen,
                      });
                    }
                  }}
                  onMouseEnter={() => tableRoot.current?.style.setProperty(`--cell-hover-color-${index}`, colorBgHover)}
                  onMouseLeave={() => tableRoot.current?.style.setProperty(`--cell-hover-color-${index}`, null)}
                  style={{
                    height: extras?.height ? `${extras?.height}px` : undefined,
                    color: extras?.color,
                    background: `var(--cell-hover-color-${index}), ${
                      extras?.background ? `,${extras?.background}` : `,${colorBg}`
                    })`,
                  }}
                  className={`cell ${isShowMoreCol ? `more-action ${isOpen ? 'open' : 'close'}` : ``}`}
                >
                  {isShowMoreCol ? (
                    isOpen ? (
                      <Text size={FontSize.small}>
                        Show Less {<Icon type={IconType.ChevronUp} width={11} height={7} />}
                      </Text>
                    ) : (
                      <Text size={FontSize.small} color={Colors.gray_500}>
                        Show More {<Icon type={IconType.ChevronDown} width={11} height={7} />}
                      </Text>
                    )
                  ) : (
                    row[column]
                  )}
                </div>
                {isOpen &&
                  nestedTable?.map((row) => {
                    const extras = row._extras;
                    return (
                      <div
                        key={`${rowKey}-${row._key}`}
                        style={{
                          height: extras?.height ? `${extras?.height}px` : undefined,
                          color: extras?.color,
                          background: extras?.background,
                        }}
                        className={`cell nested ${isShowMoreCol && row._extras?.action ? 'action' : ''}`}
                      >
                        {isShowMoreCol ? row._extras?.action : (row as any)[column]}
                      </div>
                    );
                  })}
              </>
            );
          })}
        </div>
      );
    });
  };

  const buildHeader = (list: [string, ColumnInfo][], type: ColumnType) => {
    return list.map(([column, columnInfo], index) => {
      return (
        <div key={column} className="column" ref={(elem) => headerRef.setRef(column, elem, type)}>
          <div className="cell header">{columnInfo.label}</div>
        </div>
      );
    });
  };

  const syncCols = useCallback(
    (type: 'left' | 'body' | 'right') => {
      const columns = Object.entries(columnsRef.refs);
      columns
        .filter(([key, value]) => value.type === type)
        .forEach(([columnsKey, column]) => {
          const headerElement = headerRef.refs[columnsKey].element;
          const columnElement = column.element;
          if (headerElement && columnElement) {
            headerElement.style.width = `fit-content`;
            headerElement.style.minWidth = `initial`;
            columnElement.style.flex = `1 1 0%`;
            columnElement.style.width = `fit-content`;
            columnElement.style.minWidth = `fit-content`;
          }
        });
      if (columns.length) {
        columns.forEach(([columnsKey, column]) => {
          const headerElement = headerRef.refs[columnsKey].element;
          const columnElement = column.element;
          if (headerElement && columnElement) {
            const columnWidth = Math.floor(columnElement.getBoundingClientRect().width);
            const headerWidth = Math.floor(headerElement.getBoundingClientRect().width);
            if (columnWidth > headerWidth) {
              headerElement.style.width = `${columnWidth}px`;
              headerElement.style.minWidth = `${columnWidth}px`;
              columnElement.style.width = `${columnWidth}px`;
              columnElement.style.minWidth = `${columnWidth}px`;
            } else {
              headerElement.style.width = `${headerWidth}px`;
              headerElement.style.minWidth = `${headerWidth}px`;
              columnElement.style.width = `${headerWidth}px`;
              columnElement.style.minWidth = `${headerWidth}px`;
            }
            columnElement.style.flex = ``;
          }
        });
      } else if (type === 'body') {
        const headers = Object.entries(headerRef.refs);
        headers.forEach(([key, ref]) => {
          if (ref.element) {
            ref.element.style.width = '';
            ref.element.style.minWidth = '';
            ref.element.style.flex = 'auto';
          }
        });
      }
    },
    [columnsRef.refs, headerRef.refs],
  );

  const onResize = useCallback(() => {
    syncCols('left');
    syncCols('right');
    syncCols('body');
  }, [syncCols]);

  useEffect(() => {
    onResize();
  });

  useEffect(() => {
    const windowResize = () => {
      onResize();
    };
    window.addEventListener('resize', windowResize);
    return () => {
      window.removeEventListener('resize', windowResize);
    };
  }, []);

  const NoData = (
    <>
      {props.config.loading ? (
        <Loading />
      ) : typeof props.config.whenNoData === 'string' ? (
        <Text size={FontSize.small} color={Colors.gray_500}>
          {props.config.whenNoData}
        </Text>
      ) : (
        props.config.whenNoData || (
          <Text size={FontSize.small} color={Colors.gray_500}>
            No Data Found
          </Text>
        )
      )}
    </>
  );

  return (
    <TableRoot ref={tableRoot} className={props.className}>
      <div className="header">
        <div className="left">{buildHeader(leftColumns, 'left')}</div>
        <div ref={headerBody} className="body">
          {buildHeader(bodyColumns, 'body')}
        </div>
        <div className="action">{buildHeader(rightColumns, 'right')}</div>
      </div>
      <div className="rows">
        {!props.config.rows?.length ? (
          <div className="no-data">{NoData}</div>
        ) : (
          <>
            <div className="left">{buildColumns(leftColumns, 'left')}</div>
            <div
              className="body"
              onScroll={(event) => {
                headerBody.current?.style.setProperty('--scroll-x', `${-event.currentTarget.scrollLeft}px`);
              }}
            >
              {buildColumns(bodyColumns, 'body')}
            </div>
            {!!rightColumns.length && <div className="action">{buildColumns(rightColumns, 'right')}</div>}
          </>
        )}
      </div>
    </TableRoot>
  );
};

export const Table = ResponsiveContainerWrapper(TableFC);
