import { useQuery } from 'react-query';

import { type CarbonProjectsResponse, fetchCarbonProjects } from 'data-provider/carbon/fetchCarbonProjects';

function useCarbonProjects({
  searchBy,
  filterBy,
  options,
  isFilterLike,
}: {
  searchBy?: string;
  options?: Record<string, any>;
  filterBy?: Record<string, any>;
  isFilterLike?: boolean;
}) {
  const { data, ...useQueryParams } = useQuery<CarbonProjectsResponse>(
    ['/api/carbon/projects', searchBy, filterBy],
    () =>
      fetchCarbonProjects({
        searchBy,
        filterBy,
        isFilterLike,
      }),
    {
      ...options,
      refetchOnWindowFocus: false,
    },
  );

  return {
    ...useQueryParams,
    projects: data?.items,
    pagination: { totalItems: data?.total, totalPages: data?.totalPage },
  };
}

export default useCarbonProjects;
