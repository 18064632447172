import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { fetchAcceptDocument } from 'data-provider/user/fetchAcceptDocument';

function useAcceptDocument(fileName: string) {
  const [hasAuthenticated, setHasAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    if (hasAuthenticated) return;

    Auth.currentSession()
      .then(() => {
        setHasAuthenticated(true);
      })
      .catch(() => {
        setHasAuthenticated(false);
      });
  }, [hasAuthenticated]);

  const { data, isLoading, error, refetch } = useQuery(
    ['accept-document', fileName],
    () => fetchAcceptDocument(fileName),
    {
      refetchOnWindowFocus: false,
      enabled: !!fileName && hasAuthenticated,
    },
  );

  const hasAccepted = () => data?.status && data?.acceptedDoc?.acceptedFilename === fileName;

  return {
    data,
    isLoading,
    error,
    selectors: {
      hasAccepted,
    },
    actions: {
      refetchTcAccepted: refetch,
    },
  };
}

export default useAcceptDocument;
