import { userAuthenticatedApi } from 'data-provider/fetcher';

import { CounterOffer } from '../../../../../../../utils/fetchTradeRequests';

export const createCounterOffer = async (
  props: Omit<CounterOffer, 'id' | 'placedBy' | 'createdAtUtc'>,
): Promise<
  | {
      status: 'ok';
      data: CounterOffer;
    }
  | {
      status: 'error';
      error: string;
    }
> => {
  const userService = await userAuthenticatedApi();

  try {
    const bodyJSON = JSON.stringify(props);

    const res = await userService
      .url(`/user/otc/counter-offer`)
      .headers({
        'Content-Type': 'application/json',
      })
      .body(bodyJSON)
      .post()
      .res();
    if (res.status >= 300) {
      return {
        status: 'error',
        error: res.statusText ?? 'Something went wrong. Please try again',
      };
    }
    const resJson = await res.json();

    return resJson.data;
  } catch (e) {
    const error = JSON.parse((e as Error).message);
    return {
      status: 'error',
      error: error.message ?? 'Something went wrong. Please try again',
    };
  }
};
