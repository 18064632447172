import type { GERHistoryData } from '../hooks/GERHistoryData';

export const toTableColumns = (props: {
  history: GERHistoryData;
  assetsAllocationsMap: Record<string, number>;
}): Record<
  string,
  {
    label: string;
    minWidth?: number;
  }
> => {
  switch (props.history.operation) {
    case 'pack':
      return {
        id: {
          label: 'ID',
          minWidth: 50,
        },
        packedGer: {
          label: 'Packed GER',
        },
        gerBefore: {
          label: 'GER before',
        },
        gerAfter: {
          label: 'GER after',
        },
        bcc: {
          label: `BCC${props.assetsAllocationsMap['bcc'] ? ` (${props.assetsAllocationsMap['bcc']}%)` : ''}`,
        },
        fcc: {
          label: `FCC${props.assetsAllocationsMap['fcc'] ? ` (${props.assetsAllocationsMap['fcc']}%)` : ''}`,
        },
        pcc: {
          label: `PCC${props.assetsAllocationsMap['pcc'] ? ` (${props.assetsAllocationsMap['pcc']}%)` : ''}`,
        },
        ccc: {
          label: `CCC${props.assetsAllocationsMap['ccc'] ? ` (${props.assetsAllocationsMap['ccc']}%)` : ''}`,
        },
        submittedOn: {
          label: 'Submitted on',
        },
        status: {
          label: 'Status',
          minWidth: 110,
        },
      };
    case 'unpack':
      return {
        id: {
          label: 'ID',
          minWidth: 50,
        },
        unpackedGer: {
          label: 'Unpacked GER',
        },
        gerBefore: {
          label: 'GER before',
        },
        gerAfter: {
          label: 'GER after',
        },
        bcc: {
          label: `BCC${props.assetsAllocationsMap['bcc'] ? ` (${props.assetsAllocationsMap['bcc']}%)` : ''}`,
        },
        fcc: {
          label: `FCC${props.assetsAllocationsMap['fcc'] ? ` (${props.assetsAllocationsMap['fcc']}%)` : ''}`,
        },
        pcc: {
          label: `PCC${props.assetsAllocationsMap['pcc'] ? ` (${props.assetsAllocationsMap['pcc']}%)` : ''}`,
        },
        ccc: {
          label: `CCC${props.assetsAllocationsMap['ccc'] ? ` (${props.assetsAllocationsMap['ccc']}%)` : ''}`,
        },
        submittedOn: {
          label: 'Submitted on',
        },
        status: {
          label: 'Status',
          minWidth: 110,
        },
      };
    case 'retirement': {
      return {
        batchId: {
          label: 'Batch ID',
          minWidth: 50,
        },
        gerQty: {
          label: 'GER QTY',
        },
        bcc: {
          label: `BCC${props.assetsAllocationsMap['bcc'] ? ` (${props.assetsAllocationsMap['bcc']}%)` : ''}`,
        },
        fcc: {
          label: `FCC${props.assetsAllocationsMap['fcc'] ? ` (${props.assetsAllocationsMap['fcc']}%)` : ''}`,
        },
        pcc: {
          label: `PCC${props.assetsAllocationsMap['pcc'] ? ` (${props.assetsAllocationsMap['pcc']}%)` : ''}`,
        },
        ccc: {
          label: `CCC${props.assetsAllocationsMap['ccc'] ? ` (${props.assetsAllocationsMap['ccc']}%)` : ''}`,
        },
        submittedOn: {
          label: 'Submitted on',
        },
        status: {
          label: 'Status',
          minWidth: 110,
        },
      };
    }
  }
};
