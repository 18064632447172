import { CMBOrderType } from '@aircarbon/ui';

interface ToStaticTextsProps {
  orderType: CMBOrderType;
  orderId: string;
}

export const toStaticTexts = (props: ToStaticTextsProps) => {
  const { orderType, orderId } = props;

  if (orderType === CMBOrderType.Auction) {
    return {
      modalTitle: `Place bid on Auction #${orderId}`,
      detailsTitle: 'Auction Details',
      formTitle: 'Place Bid',
    };
  }

  if (orderType === CMBOrderType.Bid) {
    return {
      modalTitle: `Place offer on Bid #${orderId}`,
      detailsTitle: 'Bid Details',
      formTitle: 'Place Offer',
    };
  }

  return {
    modalTitle: `Place bid on Offer #${orderId}`,
    detailsTitle: 'Offer Details',
    formTitle: 'Place Bid',
  };
};
