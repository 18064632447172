import { ApiClient } from 'generated';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { Empty } from 'refreshed-component/atoms/Empty';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';

import {
  Button,
  ButtonSize,
  Card,
  CardVariant,
  IconName,
  InputText,
  InputTextSize,
  Layer,
  Pagination,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import { AssetCategory, helpers } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import Loading from 'components/styled/Loading';

import { Entity } from 'state/entity';
import { User } from 'state/user';

import useCarbonMetaOptions from 'hooks/useCarbonMetaOptions';
import useDebounce from 'hooks/useDebounce';
import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';
import usePagination from 'hooks/usePagination';

import PendingAlerts, { PendingActionType } from './components/PendingAlerts';
import { getAlertTitle } from './components/SettlementDetails/utils/toPendingAlerts';
import { SettlementStatusTabs } from './components/SettlementStatusTabs';
import { Summary } from './components/Summary';
import { CardSettlementSummary } from './components/molecules/CardSettlementSummary';
import { prepareSettlementCardData } from './helpers';
import { Filters } from './hooks/useFilters';
import { urlMapping } from './route';
import { settlementStatusTabsMap } from './utils/SettleOrderStatus';

function SettlementList() {
  const {
    selector: { getAuthToken, getUserId },
    status: { canCreateSettlement },
  } = User.useContainer();
  const history = useHistory();
  const { product } = useMarketplaceProduct();
  const {
    selector: { mainCcyCode },
  } = Entity.useContainer();
  const { statusFilter } = Filters.useContainer();

  const { marketSettings } = useMarketSettings({});
  const { currentPage, limit, changePage } = usePagination(1, 10);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const fetchSettlements = async (filterBy: { assetCategoryId: string; searchBy: string; statuses: string[] }) => {
    const token = await getAuthToken();
    const apiClient = new ApiClient({
      BASE: window.location.origin,
      TOKEN: token,
    });

    const result = await apiClient.settle.listSettle({
      xEntityDomain: helpers.getHostFromUrl(window.location.origin),
      filterBy: {
        assetCategoryId: filterBy.assetCategoryId,
        searchBy: filterBy.searchBy,
        statuses: filterBy.statuses as any,
      },
      pagination: {
        page: currentPage.toString(),
        limit: limit.toString(),
      },
    });

    if (result.status === 'success') {
      return result.data;
    }

    throw new Error(result.message ?? 'Failed to fetch settlements');
  };

  const { data: settleResponse, isLoading } = useQuery(
    ['fetch-settlements', getUserId(), currentPage, product, debouncedSearchValue, statusFilter],
    () =>
      fetchSettlements({
        assetCategoryId: product,
        searchBy: debouncedSearchValue,
        statuses: settlementStatusTabsMap[statusFilter],
      }),
    {
      enabled: getUserId() > 0,
    },
  );

  const fetchPendingSettlements = async (assetCategoryId: string) => {
    const token = await getAuthToken();
    const apiClient = new ApiClient({
      BASE: window.location.origin,
      TOKEN: token,
    });

    const result = await apiClient.settle.pendingSettle({
      xEntityDomain: helpers.getHostFromUrl(window.location.origin),
      assetCategoryId,
      page: '1',
      limit: '3',
    });

    if (result.status === 'success') {
      return result.data;
    }

    throw new Error(result.message ?? 'Failed to fetch alerts');
  };

  const { data: alerts } = useQuery(
    ['settle-pending-alerts', product, getUserId()],
    () => fetchPendingSettlements(product),
    {
      enabled: getUserId() > 0,
    },
  );

  const {
    carbonMetaOptions: { registries, countries },
  } = useCarbonMetaOptions({
    query: {
      assetCategory: product === AssetCategory.rec.toString() ? 'rec' : 'token',
    },
    options: {
      enabled: !isLoading,
    },
  });

  const totalPages = Number(settleResponse?.totalPages || 0);
  const settleItems = prepareSettlementCardData({
    data: settleResponse?.settles,
    userId: getUserId(),
    product,
    mainCcyCode,
  });

  const isFetchingData = isLoading;

  return (
    <>
      {Array.isArray(alerts?.settles) && (
        <PendingAlerts
          onPressPrimaryAction={(action) => {
            if (action.primaryActionType === PendingActionType.View) {
              history.push(`/account/settlement/${action.id}`);
            }
          }}
          items={
            alerts?.settles?.map((alert) => ({
              id: alert.id,
              title: getAlertTitle(alert, getUserId()),
              primaryActionType: PendingActionType.View,
            })) ?? []
          }
        />
      )}
      <PageHolder>
        <PageSections>
          <PageControls
            title="Settlement Instructions"
            controls={{
              secondary: canCreateSettlement() && (
                <Link to={urlMapping.create} className="px-4 text-blue-500">
                  <Button
                    size={ButtonSize.s}
                    isDisabled={marketSettings?.otcEntryEnabled === 0}
                    onPress={() => urlMapping.create}
                    endIcon={IconName.ArrowEnd}
                  >
                    New Settlement Instruction
                  </Button>
                </Link>
              ),
            }}
          />
        </PageSections>
        <PageSections>
          <Summary />
        </PageSections>
        <Layer>
          <StyledCard
            variant={CardVariant.Bordered}
            header={
              <CardHeader>
                <Layer>
                  <SettlementStatusTabs />
                </Layer>
                <InputText
                  icon={IconName.Search}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchValue(e.target.value);
                  }}
                  value={searchValue}
                  placeholder="Search by ID, Vintage"
                  size={InputTextSize.s}
                />
              </CardHeader>
            }
          >
            <Layer>
              {isFetchingData && <Loading />}

              {!isFetchingData && settleItems?.length === 0 ? (
                <Empty title="No Instruction" />
              ) : (
                settleItems?.map((settlement, index) => (
                  <CardSettlementSummary
                    providers={{
                      registries:
                        registries?.map((item) => ({
                          id: String(item?.id),
                          name: String(item?.registryName ?? ''),
                        })) ?? [],
                      countries,
                    }}
                    key={`Settle-${settlement.id}-${index}`}
                    {...settlement}
                  />
                ))
              )}
            </Layer>
          </StyledCard>
        </Layer>
        <PaginationContainer>
          <Pagination currentPage={currentPage} pagesCount={totalPages} onChange={changePage} />
        </PaginationContainer>
      </PageHolder>
    </>
  );
}
const PaginationContainer = styled.div`
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => toSpacing(theme)(8)};
  padding: ${({ theme }) => toSpacing(theme)(8)};
  flex-wrap: wrap;
`;

const StyledCard = styled(Card)`
  flex-grow: 1;
  align-self: stretch;

  @media (max-width: 1258px) {
    width: 100%;
    max-height: 100vh;
    align-self: flex-start;
  }
`;

export default SettlementList;
