export default (
  <svg viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.293031 1.28966C0.480558 1.10219 0.734866 0.996877 1.00003 0.996877C1.26519 0.996877 1.5195 1.10219 1.70703 1.28966L5.00003 4.58266L8.29303 1.28966C8.38528 1.19415 8.49562 1.11797 8.61763 1.06556C8.73963 1.01315 8.87085 0.985567 9.00363 0.984413C9.13641 0.983259 9.26809 1.00856 9.39098 1.05884C9.51388 1.10912 9.62553 1.18338 9.71943 1.27727C9.81332 1.37116 9.88757 1.48281 9.93785 1.60571C9.98813 1.72861 10.0134 1.86029 10.0123 1.99306C10.0111 2.12584 9.98354 2.25706 9.93113 2.37907C9.87872 2.50107 9.80254 2.61142 9.70703 2.70366L5.70703 6.70366C5.5195 6.89113 5.26519 6.99645 5.00003 6.99645C4.73487 6.99645 4.48056 6.89113 4.29303 6.70366L0.293031 2.70366C0.10556 2.51614 0.000244141 2.26183 0.000244141 1.99666C0.000244141 1.7315 0.10556 1.47719 0.293031 1.28966Z"
      fill="#6B7280"
    />
  </svg>
);
