import { useCallback, useState } from 'react';
import { createContainer } from 'unstated-next';

export const useFilters = () => {
  const [statusFilter, setStatusFilter] = useState<'active' | 'history'>('active');

  const setStatus = useCallback((status: 'active' | 'history') => {
    setStatusFilter(status);
  }, []);

  return {
    setStatus,
    statusFilter,
  };
};

export const Filters = createContainer(useFilters);
