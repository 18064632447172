import type { ReactNode } from 'react';
import { Colors, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Themes } from 'pages/account/trading/components/Themes';

import { Entity } from 'state/entity';

import { Footer } from './Footer';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  flex-basis: 100%;
  flex-grow: 1;
  max-height: 100vh;

  > .controls {
    width: 100%;
    max-width: 560px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-shrink: 0;

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      width: 100%;
      max-width: 300px;
      gap: var(${Spacing.large});
    }

    .hidden {
      opacity: 0;
      position: absolute;
      right: 100%;
    }

    input:disabled {
      background: transparent;
      border-color: #ddd;
      padding: 0;
      border: none;
      height: auto;
      font-weight: 300;
      font-size: 1.2rem;
    }

    .forgot-password-button {
      margin-top: -0.5rem;
      display: flex;
      margin-left: auto;
      margin-bottom: 2rem;
    }
  }
`;

export const AuthWrapper = ({ children, notLogged }: { children: ReactNode; notLogged?: boolean }) => {
  const { entity } = Entity.useContainer();
  const { themeMode } = Themes.useContainer();
  const brandImage = themeMode === 'dark' ? entity.theme.web.authBrandImageDark : entity.theme.web.authBrandImageLight;
  const isBrandImageResponsive = brandImage.includes('__responsive__');

  return (
    <Wrapper>
      <div className="controls">
        <div className="flex flex-row justify-start p-large">
          <div
            className="flex-1"
            style={{
              maxHeight: '64px',
            }}
          >
            <img
              style={{
                height: '100%',
              }}
              src={themeMode === 'dark' ? entity.theme.web.logoSidebarDark : entity.theme.web.logoSidebarLight}
            />
          </div>
        </div>
        <div className="flex flex-col flex-1 justify-center items-center h-auto py-large">{children}</div>
        <div className="p-small">
          <Footer notLogged={notLogged} />
        </div>
      </div>
      <StyledBrandImageWrapper>
        <img
          srcSet={isBrandImageResponsive ? `/${brandImage}, /1.5x-${brandImage} 1.5x, /2x-${brandImage} 2x` : undefined}
          src={brandImage}
        />
      </StyledBrandImageWrapper>
    </Wrapper>
  );
};

const StyledBrandImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(${Colors.gray_0});
  overflow: hidden;
  flex-grow: 1;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
