import { Popover as PopoverAntd, PopoverProps } from 'antd';
import 'antd/lib/popover/style/index.css';
import { useEffect } from 'react';
import { Colors } from 'refreshed-component/design-system';

import styles from './index.module.scss';

export default function Popover({
  themeMode: popoverThemeMode,
  view = 'popover',
  ...props
}: PopoverProps & { themeMode?: 'dark' | 'light'; view?: 'popover' | 'tool-tip' }) {
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--trading-popover-background', `var(${Colors.gray_0})`);
    root.style.setProperty('--trading-popover-border', `var(${Colors.gray_300})`);
    root.style.setProperty('--trading-popover-border-top', `var(${Colors.gray_300})`);
  });
  return <PopoverAntd overlayClassName={view === 'popover' ? styles.popover : styles.toolTip} {...props} />;
}
