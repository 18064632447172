// eslint-disable-next-line no-shadow
enum CmbStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  ACCEPTING_BID = 'ACCEPTING_BID',
  CONFIRMED_BY_SELLER = 'CONFIRMED_BY_SELLER',
  CREDITS_ACQUIRED = 'CREDITS_ACQUIRED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REJECTED_BY_SELLER = 'REJECTED_BY_SELLER',
  PROCESS_TRADE = 'PROCESS_TRADE',
  CANCELLED = 'CANCELLED',
  CANCELLED_BY_OPS = 'CANCELLED_BY_OPS',
  DONE = 'DONE',
  FORFEIT = 'FORFEIT',
  CLOSED = 'CLOSED',
  LIST = 'LIST',
  UNLIST = 'UNLIST',
  DISABLE = 'DISABLE',
}

export default CmbStatus;
