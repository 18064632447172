import { SettleOrderSide } from 'refreshed-pages/settlement/utils/SettleOrderSide';

import { Badge, BadgeSize, BadgeVariant, IconName } from '@aircarbon/ui';

export const BadgeSettleOrderSide: React.FunctionComponent<{
  side: string;
  id: string;
}> = (props) => {
  const { side, id } = props;

  const getValue = () => {
    switch (side) {
      case SettleOrderSide.Sell:
        return `Sell #${id}`;
      case SettleOrderSide.Buy:
        return `Buy #${id}`;
      case SettleOrderSide.Broker:
        return `Broker #${id}`;
      default:
        return `Unknown #${id}`;
    }
  };

  const getVariant = () => {
    switch (side) {
      case SettleOrderSide.Sell:
        return BadgeVariant.Danger;
      case SettleOrderSide.Buy:
        return BadgeVariant.Success;
      case SettleOrderSide.Broker:
        return BadgeVariant.Info;
      default:
        return BadgeVariant.Info2;
    }
  };

  return (
    <Badge
      icon={side === SettleOrderSide.Broker ? IconName.User : IconName.Tag}
      value={getValue()}
      variant={getVariant()}
      size={BadgeSize.s}
    />
  );
};
