import { mutateCancelOrder } from 'data-mutation/oms/mutateCancelOrder';
import { useMutation } from 'react-query';

export function useCancelOrder() {
  const [cancelOrder, { isLoading, error, data }] = useMutation(async (orderId: number) => {
    const response = await mutateCancelOrder(orderId);
    return response;
  });

  return {
    cancelOrder,
    isLoading,
    data,
    error,
  };
}
