export const toSearchResults = (
  items: Array<{
    name: string;
    registryProjectId?: string;
    id: number;
    __registry__: {
      name: string;
    };
    contracts: Record<string, number>[];
  }>,
) => {
  return items.map((item) => {
    return {
      title: item.name,
      id: item.id,
      description: [item.__registry__.name, item.registryProjectId ? `ID: ${item.registryProjectId}` : undefined]
        .filter((v) => !!v)
        .join(' | '),
      balances: item.contracts,
      registryProjectId: item.registryProjectId as string,
    };
  });
};
