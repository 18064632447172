// eslint-disable-next-line no-shadow
export enum TokenRetireStatus {
  Done = 'DONE',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Reverted = 'REVERTED',
  Submitted = 'SUBMITTED'
}

// eslint-disable-next-line no-shadow
export enum TokenPhysicalDeliveryStatus {
  Done = 'DONE',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Reverted = 'REVERTED',
  Submitted = 'SUBMITTED'
}
