import { toTuple } from 'refreshed-pages/market-board-v2/utils/toTuple';

import { CardCMBProps, CMBOrderType } from '@aircarbon/ui';
import { AssetCategory, assetCategoryUom, formatter } from '@aircarbon/utils-common';
import { Dto } from '@aircarbon/utils-common';

import { FetchListingsResponse } from './fetchListings';

const quantityFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const toCmbCardProps =
  (
    toMetaFields: (criteriaItem: { id: string; key: string; value: string }) => CardCMBProps['metaFields'][0][0] | null,
  ) =>
  (props: {
    item: FetchListingsResponse['data'][0];
    userIds: Array<number>;
  }): Omit<CardCMBProps, 'onPressCTA' | 'isCTADisabled'> & {
    startsAt?: string;
  } => {
    const { item, userIds } = props;

    const result = {
      isMine: userIds.includes(item.userId) || userIds.includes(item.placedBy),
      id: item.id,
      quantity: quantityFormatter.format(item.quantity),
      quantityUnit: item.quantityUnit,
      price: item.price ? formatter.formatCurrency(item.price, item.quoteAsset?.numDecimals ?? 2) : '-',
      priceCurrency: item.priceCurrency,
      type: item.type,
      isClosed: item.statusId === Dto.OtcOrderStatus.Closed,
      ...(item.statusId !== Dto.OtcOrderStatus.Closed ? { endsAt: item.expiryUtc, startsAt: item.startAtUtc } : {}),
      isInstantTrade: !!item.isPrefunded,
      contractType: item.baseAsset?.name,
      metaFields: [
        ...toTuple(
          [
            ...(item.project
              ? [
                  {
                    label: 'Project',
                    value: [item.project.registryProjectId, item.project.name].filter((v) => !!v).join(' | '),
                  },
                ]
              : []),
            ...(item.projects?.length
              ? [
                  {
                    label: 'Projects',
                    value: item.projects.map((project) =>
                      [project.registryProjectId, project.name].filter((v) => !!v).join(' | '),
                    ),
                  },
                ]
              : []),
            ...(item.orderCriteria?.map(toMetaFields).filter((field) => !!field) as Array<
              CardCMBProps['metaFields'][0][0]
            >),
          ] || [],
          (currentItem) => ['Project', 'Projects'].includes(currentItem.label),
        ),
      ],
    };

    if (item.type === CMBOrderType.Auction) {
      (result as any).bidVolume = item.bidVolume
        ? `${quantityFormatter.format(item.bidVolume)} ${item.quantityUnit}`
        : '';
      (result as any).bestBid = item.bestOffer
        ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: item.quoteAsset?.symbol,
          }).format(item.bestOffer ?? 0)
        : '';
    }

    return result;
  };
