import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { io } from 'socket.io-client';
import { createContainer } from 'unstated-next';

import { logger } from '@aircarbon/utils-common';

import { User } from './user';

type ConnectionStatus = 'CONNECTING' | 'OPEN' | 'CLOSING' | 'CLOSED' | 'INITIAL';

const webUrl = String(window.location.origin);
const socketUrl = `${webUrl.includes('https') ? webUrl.replace('https', 'wss') : webUrl.replace('http', 'ws')}`;

export function useWS(
  { onListener, roomId, entityId }: { onListener: (...args: any[]) => void; roomId: string; entityId?: number } = {
    onListener: logger.warn,
    roomId: '',
    entityId: 0,
  },
) {
  const { selector } = User.useContainer();
  const socket = useMemo(
    () =>
      io(`${socketUrl}/user`, {
        timeout: 5e3,
        path: '/wsio',
        transports: ['websocket'],
      }),
    [],
  );
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('INITIAL');
  const onChangeStatus = useCallback((status: ConnectionStatus) => {
    logger.info(status, 'connection status');
    setConnectionStatus(status);
  }, []);

  const emitData = useCallback(
    (serviceAction: string, data: any) => socket.emit('call', serviceAction, data, logger.info),
    [socket],
  );

  useEffect(() => {
    selector
      .getAuthToken()
      .then((token) => {
        if (token) {
          socket.auth = { token, kind: selector.getAuthKind() };
        }
      })
      .catch((error) => {
        logger.error(error, 'useWS - auth error with cognito');
      });
  }, [selector, socket]);

  const signedIn = useCallback(
    (profile) => {
      emitData('user.signedIn', profile);
    },
    [emitData],
  );

  const signedOut = useCallback(
    (profile) => {
      emitData('user.signedOut', profile);
    },
    [emitData],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const connectUserOnline = (roomId: string) => {
    onChangeStatus('OPEN');
    if (roomId) {
      emitData('room.join', { room: roomId });
    }
    if (entityId) {
      emitData('room.join', { room: `entity${entityId}` });
    }
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // Make sure user is leave the room
        emitData('room.join', {
          room: 'users',
        });
        logger.warn(user, 'Socket connected - authenticated user');
        if (user) {
          emitData('user.online', {
            email: user.signInUserSession?.idToken?.payload?.email ?? user.username,
            userCognitoId: user.signInUserSession?.idToken?.payload?.sub,
            ns: 'users',
          });
        }
      })
      .catch((error) => {
        logger.error(error, 'Get user error for ws');
      });
    // Make sure to remove all listener before listen again to it
    socket.offAny(onListener);
    socket.removeAllListeners();
    socket.onAny(onListener);
  };

  useEffect(() => {
    // socket events
    socket.on('connect', () => {
      logger.warn(socket.id, 'Socket connected');
      connectUserOnline(roomId);

      const engine = socket.io?.engine;

      engine.on('close', (reason) => {
        // called when the underlying connection is closed
        logger.info(reason);
        socket.offAny(onListener);
        socket.removeAllListeners();
        onChangeStatus('CLOSED');
      });
    });

    socket.on('connect_error', (error) => {
      onChangeStatus('CLOSED');
      logger.error(error);
    });

    socket.on('disconnect', (reason) => {
      onChangeStatus('CLOSED');
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
    });

    // socket manager events
    socket.io.on('reconnect_attempt', () => {
      onChangeStatus('CONNECTING');
    });
    socket.io.on('reconnect', () => {
      onChangeStatus('CONNECTING');
      connectUserOnline(roomId);
    });
    socket.io.on('reconnect_error', (error) => {
      logger.error(error);
    });
    socket.io.on('reconnect_failed', () => {
      logger.info('reconnect_failed');
    });

    return () => {
      socket.offAny(onListener); // remove all listener
      socket.removeAllListeners(); // remove all listener
      socket.close(); // graceful shutdown
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId, socket]);

  return {
    actions: {
      signedIn,
      signedOut,
    },
    selector: {
      isReady: connectionStatus === 'OPEN',
    },
    connectionStatus,
  };
}

export const WS = createContainer(useWS);
