import type { FilterCheckBox, FilterRadioBox, Filters, FilterSelections } from 'refreshed-component/molecules/Filter';
import * as yup from 'yup';

export interface ToFilterSelectionsProps {
  queryParams: string;
  filters: Filters;
}

const checkboxSelectionConstraint = yup.object().shape({
  type: yup.string().oneOf(['check-box']).required(),
  selection: yup.array().of(yup.number()).required(),
});

export const toFilterSelections = (props: ToFilterSelectionsProps): FilterSelections<any> => {
  const searchParams = Object.fromEntries(new URLSearchParams(props.queryParams));
  const { filters } = props;

  const searchParamConstraints = yup.object().shape({
    tokenTypeId: yup
      .object()
      .shape({
        type: yup.string().oneOf(['radio-box']).required(),
        selection: yup.number().required(),
      })
      .transform((_: string, originalValue: string) => {
        if (!originalValue) {
          return;
        }
        if (!(filters.tokenTypeId as FilterRadioBox)?.list?.length) {
          return {
            type: 'radio-box',
            selection: undefined,
          };
        }

        const items = (filters.tokenTypeId as FilterRadioBox).list.map((item) => item.id) as Array<number>;

        try {
          const id = items.includes(Number(originalValue)) ? Number(originalValue) : undefined;

          return {
            type: 'radio-box',
            selection: id,
          };
        } catch {
          return {
            type: 'radio-box',
            selection: undefined,
          };
        }
      }),

    countryCodes: yup
      .object()
      .shape({
        type: yup.string().oneOf(['check-box']).required(),
        selection: yup.array().of(yup.string()).required(),
      })
      .transform(toIdFilterSelections('countryCodes', filters, (v) => v)),
    vintageYears: checkboxSelectionConstraint.transform(toIdFilterSelections('vintageYears', filters)).optional(),
  });

  const castedParams = searchParamConstraints.cast(searchParams, { stripUnknown: true });

  const normalizedParams = Object.keys(castedParams)
    .filter((key) => !!(castedParams[key] as any)?.selection)
    .reduce(
      (curr, key) => ({
        ...curr,
        [key]: castedParams[key],
      }),
      {},
    );

  return normalizedParams;
};

const toIdFilterSelections =
  (key: string, filters: Filters, transformItem?: (value: string) => any) => (_: any, originalValue: string) => {
    if (!originalValue) {
      return;
    }
    if (!(filters[key] as FilterCheckBox)?.list?.length) {
      return {
        type: 'check-box',
        selection: [],
      };
    }

    const items = (filters[key] as FilterCheckBox).list.map((item) => item.id) as Array<number>;

    try {
      const ids = originalValue
        .split(',')
        .map(transformItem ? transformItem : (id) => Number(id))
        .filter((v) => !!v && items.includes(v));

      return {
        type: 'check-box',
        selection: ids,
      };
    } catch {
      return {
        type: 'check-box',
        selection: [],
      };
    }
  };
