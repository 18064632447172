import { DevTool } from '@hookform/devtools';
import type { ReactElement } from 'react';

interface Props {
  control: any;
}

function FormDevTool({ control }: Props): ReactElement {
  if (process.env.NODE_ENV === 'development') {
    return <DevTool control={control} placement="top-left" />;
  }

  return <></>;
}

export default FormDevTool;
