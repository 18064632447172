import { Route, Switch } from 'react-router-dom';

import { EditOrderPage } from './components/EditOrderPage';
import { MarketBoardV2ListingPage } from './components/ListingPage';
import { MarketBoardV2PlaceOrderPage } from './components/PlaceOrderPage';

export const MarketBoardV2 = () => {
  return (
    <Switch>
      <Route path={'/account/mb-and-auctions/place-order'} component={MarketBoardV2PlaceOrderPage} />
      <Route path={'/account/mb-and-auctions/:id'} exact component={MarketBoardV2ListingPage} />
      <Route path={'/account/mb-and-auctions/:id/edit'} exact component={EditOrderPage} />
    </Switch>
  );
};
