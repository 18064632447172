export enum Colors {
  transparent = '--color-transparent',

  surfaceLight = '--color-surface-light',
  surfaceNormal = '--color-surface-normal',
  surfaceDark = '--color-surface-dark',

  borderLight = '--color-border-light',
  borderNormal = '--color-border-normal',
  borderDark = '--color-border-dark',

  primaryDefault = '--color-primary-default',
  primaryDark = '--color-primary-dark',
  primaryMedium = '--color-primary-medium',
  primaryNormal = '--color-primary-normal',
  primaryLight = '--color-primary-light',

  secondaryDefault = '--color-secondary-default',

  summaryIconsBackground = '--color-summary-icons-background',
  summaryIconsIcons = '--color-summary-icons-icons',

  sidebarBackground = '--color-sidebar-background',
  sidebarSeparator = '--sidebar-separator',
  sidebarMainMenuSurfaceHover = '--color-sidebar-main-menu-background-hover',
  sidebarMainMenuSurfaceSelect = '--color-sidebar-main-menu-background-select',
  sidebarMainMenuContentDefault = '--color-sidebar-main-menu-content-default',
  sidebarMainMenuContentHover = '--color-sidebar-main-menu-content-hover',
  sidebarMainMenuContentSelect = '--color-sidebar-main-menu-content-select',
  sidebarSubMenuContentDefault = '--color-sidebar-sub-menu-content',
  sidebarSubMenuContentSelect = '--color-sidebar-sub-menu-content-select',

  headerBackground = '--color-header-background',
  headerItemsContentDefault = '--color-header-items-content-default',
  headerItemsSurfaceHover = '--color-header-items-surface-hover',
  headerItemsContentHover = '--color-header-items-content-hover',
  headerItemsSurfaceSelect = '--color-header-items-surface-select',
  headerItemsContentSelect = '--color-header-items-content-select',

  gray_900 = '--color-gray-900',
  gray_800 = '--color-gray-800',
  gray_700 = '--color-gray-700',
  gray_600 = '--color-gray-600',
  gray_500 = '--color-gray-500',
  gray_400 = '--color-gray-400',
  gray_300 = '--color-gray-300',
  gray_200 = '--color-gray-200',
  gray_100 = '--color-gray-100',
  gray_50 = '--color-gray-50',
  gray_0 = '--color-gray-0',

  buyDark = '--color-buy-dark',
  buyDefault = '--color-buy-default',
  buyNormal = '--color-buy-normal',
  buyLight = '--color-buy-light',

  sellDark = '--color-sell-dark',
  sellDefault = '--color-sell-default',
  sellNormal = '--color-sell-normal',
  sellLight = '--color-sell-light',

  success_800 = '--color-success-800',
  success_700 = '--color-success-700',
  success_400 = '--color-success-400',
  success_100 = '--color-success-100',

  danger_800 = '--color-danger-800',
  danger_700 = '--color-danger-700',
  danger_600 = '--color-danger-600',
  danger_500 = '--color-danger-500',
  danger_400 = '--color-danger-400',
  danger_100 = '--color-danger-100',
  danger_50 = '--color-danger-50',

  warning_800 = '--color-warning-800',
  warning_700 = '--color-warning-700',
  warning_400 = '--color-warning-400',
  warning_100 = '--color-warning-100',

  purple_100 = '--color-purple-100',
  purple_800 = '--color-purple-800',

  buttonPrimarySurfaceDefault = '--color-button-primary-surface-default',
  buttonPrimarySurfaceHover = '--color-button-primary-surface-hover',
  buttonPrimarySurfaceDisabled = '--color-button-primary-surface-disabled',
  buttonPrimaryContentDefault = '--color-button-primary-content-default',
  buttonPrimaryContentHover = '--color-button-primary-content-hover',
  buttonPrimaryContentDisabled = '--color-button-primary-content-disabled',

  buttonSecondarySurfaceDefault = '--color-button-secondary-surface-default',
  buttonSecondarySurfaceHover = '--color-button-secondary-surface-hover',
  buttonSecondarySurfaceDisabled = '--color-button-secondary-surface-disabled',
  buttonSecondaryContentDefault = '--color-button-secondary-content-default',
  buttonSecondaryContentHover = '--color-button-secondary-content-hover',
  buttonSecondaryContentDisabled = '--color-button-secondary-content-disabled',

  buttonOutlinedStrokeDefault = '--color-button-outlined-surface-default',
  buttonOutlinedSurfaceHover = '--color-button-outlined-surface-hover',
  buttonOutlinedStrokeDisabled = '--color-button-outlined-surface-disabled',
  buttonOutlinedContentDefault = '--color-button-outlined-content-default',
  buttonOutlinedContentHover = '--color-button-outlined-content-hover',
  buttonOutlinedContentDisabled = '--color-button-outlined-content-disabled',

  buttonGhostContentDefault = '--color-button-ghost-content-default',
  buttonGhostContentHover = '--color-button-ghost-content-hover',
  buttonGhostContentDisabled = '--color-button-ghost-content-disabled',
}

export type ColorsType = { [key in keyof typeof Colors]: string };

export enum FontSize {
  xs = '--font-size-xs',
  small = '--font-size-small',
  base = '--font-size-base',
  large = '--font-size-large',
  xl = '--font-size-xl',
  _2Xl = '--font-size-2xl',
  _3Xl = '--font-size-3xl',
}

export enum FontWeight {
  default = '--font-weight-default',
  bold = '--font-weight-bold',
  semibold = '--font-weight-semibold',
  medium = '--font-weight-medium',
}

export enum Spacing {
  _3xs = '--spacing-3xs',
  _2xs = '--spacing-2xs',
  _1_5 = '--spacing-1-5',
  xs = '--spacing-xs',
  small = '--spacing-small',
  medium = '--spacing-medium',
  base = '--spacing-base',
  large = '--spacing-large',
  xl = '--spacing-xl',
  _2xl = '--spacing-2xl',
  _3xl = '--spacing-3xl',
  _4xl = '--spacing-4xl',
  _5xl = '--spacing-5xl',
  _6xl = '--spacing-6xl',
}

export enum Radius {
  xl = '--border-radius-xl',
  large = '--border-radius-large',
  medium = '--border-radius-medium',
  small = '--border-radius-small',
}
