import { Redirect, Route, Switch } from 'react-router-dom';

import NewSettlementRequest from './NewSettlementRequest';
import SettlementList from './SettlementList';
import { SettlementDetails } from './components/SettlementDetails';
import { Filters } from './hooks/useFilters';
import { rootUrl } from './route';

function Settlement() {
  return (
    <Switch>
      <Route path={`${rootUrl}/create`}>
        <NewSettlementRequest />
      </Route>
      <Route path={`${rootUrl}/:id`} component={SettlementDetails}></Route>

      <Route path="/">
        <Filters.Provider>
          <SettlementList />
        </Filters.Provider>
      </Route>

      <Route path="*">
        <Redirect to={rootUrl} />
      </Route>
    </Switch>
  );
}

export default Settlement;
