import { Input } from 'refreshed-component/atoms/Input';

export const InputDateOfIssuance: React.FC<{
  issuanceYear: string;
  value: string;
  onChange(date: string): void;
}> = (props) => {
  const { onChange, issuanceYear, value } = props;

  return (
    <Input
      config={{
        color: 'gray',
      }}
      value={value}
      min={`${issuanceYear}-01-01`}
      type="date"
      placeholder="dd/mm/yyyy"
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
