import { Colors, Radius, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

const StyledTextarea = styled.textarea`
  color: var(${Colors.gray_900});
  border-radius: var(${Radius.medium});
  border: 1px solid var(${Colors.gray_200});
  padding: var(${Spacing.small});
  background: var(${Colors.gray_50});
  outline: transparent solid 3px !important;
  transition:
    background-color 200ms ease-in-out,
    border-color 200ms ease-in-out,
    outline-color 200ms ease-in-out;

  &:hover {
    background: var(${Colors.gray_100});
    border: 1px solid var(${Colors.gray_300});
  }

  &:focus,
  &:focus-visible {
    background: var(${Colors.gray_200});
    outline: var(${Colors.gray_100}) solid 3px !important;
    border: 1px solid var(${Colors.gray_400});
  }
`;

export const Textarea: React.FC<{
  value: string;
  onChange(value: string): void;
}> = (props) => {
  return (
    <StyledTextarea value={props.value} onChange={(e) => props.onChange(e.target.value)} rows={6}></StyledTextarea>
  );
};
