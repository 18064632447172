export default (
  <svg viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.00512 0.502764C1.13639 0.371535 1.31441 0.297814 1.50002 0.297814C1.68564 0.297814 1.86365 0.371535 1.99492 0.502764L5.00002 3.50786L8.00512 0.502764C8.06969 0.435907 8.14694 0.38258 8.23234 0.345893C8.31774 0.309207 8.4096 0.289897 8.50254 0.289089C8.59549 0.288281 8.68766 0.305992 8.77369 0.341189C8.85972 0.376386 8.93787 0.428363 9.0036 0.494088C9.06932 0.559813 9.1213 0.637969 9.1565 0.723996C9.19169 0.810024 9.2094 0.9022 9.2086 0.995145C9.20779 1.08809 9.18848 1.17994 9.15179 1.26535C9.11511 1.35075 9.06178 1.42799 8.99492 1.49256L5.98982 4.49766L8.99492 7.50276C9.12243 7.63479 9.19299 7.81161 9.19139 7.99515C9.1898 8.17868 9.11618 8.35425 8.98639 8.48404C8.85661 8.61382 8.68104 8.68744 8.4975 8.68904C8.31396 8.69063 8.13714 8.62008 8.00512 8.49256L5.00002 5.48746L1.99492 8.49256C1.8629 8.62008 1.68608 8.69063 1.50254 8.68904C1.319 8.68744 1.14343 8.61382 1.01365 8.48404C0.883863 8.35425 0.810244 8.17868 0.808649 7.99515C0.807055 7.81161 0.877611 7.63479 1.00512 7.50276L4.01022 4.49766L1.00512 1.49256C0.873892 1.36129 0.800171 1.18328 0.800171 0.997664C0.800171 0.812049 0.873892 0.634034 1.00512 0.502764Z"
      fill="#AAAAAA"
    />
  </svg>
);
