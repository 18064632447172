/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddSettleDTO } from '../models/AddSettleDTO';
import type { AddSettleResponse } from '../models/AddSettleResponse';
import type { GetApproveSettleResponse } from '../models/GetApproveSettleResponse';
import type { GetListRejectReasonResponse } from '../models/GetListRejectReasonResponse';
import type { GetMyProjectsResponse } from '../models/GetMyProjectsResponse';
import type { GetPendingSettlesResponse } from '../models/GetPendingSettlesResponse';
import type { GetRejectSettleResponse } from '../models/GetRejectSettleResponse';
import type { GetReserveSettleResponse } from '../models/GetReserveSettleResponse';
import type { GetSettlesResponse } from '../models/GetSettlesResponse';
import type { GetSummarySettleResponse } from '../models/GetSummarySettleResponse';
import type { PutCancelSettleResponse } from '../models/PutCancelSettleResponse';
import type { ViewSettleResponse } from '../models/ViewSettleResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SettleService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Approve settle by seller/buyer.
     * @returns GetApproveSettleResponse Successful operation
     * @throws ApiError
     */
    public approveSettle({
        xEntityDomain,
        id,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        id: string,
    }): CancelablePromise<GetApproveSettleResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/settle/{id}/approve',
            path: {
                'id': id,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                404: `Settle was not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Cancel settle by operation.
     * @returns PutCancelSettleResponse Successful operation
     * @throws ApiError
     */
    public cancelSettle({
        xEntityDomain,
        id,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        id: string,
    }): CancelablePromise<PutCancelSettleResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/settle/{id}/cancel',
            path: {
                'id': id,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                404: `Settle was not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Create a new settle.
     * @returns AddSettleResponse Successful operation
     * @throws ApiError
     */
    public createSettle({
        xEntityDomain,
        requestBody,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        requestBody?: AddSettleDTO,
    }): CancelablePromise<AddSettleResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/settle',
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request error`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Return a list of settle.
     * @returns GetSettlesResponse Successful operation
     * @throws ApiError
     */
    public listSettle({
        xEntityDomain,
        filterBy,
        pagination,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        filterBy?: {
            /**
             * User Email
             */
            userEmail?: string;
            /**
             * Vintage
             */
            vintage?: number;
            /**
             * Country
             */
            country?: string;
            /**
             * Technology
             */
            technology?: string;
            /**
             * Registry
             */
            registry?: string;
            /**
             * Settlement Date
             */
            settlementDate?: string;
            /**
             * Search By
             */
            searchBy?: string;
            /**
             * Asset Category Id
             */
            assetCategoryId?: string;
            /**
             * Statuses
             */
            statuses?: Array<'SUBMITTED' | 'APPROVED' | 'VALIDATED' | 'SETTLED' | 'RESERVED' | 'EXPIRED' | 'REJECTED' | 'CANCELED' | 'PROCESSING'>;
        },
        pagination?: {
            /**
             * Page number
             */
            page: string;
            /**
             * Number of items per page
             */
            limit: string;
        },
    }): CancelablePromise<GetSettlesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/settle',
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            query: {
                'filterBy': filterBy,
                'pagination': pagination,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }
    /**
     * Returns data.
     * @returns GetListRejectReasonResponse Successful operation
     * @throws ApiError
     */
    public listRejectReason({
        xEntityDomain,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
    }): CancelablePromise<GetListRejectReasonResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/settle/reject-reason',
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                404: `Settle was not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Returns my projects.
     * @returns GetMyProjectsResponse Successful operation
     * @throws ApiError
     */
    public myProjects({
        xEntityDomain,
        status,
        searchBy,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        status?: Array<string>,
        searchBy?: string,
    }): CancelablePromise<GetMyProjectsResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/settle/my-projects',
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            query: {
                'status': status,
                'searchBy': searchBy,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }
    /**
     * Returns pending settles with pagination.
     * @returns GetPendingSettlesResponse Successful operation
     * @throws ApiError
     */
    public pendingSettle({
        xEntityDomain,
        assetCategoryId,
        page = '1',
        limit = '10',
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        assetCategoryId: string,
        page?: string,
        limit?: string,
    }): CancelablePromise<GetPendingSettlesResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/settle/pending',
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            query: {
                'page': page,
                'limit': limit,
                'assetCategoryId': assetCategoryId,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }
    /**
     * Reject settle by seller/buyer.
     * @returns GetRejectSettleResponse Successful operation
     * @throws ApiError
     */
    public rejectSettle({
        xEntityDomain,
        id,
        requestBody,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        id: string,
        requestBody?: {
            /**
             * Reject reason ID
             */
            reasonId: string;
        },
    }): CancelablePromise<GetRejectSettleResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/settle/{id}/reject',
            path: {
                'id': id,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Settle was not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Reserve fund for a settle.
     * @returns GetReserveSettleResponse Successful operation
     * @throws ApiError
     */
    public reserveSettle({
        xEntityDomain,
        id,
        requestBody,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        id: string,
        requestBody?: {
            /**
             * Batches to reserve
             */
            batches: Array<{
                batchId: number;
                quantity: number;
            }> | null;
            /**
             * Selected contract ID
             */
            selectedContractId?: string;
        },
    }): CancelablePromise<GetReserveSettleResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/settle/{id}/reserve',
            path: {
                'id': id,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Settle was not found`,
                500: `Internal server error`,
            },
        });
    }
    /**
     * Returns data.
     * @returns GetSummarySettleResponse Successful operation
     * @throws ApiError
     */
    public summarySettle({
        xEntityDomain,
        assetCategoryId,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        assetCategoryId: string,
    }): CancelablePromise<GetSummarySettleResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/settle/summary/{assetCategoryId}',
            path: {
                'assetCategoryId': assetCategoryId,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }
    /**
     * Return a settle by ID.
     * @returns ViewSettleResponse Successful operation
     * @throws ApiError
     */
    public viewSettle({
        xEntityDomain,
        id,
    }: {
        /**
         * The domain of the entity.
         */
        xEntityDomain: string,
        id: string,
    }): CancelablePromise<ViewSettleResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/settle/{id}',
            path: {
                'id': id,
            },
            headers: {
                'x-entity-domain': xEntityDomain,
            },
            errors: {
                404: `Settle was not found`,
                500: `Internal server error`,
            },
        });
    }
}
