import { SettleStatus } from '@aircarbon/utils-common';

export const settlementStatusTabsMap = {
  active: [SettleStatus.SUBMITTED, SettleStatus.APPROVED, SettleStatus.VALIDATED, SettleStatus.RESERVED],
  history: [SettleStatus.SETTLED, SettleStatus.EXPIRED, SettleStatus.REJECTED, SettleStatus.CANCELED],
};
export const getSettleOrderStatusLabel = (status: string) => {
  switch (status) {
    case SettleStatus.SUBMITTED:
      return 'New';
    case SettleStatus.APPROVED:
    case SettleStatus.VALIDATED:
    case SettleStatus.RESERVED:
      return 'In Progress';
    case SettleStatus.SETTLED:
      return 'Settled';
    case SettleStatus.EXPIRED:
      return 'Expired';
    case SettleStatus.REJECTED:
    case SettleStatus.CANCELED:
      return 'Failed';

    default:
      return 'Unknown';
  }
};
