import { useQuery, queryCache } from 'react-query';

import { fetchPositions } from 'data-provider/user/fetchPositions';

export function usePositions(accountAddress: string) {
  const { data, isLoading, error } = useQuery(['oms-user-positions'], () => fetchPositions(accountAddress), {
    refetchInterval: 30000,
  });

  const refetchPositions = () => {
    queryCache.refetchQueries(['oms-user-positions']);
  };

  return {
    error,
    isLoading,
    positions: data,
    refetchPositions,
  };
}
