export default (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1340_54159)">
      <path
        d="M19.6738 2.39844H12.1851C6.99285 2.39844 2.77013 6.61276 2.77013 11.7947C2.77013 13.921 3.48455 15.9072 4.70926 17.486L2.77013 19.4212C2.27259 19.9305 2.27259 20.7327 2.77013 21.2292C3.02528 21.4711 3.34422 21.5984 3.67591 21.5984C4.0076 21.5984 4.32654 21.4711 4.58169 21.2292L6.53358 19.2812C8.11551 20.4907 10.0674 21.191 12.1851 21.191C17.3647 21.191 21.6002 16.9767 21.6002 11.7947V4.32098C21.6002 3.26422 20.7327 2.39844 19.6738 2.39844ZM17.9515 10.1396L13.3843 14.6976C13.1292 14.9396 12.8103 15.0669 12.4786 15.0669C12.1469 15.0669 11.8279 14.9396 11.5728 14.6976L9.85053 12.9661L8.30687 14.4939C7.80933 14.9905 7.00561 14.9905 6.50807 14.4939C6.01053 13.9974 6.01053 13.1953 6.50807 12.6987L8.94475 10.2669C9.41677 9.79578 10.2715 9.78305 10.7436 10.2669L12.4786 11.9984L16.14 8.33159C16.6375 7.83504 17.454 7.83504 17.9515 8.33159C18.4491 8.82815 18.4491 9.643 17.9515 10.1396Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1340_54159">
        <rect width="19.2" height="19.2" fill="white" transform="translate(2.3999 2.39844)" />
      </clipPath>
    </defs>
  </svg>
);
