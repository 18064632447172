import queryString from 'query-string';

import { type FeeType, AssetCategory } from '@aircarbon/utils-common';

import { userAuthenticatedApi } from '../fetcher';

export const fetchFee = async ({
  feeType,
  assetCategoryId,
  tokenQty,
  totalAmount,
}: {
  feeType: keyof typeof FeeType;
  assetCategoryId?: AssetCategory;
  tokenQty?: number;
  totalAmount?: number;
}) => {
  const qs = queryString.stringify(
    {
      feeType,
      tokenQty: tokenQty || '0',
      totalAmount: totalAmount || '0',
      assetCategory: assetCategoryId ? AssetCategory[assetCategoryId] : null,
    },
    {
      skipNull: true,
    },
  );
  const endpoint = `/user/settings/fee?${qs}`;

  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).get();
  return res.json<number>();
};
