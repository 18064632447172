import { CMBOrderType } from '@aircarbon/ui';

import { userAuthenticatedApi } from 'data-provider/fetcher';

import { AuctionFormValue } from '../components/AuctionForm';
import { BidFormValue } from '../components/BidForm';
import { OfferFormValue } from '../components/OfferForm';
import { toCreateOtcOrderBody } from './toCreateOtcOrderBody';

type FormValue<OrderType extends CMBOrderType> = OrderType extends CMBOrderType.Bid
  ? BidFormValue
  : OrderType extends CMBOrderType.Auction
    ? AuctionFormValue
    : OfferFormValue;
export async function createOrder<OT extends CMBOrderType>(props: {
  assetCategoryId: string;
  orderType: OT;
  formValue: FormValue<OT>;
  creatorUserId: number;
}): Promise<
  | {
      status: 'ok';
    }
  | {
      status: 'error';
      error: string;
    }
> {
  const userService = await userAuthenticatedApi();

  try {
    const bodyJSON = JSON.stringify(toCreateOtcOrderBody(props));

    const res = await userService
      .url(`/user/otc/order`)
      .headers({
        'Content-Type': 'application/json',
      })
      .body(bodyJSON)
      .post()
      .res();

    if (res.status >= 300) {
      return {
        status: 'error',
        error: res.statusText ?? 'Something went wrong. Please try again',
      };
    }

    return {
      status: 'ok',
    };
  } catch (e) {
    const error = JSON.parse((e as Error).message);
    return {
      status: 'error',
      error: error.message ?? 'Something went wrong. Please try again',
    };
  }
}
