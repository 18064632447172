/* eslint-disable no-underscore-dangle */

/* eslint-disable camelcase */
import { Page, Text, Document, StyleSheet, renderToStream, renderToString } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: 12,
    lineHeight: '1.4',
    backgroundColor: '#F3F4F6',
  },
});

const generateAcxRetirementCertificate = async (retirement: any) => {
  if (!retirement) {
    return null;
  }

  const MyDocument = () => (
    // eslint-disable-next-line react/jsx-filename-extension
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <Text>ACX retirement certificate is coming Soon</Text>
      </Page>
    </Document>
  );

  return { streamPDF: renderToStream(<MyDocument />), stringPDF: renderToString(<MyDocument />) };
};

export default generateAcxRetirementCertificate;
