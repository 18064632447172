import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Loading from 'refreshed-component/molecules/Loading';
import {
  FetchOtcOrderResponse,
  FetchOtcOrderStatus,
  OTCOrderResource,
  fetchOTCOrder,
} from 'refreshed-pages/market-board-v2/utils/fetchOTCOrder';
import { toCMBOrderType } from 'refreshed-pages/market-board-v2/utils/toCMBOrderType';
import { wait } from 'refreshed-pages/utils/wait';

import { Text, CMBOrderType, TextColor, ToastVariant, TypographyVariant, showToast } from '@aircarbon/ui';
import { AssetCategory, logger } from '@aircarbon/utils-common';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { NotFoundError } from 'utils/NotFoundError';

import { MarketBoardV2PlaceOrderPage } from '../PlaceOrderPage';
import { FormValue } from '../PlaceOrderPage/utils/FormValue';
import { editOrder } from './utils/editOrder';
import { ApiProject, fetchProjects } from './utils/fetchProjects';
import { toFormValue } from './utils/toFormValue';
import { toProjectIds } from './utils/toProjectIds';

const titleToOrderTypeMap = {
  [CMBOrderType.Auction]: 'Auction',
  [CMBOrderType.Bid]: 'Bid',
  [CMBOrderType.Offer]: 'Offer',
};

export const EditOrderPage = () => {
  const history = useHistory();
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [order, setOrder] = useState<OTCOrderResource | null>(null);

  const projectsRef = useRef<Array<ApiProject>>([]);
  if (error) {
    throw error;
  }

  const { id } = useParams<{
    id: string;
  }>();

  const { product, changeProduct, changeAvailableProducts } = useMarketplaceProduct();

  const onBack = useCallback(() => {
    history.push('/account/mb-and-auctions/' + id);
  }, []);

  const onConfirm = useCallback(
    async (props: { assetCategoryId: string; formValue: FormValue<CMBOrderType>; creatorUserId: number }) => {
      const { formValue } = props;

      const orderType = toCMBOrderType({
        isAuction: !!order?.isAuction,
        sideId: (order as OTCOrderResource).sideId,
      });

      setIsSubmittingOrder(true);
      const result = await editOrder({
        id,
        orderType,
        formValue,
      });

      setIsSubmittingOrder(false);

      if (result.status === 'ok') {
        history.push('/account/mb-and-auctions/' + id);
        showToast({
          variant: ToastVariant.Success,
          message: (
            <Text variant={TypographyVariant.subtitle2} color={TextColor.inverse}>
              You have successfully edited {titleToOrderTypeMap[orderType]} #{id}
            </Text>
          ),
        });
        return;
      }

      showToast({
        variant: ToastVariant.Danger,
        message: result.error,
      });
    },
    [order],
  );

  useEffect(() => {
    let cancelableLoadData: ReturnType<typeof wait<FetchOtcOrderResponse>>;
    const loadData = async () => {
      setError(null);
      setOrder(null);
      cancelableLoadData = wait(() =>
        fetchOTCOrder({
          id,
        }),
      );
      const order = await cancelableLoadData.job;

      if (order.status === FetchOtcOrderStatus.NotFound) {
        // show not found page
        setError(new NotFoundError());
        return;
      }

      if (order.status === FetchOtcOrderStatus.Error) {
        setError(new Error());
        return;
      }

      try {
        const orderData = order.data as OTCOrderResource;
        const projectIds = toProjectIds(orderData.criteria);

        if (projectIds.length) {
          const projects = await fetchProjects({
            projectIds,
            assetCategoryId: orderData.baseAssetCategoryId,
          });

          projectsRef.current = projects.data;
        }
      } catch (e) {
        logger.error('failed to fetch projects', e);
      }

      if (order.data?.baseAssetCategoryId && order.data?.baseAssetCategoryId !== Number(product)) {
        changeAvailableProducts([String(order.data?.baseAssetCategoryId) as unknown as AssetCategory]);
        changeProduct(String(order.data?.baseAssetCategoryId));
        return;
      }

      setOrder(order.data ?? null);
    };

    loadData();

    return () => {
      setOrder(null);
      if (cancelableLoadData) {
        cancelableLoadData.cancel();
      }
    };
  }, [id, product]);

  const formValue = useMemo(() => {
    if (!order) {
      return undefined;
    }

    const formattedFormValue = toFormValue(order, projectsRef.current);

    return formattedFormValue;
  }, [order]);

  if (!order) {
    return <Loading />;
  }

  const orderType = toCMBOrderType({
    isAuction: !!order?.isAuction,
    sideId: order.sideId,
  });

  return (
    <MarketBoardV2PlaceOrderPage
      formValue={formValue}
      isSubmittingOrder={isSubmittingOrder}
      title={`Edit ${titleToOrderTypeMap[orderType]} #${id}`}
      orderType={orderType}
      onBack={onBack}
      onConfirm={onConfirm}
      isEditing={true}
      isAssetsReservedHidden={true}
    />
  );
};
