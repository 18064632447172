import { useQuery } from 'react-query';

import { fetchPublicTrades } from 'data-provider/trade/fetchPublicTrades';

export function useTradesPublic({
  pairId,
  typeIds,
  page,
  limit,
  status,
}: {
  pairId?: number | string;
  typeIds?: number[];
  page: number;
  limit: number;
  status: 'confirmed';
}) {
  const enabledQuery = pairId && page && limit && status;
  const { data, isLoading, error } = useQuery(
    ['oms-public-trades', pairId, typeIds, page, limit, status],
    () =>
      fetchPublicTrades({
        typeIds,
        page,
        limit,
        status,
        pairId: String(pairId),
      }),
    { enabled: enabledQuery },
  );

  return {
    error,
    isLoading,
    trades: data,
  };
}
