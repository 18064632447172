export default (
  <svg viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 2C0 0.89543 0.895431 0 2 0H27C28.1046 0 29 0.895431 29 2V5C29 6.10457 28.1046 7 27 7H2C0.89543 7 0 6.10457 0 5V2Z"
      fill="#007495"
    />
    <path
      d="M0 12C0 10.8954 0.895431 10 2 10H27C28.1046 10 29 10.8954 29 12V15C29 16.1046 28.1046 17 27 17H2C0.89543 17 0 16.1046 0 15V12Z"
      fill="#007495"
    />
    <path
      d="M0 22C0 20.8954 0.895431 20 2 20H27C28.1046 20 29 20.8954 29 22V25C29 26.1046 28.1046 27 27 27H2C0.89543 27 0 26.1046 0 25V22Z"
      fill="#007495"
    />
  </svg>
);
