import { Pagination as PaginationAntd, PaginationProps } from 'antd';
import 'antd/lib/pagination/style/index.css';
import 'antd/lib/select/style/index.css';
import styled from 'styled-components';

import { ThemeColors, Themes } from './Themes';

const Wrapper = styled.div<{ themeColor: ThemeColors }>`
  display: contents;
  .ant-pagination-item a {
    color: ${(props) => props.themeColor.panel.button.color};
    font-size: 12px;
    background-color: transparent !important;
    &:hover {
      color: ${(props) => props.themeColor.panel.button.color} !important;
    }
  }
  .ant-pagination-item-active {
    border: 1px solid ${(props) => props.themeColor.panel.button.border};
    border-radius: 4px !important;
    background-color: ${(props) => props.themeColor.panel.button.background} !important;
    &:hover {
      border: 1px solid ${(props) => props.themeColor.panel.button.active.border};
    }
    a {
      color: ${(props) => props.themeColor.panel.button.color};
      font-size: 12px;
      background-color: transparent !important;
    }
  }
  .anticon svg {
    path {
      color: ${(props) => props.themeColor.panel.button.color};
    }
  }
  .ant-select {
    .ant-select-selector {
      border: 1px solid ${(props) => props.themeColor.panel.button.active.border} !important;
      color: ${(props) => props.themeColor.panel.button.color} !important;
      background-color: ${(props) => props.themeColor.panel.button.background} !important;
      border-radius: 4px !important;
    }
    .ant-select-selection-item {
      font-size: 12px !important;
    }
  }
  .ant-pagination-options-quick-jumper {
    color: ${(props) => props.themeColor.panel.button.color};
    font-size: 12px !important;
    input {
      border: 1px solid ${(props) => props.themeColor.panel.button.active.border} !important;
      color: ${(props) => props.themeColor.panel.button.color} !important;
      background-color: ${(props) => props.themeColor.panel.button.background} !important;
      border-radius: 4px !important;
    }
  }
`;

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const { theme } = Themes.useContainer();
  return (
    <Wrapper themeColor={theme}>
      <PaginationAntd {...props} />
    </Wrapper>
  );
};

export default Pagination;
