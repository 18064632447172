import { Tip } from 'grommet';
import { isArray } from 'lodash';
import moment from 'moment';
import { CSSProperties, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { queryCache } from 'react-query';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { Text } from 'refreshed-component/atoms/Text';
import { Tooltip } from 'refreshed-component/atoms/Tooltip';
import { Colors, FontSize, Spacing } from 'refreshed-component/design-system';
import { toast } from 'refreshed-component/molecules/toast';
import styled from 'styled-components';

import { formatter, contractParser, TradeType, LocalStorage, AssetCategory } from '@aircarbon/utils-common';

import { AccountDetails } from 'components/SelectOboAccount';

import { Contract } from 'state/contract';
import { UI } from 'state/ui';
import { User } from 'state/user';

import { TradeRequestCancelReason } from 'data-provider/trade/fetchTradeRequestCancelReason';

import { checkIfNonBilateral } from 'utils/checkIfTokenOrRec';
import { formatACXDate } from 'utils/helpers';

import { DropdownItem } from '../components/Button';
import { DialogContext } from '../components/Dialog';
import Pagination from '../components/Pagination';
import Panel, { Search } from '../components/Panel';
import SimpleBar from '../components/Simplebar';
import Table, { TableHeaderOption } from '../components/Table';
import TextArea from '../components/TextArea';
import { Wrapper, Span } from '../components/styled';
import { useUserOrders, useUserSpotTrades, Pair } from '../hooks';
import { useOperationsEmail } from '../hooks/operationsEmail';
import { useCancelOrder } from '../hooks/useCancelOrder';
import useMarketSettings from '../hooks/useMarketSettings';
import { useTradeRequestCancelReason } from '../hooks/useTradeRequestCancelReason';
import { useTradesRequests } from '../hooks/useTradesRequest';
import useUserMarketSettings from '../hooks/useUserMarketSettings';
import { AssetCategoryCode, Layouts } from '../layouts/trading.hook';
import Balance from './Balance';
import TradeReversalToolTip from './TradeReversalToolTip';

const { ethExplorerUrlFromTXID } = contractParser;

const CancelButton = styled.span<{ backgroundColor: string; hoverBorder: string; color: string }>`
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: auto;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  font-size: var(${FontSize.xs});
  padding: var(${Spacing._2xs});
  padding-left: var(${Spacing.xs});
  padding-right: var(${Spacing.xs});
  line-height: 10px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  &:hover {
    border: 1px solid ${(props) => props.hoverBorder};
  }
  &.row-disabled {
    background: ${(props) => props.backgroundColor};
  }
`;

const PlainButton = styled.span<{ backgroundColor: string; hoverBorder: string }>`
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: auto;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  font-size: 10px;
  padding-left: 6px;
  padding-right: 6px;
  line-height: 14px;
  background-color: ${(props) => props.backgroundColor};
  &:hover {
    border: 1px solid ${(props) => props.hoverBorder};
  }
`;

const DisabledCancelButton = styled.span<{ backgroundColor: string }>`
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: auto;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  font-size: 10px;
  padding-left: 6px;
  padding-right: 6px;
  line-height: 14px;
  background-color: ${(props) => props.backgroundColor};
`;

type Props = {
  pair: Pair | undefined;
  height?: 'fixed' | 'auto';
  list?: {
    title: string;
    content?: React.ReactNode;
    style?: CSSProperties;
    _key?: string;
  }[];
  showPnL?: boolean;
  layout?: Layouts;
  selectedAccount: AccountDetails;
  viewOnly?: 'Open Orders' | 'Order History' | 'Trade History' | 'Match History' | 'Balance / P&L';
  assetCategory?: AssetCategoryCode;
  projectId?: string | null;
};

const TRADE_STATUS = {
  3: 'Reversed',
  4: 'Replaced',
};

const tradingScreenOrderLimitKey = 'trading-screen-order-limit';
const tradingScreenTradeLimitKey = 'trading-screen-trade-limit';

const CancelTradeRequestPopupContent = ({
  tradeRequestCancelReason,
  onChange,
  operationsEmail,
}: {
  tradeRequestCancelReason: TradeRequestCancelReason[];
  onChange?: (value: { id: number | null; comment: string | null }) => void;
  operationsEmail: string;
}) => {
  const [selectedItem, setDropdownItem] = useState<DropdownItem | null>(null);
  const [comment, setComment] = useState<string | null>(null);

  operationsEmail = operationsEmail?.split(',').join(', ');
  useEffect(() => {
    onChange?.({
      comment,
      id: selectedItem?.id || null,
    });
  }, [selectedItem, comment]);
  return (
    <div
      style={{ color: `var(${Colors.gray_900})` }}
      className="flex flex-col flex-shrink gap-1 w-11/12 text-sm text-gray-200"
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col flex-1 gap-2 text-left">
          Select Reason:{' '}
          <Dropdown
            key={'to'}
            list={
              tradeRequestCancelReason?.map((item) => ({
                id: item.id,
                label: item.reason,
              })) ?? []
            }
            selected={[selectedItem?.id]}
            onSelectItem={(item) => {
              !item
                ? setDropdownItem(null)
                : setDropdownItem({
                    id: Number(item.id),
                    value: item.label as string,
                  });
            }}
            config={{
              color: 'gray',
              size: 'sm',
            }}
            placeholder="Select account"
          />
        </div>
        <div className="flex flex-col flex-1 gap-2 text-left">
          Comment:{' '}
          <TextArea
            style={{
              height: '80px',
            }}
            onChange={(event) => {
              setComment(event.target.value);
            }}
          />
        </div>
        <Text align="center">*Please provide supporting document to {operationsEmail}</Text>
      </div>
    </div>
  );
};

const OrderHistory = ({
  pair,
  list,
  height,
  showPnL = true,
  layout,
  selectedAccount,
  viewOnly,
  assetCategory = 'token',
  projectId,
}: Props) => {
  const { getSetting } = UI.useContainer();
  const { account } = selectedAccount;
  const { contractJson } = Contract.useContainer();
  const networkId = Number(contractJson?.networkId);
  const {
    selector: { getUserId, getAuthToken },
    status: { isMember, canSeeExternalBrokerClientForBiofuel, canTradeBiofuel, canTradeSpot },
  } = User.useContainer();
  const { showDialog, removeDialog } = useContext(DialogContext);
  const [search, setSearch] = useState<string>('');
  const { marketSettings } = useMarketSettings({});
  const { userMarketSettings } = useUserMarketSettings({
    accountAddress: account,
  });

  const { operationsEmail } = useOperationsEmail();

  const [orderPage, setOrderPage] = useState(1);
  const [orderLimit, setOrderLimit] = useState(LocalStorage.getItem(tradingScreenOrderLimitKey, 15, true));
  const [tradePage, setTradePage] = useState(1);
  const [tradeLimit, setTradeLimit] = useState(LocalStorage.getItem?.(tradingScreenTradeLimitKey, 15, true));

  useEffect(() => {
    LocalStorage.setItem(tradingScreenOrderLimitKey, orderLimit, true);
    LocalStorage.setItem(tradingScreenTradeLimitKey, tradeLimit, true);
  }, [orderLimit, tradeLimit]);

  const { cancelOrder: mutateCancelOrder } = useCancelOrder();

  const pairId = pair?.id;
  const ccyNumDecimals = pair?.quoteAsset?.numDecimals ?? 2;
  const tokenNumDecimals = pair?.baseAsset?.numDecimals ?? 0;
  const isPairOrderCancelationEnabled = pair?.marketFlags?.orderCancelationEnabled === 1;
  const isMarketOrderCancelationEnabled = {
    token: marketSettings?.orderCancelationEnabled === 1,
    fct: marketSettings?.orderCancelationEnabled === 1, // TODO: implement FCT market settings
    biofuel: marketSettings?.biofuelCancelationEnabled === 1,
  };
  const isUserOrderCancelationDisabled =
    !!userMarketSettings &&
    !!Object.keys(userMarketSettings).length &&
    userMarketSettings?.orderCancelationEnabled === 0;

  const isNonBilateralOrIsFCT =
    checkIfNonBilateral(assetCategory) || assetCategory === AssetCategory[AssetCategory.fct];

  const isOrderCancelationEnabled = () => {
    if (isNonBilateralOrIsFCT) {
      return (
        isPairOrderCancelationEnabled &&
        isMarketOrderCancelationEnabled?.[assetCategory] &&
        !isUserOrderCancelationDisabled
      );
    }
    return isPairOrderCancelationEnabled && isMarketOrderCancelationEnabled?.[assetCategory];
  };
  const canTrade = () => (isNonBilateralOrIsFCT ? canTradeSpot() : canTradeBiofuel());

  const [orderTableHeaderOption, setOrderTableHeaderOption] = useState<{
    [key: string]: TableHeaderOption | undefined;
  }>({
    side: {
      option: {
        list: ['Buy', 'Sell'],
        type: 'single',
        default: 'All',
      },
    },
    timeInForce: {
      option: {
        list: ['Day', 'Good Till Cancel', 'Good Till Date'],
        type: 'multiple',
        default: 'All',
      },
    },
    status: {
      option: {
        list: ['Submitted', 'Validating', 'Active', ...(isNonBilateralOrIsFCT ? ['Partially filled'] : [])],
        type: 'multiple',
        default: 'All',
      },
    },
  });

  const [orderHistoryTableHeaderOption, setHistoryOrderTableHeaderOption] = useState<{
    [key: string]: TableHeaderOption | undefined;
  }>({
    side: {
      option: {
        list: ['Buy', 'Sell'],
        type: 'single',
        default: 'All',
      },
    },
    timeInForce: {
      option: {
        list: ['Day', 'Good Till Cancel', 'Good Till Date'],
        type: 'multiple',
        default: 'All',
      },
    },
    status: {
      option: {
        list: [
          'Active',
          ...(isNonBilateralOrIsFCT ? ['Partially filled'] : []),
          'Filled',
          'Canceled',
          'Pending Cancel',
          'Rejected',
          'Expired',
          'Validating',
          'Submitted',
          'Submitted Cancel',
          'Queued Cancel',
        ],
        type: 'multiple',
        default: 'All',
      },
    },
  });

  const [tradeTableHeaderOption, setTradeOrderTableHeaderOption] = useState<{
    [key: string]: TableHeaderOption | undefined;
  }>({
    side: {
      option: {
        list: ['Buy', 'Sell'],
        type: 'single',
        default: 'All',
      },
    },
    status: {
      option: {
        list: ['Confirmed', 'Pending', 'Error'],
        type: 'single',
        default: 'All',
      },
    },
  });

  const [selectedTab, setSelectedTab] = useState<
    'Open Orders' | 'Order History' | 'Trade History' | 'Match History' | 'Balance / P&L'
  >(viewOnly || 'Open Orders');

  const orderAndOrderHistoryTableHeaderOption =
    selectedTab === 'Order History' ? orderHistoryTableHeaderOption : orderTableHeaderOption;

  const orderAndOrderSide = isArray(orderAndOrderHistoryTableHeaderOption.side?.option?.selected)
    ? orderAndOrderHistoryTableHeaderOption.side?.option?.selected.join(',')
    : orderAndOrderHistoryTableHeaderOption.side?.option?.selected;
  const orderAndOrderTimeInForce = isArray(orderAndOrderHistoryTableHeaderOption.timeInForce?.option?.selected)
    ? orderAndOrderHistoryTableHeaderOption.timeInForce?.option?.selected.join(',')
    : orderAndOrderHistoryTableHeaderOption.timeInForce?.option?.selected;
  const orderAndOrderStatusLabel = isArray(orderAndOrderHistoryTableHeaderOption.status?.option?.selected)
    ? orderAndOrderHistoryTableHeaderOption.status?.option?.selected.join(',')
    : orderAndOrderHistoryTableHeaderOption.status?.option?.selected;

  const { orders } = useUserOrders({
    pairId,
    page: orderPage,
    limit: orderLimit,
    searchByOrderId: search,
    status: selectedTab === 'Order History' ? 'closed' : 'opened',
    side: orderAndOrderSide,
    timeInForce: orderAndOrderTimeInForce,
    statusLabel: orderAndOrderStatusLabel,
    accountAddress: account,
    assetCategory,
  });

  const { tradeRequestCancelReason } = useTradeRequestCancelReason();

  const tradeSide = isArray(tradeTableHeaderOption.side?.option?.selected)
    ? tradeTableHeaderOption.side?.option?.selected.join(',')
    : tradeTableHeaderOption.side?.option?.selected;
  const tradeStatus = isArray(tradeTableHeaderOption.status?.option?.selected)
    ? tradeTableHeaderOption.status?.option?.selected.join(',')
    : {
        Confirmed: 'confirmed',
        Error: 'error',
        Pending: 'pending',
      }[tradeTableHeaderOption.status?.option?.selected || ''];

  const { trades } = useUserSpotTrades({
    pairId,
    page: tradePage,
    limit: tradeLimit,
    searchByOrderId: search,
    side: tradeSide,
    status: tradeStatus,
    accountAddress: account,
    assetCategory,
  });

  const { tradeRequests } = useTradesRequests({
    pairId,
    searchByOrderId: search,
    status: 'NEW',
    accountAddress: account,
    assetCategory,
    options: { enabled: assetCategory === 'biofuel' },
  });

  const refreshOrderList = useCallback(() => {
    const filterStatus = selectedTab === 'Order History' ? 'all' : 'opened';
    queryCache.refetchQueries([
      'oms-orders',

      pairId,
      orderPage,
      orderLimit,
      filterStatus,
      search,
      orderAndOrderSide,
      orderAndOrderTimeInForce,
      orderAndOrderStatusLabel,
    ]);
  }, [
    selectedTab,
    pairId,
    orderLimit,
    orderPage,
    search,
    orderAndOrderSide,
    orderAndOrderTimeInForce,
    orderAndOrderStatusLabel,
  ]);

  const refreshTradeList = useCallback(() => {
    queryCache.refetchQueries([
      'oms-user-trades',

      pairId,
      tradePage,
      tradeLimit,
      search,
      tradeSide,
      tradeStatus,
      account,
      assetCategory,
    ]);
    queryCache.refetchQueries([
      'oms-user-trades-request',

      pairId,
      search,
      'NEW',
      account,
      assetCategory,
      assetCategory === 'biofuel',
    ]);
  }, [pairId, tradePage, tradeLimit, search, tradeSide, tradeStatus, assetCategory, account]);

  const rejectTipGenerator = (reason: string) => (
    <>
      <div className="flex flex-row items-center gap-small">
        <Tooltip
          color={Colors.danger_700}
          contentWidth="medium"
          text={
            <Text align="center" size={FontSize.small} color={Colors.danger_700}>
              {reason}
            </Text>
          }
        />
      </div>
    </>
  );

  const placedByTipGenerator = (placedBy: number, placedByName: string) => (
    <div className="flex flex-row items-center gap-xs">
      {placedBy}
      <Tooltip
        text={
          <Text className="whitespace-pre" align="center" size={FontSize.small}>
            {placedByName}
          </Text>
        }
      />
    </div>
  );

  const disabledCancelButtonWithTip = () => {
    let cancelDisabledMessage = isUserOrderCancelationDisabled ? 'Order Cancellation Disabled for This User' : '';
    cancelDisabledMessage = !isPairOrderCancelationEnabled
      ? 'Order Cancellation Disabled for This Pair'
      : cancelDisabledMessage;
    cancelDisabledMessage = !isMarketOrderCancelationEnabled?.[assetCategory]
      ? 'Order Cancellation Disabled'
      : cancelDisabledMessage;

    return (
      <DisabledCancelButton backgroundColor={`var(${Colors.gray_200})`} className="flex justify-center items-center">
        CANCEL{' '}
        <Tooltip
          color={Colors.danger_700}
          width={10}
          height={10}
          text={
            <Text align="center" size={FontSize.small} color={Colors.danger_700}>
              {cancelDisabledMessage}
            </Text>
          }
        />
      </DisabledCancelButton>
    );
  };

  const orderList =
    orders?.total &&
    orders.items.map((item: any) => {
      const isSell = item.__orderSide__.name === 'Sell';
      const orderStatus = item.__status__.status;
      let orderStatusLabel =
        selectedTab === 'Open Orders' && item.outsidePriceLimit ? 'INACTIVE' : item.__status__.statusLabel;
      orderStatusLabel = isNonBilateralOrIsFCT ? orderStatusLabel : orderStatusLabel?.replace(/trade/gi, 'match');
      const orderPlacedBy = item?.placedBy ?? item.userId;
      const canceledStatus = ['Canceled', 'Pending Cancel', 'Draft Cancel', 'Queued Cancel'];
      let statusColor: Colors.success_700 | Colors.danger_700;
      let rejectTip;
      if (['Partially filled', 'Filled'].includes(orderStatus)) statusColor = Colors.success_700;
      else if (orderStatus === 'New') statusColor = Colors.success_700;
      else if (canceledStatus.includes(orderStatus) && item.filled > 0) statusColor = Colors.success_700;
      else statusColor = Colors.danger_700;
      if (item.statusReason) {
        rejectTip = rejectTipGenerator(item.statusReason);
      }
      if (selectedTab === 'Open Orders' && item.outsidePriceLimit) {
        rejectTip = rejectTipGenerator('Order outside price limit.');
      }

      const rowData: any = {
        orderId: item.legacyHxNumber ?? item.id,
        status: (
          <Text
            spacing={Spacing._3xs}
            spacingLR={Spacing.xs}
            background={statusColor === Colors.success_700 ? Colors.success_100 : Colors.danger_100}
            size={FontSize.small}
            color={statusColor}
            lineHeight={18}
          >
            <div className="flex flex-row items-center">
              {String(orderStatusLabel).toUpperCase()} {rejectTip}
            </div>
          </Text>
        ),
        pair: item.__pair__.name,
        side: (
          <Text
            spacing={Spacing._3xs}
            spacingLR={Spacing.xs}
            background={isSell ? Colors.danger_100 : Colors.success_100}
            size={FontSize.small}
            color={isSell ? Colors.danger_700 : Colors.success_700}
            lineHeight={18}
          >
            {item.__orderSide__.name}
          </Text>
        ),
        type: item.__orderType__.name,
        time: formatACXDate({ date: item.createdAtUtc, week: false }),
        timeInForce: item.__orderTimeInForceType__.timeInForce || 'MKT',
        price: item.__orderType__.name !== 'Market' ? formatter.formatNumber(item.price, ccyNumDecimals) : 'MKT',
        qty: formatter.formatNumber(item.qty, tokenNumDecimals),
        filledQty: formatter.formatNumber(item.filled, tokenNumDecimals),
        remainingQty: formatter.formatNumber(item.qty - item.filled, tokenNumDecimals),
        placedBy: item.placedBy
          ? placedByTipGenerator(
              item.placedBy,
              `${item.__placedByUser__?.firstName} ${item.__placedByUser__?.lastName}`,
            )
          : '-',
        client: item.__xOrderBiofuelDetails__?.__externalBrokerClient__?.name || '-',
        fob: item.__xOrderBiofuelDetails__?.__fobType__?.name || 'Any',
        shipping: item.__xOrderBiofuelDetails__?.__shippingType__?.name || 'Any',
      };

      if (selectedTab === 'Open Orders') {
        if (canTrade() && (!isMember() || (isMember() && orderPlacedBy === getUserId()))) {
          rowData.delete = isOrderCancelationEnabled() ? (
            <CancelButton
              backgroundColor={`var(${Colors.danger_100})`}
              hoverBorder={`var(${Colors.danger_400})`}
              color={`var(${Colors.danger_700})`}
              className={`flex justify-center items-center cursor-pointer ${
                item.outsidePriceLimit ? 'row-disabled' : ''
              }`}
              onClick={() => {
                cancelOrderOnclick(item.id, orderStatus);
              }}
            >
              CANCEL
            </CancelButton>
          ) : (
            disabledCancelButtonWithTip()
          );
        }
        if (item.outsidePriceLimit) {
          rowData.specialClassName = 'row-disabled';
        } else {
          rowData.specialClassName = undefined;
        }
      }
      return rowData;
    });

  function getBiofuelTradeRecord(rawTrade: any) {
    const tradeFromUserPerspective =
      rawTrade.__bfTakerOrder__.userId === Number(getUserId()) ? rawTrade.__bfTakerOrder__ : rawTrade.__bfMakerOrder__;

    return {
      pair: tradeFromUserPerspective.__pair__.name,
      price: rawTrade.__bfMakerOrder__.price, // Price should be maker order price, because BF match is based on it.
      qty: tradeFromUserPerspective.qty,
      orderId: tradeFromUserPerspective.id,
      side: tradeFromUserPerspective.__orderSide__.name,
      tradeFee: tradeFromUserPerspective.tradeFee,
      client: tradeFromUserPerspective?.__xOrderBiofuelDetails__?.__externalBrokerClient__?.name,
      fob: tradeFromUserPerspective?.__xOrderBiofuelDetails__?.__fobType__?.name || 'Any',
      shipping: tradeFromUserPerspective?.__xOrderBiofuelDetails__?.__shippingType__?.name || 'Any',
    };
  }

  const tradeList =
    trades &&
    trades?.total > 0 &&
    trades.items.map((data: any) => {
      let pair, orderId, side, tradeFee, fob, shipping: string;
      let tradeIdLabel;
      let txStatus;
      let item = data.tradeId ? data.__trade__ : data;
      let isRatable: boolean | null = null;
      let price;
      let qty;
      let status;
      let client;

      // TODO: data.tradeId is null in case it is a NEW trade request (in other cases it might have value or not exist at all)
      if (data.tradeId === null) {
        tradeIdLabel = '-';

        ({ pair, price, qty, orderId, side, tradeFee, client, fob, shipping } = getBiofuelTradeRecord(item));

        status = (
          <Text
            spacing={Spacing._3xs}
            spacingLR={Spacing.xs}
            background={Colors.danger_100}
            size={FontSize.small}
            color={Colors.danger_700}
            lineHeight={18}
          >
            {item.status}
          </Text>
        );
      } else {
        const makerOrder = item.__makerOrder__ || data.__bfMakerOrder__;
        const takerOrder = item.__takerOrder__ || data.__bfTakerOrder__;
        const transaction = item.__transaction__ || data.__transaction__;

        if (data.tradeTypeId === TradeType.Bilateral) {
          ({ pair, orderId, side, tradeFee, client, fob, shipping } = getBiofuelTradeRecord(data));
        } else {
          if (makerOrder.userId === Number(getUserId())) {
            pair = makerOrder.__pair__.name;
            orderId = makerOrder.id;
            side = makerOrder.__orderSide__.name;
            tradeFee = item.makerTradeFee;
            client = makerOrder?.__xOrderBiofuelDetails__?.__externalBrokerClient__?.name;
            fob = makerOrder?.__xOrderBiofuelDetails__?.__fobType__?.name || 'Any';
            shipping = makerOrder?.__xOrderBiofuelDetails__?.__shippingType__?.name || 'Any';
          } else {
            pair = takerOrder.__pair__.name;
            orderId = takerOrder.id;
            side = takerOrder.__orderSide__.name;
            tradeFee = item.takerTradeFee;
            client = takerOrder?.__xOrderBiofuelDetails__?.__externalBrokerClient__?.name;
            fob = takerOrder?.__xOrderBiofuelDetails__?.__fobType__?.name || 'Any';
            shipping = takerOrder?.__xOrderBiofuelDetails__?.__shippingType__?.name || 'Any';
          }

          // If user is both the maker and taker
          if (makerOrder.userId === Number(getUserId()) && takerOrder.userId === Number(getUserId())) {
            side = `${makerOrder.__orderSide__.name} / ${takerOrder.__orderSide__.name}`;
          }
        }

        txStatus = [3, 4].includes(item.statusId)
          ? TRADE_STATUS[item.statusId as 3 | 4]
          : item.transactionId
            ? transaction?.txStatus
            : 'pending';
        tradeIdLabel =
          item.transactionId && transaction?.txHash && transaction.txStatus === 'confirmed' ? (
            <a
              href={window.location.origin + '/explore/tx/' + transaction?.txHash}
              className="flex flex-row gap-1 justify-center items-center"
              target="_blank"
              rel="noreferrer"
            >
              {item.legacyHxNumber ?? item.id}
              <svg width="12" height="12" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20 5L10 5C8.89543 5 8 5.89543 8 7L8 41C8 42.1046 8.89543 43 10 43L38 43C39.1046 43 40 42.1046 40 41L40 24.75"
                  stroke="#b7bdc6"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M28 5H40V17" stroke="#b7bdc6" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path
                  d="M21.0002 23.9998L39.0001 6"
                  stroke="#b7bdc6"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          ) : (
            item.legacyHxNumber ?? item.id
          );

        price = formatter.formatNumber(item.price, ccyNumDecimals);
        qty = formatter.formatNumber(item.qty, tokenNumDecimals);
        status = (
          <Span
            color={
              txStatus === 'confirmed' || !isNonBilateralOrIsFCT
                ? `var(${Colors.success_700})`
                : `var(${Colors.danger_700})`
            }
          >
            <div className="flex flex-row">
              {!isNonBilateralOrIsFCT ? 'CONFIRMED'.toUpperCase() : String(txStatus).toUpperCase()}{' '}
              {[3, 4].includes(item.statusId) && (
                <TradeReversalToolTip tradeId={item.id} replacingTradeId={item?.replacingTradeId} />
              )}
            </div>
          </Span>
        );

        const deliveryDays = (parseInt(shipping.replace('weeks', '').replace('week', '')) + 1) * 7;
        const now = moment(new Date());
        const end = moment(item.createdAtUtc);
        const duration = moment.duration(now.diff(end));
        const diffDays = duration.asDays();
        isRatable = diffDays > deliveryDays && diffDays <= 60;
      }

      return {
        _key: data.id,
        tradeId: tradeIdLabel,
        orderId,
        status,
        tradeDate: formatACXDate({ date: item.createdAtUtc, week: false }),
        side: (
          <Text
            spacing={Spacing._3xs}
            spacingLR={Spacing.xs}
            background={side === 'Sell' ? Colors.danger_100 : Colors.success_100}
            size={FontSize.small}
            color={side === 'Sell' ? Colors.danger_700 : Colors.success_700}
            lineHeight={18}
          >
            {side}
          </Text>
        ),
        pair,
        qty,
        unitPrice: price,
        tradeFee: formatter.formatNumber(tradeFee, ccyNumDecimals) ?? '-',
        specialClassName: [3, 4].includes(item.statusId) ? 'row-disabled' : undefined,
        client: client || '-',
        fob,
        shipping,
        actions: (
          <div className="flex flex-row gap-2">
            {isRatable ? (
              <PlainButton
                backgroundColor={`var(${Colors.gray_100})`}
                hoverBorder={`var(${Colors.gray_200})`}
                className={`flex justify-center items-center cursor-pointer`}
              >
                RATE
              </PlainButton>
            ) : isRatable === false ? (
              <Tip
                content={
                  <div
                    style={{
                      backgroundColor: `var(${Colors.gray_50})`,
                      color: `var(${Colors.gray_900})`,
                      border: `1px solid var(${Colors.gray_200})`,
                      fontSize: '12px',
                      borderRadius: '10px',
                      padding: '4px',
                      paddingLeft: '12px',
                      paddingRight: '12px',
                      whiteSpace: 'pre',
                    }}
                  >
                    {`${isNonBilateralOrIsFCT ? 'Trade' : 'Match'} request is not ratable at the moment`}
                  </div>
                }
                dropProps={{ align: { bottom: 'top', left: 'right' } }}
                plain={true}
              >
                <div className="flex flex-row items-center mr-1">
                  <img className="ml-1 text-red-200" height="15px" width="15px" src="/info-red.png" alt="info" />
                </div>
              </Tip>
            ) : (
              ''
            )}
          </div>
        ),
      };
    });

  const tradeRequestList =
    tradeRequests?.total > 0 &&
    tradeRequests.items.map((item: any) => {
      const { pair, price, qty, orderId, side, tradeFee, client, fob, shipping } = getBiofuelTradeRecord(item);

      const now = moment(new Date());
      const end = moment(item.createdAtUtc);
      const duration = moment.duration(now.diff(end));
      const diffMinutes = duration.asMinutes();
      const allowedMinutes = Number(getSetting('biofuel_order_cancellation_timeout_minutes'));
      const isCancelable = diffMinutes < allowedMinutes;

      return {
        _key: `${orderId}-${item.status}`,
        tradeId: '-',
        orderId,
        status: (
          <Text
            spacing={Spacing._3xs}
            spacingLR={Spacing.xs}
            background={Colors.danger_100}
            size={FontSize.small}
            color={Colors.danger_700}
            lineHeight={18}
          >
            PENDING
          </Text>
        ),
        tradeDate: formatACXDate({ date: item.createdAtUtc, week: false }),
        side: (
          <Text
            spacing={Spacing._3xs}
            spacingLR={Spacing.xs}
            background={side === 'Sell' ? Colors.danger_100 : Colors.success_100}
            size={FontSize.small}
            color={side === 'Sell' ? Colors.danger_700 : Colors.success_700}
            lineHeight={18}
          >
            {side}
          </Text>
        ),
        pair: pair,
        qty: formatter.formatNumber(item.quantity, tokenNumDecimals),
        unitPrice: formatter.formatNumber(item.price, ccyNumDecimals),
        tradeFee: formatter.formatNumber(tradeFee, ccyNumDecimals) ?? '-',
        specialClassName: [3, 4].includes(item.statusId) ? 'row-disabled' : undefined,
        client,
        fob,
        shipping,
        actions: (
          <div className="flex flex-row gap-2">
            {isCancelable && canTrade() ? (
              <CancelButton
                backgroundColor={`var(${Colors.danger_100})`}
                hoverBorder={`var(${Colors.danger_400})`}
                color={`var(${Colors.danger_700})`}
                className={`flex justify-center items-center cursor-pointer`}
                onClick={() => cancelTradeRequestOnclick(item.id, Number(orderId))}
              >
                CANCEL
              </CancelButton>
            ) : (
              <Tooltip
                color={Colors.danger_700}
                contentWidth="medium"
                text={
                  <Text align="center" size={FontSize.small} color={Colors.danger_700}>
                    {`${isNonBilateralOrIsFCT ? 'Trade' : 'Match'} request can not be cancelled`}
                  </Text>
                }
              />
            )}
          </div>
        ),
      };
    });

  const tradesToShow = [...(tradeRequestList || []), ...(tradeList || [])];

  const cancelOrder = async (orderId: number, status: string) => {
    if (['NEW', 'DRAFT', 'QUEUED', 'SUBMITTED', 'PARTIALLY FILLED'].includes(status.toUpperCase())) {
      const cancel = await mutateCancelOrder(orderId);
      if (cancel?.id) {
        queryCache.invalidateQueries('oms-orders');
      } else {
        const errorResponse = cancel?.json;
        toast.error(errorResponse ? errorResponse.message : 'Something went wrong!');
      }
    } else {
      toast.error('Order is not in open status');
    }
  };

  const cancelOrderOnclick = (orderId: number, status: string) => {
    showDialog?.({
      title: 'Cancel Order',
      message: <Text align="center">{`Are you sure want cancel order no #${orderId}?`}</Text>,
      cancel: {
        onClick: () => {
          removeDialog?.();
        },
      },
      accept: {
        onClick: () => {
          cancelOrder(orderId, status);
          removeDialog?.();
        },
      },
    });
  };

  const cancelTradeRequest = async ({
    tradeRequestId,
    comment,
    cancelReasonId,
    orderId,
  }: {
    tradeRequestId: number;
    comment?: string | null;
    cancelReasonId?: number;
    orderId?: number;
  }) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    const cancel = await fetch(`/api/oms/trade/cancel-biofuel-trade-request`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        tradeRequestId: Number(tradeRequestId),
        cancelReasonId: Number(cancelReasonId),
        comment: comment || '',
      }),
    });
    if (!cancel?.ok) {
      const error = await cancel?.json();
      toast.error(error?.message ?? 'Something went wrong!');
    } else {
      toast.success(`Your ${isNonBilateralOrIsFCT ? 'trade' : 'match'} (order #${orderId}) is canceled.`);
    }
    refreshTradeList();
  };

  const tradeRequestFormData = useMemo(() => {
    return {} as {
      cancelReasonId?: number | null;
      comment?: string | null;
      isFormValid?: boolean;
    };
  }, []);

  const cancelTradeRequestOnclick = (tradeRequestId: number, orderId: number) => {
    tradeRequestFormData.cancelReasonId = null;
    tradeRequestFormData.comment = null;
    tradeRequestFormData.isFormValid = false;
    showDialog?.({
      title: `Cancel ${isNonBilateralOrIsFCT ? 'Trade' : 'Match'} Request`,
      message: (
        <div className="flex flex-col gap-5 items-center w-full h-auto" style={{ minWidth: '350px' }}>
          <Text align="center">
            {`Are you sure you want cancel ${isNonBilateralOrIsFCT ? 'trade' : 'match'} request no #${tradeRequestId}?`}
          </Text>
          {!!tradeRequestCancelReason?.length && (
            <CancelTradeRequestPopupContent
              tradeRequestCancelReason={tradeRequestCancelReason}
              onChange={(value) => {
                tradeRequestFormData.cancelReasonId = value.id;
                tradeRequestFormData.comment = value.comment;
                tradeRequestFormData.isFormValid =
                  tradeRequestFormData?.cancelReasonId !== undefined && tradeRequestFormData.cancelReasonId !== null;
              }}
              operationsEmail={operationsEmail ?? ''}
            />
          )}
        </div>
      ),
      cancel: {
        onClick: () => {
          removeDialog?.();
        },
      },
      accept: {
        onClick: () => {
          if (!tradeRequestFormData.isFormValid) {
            toast.error('Please select reason.');
          } else {
            cancelTradeRequest({
              tradeRequestId,
              comment: tradeRequestFormData.comment,
              cancelReasonId: tradeRequestFormData.cancelReasonId as number,
              orderId,
            });
            removeDialog?.();
          }
        },
      },
    });
  };

  useEffect(() => {
    setOrderPage(1);
    setTradePage(1);
  }, [selectedTab]);

  useEffect(() => {
    refreshOrderList();
  }, [refreshOrderList]);

  useEffect(() => {
    refreshTradeList();
  }, [refreshTradeList]);

  const tableBackgroundColor = layout !== 'largeOrWideDesktop' ? `var(${Colors.gray_0})` : '';

  const body = (
    <>
      <div className="flex relative flex-row flex-auto pr-3">
        {selectedTab === 'Open Orders' && (
          <Table
            config={{
              headerOption: {
                value: orderTableHeaderOption,
                onChange: (value) => {
                  setOrderTableHeaderOption(value);
                },
              },
              backgroundColor: tableBackgroundColor,
              columns: {
                orderId: {
                  label: 'ORDER ID',
                },
                status: {
                  label: 'STATUS',
                },
                pair: {
                  label: 'PAIR',
                  font: 'code',
                },
                side: {
                  label: 'SIDE',
                },
                type: {
                  label: 'TYPE',
                },
                time: {
                  label: 'TIME',
                },
                timeInForce: {
                  label: 'TIF',
                },
                price: {
                  label: 'PRICE',
                  align: 'right',
                  font: 'code',
                },
                qty: {
                  label: 'QUANTITY',
                  align: 'right',
                  font: 'code',
                },
                ...(!isNonBilateralOrIsFCT
                  ? {
                      ...(canSeeExternalBrokerClientForBiofuel()
                        ? {
                            client: {
                              label: 'CLIENT',
                              align: 'right',
                            },
                          }
                        : {}),
                      fob: {
                        label: 'FOB',
                        align: 'right',
                      },
                      shipping: {
                        label: 'SHIPPING',
                        align: 'right',
                      },
                    }
                  : {}),

                ...(!isNonBilateralOrIsFCT
                  ? {}
                  : {
                      filledQty: {
                        label: 'FILLED',
                        align: 'right',
                        font: 'code',
                      },
                      remainingQty: {
                        label: 'REMAINING',
                        align: 'right',
                        font: 'code',
                      },
                    }),

                placedBy: {
                  label: 'PLACED BY',
                },
                delete: {
                  label: '',
                  skipSpecialClass: true,
                },
              },
              rows: orderList || [],
            }}
          />
        )}
        {selectedTab === 'Order History' && (
          <Table
            config={{
              headerOption: {
                value: orderHistoryTableHeaderOption,
                onChange: (value) => {
                  setHistoryOrderTableHeaderOption(value);
                },
              },
              backgroundColor: tableBackgroundColor,
              columns: {
                orderId: {
                  label: 'ORDER ID',
                },
                status: {
                  label: ' STATUS',
                  align: 'left',
                },
                pair: {
                  label: 'PAIR',
                  font: 'code',
                },
                side: {
                  label: 'SIDE',
                },
                type: {
                  label: 'TYPE',
                },
                time: {
                  label: 'TIME',
                },
                timeInForce: {
                  label: 'TIF',
                },
                price: {
                  label: 'PRICE',
                  align: 'right',
                  font: 'code',
                },
                qty: {
                  label: 'QUANTITY',
                  align: 'right',
                  font: 'code',
                },
                ...(!isNonBilateralOrIsFCT
                  ? {
                      ...(canSeeExternalBrokerClientForBiofuel()
                        ? {
                            client: {
                              label: 'CLIENT',
                              align: 'right',
                            },
                          }
                        : {}),
                      fob: {
                        label: 'FOB',
                        align: 'right',
                      },
                      shipping: {
                        label: 'SHIPPING',
                        align: 'right',
                      },
                    }
                  : {}),

                ...(!isNonBilateralOrIsFCT
                  ? {}
                  : {
                      filledQty: {
                        label: 'FILLED',
                        align: 'right',
                        font: 'code',
                      },
                      remainingQty: {
                        label: 'REMAINING',
                        align: 'right',
                        font: 'code',
                      },
                    }),

                placedBy: {
                  label: 'PLACED BY',
                },
              },
              rows: orderList || [],
            }}
          />
        )}
        {(selectedTab === 'Trade History' || selectedTab === 'Match History') && (
          <Table
            config={{
              headerOption: {
                value: tradeTableHeaderOption,
                onChange: (value) => {
                  setTradeOrderTableHeaderOption(value);
                },
              },
              backgroundColor: tableBackgroundColor,
              columns: {
                tradeId: {
                  label: isNonBilateralOrIsFCT ? 'TRADE ID' : 'MATCH ID',
                },
                orderId: {
                  label: 'ORDER ID',
                },
                status: {
                  label: 'STATUS',
                },
                ...(!isNonBilateralOrIsFCT
                  ? {
                      actions: {
                        label: 'ACTIONS',
                      },
                    }
                  : {}),
                tradeDate: {
                  label: 'TIME',
                },
                pair: {
                  label: 'PAIR',
                  font: 'code',
                },
                side: {
                  label: 'SIDE',
                },
                qty: {
                  label: 'QUANTITY',
                  align: 'right',
                  font: 'code',
                },
                ...(!isNonBilateralOrIsFCT
                  ? {
                      ...(canSeeExternalBrokerClientForBiofuel()
                        ? {
                            client: {
                              label: 'CLIENT',
                              align: 'right',
                            },
                          }
                        : {}),
                      fob: {
                        label: 'FOB',
                        align: 'right',
                      },
                      shipping: {
                        label: 'SHIPPING',
                        align: 'right',
                      },
                    }
                  : {}),
                unitPrice: {
                  label: 'UNIT PRICE',
                  align: 'right',
                  font: 'code',
                },
                tradeFee: {
                  label: isNonBilateralOrIsFCT ? 'TRADE FEE' : 'MATCH FEE',
                  align: 'right',
                  font: 'code',
                },
              },
              rows: tradesToShow || [],
            }}
          />
        )}
        {selectedTab === 'Balance / P&L' && (
          <Balance withScrollBar={false} selectedAccount={selectedAccount} projectId={projectId} />
        )}
      </div>
      {['Open Orders', 'Order History'].includes(selectedTab) ? (
        <div className="flex flex-row justify-end pr-3 mt-2">
          <div>
            {Number(orders?.total ?? 0) > orderLimit && (
              <div className="pb-4 w-full pagination-wrapper">
                <Pagination
                  size="small"
                  current={orderPage}
                  onChange={(page, pageSize) => {
                    if (orderLimit !== pageSize) {
                      setOrderLimit(pageSize);
                      setOrderPage(1);
                    } else {
                      setOrderPage(page);
                    }
                  }}
                  pageSize={orderLimit}
                  total={Number(orders?.total ?? 0)}
                  showSizeChanger={true}
                  showQuickJumper={true}
                  pageSizeOptions={[15, 20, 30, 40, 50, 100]}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {selectedTab === 'Trade History' || selectedTab === 'Match History' ? (
        <div className="flex flex-row justify-end mt-2">
          <div>
            {Number(trades?.total ?? 0) > tradeLimit && (
              <div className="w-full pagination-wrapper">
                <Pagination
                  size="small"
                  current={tradePage}
                  onChange={(page, pageSize) => {
                    if (tradeLimit !== pageSize) {
                      setTradeLimit(pageSize);
                      setTradePage(1);
                    } else {
                      setTradePage(page);
                    }
                  }}
                  pageSize={tradeLimit}
                  total={Number(trades?.total ?? 0)}
                  showSizeChanger={true}
                  pageSizeOptions={[15, 20, 30, 40, 50, 100]}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );

  const { update } = useMemo(() => {
    let timer: NodeJS.Timeout;
    let lastUpdated = 0;
    function update(value: string) {
      const time = new Date().getTime();
      if (time - lastUpdated >= 500) {
        lastUpdated = time;
        setSearch(value);
      } else {
        clearTimeout(timer);
        timer = setTimeout(() => {
          clearTimeout(timer);
          update(value);
        }, 500);
      }
    }
    return {
      update,
    };
  }, []);

  if (viewOnly) {
    return (
      <Wrapper className="flex flex-col w-full h-full">
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-row w-full">
            <div
              className="flex flex-auto items-center"
              style={{
                color: `var(${Colors.gray_900})`,
              }}
            >
              {`${selectedAccount.fullName} [${selectedAccount.userId}]`}
            </div>
            <div>
              <Search
                style={{
                  position: 'relative',
                  top: 'auto',
                  left: 'auto',
                  right: 'auto',
                }}
                backgroundColor={`var(${Colors.gray_100})`}
                borderColor={`var(${Colors.gray_200})`}
                color={`var(${Colors.gray_900})`}
                placeholder={'Search by Order ID'}
                value={search}
                onChange={(event) => {
                  update(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex relative flex-auto w-full">
            <div className="absolute w-full h-full">
              <SimpleBar>{body}</SimpleBar>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }

  const content =
    height !== 'auto' ? (
      <Wrapper className="flex overflow-auto flex-col w-full h-full">
        <SimpleBar>{body}</SimpleBar>
      </Wrapper>
    ) : (
      body
    );

  const isOrderContent =
    selectedTab === 'Open Orders' ||
    selectedTab === 'Order History' ||
    selectedTab === 'Trade History' ||
    selectedTab === 'Match History';
  const isBalanceContent = selectedTab === 'Balance / P&L';

  return (
    <div className="relative w-full h-full">
      <Panel
        height={height}
        selected={selectedTab}
        onChange={(label) => setSelectedTab(label as any)}
        contentBodyStyle={{
          minHeight: layout === 'mobile' ? '300px' : undefined,
          paddingTop: isOrderContent || isBalanceContent ? '0px' : '10px',
        }}
        contentAfterHeader={
          isOrderContent || isBalanceContent ? (
            <div
              className={'w-full flex flex-row item items-center'}
              style={{
                paddingTop: '6px',
                height: '34px',
              }}
            >
              <div
                className="flex flex-auto"
                style={{
                  color: `var(${Colors.gray_900})`,
                }}
              >
                {`${selectedAccount.fullName} [${selectedAccount.userId}]`}
              </div>
              {isOrderContent && (
                <div>
                  <Search
                    style={{
                      position: 'relative',
                      top: 'auto',
                      left: 'auto',
                      right: 'auto',
                    }}
                    backgroundColor={`var(${Colors.gray_100})`}
                    borderColor={`var(${Colors.gray_200})`}
                    color={`var(${Colors.gray_900})`}
                    placeholder={'Search by Order ID'}
                    value={search}
                    onChange={(event) => {
                      update(event.target.value);
                    }}
                  />
                </div>
              )}
            </div>
          ) : undefined
        }
        list={[
          {
            title: 'Open Orders',
            content: content,
          },
          {
            title: 'Order History',
            content: content,
          },
          {
            title: isNonBilateralOrIsFCT ? 'Trade History' : 'Match History',
            content: content,
          },
          ...(showPnL
            ? [
                {
                  title: 'Balance / P&L',
                  content: content,
                },
              ]
            : []),
          ...(list || []),
        ]}
      />
    </div>
  );
};

export default OrderHistory;
