import { AssetCategory } from '@aircarbon/utils-common';

/**
 * Transforms asset category into min and max lots value for the asset
 */
export const toAssetMinMaxLots = ({
  assetCategory,
  getSetting,
}: {
  assetCategory: AssetCategory;
  getSetting: (setting: string) => any;
}) => {
  if (assetCategory === AssetCategory.rec) {
    return {
      askMinLots: Number(getSetting('global_emb_askMinLots_rec') || 1),
      askMaxLots: Number(getSetting('global_emb_askMaxLots_rec') || 1),
      bidMinLots: Number(getSetting('global_emb_bidMinLots_rec') || 1),
    };
  }

  return {
    askMinLots: Number(getSetting('global_emb_askMinLots') || 1),
    askMaxLots: Number(getSetting('global_emb_askMaxLots') || 1),
    bidMinLots: Number(getSetting('global_emb_bidMinLots') || 1),
  };
};
