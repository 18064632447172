import { Colors, Radius } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Themes } from './Themes';

type Size = 'small' | 'medium' | 'big';

type WrapperProps = {
  size: Size;
};

const Wrapper = styled.textarea.attrs({
  type: 'textarea',
})`
  width: 100%;
  font-size: 12px;
  color: var(${Colors.gray_900});
  background: var(${Colors.gray_50});
  border-radius: 6px;
  border-radius: var(${Radius.medium});
  padding: 8px;
  border: 1px solid var(${Colors.gray_300});
  &:focus {
    background: var(${Colors.gray_200});
    outline: var(${Colors.gray_100}) solid 3px !important;
  }
  &:hover {
    background: var(${Colors.gray_100});
    border: 1px solid var(${Colors.gray_300});
  }
  &:disabled {
    background-color: var(${Colors.gray_300});
  }
  ${(props: WrapperProps) => {
    if (props.size === 'big') {
      return `height: 30px;`;
    } else if (props.size === 'small') {
      return `height: 22px;`;
    } else {
      return `height: 26px;`;
    }
  }}
`;

export default function TextArea({
  onEnter,
  ...props
}: React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
  forwardRef?: React.LegacyRef<HTMLTextAreaElement>;
  onEnter?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}) {
  const { theme } = Themes.useContainer();
  return (
    <Wrapper
      config={theme.placeHolder.input}
      {...(props as any)}
      ref={props.forwardRef}
      onKeyDownCapture={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
          onEnter?.(event);
        }
        props?.onKeyDownCapture?.(event);
      }}
    />
  );
}
