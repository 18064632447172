import { useCallback, useMemo, useRef, useState } from 'react';

import {
  Text,
  Link,
  LinkColor,
  ModalStack,
  ToastVariant,
  TypographyVariant,
  showToast,
  TextColor,
} from '@aircarbon/ui';

import { OrderDetails, PlaceOrderModal } from '../components/PlaceOrderModal';
import { BidFormValue, OfferFormValue } from '../components/PlaceOrderModal/hooks/usePlaceOrderForm';
import { createTradeRequest } from '../utils/createTradeRequest';
import { globalEventsEmitter } from '../utils/globalEventsEmitter';
import { toCreateTradeRequestBody } from '../utils/toCreateTradeRequestBody';
import { toSelectProjectCriteria } from '../utils/toSelectProjectCriteria';

export const usePlaceOrderModal = ({
  onPressShowOpenRequests,
  refetchListings,
}: {
  onPressShowOpenRequests(): void;
  refetchListings?: () => void;
}) => {
  const [orderDetails, setOrderDetails] = useState<OrderDetails | undefined>();
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false);
  const orderCriteriaRef = useRef<Array<{ key: string; value: string }>>([]);
  const onConfirmOrder = async (formValue: BidFormValue | OfferFormValue, onBehalfOfUserId: number) => {
    const currentOrderDetails = orderDetails as OrderDetails;
    setIsSubmittingOrder(true);
    const result = await createTradeRequest(
      toCreateTradeRequestBody({
        formValue,
        orderId: currentOrderDetails.id,
        senderUserId: onBehalfOfUserId,
        orderType: currentOrderDetails.type,
      }),
    );

    setIsSubmittingOrder(false);

    if (!result.success) {
      showToast({
        variant: ToastVariant.Danger,
        message: result.message,
      });
      return;
    }

    setOrderDetails(undefined);
    if (refetchListings) refetchListings();
    globalEventsEmitter.emit('tradeRequestCreated');
    showToast({
      variant: ToastVariant.Success,
      message: (
        <Text variant={TypographyVariant.subtitle2} color={TextColor.inverse}>
          You have successfully placed your request. You can review it in{' '}
          <Link color={LinkColor.Primary} onPress={onPressShowOpenRequests}>
            My Responses
          </Link>
        </Text>
      ),
    });
  };

  const onClosePlaceOrderModal = useCallback(() => {
    setOrderDetails(undefined);
  }, []);

  const modal = useMemo(
    () => (
      <ModalStack>
        <PlaceOrderModal
          isSubmittingOrder={isSubmittingOrder}
          onConfirmOrder={onConfirmOrder}
          orderDetails={orderDetails}
          orderCriteria={toSelectProjectCriteria(orderCriteriaRef.current)}
          onClose={onClosePlaceOrderModal}
        />
      </ModalStack>
    ),
    [orderDetails, isSubmittingOrder],
  );

  const open = useCallback(
    (props: {
      orderDetails: OrderDetails;
      orderCriteria: Array<{
        key: string;
        value: string;
      }>;
    }) => {
      orderCriteriaRef.current = props.orderCriteria;
      setOrderDetails(props.orderDetails);
    },
    [],
  );

  return { modal, open };
};
