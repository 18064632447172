import { userAuthenticatedApi } from '../fetcher';

export interface ShippingTypes {
  id: number;
  name: string;
}

export const fetchShippingTypes = async () => {
  const endpoint = '/oms/order/shipping-types';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).get();
  return res.json<ShippingTypes[]>();
};
