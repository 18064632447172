import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

export const Balance: React.FC<{
  title: string;
  value: string;
}> = ({ title, value }) => {
  return (
    <StyledBalance>
      <Logo>
        <img src={'/logos/GER.png'} />
      </Logo>
      <Content>
        <Text color={Colors.gray_500}>{title}</Text>
        <Text size={FontSize._2Xl} weight={FontWeight.semibold}>
          {value}
        </Text>
      </Content>
    </StyledBalance>
  );
};

const StyledBalance = styled.div`
  display: flex;
  border-radius: 8px;
  gap: 8px;
  background-color: var(${Colors.gray_100});
  padding: 24px;
`;

const Logo = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  background-color: var(${Colors.gray_0});
  border: 1px solid var(${Colors.gray_300});
  border-radius: 50%;

  & > img {
    width: 80%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
