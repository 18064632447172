import { forwardRef, useImperativeHandle, useState } from 'react';
import { FieldsGroup } from 'refreshed-pages/market-board-v2/components/FieldsGroup';
import * as yup from 'yup';

import { InputText, InputTextType } from '@aircarbon/ui';
import { AssetCategory, assetCategoryUom } from '@aircarbon/utils-common';

import { useEntity } from 'state/entity';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

export interface QuantityAndPriceRef {
  /**
   * Validates the form.
   * Returns `true` if form has validation errors
   */
  validate(): boolean;
}

const validator = yup.object().shape({
  price: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .moreThan(0, 'Price should be more than 0')
    .required('Please provide Price'),
  quantity: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .moreThan(0, 'Quantity should be more than 0')
    .test({
      name: 'max',
      params: { max: yup.ref('$openQuantity'), uom: yup.ref('$uom') },
      message: ({ max, uom }) =>
        `Quantity should not exceed open quantity of ${new Intl.NumberFormat('en-US', {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }).format(max)} ${uom}`,
      test: (value, context) => value && ((value <= context.options.context?.openQuantity) as any),
    })
    .required('Please provide Quantity'),
});

export const QuantityAndPrice = forwardRef<
  QuantityAndPriceRef,
  {
    value: {
      quantity: string;
      price: string;
    };
    openQuantity: number;
    onChange(value: { quantity: string; price: string }): void;
  }
>((props, ref) => {
  const { value, openQuantity, onChange } = props;
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>({});
  const { product } = useMarketplaceProduct();
  const uom = assetCategoryUom[Number(product) as AssetCategory];
  const { selector } = useEntity();
  const currencyCode = selector.mainCcySymbol;

  useImperativeHandle(ref, () => ({
    validate() {
      setDirtyFields({});
      try {
        validator.validateSync(value, {
          abortEarly: false,
          context: {
            openQuantity,
            uom,
          },
        });
      } catch (e) {
        setErrors(
          (e as yup.ValidationError).inner.reduce((acc, curr) => ({ ...acc, [curr.path as string]: curr.message }), {}),
        );
        return true;
      }

      setErrors({});
      return false;
    },
  }));

  return (
    <FieldsGroup>
      <InputText
        value={value.quantity}
        onChange={(e) => {
          setDirtyFields((prevFields) => ({
            ...prevFields,
            quantity: true,
          }));
          onChange({ ...value, quantity: e.target.value });
        }}
        label={'Quantity'}
        type={InputTextType.Number}
        error={!dirtyFields.quantity ? errors.quantity : undefined}
        suffix={uom}
      />
      <InputText
        onChange={(e) => {
          setDirtyFields((prevFields) => ({
            ...prevFields,
            price: true,
          }));
          onChange({ ...value, price: e.target.value });
        }}
        value={value.price}
        label={`Price (per ${uom})`}
        type={InputTextType.Number}
        error={!dirtyFields.price ? errors.price : undefined}
        suffix={currencyCode}
      />
    </FieldsGroup>
  );
});
