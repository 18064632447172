import { Auth, type CognitoUser } from '@aws-amplify/auth';
import {
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from 'amazon-cognito-identity-js';
import QRCode from 'qrcode';
import type * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'refreshed-component/atoms/Button';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight, Radius, Spacing } from 'refreshed-component/design-system';
import { toast } from 'refreshed-component/molecules/toast';
import styled from 'styled-components';

import { logger } from '@aircarbon/utils-common';

import { Themes } from 'pages/account/trading/components/Themes';

import FormDevTool from 'components/FormDevTool';

import { Entity } from 'state/entity';
import { User } from 'state/user';

const QRWrapper = styled.div`
  background: var(${Colors.borderLight});
  box-shadow: 8px 13px 15px -9px rgba(0, 0, 0, 0.1);
  padding: var(${Spacing.large});
  border-radius: var(${Radius.medium});
  border: 1px solid var(${Colors.gray_200});

  .qr-code {
    width: 80%;
    margin: 0 auto;
  }
`;

interface Props {
  signInUser?: CognitoUser | null;
  onCancel?: () => void;
}
const MFA: React.FC<Props> = ({ signInUser, onCancel }) => {
  const { colors } = Themes.useContainer();
  const { entity } = Entity.useContainer();
  const { user } = User.useContainer();
  const [qrCode, setQrCode] = useState('');
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm<{
    code: string;
  }>();

  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = async (values: { code: string }) => {
    if (!values.code) {
      toast.error('No TOTP Code provided');
      return;
    }

    setIsSaving(true);

    try {
      if (user) {
        await Auth.verifyTotpToken(user, values.code);
        await Auth.setPreferredMFA(user, 'TOTP');

        const data = await fetch('/api/user/user/verify-user-mfa', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
          },
        });
        const result = await data.json();
        if (!result.ok) {
          toast.error(result.statusText ?? 'Error, cannot validate otp');
          setIsSaving(false);
          return;
        }

        toast.success('MFA setup successfully');
        setQrCode('');
        setIsSaving(false);
        // fetchUser();
      } else if (signInUser) {
        try {
          const userName = (signInUser as any)?.username;
          const session = (signInUser as any)?.Session;
          const data = await fetch('/api/user/user/respond-to-auth-challenge', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              code: values.code,
              session,
              userName,
              challengeName: 'SOFTWARE_TOKEN_MFA',
            }),
          });

          const result = await data.json();
          if (!result?.ok) {
            toast.error(result?.statusText ?? 'Error, cannot validate otp');
            setIsSaving(false);
            return;
          }
          const userSession = new CognitoUserSession({
            IdToken: new CognitoIdToken({ IdToken: result?.data?.IdToken }),
            RefreshToken: new CognitoRefreshToken({ RefreshToken: result?.data?.RefreshToken }),
            AccessToken: new CognitoAccessToken({ AccessToken: result?.data?.AccessToken }),
          });
          const currentUser = await (Auth as any).createCognitoUser(
            userSession.getIdToken().decodePayload()['cognito:username'],
          );
          await currentUser.setSignInUserSession(userSession);
        } catch (error) {
          logger.error(error);
          toast.error((error as Error).message);
        }
        setIsSaving(false);
        // NOTE: This is a hack to force a reload of the page and clear the cache. Also helps to apply new deployments.
        window.location.reload();
      }
    } catch (error) {
      toast.error((error as Error).message);
      setIsSaving(false);
    }
  };

  // override qr code from amplify
  useEffect(() => {
    const generateQR = async (secretKey: string) => {
      const issuer = entity.name;
      const code = `otpauth://totp/${issuer}:${user?.username}?secret=${secretKey}&issuer=${issuer}`;
      return QRCode.toDataURL(code, {
        color: {
          dark: colors.gray_900,
          light: colors.gray_0,
        },
      });
    };

    const setMFA = async () => {
      const secretKey = await Auth.setupTOTP(user);
      const code = await generateQR(secretKey);
      setQrCode(code);
    };
    if (user && !signInUser) {
      setMFA();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, signInUser]);

  return (
    <>
      <FormDevTool control={control} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col flex-1 items-stretch w-full gap-base">
          {!signInUser && qrCode && (
            <QRWrapper>
              <div className="flex flex-col w-full gap-base">
                <Text className="flex-1">Scan the QR code with your preferred authenticator app</Text>
                <Text className="flex-1">e.g. Google Authenticator, Authy, etc.</Text>
                <img className="qr-code" src={qrCode} alt="qrCode" />
              </div>
            </QRWrapper>
          )}
          {signInUser ? (
            <div className="flex flex-col w-full gap-base">
              <Text className="flex-1">Enter the code provided by the authenticator</Text>
              <Text className="flex-1">e.g. Google Authenticator, Authy, etc.</Text>
            </div>
          ) : (
            <Text className="flex-1">Enter the code provided by the authenticator</Text>
          )}
          <div className="flex flex-col flex-1 gap-xs">
            <Text weight={FontWeight.bold} className="w-full" size={FontSize.small}>
              Code
            </Text>
            <Input
              autoFocus
              config={{
                color: 'gray',
              }}
              type="number"
              formRegister={register('code', {
                required: 'Required',
              })}
              maxLength={6}
              minLength={6}
              placeholder="Authenticator code"
            />
            {errors.code?.message && (
              <Text size={FontSize.small} color={Colors.danger_700}>
                {errors.code.message}
              </Text>
            )}
          </div>
          <Button isLoading={isSaving}>Verify</Button>
          {signInUser && onCancel && (
            <Button
              config={{
                color: 'gray',
              }}
              className="cancel-button"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          )}
        </div>
      </form>
    </>
  );
};

export default MFA;
