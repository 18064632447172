import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFilters } from 'refreshed-pages/market-board-v2/hooks/useFilters';
import { wait } from 'refreshed-pages/utils/wait';

import { CardCMBProps } from '@aircarbon/ui';
import { logger } from '@aircarbon/utils-common';

import { Account } from 'state/account';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';
import useTabActive from 'hooks/useTabActive';

import { toMetaFields } from '../../../utils/toMetaFields';
import { FetchListingsResponse, fetchListings } from '../utils/fetchListings';
import { toCmbCardProps } from '../utils/toCmbCardProps';

export const useListings = () => {
  const [listings, setListings] = useState<
    Array<
      Omit<
        CardCMBProps & {
          endsAt?: string;
        },
        'onPressCTA' | 'isCTADisabled'
      >
    >
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { product } = useMarketplaceProduct();
  const { getApiQueryParams, filteringOptions } = useFilters();
  const [pagination, setPagination] = useState({
    totalCount: 0,
    currentPage: 1,
    totalPages: 1,
  });
  const [loadingMoreStopIndex, setLoadingMoreStopIndex] = useState<number | null>(null);
  const [refetchListings, setRefetchListings] = useState(false);
  const rawDataByIdRef = useRef<Map<string, FetchListingsResponse['data'][0]>>(new Map());
  const { accountUsers } = Account.useContainer();

  const userIds = useMemo(() => {
    return accountUsers.map((user) => user.user_id);
  }, [accountUsers]);

  const isTabActive = useTabActive();

  useEffect(() => {
    let cancelableFetchListings: ReturnType<typeof wait<FetchListingsResponse>>;
    const loadListings = async () => {
      setIsLoading(true);

      cancelableFetchListings = wait(() =>
        fetchListings({
          assetCategoryId: product,
          queryParams: getApiQueryParams(1),
        }),
      );

      rawDataByIdRef.current.clear();
      const fetchListingsResponse = await cancelableFetchListings.job;
      const newListings = fetchListingsResponse.data.map((item) => {
        rawDataByIdRef.current.set(item.id, item);
        return toCmbCardProps(toMetaFields(filteringOptions))({ item, userIds });
      });

      setListings(newListings);
      setLoadingMoreStopIndex(null);
      setPagination({
        currentPage: fetchListingsResponse.currentPage,
        totalCount: fetchListingsResponse.totalCount,
        totalPages: fetchListingsResponse.totalPages,
      });
      setIsLoading(false);
    };

    if (isTabActive) {
      loadListings();
    }

    if (refetchListings) {
      logger.info('Refreshing listings');
      loadListings();
      setRefetchListings(false);
    }

    return () => {
      if (cancelableFetchListings) {
        cancelableFetchListings.cancel();
      }
    };
  }, [getApiQueryParams, product, filteringOptions, userIds, isTabActive, refetchListings]);

  /**
   * Load more listings
   * @param startIndex — from where to start loading (the index of the last loaded item)
   * @param stopIndex — the index of new last item to be loaded
   */
  const loadMore = useCallback(
    (startIndex: number, stopIndex: number) => {
      const loadListings = async () => {
        setLoadingMoreStopIndex(stopIndex);
        setIsLoadingMore(true);

        const loadedListings = await fetchListings({
          assetCategoryId: product,
          queryParams: getApiQueryParams(pagination.currentPage + 1),
        });

        const newListings = loadedListings.data.map((item) => {
          rawDataByIdRef.current.set(item.id, item);
          return toCmbCardProps(toMetaFields(filteringOptions))({ item, userIds });
        });

        setListings((currentListings) => [...currentListings, ...newListings]);
        setPagination({
          currentPage: loadedListings.currentPage,
          totalCount: loadedListings.totalCount,
          totalPages: loadedListings.totalPages,
        });
        setIsLoadingMore(false);
      };

      if (stopIndex <= (loadingMoreStopIndex || 0)) {
        return;
      }

      if (!isLoadingMore && !isLoading && pagination.currentPage <= pagination.totalPages) {
        loadListings();
      }
    },
    [
      getApiQueryParams,
      loadingMoreStopIndex,
      product,
      userIds,
      isLoadingMore,
      pagination.totalPages,
      pagination.currentPage,
      isLoading,
      filteringOptions,
    ],
  );

  const getRawDataItemById = useCallback((itemId: string) => {
    return rawDataByIdRef.current.get(itemId);
  }, []);

  return {
    listings,
    loadMore,
    isLoading,
    refetch: () => setRefetchListings(true),
    getRawDataItemById,
    totalCount: pagination.totalCount,
  };
};
