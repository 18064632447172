import { useQuery } from 'react-query';

import { fetchOperationEmails } from 'data-provider/setting/fetchOperationEmails';

export function useOperationsEmail() {
  const { data, isLoading, error } = useQuery(['oms-operations-email'], () => fetchOperationEmails());

  return {
    isLoading,
    error,
    operationsEmail: data,
  };
}
