import { createGlobalStyle } from 'styled-components';

import { Themes } from 'pages/account/trading/components/Themes';

import { Colors, type ColorsType, FontSize, FontWeight, Radius, Spacing } from './enums';

export const colorsAcxLight: ColorsType = {
  transparent: 'transparent',

  surfaceLight: '#FFFFFF',
  surfaceNormal: '#F9FAFB',
  surfaceDark: '#F3F4F6',

  borderLight: '#FFFFFF',
  borderNormal: '#E5E7EB',
  borderDark: '#111928',

  primaryDefault: '#007495',
  primaryDark: '#004F66',
  primaryMedium: '#006380',
  primaryNormal: '#AED2DC',
  primaryLight: '#E0EDF1',

  secondaryDefault: '#111928',

  summaryIconsBackground: '#F3F4F6',
  summaryIconsIcons: '#007495',

  sidebarBackground: '#FFFFFF',
  sidebarSeparator: '#E5E7EB',
  sidebarMainMenuSurfaceHover: '#F3F4F6',
  sidebarMainMenuSurfaceSelect: '#F3F4F6',
  sidebarMainMenuContentDefault: '#6B7280',
  sidebarMainMenuContentHover: '#6B7280',
  sidebarMainMenuContentSelect: '#007495',

  sidebarSubMenuContentDefault: '#111928',
  sidebarSubMenuContentSelect: '#007495',

  headerBackground: '#FFFFFF',
  headerItemsContentDefault: '#6B7280',
  headerItemsSurfaceHover: '#F3F4F6',
  headerItemsContentHover: '#007495',
  headerItemsSurfaceSelect: '#F3F4F6',
  headerItemsContentSelect: '#007495',

  gray_900: '#111928',
  gray_800: '#1F2A37',
  gray_700: '#374151',
  gray_600: '#4B5563',
  gray_500: '#6B7280',
  gray_400: '#9CA3AF',
  gray_300: '#D1D5DB',
  gray_200: '#E5E7EB',
  gray_100: '#F3F4F6',
  gray_50: '#F9FAFB',
  gray_0: '#FFFFFF',

  buyDark: '#004F66',
  buyDefault: '#007495',
  buyNormal: '#AED2DC',
  buyLight: '#E0EDF1',

  sellDark: '#9B1C1C',
  sellDefault: '#C81E1E',
  sellNormal: '#F98080',
  sellLight: '#FDE8E8',

  success_800: '#03543F',
  success_700: '#046C4E',
  success_400: '#31C48D',
  success_100: '#DEF7EC',

  warning_800: '#723B13',
  warning_700: '#8E4B10',
  warning_400: '#E3A008',
  warning_100: '#FDF6B2',

  danger_800: '#9B1C1C',
  danger_700: '#C81E1E',
  danger_600: '#FDE8E8',
  danger_500: '#EF4444',
  danger_400: '#F98080',
  danger_100: '#FDE8E8',
  danger_50: '#FDF2F2',

  purple_100: '#EDEBFE',
  purple_800: '#5521B5',

  buttonPrimaryContentDefault: '#FFFFFF',
  buttonPrimarySurfaceDefault: '#007495',
  buttonPrimaryContentHover: '#FFFFFF',
  buttonPrimarySurfaceHover: '#006380',
  buttonPrimaryContentDisabled: '#FFFFFF',
  buttonPrimarySurfaceDisabled: '#AED2DC',

  buttonSecondaryContentDefault: '#FFFFFF',
  buttonSecondarySurfaceDefault: '#111928',
  buttonSecondaryContentHover: '#FFFFFF',
  buttonSecondarySurfaceHover: '#374151',
  buttonSecondaryContentDisabled: '#9CA3AF',
  buttonSecondarySurfaceDisabled: '#E5E7EB',

  buttonOutlinedContentDefault: '#111928',
  buttonOutlinedStrokeDefault: '#D1D5DB',
  buttonOutlinedContentHover: '#FFFFFF',
  buttonOutlinedSurfaceHover: '#111928',
  buttonOutlinedContentDisabled: '#9CA3AF',
  buttonOutlinedStrokeDisabled: '#9CA3AF',

  buttonGhostContentDefault: '#6B7280',
  buttonGhostContentHover: '#111928',
  buttonGhostContentDisabled: '#9CA3AF',
};

export const colorsAcxDark: ColorsType = {
  transparent: 'transparent',

  surfaceLight: '#1F2A37',
  surfaceNormal: '#1F2A37',
  surfaceDark: '#111928',

  borderLight: '#1F2A37',
  borderNormal: '#374151',
  borderDark: '#F3F4F6',

  primaryDefault: '#328FA9',
  primaryDark: '#328FA9',
  primaryMedium: '#007495',
  primaryNormal: '#AED2DC',
  primaryLight: '#E0EDF1',

  secondaryDefault: '#F3F4F6',

  summaryIconsBackground: '#111928',
  summaryIconsIcons: '#2A9AB9',

  sidebarBackground: '#1F2A37',
  sidebarSeparator: '#4B5563',
  sidebarMainMenuSurfaceHover: '#374151',
  sidebarMainMenuSurfaceSelect: '#374151',
  sidebarMainMenuContentDefault: '#9CA3AF',
  sidebarMainMenuContentHover: '#9CA3AF',
  sidebarMainMenuContentSelect: '#F9FAFB',

  sidebarSubMenuContentDefault: '#9CA3AF',
  sidebarSubMenuContentSelect: '#F1F2F3',

  headerBackground: '#1F2A37',
  headerItemsContentDefault: '#9CA3AF',
  headerItemsSurfaceHover: '#374151',
  headerItemsContentHover: '#F9FAFB',
  headerItemsSurfaceSelect: '#374151',
  headerItemsContentSelect: '#F9FAFB',

  gray_900: '#F3F4F6',
  gray_800: '#F3F4F6',
  gray_700: '#374151',
  gray_600: '#4B5563',
  gray_500: '#9CA3AF',
  gray_400: '#9CA3AF',
  gray_300: '#4B5563',
  gray_200: '#4B5563',
  gray_100: '#111928',
  gray_50: '#374151',
  gray_0: '#1F2A37',

  buyDark: '#007495',
  buyDefault: '#328FA9',
  buyNormal: '#AED2DC',
  buyLight: '#E0EDF1',

  sellDark: '#9B1C1C',
  sellDefault: '#C81E1E',
  sellNormal: '#F98080',
  sellLight: '#FDE8E8',

  success_800: '#03543F',
  success_700: '#84E1BC',
  success_400: '#0E9F6E',
  success_100: '#014737',

  warning_800: '#723B13',
  warning_700: '#FACA15',
  warning_400: '#E3A008',
  warning_100: '#633112',

  danger_800: '#9B1C1C',
  danger_700: '#F8B4B4',
  danger_600: '#8f2c2c',
  danger_500: '#F98080',
  danger_400: '#F05252',
  danger_100: '#771D1D',
  danger_50: '#771D1D',

  purple_100: '#EDEBFE',
  purple_800: '#6C2BD9',

  buttonPrimaryContentDefault: '#FFFFFF',
  buttonPrimarySurfaceDefault: '#2A9AB9',
  buttonPrimaryContentHover: '#FFFFFF',
  buttonPrimarySurfaceHover: '#007495',
  buttonPrimaryContentDisabled: '#6B7280',
  buttonPrimarySurfaceDisabled: '#374151',

  buttonSecondaryContentDefault: '#111928',
  buttonSecondarySurfaceDefault: '#E5E7EB',
  buttonSecondaryContentHover: '#111928',
  buttonSecondarySurfaceHover: '#9CA3AF',
  buttonSecondaryContentDisabled: '#9CA3AF',
  buttonSecondarySurfaceDisabled: '#4B5563',

  buttonOutlinedContentDefault: '#FFFFFF',
  buttonOutlinedStrokeDefault: '#6B7280',
  buttonOutlinedContentHover: '#111928',
  buttonOutlinedSurfaceHover: '#E5E7EB',
  buttonOutlinedContentDisabled: '#374151',
  buttonOutlinedStrokeDisabled: '#374151',

  buttonGhostContentDefault: '#9CA3AF',
  buttonGhostContentHover: '#FFFFFF',
  buttonGhostContentDisabled: '#4B5563',
};

const EntityTheme = createGlobalStyle<{ colors: ColorsType }>`
  :root {
    ${({ colors }) => {
      return Object.entries(Colors)
        .map(([key, valuable]) => {
          const color = (colors as any)[key];
          if (color) {
            return `${valuable}: ${color};`;
          } else {
            return ``;
          }
        })
        .join('');
    }}

    ${FontSize.xs}: 0.75rem; /* 12px */
    ${FontSize.small}: 0.875rem; /* 14px */
    ${FontSize.base}: 1rem; /* 16px */
    ${FontSize.large}: 1.125rem; /* 18px */
    ${FontSize.xl}: 1.25rem; /* 20px */
    ${FontSize._2Xl}: 1.5rem; /* 24px */
    ${FontSize._3Xl}: 1.875em; /* 24px */

    ${FontWeight.default}: 400;
    ${FontWeight.bold}: 700;
    ${FontWeight.semibold}: 600;
    ${FontWeight.medium}: 500;

    ${Spacing._3xs}: 0.12rem; /* 2px */
    ${Spacing._2xs}: 0.25rem; /* 4px */
    ${Spacing._1_5}: 0.375rem; /* 6px */
    ${Spacing.xs}: 0.5rem; /* 8px */
    ${Spacing.small}: 0.75rem; /* 12px */
    ${Spacing.medium}: 1.25rem; /* 24px */
    ${Spacing.base}: 1rem; /* 16px */
    ${Spacing.large}: 1.5rem; /* 28px */
    ${Spacing.xl}: 2rem; /* 32px */
    ${Spacing._2xl}: 3rem; /* 48px */
    ${Spacing._3xl}: 4rem; /* 64px */
    ${Spacing._4xl}: 6rem; /* 96px */
    ${Spacing._5xl}: 8rem; /* 128px */
    ${Spacing._6xl}: 9rem; /* 144px */

    ${Radius.xl}: 1rem; /* 24px */
    ${Radius.large}: 1rem; /* 16px */
    ${Radius.medium}: 0.5rem; /* 8px */
    ${Radius.small}: 0.25rem; /* 4px */
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: transparent;
  }

  *:hover::-webkit-scrollbar-thumb {
    background: var(${Colors.gray_200});
    -webkit-border-radius: 1ex;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

const CssTheme: React.FC<{ name?: 'acx' | 'eon' }> = ({ name = 'eon', children }) => {
  const { colors } = Themes.useContainer();
  return (
    <div className="contents">
      <EntityTheme colors={colors} />
      {children}
    </div>
  );
};

export { CssTheme, Colors, FontSize, FontWeight, Radius, Spacing };
