import { userAuthenticatedApi } from 'data-provider/fetcher';

export interface CreateTradeRequestProps {
  otcOrderId: number;
  senderUserId: number;
  quantity: number;
  price: number;
  timeInForceId: number;
  isPrefunded?: boolean;
  endDate?: string;
  projectId?: number;
  vintageYear?: number;
  tradeRequestId?: number;
  baseAssetId?: number;
}

export async function createTradeRequest(props: CreateTradeRequestProps) {
  const userService = await userAuthenticatedApi();

  try {
    const bodyJSON = JSON.stringify(props);

    const res = await userService
      .url(`/user/otc/trade-request`)
      .headers({
        'Content-Type': 'application/json',
      })
      .body(bodyJSON)
      .post()
      .res();

    if (res.status >= 300) {
      // return error message
      return { success: false, message: res.statusText };
    }

    return { success: true, data: await res.json() };
  } catch (e: any) {
    const error = JSON.parse(e.message);
    return { success: false, message: error.message };
  }
}
