import { useEffect, useReducer, useState } from 'react';
import { wait } from 'refreshed-pages/utils/wait';

import { ToastVariant, showToast } from '@aircarbon/ui';
import { logger } from '@aircarbon/utils-common';

import { UIState, UIEvent, toUIState } from '../utils/toUIState';

interface SearchResult {
  title: string;
  description: string;
  id: number;
}

interface SearchProjectsProps {
  searchValue: string;
  assetCategoryId: string;
}

const debounceSearchTimeout = 300;

/**
 * Hook for the search project ui
 */
export const useSearchProject = (
  assetCategoryId: string,
  searchProjects: (props: SearchProjectsProps) => Promise<Array<SearchResult>>,
) => {
  const [uiState, dispatch] = useReducer(toUIState, UIState.Idle);
  const [searchResults, setSearchResults] = useState<Array<SearchResult>>([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    let debouncedSearch: {
      job: Promise<Array<SearchResult>>;
      cancel(): void;
    };

    const updateSearchResults = async () => {
      debouncedSearch = wait(
        () =>
          searchProjects({
            assetCategoryId,
            searchValue,
          }),
        debounceSearchTimeout,
      );

      try {
        const newSearchResults = await debouncedSearch.job;

        setSearchResults(newSearchResults);
        if (newSearchResults.length) {
          dispatch({
            event: UIEvent.ResultsFound,
          });
        } else {
          dispatch({
            event: UIEvent.ResultsNotFound,
          });
        }
      } catch (e) {
        logger.error('Failed to fetch projects for autocomplete!', e);
        showToast({
          variant: ToastVariant.Danger,
          message: 'Something went wrong',
        });
      }
    };

    if (uiState === UIState.SearchInProgress) {
      updateSearchResults();
    }

    return () => {
      if (debouncedSearch) {
        debouncedSearch.cancel();
      }
    };
  }, [searchValue, uiState, assetCategoryId]);

  return {
    uiState,
    searchResults,
    searchValue,
    dispatch,
    setSearchValue,
  };
};
