import { userAuthenticatedApi } from 'data-provider/fetcher';

export const cancelOtcOrder = async (
  orderId: string,
): Promise<
  | {
      status: 'ok';
    }
  | {
      status: 'error';
      error: string;
    }
> => {
  const userService = await userAuthenticatedApi();

  try {
    const bodyJSON = JSON.stringify({
      id: orderId,
    });

    const res = await userService
      .url(`/user/otc/order/${orderId}/cancel`)
      .headers({
        'Content-Type': 'application/json',
      })
      .body(bodyJSON)
      .post()
      .res();

    if (res.status >= 300) {
      return {
        status: 'error',
        error: res.statusText ?? 'Something went wrong. Please try again',
      };
    }

    return {
      status: 'ok',
    };
  } catch (e) {
    const error = JSON.parse((e as Error).message);
    return {
      status: 'error',
      error: error.message ?? 'Something went wrong. Please try again',
    };
  }
};
