import { useSearchProject } from 'refreshed-component/organisms/SearchProject/hooks/useSearchProject';
import { UIEvent, UIState } from 'refreshed-component/organisms/SearchProject/utils/toUIState';

import {
  IllustrationEmptyListCircle,
  ListItem,
  Search,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  useSpacing,
} from '@aircarbon/ui';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { searchProjects } from './utils/searchProjects';

export const SearchProject: React.FC<
  Pick<React.ComponentProps<typeof Search>, 'margin'> & {
    onChange(project: { title: string; description: string; id: number }): void;
  }
> = (props) => {
  const { product } = useMarketplaceProduct();
  const { uiState, searchResults, searchValue, dispatch, setSearchValue } = useSearchProject(product, searchProjects);
  const { onChange, ...restProps } = props;
  const onPressItem = (item: { title: string; description: string; id: number }) => () => {
    setSearchValue('');
    dispatch({
      event: UIEvent.SelectProject,
    });
    props.onChange(item);
  };

  const isShowingContent = [UIState.SearchInProgress, UIState.ShowingEmptyResults, UIState.ShowingResults].includes(
    uiState,
  );
  const shouldShowLoader = uiState === UIState.SearchInProgress;
  const shouldShowSearchResults = [UIState.ShowingResults, UIState.IdleAfterShowingResults].includes(uiState);
  const shouldShowNothingFound = [UIState.ShowingEmptyResults, UIState.IdleAfterShowingEmptyResults].includes(uiState);
  const { spacing } = useSpacing();
  return (
    <Search
      value={searchValue}
      isShowingContent={isShowingContent}
      onChange={({ value }) => {
        setSearchValue(value);
        dispatch({
          event: UIEvent.ChangeSearchValue,
          payload: value,
        });
      }}
      content={
        <SearchContent>
          {shouldShowLoader && [1, 2, 3].map((key) => <StyledListItemLoader key={key} showDescription />)}
          {shouldShowSearchResults &&
            searchResults.map((result, index) => (
              <ListItem
                key={index}
                title={result.title}
                description={result.description}
                onPress={onPressItem(result)}
              />
            ))}
          {shouldShowNothingFound && (
            <EmptyResultsSection>
              <IllustrationEmptyListCircle />
              <Text variant={TypographyVariant.subtitle1} marginTop={spacing(8)}>
                No Results Found
              </Text>
              <Text variant={TypographyVariant.body2} marginTop={spacing(2)} color={TextColor.secondary}>
                We can't find any matching project
              </Text>
            </EmptyResultsSection>
          )}
        </SearchContent>
      }
      onChangeActive={(isActive) => {
        if (uiState === UIState.Idle) {
          return;
        }

        if (isActive) {
          dispatch({
            event: UIEvent.Focus,
            payload: searchValue,
          });
          return;
        }

        dispatch({
          event: UIEvent.Blur,
        });
      }}
      {...restProps}
    />
  );
};

const SearchContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledListItemLoader = styled(ListItem.Loader)`
  align-self: stretch;
`;

const EmptyResultsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 13.375rem;
`;
