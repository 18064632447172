export default (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.81831 14.4031H7.22761C6.89289 14.4031 6.6452 14.252 6.48454 13.9428C6.32388 13.6336 6.34396 13.3381 6.53809 13.0496L12.7906 3.82216C12.9312 3.62291 13.1119 3.4855 13.3328 3.40305C13.5537 3.3206 13.788 3.32747 14.0223 3.41679C14.2566 3.50611 14.4307 3.65039 14.5445 3.87026C14.6583 4.09012 14.6985 4.31686 14.6717 4.55733L14.0023 10.1158H17.2423C17.6038 10.1158 17.8582 10.2807 18.0055 10.6105C18.1527 10.9403 18.1059 11.2494 17.8716 11.5311L10.9965 19.9822C10.8425 20.1677 10.6551 20.2913 10.4342 20.3463C10.2133 20.4013 9.99236 20.3807 9.78484 20.2845C9.57731 20.1883 9.40996 20.0303 9.29616 19.8241C9.18235 19.618 9.13549 19.3913 9.16227 19.1508L9.8317 14.41L9.81831 14.4031Z"
      fill="currentColor"
    />
    <path
      d="M6.83273 6.76284L7.46199 5.12073C5.35328 6.41244 3.94748 8.65917 3.70648 11.2013C3.46549 13.7435 4.52319 16.4712 6.54487 18.1614C6.81264 18.3881 6.8595 18.8004 6.63859 19.0752C6.49801 19.247 6.29718 19.3363 6.08296 19.3157C5.96246 19.302 5.84197 19.2539 5.74825 19.1645C3.38515 17.1995 2.1534 14.1764 2.44126 11.0708C2.72911 7.96522 4.36252 5.49175 6.81264 3.99393L5.31311 3.50611C5.02526 3.40992 4.8646 3.09386 4.95832 2.79842C5.04534 2.49611 5.35997 2.33121 5.64783 2.4274L8.59333 3.38244C8.75399 3.4374 8.89458 3.5542 8.96821 3.70536C9.04855 3.87026 9.04855 4.0489 8.9883 4.2138L7.85026 7.16822C7.76323 7.40869 7.52224 7.53924 7.28124 7.5255C7.23438 7.5255 7.18752 7.50488 7.13397 7.48427C7.00008 7.42931 6.88628 7.32624 6.83273 7.18883C6.77248 7.05141 6.76578 6.89339 6.83273 6.75597V6.76284Z"
      fill="currentColor"
    />
    <path
      d="M18.5612 20.7998C18.6215 20.9303 18.6282 21.0883 18.5746 21.2326C18.4876 21.4731 18.2466 21.6243 18.0056 21.5968C17.9588 21.5968 17.9119 21.5762 17.865 21.5624L14.9932 20.4631C14.8325 20.4013 14.6919 20.2639 14.625 20.099C14.558 19.9272 14.558 19.7348 14.625 19.5699L15.9036 16.7048C15.9638 16.5674 16.0776 16.4643 16.2115 16.4094C16.3454 16.3544 16.4994 16.3613 16.6333 16.4231C16.7672 16.4849 16.8676 16.6017 16.9211 16.7392C16.9747 16.8903 16.968 17.0415 16.9077 17.172L16.1981 18.766C18.3604 17.6049 19.8733 15.4406 20.2482 12.9122C20.6298 10.26 19.706 7.60794 17.7646 5.81468C17.5035 5.58107 17.4768 5.16195 17.7178 4.894C17.9521 4.61917 18.3537 4.59855 18.6148 4.8459C20.8708 6.93461 21.9486 10.0196 21.5 13.0977C21.0716 16.0521 19.3043 18.5737 16.7672 19.9341L18.2399 20.4906C18.3738 20.5456 18.4876 20.6486 18.5478 20.7929L18.5612 20.7998Z"
      fill="currentColor"
    />
  </svg>
);
