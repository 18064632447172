import { Card, CardVariant, styled, toSpacing } from '@aircarbon/ui';

export const StyledSectionCard = styled(Card).attrs({
  variant: CardVariant.Bordered,
})`
  padding: ${({ theme }) => toSpacing(theme)(12)};
  gap: ${({ theme }) => toSpacing(theme)(8)};
  width: 100%;
  max-width: 47rem;
`;
