export * from './featureToggles';

export const allTransferTypes: Array<{ key: string; SCCode: number; label?: string; allowManual?: boolean }> = [
  { key: 'UNDEFINED', SCCode: 0, label: 'Undefined' },
  { key: 'USER', SCCode: 1, label: 'User' },
  { key: 'EXCHANGE_FEE', SCCode: 2, label: 'Exchange Fee' },
  { key: 'ORIGINATOR_FEE', SCCode: 3, label: 'Originator Fee' },
  { key: 'TAKEPAY_FEE', SCCode: 4, label: 'Takepay Fee' },
  { key: 'SETTLE_TAKE', SCCode: 5, label: 'Settle Take' },
  { key: 'SETTLE_PAY', SCCode: 6, label: 'Settle Pay' },
  { key: 'ONBOARDING_FEE', label: 'Onboarding Fee', allowManual: true, SCCode: 12 },
  { key: 'MINT_FEE', label: 'Mint Fee', allowManual: true, SCCode: 7 },
  { key: 'BURN_FEE', label: 'Burn Fee', allowManual: true, SCCode: 8 },
  { key: 'WITHDRAW_FEE', label: 'Withdraw Fee', allowManual: true, SCCode: 9 },
  { key: 'DEPOSIT_FEE', label: 'Deposit Fee', allowManual: true, SCCode: 10 },
  { key: 'DATA_FEE', label: 'Data Fee', allowManual: true, SCCode: 11 },
  { key: 'WITHDRAW', label: 'Withdraw', allowManual: true, SCCode: 13 },
  { key: 'RETIREMENT', label: 'Retirement', allowManual: true, SCCode: 14 },
  { key: 'REBATE', label: 'Rebate', allowManual: true, SCCode: 15 },
  { key: 'PHYSICAL_DELIVERY', label: 'Physical Delivery', allowManual: true, SCCode: 16 },
  { key: 'RELATED_TRANSFER', label: 'User Related Transfer', allowManual: true, SCCode: 17 },
  { key: 'ADJUSTMENT', label: 'Adjustment', allowManual: true, SCCode: 18 },
  { key: 'ERC20', SCCode: 19, label: 'ERC20 Transfer' },
  { key: 'CFT_ISSUANCE', SCCode: 20, label: 'CFT Issuance' },
  { key: 'MIN_BALANCE_FEE', label: 'Minimum Balance Fee', allowManual: true, SCCode: 22 },
  { key: 'NO_MOVEMENT_FEE', label: 'No Movement Fee', allowManual: true, SCCode: 23 },
  { key: 'RESERVE', label: 'Transfer assets to reserve account', allowManual: true, SCCode: 24 },
  { key: 'RETOKENIZE', label: 'Retokenize transfer', allowManual: true, SCCode: 25 },
  { key: 'RELEASE', label: 'Transfer assets from reserve account', allowManual: true, SCCode: 26 },
  // FIXME: this is a hack to make the manual trade work, but it should be removed when SC Code is fixed
  { key: 'BLOCK_TRADE', SCCode: 0, label: 'Block Trade' },
];

export const MarketUom = {
  Carbon: 2, // tCo2
  Fct: 2, // tCo2
  Biofuel: 7, // tonnes
};
