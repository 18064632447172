import { yupResolver } from '@hookform/resolvers/yup';
import type React from 'react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Button } from 'refreshed-component/atoms/Button';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize } from 'refreshed-component/design-system';
import { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import { toast } from 'refreshed-component/molecules/toast';
import * as yup from 'yup';

import { logger, formatter, UserType } from '@aircarbon/utils-common';

import CopyInput from 'components/CopyInput';
import FormDevTool from 'components/FormDevTool';

import { Account } from 'state/account';
import { Contract } from 'state/contract';
import { User } from 'state/user';

import { convertTextNumberToValue } from 'utils/helpers';

const { truncateMiddle } = formatter;

const schema = (availableAmount: number) =>
  yup.object().shape({
    to: yup.string().required(),
    quantity: yup
      .number()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;

        return convertTextNumberToValue(originalValue);
      })
      .min(1)
      .test('hasEnoughAmount', 'Insufficient balance', function validateAmount(val: any): boolean {
        return Number(val) <= Number(availableAmount);
      })
      .required(),
  });

export type TransferFormData = {
  to: string;
  quantity: number;
};

interface Props {
  fromUser: { name: string; account: string; role: string };
  totalAmount: number;
  openAmount: number;
  currentAmount: number;
  stIds: Array<number>;
  scTokenTypeId: number;
  batchId: number;
  onSuccess?: () => void;
  project?: any;
}

export const CorpTransferBatchForm: React.FC<Props> = ({
  fromUser,
  scTokenTypeId,
  totalAmount,
  openAmount,
  currentAmount,
  stIds,
  batchId,
  onSuccess,
  project,
}) => {
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  const { accountUsers, onTxRequestSuccess } = Account.useContainer();
  const { tokenTypesByScId } = Contract.useContainer();
  const [saving, setSaving] = useState(false);
  const [, setTxHash] = useState<string | null>();

  const tokenType = tokenTypesByScId()?.[scTokenTypeId];
  const tokenTypeSymbol = tokenType?.name ?? '';
  const availableAmount = totalAmount - openAmount;
  const tokenUnit = tokenType?.uom?.name;

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm<TransferFormData>({
    defaultValues: {},
    resolver: yupResolver(schema(Math.min(currentAmount, availableAmount))) as any,
  });

  watch();
  const [transfer] = useMutation(
    async (formData: Record<string, any>) => {
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();

      return fetch(`/api/user/transfer/token`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        } as any,
        body: JSON.stringify(formData),
      });
    },
    {
      onSuccess: async (data) => {
        if (data.ok) {
          const txId = await data.json();
          setTxHash(txId);
          if (onSuccess) onSuccess();
          reset();
          onTxRequestSuccess('We are processing your transfer. It may take several seconds.');
        } else {
          const error = await data.json();
          toast.error(error?.message ?? 'Something went wrong!');
          logger.error(error);
        }
        setSaving(false);
      },
      onError: (error: Error) => {
        setSaving(false);

        toast.error(error?.message ?? 'Something went wrong!');
        logger.error(error);
      },
    },
  );

  const onSubmit = useCallback(
    ({ to, quantity }: TransferFormData) => {
      setSaving(true);
      transfer({
        type: 'token',
        tokSender: {
          addr: fromUser.account,
          tokTypeId: Number(scTokenTypeId),
          tokenQty: Number(quantity),
          stIds,
        },
        tokReceiver: {
          addr: to,
        },
      });
    },
    [transfer, scTokenTypeId, fromUser, stIds],
  );

  const showAccountTypes: string[] = [
    UserType.CORPORATE_ADMIN,
    UserType.CORPORATE_TRADER,
    UserType.CORPORATE_CLIENT_READ_ONLY,
    UserType.CORPORATE_CLIENT_DMA,
  ];

  return (
    <>
      <FormDevTool control={control} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent className="flex flex-col gap-small">
          <div className="flex flex-col w-full rounded-md bg-gray_100 p-small gap-small">
            <div className="flex flex-col">
              <Text color={Colors.gray_500} size={FontSize.xs}>
                Project Name
              </Text>
              <Text className="max-w-full">{project?.TXT_PROJECT_NAME}</Text>
            </div>
            <div className="flex flex-col">
              <Text color={Colors.gray_500} size={FontSize.xs}>
                Registry
              </Text>
              <Text>{project?.TXT_REGISTRY}</Text>
            </div>
            <div className="flex flex-row gap-small">
              <div className="flex flex-col flex-1 gap-small">
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.xs}>
                    Batch ID
                  </Text>
                  <Text>#{batchId}</Text>
                </div>
              </div>
              <div className="flex flex-col flex-1 gap-small">
                <div className="flex flex-col">
                  <Text color={Colors.gray_500} size={FontSize.xs}>
                    Asset Type
                  </Text>
                  <Text>
                    {tokenType.name} - {tokenType.fullName}
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <Text size={FontSize.small}>From Account</Text>
          <div className="flex flex-col w-full rounded-md bg-gray_100 p-small gap-small">
            <div className="flex flex-col gap-3xs">
              <Text color={Colors.gray_500}>{fromUser.name}</Text>
              <Text className="max-w-full">
                <CopyInput text={fromUser.account}>{truncateMiddle(fromUser.account, 16)}</CopyInput>
              </Text>
            </div>
          </div>
          <div className="flex flex-col gap-small">
            <Text size={FontSize.small}>To Account</Text>
            <Dropdown
              key={'to'}
              list={accountUsers
                .filter(
                  ({ account, account_type }) =>
                    showAccountTypes.includes(account_type) && account && account !== fromUser.account,
                )
                .map((userAccount: Record<string, any>, index: number) => {
                  const { first_name, last_name, account } = userAccount;
                  const label = `${first_name} ${last_name}`;
                  const id = account;
                  return {
                    id,
                    label,
                  };
                })}
              selected={[getValues('to')]}
              onSelectItem={(item) => {
                setValue('to', item.id.toString());
              }}
              config={{
                color: 'gray',
                size: 'sm',
              }}
              placeholder="Select account"
            />
            {errors.to && (
              <Text size={FontSize.small} color={Colors.danger_700}>
                {errors.to.message}
              </Text>
            )}
          </div>
          <div className="flex flex-col gap-small">
            <Text size={FontSize.small}>Quantity</Text>
            <Input
              config={{
                size: 'sm',
                color: errors.quantity ? 'error' : 'gray',
                postfix: tokenUnit || '',
                validation: {
                  type: 'integer',
                  numeralPositiveOnly: true,
                  numeralThousandsGroupStyle: 'thousand',
                },
              }}
              onChange={(event) => {
                const value = (event?.target as any)?.rawValue;
                setValue('quantity', value as unknown as number);
              }}
              value={getValues('quantity')}
            />
            {errors.quantity && (
              <Text size={FontSize.small} color={Colors.danger_700}>
                {errors.quantity.message}
              </Text>
            )}
          </div>
          <div className="flex flex-col w-full rounded-md bg-gray_100 p-small">
            <div className="flex flex-col">
              <Text className="max-w-full">
                Available Quantity: {formatter.formatNumber(Math.min(availableAmount, currentAmount), 0)} {tokenUnit}
              </Text>
              <Text color={Colors.gray_500} size={FontSize.xs}>
                {tokenTypeSymbol} Total Qty: {formatter.formatNumber(totalAmount, 0)} {tokenUnit}
              </Text>
              <Text color={Colors.gray_500} size={FontSize.xs}>
                {tokenTypeSymbol} Open Qty: {formatter.formatNumber(openAmount, 0)} {tokenUnit}
              </Text>
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            isLoading={saving}
            config={{
              color: 'secondary',
              size: 'sm',
            }}
            type="submit"
            className="flex-1"
          >
            Confirm
            <Icon width={14} height={14} type={IconType.ArrowRight} />
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default CorpTransferBatchForm;
