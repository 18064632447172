import { userAuthenticatedApi } from '../fetcher';

type RFQResponse = {
  id: number;
  type: string;
  fillType: string;
  tokenTypeId: number;
  ccyTypeId: number;
  quantity: number;
  filled: number;
  price: number;
  createdAt: Date;
  userId: number;
  status: string;
  __rfqRequestDetails__: Array<any>;
  __rfqResponses__: Array<any>;
};

export const fetchRFQ = async (rfqId: number) => {
  const endpoint = `/user/rfq/${rfqId}`;
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).get();
  return res.json<RFQResponse>();
};
