import { useMemo, useRef, useState } from 'react';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import Popover from 'refreshed-component/atoms/Popover';
import { RadioBox } from 'refreshed-component/atoms/RadioBox';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight, Radius } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { hooks } from '@aircarbon/utils-common';

interface ProductDropdownProps {
  value: string;
  items: Array<{
    id: string;
    label: string;
    description: string;
    icon: IconType;
  }>;
  onChange(id: string): void;
}

export const ProductDropdown: React.FunctionComponent<ProductDropdownProps> = (props) => {
  const { value, items, onChange } = props;

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownItemsRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  hooks.useOnClickOutside(dropdownItemsRef as any, () => {
    setIsDropdownVisible(false);
  });

  const selectedItem = useMemo(() => {
    return items.find((item) => item.id === value);
  }, [value, items]);

  const onPressDropdownItem = (id: string) => {
    onChange(id);
    setIsDropdownVisible(false);
  };

  return (
    <Popover
      view="transparent"
      placement="bottomLeft"
      visible={isDropdownVisible}
      content={() => (
        <StyledDropdownItems
          ref={dropdownItemsRef}
          style={{
            minWidth: `${dropdownRef.current?.getBoundingClientRect().width}px`,
          }}
        >
          {items.map((item) => (
            <DropdownItem
              onPress={() => onPressDropdownItem(item.id)}
              title={item.label}
              key={item.id}
              description={item.description}
              icon={item.icon}
              isSelected={item.id === value}
            />
          ))}
        </StyledDropdownItems>
      )}
    >
      <StyledDropdown isActive={isDropdownVisible} ref={dropdownRef} onClick={() => setIsDropdownVisible(true)}>
        {!!selectedItem?.icon && (
          <StyledIcon
            type={selectedItem?.icon}
            width={24}
            color={isDropdownVisible ? Colors.primaryDefault : Colors.gray_900}
          />
        )}
        <StyledSelectedItemText
          size={FontSize._2Xl}
          weight={FontWeight.bold}
          color={isDropdownVisible ? Colors.primaryDefault : Colors.gray_900}
        >
          {selectedItem?.label}
        </StyledSelectedItemText>
        <ChevronDownContainer>
          <StyledIcon
            type={IconType.ChevronDown}
            width={12}
            height={12}
            color={isDropdownVisible ? Colors.primaryDefault : Colors.gray_900}
          />
        </ChevronDownContainer>
      </StyledDropdown>
    </Popover>
  );
};

const DropdownItem: React.FunctionComponent<{
  title: string;
  description: string;
  icon: IconType;
  isSelected: boolean;
  onPress(): void;
}> = (props) => {
  const { title, description, icon, isSelected, onPress } = props;

  return (
    <StyledDropdownItem onClick={onPress}>
      <StyledIcon width={24} type={icon} />
      <ItemContent>
        <Text weight={FontWeight.semibold} size={FontSize.large}>
          {title}
        </Text>
        <Text size={FontSize.small} color={Colors.gray_500}>
          {description}
        </Text>
      </ItemContent>
      <RadioBox id={title} label="" isSelected={isSelected} />
    </StyledDropdownItem>
  );
};

const StyledSelectedItemText = styled(Text)`
  white-space: nowrap;
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledDropdownItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 0;
  cursor: pointer;

  &:not(:first-child) {
    border-top: 1px solid var(${Colors.gray_200});
  }
`;

const StyledDropdown = styled.div<{
  isActive: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 24px;
  border-left: 1px solid var(${Colors.gray_200});
  border-right: 1px solid var(${Colors.gray_200});
  height: 60px;
  gap: 8px;
  cursor: pointer;
  transition: background 0.15s linear;
  ${(props) => {
    if (props.isActive) {
      return `
        background: var(${Colors.gray_100});
      `;
    }
  }}
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  color: var(${Colors.gray_900});
`;

const ChevronDownContainer = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
`;

const StyledDropdownItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  border: 1px solid var(${Colors.gray_200});
  border-radius: var(${Radius.medium});
  background: var(${Colors.gray_0});
  overflow-y: auto;
  box-shadow:
    0px 4px 6px 0px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`;
