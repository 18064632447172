import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Text } from './Text';

const StyledBadge = styled.div<{
  badgeVariant: BadgeVariant;
  badgeSize: BadgeSize;
  backgroundColor: Colors;
  color: Colors;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  color: var(${({ color }) => color});
  background: var(${({ backgroundColor }) => backgroundColor});
  ${({ badgeVariant: badgeType, badgeSize }) => {
    switch (true) {
      case badgeType === BadgeVariant.Circle && badgeSize === BadgeSize.Small:
        return `
            border-radius: 50%;
            width: 20px;
            height: 20px;
          `;
      case badgeType === BadgeVariant.Default && badgeSize === BadgeSize.Large:
        return `
                border-radius: 6px;
                padding: 2px 12px;
                gap: 4px;
              `;
    }
  }};
`;

export enum BadgeVariant {
  Circle = 0,
  Default = 1,
}

export enum BadgeSize {
  Small = 0,
  Large = 1,
}

export const Badge: React.FC<{
  className?: string;
  variant: BadgeVariant;
  size: BadgeSize;
  backgroundColor?: Colors;
  color?: Colors;
  startIcon?: React.ReactNode;
}> = (props) => {
  const {
    className,
    size,
    variant,
    children,
    color = Colors.gray_900,
    backgroundColor = Colors.gray_200,
    startIcon,
  } = props;

  return (
    <StyledBadge
      color={color}
      backgroundColor={backgroundColor}
      badgeSize={size}
      badgeVariant={variant}
      className={className}
    >
      {startIcon}
      {typeof children === 'string' ? (
        <Text color={color} size={FontSize.small} weight={FontWeight.medium}>
          {children}
        </Text>
      ) : (
        children
      )}
    </StyledBadge>
  );
};
