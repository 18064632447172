import { type ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Input } from 'refreshed-component/atoms/Input';
import Popover from 'refreshed-component/atoms/Popover';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, Radius } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { CountriesList } from './components/CountriesList';
import { ImageWithPlaceholder } from './components/ImageWithPlaceholder';
import { PhoneInputContainer } from './components/PhoneInputContainer';
import { Select } from './components/Select';
import type { Country } from './utils/Country';
import { toCountryIconUrl } from './utils/toCountryIconUrl';
import { toFilteredCountries } from './utils/toFilteredCountries';

type InputPhoneNumberProps = {
  /**
   * List of countries and their phone codes
   */
  countries: Array<Country>;

  /**
   * Country phone code
   *
   * @example "+971"
   */
  phoneCode: string;

  /**
   * Phone number value
   *
   * @example "58123456780"
   */
  value?: string;

  /**
   * Input label
   */
  label?: string;

  /**
   * If set to true, then will disable the input from modifications
   */
  isDisabled?: boolean;

  /**
   * Error text. If set, will put input into error state
   */
  error?: string;

  /**
   * Handles input changes
   */
  onChange(props: {
    /**
     * Phone number value
     *
     * @example "58123456780"
     */
    value: string;
    /**
     * Country phone code
     *
     * @example "+971"
     */
    phoneCode: string;
  }): void;
};

export const InputPhoneNumber: React.FC<InputPhoneNumberProps> = (props) => {
  const { label, countries = [], phoneCode, value = '', error, onChange } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const selectAndInputContainerRef = useRef<HTMLDivElement>(null);

  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isSelectFocused, setIsSelectFocused] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setFilteredCountries(
      toFilteredCountries({
        searchValue,
        countries,
      }),
    );
  }, [searchValue, countries]);

  const onFocusInput = () => {
    setIsInputFocused(true);
    setIsSelectFocused(false);
  };

  const onClickInputContainer = () => {
    inputRef.current?.focus();
  };

  const onBlurInput = () => {
    setIsInputFocused(false);
  };

  const onPopoverVisibilityChange = (isVisible: boolean) => {
    setIsSelectFocused(isVisible);

    if (!isVisible) {
      setSearchValue('');
    }
  };

  const onPressCountry = (country: Country) => {
    setIsSelectFocused(false);
    setSearchValue('');
    onChange({
      phoneCode: country.phoneCode,
      value,
    });
    inputRef.current?.focus();
  };

  const onChangeSearchValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);
  };

  const onPressClearSearch = () => {
    setSearchValue('');
  };

  const onChangePhoneValue: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange({
      phoneCode,
      value: e.target.value,
    });
  };

  return (
    <StyledInputPhoneNumber>
      {!!label && <Text size={FontSize.small}>{label}</Text>}

      <SelectAndInput ref={selectAndInputContainerRef} isFocused={isInputFocused}>
        <Popover
          view="transparent"
          onVisibleChange={onPopoverVisibilityChange}
          trigger={'click'}
          visible={isSelectFocused}
          overlayStyle={{
            borderRadius: '0px !important',
          }}
          content={() => (
            <PhoneCodes
              style={{
                width: selectAndInputContainerRef.current?.getBoundingClientRect().width + 'px',
              }}
            >
              <Input
                placeholder="Search"
                config={{
                  size: 'base',
                  color: 'gray',
                  postfix: (
                    <ClearSearch onClick={onPressClearSearch}>
                      <Icon type={IconType.XCircle} width={14} height={14} />
                    </ClearSearch>
                  ),
                  prefix: <Icon type={IconType.Search} width={14} height={14} />,
                }}
                value={searchValue}
                onChange={onChangeSearchValue}
                ref={searchInputRef}
              />
              <CountriesList countries={filteredCountries} onPressCountry={onPressCountry} />
            </PhoneCodes>
          )}
          placement="bottomLeft"
        >
          <Select isFocused={isSelectFocused}>
            <ImageWithPlaceholder
              placeholder="/assets/country-flags/placeholder.svg"
              src={toCountryIconUrl(phoneCode)}
            />
            <StyledChevronIcon isFocused={isSelectFocused} width={12} height={12} />
          </Select>
        </Popover>

        <PhoneInputContainer hasError={!!error} isFocused={isInputFocused} onClick={onClickInputContainer}>
          <PhoneCode>{phoneCode}</PhoneCode>
          <PhoneInput
            type="tel"
            ref={inputRef}
            onFocus={onFocusInput}
            onBlur={onBlurInput}
            value={value}
            onChange={onChangePhoneValue}
          />
        </PhoneInputContainer>
      </SelectAndInput>
      {!!error && (
        <Text color={Colors.danger_700} size={FontSize.small}>
          {error}
        </Text>
      )}
    </StyledInputPhoneNumber>
  );
};

const StyledInputPhoneNumber = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  position: relative;
`;

const SelectAndInput = styled.div<{
  isFocused: boolean;
}>`
  display: flex;
  height: 48px;
`;

const PhoneInput = styled.input`
  background: transparent;
  flex: 1;
  outline: none;
  color: var(${Colors.gray_900});
`;

const StyledChevronIcon = styled(Icon).attrs(() => ({
  type: IconType.ChevronDown,
}))<{
  isFocused: boolean;
}>`
  transition: all 0.15s linear;
  transform: rotate(${({ isFocused }) => (isFocused ? '180deg' : 0)});
`;

const ClearSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-inline-end: -6px;
  color: var(${Colors.gray_400});
  cursor: pointer;
`;

const PhoneCode = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 10px 14px 16px;
  color: var(${Colors.gray_500});
`;

const PhoneCodes = styled.div`
  padding: 16px;
  border: 1px solid var(${Colors.gray_200});
  border-radius: var(${Radius.medium});
  background: var(${Colors.gray_0});
  box-shadow:
    0px 4px 6px 0px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`;
