import { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'refreshed-component/molecules/toast';
import { findGERAsset } from 'refreshed-pages/ger/utils/findGERAsset';
import { toAssetsAllocations } from 'refreshed-pages/ger/utils/toAssetsAllocations';
import { toValidationErrorsMap } from 'refreshed-pages/ger/utils/toValidationErrorsMap';

import { logger } from '@aircarbon/utils-common';

import { Contract } from 'state/contract';
import { User } from 'state/user';

import { toAssetBalance } from '../../GERBalance/utils/toAssetBalance';
import { packOrRetireGER } from '../utils/packGER';

export const useGERPacker = (props: {
  onPackingFinished(): void;
}): {
  quantity: string;
  assets: Array<{
    assetType: string;
    balance: string;
    packQuantity: string;
    balanceAfter: string;
  }>;
  isPackingInProgress: boolean;
  isPackingDisabled: boolean;
  validationErrorsMap: Record<string, string>;
  resultingBalance: string;
  changeQuantity(newQuantity: string): void;
  resetUserInput(): void;
  pack(): void;
} => {
  const [isTouched, setIsTouched] = useState(false);
  const [quantity, setQuantity] = useState('');
  const [isPackingInProgress, setIsPackingInProgress] = useState(false);

  const {
    selector: { getAuthToken },
  } = User.useContainer();

  const { tokenTypes } = Contract.useContainer();
  const { data: tokensQty = {} } = useQuery<Record<number, number>>(['ger-tokens-qty'], async () => {
    try {
      // TODO: Implement data-provider
      const authToken = await getAuthToken();
      const result = fetch(`/api/account/basket/token-available`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
      }).then((resp) => resp.json());

      return result;
    } catch (e) {
      toast.error((e as any)?.message ?? 'Something went wrong');
      return {};
    }
  });

  const gerAsset = findGERAsset(tokenTypes);
  const assetBalance = toAssetBalance({
    asset: gerAsset,
    assetBalances: tokensQty,
  });

  const assetsAllocations = toAssetsAllocations({
    assetConfigurationJSON: gerAsset?.configuration,
    assets: tokenTypes,
    tokensQuantities: tokensQty,
  });

  const packingResult = assetBalance
    ? packOrRetireGER({
        quantity: Number(quantity || 0),
        currentBalance: assetBalance,
        assetsAllocations,
        scalingRatio: gerAsset?.uom?.scRatio ?? 1000,
      })
    : {
        assetBalances: [],
        resultingBalance: 0,
      };

  const validationErrorsMap = toValidationErrorsMap({
    quantity: Number(quantity || 0),
    assetsBalances: packingResult.assetBalances,
  });

  const isPackingDisabled = false;

  const changeQuantity = (newQuantity: string) => {
    setQuantity(newQuantity);
  };

  const resetUserInput = () => {
    setQuantity('');
    setIsTouched(false);
  };

  const pack = async () => {
    setIsTouched(true);

    if (!!validationErrorsMap) {
      return;
    }

    setIsPackingInProgress(true);
    try {
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      const response = await fetch('/api/account/basket/packing', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          tokTypeId: gerAsset?.scId,
          qtyUnit: Number(quantity),
        }),
      });
      if (response.ok) {
        toast.default('Processing ...');
        props.onPackingFinished();
      } else {
        const result = await response.json();
        // https://github.com/developit/unfetch#caveats
        throw new Error(result.message || response.statusText);
      }
      setIsPackingInProgress(false);
      setIsTouched(false);
    } catch (err: any) {
      setIsPackingInProgress(false);

      toast.error(err.message);
    }
  };

  return {
    resetUserInput,
    quantity,
    isPackingDisabled,
    isPackingInProgress,
    validationErrorsMap: isTouched ? validationErrorsMap || {} : {},
    assets: packingResult.assetBalances.map((balance) => ({
      assetType: `${balance.name} (${balance.percentage}%)`,
      balance: new Intl.NumberFormat('en-US').format(balance.availableBalance),
      packQuantity: new Intl.NumberFormat('en-US').format(balance.balanceChange),
      balanceAfter: new Intl.NumberFormat('en-US').format(balance.resultingBalance),
    })),
    resultingBalance: new Intl.NumberFormat('en-US').format(packingResult.resultingBalance),
    changeQuantity,
    pack,
  };
};
