import { FunctionComponent } from 'react';

import { Card, styled, Text, TextColor, toSpacing, TypographyVariant } from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

interface SummaryProps {
  quantity: number;
  price: number;
  exchangeFee: number;
  arrangerFee: number;
  totalPay: number;
  totalReceive: number;
  ccyCode: string;
  uom: string;
}

export const Summary = (props: SummaryProps) => {
  const { quantity, price, exchangeFee, arrangerFee, totalPay, totalReceive, ccyCode, uom } = props;
  return (
    <StyledCard>
      <Text variant={TypographyVariant.subtitle1}>Summary</Text>

      <SummaryItems>
        <SummaryItem label="Quantity" value={formatter.formatNumber(quantity, 0)} />
        <SummaryItem label={`Price (per ${uom})`} value={`${ccyCode} ${formatter.formatNumber(price)}`} />
        <SummaryItem label="Exchange Fee" value={`${ccyCode} ${formatter.formatNumber(exchangeFee)}`} />
        {arrangerFee > 0 && (
          <SummaryItem label="Arranger Fee" value={`${ccyCode} ${formatter.formatNumber(arrangerFee)}`} />
        )}
        {!!totalPay && (
          <SummaryItem isTotal label="Total Pay" value={`${ccyCode} ${formatter.formatNumber(totalPay)}`} />
        )}
        {!!totalReceive && (
          <SummaryItem isTotal label="Total Receive" value={`${ccyCode} ${formatter.formatNumber(totalReceive)}`} />
        )}
      </SummaryItems>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  padding: ${({ theme }) => toSpacing(theme)(12)};
  gap: ${({ theme }) => toSpacing(theme)(6)};
`;

const SummaryItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(3)};
`;

const SummaryItem: FunctionComponent<{
  label: string;
  value: string;
  isTotal?: boolean;
}> = (props) => {
  const { label, value, isTotal } = props;

  return (
    <StyledSummaryItem>
      <Text
        variant={isTotal ? TypographyVariant.subtitle2 : TypographyVariant.body2}
        color={isTotal ? TextColor.primary : TextColor.secondary}
      >
        {label}
      </Text>
      <Text
        variant={isTotal ? TypographyVariant.subtitle2 : TypographyVariant.body2}
        color={isTotal ? TextColor.primary : TextColor.secondary}
      >
        {value}
      </Text>
    </StyledSummaryItem>
  );
};

const StyledSummaryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;
