import { Colors, Radius, Spacing } from 'refreshed-component/design-system';
import BankAccount from 'refreshed-component/forms/BankAccount';
import styled from 'styled-components';

const DashboardRoot = styled.div`
  padding: var(${Spacing.large});
  gap: var(${Spacing.large});
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  > .header {
    display: flex;
    flex-direction: row;
    flex: space;
    justify-content: space-between;
    width: 100%;
    > .title {
      display: flex;
      flex-direction: row;
      flex: space;
      gap: var(${Spacing.small});
      > .account-type {
        min-width: 200px;
      }
    }
    > .actions {
      > .transfer {
        min-width: 200px;
      }
    }
  }
  > .body {
    max-width: 704px;
    width: 100%;
    display: block;
    border-radius: var(${Radius.large});
    background-color: var(${Colors.gray_0});
    padding: var(${Spacing.large});
  }
`;

export const BankDetails = () => {
  return (
    <DashboardRoot>
      <div className="header">
        {/*<div className="title">
          <Text weight={FontWeight.semibold}>Current Bank Details</Text>
        </div>
         <div className="flex flex-row gap-4 actions">
          {isFormDisabled && !canUpdate && (
            <Button
              className="account-type"
              config={{
                color: 'primary',
              }}
              onClick={() => {
                setCanUpdate(true);
              }}
            >
              Request a Change <Icon width={15} height={15} type={IconType.Pencil} />
            </Button>
          )}
        </div> */}
      </div>
      <div className={'body'}>
        <BankAccount />
      </div>
    </DashboardRoot>
  );
};
