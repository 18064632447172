import type { ChangeEvent } from 'react';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors } from 'refreshed-component/design-system';
import { Table } from 'refreshed-component/templates/Table';
import styled from 'styled-components';

import { InputDateOfIssuance } from '../InputDateOfIssuance';
import { OfferedQuantity } from '../OfferedQuantity';

type Vintage = {
  id?: number;
  year: string;
  dateOfIssuance: string;
  estimatedQty: string;
  offeredQty: string;
};

export const VintagesTable: React.FC<{
  maximumOfferedQuantity: number;
  vintages: Array<Vintage>;
  onChange(vintages: Array<Vintage>): void;
}> = (props) => {
  const { vintages, maximumOfferedQuantity, onChange } = props;

  const onChangeEstimatedQuantity = (year: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const newVintages = vintages.map((vintage) => {
      const estimatedQty = Number(e.target.value);

      if (vintage.year !== year || isNaN(estimatedQty) || estimatedQty < 0) {
        return vintage;
      }

      return {
        ...vintage,
        estimatedQty: String(estimatedQty),
      };
    });

    onChange(newVintages);
  };

  const onChangeOfferedQuantity = (year: string) => (offeredQty: string) => {
    const newVintages = vintages.map((vintage) => {
      if (vintage.year !== year) {
        return vintage;
      }

      const newOfferedQty = Number(offeredQty);

      if (isNaN(newOfferedQty) || newOfferedQty < 0) {
        return { ...vintage, offeredQty: '0' };
      }

      return {
        ...vintage,
        offeredQty: String(offeredQty),
      };
    });

    onChange(newVintages);
  };

  const onChangeDateOfIssuance = (year: string) => (dateOfIssuance: string) => {
    const newVintages = vintages.map((vintage) => {
      if (vintage.year !== year) {
        return vintage;
      }

      return {
        ...vintage,
        dateOfIssuance,
      };
    });

    onChange(newVintages);
  };

  return (
    <Table
      config={{
        columns: {
          vintage: {
            label: 'Vintage',
          },
          dateOfIssuance: {
            label: 'Expected Issuance Date',
          },
          estimatedQty: {
            label: 'Estimated qty*',
          },
          offeredQty: {
            label: `Offered qty (${maximumOfferedQuantity}% Max)`,
          },
        },
        rows: vintages?.map((row, index) => ({
          _key: row.year,
          vintage: row.year,
          dateOfIssuance: (
            <InputDateOfIssuance
              value={row.dateOfIssuance}
              onChange={onChangeDateOfIssuance(row.year)}
              issuanceYear={row.year}
            />
          ),
          estimatedQty: (
            <StyledEstimatedQuantityInput
              config={{
                color: 'gray',
                postfix: <Text color={Colors.gray_500}>tCO2e</Text>,
              }}
              placeholder="0"
              min="0"
              type="number"
              value={row.estimatedQty}
              onChange={onChangeEstimatedQuantity(row.year)}
            />
          ),
          offeredQty: (
            <OfferedQuantity
              estimatedQuantity={row.estimatedQty}
              maximumOfferedQuantityPercentage={maximumOfferedQuantity}
              offeredQuantity={row.offeredQty}
              onChange={onChangeOfferedQuantity(row.year)}
            />
          ),
        })),
      }}
    ></Table>
  );
};

const StyledEstimatedQuantityInput = styled(Input)`
  min-width: 176px;
  & input {
    -moz-appearance: textfield;
  }
  & > input::-webkit-inner-spin-button,
  & > input::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`;
