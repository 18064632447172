import { useQuery } from 'react-query';

import { fetchExternalMarketAsset } from 'data-provider/oms/fetchExternalMarketAsset';

export interface ExternalMarketAsset {
  name: string;
  id: number;
  symbol: string;
  price: number;
}

export function useExternalMarketAsset(symbol: string) {
  const { data, isLoading, error } = useQuery(['oms-external-market-asset'], () => fetchExternalMarketAsset(symbol));
  return {
    isLoading,
    error,
    externalMarketICE: data,
  };
}
