import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useQueryCache } from 'react-query';

import {
  CMBOrderType,
  Layer,
  Modal,
  Text,
  ToastVariant,
  showToast,
  styled,
  toLayerBackground,
  toSpacing,
  useSpacing,
} from '@aircarbon/ui';

import { User } from 'state/user';

import { Offer, OfferProps } from '../Offer';
import { QuantityAndPrice, QuantityAndPriceRef } from './components/QuantityAndPrice';
import { ReserveExtraFunds } from './components/ReserveExtraFunds';
import { createCounterOffer } from './utils/createCounterOffer';

export interface ShowCounterOfferModalProps {
  id: string;
  orderOpenQty: number;
  orderType: CMBOrderType;
  offers: Array<OfferProps>;
  baseAssetSymbol?: string;
  reservedAssetQty: number;
  sellerUserId: number | null;
}
export interface CounterOfferModalRef {
  show(props: ShowCounterOfferModalProps): void;
}

export const CounterOfferModal = forwardRef<
  CounterOfferModalRef,
  {
    onCounterOfferPlaced(counterOffer: any): void;
  }
>((props, ref) => {
  const { onCounterOfferPlaced } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [id, setId] = useState('');
  const [negotiationHistory, setNegotiationHistory] = useState<Array<OfferProps>>([]);
  const [formValue, setFormValue] = useState({
    price: '',
    quantity: '',
  });
  const [isPlacingCounterOffer, setIsPlacingCounterOffer] = useState(false);
  const {
    selector: { getUserId },
  } = User.useContainer();
  const [shouldReserveExtraFunds, setShouldReserveExtraFunds] = useState(false);
  const [isReservingMoreFunds, setIsReservingMoreFunds] = useState(false);
  const [baseAssetSymbol, setBaseAssetSymbol] = useState<string | undefined>();
  const [orderType, setOrderType] = useState<CMBOrderType>();
  const [reservedQuantity, setReservedQuantity] = useState(0);
  const [openQuantity, setOpenQuantity] = useState(0);
  const queryCache = useQueryCache();
  const userId = getUserId();
  const { spacing } = useSpacing();
  const [sellerUserId, setSellerUserId] = useState(userId);
  const quantityAndPriceRef = useRef<QuantityAndPriceRef>(null);

  useImperativeHandle(ref, () => {
    return {
      show(props) {
        const { offers, id, orderOpenQty, reservedAssetQty, baseAssetSymbol, orderType, sellerUserId } = props;
        const openQty = orderOpenQty;
        const quantity = offers?.[0]?.quantityRaw;
        const finalQuantity = openQty < quantity ? openQty : quantity;
        setSellerUserId(sellerUserId);
        setOpenQuantity(openQty);
        setOrderType(orderType);
        setBaseAssetSymbol(baseAssetSymbol);
        setReservedQuantity(reservedAssetQty);
        setId(id);
        setFormValue({
          price: offers?.[0]?.priceRaw.toString(),
          quantity: finalQuantity.toString(),
        });
        setNegotiationHistory(offers);
        setIsVisible(true);
      },
    };
  });

  const offersContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    offersContainerRef.current?.scrollTo({
      top: offersContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [negotiationHistory]);

  useEffect(() => {
    setShouldReserveExtraFunds(false);
  }, [isVisible]);

  const onClose = () => {
    if (isPlacingCounterOffer) {
      return;
    }
    setIsVisible(false);
  };

  const onPressCounter = async () => {
    const hasValidationErrors = quantityAndPriceRef.current?.validate() ?? false;

    if (hasValidationErrors) {
      return;
    }

    setIsPlacingCounterOffer(true);

    const result = await createCounterOffer({
      price: Number(formValue.price),
      quantity: Number(formValue.quantity),
      tradeRequestId: id,
      userId,
    });

    setIsPlacingCounterOffer(false);

    if (result.status === 'error') {
      showToast({
        variant: ToastVariant.Danger,
        message: result.error,
      });

      return;
    }

    showToast({
      variant: ToastVariant.Success,
      message: 'Counter offer placed successfully.',
    });

    queryCache.invalidateQueries('tradeRequests');
    onCounterOfferPlaced(result);
    setIsVisible(false);
  };

  return (
    <Modal
      title={`Counter Offer #${id}`}
      isVisible={isVisible}
      onClose={onClose}
      onPressNegativeButton={onClose}
      onPressPositiveButton={onPressCounter}
      positiveButtonText="Counter Offer"
      isPositiveButtonLoading={isPlacingCounterOffer}
      isPositiveButtonDisabled={shouldReserveExtraFunds && !isReservingMoreFunds}
      contentComponent={Container}
    >
      <Heading>
        <QuantityAndPrice
          openQuantity={openQuantity}
          value={formValue}
          ref={quantityAndPriceRef}
          onChange={setFormValue}
        />
        {orderType === CMBOrderType.Bid && (
          <ReserveExtraFunds
            value={isReservingMoreFunds}
            onExtraReservationNeeded={setShouldReserveExtraFunds}
            onChange={setIsReservingMoreFunds}
            baseAssetSymbol={baseAssetSymbol}
            quantity={Number(formValue.quantity)}
            reservedQuantity={reservedQuantity}
            sellerUserId={sellerUserId}
          />
        )}

        <Text marginTop={spacing(16)}>Negotiation History</Text>
      </Heading>

      <Layer>
        <OffersContainer ref={offersContainerRef}>
          {negotiationHistory.map((historyItem) => (
            <Offer {...historyItem} />
          ))}
        </OffersContainer>
      </Layer>
    </Modal>
  );
});

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => toSpacing(theme)(8)};
  padding-top: ${({ theme }) => toSpacing(theme)(8)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const OffersContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => toLayerBackground(theme)('layer')};
  margin-top: ${({ theme }) => toSpacing(theme)(8)};
  padding: ${({ theme }) => toSpacing(theme)(8)};
  gap: ${({ theme }) => toSpacing(theme)(8)};
  flex-grow: 1;
  overflow-y: auto;
`;
