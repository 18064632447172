type ToEndYearProps = {
  endYears: Array<{
    id: string;
    label: string;
  }>;
  endYear: string;
};

export const toEndYear = (props: ToEndYearProps) => {
  if (!props.endYears.length) {
    return '';
  }

  if (props.endYears.map((endYear) => endYear.id).includes(props.endYear)) {
    return props.endYear;
  }

  return '';
};
