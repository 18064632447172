import {
  Card,
  CardColor,
  Layer,
  styled,
  Text,
  TextAlign,
  TextAs,
  TextColor,
  toSpacing,
  TypographyVariant,
} from '@aircarbon/ui';

export interface OfferProps {
  /**
   * From whoom the offer is coming
   */
  from: string;

  /**
   * Time of placing the offer
   */
  time: string;

  /**
   * Offer quantity
   */
  quantity: string;

  /**
   * Offer price
   */
  price: string;

  priceRaw: number;
  quantityRaw: number;

  /**
   * Optional price label
   *
   * @default Price (per tCO2)
   */
  priceLabel?: string;

  /**
   * If true,
   * then the offer is from the current user
   */
  isMine?: boolean;
}

export const Offer: React.FunctionComponent<OfferProps> = (props) => {
  const { from, time, quantity, price, priceLabel = 'Price (per tCO2)', isMine = false } = props;
  return (
    <StyledOffer isMine={isMine}>
      <Text variant={TypographyVariant.subtitle2} align={isMine ? TextAlign.end : TextAlign.start}>
        {from}{' '}
        <Text variant={TypographyVariant.body2} color={TextColor.secondary} as={TextAs.span}>
          {time}
        </Text>
      </Text>

      <Layer>
        <Card color={isMine ? CardColor.LayerInverted : CardColor.Layer}>
          <QuantityAndPrice>
            <Text variant={TypographyVariant.body2} color={isMine ? TextColor.secondaryInverse : TextColor.secondary}>
              Qty
            </Text>
            <Text
              variant={TypographyVariant.subtitle2}
              color={isMine ? TextColor.inverse : TextColor.primary}
              align={TextAlign.end}
            >
              {quantity}
            </Text>
            <Text variant={TypographyVariant.body2} color={isMine ? TextColor.secondaryInverse : TextColor.secondary}>
              {priceLabel}
            </Text>
            <Text
              variant={TypographyVariant.subtitle2}
              color={isMine ? TextColor.inverse : TextColor.primary}
              align={TextAlign.end}
            >
              {price}
            </Text>
          </QuantityAndPrice>
        </Card>
      </Layer>
    </StyledOffer>
  );
};

const StyledOffer = styled.div<{
  isMine: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  ${({ isMine }) => `
    align-self: ${isMine ? 'flex-end' : 'flex-start'}
  `}
`;

const QuantityAndPrice = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => toSpacing(theme)(5)};
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;
