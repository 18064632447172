export default (
  <svg viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.89543 20 2V5C20 5.55228 19.5523 6 19 6C18.4477 6 18 5.55228 18 5V2H2V5C2 5.55228 1.55228 6 1 6C0.447715 6 0 5.55228 0 5V2Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4H4V14.6431C4 15.3935 4.85922 16 5.92233 16H14.0777C15.1408 16 16 15.3935 16 14.6431V4ZM11.8534 10.6466C11.9473 10.7403 12 10.8675 12 11.0001C12 11.1327 11.9473 11.2598 11.8534 11.3536L10.351 12.8536C10.2571 12.9473 10.1298 13 9.99698 13C9.86419 13 9.73683 12.9473 9.64292 12.8536L8.14058 11.3536C8.04935 11.2593 7.99888 11.133 8.00002 11.0019C8.00116 10.8708 8.05383 10.7454 8.14668 10.6527C8.23952 10.5599 8.36513 10.5074 8.49643 10.5062C8.62774 10.5051 8.75423 10.5555 8.84868 10.6466L9.49619 11.2931L9.49619 7.50001C9.49619 7.3674 9.54896 7.24022 9.64287 7.14645C9.73678 7.05268 9.86416 7 9.99698 7C10.1298 7 10.2572 7.05268 10.3511 7.14645C10.445 7.24022 10.4978 7.3674 10.4978 7.50001V11.2931L11.1453 10.6466C11.2392 10.5528 11.3665 10.5002 11.4993 10.5002C11.6321 10.5002 11.7595 10.5528 11.8534 10.6466Z"
      fill="white"
    />
  </svg>
);
