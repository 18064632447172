export interface ToMarginProps {
  /**
   * Margin on each side
   */
  margin?: string;

  /**
   * Top margin
   */
  marginTop?: string;

  /**
   * Bottom margin
   */
  marginBottom?: string;

  /**
   * Horizontal margin (start and end)
   */
  marginHorizontal?: string;

  /**
   * Vertical margin (bottom and top)
   */
  marginVertical?: string;

  /**
   * Start margin
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin-inline-start
   */
  marginStart?: string;

  /**
   * End margin
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/margin-inline-end
   */
  marginEnd?: string;
}

export const toMargin = (props: ToMarginProps) => `
    margin: ${props.margin};
    ${
      props.marginVertical &&
      `
      margin-top: ${props.marginVertical};
      margin-bottom: ${props.marginVertical};
    `
    }
    margin-top: ${props.marginTop};
    margin-bottom: ${props.marginBottom};
    ${
      props.marginHorizontal &&
      `
      margin-inline-start: ${props.marginHorizontal};
      margin-inline-end: ${props.marginHorizontal};
    `
    }
    margin-inline-start: ${props.marginStart};
    margin-inline-end: ${props.marginEnd};
  `;
