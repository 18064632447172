import { useQuery } from 'react-query';

import { fetchShippingTypes } from 'data-provider/oms/fetchShippingTypes';

export function useShippingTypes() {
  const { data, isLoading, error } = useQuery(['oms-shipping-types'], () => fetchShippingTypes());

  return {
    isLoading,
    error,
    shippingTypes: data,
  };
}
