const BilateralTradeType = {
  Undefined: 0,
  Biofuel: 1,
  BrokerPortal: 2,
  Type3: 3,
  Type4: 4,
  Type5: 5,
  Type6: 6,
  Type7: 7,
  Type8: 8,
  Type9: 9,
  Type10: 10,
} as const;

export default BilateralTradeType;
