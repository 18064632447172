import { Button } from 'refreshed-component/atoms/Button';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import Modal, { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import styled from 'styled-components';

import { RetireBatchForm } from '../RetireBatchForm';
import { useRetireGER } from './hooks/useRetireGER';

export const RetireBatch: React.FC<{
  isVisible: boolean;
  batchId: number;
  maxRetireQuantity: number;
  onClose(): void;
}> = (props) => {
  const { isVisible, batchId, maxRetireQuantity, onClose } = props;
  const {
    formValues,
    isRetireDisabled,
    isRetireInProgress,
    validationErrorsMap,
    assets,
    transactionFee,
    changeFormValues,
    retire,
    resetUserInput,
  } = useRetireGER({
    batchId,
    maxRetireQuantity,
    onRetired() {
      onClose();
    },
  });

  const onPressConfirm = () => {
    retire();
  };

  const onCloseModal = () => {
    if (isRetireInProgress) {
      return;
    }

    resetUserInput();
    onClose();
  };

  return (
    <Modal isOpen={isVisible} onClose={onCloseModal} title="Request Retirement">
      {() => {
        return (
          <>
            <ModalContent>
              <RetireBatchForm
                batchInformation={{
                  batchId,
                  quantity: maxRetireQuantity,
                }}
                assets={assets}
                value={formValues}
                errors={validationErrorsMap}
                transactionFee={transactionFee}
                onChange={changeFormValues}
              />
            </ModalContent>
            <ModalFooter>
              <StyledButton
                config={{
                  color: 'secondary',
                  icon: {
                    right: <Icon type={IconType.ArrowRight} width={20} />,
                  },
                }}
                isLoading={isRetireInProgress}
                disabled={isRetireDisabled}
                onClick={onPressConfirm}
              >
                Confirm
              </StyledButton>
            </ModalFooter>
          </>
        );
      }}
    </Modal>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
  justify-content: center;
  & .children {
    flex: 0 1 auto;
  }
`;
