import { useQuery } from 'react-query';

import { fetchTradeRequestCancelReason } from 'data-provider/trade/fetchTradeRequestCancelReason';

export function useTradeRequestCancelReason() {
  const { data, isLoading, error } = useQuery(['oms-trade-request-cancel-reason'], () =>
    fetchTradeRequestCancelReason(),
  );

  return {
    isLoading,
    error,
    tradeRequestCancelReason: data,
  };
}
