import {
  Icon,
  IconName,
  Text,
  TextAs,
  TextColor,
  TextDecoration,
  TypographyVariant,
  styled,
  toSpacing,
} from '@aircarbon/ui';

import { TradeRequest } from './fetchTradeRequests';
import { findLastCounterOffers } from './findLastCounterOffers';

export const toLastOfferQuantityRaw = (props: { tradeRequest: TradeRequest; currentUserId: number }) => {
  const { tradeRequest } = props;

  if (tradeRequest.status === 'ACCEPTED') {
    return tradeRequest.quantity;
  }

  if (['ACCEPTED', 'REJECTED'].includes(tradeRequest.status)) {
    return tradeRequest.quantity;
  }

  const { lastCounterOffer } = findLastCounterOffers({
    counterOffers: tradeRequest.counterOffers,
  });

  return lastCounterOffer?.quantity ?? 0;
};

export const toOfferQuantity = (props: { tradeRequest: TradeRequest }) => {
  const { tradeRequest } = props;

  const { lastCounterOffer, lastCounterOfferFromOwner, isLastFromOwner } = findLastCounterOffers({
    counterOffers: tradeRequest.counterOffers,
  });

  const quantityFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  const isNewQuantity = lastCounterOffer?.quantity !== lastCounterOfferFromOwner?.quantity;

  if (['ACCEPTED', 'REJECTED', 'EXECUTED'].includes(tradeRequest.status)) {
    return quantityFormatter.format(tradeRequest?.quantity ?? 0);
  }

  if (!lastCounterOffer || !isNewQuantity) {
    return quantityFormatter.format(lastCounterOfferFromOwner?.quantity ?? 0);
  }

  if (isLastFromOwner) {
    return (
      <>
        <Text variant={TypographyVariant.body2} color={TextColor.primary}>
          {quantityFormatter.format(lastCounterOffer.quantity)}{' '}
        </Text>
        <CounterOffer>
          <Icon name={IconName.ArrowUpEnd} color={TextColor.secondary} />
          <Text variant={TypographyVariant.body2} color={TextColor.secondary} as={TextAs.span}>
            {quantityFormatter.format(lastCounterOfferFromOwner?.quantity ?? 0)}
          </Text>
        </CounterOffer>
      </>
    );
  }

  return (
    <>
      <Text variant={TypographyVariant.body2}>{quantityFormatter.format(lastCounterOffer.quantity)} </Text>
      <Text
        variant={TypographyVariant.body2}
        color={TextColor.secondary}
        as={TextAs.span}
        decoration={TextDecoration.LineThrough}
      >
        {quantityFormatter.format(lastCounterOfferFromOwner?.quantity ?? 0)}
      </Text>
    </>
  );
};

const CounterOffer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(1)};
`;
