import { useQuery } from 'react-query';

import { fetchOtherTrades } from 'data-provider/trade/fetchOtherTrades';

import { AssetCategoryCode } from '../layouts/trading.hook';

export function useOtherTrades({
  pairId,
  page,
  limit,
  status,
  assetCategory,
}: {
  pairId?: number | string;
  page: number;
  limit: number;
  status: 'confirmed';
  assetCategory?: AssetCategoryCode;
}) {
  const enabledQuery = pairId && page && limit && status;
  const { data, isLoading, error } = useQuery(
    ['other-trades', pairId, page, limit, status, assetCategory],
    () =>
      fetchOtherTrades({
        page,
        limit,
        status,
        assetCategory,
        pairId: String(pairId),
      }),
    { enabled: enabledQuery },
  );

  return {
    error,
    isLoading,
    trades: data,
  };
}
