import { getSettleOrderStatusLabel } from 'refreshed-pages/settlement/utils/SettleOrderStatus';

import { Badge, BadgeSize, BadgeVariant, IconName } from '@aircarbon/ui';
import { SettleStatus } from '@aircarbon/utils-common';

export const BadgeSettleOrderStatus: React.FunctionComponent<{
  status: string;
}> = (props) => {
  const { status } = props;

  const getIcon = () => {
    switch (status) {
      case SettleStatus.SUBMITTED:
        return undefined;
      case SettleStatus.APPROVED:
      case SettleStatus.VALIDATED:
      case SettleStatus.RESERVED:
        return IconName.Clock;
      case SettleStatus.SETTLED:
        return IconName.CheckCircle;
      case SettleStatus.EXPIRED:
        return IconName.Clock;
      case SettleStatus.REJECTED:
      case SettleStatus.CANCELED:
        return IconName.XCircle;
    }
  };

  const getVariant = () => {
    switch (status) {
      case SettleStatus.SUBMITTED:
        return BadgeVariant.Info2;
      case SettleStatus.APPROVED:
      case SettleStatus.VALIDATED:
      case SettleStatus.RESERVED:
        return BadgeVariant.Alert;
      case SettleStatus.SETTLED:
        return BadgeVariant.Success;
      case SettleStatus.EXPIRED:
      case SettleStatus.REJECTED:
      case SettleStatus.CANCELED:
        return BadgeVariant.Danger;
    }
  };

  return <Badge icon={getIcon()} value={getSettleOrderStatusLabel(status)} variant={getVariant()} size={BadgeSize.s} />;
};
