import lodash from 'lodash';
import React from 'react';
import { useRef, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';

export type ContainerSize = 'xs' | 'small' | 'medium' | 'large' | 'xl';

export type ResponsiveContainerContextProps = {
  size: ContainerSize;
};

export const ResponsiveContainerContext = React.createContext<ResponsiveContainerContextProps>({
  size: 'xl',
});

const ResponsiveContainerRoot = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;

export const ResponsiveContainer: React.FC<{ className?: string }> = ({ children, className }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSetSize] = useState<ContainerSize>();

  const resizer = useMemo(() => {
    let callback: (() => void) | undefined;
    return {
      setCallback: (fn: () => void) => (callback = fn),
      resizeObserver: new ResizeObserver(() => {
        callback?.();
      }),
    };
  }, []);

  useEffect(() => {
    return () => {
      return resizer.resizeObserver.disconnect();
    };
  }, [resizer.resizeObserver]);

  useEffect(() => {
    const element = ref.current;
    const onResize = lodash.debounce(() => {
      if (element) {
        const viewPortTypes: ContainerSize[] = ['xs', 'small', 'medium', 'large', 'xl'];
        const viewPortWidth = [425, 640, 768, 1024, 1536];
        const width = element.offsetWidth;
        const viewPortIndex = viewPortWidth.findIndex((from, index) => {
          const to = viewPortWidth[index + 1];
          if ((width > from && width < to) || width === to || (width > from && to === undefined)) {
            return true;
          }
          return false;
        });
        const foundViewPort =
          viewPortTypes[viewPortIndex === -1 ? 0 : viewPortIndex + 1] || viewPortTypes[viewPortTypes.length - 1];
        if (foundViewPort !== size || !size) {
          setSetSize(foundViewPort);
        }
      }
    }, 100);
    resizer.setCallback(onResize);
    if (element) resizer.resizeObserver.observe(element);
    onResize();
    return () => {
      onResize.cancel();
      if (element) resizer.resizeObserver.unobserve(element);
    };
  }, [resizer, size]);

  return (
    <ResponsiveContainerRoot
      style={{
        opacity: size ? 1 : 0,
      }}
      className={className}
      ref={ref}
    >
      <ResponsiveContainerContext.Provider
        value={{
          size: size || 'xl',
        }}
      >
        {children}
      </ResponsiveContainerContext.Provider>
    </ResponsiveContainerRoot>
  );
};

export const ResponsiveContainerWrapper: <T>(t: T) => T = (InputComponent: any) => {
  return ((props: any) => {
    return (
      <ResponsiveContainer>
        <InputComponent {...props} />
      </ResponsiveContainer>
    );
  }) as any;
};
