import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Table } from 'refreshed-component/templates/Table';

import { Badge, BadgeSize, BadgeVariant, Pagination, Search, styled, toSpacing } from '@aircarbon/ui';
import { trades } from '@aircarbon/utils-common';

import useDebounce from 'hooks/useDebounce';

import { fetchTrades } from './utils/fetchTrades';

const tradeStatusBadgeVariant: Record<string, BadgeVariant> = {
  [trades.TradeStatus.New]: BadgeVariant.Info,
  [trades.TradeStatus.Processed]: BadgeVariant.Success,
  [trades.TradeStatus.Replaced]: BadgeVariant.Alert,
  [trades.TradeStatus.Reversed]: BadgeVariant.Danger,
};

const quantityNumberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const priceNumberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const TradeHistory: React.FunctionComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [orderIdToSearch, setOrderIdToSearch] = useState('');
  const debouncedOrderIdToSearch = useDebounce(orderIdToSearch, 500);
  const { data: fetchTradesResult, isFetching } = useQuery(`trades?${currentPage}&${debouncedOrderIdToSearch}`, () =>
    fetchTrades({
      orderId: debouncedOrderIdToSearch,
      page: currentPage,
    }),
  );

  const tableRows = useMemo(() => {
    if (fetchTradesResult?.status === 'error') {
      return [];
    }

    return fetchTradesResult?.data.data.map((trade) => ({
      _key: trade.id,
      id: trade.id,
      orderId: trade.otcOrderId,
      status: (
        <Badge
          size={BadgeSize.s}
          value={trades.TradeStatus[trade.statusId]}
          variant={tradeStatusBadgeVariant[trade.statusId]}
        />
      ),
      time: format(new Date(trade.createdAtUtc), "MMM d yyyy 'at' h:mma"),
      pair: trade.pairName,
      side: (
        <Badge
          size={BadgeSize.s}
          variant={trade.sideId === trades.OrderSide.Buy ? BadgeVariant.Success : BadgeVariant.Danger}
          value={trade.sideId === trades.OrderSide.Buy ? 'Buy' : 'Sell'}
        />
      ),
      quantity: quantityNumberFormatter.format(trade.qty),
      unitPrice: priceNumberFormatter.format(trade.price),
      tradeFee: priceNumberFormatter.format(trade.fee),
    }));
  }, [fetchTradesResult]);

  const pagesCount = fetchTradesResult?.status === 'ok' ? fetchTradesResult?.data?.totalPages ?? 1 : 1;

  return (
    <StyledTradeHistory>
      <SearchContainer>
        <Search
          placeholder="Search by Order ID"
          value={orderIdToSearch}
          onChange={({ value }) => setOrderIdToSearch(value)}
        />
      </SearchContainer>
      <StyledTable
        config={{
          loading: isFetching,
          sticky: {
            left: ['id'],
          },
          columns: {
            id: {
              label: 'TRADE ID',
            },
            orderId: {
              label: 'ORDER ID',
            },
            status: {
              label: 'STATUS',
            },
            time: {
              label: 'TIME',
            },
            pair: {
              label: 'PAIR',
            },
            side: {
              label: 'SIDE',
            },
            quantity: {
              label: 'QUANTITY',
            },
            unitPrice: {
              label: 'UNIT PRICE',
            },
            tradeFee: {
              label: 'TRADE FEE',
            },
          },
          rows: tableRows,
        }}
      />
      <PaginationContainer>
        <Pagination currentPage={currentPage} pagesCount={pagesCount} onChange={setCurrentPage} />
      </PaginationContainer>
    </StyledTradeHistory>
  );
};

const PaginationContainer = styled.div`
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;

const StyledTradeHistory = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled(Table)`
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  margin-top: ${({ theme }) => toSpacing(theme)(8)};
`;

const SearchContainer = styled.div`
  align-self: flex-start;
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;
