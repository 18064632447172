type ToStartYearProps = {
  startYears: Array<{
    id: string;
    label: string;
  }>;
  startYear: string;
};

export const toStartYear = (props: ToStartYearProps) => {
  if (!props.startYears.length) {
    return '';
  }

  if (props.startYears.map((v) => v.id).includes(props.startYear)) {
    return props.startYear;
  }

  return '';
};
