import { projects } from '@aircarbon/utils-common';

import { userAuthenticatedApi } from 'data-provider/fetcher';

import type { ProjectFormMetadata } from '../../ProjectForm';

export const fetchFormMetadata = async (): Promise<ProjectFormMetadata | null> => {
  try {
    const userService = await userAuthenticatedApi();
    const request = await userService.url(`/user/carbon/meta-options`).get();

    const response = (await request.json()) as any;

    return {
      countries: Object.keys(projects?.countryNames).map((code) => ({
        code,
        name: projects.countryNames[code],
      })),
      registries: response.registries.map((registry: { id: number; registryName: string }) => ({
        id: '' + registry.id,
        label: registry.registryName,
      })),
      methodologies: response.methodologies.map((methodology: { id: number; name: string; methodologyId: string }) => ({
        id: '' + methodology.id,
        label: [methodology.methodologyId, methodology.name].filter((v) => !!v).join(': '),
      })),
      projectTypes: response.projectTypes.map((type: { id: number; name: string }) => ({
        id: '' + type.id,
        label: type.name,
      })),
      sdgGoals: response.sdgGoals.map((goal: { sdgGoalId: number; sdgGoalName: string }) => ({
        id: '' + goal.sdgGoalId,
        label: goal.sdgGoalName,
      })),
      sectoralScopes: response.unSectoralScopes.map((scope: { id: number; unSectoralScopeName: string }) => ({
        id: '' + scope.id,
        label: scope.unSectoralScopeName,
      })),
      projectPhases: response.carbonProjectApxPhases.map((phase: { id: number; name: string }) => ({
        id: '' + phase.id,
        label: phase.name,
      })),
      additonalCertifications: [
        {
          id: '1',
          label: 'Test',
        },
        {
          id: '2',
          label: 'Test 2',
        },
      ],
      internalGradesEligibilities: [
        {
          id: '1',
          label: 'Test',
        },
        {
          id: '2',
          label: 'Test 2',
        },
      ],
      companyInvolvements: [
        {
          id: 'Owner',
          label: 'Owner',
        },
        {
          id: 'Developer',
          label: 'Developer',
        },
      ],
      contactPersonRoles: [
        {
          id: 'Management',
          label: 'Management',
        },
        {
          id: 'Sales',
          label: 'Sales',
        },
        {
          id: 'Tech',
          label: 'Tech',
        },
        {
          id: 'HR',
          label: 'HR',
        },
      ],
    };
  } catch {
    return null;
  }
};
