import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'refreshed-component/templates/Table';
import { useOtcCriteria } from 'refreshed-pages/market-board-v2/hooks/useOtcCriteria';
import { globalEventsEmitter } from 'refreshed-pages/market-board-v2/utils/globalEventsEmitter';
import { toCMBOrderType } from 'refreshed-pages/market-board-v2/utils/toCMBOrderType';
import { toOfferDescription } from 'refreshed-pages/market-board-v2/utils/toOfferDescription';

import {
  Badge,
  Button,
  ButtonSize,
  ButtonVariant,
  IconName,
  Pagination,
  styled,
  toSpacing,
  Text,
  TypographyVariant,
  InputTextSize,
  Select,
  ListItem,
  CMBOrderType,
} from '@aircarbon/ui';
import { Dto } from '@aircarbon/utils-common';
import { Const } from '@aircarbon/utils-common';

import { useEntity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { toOrderName } from '../../utils/toOrderName';
import { toOrderSideBadgeProps } from '../../utils/toOrderSideBadgeProps';
import { useActiveTab } from '../BottomSlideUp/hooks/useActiveTab';
import { FetchListingsResponse, fetchListings } from '../Listings/utils/fetchListings';
import { toOrderStatusBadgeProps } from './utils/toOrderStatusBadgeProps';

const defaultOrderStatusesToLoad = [
  Dto.OtcOrderStatus.Active,
  Dto.OtcOrderStatus.Cancelled,
  Dto.OtcOrderStatus.Filled,
  Dto.OtcOrderStatus.PartiallyFilled,
  Dto.OtcOrderStatus.Pending,
];

export const OrderHistory: React.FunctionComponent = () => {
  const { product } = useMarketplaceProduct();
  const [isLoadingListings, setIsLoadingListings] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [listings, setListings] = useState<FetchListingsResponse['data']>([]);
  const { selector } = useEntity();

  const currencyCode = selector.mainCcySymbol;

  const priceFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  });
  const {
    selector: { getUserId },
    status: { canManageCmbBidV2, canCreateAuctionV2, canCmbBidOboV2 },
  } = User.useContainer();
  const { meta } = useOtcCriteria();
  const history = useHistory();
  const userId = getUserId();
  const [orderStatus, setOrderStatus] = useState('all');
  const { updateState } = useActiveTab();
  const { getSetting } = UI.useContainer();

  useEffect(() => {
    const loadListings = async () => {
      setIsLoadingListings(true);
      const loadedListings = await fetchListings({
        assetCategoryId: product,
        queryParams: {
          userId,
          page: currentPage,
          statusIds: orderStatus === 'all' ? defaultOrderStatusesToLoad : [orderStatus],
          limit: 10,
        },
      });

      setIsLoadingListings(false);

      setListings(loadedListings.data);
      setTotalPages(loadedListings.totalPages);
    };

    loadListings();

    const unsubscribeFromGlobalEvents = globalEventsEmitter.on('orderCreated', () => {
      loadListings();
    });

    return unsubscribeFromGlobalEvents;
  }, [userId, currentPage, orderStatus, product]);

  const isUserReadOnly = !(canCreateAuctionV2() || canManageCmbBidV2());

  const isMarketBoardAndAuctionsV2SettlementModeEnabled =
    getSetting(Const.FeatureToggle.MarketBoardAndAuctionsV2SettlementMode) === '1';

  return (
    <StyledOrderHistory>
      <Filters>
        <FilterSection>
          <StatusFilterContainer>
            <Text variant={TypographyVariant.subtitle2}>Status</Text>
            <Select
              size={InputTextSize.s}
              items={[
                {
                  title: 'All',
                  value: 'all',
                },
                {
                  title: 'Active',
                  value: String(Dto.OtcOrderStatus.Active),
                },
                {
                  title: 'Pending',
                  value: String(Dto.OtcOrderStatus.Pending),
                },
                {
                  title: 'Partially Filled',
                  value: String(Dto.OtcOrderStatus.PartiallyFilled),
                },
                {
                  title: 'Filled',
                  value: String(Dto.OtcOrderStatus.Filled),
                },
                {
                  title: 'Cancelled',
                  value: String(Dto.OtcOrderStatus.Cancelled),
                },
              ]}
              value={orderStatus}
              onChange={({ value }) => {
                setOrderStatus(value);
                setCurrentPage(1);
              }}
            />
          </StatusFilterContainer>
        </FilterSection>
      </Filters>
      <StyledTable
        config={{
          loading: isLoadingListings,
          sticky: {
            left: ['id'],
            right: ['actions'],
          },
          columns: {
            id: {
              label: '#',
            },
            side: {
              label: 'SIDE',
            },
            name: {
              label: 'NAME / CRITERIA',
            },
            price: {
              label: 'PRICE',
            },
            quantity: {
              label: 'QTY',
            },
            ...(!isMarketBoardAndAuctionsV2SettlementModeEnabled ? { prefunded: { label: 'ASSETS RESERVED' } } : {}),
            placedBy: {
              label: 'PLACED BY',
            },
            ...(canCmbBidOboV2()
              ? {
                  onBehalfOf: {
                    label: 'ON BEHALF',
                  },
                }
              : {}),
            status: {
              label: 'STATUS',
            },
            ...(!isUserReadOnly && {
              actions: {
                label: 'ACTIONS',
              },
            }),
          },
          rows: listings.map((listing) => {
            const orderType = toCMBOrderType({
              isAuction: !!listing.isAuction,
              sideId: listing.sideId,
            });
            const listingStatus =
              !listing?.project || listing?.project?.status === Dto.ProjectStatus.Active
                ? listing.statusId
                : Dto.OtcOrderStatus.Pending;

            const canManageListing = orderType === CMBOrderType.Auction ? canCreateAuctionV2() : canManageCmbBidV2();
            return {
              _key: listing.id,
              id: listing.id,
              side: <Badge icon={IconName.Tag} {...toOrderSideBadgeProps(orderType)} />,
              name: (
                <NameColumn>
                  {listing.project ? (
                    <StyledListItem
                      isReadOnly
                      title={toOrderName({ listing, otcCriteria: meta })}
                      description={toOfferDescription(listing.project)}
                    />
                  ) : (
                    toOrderName({ listing, otcCriteria: meta })
                  )}
                </NameColumn>
              ),
              price: listing.price ? priceFormatter.format(listing.price) : '-',
              quantity: listing.quantity,
              placedBy: `${listing.placedByUser?.firstName} ${listing.placedByUser?.lastName}`,
              prefunded: listing.isPrefunded ? 'Yes' : orderType === CMBOrderType.Offer ? 'No' : '-',
              status: <Badge {...toOrderStatusBadgeProps(listingStatus)} />,
              ...(canCmbBidOboV2()
                ? {
                    onBehalfOf: `${listing.user?.firstName} ${listing.user?.lastName}`,
                  }
                : {}),
              ...(!isUserReadOnly && {
                actions: canManageListing && (
                  <Button
                    size={ButtonSize.s}
                    variant={ButtonVariant.outlined}
                    onPress={() => {
                      updateState((prevState) => ({ ...prevState, isOpened: false }));
                      history.push('/account/mb-and-auctions/' + listing.id);
                    }}
                  >
                    {listing?.numNewRequests! > 0 ? `${listing.numNewRequests} new request(s)` : 'Manage'}
                  </Button>
                ),
              }),
            };
          }),
        }}
      />
      <PaginationContainer>
        <Pagination currentPage={currentPage} pagesCount={totalPages} onChange={setCurrentPage} />
      </PaginationContainer>
    </StyledOrderHistory>
  );
};

const StyledListItem = styled(ListItem)`
  padding: 0;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;

  * {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
`;

const StyledTable = styled(Table)`
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  margin-top: ${({ theme }) => toSpacing(theme)(8)};
`;

const StyledOrderHistory = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaginationContainer = styled.div`
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;

const NameColumn = styled.div`
  max-width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => toSpacing(theme)(12)};
  padding: 0 ${({ theme }) => toSpacing(theme)(12)};
`;

const FilterSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;

const StatusFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(4)};
`;
