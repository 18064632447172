import { useQuery } from 'react-query';

import { AssetCategoryCode } from '@aircarbon/utils-common';

import { TradeSettingParams, fetchTradeSettings } from 'data-provider/trade/fetchTradeSettings';

export function useTradeSettings(params: TradeSettingParams, assetCategory: AssetCategoryCode = 'token') {
  const { orderBy } = params;

  const { data, isLoading, error } = useQuery(
    `oms-trade&orderby=${orderBy.timeInForceOrderBy}&assetCategory=${assetCategory}&projectId=${params.projectId}`,
    () => fetchTradeSettings(params, assetCategory),
    {
      retry: false,
    },
  );

  return {
    error,
    isLoading,
    data,
  };
}
