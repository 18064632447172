import { queryCache, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Countdown from 'refreshed-component/atoms/CountDown';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight, Spacing } from 'refreshed-component/design-system';
import Loading from 'refreshed-component/molecules/Loading';
import { PageNavigationHolder } from 'refreshed-component/molecules/PageNavigation';
import { SummaryCard } from 'refreshed-component/molecules/SummaryCard';
import { SummaryHolder } from 'refreshed-component/molecules/SummaryHolder';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { MarketPlaceProjectCard } from 'refreshed-component/templates/market-board/MarketPlaceProjectCard';

import { formatter, logger } from '@aircarbon/utils-common';

import useCurrencies from 'hooks/useCurrencies';

import { fetchMyCMBAsk } from 'data-provider/market-board/fetchMyCMBAsk';

import { formatDate } from 'utils/helpers';

import { MyListingAskBids } from './MyListingAskBids';
import { askStatusLabel } from './MyListingAsks';

export const MyListingAskInfo = () => {
  const { askId } = useParams<{ askId: string }>();
  const askUrl = `/api/user/carbon/my-cmb-ask/${askId}`;
  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();

  const { data: ask, isLoading } = useQuery(askUrl, async () => {
    try {
      const response = await fetchMyCMBAsk(askId);
      return response;
    } catch (error) {
      logger.error(error);
    }
  });

  if (!ask) <Loading />;

  const tokenAsset = ask?.__tokenAsset__;
  const tokenUnit = tokenAsset?.__uom__?.name ?? '';
  const ccyAsset = ask && currenciesObjById?.[ask?.quoteAssetId];

  return (
    <>
      <PageHolder>
        <PageSections>
          <PageNavigationHolder
            list={[
              {
                label: 'Marketplace',
                path: '/account/auctions/marketplace',
              },
              {
                label: 'My Listings',
                path: '/account/auctions/my-listings',
              },
              {
                label: `Project #${askId}`,
              },
            ]}
          />
          {ask && (
            <MarketPlaceProjectCard
              info={{
                carbonProject: { ...ask?.__carbonProject__, vintageYear: ask?.carbonProjectVintageYear },
                id: `Project #${ask?.id}`,
                carbonProjectVintageYear: ask?.carbonProjectVintageYear,
              }}
              type="white"
              controls={
                <>
                  <div className="flex flex-row justify-between whitespace-pre gap-base">
                    <div>
                      <Text
                        color={Colors.gray_900}
                        size={FontSize.small}
                        background={Colors.gray_200}
                        spacing={Spacing.small}
                        spacingTB={Spacing._2xs}
                      >
                        {askStatusLabel(ask)}
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between whitespace-pre gap-base">
                    <div className="flex flex-col gap-xs">
                      <Text color={Colors.gray_500} size={FontSize.small}>
                        Price ({ccyAsset?.symbol})
                      </Text>
                      <Text color={Colors.gray_900} size={FontSize._2Xl} weight={FontWeight.semibold}>
                        {ccyAsset?.code}
                        {formatter.formatNumber(ask.price, ccyAsset?.numDecimals)}
                      </Text>
                    </div>
                    <div className="flex flex-col gap-xs">
                      <Text color={Colors.gray_500} size={FontSize.small}>
                        Listed Qty ({ask?.__tokenAsset__?.__uom__?.code || tokenUnit})
                      </Text>
                      <Text color={Colors.gray_900} size={FontSize._2Xl} weight={FontWeight.semibold}>
                        {formatter.formatNumber(ask?.quantity - ask?.openBidsTotalQty, 0)}{' '}
                      </Text>
                    </div>
                  </div>
                  <div className="flex overflow-hidden flex-col justify-between whitespace-pre rounded-medium">
                    <div className="flex flex-col bg-gray_100 p-base">
                      <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.medium}>
                        Started on:{' '}
                        <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.medium}>
                          {formatDate(ask.startDate)}
                        </Text>
                      </Text>
                      <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.medium}>
                        Ends on:{' '}
                        <Text color={Colors.gray_900} size={FontSize.small} weight={FontWeight.medium}>
                          {formatDate(ask.endDate)}
                        </Text>
                      </Text>
                    </div>
                    {ask.status === 'LIST' && (
                      <div className="flex flex-col text-gray_900 bg-gray_200 p-base">
                        <Countdown
                          date={new Date(ask.endDate)}
                          endLabel={'This auction has ended.'}
                          onZero={() => queryCache.invalidateQueries(askUrl)}
                        />
                      </div>
                    )}
                  </div>
                </>
              }
            />
          )}
        </PageSections>
        <PageSections>
          <SummaryHolder>
            <SummaryCard title={'Total Bids'} value={ask?.__carbonEmbBids__.length.toString() ?? ''} />
            <SummaryCard
              title={'Accepted Bids'}
              value={
                ask?.__carbonEmbBids__
                  // TODO: Add dto enum for bids status
                  .filter((item) => ['CONFIRMED_BY_SELLER', 'DONE'].includes(item.status?.toUpperCase()))
                  .length.toString() ?? ''
              }
            />
            <SummaryCard
              title={'Open Quantity'}
              value={ask?.openBidsTotalQty ? formatter.formatNumber(ask.openBidsTotalQty, 0) : '-'}
            />
          </SummaryHolder>
        </PageSections>
        <PageSections>
          {ask && <MyListingAskBids ask={ask} bids={ask?.__carbonEmbBids__} tokenUnit={tokenUnit} />}
          {isLoading && (
            <div className="p-large">
              <Loading isOverLay={false} />
            </div>
          )}
        </PageSections>
      </PageHolder>
    </>
  );
};
