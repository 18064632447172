import { Alert, AlertColor, AlertVariant, toSpacing, styled } from '@aircarbon/ui';

const StyledAlert = styled(Alert)`
  flex-shrink: 0;
`;

export enum PendingActionType {
  View = 'View',
  Reject = 'Reject',
  Accept = 'Accept',
  Reserve = 'Reserve',
}

export interface PendingAction {
  id: number;
  title: string;
  primaryActionType: PendingActionType;
  primaryActionLabel?: string;
  secondaryActionType?: PendingActionType;
}

function PendingAlerts({
  items,
  onPressPrimaryAction,
  onPressSecondaryAction,
}: {
  items: Array<PendingAction>;
  onPressPrimaryAction: (action: PendingAction) => void;
  onPressSecondaryAction?: (action: PendingAction) => void;
}) {
  if (!items.length) {
    return null;
  }

  return (
    <StyledPendingAlerts>
      {items.map((item) => (
        <StyledAlert
          key={item.id}
          color={AlertColor.Warning}
          variant={AlertVariant.Primary}
          title={item.title}
          onPressPrimaryButton={() => onPressPrimaryAction(item)}
          primaryButtonText={item.primaryActionLabel ?? toActionButtonText(item.primaryActionType)}
          secondaryButtonText={item.secondaryActionType ? toActionButtonText(item.secondaryActionType) : undefined}
          onPressSecondaryButton={item.secondaryActionType ? () => onPressSecondaryAction?.(item) : undefined}
        />
      ))}
    </StyledPendingAlerts>
  );
}

export default PendingAlerts;

const StyledPendingAlerts = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(1)};
`;

const toActionButtonText = (actionType: PendingActionType) =>
  ({
    [PendingActionType.Accept]: 'Accept',
    [PendingActionType.Reject]: 'Reject',
    [PendingActionType.View]: 'View',
    [PendingActionType.Reserve]: 'Reserve Funds',
  })[actionType];
