import type { SettleEntity } from 'generated';

import { ModalSettlementReserveRECStep } from '@aircarbon/ui';

import { toRemainingQuantity } from './toRemainingQuantity';

const quantityFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const toReserveRECModalData = (props: {
  settlementDetails: SettleEntity;
  projects: Array<{
    name: string;
    id: string;
    balance: number;
  }>;
  quantitiesByProject: Record<string, number>;
  uom?: string;
}) => {
  const {
    settlementDetails: { quantity },
    quantitiesByProject,
    projects,
    uom = 'MWh',
  } = props;

  const remainingQuantity = toRemainingQuantity({
    totalQuantity: quantity,
    quantitiesByProject,
  });

  const result = {
    currentStep: ModalSettlementReserveRECStep.AllocateFunds,
    totalQuantity: `${quantityFormatter.format(quantity)} ${uom}`,
    remainingQuantity: `${quantityFormatter.format(remainingQuantity)} ${uom}`,
    projects: projects.map((project) => {
      return {
        id: project.id,
        title: project.name,
        value: quantitiesByProject[project.id] ? String(quantitiesByProject[project.id]) : '',
        balance: `${quantityFormatter.format(project.balance)} ${uom}`,
        unit: uom,
        isDisabled: !quantitiesByProject[project.id] && remainingQuantity === 0,
      };
    }),
    fundAllocation: Object.keys(quantitiesByProject).map((projectId) => {
      return {
        label: projects.find((project) => project.id === projectId)?.name ?? '',
        value: `${quantityFormatter.format(quantitiesByProject[projectId])} ${uom}`,
      };
    }),
  };

  return result;
};
