import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

import type { Country } from '../utils/Country';
import { toCountryIconUrl } from '../utils/toCountryIconUrl';
import { ImageWithPlaceholder } from './ImageWithPlaceholder';

type CountriesListProps = {
  /**
   * List of countries and their phone codes
   */
  countries: Array<Country>;

  /**
   * Handles press on country
   */
  onPressCountry(country: Country): void;
};

export const CountriesList: React.FC<CountriesListProps> = (props) => {
  const { countries, onPressCountry } = props;
  return (
    <StyledCountriesList>
      {countries.map((country, index) => (
        <CountryContainer onClick={() => onPressCountry(country)} key={index}>
          <ImageWithPlaceholder
            src={toCountryIconUrl(country.phoneCode)}
            alt={country.name}
            placeholder={'/assets/country-flags/placeholder.svg'}
          />
          <StyledNameText size={FontSize.small} weight={FontWeight.semibold}>
            {country.name}
          </StyledNameText>
          <Text size={FontSize.small} weight={FontWeight.semibold}>
            {country.phoneCode}
          </Text>
        </CountryContainer>
      ))}
    </StyledCountriesList>
  );
};

const StyledCountriesList = styled.div`
  max-height: 210px;
  overflow-y: auto;
  margin-top: 16px;
  &::-webkit-scrollbar {
    display: block;
    width: 4px;
    overflow: auto;
    height: 2px;
    background: transparent;
    margin-inline-end: -4px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(${Colors.gray_400});
    border-radius: 3px;
  }
`;

const CountryContainer = styled.div`
  display: flex;
  padding: 12px 0;
  gap: 12px;
  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(${Colors.gray_200});
  }
`;

const StyledNameText = styled(Text)`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;
