const generateDepositReference = ({
  rootUserId,
  tradingName,
  accountReferencePrefix,
}: {
  rootUserId: number;
  tradingName: string;
  accountReferencePrefix?: string;
}): string => {
  if (accountReferencePrefix) {
    return `${accountReferencePrefix}${rootUserId}`;
  }
  return tradingName;
};

export default generateDepositReference;
