import { queryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'refreshed-component/molecules/toast';

import { Entity } from 'state/entity';
import { logout, User } from 'state/user';
import { WS } from 'state/ws';

import emitter from 'utils/emitter';

type Props = {
  children: React.ReactNode;
};

const AppNotification = ({ children }: Props) => {
  const history = useHistory();
  const { resetUser, selector } = User.useContainer();
  const { entity } = Entity.useContainer();

  const onListeners = (eventName: string, args: Record<string, unknown>) => {
    if (['CONFIRMED_TRADE', 'CONFIRMED_TRANSACTION'].includes(eventName)) {
      queryCache.invalidateQueries(['account-balances']);
      queryCache.invalidateQueries(['account-balance']);
      queryCache.invalidateQueries(['fetchUsers']);
      queryCache.invalidateQueries(['contract/addresses-data']);
    }
    if (eventName.includes('FORCE_LOGOUT')) {
      toast.success(
        eventName === 'FORCE_LOGOUT' ? 'You have been logged out by the administrator' : 'You have been logged out',
      );
      logout().then(() => {
        resetUser();
        history.push('/');
      });
    } else if (eventName) {
      emitter.emit('WS_SOCKET_DATA', {
        eventName,
        args,
      });
    }
  };

  if (selector.isGuest()) {
    return <> {children} </>;
  }

  return (
    <WS.Provider
      initialState={{
        onListener: onListeners,
        roomId: '',
        entityId: entity.id,
      }}
    >
      {children}
    </WS.Provider>
  );
};

export default AppNotification;
