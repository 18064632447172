import { userAuthenticatedApi } from 'data-provider/fetcher';

type TokenPricesResponse = { marketPrice: { buy: number; sell: number }; settlementPrice: number };

export const fetchTokenPrices = async (pairId: number) => {
  const endpoint = '/oms/trade/token-prices';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ pairId }).get();
  return res.json<TokenPricesResponse>();
};
