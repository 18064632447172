import { OtcCriteria } from './OtcCriteria';
import { StaticFilters } from './StaticFilters';

export enum FiltersContextEventName {
  ChangedFilteringOptions = 'CHANGED_FILTERING_OPTIONS',
  FetchingFilteringOptions = 'FETCHING_FILTERING_OPTIONS',
  ChangedDynamicFilters = 'CHANGED_DYNAMIC_FILTERS',
  ChangedStaticFilters = 'CHANGED_STATIC_FILTERS',
}

export type FiltersContextEvent =
  | {
      event: FiltersContextEventName.ChangedFilteringOptions;
      payload: OtcCriteria;
    }
  | {
      event: FiltersContextEventName.FetchingFilteringOptions;
      payload: boolean;
    }
  | {
      event: FiltersContextEventName.ChangedDynamicFilters;
      payload: OtcCriteria;
    }
  | {
      event: FiltersContextEventName.ChangedStaticFilters;
      payload: StaticFilters;
    };

export type FiltersContextEventMap = { [E in FiltersContextEvent as E['event']]: E };
