import { useMemo } from 'react';
import { queryCache, useQuery } from 'react-query';

import type { AssetCategoryCode } from '@aircarbon/utils-common';
import type { Pair, MarketData } from '@aircarbon/utils-common/src/dto';

import { fetchPairs } from 'data-provider/oms/fetchPairs';

export function usePairs({
  filterByMarket = 'spot',
  includeMarketData = false,
  assetCategories,
  projectId,
}: {
  assetCategories: Array<AssetCategoryCode>;
  filterByMarket?: 'spot' | 'block';
  includeMarketData?: boolean;
  projectId?: string;
}) {
  const {
    data: pairsData,
    isLoading,
    error,
  } = useQuery(
    ['oms-pairs', assetCategories, filterByMarket, includeMarketData, projectId],
    () =>
      fetchPairs({
        assetCategories,
        filterByMarket,
        includeMarketData,
        projectId,
      }),
    {
      enabled: assetCategories.length > 0,
    },
  );

  const data = Array.isArray(pairsData) ? pairsData : [];
  const refreshPairsFromMQ = (payload: MarketData) => {
    const data = queryCache.getQueryData<Pair[]>(['oms-pairs']);
    const updatedItems = data?.map((item) => {
      if (item.id === payload.pairId && item.marketData) {
        item.marketData = {
          ...item.marketData,
          lastTradedPrice: payload.lastTradedPrice ?? item.marketData?.lastTradedPrice,
          lastBidPrice: payload.lastBidPrice ?? item.marketData?.lastBidPrice,
          lastAskPrice: payload.lastAskPrice ?? item.marketData?.lastAskPrice,
          volume: payload.volume ?? item.marketData?.volume,
          lowPrice: payload.lowPrice ?? item.marketData?.lowPrice,
          highPrice: payload.highPrice ?? item.marketData?.highPrice,
          changeValue: payload.changeValue ?? item.marketData?.changeValue,
          changePercentage: payload.changePercentage ?? item.marketData?.changePercentage,
        };
      }
      return item;
    });
    queryCache.setQueryData(['oms-pairs'], updatedItems);
  };

  const pairsSelectOptions = useMemo(() => {
    // TODO: get generic asset symbol from DB config
    const genericTonSymbol = 'XCT';

    const nonGenericPairs = data?.filter((pair) => pair.baseAsset?.symbol !== genericTonSymbol);
    const genericPair = data?.find((pair) => pair.baseAsset?.symbol === genericTonSymbol);
    return (
      [...(genericPair ? [genericPair] : []), ...(nonGenericPairs ?? [])]?.map((item) => ({
        label: `${item.name} - ${item.baseAsset?.fullName}`,
        value: item.id,
      })) || []
    );
  }, [data]);
  const getPairByAssetId = (assetId: number) => {
    return data?.find((item) => item?.baseAsset?.id === assetId);
  };

  const getPairById = (id: number) => {
    return data?.find((item) => item.id === id);
  };

  return {
    error,
    isLoadingWatchlist: isLoading,
    pairs: data,
    pairsSelectOptions,
    getPairById,
    pairsWatchList: data,
    getPairByAssetId,
    refreshPairsFromMQ: (payload: any) => refreshPairsFromMQ(payload),
  };
}
