import '@aws-amplify/ui/dist/style.css';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import { Amplify } from 'aws-amplify';
import { Grommet } from 'grommet';
import * as React from 'react';
import { Suspense, useMemo } from 'react';
import FullStory from 'react-fullstory';
import { ReactQueryDevtools } from 'react-query-devtools';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ThemedErrorHandler } from 'refreshed-component/molecules/ThemedErrorHandler';
import { ThemeProvider } from 'styled-components';

import { ToastContainer } from '@aircarbon/ui';
import { Const } from '@aircarbon/utils-common';

import AccountComponent from 'pages/account';
import { Themes } from 'pages/account/trading/components/Themes';

import AppNotification from 'components/AppNotification';
import Layout from 'components/Layout';
import ScrollToTop from 'components/ScrollToTop';
import Loading from 'components/styled/Loading';

import { Account } from 'state/account';
import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import ContractProvider from 'providers/ContractProvider';
import { UILibraryThemeProvider } from 'providers/UILibraryThemeProvider';

import './styles/antd.default.css';
// eslint-disable-next-line import/no-unresolved
import './styles/compiled/index.css';
import './styles/custom.css';

// Initialize Amplify with cognito configuration
Amplify.configure({
  Auth: {
    identityPoolId: process.env.WEB_AWS_COGNITO_USERS_POOL_ID,
    region: process.env.WEB_AWS_REGION ?? 'ap-southeast-1',
    userPoolId: process.env.WEB_AWS_COGNITO_USERS_POOL_ID,
    userPoolWebClientId: process.env.WEB_AWS_COGNITO_USERS_WEB_CLIENT_ID,
    authenticationFlowType: 'CUSTOM_AUTH',
  },
  oauth: {},
});

const Logout = React.lazy(() => import('pages/Logout'));
const PageNotFound = React.lazy(() => import('pages/PageNotFound'));
const Explorer = React.lazy(() => import('pages/explorer'));
const PublicTrading = React.lazy(() => import('pages/account/trading/PublicTrading'));

Sentry.init({
  dsn: process.env.WEB_SENTRY_DSN,
  environment: process.env.WEB_DEPLOYMENT_ENV,
  // Disable Sentry in development
  enabled: !process.env.WEB_URL?.includes('localhost') && !process.env.WEB_URL?.includes('.local'),
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const ThemeWrapper = () => {
  const { getSetting } = UI.useContainer();
  const enableExternalUser = Number(getSetting('web_settings_enabled_externalUser') || 0) === 1;
  const featureDelayedBiofuelEnabled = Number(getSetting(Const.FeatureToggle.DelayedBiofuel) || 0) === 1;
  const featureRecEnabled = Number(getSetting(Const.FeatureToggle.DelayedRec) || 0) === 1;
  const featureDelayedCarbonEnabled = Number(getSetting(Const.FeatureToggle.DelayedCarbon) || 0) === 1;

  const canViewDelayedMarket = featureRecEnabled || featureDelayedBiofuelEnabled || featureDelayedCarbonEnabled;
  const allowedPath = enableExternalUser
    ? ['/account/*', '/sign-up', '/cmb/seller/sign-up', '/auction/buyer/sign-up']
    : ['/account/*', '/sign-up'];

  const { entity } = Entity.useContainer();
  const { grommetTheme, theme } = useMemo(() => {
    const entityTheme = entity?.theme?.web;
    return {
      grommetTheme: {
        global: {
          colors: {
            brand: entityTheme?.brandColor,
            focus: entityTheme?.brandColor,
          },
          font: {
            size: '15px',
            height: '20px',
          },
          spacing: '2em',
        },
        checkBox: {
          color: entityTheme?.brandColor,
          toggle: {
            radius: '12px',
          },
          border: {
            color: '#222',
          },
          hover: {
            border: {
              color: entityTheme?.brandColor,
            },
          },
        },
        select: {
          icons: {
            color: '#e2e8f0',
            margin: '10px',
          },
        },
        tab: {
          active: {
            background: 'transparent',
            color: '#424242',
          },
          background: 'transparent',
          border: {
            color: 'transparent',
            active: {
              color: {
                light: entityTheme?.brandColor,
              },
            },
            hover: {
              color: {
                light: entityTheme?.brandColor,
              },
            },
          },
          color: '#828282',
          hover: {
            background: 'transparent',
            color: '#424242',
          },
          margin: {
            horizontal: 'small',
            vertical: 'none',
          },
          pad: 'small',
        },
      },
      theme: entityTheme,
    };
  }, [entity?.theme?.web]);

  if (window?.location?.pathname.includes('carbon-finder')) {
    grommetTheme.checkBox.border.color = '#929292';
  }

  const mainPath = ['/', '/account/dashboard', '/explore', '/explore/*'];
  if (canViewDelayedMarket) {
    mainPath.push('/market');
  }

  if (!entity || !grommetTheme || !theme) return <div className="contents" />;

  return (
    <ErrorBoundary // for branded error boundary. if something goes wrong in it then it will render old error handler on.
      fallback={() => (
        <Entity.Provider>
          <Grommet theme={grommetTheme} full>
            <ThemeProvider theme={theme}>
              <Themes.Provider>
                <UILibraryThemeProvider>
                  <User.Provider>
                    <ThemedErrorHandler />
                  </User.Provider>
                </UILibraryThemeProvider>
              </Themes.Provider>
            </ThemeProvider>
          </Grommet>
        </Entity.Provider>
      )}
    >
      <Grommet theme={grommetTheme} full>
        <ThemeProvider theme={theme}>
          <Themes.Provider>
            <UILibraryThemeProvider>
              <User.Provider>
                <ContractProvider>
                  <AppNotification>
                    <Switch>
                      <Route exact path={mainPath}>
                        <Layout title={entity?.displayName}>
                          <ScrollToTop />
                          <Suspense fallback={<Loading />}>
                            <Switch>
                              <Route exact path={['/', '/account/dashboard']}>
                                <Redirect to="/account/balances" />
                              </Route>
                              <Route path="/explore">
                                <Explorer />
                              </Route>
                              {canViewDelayedMarket && (
                                <Route path="/market">
                                  <PublicTrading />
                                </Route>
                              )}
                            </Switch>
                          </Suspense>
                        </Layout>
                      </Route>
                      <Route exact path={allowedPath}>
                        <Suspense fallback={<Loading />}>
                          <Account.Provider>
                            <AccountComponent />
                          </Account.Provider>
                        </Suspense>
                      </Route>
                      <Route path={'*'}>
                        <Layout title={entity?.displayName}>
                          <ScrollToTop />
                          <Suspense fallback={<Loading />}>
                            <Switch>
                              <Route exact path="/logout" component={Logout} />
                              <Route path="*">
                                <PageNotFound />
                              </Route>
                            </Switch>
                          </Suspense>
                        </Layout>
                      </Route>
                    </Switch>
                  </AppNotification>
                </ContractProvider>
              </User.Provider>
              <ToastContainer />
            </UILibraryThemeProvider>
          </Themes.Provider>
        </ThemeProvider>
      </Grommet>
    </ErrorBoundary>
  );
};

const App: React.FC = () => {
  return (
    <React.Fragment>
      <Router>
        <Entity.Provider>
          <UI.Provider>
            <ThemeWrapper />
          </UI.Provider>
        </Entity.Provider>
      </Router>
      {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} position="top-left" />}
      {process.env.NODE_ENV === 'production' && process.env.WEB_FULLSTORY_KEY && (
        <FullStory org={String(process.env.WEB_FULLSTORY_KEY)} />
      )}
      <div id="modal-root" />
    </React.Fragment>
  );
};

export default App;
