import { CardCMBProps } from '@aircarbon/ui';

import { formatDate } from 'utils/helpers';

import { FetchListingsResponse } from '../components/Listings/utils/fetchListings';
import { OrderDetails } from '../components/PlaceOrderModal';
import { toTuple } from './toTuple';

const quantityFormatter = Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const toPlaceOrderModalOrderDetails =
  (
    toMetaFields: (criteriaItem: { id: string; key: string; value: string }) => CardCMBProps['metaFields'][0][0] | null,
  ) =>
  (otcOrderApiItem: FetchListingsResponse['data'][0]): OrderDetails => {
    return {
      id: otcOrderApiItem.id,
      type: otcOrderApiItem.type,
      endsAt: otcOrderApiItem?.expiryUtc ? formatDate(otcOrderApiItem?.expiryUtc) : '',
      openQuantity: quantityFormatter.format(otcOrderApiItem.quantity),
      rawQuantity: otcOrderApiItem.quantity,
      rawPrice: otcOrderApiItem.price,
      isPrefunded: otcOrderApiItem.isPrefunded === 1,
      price: new Intl.NumberFormat('en-US', { style: 'currency', currency: otcOrderApiItem.priceCurrency }).format(
        otcOrderApiItem?.price || 0,
      ),
      priceLabel: `Price (per ${otcOrderApiItem.quantityUnit})`,
      minimumQuantity: otcOrderApiItem.minQty,
      maximumQuantity: otcOrderApiItem.maxQty,
      quantityMultiplesOf: otcOrderApiItem.qtyMultipleOf,
      contractType: otcOrderApiItem.baseAsset?.name,
      auctionRulesLink: otcOrderApiItem.auctionRulesLink,
      metaFields: [
        ...(otcOrderApiItem?.project?.name
          ? [
              [
                {
                  label: 'Project',
                  value: [otcOrderApiItem.project.registryProjectId, otcOrderApiItem.project.name]
                    .filter((v) => !!v)
                    .join(' | '),
                },
              ],
            ]
          : ([] as any)),
        ...(otcOrderApiItem.projects?.length
          ? [
              [
                {
                  label: otcOrderApiItem.projects.length > 1 ? 'Projects' : 'Project',
                  value:
                    otcOrderApiItem.projects.length > 1
                      ? otcOrderApiItem.projects.map((project) => project.registryProjectId).join(', ')
                      : [otcOrderApiItem.projects[0].registryProjectId, otcOrderApiItem.projects[0].name]
                          .filter((v) => !!v)
                          .join(' | '),
                },
              ],
            ]
          : []),
        ...toTuple(otcOrderApiItem.orderCriteria?.map(toMetaFields).filter((field) => !!field) || []),
      ],
    };
  };
