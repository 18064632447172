import { ApiClient, GetMyProjectsResponse } from 'generated';
import { QueryConfig, useQuery } from 'react-query';

import { helpers } from '@aircarbon/utils-common';

import { User } from 'state/user';

async function fetchMyProjects({
  statuses,
  searchBy,
  getAuthToken,
}: {
  statuses: string[];
  searchBy: string;
  getAuthToken: () => Promise<string>;
}) {
  const token = await getAuthToken();
  const apiClient = new ApiClient({
    BASE: window.location.origin,
    TOKEN: token,
  });

  const result = await apiClient.settle.myProjects({
    xEntityDomain: helpers.getHostFromUrl(window.location.origin),
    status: statuses,
    searchBy,
  });

  if (result.status === 'success') {
    return result.data;
  }

  throw new Error(result.message ?? 'Failed to fetch my projects');
}

type ProjectResponse = GetMyProjectsResponse['data'];

function useMyProjects({
  statuses,
  searchBy,
  options,
}: {
  statuses: string[];
  searchBy: string;
  options?: QueryConfig<ProjectResponse>;
}) {
  const { selector } = User.useContainer();
  const { data, isLoading, isError, error } = useQuery(
    ['/api/settle/my-projects', statuses, searchBy],
    () => fetchMyProjects({ statuses, searchBy, getAuthToken: selector.getAuthToken }),
    {
      ...options,
    },
  );

  return {
    projects: data?.projects ?? [],
    isLoading,
    isError,
    error,
  };
}

export default useMyProjects;
