import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled, { keyframes } from 'styled-components';

interface SearchResultProps {
  title: string;
  description: string;
  onPress(): void;
}

export const SearchResult: React.FunctionComponent<SearchResultProps> & {
  Loader: React.FunctionComponent;
} = (props) => {
  return (
    <StyledSearchResult onClick={props.onPress}>
      <Text size={FontSize.small} weight={FontWeight.medium}>
        {props.title}
      </Text>
      <Text size={FontSize.xs} color={Colors.gray_500}>
        {props.description}
      </Text>
    </StyledSearchResult>
  );
};

const StyledSearchResult = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  cursor: pointer;

  &:not(:first-child) {
    border-top: 1px solid var(${Colors.gray_200});
  }
`;

const SearchResultLoader = () => {
  return (
    <StyledSearchResultLoader>
      <LoaderBar style={{ width: '80%' }} />
      <LoaderBar style={{ width: '60%', height: '10px', borderRadius: '5px' }} />
    </StyledSearchResultLoader>
  );
};

// Create the keyframes
const loaderAnimation = keyframes`
  to {
    background-position: right -270px top 0;
  }
`;

const LoaderBar = styled.div`
  background-size: 270px 100%;
  background-repeat: no-repeat;
  background-position: left -270px top 0;
  animation: ${loaderAnimation} 1s ease infinite;
  border-radius: 6px;
  background-color: #f2f2f2;
  background-image: linear-gradient(90deg, transparent, var(${Colors.gray_0}), transparent);
  height: 12px;
`;

const StyledSearchResultLoader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  width: 260px;
  gap: 6px;

  &:not(:first-child) {
    border-top: 1px solid var(${Colors.gray_200});
  }
`;

SearchResult.Loader = SearchResultLoader;
