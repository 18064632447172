import { useEffect, useState } from 'react';

enum ImageState {
  Loading = 0,
  Loaded = 1,
  Error = 2,
}

export const ImageWithPlaceholder: React.FC<{
  src: string;
  placeholder: string;
  className?: string;
  alt?: string;
}> = (props) => {
  const { src, placeholder, className, alt } = props;
  const [imageState, setImageState] = useState(ImageState.Loading);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    // Reset the state when the src changes
    setImageState(ImageState.Loading);
    setHasError(false);
  }, [src]);

  const onLoadImage = () => {
    if (!hasError) {
      setImageState(ImageState.Loaded);
    }
  };

  const onErrorImage = () => {
    if (!hasError) {
      setImageState(ImageState.Error);
      setHasError(true);
    }
  };

  return (
    <img
      onLoad={onLoadImage}
      onError={onErrorImage}
      className={className}
      alt={alt}
      src={imageState === ImageState.Error ? placeholder : src}
    />
  );
};
