import { Text } from 'refreshed-component/atoms/Text';
import { Spacing, FontSize, FontWeight, Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

export type ToggleProps = {
  id: number | string;
  label: string;
  className?: string;
  isSelected?: boolean;
  onClick?: (props: ToggleProps) => void;
};

const ToggleRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: var(${Spacing.xs});
  cursor: pointer;
  font-size: var(${FontSize.small});
  font-weight: var(${FontWeight.medium});
  max-width: 300px;
  line-height: 22px;
  > .container {
    width: 44px;
    height: 24px;
    display: flex;
    flex-direction: row;
    border-radius: 100px;
    padding: 2px;
    &.on {
      justify-content: end;
      align-items: end;
      background-color: var(${Colors.success_400});
    }
    &.off {
      justify-content: start;
      align-items: start;
      background-color: var(${Colors.gray_500});
    }
    > .circle {
      width: 20px;
      height: 20px;
      background-color: var(${Colors.gray_0});
      border-radius: 100px;
    }
  }
`;

export const Toggle = (props: ToggleProps) => {
  return (
    <ToggleRoot onClick={() => props.onClick?.(props)} tabIndex={1} className={props.className}>
      <div className={`container ${props.isSelected ? 'on' : 'off'}`}>
        <div className="circle" />
      </div>
      <Text>{props.label}</Text>
    </ToggleRoot>
  );
};
