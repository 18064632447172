import { useEffect, useMemo, useState } from 'react';
import { colorsAcxDark, colorsAcxLight } from 'refreshed-component/design-system';
import { Colors, ColorsType } from 'refreshed-component/design-system/enums';
import { createContainer } from 'unstated-next';

import { LocalStorage } from '@aircarbon/utils-common';

import { Entity } from 'state/entity';

import emitter from 'utils/emitter';

export type ThemeColors = {
  brandColor: {
    default: string;
  };
  main: {
    background: string;
    border: string;
  };
  orderHistory: {
    cancelButton: {
      background: string;
      active: {
        border: string;
      };
    };
  };
  placeHolder: {
    background: string;
    button: {
      color: string;
      background: string;
      sell: string;
      buy: string;
      disabled: {
        background: string;
      };
    };
    input: {
      text: string;
      label: string;
      color: string;
      background: string;
      border: string;
      active: {
        border: string;
      };
      highlight: {
        buy: string;
        sell: string;
      };
      disabled: {
        background: string;
      };
    };
  };
  panel: {
    button: {
      color: string;
      background: string;
      sell: string;
      buy: string;
      border: string;
      disabled: {
        background: string;
      };
      active: {
        border: string;
      };
    };
    title: {
      default: string;
      active: string;
    };
    search: {
      background: string;
      border: string;
    };
    text: {
      label: string;
      default: string;
      buy: string;
      sell: string;
    };
    border: string;
    lightBackground: string;
    background: string;
    splitter: string;
    scroll: {
      bar: string;
      opacity: number;
    };
  };
  table: {
    header: {
      default: string;
      active: string;
    };
    active: {
      text: string;
      background: string;
    };
    text: {
      default: string;
      green: string;
      red: string;
      yellow: string;
      blue: string;
      bright: string;
    };
    border: string;
    background: string;
  };
  dropdown: {
    background: string;
    decoration: string;
    border: string;
    text: string;
    active: {
      itemBackground: string;
    };
  };
  pairDropdown: {
    background: string;
    border: string;
    logoBackground: string;
    color: string;
  };
  balancePL: {
    summary: {
      border: string;
      background: string;
    };
  };
  rfq: RFQThemesColors;
  dashboard: DashboardThemesColors;
};

export type ThemeMode = 'dark' | 'light';

type DashboardThemesColors = {
  background: string;
  deepBackground: string;
  contentWrapperBackground: string;
  info: {
    label: string;
    innerLabel: string;
  };
  block: {
    background: string;
    border: string;
    lightBorder: string;
  };
  table: {
    border: string;
    background: string;
    hover: {
      background: string;
    };
  };
  disabledColor: string;
  color: string;
  lightColor: string;
  brightColor: string;
  sideMenu: {
    border: string;
    color: string;
    icon: string;
  };
  button: {
    color: string;
    filter: {
      background: string;
      color: string;
      fill: string;
    };
    refresh: {
      color: string;
      hover: {
        background: string;
      };
      disabled: {
        color: string;
      };
    };
  };
  form: {
    title: {
      background: string;
      border: string;
    };
    input: {
      background: string;
      border: string;
      selectColor: string;
      color: string;
      placeholder: string;
    };
    button: {
      cancel: {
        border: string;
        color: string;
      };
    };
    background: string;
    sectionBackground: string;
    warning: string;
  };
};

type RFQThemesColors = {
  background: string;
  lightBackground: string;
  sidebar: {
    titleBackground: string;
    table: {
      border: string;
      cell: string;
      criteria: {
        background: string;
      };
      input: {
        background: string;
        border: string;
      };
      button: {
        background: string;
        border: string;
        color: string;
        disabled: {
          color: string;
          hoverBackground: string;
        };
      };
    };
  };
  content: {
    background: string;
    form: {
      title: {
        background: string;
        border: string;
      };
      input: {
        fieldBackground: string;
        background: string;
        border: string;
        selectColor: string;
        color: string;
      };
      background: string;
      sectionBackground: string;
    };
    border: string;
  };
  color: string;
};

function getPreferredColorScheme() {
  if (window.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    } else {
      return 'light';
    }
  }
  return 'light';
}

export function getTheme() {
  return LocalStorage.getItem<'dark' | 'light' | 'system'>('ui-theme', 'system');
}

export function setTheme(theme: string) {
  return LocalStorage.setItem('ui-theme', theme);
}

export function useTheme() {
  const { entity } = Entity.useContainer();
  const tradingTheme = entity.theme.web.trading;

  const lightDashboardTheme: DashboardThemesColors = {
    background: '#f7f7f7',
    deepBackground: '#ededed',
    contentWrapperBackground: '#f9f9f9',
    color: '#000',
    brightColor: '#828282',
    lightColor: '#424242',
    disabledColor: '#929292',
    info: {
      label: '#626262',
      innerLabel: '#717171',
    },
    block: {
      background: '#fff',
      border: '#ccc',
      lightBorder: 'rgba(200, 200, 200, 0.4)',
    },
    table: {
      border: '#eaeaea',
      background: '#f5f5f5',
      hover: {
        background: '#ededed',
      },
    },
    button: {
      color: '#fff',
      filter: {
        background: '#fff',
        color: '#828275',
        fill: '#bbb',
      },
      refresh: {
        color: '#424242',
        hover: {
          background: '#ededed',
        },
        disabled: {
          color: '#c2c2c2',
        },
      },
    },
    sideMenu: {
      border: '#ddd',
      color: '#222',
      icon: '#9d9d9d',
    },
    form: {
      input: {
        background: '#fff',
        border: '#ddd',
        selectColor: '#000',
        color: '#000',
        placeholder: '#929292',
      },
      button: {
        cancel: {
          border: '#a51111',
          color: '#cb1919',
        },
      },
      title: { background: '#f5f5f5', border: '#000' },
      background: '#fff',
      sectionBackground: '#fdfdfd',
      warning: '#991b1b',
    },
  };

  const darkDashboardTheme: DashboardThemesColors = {
    background: '#262626',
    deepBackground: '#151515',
    contentWrapperBackground: '#222222',
    color: '#fff',
    lightColor: '#c5c5c5',
    brightColor: '#828282',
    disabledColor: '#929292',
    info: {
      label: '#626262',
      innerLabel: '#717171',
    },
    block: {
      background: '#2c2c2c',
      border: '#666',
      lightBorder: 'rgba(111, 111, 111, 0.4)',
    },
    table: {
      border: '#333',
      background: '#222',
      hover: {
        background: '#3f3f3f',
      },
    },
    button: {
      filter: {
        background: '#333',
        color: '#bbb',
        fill: '#eee',
      },
      color: '#fff',
      refresh: {
        color: '#ccc',
        hover: {
          background: '#000',
        },
        disabled: {
          color: '#666',
        },
      },
    },
    sideMenu: {
      border: '#404040',
      color: '#ddd',
      icon: '#626262',
    },
    form: {
      input: {
        background: '#202020',
        border: '#444',
        selectColor: '#ededed',
        color: '#fff',
        placeholder: '#ddd',
      },
      button: {
        cancel: {
          border: '#c34e4e',
          color: '#d65b5b',
        },
      },
      title: { background: '#222', border: '#000' },
      background: '#121212',
      sectionBackground: '#161616',
      warning: '#c04848',
    },
  };

  tradingTheme.dark.dashboard = darkDashboardTheme;
  tradingTheme.light.dashboard = lightDashboardTheme;

  const [{ theme, themeMode }, setTheme] = useState({
    theme: tradingTheme.dark,
    themeMode: 'dark',
  });

  const selectThemeColors = () => {
    const myTheme = entity.theme.web;
    return {
      primary25: `rgba(${myTheme.brandRbgColor}, 0.1)`,
      primary: `rgba(${myTheme.brandRbgColor}, 0.5)`,
      neutral0: theme.dashboard.form.input.background, // input background: ;
      neutral80: theme.dashboard.lightColor,
      neutral20: theme.dashboard.form.input.border,
      neutral30: `rgba(${myTheme.brandRbgColor}, 1)`,
      neutral10: theme.dashboard.background, // selected items
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    const onChange = () => {
      const loadedThemeMode = getTheme();
      const theme = loadedThemeMode === 'system' ? getPreferredColorScheme() : loadedThemeMode;
      setTheme({
        theme: tradingTheme[theme],
        themeMode: theme,
      });
    };

    onChange();
    window.matchMedia?.('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      onChange();
    });
    const removeEvent = emitter.on('trading-screen-theme-changed', onChange);
    return () => {
      removeEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const colors: ColorsType = useMemo(() => {
    return Object.entries(Colors).reduce((previousValue, currentValue, index, array) => {
      const darkColors = entity.theme.colorsDark || colorsAcxDark;
      const lightColors = entity.theme.colorsLight || colorsAcxLight;
      const [key] = array[index];
      previousValue[key] = ((themeMode === 'dark' ? darkColors : lightColors) as any)[key];
      return previousValue;
    }, {} as any);
  }, [themeMode]);

  return {
    setTheme,
    theme: theme as ThemeColors,
    themeMode: themeMode as ThemeMode,
    colors: colors as ColorsType,
    tradingTheme: tradingTheme as {
      light: ThemeColors;
      dark: ThemeColors;
    },
    selectThemeColors,
  };
}

export const Themes = createContainer(useTheme);
