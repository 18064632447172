import { queryCache, useQuery } from 'react-query';

import useAddressData from 'hooks/useAddressData';

import { fetchBatchByStId } from 'data-provider/contract/fetchBatchByStId';

export function useWeb3AccountBalance(accountAddress: string) {
  const { addressData, isLoading: isLoadingAddressData } = useAddressData({
    queryName: 'oms-web3-user-balance',
    address: accountAddress,
    options: { enabled: accountAddress?.length > 0 },
  });

  const { data, isLoading, error } = useQuery(
    ['oms-user-tokens', accountAddress],
    async () => {
      let tokens;
      let ccys;
      if (addressData) {
        if (addressData?.account?.tokens) {
          const batchPromises = await Promise.all(
            addressData.account.tokens?.map((token) => fetchBatchByStId(token.stId)),
          );

          tokens = addressData.account.tokens?.map((token, index) => ({
            tokTypeId: token.tokTypeId,
            currentQty: token.currentQty,
            batch: batchPromises[index],
          }));
        }
        if (addressData?.account?.ccys) {
          ccys = addressData?.account?.ccys?.map((ccy) => ({
            unit: ccy.unit,
            balance: ccy.balance,
            ccyTypeId: ccy.ccyTypeId,
            name: ccy.name,
          }));
        }
      }

      return {
        tokens,
        ccys,
      };
    },
    { enabled: !!addressData && accountAddress.length > 0 },
  );

  const refetchBalance = () => {
    queryCache.refetchQueries(['oms-web3-user-balance', accountAddress]);
  };

  const isCurrentTokenBalance = (tokenId: number) => {
    const tokenBalance = data?.tokens?.find((token) => token.tokTypeId === tokenId);
    return !!tokenBalance;
  };

  const getCurrentTokenBalance = (mapSmtToUom: number, tokenId: number, autoRetireAsset: boolean) => {
    if (isCurrentTokenBalance(tokenId)) {
      return (data?.tokens ?? []).reduce((result, token) => {
        if (autoRetireAsset) {
          return token.tokTypeId === tokenId && token.batch.data?.originator === accountAddress
            ? result + token.currentQty / mapSmtToUom
            : result;
        } else {
          return token.tokTypeId === tokenId ? result + token.currentQty / mapSmtToUom : result;
        }
      }, 0);
    }
    return 0;
  };

  const getBuyCurrentBalanceByCcy = (currency: string) => {
    const balanceObject = data?.ccys?.filter((ccy) => ccy.name === currency);
    return balanceObject?.[0]?.balance ?? 0;
  };

  return {
    error,
    isLoadingAccountBalance: isLoading || isLoadingAddressData,
    userAccount: data,
    refetchCurrentBalance: refetchBalance,
    selectors: {
      isSellCurrentBalance: (tokenId: number) => isCurrentTokenBalance(tokenId),
      getSellCurrentBalance: (mapSmtToUom: number, tokenId: number, autoRetireAsset: boolean) =>
        getCurrentTokenBalance(mapSmtToUom, tokenId, autoRetireAsset),
      getBuyCurrentBalance: (currency: string) => getBuyCurrentBalanceByCcy(currency),
      getBuyCurrentBalances: () => data?.ccys,
    },
  };
}
