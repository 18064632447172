import UomCode from './UomCode';
import { AssetCategory } from './assetCategory';

const assetCategoryUom: Record<AssetCategory, UomCode> = {
  [AssetCategory.token]: UomCode.tCO2,
  [AssetCategory.biofuel]: UomCode.ton,
  [AssetCategory.rec]: UomCode.MWh,
  [AssetCategory.fct]: UomCode.tCO2,
  [AssetCategory.currency]: UomCode.USD,
};

export default assetCategoryUom;
