import { type ChangeEventHandler, useEffect, useState } from 'react';
import { Dropdown } from 'refreshed-component/atoms/Dropdown';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { FieldWrapper } from 'components/styled/Styled';

import { VintagesTable } from './Milestones/components/VintagesTable';
import { useIssuanceYears } from './Milestones/hooks/useIssuanceYears';
import type { Vintage } from './Milestones/utils/Vintage';
import { toVintages } from './Milestones/utils/toVintages';

export const Milestones: React.FC<{
  value: {
    implementationDate: string;
    vintages: Array<Vintage>;
  };
  error?: Record<string, string>;
  onChange(value: { implementationDate: string; vintages: Array<Vintage> }): void;
}> = (props) => {
  const { value, error, onChange } = props;
  const { implementationDate, vintages: vintagesValue } = value;
  const {
    startYear,
    startYears,
    endYears,
    endYear,
    vintagesCount,
    onChangeStartYear,
    onChangeEndYear,
    onChangeVintagesCount,
  } = useIssuanceYears({
    implementationDate,
    prevVintages: vintagesValue,
  });
  const [vintages, setVintages] = useState(
    toVintages({
      prevVintages: vintagesValue,
      startYear,
      endYear,
    }),
  );

  useEffect(() => {
    const newVintages = toVintages({
      prevVintages: vintages,
      startYear,
      endYear,
    });
    setVintages(newVintages);

    onChange({
      implementationDate,
      vintages: newVintages,
    });
  }, [startYear, endYear]);

  const onChangeVintages = (vintages: Array<Vintage>) => {
    setVintages(vintages);
    onChange({ implementationDate, vintages });
  };

  const onChangeImplementationDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange({
      implementationDate: e.target.value,
      vintages,
    });
  };

  return (
    <>
      <Text size={FontSize.large}>Crediting Period Term</Text>
      <div className="flex w-full mt-large">
        <div className="pr-2 w-2/5">
          <FieldWrapper>
            <Text size={FontSize.small}>Expected Implementation Date*</Text>
            <Input
              config={{
                color: !!error?.implementationDate ? 'error' : 'gray',
              }}
              type="date"
              value={implementationDate}
              onChange={onChangeImplementationDate}
              placeholder="dd/mm/yyyy"
            />
            {!!error?.implementationDate && (
              <Text color={Colors.danger_700} size={FontSize.small}>
                {error?.implementationDate}
              </Text>
            )}
          </FieldWrapper>
        </div>
        <div className="flex pl-2 w-2/3">
          <div className="flex pr-2 w-2/3">
            <FieldWrapper>
              <Text size={FontSize.small}>Vintage Start Year*</Text>
              <Dropdown
                key={'project-location'}
                list={startYears}
                selected={[startYear]}
                config={{
                  color: 'gray',
                }}
                onSelectItem={(item) => onChangeStartYear(item.id as string)}
                placeholder="Select"
              />
            </FieldWrapper>
          </div>
          <div className="flex pr-2 pl-2 w-2/3">
            <FieldWrapper>
              <Text size={FontSize.small}>Vintage End Year*</Text>
              <Dropdown
                key={'project-location'}
                list={endYears}
                selected={[endYear]}
                config={{
                  color: 'gray',
                }}
                onSelectItem={(item) => onChangeEndYear(item.id as string)}
                placeholder="Select"
              />
            </FieldWrapper>
          </div>
          <div className="flex pl-2 w-1/3">
            <FieldWrapper>
              <Text size={FontSize.small}># Vintages</Text>
              <Input
                config={{
                  color: 'gray',
                }}
                type="number"
                min="1"
                onChange={(e) => onChangeVintagesCount(e.target.value)}
                value={vintagesCount}
                disabled
              />
            </FieldWrapper>
          </div>
        </div>
      </div>

      {!error?.implementationDate && !!error?.vintages && (
        <VintagesError>
          <Text size={FontSize.small} color={Colors.danger_700}>
            {error.vintages}
          </Text>
        </VintagesError>
      )}
      {!!startYear && !!endYear && (
        <VintagesTable onChange={onChangeVintages} maximumOfferedQuantity={20} vintages={vintages} />
      )}
    </>
  );
};

const VintagesError = styled.div`
  border-radius: 6px;
  background-color: var(${Colors.danger_100});
  padding: 16px;
`;
