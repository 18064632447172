import queryString from 'query-string';

import type { UserTradeResponse } from '@aircarbon/utils-common/src/dto';

import { userAuthenticatedApi } from '../fetcher';

export const fetchUserTrades = async (data: {
  page: number;
  limit: number;
  userId: number;
  startDate?: string;
  endDate?: string;
}) => {
  const qs = queryString.stringify(data, { arrayFormat: 'bracket', skipEmptyString: true, skipNull: true });
  const endpoint = `/oms/trade?${qs}`;
  const userService = await userAuthenticatedApi();

  const res = userService.url(endpoint).get();
  return res.json<UserTradeResponse>();
};
