import { gql, useSubscription } from '@apollo/client';
import { useState, useEffect } from 'react';

import { logger } from '@aircarbon/utils-common';

import { User } from 'state/user';

// TODO: Migrate to web socket later if needed
const RFQ_MESSAGES_SUBSCRIPTION = gql`
  subscription rfqMessages($authToken: String!) {
    rfqMessages(authToken: $authToken) {
      id
      type
      status
    }
  }
`;

const useCarbonFinderRealtimeMessage = () => {
  const { selector } = User.useContainer();
  const [authToken, setAuthToken] = useState('');

  useEffect(() => {
    selector
      .getAuthToken()
      .then((token) => {
        setAuthToken(token);
      })
      .catch((err) => {
        logger.error(err);
        setAuthToken('');
      });
  }, [selector]);

  const { data } = useSubscription(RFQ_MESSAGES_SUBSCRIPTION, {
    variables: { authToken },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      logger.warn({
        data,
      });
    },
    // Skip subscription if no auth token
    skip: !authToken,
  });
  return data;
};

export default useCarbonFinderRealtimeMessage;
