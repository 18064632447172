import styled from 'styled-components';

import type { ThemeColors } from 'pages/account/trading/components/Themes';

export const Wrapper = styled.div``;
export const TableWrapper = styled.div<{ themeColor?: ThemeColors }>`
  max-height: 350px;
  overflow: auto;

  & > table {
    table-layout: fixed;
    border-collapse: separate;
    width: 100%;
    border-spacing: 0;

    th {
      width: 130px;
    }

    .projectName {
      width: 100%;
    }

    thead th {
      border-bottom: 2px ​solid rgba(44, 171, 119, 0.5);
      position: sticky;
      top: 0;
    }

    tfoot td {
      border-top: 2px solid rgba(44, 171, 119, 0.5);
      position: sticky;
      bottom: 0;
    }

    th,
    td {
      background: ${({ themeColor }) => themeColor?.rfq.sidebar.table.cell || '#1a1a1a'};
      padding: 0.75rem 1rem;
      text-align: left;
      border-bottom: 1px solid ${({ themeColor }) => themeColor?.rfq.sidebar.table.border || '#000'};
    }

    input {
      background: ${({ themeColor }) => themeColor?.rfq.sidebar.table.input.background || '#111111'};
      padding: 0.5rem;
      border-radius: 5px;
      border: 1px solid ${({ themeColor }) => themeColor?.rfq.sidebar.table.input.border || '#323232'};
      width: 100%;

      &:hover,
      &:focus {
        border-color: ${(props) => props.theme.brandColor};
        outline: none;
      }
    }
  }
`;
