import * as React from 'react';
import type { Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { formatter, logger } from '@aircarbon/utils-common';

import { HitArea } from './HitArea';
import { Icon, IconType } from './Icon';
import { Text, type TextProps } from './Text';
import { Tooltip } from './Tooltip';

function clipboardCopy(text: string) {
  let success = false;

  if (typeof window !== 'undefined') {
    // Use the Async Clipboard API when available. Requires a secure browsing
    // context (i.e. HTTPS)
    if (navigator.clipboard) {
      return navigator.clipboard.writeText(text).catch((err) => {
        throw err !== undefined ? err : new DOMException('The request is not allowed', 'NotAllowedError');
      });
    }

    // ...Otherwise, use document.execCommand() fallback

    // Put the text to copy into a <span>
    const span = document.createElement('span');
    span.textContent = text;

    // Preserve consecutive spaces and newlines
    span.style.whiteSpace = 'pre';

    // Add the <span> to the page
    document.body.appendChild(span);

    // Make a selection object representing the range of text selected by the user
    const selection = window.getSelection();
    const range = window.document.createRange();

    if (selection) {
      selection.removeAllRanges();
      range.selectNode(span);
      selection.addRange(range);

      // Copy text to the clipboard
      try {
        success = window.document.execCommand('copy');
      } catch (err) {
        logger.error(err);
      }

      // Cleanup
      selection.removeAllRanges();
      window.document.body.removeChild(span);
    }
  }

  return success
    ? Promise.resolve()
    : Promise.reject(new DOMException('The request is not allowed', 'NotAllowedError'));
}

export interface CopyInputProps {
  text: string;
  children?: never;
  length: number;
  svgColor?: Colors;
}

export const CopyInput: React.FC<TextProps & CopyInputProps> = ({ text, length = 16, ...props }) => {
  const [done, setDone] = React.useState(false);
  return (
    <Text {...props}>
      {formatter.truncateMiddle(text || '', 16)}

      {done ? (
        <Tooltip key={'copied'} visible={true} text={'Copied'}>
          <HitArea width={20} height={20}>
            <Icon color={props.svgColor || props.color} type={IconType.Check} width={12} height={12} />
          </HitArea>
        </Tooltip>
      ) : (
        <Tooltip key={'copy'} text={'Click to Copy'}>
          <HitArea
            onClick={() => {
              clipboardCopy(text);
              setDone(true);
              setTimeout(() => setDone(false), 1500);
            }}
            width={20}
            height={20}
            className="cursor-pointer"
          >
            <Icon color={props.svgColor || props.color} type={IconType.Copy} width={18} height={18} />
          </HitArea>
        </Tooltip>
      )}
    </Text>
  );
};
