import type { Dto } from '@aircarbon/utils-common';

import type { ProjectFormValue } from '../../ProjectForm';

export const toNewProjectApiBody = (formValue: ProjectFormValue): Dto.AddApxProjectApiParams => {
  const params: Dto.AddApxProjectApiParams = {
    name: formValue.projectName,
    description: formValue.projectDescription,
    countryCode: formValue.projectLocation,
    methodology: Number(formValue.methodology),
    customMethodology: formValue.customMethodology,
    carbonProjectType: Number(formValue.projectType),
    apxPhase: Number(formValue.projectPhase),
    registry: Number(formValue.standardUsed),
    sectoralScopeId: formValue.sectoralScope ? Number(formValue.sectoralScope) : undefined,
    sdgGoals: formValue.sdgGoals?.length ? formValue.sdgGoals.map((goal) => Number(goal)) : undefined,
  };

  return params;
};
