export const wait = <T>(
  job: () => Promise<T>,
  timeout?: number,
): {
  cancel(): void;
  job: Promise<T>;
} => {
  let isCanceled = false;
  let waitTimeout: ReturnType<typeof setTimeout>;

  const cancellablePromise = new Promise<T>(async (resolve, reject) => {
    const resolveJob = async () => {
      try {
        const result = await job();

        if (!isCanceled) {
          resolve(result);
        }
      } catch (e) {
        if (!isCanceled) {
          reject(e);
        }
      }
    };

    if (timeout === undefined) {
      resolveJob();
      return;
    }

    waitTimeout = setTimeout(resolveJob, timeout);
  });

  const cancel = () => {
    isCanceled = true;
    if (waitTimeout !== undefined) {
      clearTimeout(waitTimeout);
    }
  };

  return {
    job: cancellablePromise,
    cancel,
  };
};
