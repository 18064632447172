import { Auth } from 'aws-amplify';
import wretch, { type FetchLike, type WretchOptions } from 'wretch';
import queryString from 'wretch/addons/queryString';
import { retry, dedupe } from 'wretch/middlewares';

import { logger } from '@aircarbon/utils-common';

const { WEB_INDEXER_API } = process.env;

export type ApiResponse<T> = { status: 'success' | 'fail' | 'error'; code?: number; data?: T };

/**
 * Logger middleware.
 * Prints the request method and url.
 */
function loggerMiddleware() {
  return (next: FetchLike) => (url: string, opts: WretchOptions) => {
    logger.info(`FETCHER: ${opts.method}@${url}`);
    return next(url, opts);
  };
}

type RetryOptions = Parameters<typeof retry>[0];

/**
 * Retry with logger.
 * Skip the retry if the response is 401, 403 or 404.
 */
function retryWithLogger(options?: RetryOptions) {
  return retry({
    ...options,
    until: (response, _error) => {
      if (response) {
        // Skip retry if 401 (unauthorized) or 403 (forbidden) or 404 (not found)
        if ([400, 401, 403, 404].includes(response.status)) {
          logger.info(`FETCHER: response status ${response.status}. Skip retry.`);
          return true;
        }
      }

      return Boolean(response?.ok);
    },
    onRetry: ({ url }) => {
      logger.info(`FETCHER: retrying ${url}`);
    },
    resolveWithLatestResponse: true, // this is needed to properly handle last error coming from the response instead of getting wretch error
  });
}

async function getAuthToken() {
  const user = await Auth.currentSession();
  return `Bearer ${user.getAccessToken().getJwtToken()}`;
}

export const externalApi = wretch('/api', { mode: 'cors' })
  .addon(queryString)
  .middlewares([
    loggerMiddleware(),
    retryWithLogger({
      delayTimer: 500,
      delayRamp: (delay, nbOfAttempts) => delay * nbOfAttempts,
      maxAttempts: 3,
    }),
    dedupe(),
  ])
  .errorType('json');

export const indexerApi = wretch(`${WEB_INDEXER_API}`, { mode: 'cors' })
  .addon(queryString)
  .middlewares([
    loggerMiddleware(),
    retryWithLogger({
      delayTimer: 500,
      delayRamp: (delay, nbOfAttempts) => delay * nbOfAttempts,
      maxAttempts: 3,
    }),
    dedupe(),
  ])
  .errorType('json');

export const userAuthenticatedApi = async () => {
  const authToken = await getAuthToken();
  return externalApi.auth(authToken);
};
