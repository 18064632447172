import { userAuthenticatedApi } from '../fetcher';

export const fetchUserTokenHistory = async ({
  page,
  limit,
  isBasket,
  statusQuery,
  type,
}: {
  page: number;
  limit: number;
  isBasket?: 'YES' | 'NO';
  statusQuery: string;
  type: string;
}) => {
  const endpoint = `/user/token/${type}?${
    isBasket ? `&isBasket=${isBasket}` : ''
  }&page=${page}&limit=${limit}${statusQuery}`;
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).get();
  return res.json();
};
