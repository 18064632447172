import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, queryCache } from 'react-query';
import { Button } from 'refreshed-component/atoms/Button';
import { Input } from 'refreshed-component/atoms/Input';
import { Text } from 'refreshed-component/atoms/Text';
import { Tooltip } from 'refreshed-component/atoms/Tooltip';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import Loading from 'refreshed-component/molecules/Loading';
import { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import { toast } from 'refreshed-component/molecules/toast';
import { AuctionBidsQueryKey } from 'refreshed-pages/auction/Marketplace';
import { CmbBidsQueryKey } from 'refreshed-pages/market-board/Marketplace';
import * as yup from 'yup';

import { logger, helpers, formatter, FeeType, type AssetCategory } from '@aircarbon/utils-common';

import type { CmbAsk } from 'pages/account/carbon/CMB/types';

import FormDevTool from 'components/FormDevTool';
import SelectOboAccount, { type AccountDetails } from 'components/SelectOboAccount';

import { Entity } from 'state/entity';
import { User } from 'state/user';

import useAvailableBalance from 'hooks/useAvailableBalance';
import useCurrencies from 'hooks/useCurrencies';
import useDebounce from 'hooks/useDebounce';
import useFee from 'hooks/useFee';

import { convertTextNumberToValue } from 'utils/helpers';

type FormData = {
  quantity: number;
  price: number;
};

type Props = {
  ask: CmbAsk;
  tokenAssetCategoryId: AssetCategory;
  depositPercentage: number;
  minQty: number;
  maxQty: number;
  bidQtyMultiplier: number;
  minBid?: number;
  availableBalance: number;
  tokenUnit: string;
  onSuccess?: () => void;
  onLoading?: (isLoading?: boolean) => void;
};

const bidFormSchema = ({
  minQty,
  maxQty,
  bidQtyMultiplier,
  minBid,
  availableBalance,
  availableQuantity,
  tokenUnit,
  depositAmount,
}: {
  minQty: number;
  maxQty: number;
  bidQtyMultiplier: number;
  minBid?: number;
  availableBalance: number;
  availableQuantity: number;
  tokenUnit: string;
  depositAmount: number;
}) =>
  yup.object().shape({
    quantity: yup
      .number()
      .required()
      .test(
        'minQuantity',
        `Minimum quantity is ${formatter.formatNumber(minQty ?? 0, 0)} ${tokenUnit}`,
        function minQuantity(quantity?: number) {
          if (minQty && quantity) {
            return quantity >= minQty;
          }
          return true;
        },
      )
      .test(
        'maxQuantity',
        `Maximum quantity is ${formatter.formatNumber(maxQty ?? 0, 0)} ${tokenUnit}`,
        function maxQuantity(quantity?: number) {
          if (maxQty && quantity) {
            return quantity <= maxQty;
          }
          return true;
        },
      )
      .test('hasEnoughAmount', 'Insufficient funds to cover deposit', function hasEnoughAmount() {
        return depositAmount <= Number(availableBalance);
      })
      .test('isInMultiplesOf', `Should be multiple of ${bidQtyMultiplier}`, function isMultipleOf(quantity: any) {
        if (bidQtyMultiplier && quantity) {
          return quantity % bidQtyMultiplier === 0;
        }
        return true;
      })
      .test(
        'availableQty',
        `There is only ${formatter.formatNumber(availableQuantity, 0)} ${tokenUnit} available`,
        function availableQty(quantity: any) {
          if (availableQuantity && quantity) {
            return quantity <= availableQuantity;
          }
          return true;
        },
      ),
    price: yup
      .number()
      .required()
      .test(
        'minBidValidation',
        `Minimum bid is ${formatter.formatNumber(minBid ?? 0)}`,
        function minBidValidation(price?: number) {
          if (!price) return false;
          if (minBid && price) return price >= minBid;
          return true;
        },
      )
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;

        return convertTextNumberToValue(originalValue);
      }),
  });

const PlaceBid = ({
  ask,
  tokenAssetCategoryId,
  minQty,
  maxQty,
  bidQtyMultiplier,
  depositPercentage,
  minBid,
  availableBalance,
  tokenUnit,
  onSuccess,
  onLoading,
}: Props) => {
  const {
    selector: { mainCcyScId, mainCcyCode, mainCcyNumDecimals },
  } = Entity.useContainer();

  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();
  const ccyAsset = currenciesObjById?.[ask?.quoteAssetId];
  const {
    selector: { getAuthToken, getAccountAddress, getUserId, getFullName },
    status: { canCmbBidObo },
  } = User.useContainer();

  // Used for Members
  const [selectedAccount, setSelectedAccount] = useState<AccountDetails>({
    account: getAccountAddress(),
    userId: getUserId(),
    fullName: getFullName(),
  });

  const auctionBidsQueryKey = useContext(AuctionBidsQueryKey);
  const cmbBidsQueryKey = useContext(CmbBidsQueryKey);

  const [tokenQty, setTokenQty] = useState(0);
  const [price, setPrice] = useState(ask.price);
  const debouncedTokenQty = useDebounce(tokenQty, 500);
  const debouncedPrice = useDebounce(price, 500);

  const debouncedTradeTotal = debouncedTokenQty * debouncedPrice;

  const { feeAmount, isLoading: isLoadingFeeAmount } = useFee({
    params: {
      feeType: ask?.isAuction ? FeeType.TRADE_AUCTION_FEE : FeeType.TRADE_CMB_FEE,
      assetCategoryId: tokenAssetCategoryId,
      tokenQty: debouncedTokenQty,
      totalAmount: debouncedTradeTotal,
    },
    options: { enabled: debouncedTokenQty > 0 && debouncedPrice > 0 },
  });

  const depositAmount =
    depositPercentage === 100 ? debouncedTradeTotal + feeAmount : (depositPercentage * debouncedTradeTotal) / 100;

  const { balance: selectedAccountBalance, isLoading: isLoadingAccountBalance } = useAvailableBalance({
    params: { ccyTypeId: mainCcyScId, getRoot: false, userId: selectedAccount?.userId },
    options: { enabled: !!mainCcyScId },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      price: ask.price,
    },
    resolver: yupResolver(
      bidFormSchema({
        minQty,
        maxQty,
        bidQtyMultiplier,
        minBid,
        availableBalance: selectedAccountBalance?.availableAmount ?? availableBalance,
        availableQuantity: ask.quantity - ask.openBidsTotalQty,
        tokenUnit,
        depositAmount,
      }),
    ),
  });

  const [mutate, { isLoading, isSuccess }] = useMutation(async (bid: Record<string, any>) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    const data = await fetch(`/api/user/carbon/cmb-ask/${ask?.id}/bid`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...bid,
        buyerUserId: selectedAccount.userId,
        quantity: bid.quantity,
        price: convertTextNumberToValue(bid.price),
      }),
    });
    queryCache.invalidateQueries([auctionBidsQueryKey, cmbBidsQueryKey]);
    return data;
  });

  useEffect(() => {
    onLoading?.(isLoading);
  }, [isLoading, onLoading]);

  useEffect(() => {
    if (isSuccess) onSuccess?.();
  }, [isSuccess, onSuccess]);

  const onSubmitHandler = async (formData: FormData) => {
    toast.info('Submitting bid..');
    try {
      const addBid = await mutate({ ...formData, askId: ask.id });
      if (addBid?.ok) {
        toast.success('Bid submitted.');
      } else {
        logger.warn(addBid);
        const error = await addBid?.json();
        toast.error(error?.message ?? 'Something went wrong!');
        logger.warn({ error });
      }
    } catch (err: any) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <FormDevTool control={control} />
      <form onSubmit={handleSubmit(onSubmitHandler)} autoComplete="off">
        <ModalContent>
          <div className="flex flex-col gap-large">
            <div className="flex flex-col w-full rounded-md bg-gray_100 p-small gap-small">
              <div className="flex flex-row gap-small">
                <div className="flex flex-col flex-1 gap-small">
                  <div className="flex flex-col">
                    <Text color={Colors.gray_500} size={FontSize.xs}>
                      Offer Number
                    </Text>
                    <Text>#{ask.id}</Text>
                  </div>
                  <div className="flex flex-col">
                    <Text color={Colors.gray_500} size={FontSize.xs}>
                      Available Qty
                    </Text>
                    <Text>
                      {formatter.formatNumber(ask.quantity, 0)} {tokenUnit}
                    </Text>
                  </div>
                </div>
                <div className="flex flex-col flex-1 gap-small">
                  <div className="flex flex-col">
                    <Text color={Colors.gray_500} size={FontSize.xs}>
                      Project Name
                    </Text>
                    <Text className="max-w-full"> {ask.__carbonProject__.name}</Text>
                  </div>
                  <div className="flex flex-col">
                    <Text color={Colors.gray_500} size={FontSize.xs}>
                      Price (per {tokenUnit})
                    </Text>
                    <Text>
                      {ccyAsset?.code ?? ''}
                      {formatter.formatNumber(ask.price, ccyAsset?.numDecimals)}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            {canCmbBidObo() && (
              <div className="flex flex-row gap-large">
                <div className="flex flex-col flex-1 gap-2xs">
                  <Text size={FontSize.small}>On Behalf Of</Text>
                  <SelectOboAccount
                    name="OBO"
                    inputValue={selectedAccount.account}
                    setFieldValue={(_selectedField, value) => {
                      setSelectedAccount({ account: value.value, userId: value.user_id, fullName: value.fullName });
                    }}
                  />
                  <div className="flex text-xs">
                    <div>Available Balance:</div>
                    <div className="relative pl-2">
                      {isLoadingAccountBalance ? (
                        <Loading size="small" />
                      ) : (
                        <span>
                          {mainCcyCode}
                          {formatter.formatNumber(selectedAccountBalance?.availableAmount ?? 0, mainCcyNumDecimals)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col sm:flex-row gap-large">
              <div className="flex flex-col flex-1 gap-2xs">
                <Text size={FontSize.small}>
                  Bid Quantity{' '}
                  {
                    <Tooltip
                      text={`Min: ${formatter.formatNumber(minQty, 0)}. Max: ${formatter.formatNumber(
                        maxQty,
                        0,
                      )}. Multiples of: ${formatter.formatNumber(bidQtyMultiplier, 0)}. Quantities in ${tokenUnit}`}
                    />
                  }
                </Text>
                <Input
                  config={{
                    size: 'sm',
                    color: errors.quantity ? 'error' : 'gray',
                    postfix: tokenUnit,
                    validation: {
                      type: 'float',
                      numeralDecimalScale: 2,
                      numeralPositiveOnly: true,
                      numeralThousandsGroupStyle: 'thousand',
                    },
                  }}
                  onChange={(event) => {
                    const value = (event?.target as any)?.rawValue;
                    setValue('quantity', value as unknown as number);
                    setTokenQty(helpers.convertTextNumberToValue(event.target.value));
                  }}
                  value={getValues('quantity')}
                />
                {errors.quantity && (
                  <Text size={FontSize.small} color={Colors.danger_700}>
                    {errors.quantity.message}
                  </Text>
                )}
              </div>
              <div className="flex flex-col flex-1 gap-2xs">
                <Text size={FontSize.small}>Price (per {tokenUnit})</Text>
                <Input
                  config={{
                    size: 'sm',
                    color: errors.price ? 'error' : 'gray',
                    postfix: ccyAsset?.symbol ?? '',
                    validation: {
                      type: 'float',
                      numeralDecimalScale: 2,
                      numeralPositiveOnly: true,
                      numeralThousandsGroupStyle: 'thousand',
                    },
                  }}
                  onChange={(event) => {
                    const value = (event?.target as any)?.rawValue;
                    setValue('price', value as unknown as number);
                    setPrice(helpers.convertTextNumberToValue(event.target.value));
                  }}
                  value={getValues('price')}
                />
                <Text color={Colors.gray_500} size={FontSize.xs}>
                  Amount (max: {ccyAsset?.code ?? ''}
                  {formatter.formatNumber(availableBalance, ccyAsset?.numDecimals)})
                </Text>
                {errors.price && (
                  <Text size={FontSize.small} color={Colors.danger_700}>
                    {errors.price.message}
                  </Text>
                )}
              </div>
            </div>
            <div className="flex flex-col w-full rounded-md bg-gray_100 p-small gap-small">
              <div className="flex flex-col flex-1 sm:flex-row gap-small">
                <div className="flex flex-col flex-1">
                  <Text color={Colors.gray_500} size={FontSize.xs}>
                    Total Amount
                  </Text>
                  <Text className="max-w-full">
                    {ccyAsset?.code ?? ''}
                    {formatter.formatNumber(
                      helpers.convertTextNumberToValue(debouncedTradeTotal),
                      ccyAsset?.numDecimals,
                    )}
                  </Text>
                </div>
                <div className="flex flex-col flex-1">
                  <Text color={Colors.gray_500} size={FontSize.xs}>
                    Fee
                  </Text>
                  <Text>
                    {ccyAsset?.code ?? ''}
                    {formatter.formatNumber(feeAmount, ccyAsset?.numDecimals)}
                  </Text>
                </div>
                <div className="flex flex-col flex-1">
                  <Text color={Colors.gray_500} size={FontSize.xs}>
                    Deposit ({depositPercentage}%)
                  </Text>
                  <Text>
                    {ccyAsset?.code ?? ''}
                    {formatter.formatNumber(depositAmount, ccyAsset?.numDecimals)}
                  </Text>
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full rounded-md gap-small">
              <Text color={Colors.gray_500} size={FontSize.small} weight={FontWeight.medium}>
                A deposit will be automatically collected if the supplier accepts the bid. Please ensure that your
                account is sufficiently funded to meet the deposit requirement or else your bid will not be accepted
                (deposit: {depositPercentage}% of total). Once you press confirm, it will be a binding bid. You will not
                be able to cancel once the supplier confirms the trade.
              </Text>
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            config={{
              color: 'primary',
              size: 'sm',
            }}
            type="submit"
            className="flex-1"
            isLoading={isLoadingFeeAmount || isLoading}
          >
            Place Bid
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default PlaceBid;
