export interface SectorResponse {
  id: string;
  sector: string;
  subSector: string;
}

export function groupSectors(sectors: SectorResponse[]): Record<string, any[]> {
  return (Array.isArray(sectors) ? sectors : []).reduce((grouped: any, current: any) => {
    // eslint-disable-next-line no-param-reassign
    (grouped[current.sector] = grouped[current.sector] || []).push(current);
    return grouped;
  }, {});
}
