import queryString from 'query-string';

import { userAuthenticatedApi } from '../fetcher';

type RFQBidsResponse = {
  items: Array<{
    id: number;
    type: string;
    tokenTypeId: number;
    ccyTypeId: number;
    quantity: number;
    price: number;
    createdAt: Date;
    userId: number;
    status: string;
    filled: number;
    placedBy: number;
    __rfqRequestDetails__: Array<any>;
    __rfqResponses__: Array<any>;
    __ccyType__: Record<string, any>;
  }>;
  total: number;
  page: number;
};

export const fetchRFQBids = async ({
  page,
  limit,
  status,
  filter,
  filterStatus,
  ccyTypeId,
  userId,
}: {
  page: number;
  limit: number;
  status?: string;
  filter: string;
  ccyTypeId: number;
  userId?: number;
  filterStatus?: string[];
}) => {
  const endpoint =
    filter === 'bids-to-me'
      ? `/user/rfq?${queryString.stringify({
          page,
          limit,
          ccyTypeId,
          status,
        })}`
      : `/user/rfq?${queryString.stringify(
          {
            page,
            limit,
            ccyTypeId,
            status: filterStatus?.length ? filterStatus : undefined,
            userId,
          },
          {
            arrayFormat: 'bracket',
          },
        )}`;
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).get();
  return res.json<RFQBidsResponse>();
};
