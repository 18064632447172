import { useQuery } from 'react-query';

import { fetchTradeAdjustment } from 'data-provider/trade/fetchTradeAdjustment';

export function useTradeAdjustment(tradeId: number) {
  const { data, isLoading, error } = useQuery(['oms-trade-adjustment', tradeId], () => fetchTradeAdjustment(tradeId), {
    enabled: tradeId,
  });

  return {
    error,
    isLoading,
    tradeAdjustment: data,
  };
}
