import { useState } from 'react';
import { Button } from 'refreshed-component/atoms/Button';
import { Card } from 'refreshed-component/atoms/Card';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { PageHeader } from 'refreshed-component/molecules/PageHeader';
import styled from 'styled-components';

import { User } from 'state/user';

import { Batches } from './components/Batches';
import { GERBalance } from './components/GERBalance';
import { PackGER } from './components/PackGER';
import { UnpackGER } from './components/UnpackGER';

export const Ger = () => {
  const [isPackGERVisible, setIsPackGERVisible] = useState(false);
  const [isUnpackGERVisible, setIsUnpackGERVisible] = useState(false);
  const {
    status: { canPackGER, canUnpackGER },
  } = User.useContainer();
  const onPressPackGER = () => {
    setIsPackGERVisible(true);
  };

  const onCloseUnpackGER = () => {
    setIsUnpackGERVisible(false);
  };

  const onClosePackGER = () => {
    setIsPackGERVisible(false);
  };

  const onPressUnpackGER = () => {
    setIsUnpackGERVisible(true);
  };

  return (
    <>
      <PageHeader
        title="Dashboard"
        margin="24px"
        suffix={
          <Actions>
            {canUnpackGER() && (
              <Button
                config={{
                  color: 'outlined',
                  icon: {
                    right: <Icon width={20} type={IconType.ViewGrid} />,
                  },
                }}
                onClick={onPressUnpackGER}
              >
                Unpack GER
              </Button>
            )}
            {canPackGER() && (
              <Button
                config={{
                  icon: {
                    right: <Icon width={20} type={IconType.ViewBoards} />,
                  },
                }}
                onClick={onPressPackGER}
              >
                Pack GER
              </Button>
            )}
          </Actions>
        }
      />
      <StyledCard>
        <GERBalance />
        <Batches />
      </StyledCard>
      <UnpackGER isVisible={isUnpackGERVisible} onClose={onCloseUnpackGER} />
      <PackGER isVisible={isPackGERVisible} onClose={onClosePackGER} />
    </>
  );
};

const StyledCard = styled(Card)`
  flex-direction: column;
  margin: 0 24px;
  container-type: inline-size;
  height: auto;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
`;
