import { useQuery } from 'react-query';

import { fetchUserSpotTrades } from 'data-provider/trade/fetchUserSpotTrades';

import { AssetCategoryCode } from '../layouts/trading.hook';

export function useUserSpotTrades({
  pairId,
  page,
  limit,
  accountAddress,
  searchByOrderId,
  side,
  status,
  assetCategory,
}: {
  pairId?: number | string;
  page: number;
  limit: number;
  accountAddress: string;
  searchByOrderId?: string;
  side?: string;
  status?: string;
  assetCategory?: AssetCategoryCode;
}) {
  const enableQuery = Number(pairId) > 0 && page && limit && accountAddress;
  const { data, isLoading, error } = useQuery(
    ['oms-user-trades', pairId, page, limit, searchByOrderId, side, status, accountAddress, assetCategory],
    () =>
      fetchUserSpotTrades({
        pairId: String(pairId),
        page,
        limit,
        accountAddress,
        searchByOrderId,
        side,
        status,
        assetCategory,
      }),
    { enabled: enableQuery },
  );

  return {
    error,
    isLoading,
    trades: data,
  };
}
