import { userAuthenticatedApi } from 'data-provider/fetcher';

import { toSearchResults } from './toSearchResults';

interface SearchProjectsProps {
  searchValue: string;
  assetCategoryId: string;
}

export const searchProjects = async (props: SearchProjectsProps) => {
  const { searchValue, assetCategoryId } = props;

  try {
    const userService = await userAuthenticatedApi();
    const request = await userService
      .url(`/user/carbon/project?searchBy=${searchValue}&assetCategoryId=${assetCategoryId}`)
      .get();

    const response = (await request.json()) as any;

    return toSearchResults(response.items);
  } catch (e) {
    throw e;
  }
};
