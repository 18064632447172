import React, { useRef } from 'react';
import { Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { LocalStorage, hooks } from '@aircarbon/utils-common';

import emitter from 'utils/emitter';

import Popover from './components/Popover';
import { ThemeColors } from './components/Themes';
import { BRIGHT } from './components/theme';

const { useOnClickOutside } = hooks;

const Selection = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  left: 0;
  right: 0;
  border-radius: 0px 0px 6px 6px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

const OptionItem = styled.div`
  color: ${BRIGHT.text};
  width: 100%;
  height: 22px;
  left: 0;
  right: 0;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;

  &:hover {
    background-color: #444;
  }
`;

const OptionDescription = styled.div`
  color: #6c6c6c;
  width: 100%;
  height: 20px;
  left: 0;
  right: 0;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 11px;
`;

type MenuItem =
  | {
      id: string;
      label: string;
      type?: 'clickable';
      onClick?: () => void;
      selected?: boolean;
    }
  | {
      type: 'description';
      label: string;
    };

type MenuProp = {
  style?: React.CSSProperties;
  list?: MenuItem[];
  showMinAndMax?: boolean;
  background?: string;
};

type Item = {
  children?: React.ReactNode;
  className?: string;
  isFixed?: boolean;
  resizeEventName?: string;
  style?: React.CSSProperties;
  minSizePx?: number;
  menu?: MenuProp;
} & (
  | {
      type: 'px';
      value: number;
    }
  | {
      type: 'flex';
      value: number;
    }
);

const SelectionMenu = ({ children, onClickOutside }: { children?: React.ReactNode; onClickOutside?: () => void }) => {
  const selectionRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(selectionRef, () => {
    if (onClickOutside) onClickOutside();
  });
  return <Selection ref={selectionRef}>{children}</Selection>;
};

const List = styled.div<{ type: 'row' | 'column'; backgroundColor: string; borderColor: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor};
  z-index: 1;
  ${(props) =>
    props.type === 'row'
      ? `
      flex-direction: row;
    > div:not(.splitter) {
      position: relative;
      width: 100%;
      border-right: 1px solid ${props.borderColor};
      &:last-child {
        border-right: 0px;
      }
    }
  `
      : `
      flex-direction: column;
    > div:not(.splitter) {
      position: relative;
      border-bottom: 1px solid ${props.borderColor};
      &:last-child {
        border-bottom: 0px;
      }
    }
  `}
`;

const ItemBox = styled.div`
  box-sizing: border-box;
  z-index: 1;
  overflow: hidden;
  position: relative;
`;

const Splitter = styled.div<{ type: 'row' | 'column' }>`
  ${(props) => {
    if (props.type === 'column') {
      return `width: 100%;`;
    } else {
      return `height: 100%;`;
    }
  }}
  flex: 0 0 0px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
`;

const SplitterBox = styled.div<{ type: 'row' | 'column'; isSelected?: boolean; backgroundColor: string }>`
  ${(props) => {
    if (props.type === 'column') {
      return `width: 100%; height: 5px; top: -3px; cursor: row-resize;`;
    } else {
      return `height: 100%; width: 5px; left: -3px; cursor: col-resize;`;
    }
  }}
  ${(props) => {
    if (props.isSelected) {
      return `opacity: 1;`;
    } else {
      return `opacity: 0;`;
    }
  }}
  background-color: ${(props) => props.backgroundColor};
  box-sizing: border-box;
  position: absolute;
  &:hover {
    opacity: 1;
  }
  border-right: 0px;
  transition-delay: 300ms;
  transition: opacity 150ms;
`;

const MenuHolder = styled.div`
  flex: 0 0 0px;
  box-sizing: border-box;
  z-index: 2;
  position: absolute !important;
  top: 0;
  right: 0;
  width: auto;
  height: 44px;
  cursor: pointer;
  display: flex;
  flex-direction: row;

  > div,
  svg {
    z-index: 2;
  }

  > div:first-child {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 32px;
    background-color: #191919;
    z-index: 1;
  }
`;

const MenuIcon = styled.div`
  flex: 0 0 0px;
  box-sizing: border-box;
  position: relative !important;
  padding-left: 12px;
  padding-right: 12px;
  height: 44px;
  cursor: pointer;

  svg {
    opacity: 0.5;
    transition: opacity 150ms;
    z-index: 2;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

type Props = {
  type: 'column' | 'row';
  list: Item[];
  minimized?: {
    list?: boolean[];
    onMinimized?: (list: boolean[]) => void;
  };
  id: string;
  style?: React.CSSProperties;
};

type State = {
  currentIndex: number;
  isMenuVisible: boolean;
  menuIndex: number;
};

const LOCAL_STORAGE_VERSION = `panel-storage-v1`;
class PanelListComponent extends React.Component<Props, State> {
  state: State = {
    currentIndex: -1,
    isMenuVisible: false,
    menuIndex: -1,
  };

  constructor(props: Props & { theme: ThemeColors }) {
    super(props);
    this.loadSizes();
  }

  savedSizes: number[] = [];
  loadSizes() {
    this.savedSizes = LocalStorage.getItem(`${LOCAL_STORAGE_VERSION}-${this.props.id}`, [], true);
  }

  removeMenu() {
    this.setState({
      ...this.state,
      isMenuVisible: false,
      menuIndex: -1,
    });
  }

  showMenu(index: number) {
    this.setState({
      ...this.state,
      isMenuVisible: true,
      menuIndex: index,
    });
  }

  saveSizes(savedSizes?: number[]) {
    if (!savedSizes) {
      this.savedSizes = this.props.list.map((item, index) => {
        return this.savedSizes[index] || item.value;
      });
      if (this.currentPan) this.savedSizes[this.state.currentIndex] = this.currentPan.value;
      if (this.nextPan) this.savedSizes[this.state.currentIndex + 1] = this.nextPan.value;
    }
    LocalStorage.setItem(`${LOCAL_STORAGE_VERSION}-${this.props.id}`, this.savedSizes, true);
  }

  onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.currentIndex = index;
    this.syncSizes(this.savedSizes, false);
    window.addEventListener('mousemove', this.onWindowMove);
    window.addEventListener('mouseup', this.onWindowUp);
    this.startX = event.clientX;
    this.startY = event.clientY;
    document.body.style.userSelect = 'none';
    if (this.props.type === 'column') {
      document.body.classList.add('row-resize');
    } else {
      document.body.classList.add('col-resize');
    }
    document.body.classList.add('children-pointer-events-none');
    this.moveLock = true;
    this.setState({});
    this.forceUpdate();
  };

  shouldComponentUpdate() {
    return this.state.currentIndex === -1;
  }

  firstTimeComponentDidUpdate = false;
  componentDidUpdate(prevProp: Props) {
    const list = this.props.minimized?.list || [];
    const prevList = prevProp.minimized?.list || [];
    if (JSON.stringify(list) !== JSON.stringify(prevList)) {
      this.adjustSizeAndMinimize();
    } else if (!this.firstTimeComponentDidUpdate) {
      this.firstTimeComponentDidUpdate = true;
      this.checkAndUpdateDiff();
    }
  }

  onWindowUp = () => {
    window.removeEventListener('mousemove', this.onWindowMove);
    window.removeEventListener('mouseup', this.onWindowUp);
    document.body.style.userSelect = '';
    if (this.props.type === 'column') {
      document.body.classList.remove('row-resize');
    } else {
      document.body.classList.remove('col-resize');
    }
    document.body.classList.remove('children-pointer-events-none');
    this.saveSizes();
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.currentIndex = -1;
    this.setState({}, () => {
      this.checkAndUpdateDiff();
    });
  };

  startX: number = 0;
  startY: number = 0;

  moveLock = true;

  diffX: number = 0;
  diffY: number = 0;

  onWindowMove = (event: MouseEvent) => {
    if (
      this.moveLock &&
      (!(this.startX - 5 < event.clientX && this.startX + 5 > event.clientX) ||
        !(this.startY - 5 < event.clientY && this.startY + 5 > event.clientY))
    ) {
      this.moveLock = false;
      this.onStart();
    }
    if (!this.moveLock) {
      this.diffX = event.clientX - this.startX;
      this.diffY = event.clientY - this.startY;
      this.onMove();
    }
  };

  currentPan?: Item;
  nextPan?: Item;

  currentPanClone?: Item;
  nextPanClone?: Item;

  bodySize: number = 0;
  sizeOfCurrentElement = 0;
  sizeOfNextElement = 0;

  onStart() {
    const currentIndex = this.state.currentIndex;
    this.currentPan = { ...this.props.list[this.state.currentIndex] };
    if (this.savedSizes[currentIndex]) this.currentPan.value = this.savedSizes[currentIndex];
    this.currentPanClone = { ...this.currentPan };
    this.nextPan = { ...this.props.list[this.state.currentIndex + 1] };
    if (this.savedSizes[currentIndex + 1]) this.nextPan.value = this.savedSizes[currentIndex + 1];
    this.nextPanClone = { ...this.nextPan };

    if (this.props.type === 'column') {
      this.bodySize = this.ref.current?.clientHeight || 0;
      this.sizeOfCurrentElement = this.itemRefs[this.state.currentIndex].current?.clientHeight || 0;
      this.sizeOfNextElement = this.itemRefs[this.state.currentIndex + 1].current?.clientHeight || 0;
    } else {
      this.bodySize = this.ref.current?.clientWidth || 0;
      this.sizeOfCurrentElement = this.itemRefs[this.state.currentIndex].current?.clientWidth || 0;
      this.sizeOfNextElement = this.itemRefs[this.state.currentIndex + 1].current?.clientWidth || 0;
    }
  }

  onMove() {
    const diffSize = this.props.type === 'column' ? this.diffY : this.diffX;
    const currentElement = this.itemRefs[this.state.currentIndex].current;
    const nextElement = this.itemRefs[this.state.currentIndex + 1].current;
    if (
      this.currentPan &&
      this.currentPanClone &&
      this.nextPan &&
      this.nextPanClone &&
      this.ref.current &&
      currentElement &&
      nextElement
    ) {
      if (this.currentPan.type === 'px') {
        const newValue = this.currentPanClone.value + diffSize;
        if (newValue > 25) {
          this.currentPan.value = newValue;
        } else {
          this.currentPan.value = 25;
        }
      }
      if (this.currentPan.type === 'px' && this.nextPan.type === 'px') {
        const newValue = this.nextPanClone.value - (this.currentPan.value - this.currentPanClone.value);
        if (newValue > 25) {
          this.nextPan.value = newValue;
        } else {
          this.nextPan.value = 25;
          this.currentPan.value = this.currentPanClone.value + this.nextPanClone.value - 25;
        }
      } else if (this.nextPan.type === 'px') {
        const newValue = this.nextPanClone.value - diffSize;
        if (newValue > 25) {
          this.nextPan.value = newValue;
        } else {
          this.nextPan.value = 25;
        }
        if (this.currentPan.type === 'flex') {
          const flexPerPx = this.currentPanClone.value / this.sizeOfCurrentElement;
          const currentFlex = Number((this.currentPanClone.value + diffSize * flexPerPx).toFixed(3));
          this.currentPan.value = currentFlex < 1 ? 1 : currentFlex;
        }
      } else if (this.currentPan.type === 'px' && this.nextPan.type === 'flex') {
        const flexPerPx = this.nextPanClone.value / this.sizeOfNextElement;
        const nextFlex = Number((this.nextPanClone.value - diffSize * flexPerPx).toFixed(3));
        this.nextPan.value = nextFlex < 1 ? 1 : nextFlex;
      } else if (this.currentPan.type === 'flex' && this.nextPan.type === 'flex') {
        const totalPx = this.sizeOfCurrentElement + this.sizeOfNextElement;
        const totalFlex = this.currentPanClone.value + this.nextPanClone.value;
        const flexPerPx = totalFlex / totalPx;
        const currentPx = this.sizeOfCurrentElement + diffSize;
        const currentFlex = Number((currentPx * flexPerPx).toFixed(3));
        const nextFlex = totalFlex - currentFlex;
        this.currentPan.value = currentFlex;
        this.nextPan.value = nextFlex;
      }

      currentElement.style.flex =
        this.currentPan.type === 'px' ? `0 0 ${this.currentPan.value}px` : `${this.currentPan.value || 1} 1 0%`;
      nextElement.style.flex =
        this.nextPan.type === 'px' ? `0 0 ${this.nextPan.value}px` : `${this.nextPan.value || 1} 1 0%`;

      this.currentPan.resizeEventName && emitter.emit(this.currentPan.resizeEventName);
    }
  }

  updateMinimized(index: number, value: boolean) {
    const list = this.props.minimized?.list ? [...this.props.minimized.list] : [];
    list[index] = value;
    if (list.find((item) => item === false) === undefined) {
      if (index !== 0) {
        list[index - 1] = false;
      } else {
        list[index + 1] = false;
      }
    }
    this.props.minimized?.onMinimized?.(list);
    this.onResizePan();
  }

  checkAndUpdateDiff() {
    const type = this.props.type;
    const list = this.props.minimized?.list || [];
    const newList = [...list];
    this.props.list.forEach((item, index) => {
      const itemSize =
        (type ? this.itemRefs[index].current?.offsetHeight : this.itemRefs[index].current?.offsetWidth) || 0;
      if (item.minSizePx && itemSize <= item.minSizePx) {
        newList[index] = true;
      } else {
        newList[index] = false;
      }
    });
    if (JSON.stringify(list) !== JSON.stringify(newList)) {
      this.props.minimized?.onMinimized?.(newList);
    }
  }

  adjustSizeAndMinimize() {
    const type = this.props.type;
    let maxSize = 0;
    let itemSizes: number[] = [];
    let unfairItem: number[] = [];
    let maxItem: number[] = [];

    const items = this.props.list;
    items.forEach((item, index) => {
      const isMin = this.props.minimized?.list?.[index] || false;
      const itemSize =
        (type ? this.itemRefs[index].current?.offsetHeight : this.itemRefs[index].current?.offsetWidth) || 0;
      itemSizes.push(itemSize);
      if (item.minSizePx && itemSize <= item.minSizePx && !isMin && !item.isFixed) {
        unfairItem.push(index);
      } else if (!isMin && !item.isFixed) {
        maxItem.push(index);
        maxSize += itemSize;
      }
    });

    if (unfairItem.length) {
      const fairSizeRatio = maxItem.length / (maxItem.length + unfairItem.length);
      const fairSize = (maxSize * fairSizeRatio) / unfairItem.length;
      itemSizes = itemSizes.map((value, index) => {
        if (unfairItem.includes(index)) {
          return fairSize;
        } else if (maxItem.includes(index)) {
          return value * fairSizeRatio;
        } else {
          return value;
        }
      });
      this.syncSizes([...itemSizes]);
    } else {
      this.onResizePan();
    }
  }

  onResizePan() {
    this.props.list?.forEach((item, index) => {
      const resizeEventName = this.props.list[index].resizeEventName;
      resizeEventName && emitter.emit(resizeEventName);
    });
  }

  syncSizes(newSizes: number[], triggerResize = true) {
    if (!triggerResize) {
      newSizes = this.props.list.map((item, index) => {
        return (
          (this.props.type === 'column'
            ? this.itemRefs[index].current?.clientHeight
            : this.itemRefs[index].current?.clientWidth) ?? 1
        );
      });
    }
    this.savedSizes = newSizes;
    this.saveSizes(newSizes);
    this.savedSizes.forEach((size, index) => {
      const item = this.props.list[index];
      const ref = this.itemRefs[index].current;
      if (ref) {
        ref.style.flex = item.type === 'px' ? `0 0 ${size}px` : `${size || 1} 1 0%`;
      }
    });
    if (triggerResize) this.onResizePan();
  }

  ref: React.RefObject<HTMLDivElement | null> = React.createRef();
  itemRefs: React.RefObject<HTMLDivElement | null>[] = [];

  render() {
    this.itemRefs.length = this.props.list.length;
    return (
      <List
        type={this.props.type}
        ref={this.ref as any}
        style={this.props.style}
        backgroundColor={`var(${Colors.gray_0})`}
        borderColor={`var(${Colors.gray_200})`}
      >
        {this.props.list.map((itemProp, index, list) => {
          const isLast = list.length - 1 === index;
          if (!this.itemRefs[index]) this.itemRefs[index] = React.createRef();
          const nextItemProp = list[index + 1];
          const itemValue = itemProp.isFixed ? itemProp.value : this.savedSizes[index] || itemProp.value;
          const minimizedList = this.props.minimized?.list;
          const isMinimized = minimizedList?.[index];
          const minimizedValue = minimizedList?.[index] ? itemProp.minSizePx || itemValue : itemValue;
          const isMenuVisible = this.state.isMenuVisible && this.state.menuIndex === index;
          return (
            <React.Fragment key={index}>
              <ItemBox
                ref={this.itemRefs[index] as any}
                style={{
                  ...itemProp.style,
                  flex:
                    itemProp.type === 'px' || isMinimized || itemProp.isFixed
                      ? `0 0 ${minimizedValue}px`
                      : `${minimizedValue || 1} 1 0%`,
                  minWidth: this.props.type === 'row' ? `${itemProp.minSizePx || 25}px` : '',
                  minHeight: this.props.type === 'column' ? `${itemProp.minSizePx || 25}px` : '',
                }}
                className={itemProp.className}
              >
                {itemProp.children}
                <MenuHolder>
                  <div style={{ background: itemProp.menu?.background }} />
                  {itemProp.menu?.showMinAndMax && (
                    <div className={`w-full flex justify-center items-center ${itemProp.menu?.list ? '' : 'mr-4'}`}>
                      <svg
                        className="cursor-pointer"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        onClick={() => this.updateMinimized(index, !isMinimized)}
                      >
                        <path
                          d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
                          fill="#333"
                        />
                        {isMinimized ? (
                          <>
                            <path d="M10.855 9.14498L12.1585 3L17 7.8415L10.855 9.14498Z" fill="#B6B6B6" />
                            <path d="M9.14498 10.855L7.8415 17L3 12.1585L9.14498 10.855Z" fill="#B6B6B6" />
                          </>
                        ) : (
                          <>
                            <path d="M4 16L5.42373 9.28809L10.7119 14.5762L4 16Z" fill="#B6B6B6" />
                            <path d="M16 4.00001L14.5763 10.7119L9.28813 5.42374L16 4.00001Z" fill="#B6B6B6" />
                          </>
                        )}
                      </svg>
                    </div>
                  )}
                  {itemProp.menu && itemProp.menu?.list && (
                    <>
                      {isMenuVisible ? (
                        <Popover
                          visible={true}
                          placement={'bottomRight'}
                          content={
                            <div style={{ minWidth: '150px' }}>
                              <SelectionMenu
                                onClickOutside={() => {
                                  this.removeMenu();
                                }}
                              >
                                {itemProp.menu?.list?.map((panelMenu) => {
                                  if (panelMenu.type === 'description') {
                                    return (
                                      <OptionDescription className={`cursor-pointer`}>
                                        {panelMenu.label}
                                      </OptionDescription>
                                    );
                                  } else {
                                    return (
                                      <OptionItem
                                        onMouseDown={(event) => {
                                          event.preventDefault();
                                        }}
                                        onClick={() => {
                                          panelMenu.onClick?.();
                                          this.removeMenu();
                                        }}
                                        className={`cursor-pointer`}
                                      >
                                        <div className="flex flex-row justify-center items-center min-w-full h-full">
                                          <div className="flex-1">{panelMenu.label}</div>
                                          {panelMenu.selected && (
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 48 48"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M10 24L20 34L40 14"
                                                stroke="white"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          )}
                                        </div>
                                      </OptionItem>
                                    );
                                  }
                                })}
                              </SelectionMenu>
                            </div>
                          }
                        >
                          <MenuIcon
                            onClick={() => this.removeMenu()}
                            style={itemProp.menu?.style}
                            className={'flex justify-center items-center opacity-100'}
                          >
                            <svg
                              style={{
                                opacity: 1,
                              }}
                              width="3"
                              height="15"
                              viewBox="0 0 3 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect width="3" height="3" rx="1.5" fill="#fff" />
                              <rect y="6" width="3" height="3" rx="1.5" fill="#fff" />
                              <rect y="12" width="3" height="3" rx="1.5" fill="#fff" />
                            </svg>
                          </MenuIcon>
                        </Popover>
                      ) : (
                        <MenuIcon
                          onClick={() => this.showMenu(index)}
                          style={itemProp.menu?.style}
                          className={'flex justify-center items-center'}
                        >
                          <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="3" height="3" rx="1.5" fill="#EDEEED" />
                            <rect y="6" width="3" height="3" rx="1.5" fill="#EDEEED" />
                            <rect y="12" width="3" height="3" rx="1.5" fill="#EDEEED" />
                          </svg>
                        </MenuIcon>
                      )}
                    </>
                  )}
                </MenuHolder>
              </ItemBox>
              {!isLast && !itemProp.isFixed && !nextItemProp?.isFixed && (
                <Splitter
                  className="splitter"
                  onMouseDown={(event) => {
                    this.onMouseDown(event, index);
                  }}
                  type={this.props.type}
                >
                  <SplitterBox
                    backgroundColor={`var(${Colors.primaryDark})`}
                    type={this.props.type}
                    isSelected={this.state.currentIndex === index}
                  />
                </Splitter>
              )}
            </React.Fragment>
          );
        })}
      </List>
    );
  }
}

export const PanelList = (props: Props) => {
  return <PanelListComponent {...props} />;
};
