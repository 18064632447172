import { Badge, BadgeSize, BadgeVariant } from 'refreshed-component/atoms/Badge';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import { ProjectStatus } from 'refreshed-pages/project-exchange/utils/ProjectStatus';

export const BadgeProjectStatus: React.FC<{
  status: ProjectStatus;
}> = ({ status }) => {
  const badgeColor = toBadgeColor(status);

  return (
    <Badge
      variant={BadgeVariant.Default}
      size={BadgeSize.Large}
      backgroundColor={badgeColor.background}
      color={badgeColor.color}
    >
      <Icon type={toBadgeIconType(status)} width={16} height={16} />
      <Text color={badgeColor.color} size={FontSize.small} weight={FontWeight.medium}>
        {toStatusText(status)}
      </Text>
    </Badge>
  );
};

const statusBadgeColorMap = {
  [ProjectStatus.Draft]: {
    background: Colors.gray_200,
    color: Colors.gray_900,
  },
  [ProjectStatus.Rejected]: {
    background: Colors.danger_100,
    color: Colors.danger_700,
  },
  [ProjectStatus.Submitted]: {
    background: Colors.warning_100,
    color: Colors.warning_700,
  },
  [ProjectStatus.Active]: {
    background: Colors.success_100,
    color: Colors.success_700,
  },
};

const statusesIconMap = {
  [ProjectStatus.Draft]: IconType.Document,
  [ProjectStatus.Rejected]: IconType.XCircle,
  [ProjectStatus.Submitted]: IconType.Clock,
  [ProjectStatus.Active]: IconType.CheckCircle,
};

const statusesTextMap = {
  [ProjectStatus.Draft]: 'Draft',
  [ProjectStatus.Rejected]: 'Rejected',
  [ProjectStatus.Submitted]: 'Submitted',
  [ProjectStatus.Active]: 'Active',
};

const toBadgeColor = (status: ProjectStatus) => statusBadgeColorMap[status];

const toBadgeIconType = (status: ProjectStatus) => statusesIconMap[status];

const toStatusText = (status: ProjectStatus) => statusesTextMap[status];
