import { useState } from 'react';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { BrandingEntity } from 'data-provider/entity';

import { Layouts } from '../../layouts/trading.hook';
import Button from '../Button';
import { MarketplaceModal } from './components/MarketplaceModal';

export const StyledProjectButton = styled(Button)<{
  layout?: Layouts;
  entity: BrandingEntity;
}>`
  background: var(${Colors.gray_50});
  color: var(${Colors.gray_900});
  padding: 3px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  outline: none;
  box-sizing: border-box;
  overflow: hidden;
  transition: none;
  width: ${({ layout }) => (layout === 'mobile' ? `100%` : `280px`)};
  height: 100%;
  border-radius: 9px;
  cursor: pointer;
  border: 1px solid var(${Colors.secondaryDefault});
  white-space: pre;
  display: flex;
  display: flex;
  flex-direction: row;

  &:hover {
    border: 1px solid ${({ entity }) => entity?.theme?.web?.brandColor};
  }
  > div {
    overflow: hidden;
    > div:first-child > div:last-child {
      overflow: hidden;
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .logo {
    background: var(${Colors.gray_0});
    padding: 3px;
    border-radius: 50px;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      height: 100%;
    }
  }
`;

export const ProjectButton: React.FC<{
  layout?: Layouts;
  apxName?: string;
  entity: BrandingEntity;
}> = (props) => {
  const { layout, entity, apxName } = props;

  if (!apxName) {
    return null;
  }

  return (
    <>
      <MarketplaceModal
        action={
          <StyledProjectButton
            layout={layout}
            entity={entity}
            config={{
              arrow: true,
              label: (
                <div className="flex relative gap-3 items-center text-left">
                  <Text color={Colors.gray_900} weight={FontWeight.semibold} size={FontSize.base}>
                    {apxName}
                  </Text>
                </div>
              ),
              size: 'big',
            }}
          />
        }
      />
    </>
  );
};
