export interface AddressData {
  address: string;
  account: {
    ccys?: Array<{
      balance: number;
      ccyTypeId: number;
      unit: number;
      name: string;
    }>;
    tokens?: Array<{
      currentQty: number;
      mintedQty: number;
      batchId: number;
      stId: number;
      tokTypeId: number;
      tokTypeName: string;
    }>;
  };
  batches: Array<any>;
  totalMintedQty: number;
  totalBurnedQty: number;
}
