import { useQuery } from 'react-query';

import { fetchUserOrders } from 'data-provider/oms/fetchUserOrders';

import { AssetCategoryCode } from '../layouts/trading.hook';

export function useUserOrders({
  pairId,
  page,
  limit,
  status,
  searchByOrderId,
  startDate,
  endDate,
  side,
  timeInForce,
  statusLabel,
  includeUser = false,
  accountAddress,
  assetCategory = 'token',
}: {
  pairId?: number | string;
  page: number;
  limit: number;
  status: string | string[];
  searchByOrderId?: string;
  includeUser?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  side?: string;
  timeInForce?: string;
  statusLabel?: string;
  accountAddress: string;
  assetCategory?: AssetCategoryCode;
}) {
  const enableFetch = page && limit && status && accountAddress;

  const { data, isLoading, error } = useQuery(
    [
      'oms-orders',
      pairId,
      page,
      limit,
      status,
      searchByOrderId,
      side,
      timeInForce,
      statusLabel,
      includeUser,
      startDate,
      endDate,
      includeUser,
      accountAddress,
      assetCategory,
    ],
    () =>
      fetchUserOrders({
        pairId,
        page,
        limit,
        status,
        accountAddress,
        searchByOrderId,
        includeUser,
        startDate,
        endDate,
        side,
        timeInForce,
        statusLabel,
        assetCategory,
      }),
    { enabled: enableFetch },
  );

  return {
    error,
    isLoading,
    orders: data,
  };
}
