import type { OrderBookResponse } from '@aircarbon/utils-common/src/dto/order';

import { userAuthenticatedApi } from '../fetcher';

export const fetchOrderBook = async (pairId?: number) => {
  const endpoint = '/oms/trade/order-book';
  const userService = await userAuthenticatedApi();
  const res = userService.url(endpoint).query({ pairId }).get();
  return res.json<OrderBookResponse>();
};
