import { showToast, ToastVariant } from '@aircarbon/ui';

import { downloadAuctionRules } from './downloadAuctionRules';

const onPressDownloadAuctionRules = async (orderId: number) => {
  showToast({
    variant: ToastVariant.Info,
    message: 'Downloading...',
  });

  const result = await downloadAuctionRules(orderId);

  if (result.status === 'error') {
    showToast({
      variant: ToastVariant.Danger,
      message: result.error,
    });

    return;
  }

  const blob = await result.file;

  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${result.fileName}`);
  link.click();
  link.parentNode?.removeChild(link);
};

export default onPressDownloadAuctionRules;
