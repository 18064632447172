export default (
  <svg viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.249939 18V23.25C0.249939 26.1497 5.73444 28.5 12.4999 28.5C19.2654 28.5 24.7499 26.1497 24.7499 23.25V18C24.7499 20.8997 19.2654 23.25 12.4999 23.25C5.73444 23.25 0.249939 20.8997 0.249939 18Z"
      fill="#111111"
    />
    <path
      d="M0.249939 9.25V14.5C0.249939 17.3997 5.73444 19.75 12.4999 19.75C19.2654 19.75 24.7499 17.3997 24.7499 14.5V9.25C24.7499 12.1497 19.2654 14.5 12.4999 14.5C5.73444 14.5 0.249939 12.1497 0.249939 9.25Z"
      fill="#111111"
    />
    <path
      d="M24.7499 5.75C24.7499 8.64975 19.2654 11 12.4999 11C5.73444 11 0.249939 8.64975 0.249939 5.75C0.249939 2.85025 5.73444 0.5 12.4999 0.5C19.2654 0.5 24.7499 2.85025 24.7499 5.75Z"
      fill="#111111"
    />
  </svg>
);
