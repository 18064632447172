import { useEffect, useState } from 'react';

import { CMBOrderType } from '@aircarbon/ui';
import { FeeType } from '@aircarbon/utils-common';

import { Entity } from 'state/entity';

import useAvailableBalance from 'hooks/useAvailableBalance';
import useFee from 'hooks/useFee';
import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

interface UseOrderSummaryProps {
  orderType: CMBOrderType;
  price: number;
  quantity: number;
  userId?: number;
  isTradeRequest?: boolean;
}

export const useOrderSummary = (props: UseOrderSummaryProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { orderType, price, quantity, userId, isTradeRequest } = props;
  const {
    selector: { mainCcyScId },
  } = Entity.useContainer();
  const { product } = useMarketplaceProduct();

  const { balance, isLoading: isLoadingBalance } = useAvailableBalance({
    params: { ccyTypeId: mainCcyScId, getRoot: false, userId },
  });

  const totalAmount = price * quantity;

  const [debouncedValues, setDebouncedValues] = useState({
    totalAmount: totalAmount,
    quantity,
  });

  useEffect(() => {
    setIsLoading(true);
    const debouncedValuesTimeout = setTimeout(() => {
      setDebouncedValues({
        totalAmount,
        quantity,
      });
      setIsLoading(false);
    }, 500);

    return () => {
      clearTimeout(debouncedValuesTimeout);
    };
  }, [totalAmount, quantity]);

  const { feeAmount, isLoading: isFetchingFee } = useFee({
    params: {
      feeType: orderType === CMBOrderType.Auction ? FeeType.TRADE_AUCTION_FEE : FeeType.TRADE_CMB_FEE,
      assetCategoryId: Number(product),
      tokenQty: debouncedValues.quantity,
      totalAmount: debouncedValues.totalAmount,
    },
    options: { enabled: debouncedValues.totalAmount > 0 },
  });

  const estimatedTotal = isTradeRequest
    ? orderType === CMBOrderType.Bid
      ? totalAmount - feeAmount
      : totalAmount + feeAmount
    : orderType === CMBOrderType.Bid
      ? totalAmount + feeAmount
      : totalAmount - feeAmount;

  return {
    availableBalance: balance?.balance || 0,
    estimatedTotal: estimatedTotal,
    fee: feeAmount,
    isCalculatingFee: isFetchingFee || isLoading,
    isLoadingBalance,
  };
};
