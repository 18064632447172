import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

export const BalanceCard: React.FC<{
  label: string;
  isTransparent?: boolean;
  logo?: string;
}> = (props) => {
  const { label, isTransparent = false, logo, children } = props;
  return (
    <StyledBalanceCard isTransparent={isTransparent}>
      {!!logo && (
        <Logo>
          <img src={logo} />
        </Logo>
      )}
      <Content>
        <Text color={Colors.gray_500}>{label}</Text>
        <Text weight={FontWeight.semibold} size={FontSize._2Xl}>
          {children}
        </Text>
      </Content>
    </StyledBalanceCard>
  );
};

const StyledBalanceCard = styled.div<{
  isTransparent: boolean;
}>`
  display: flex;
  gap: 8px;
  align-items: center;
  border: 1px solid ${({ isTransparent }) => (isTransparent ? 'transparent' : `var(${Colors.gray_300})`)};
  padding: 32px;
  border-radius: 8px;
  background-color: ${({ isTransparent }) => (isTransparent ? 'transparent' : `var(${Colors.gray_0})`)};
`;

const Logo = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div``;
