import { Colors } from 'refreshed-component/design-system';
import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 14px;
  padding: 1rem;
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  max-width: 100%;
  position: relative;
  overflow: auto;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(${Colors.gray_800});
  padding-bottom: 1em;
  margin-bottom: 1em;
`;
export const CriteriaWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  font-size: 14px;
  gap: 10px;
`;
