import { formatRelative } from 'date-fns';
import { CounterOffer } from 'refreshed-pages/market-board-v2/utils/fetchTradeRequests';

import { CMBOrderType } from '@aircarbon/ui';

import { OfferProps } from '../components/Offer';

const quantityFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });

export const toCounterOfferModalOffers = (props: {
  negotiations: Array<CounterOffer>;
  currentUserId: number;
  currentUserName: string;
  priceLabel: string;
  currency: string;
  isCurrentUserSeller: boolean;
  orderType: CMBOrderType;
}): Array<OfferProps> => {
  const { negotiations, currentUserName, priceLabel, currency, orderType, isCurrentUserSeller } = props;
  const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency });

  return [...negotiations].reverse().map((negotiation) => {
    const isMine = negotiation.direction === 'sent';

    return {
      from: isMine ? `${currentUserName} (Me)` : isCurrentUserSeller ? 'Buyer' : 'Seller',
      isMine,
      time: formatRelative(new Date(negotiation.createdAtUtc), new Date()),
      price: priceFormatter.format(negotiation.price),
      quantity: quantityFormatter.format(negotiation.quantity),
      priceLabel: priceLabel,
      priceRaw: negotiation.price,
      quantityRaw: negotiation.quantity,
    };
  });
};
