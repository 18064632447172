import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize } from 'refreshed-component/design-system';
import styled from 'styled-components';

type FileCardProps = {
  /**
   * File name
   *
   * @example "Uploaded file.pdf"
   */
  name: string;

  /**
   * File description
   *
   * @example "File Size: 20MB"
   */
  description: string;

  /**
   * Handles press on remove button
   */
  onRemove(): void;
};

export const FileCard: React.FC<FileCardProps> = (props) => {
  const { name, description, onRemove } = props;

  return (
    <StyledFileCard>
      <StyledFileIcon />
      <FileInfo>
        <Text>{name}</Text>
        <Text size={FontSize.xs} color={Colors.gray_500}>
          {description}
        </Text>
      </FileInfo>
      <StyledRemoveIcon onClick={onRemove} />
    </StyledFileCard>
  );
};

const StyledFileCard = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid var(${Colors.secondaryDefault});
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
`;

const StyledFileIcon = styled(Icon).attrs({
  type: IconType.Document,
  width: 42,
  height: 42,
})`
  color: var(${Colors.gray_500});
  flex-shrink: 0;
`;

const StyledRemoveIcon = styled(Icon).attrs({
  type: IconType.X,
  width: 42,
  height: 42,
})`
  color: var(${Colors.gray_500});
  cursor: pointer;
  flex-shrink: 0;
  margin-inline-end: -6px;

  & > svg {
    width: 20px;
    height: 20px;
  }
`;

const FileInfo = styled.div`
  flex-grow: 1;
`;
