import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'refreshed-component/molecules/toast';
import { findGERAsset } from 'refreshed-pages/ger/utils/findGERAsset';

import { logger } from '@aircarbon/utils-common';

import { Contract } from 'state/contract';
import { User } from 'state/user';

import { toAssetsAllocationsMap } from '../utils/toAssetsAllocationsMap';
import { toHistoryData } from '../utils/toHistoryData';
import type { GERHistoryData } from './GERHistoryData';

type GERHistoryFilters = {
  operation: 'pack' | 'unpack' | 'retirement';
};

const defaultItemsPerPage = 10;

export const useGERHistory = (): {
  meta: {
    assetsAllocationsMap: Record<string, number>;
  };
  pagination: {
    total: number;
    currentPage: number;
    perPage: number;
  };
  history: GERHistoryData;
  filters: GERHistoryFilters;
  isLoading: boolean;
  filter(filters: GERHistoryFilters): void;
  changePagination(pagination: { perPage: number; currentPage: number }): void;
} => {
  const routerHistory = useHistory();
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const parsedParams = new URLSearchParams(search);

  const [filters, setFilters] = useState<GERHistoryFilters>({
    operation: 'retirement',
  });
  const [history, setHistory] = useState<GERHistoryData>({
    operation: 'retirement',
    history: [],
  });
  const { tokenTypes } = Contract.useContainer();
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  const [pagination, setPagination] = useState({
    total: 0,
    perPage:
      Number.isNaN(Number(parsedParams.get('perPage'))) || !Number(parsedParams.get('perPage'))
        ? defaultItemsPerPage
        : Number(parsedParams.get('perPage')),
    currentPage:
      Number.isNaN(Number(parsedParams.get('currentPage'))) || !Number(parsedParams.get('currentPage'))
        ? 1
        : Number(parsedParams.get('currentPage')),
  });

  const gerAsset = findGERAsset(tokenTypes);
  const assetsAllocationsMap = useMemo(
    () =>
      toAssetsAllocationsMap({
        assetConfigurationJSON: gerAsset?.configuration,
        assets: tokenTypes,
      }),
    [gerAsset?.configuration, tokenTypes],
  );

  useEffect(() => {
    const fetchHistory = async () => {
      setIsLoading(true);
      let apiResult;
      try {
        // TODO: Implement data-provider
        const authToken = await getAuthToken();
        const response = await fetch(
          `/api/user/token/retire?isBasket=YES&page=${pagination.currentPage}&limit=${pagination.perPage}`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              authorization: `Bearer ${authToken}`,
            },
          },
        );
        const result = await response.json();
        apiResult = result;

        const historyData = toHistoryData({ ...result, assetsAllocationsMap });

        setHistory({
          operation: 'retirement',
          history: historyData,
        });
        setPagination((prevPagination) => ({
          ...prevPagination,
          total: result.total,
        }));
      } catch (e) {
        toast.error(apiResult?.message ?? 'Something went wrong!');
      } finally {
        setIsLoading(false);
      }
    };

    fetchHistory();
  }, [pagination.currentPage, pagination.perPage, assetsAllocationsMap]);

  const filter = (newFilters: GERHistoryFilters) => {
    setFilters(newFilters);
  };

  const changePagination = (newPagination: { currentPage: number; perPage: number }) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      ...newPagination,
    }));
    const updatedSearchParams: Record<string, string> = {};

    if (newPagination.currentPage > 1) {
      updatedSearchParams.currentPage = String(newPagination.currentPage);
    }

    if (newPagination.perPage !== defaultItemsPerPage) {
      updatedSearchParams.perPage = String(newPagination.perPage);
    }

    routerHistory.replace({
      pathname: '/account/assets/ger/history',
      search: new URLSearchParams(updatedSearchParams).toString(),
    });
  };

  return {
    filters,
    pagination,
    meta: {
      assetsAllocationsMap,
    },
    history,
    isLoading,
    filter,
    changePagination,
  };
};
