import { forwardRef, useImperativeHandle, useState } from 'react';
import { FieldsGroup } from 'refreshed-pages/market-board-v2/components/FieldsGroup';

import {
  Alert,
  AlertColor,
  AlertVariant,
  InputText,
  Modal,
  Select,
  ToastVariant,
  showToast,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import { Dto } from '@aircarbon/utils-common';

import { updateTradeRequest } from '../../utils/updateTradeRequest';

export interface RejectRequestModalRef {
  show(props: { tradeRequestId: string }): void;
}

export const RejectRequestModal = forwardRef<
  RejectRequestModalRef,
  {
    onRejected(result: {
      id: string;
      approvedAtUtc: string;
      approvedBy: number;
      buyerConfirmationStatus: string;
      sellerConfirmationStatus: string;
      buyerUpdatedAtUtc: string;
      sellerUpdatedAtUtc: string;
      price: number;
      quantity: number;
    }): void;
  }
>(({ onRejected }, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [tradeRequestId, setTradeRequestId] = useState('');

  const [formValues, setFormValues] = useState({
    rejectionReason: 'Credits no longer available',
    internalRemark: '',
  });

  useImperativeHandle(ref, () => ({
    show({ tradeRequestId }) {
      setTradeRequestId(tradeRequestId);
      setIsVisible(true);
    },
  }));

  const onClose = () => {
    if (isRejecting) {
      return;
    }

    setIsVisible(false);
  };

  const onPressReject = async () => {
    setIsRejecting(true);

    const result = await updateTradeRequest({
      tradeRequestId,
      status: 'CANCELLED',
      ...formValues,
    });

    setIsRejecting(false);

    if (result.status === 'error') {
      showToast({
        variant: ToastVariant.Danger,
        message: result.error,
      });
      return;
    }

    onRejected(result.data);
    setIsVisible(false);
  };

  return (
    <Modal
      title={`Reject Request #${tradeRequestId}`}
      isVisible={isVisible}
      onClose={onClose}
      onPressNegativeButton={onClose}
      onPressPositiveButton={onPressReject}
      positiveButtonText="Reject"
    >
      <Container>
        <Alert
          color={AlertColor.Warning}
          title="The offer will be no longer negotiable once your reject it"
          variant={AlertVariant.Secondary}
        />
        <FieldsGroup>
          <Select
            label="Rejection Reason"
            placeholder="Select Reason"
            value={formValues.rejectionReason}
            onChange={({ value }) => {
              setFormValues((prevValues) => ({ ...prevValues, rejectionReason: value }));
            }}
            items={Object.values(Dto.OfferRejectionReason).map((reason) => ({ value: reason, title: reason }))}
          />
          {/* <InputText
            label="Internal Remark (optional)"
            value={formValues.internalRemark}
            onChange={(e) => {
              setFormValues((prevValues) => ({ ...prevValues, internalRemark: e.target.value }));
            }}
          /> */}
        </FieldsGroup>
      </Container>
    </Modal>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;
