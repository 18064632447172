export default (
  <svg viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.00002 6.20156V4.60156C2.00002 3.5407 2.42145 2.52328 3.1716 1.77314C3.92174 1.02299 4.93916 0.601563 6.00002 0.601562C7.06089 0.601562 8.07831 1.02299 8.82845 1.77314C9.5786 2.52328 10 3.5407 10 4.60156V6.20156C10.4244 6.20156 10.8313 6.37013 11.1314 6.67019C11.4315 6.97025 11.6 7.37722 11.6 7.80156V11.8016C11.6 12.2259 11.4315 12.6329 11.1314 12.9329C10.8313 13.233 10.4244 13.4016 10 13.4016H2.00002C1.57568 13.4016 1.16871 13.233 0.868654 12.9329C0.568595 12.6329 0.400024 12.2259 0.400024 11.8016V7.80156C0.400024 7.37722 0.568595 6.97025 0.868654 6.67019C1.16871 6.37013 1.57568 6.20156 2.00002 6.20156V6.20156ZM8.40003 4.60156V6.20156H3.60002V4.60156C3.60002 3.96504 3.85288 3.35459 4.30297 2.90451C4.75306 2.45442 5.36351 2.20156 6.00002 2.20156C6.63654 2.20156 7.24699 2.45442 7.69708 2.90451C8.14717 3.35459 8.40003 3.96504 8.40003 4.60156V4.60156Z"
      fill="white"
    />
  </svg>
);
