import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { CopyInput } from 'refreshed-component/atoms/CopyInput';
import { Colors, FontSize, Spacing } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { Text, TypographyVariant, TextColor, Tabs, TabsVariant, TabsSize, IconName } from '@aircarbon/ui';

import { getTheme, setTheme } from 'pages/account/trading/components/Themes';

import { User, logout } from 'state/user';

import emitter from 'utils/emitter';

export type ContentHolderProps = {};
const ProfileMenuRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(${Spacing.base});
  gap: var(${Spacing.base});
  > .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(${Spacing._2xs});
    align-self: stretch;
    white-space: pre;
  }
  > .item {
    display: flex;
    flex-direction: row;
    gap: var(${Spacing.small});
    white-space: pre;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  > .separator {
    height: 1px;
    align-self: stretch;
    background: var(${Colors.gray_300});
  }
`;

export const ProfileMenu = () => {
  const theme = getTheme();
  const {
    selector: { getFullName, getUserProfile, getAccountAddress },
    status: {
      canDeposit,
      canWithdraw,
      canSeeExternalBrokerClientForBlockTrade,
      canSeeExternalBrokerClientForBiofuel,
      canManageUser,
    },
  } = User.useContainer();
  const history = useHistory();
  const userProfile = getUserProfile();
  const themesList = useMemo(() => {
    if (!!window.matchMedia) {
      return [
        {
          id: 'dark',
          icon: IconName.Moon,
          label: 'Dark',
        },
        {
          id: 'light',
          icon: IconName.Sun,
          label: 'Light',
        },
        {
          id: 'system',
          label: 'System',
        },
      ];
    }

    return [
      {
        id: 'dark',
        icon: IconName.Moon,
        label: 'Dark',
      },
      {
        id: 'light',
        icon: IconName.Sun,
        label: 'Light',
      },
    ];
  }, []);

  return (
    <ProfileMenuRoot>
      <div className="flex flex-col title gap-small">
        <Text variant={TypographyVariant.subtitle1}>{getFullName()}</Text>
        <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
          {userProfile.account_type}
        </Text>
        <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
          {userProfile.user_name}
        </Text>
      </div>
      {getAccountAddress() && (
        <div
          className="flex flex-row w-full address"
          style={{
            position: 'relative',
            margin: '0 -16px',
            width: 'calc(100% + 32px)',
          }}
        >
          <CopyInput
            prefix={{
              view: (
                <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
                  Address:
                </Text>
              ),
            }}
            radius="no-radius"
            className="flex-1"
            spacingLR={Spacing.small}
            spacingTB={Spacing.xs}
            background={Colors.gray_100}
            color={Colors.gray_900}
            svgColor={Colors.gray_500}
            size={FontSize.xs}
            text={getAccountAddress()}
            length={10}
            align={'center'}
          />
        </div>
      )}
      <StyledTabs
        activeItemId={theme}
        variant={TabsVariant.Contained}
        size={TabsSize.m}
        onPressTab={(tab) => {
          setTheme(tab.id);
          emitter.emit('trading-screen-theme-changed');
        }}
        items={themesList}
      />
      {(canSeeExternalBrokerClientForBlockTrade() ||
        canSeeExternalBrokerClientForBiofuel() ||
        canManageUser() ||
        canDeposit() ||
        canWithdraw()) && (
        <div
          onClick={() => {
            history.push('/account/company-settings/manage-user');
          }}
          className="item"
        >
          <Text>Settings</Text>
        </div>
      )}

      <div
        onClick={async () => {
          await logout();
        }}
        className="item"
      >
        <Text color={TextColor.error}>Logout</Text>
      </div>
    </ProfileMenuRoot>
  );
};

const StyledTabs = styled(Tabs)`
  width: 100%;
`;
