import { ProjectStatus } from '../../../utils/ProjectStatus';

export const toApiProjectStatus = (status?: ProjectStatus) => {
  if (status === undefined) {
    return null;
  }

  const statusesMap = {
    [ProjectStatus.Draft]: 'draft',
    [ProjectStatus.Rejected]: 'rejected',
    [ProjectStatus.Submitted]: 'submitted',
    [ProjectStatus.Active]: 'active',
  };

  return statusesMap[status];
};
