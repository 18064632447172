import { BadgeVariant } from '@aircarbon/ui';
import { Dto } from '@aircarbon/utils-common';

const orderStatusLabel = {
  [Dto.OtcOrderStatus.Filled]: 'Filled',
  [Dto.OtcOrderStatus.PartiallyFilled]: 'Partially Filled',
  [Dto.OtcOrderStatus.Active]: 'Active',
  [Dto.OtcOrderStatus.Pending]: 'Under Review',
  [Dto.OtcOrderStatus.Cancelled]: 'Cancelled',
  [Dto.OtcOrderStatus.Closed]: 'Closed',
};

const orderStatusBadgeVariant = {
  [Dto.OtcOrderStatus.Active]: BadgeVariant.Info,
  [Dto.OtcOrderStatus.PartiallyFilled]: BadgeVariant.Info,
  [Dto.OtcOrderStatus.Filled]: BadgeVariant.Success,
  [Dto.OtcOrderStatus.Pending]: BadgeVariant.Alert,
  [Dto.OtcOrderStatus.Cancelled]: BadgeVariant.Danger,
  [Dto.OtcOrderStatus.Closed]: BadgeVariant.Danger,
};

export const toOrderStatusBadgeProps = (statusId: Dto.OtcOrderStatus, projectStatus?: Dto.ProjectStatus) => {
  if (projectStatus && projectStatus !== Dto.ProjectStatus.Active) {
    return {
      variant: orderStatusBadgeVariant[Dto.OtcOrderStatus.Pending],
      value: orderStatusLabel[Dto.OtcOrderStatus.Pending],
    };
  }

  return {
    variant: orderStatusBadgeVariant[statusId],
    value: orderStatusLabel[statusId],
  };
};
